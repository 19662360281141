import { bool, func, object } from 'prop-types';
import React from 'react';

import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import DashboardPageCorrelationMatrixSection from './DashboardPageCorrelationMatrixSection';
import DashboardPageMarketOverviewSection from './DashboardPageMarketOverviewSection';
import DashboardPagePortfolioBreakdownSection from './DashboardPagePortfolioBreakdownSection';
import DashboardPagePortfolioPositionsSection from './DashboardPagePortfolioPositionsSection';
import ErrorBoundary from '../components/ErrorBoundary';
import styled from 'react-emotion';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');
require('@omniex/onx-common-ui/lib/semantic/css/popup.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const COMPONENT_NAME = 'DashboardPage';

const StyledDiv = styled('div')`
  height: 100%;
  overflow: hidden;

  .${COMPONENT_NAME}-grid {
    margin-bottom: 60px;
    padding-top: 20px;
  }

  .${COMPONENT_NAME}-gridRow {
    display: flex;
    margin-bottom: 20px;
  }

  .${COMPONENT_NAME}-gridColumn {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 50%;
    min-width: 40%;

    :first-child {
      margin-right: 20px;
    }
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

const Grid = ({children}) => <main><div className={cn('grid')}>{children}</div></main>
const Row = ({children}) => <div className={cn('gridRow')}>{children}</div>
const Col = ({children}) => <ErrorBoundary><div className={cn('gridColumn')}>{children}</div></ErrorBoundary>

const DashboardPage = ({
  accountsWithBalancesQueryData,
  accountsWithBalancesQueryLoading,
  currenciesQueryData,
  currenciesQueryLoading,
  currentPricesQueryData,
  currentPricesQueryLoading,
  currencyStatisticsQueryData,
  currencyStatisticsQueryError,
  currencyStatisticsQueryLoading,
  fetchCurrencyStatistics = noop,
  orgQueryData,
  orgQueryLoading,
  recentInputHistoryQueryData,
  topListQueryData,
  topListQueryError,
  topListQueryLoading,
  updateRecentInputHistory = noop,

}) => (
  <StyledDiv className={COMPONENT_NAME}>
      <Grid>
        <Row>
          <Col>
            <DashboardPagePortfolioBreakdownSection
              accountsWithBalancesQueryData={accountsWithBalancesQueryData}
              accountsWithBalancesQueryLoading={accountsWithBalancesQueryLoading}
              currenciesQueryData={currenciesQueryData}
              currenciesQueryLoading={currenciesQueryLoading}
              currentPricesQueryData={currentPricesQueryData}
              currentPricesQueryLoading={currentPricesQueryLoading}
              orgQueryData={orgQueryData}
              orgQueryLoading={orgQueryLoading}
            />
          </Col>
          <Col>
            <DashboardPageMarketOverviewSection
              orgQueryData={orgQueryData}
              orgQueryLoading={orgQueryLoading}
              topListQueryData={topListQueryData}
              topListQueryError={topListQueryError}
              topListQueryLoading={topListQueryLoading}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <DashboardPageCorrelationMatrixSection
              currencyStatisticsQueryData={currencyStatisticsQueryData}
              currencyStatisticsQueryError={currencyStatisticsQueryError}
              currencyStatisticsQueryLoading={currencyStatisticsQueryLoading}
              fetchCurrencyStatistics={fetchCurrencyStatistics}
              recentInputHistoryQueryData={recentInputHistoryQueryData}
              updateRecentInputHistory={updateRecentInputHistory}
            />
          </Col>
          <Col>
            <DashboardPagePortfolioPositionsSection
              accountsWithBalancesQueryData={accountsWithBalancesQueryData}
              accountsWithBalancesQueryLoading={accountsWithBalancesQueryLoading}
              currenciesQueryData={currenciesQueryData}
              currenciesQueryLoading={currenciesQueryLoading}
              currentPricesQueryData={currentPricesQueryData}
              currentPricesQueryLoading={currentPricesQueryLoading}
              orgQueryData={orgQueryData}
              orgQueryLoading={orgQueryLoading}
            />
          </Col>
        </Row>
      </Grid>
  </StyledDiv>
)

DashboardPage.propTypes = {
  accountsWithBalancesQueryData: object,
  accountsWithBalancesQueryError: object,
  accountsWithBalancesQueryLoading: bool,
  currenciesQueryData: object,
  currenciesQueryError: object,
  currenciesQueryLoading: bool,
  currentPricesQueryData: object,
  currentPricesQueryError: object,
  currentPricesQueryLoading: bool,
  currencyStatisticsQueryData: object,
  currencyStatisticsQueryError: object,
  currencyStatisticsQueryLoading: bool,
  fetchCurrencyStatistics: func,
  orgQueryData: object,
  orgQueryError: object,
  orgQueryLoading: bool,
  recentInputHistoryQueryData: object,
  topListQueryData: object,
  topListQueryError: object,
  topListQueryLoading: bool,
  updateRecentInputHistory: func,
}

export default DashboardPage
