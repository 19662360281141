const unsettledTradeTableCopy = {
  featureRFQ: 'RFQ',
  featureESP: 'ESP',
  notAvailable: 'N/A',
  popupLabelEMSOrderID: 'EMS Order ID',
  popupLabelExecID: 'Exec ID',
  popupLabelQuoteID: 'Quote ID',
  tableHeaderClientOrderID: 'Client Order ID',
  tableHeaderEmsOrderId: 'EMS Order ID',
  tableHeaderExecID: 'Exec ID',
  tableHeaderExecType: 'Execution Type',
  tableHeaderFillPrice: 'Fill Price',
  tableHeaderInstrument: 'Instrument',
  tableHeaderOrderID: 'Order ID',
  tableHeaderQuantity: 'Quantity',
  tableHeaderSettle: 'Settle',
  tableHeaderSide: 'Side',
  tableHeaderTimeExecuted: 'Time Executed',
  tableHeaderVenueOrderID: 'Venue Order ID',
  warningMessage: 'There are no unsettled trades at this time.'
};

export default unsettledTradeTableCopy;
