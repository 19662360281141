import { arrayOf, bool, number, shape, string } from 'prop-types';
import { Header, Icon, Loader, Segment } from 'semantic-ui-react';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { get, has } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import copyText from './DashboardPagePortfolioBreakdownSection.copyText';
import ErrorBoundary from '../components/ErrorBoundary';
import generateCurrentPriceLookupTable from '../../selectors/generateCurrentPriceLookupTable';
import getCurrenciesKeyedById from '../../selectors/getCurrenciesKeyedById';
import getVenuePositionsFromAccountsWithBalances from '../../selectors/getVenuePositionsFromAccountsWithBalances';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import populateAccountsWithCurrency from '../../selectors/populateAccountsWithCurrency';
import PortfolioBreakdownChart, { GROUPING_ATTRIBUTE_CURRENCY } from '../components/PortfolioBreakdownChart';
import PortfolioMarketValueComponent from '../components/PortfolioMarketValueComponent';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/header.css');
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/loader.css');
require('@omniex/onx-common-ui/lib/semantic/css/segment.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');

const ERR_MSG = 'errorMessage';
const WARN_NO_POSITIONS = 'warningMessageNoPositions';

const COMPONENT_NAME = 'DashboardPagePortfolioBreakdownSection';

const StyledSection = styled('section')`
  display: flex !important;
  flex-direction: column !important;
  min-height: 100%;

  .${COMPONENT_NAME}-segment {
    flex: 1 !important;
    min-height: 350px;

    &.shorter {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 315px;
    }
  }

  .${COMPONENT_NAME}-icon {
    font-size: 1em !important;
    padding: 2px !important;
  }

  .${COMPONENT_NAME}-componentWrapper {
    height: 380px;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

const Portfolio = ({ positions = [], prices = {}, currency = '', loading = false }) => {
  if (loading) return <Loader className={cn('loader')} active content={copyText.loadingMessage} size="medium" />;

  if (positions.length === 0) {
    const noPositions = currency && prices;
    const key = noPositions ? WARN_NO_POSITIONS : ERR_MSG;
    const type = noPositions ? {warning: true} : {error: true};
    return <Message content={copyText[key]} {...type} />;
  }

  return (
    <>
      <PortfolioBreakdownChart
        height={310}
        homeCurrency={currency}
        initialGroupingAttribute={GROUPING_ATTRIBUTE_CURRENCY}
        margin={[25, 45, -10, 45]}
        positions={positions}
        priceLookupTable={prices}
        size={200}
      />
      <PortfolioMarketValueComponent
        homeCurrency={currency}
        positions={positions}
        priceLookupTable={prices}
        size="mini"
        titlePrefix={copyText.portfolioMarketValuePrefix}
      />
    </>
  );
}

export default class DashboardPagePortfolioBreakdownSection extends PureComponent {
  static propTypes = {
    accountsWithBalancesQueryData: shape({
      accounts: arrayOf(
        shape({
          id: string.isRequired,
          balanceAtCustodianAsReportedTotal: number,
          temporaryCalculatedBalance: number
        }).isRequired
      )
    }),
    accountsWithBalancesQueryLoading: bool,
    currenciesQueryData: shape({
      currencies: arrayOf(shape({ id: string.isRequired }))
    }),
    currenciesQueryLoading: bool,
    currentPricesQueryData: shape({
      currentPrices: arrayOf(
        shape({
          baseCurrency: shape({ id: string.isRequired }).isRequired,
          price: number.isRequired
        })
      )
    }),
    currentPricesQueryLoading: bool,
    orgQueryData: shape({
      org: shape({ homeCurrency: shape({ id: string.isRequired }).isRequired })
    }),
    orgQueryLoading: bool
  };

  render() {
    const loading =
      this.props.currenciesQueryLoading ||
      !has(this.props, 'currenciesQueryData.currencies') ||
      this.props.currentPricesQueryLoading ||
      !has(this.props, 'currentPricesQueryData.currentPrices') ||
      this.props.orgQueryLoading ||
      !has(this.props, 'orgQueryData.org') ||
      this.props.accountsWithBalancesQueryLoading ||
      !has(this.props, 'accountsWithBalancesQueryData.accounts');

    const accounts = populateAccountsWithCurrency(
      get(this.props, 'accountsWithBalancesQueryData.accounts'),
      get(this.props, 'currenciesQueryData.currencies')
    );

    const currentPositions = getVenuePositionsFromAccountsWithBalances(accounts);
    const currenciesKeyedById = getCurrenciesKeyedById(get(this.props, 'currenciesQueryData.currencies'));
    const homeCurrency = currenciesKeyedById[get(this.props, 'orgQueryData.org.homeCurrency.id')];

    const currentPriceLookupTable = generateCurrentPriceLookupTable(
      get(this.props, 'currentPricesQueryData.currentPrices'),
      get(homeCurrency, 'id')
    );

    return (
      <StyledSection className={COMPONENT_NAME}>
        <Header attached="top">
          <Icon name="pie graph" className={cn('icon')} fitted />
          <Header.Content>{copyText.sectionTitle}</Header.Content>
        </Header>
        <Segment className={cn('segment')} attached="bottom">
          <ErrorBoundary message={copyText.error}>
            <Portfolio positions={currentPositions} prices={currentPriceLookupTable} currency={homeCurrency} loading={loading} />
          </ErrorBoundary>
        </Segment>
      </StyledSection>
    );
  }
}
