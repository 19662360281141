import { createSelector } from 'reselect';
import { keyBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';

const configsById = (configs = []) => keyBy(configs, c => c.instrument.id);
const getParams = (venues = []) => Array.isArray(venues) ? venues.reduce(
  // eslint-disable-next-line no-sequences
  (t, v) => (v.instrumentConfigurations && (t[v.id] = configsById(v.instrumentConfigurations)), t),
  {}
) : {};

export default createSelector(vs => vs, getParams);
