import gql from 'graphql-tag'

const configureQuoteFeedMutation = gql`
  mutation configureQuoteFeed($quoteFeedInputs: [QuoteFeedInput!]!) {
    quoteRequests: configureQuoteFeed(quoteFeedInputs: $quoteFeedInputs) {
      key
      instrument {
        id
        displayName
        type
      }
      quantity
      quote {
        bidPrice
        fixQuoteID
        offerPrice
        metrics {
          name
          time
        }
      }
      reasonRejected
      status
      venue {
        id
        name
      }
    }
  }
`;

export default configureQuoteFeedMutation;
