import * as React from 'react';
import { UserAppState, OTCTileData, OTCTemplateData, BaseUser } from '../../../../types';
import { cloneForm, cloneBase } from '..';
import { debounce } from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import updateUserAppState from '../../../../apollo/graphql/updateUserAppState';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';

const WAIT = 1 * 1000;
const MAX_WAIT = 3 * 1000;

export function useUpdateAppState(tiles: OTCTileData[], templates: OTCTemplateData[], client?: ApolloClient<NormalizedCacheObject>) {
  const [updateAppState] = useMutation<BaseUser, UserAppState>(updateUserAppState, { client });

  const options = React.useMemo(() => ({
    variables: {
      otc: {
        templates: templates.map(cloneBase),
        tiles: tiles.map(cloneForm),
      }
    }
  }), [templates, tiles]);

  const updateAppStateDebounced = React.useCallback(debounce(updateAppState, WAIT, { maxWait: MAX_WAIT }), [updateAppState]);

  React.useEffect(() => {
    updateAppStateDebounced(options);
  }, [options, updateAppStateDebounced]);
}

export default useUpdateAppState;
