import { arrayOf, func, number, object, shape, string } from 'prop-types';
import { Checkbox, Popup } from 'semantic-ui-react';
import { createSelector } from 'reselect';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { formatNum } from '../../utils/SettlementUtils';
import { get, has } from '@omniex/poms-core/lib/utils/ObjectUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import { getDateFormat, getDateFormatSimple } from '../../utils/DisplayUtils';
import { keyBy, orderBy } from '@omniex/poms-core/lib/utils/CollectionUtils';
import { isNil } from '@omniex/poms-core/lib/utils/LangUtils';
import { noop } from '@omniex/poms-core/lib/utils/FunctionUtils';
import copyText from './UnsettledTradeTable.copyText';
import getFilteredAccountsGroupedByCurrencyId from '../../selectors/getFilteredAccountsGroupedByCurrencyId';
import { getNetFills } from '../../utils/SettlementUtils';
import InitiateSettlementForm from '../components/InitiateSettlementForm';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import OrderSide from '@omniex/poms-core/lib/enums/OrderSide';
import OrgType from '@omniex/poms-core/lib/enums/OrgType';
import Table, {
  CELL_STYLE_FOOTER,
} from '@omniex/onx-common-ui/lib/semantic/react/Table';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/message.css');
require('@omniex/onx-common-ui/lib/semantic/css/table.css');
require('@omniex/onx-common-ui/lib/semantic/css/checkbox.css');
require('@omniex/onx-common-ui/lib/semantic/css/popup.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const COMPONENT_NAME = 'UnsettledTradeTable';
const { CLIENT_BUY_SIDE } = OrgType;

const popupStyles = {
  container: {
    fontSize: '0.9em',
    padding: '8px 16px'
  },
  identifierLabel: {
    fontSize: '0.9em',
    textAlign: 'right'
  },
  identifier: {
    fontFamily: 'monospace',
    fontSize: '0.9em'
  }
};

// prettier-ignore
const StyledTable = styled(Table)`
  .${COMPONENT_NAME}-quantity, .${COMPONENT_NAME}-quantityHeader, .${COMPONENT_NAME}-price, .${COMPONENT_NAME}-priceHeader {
    padding-right: 0.25em !important;
    text-align: right !important;
  }

  .${COMPONENT_NAME}-type {
    text-align: center !important;
  }

  .${COMPONENT_NAME}-currency {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .${COMPONENT_NAME}-fixID {
    font-family: monospace;
    font-size: 0.9em;
  }

  .${COMPONENT_NAME}-footerLining {
    display: inline-block;
    text-align: initial;
  }
`;

const StyledDiv = styled('div')`
  padding: 14px !important;
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

let instanceCount = 0;

export default class UnsettledTradeTable extends PureComponent {
  static propTypes = {
    accounts: arrayOf(object),
    currencies: arrayOf(object),
    fills: arrayOf(
      shape({
        id: string.isRequired,
        filledQuantity: number,
        fixExecID: string.isRequired,
        order: shape({
          feature: string,
          fixClOrdID: string,
          fixOrderID: string,
          fixQuoteID: string,
          fixVenueOrderID: string,
          instrument: shape({
            id: string.isRequired,
            baseCurrency: shape({
              id: string,
              symbol: string
            }),
            symbol: string,
            termCurrency: shape({
              id: string,
              symbol: string
            })
          }),
          side: string.isRequired
        }),
        price: number.isRequired,
        quantity: number.isRequired,
        timeCreated: string
      })
    ),
    orgType: string,
    user: object.isRequired,
    onInitiate: func
  };

  static defaultProps = { onInitiate: noop };

  state = { selectedFillIds: [] };

  instanceId = ++instanceCount;

  render() {
    if (get(this.props, 'fills.length', 0) === 0) {
      return this._renderWarningMessage();
    }

    let fills = orderBy(this.props.fills, 'timeCreated', 'desc');

    let selectedInstrument = null;

    const selectedFills = fills.filter(fill =>
      this.state.selectedFillIds.includes(fill.id)
    );

    if (selectedFills.length > 0) {
      // NOTE: this ensures user only attempts to net settle like instruments
      selectedInstrument = get(selectedFills, '[0]order.instrument');
      fills = fills.filter(
        fill => get(fill, 'order.instrument.id') === selectedInstrument.id
      );
    }

    return (
      <StyledTable className={COMPONENT_NAME}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {copyText.tableHeaderTimeExecuted}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {this.props.orgType === CLIENT_BUY_SIDE
                ? copyText.tableHeaderVenueOrderID
                : copyText.tableHeaderClientOrderID}
            </Table.HeaderCell>
            <Table.HeaderCell>{copyText.tableHeaderOrderID}</Table.HeaderCell>
            <Table.HeaderCell className={cn('type')}>
              {copyText.tableHeaderExecType}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {copyText.tableHeaderInstrument}
            </Table.HeaderCell>
            <Table.HeaderCell>{copyText.tableHeaderSide}</Table.HeaderCell>
            <Table.HeaderCell className={cn('priceHeader')} textAlign="right">
              {copyText.tableHeaderFillPrice}
            </Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell
              className={cn('quantityHeader')}
              textAlign="right">
              {copyText.tableHeaderQuantity}
            </Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell>&nbsp;</Table.HeaderCell>
            {this.props.user.canInitiateSettlement && (<Table.HeaderCell>{copyText.tableHeaderSettle}</Table.HeaderCell>)}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {fills.map(fill => {
            const order = get(fill, 'order');

            return (
              <Table.Row key={fill.id}>
                <Table.Cell>
                  <Popup
                    flowing
                    hoverable
                    hideOnScroll
                    inverted
                    style={popupStyles.container}
                    trigger={<span>{getDateFormatSimple(fill.timeCreated)}</span>}>
                    <div style={popupStyles.identifierLabel}>
                      <span>{getDateFormat(fill.timeCreated)}</span>
                    </div>
                  </Popup>
                </Table.Cell>
                <Table.Cell className={cn('fixID')}>
                  {this.props.orgType === CLIENT_BUY_SIDE
                    ? order.fixVenueOrderID
                    : order.fixClOrdID}
                </Table.Cell>
                <Table.Cell className={cn('fixID')}>
                  <Popup
                    flowing
                    hoverable
                    inverted
                    style={popupStyles.container}
                    trigger={
                      <span>
                        {this.props.orgType === CLIENT_BUY_SIDE
                          ? order.fixClOrdID
                          : order.fixVenueOrderID}
                      </span>
                    }>
                    <table>
                      <tbody>
                        <tr>
                          <td style={popupStyles.identifierLabel}>
                            {copyText.popupLabelExecID}:
                          </td>
                          <td style={popupStyles.identifier}>
                            {fill.fixExecID}
                          </td>
                        </tr>
                        <tr>
                          <td style={popupStyles.identifierLabel}>
                            {copyText.popupLabelEMSOrderID}:
                          </td>
                          <td style={popupStyles.identifier}>
                            {isNil(order.fixOrderID)
                              ? copyText.notAvailable
                              : order.fixOrderID}
                          </td>
                        </tr>
                        <tr>
                          <td style={popupStyles.identifierLabel}>
                            {copyText.popupLabelQuoteID}:
                          </td>
                          <td style={popupStyles.identifier}>
                            {isNil(order.fixQuoteID)
                              ? copyText.notAvailable
                              : order.fixQuoteID}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Popup>
                </Table.Cell>
                <Table.Cell className={cn('type')}>{order.feature}</Table.Cell>
                <Table.Cell>{get(order, 'instrument.displayName')}</Table.Cell>
                <Table.Cell>
                  {this.props.orgType === CLIENT_BUY_SIDE
                    ? order.side
                    : Object.values(OrderSide).find(
                        side => OrderSide[side] !== order.side
                      )}
                </Table.Cell>
                <Table.Cell
                  className={cn('price')}
                  ifNotNumber="N/A">
                  {formatNum(fill.price, get(order, 'instrument.termCurrency.type'))}
                </Table.Cell>
                <Table.Cell className={cn('currency')}>
                  {getAssetDisplayText(get(order, 'instrument.termCurrency'))}
                </Table.Cell>
                <Table.Cell
                  className={cn('quantity')}>
                  {formatNum(fill.quantity, get(order, 'instrument.baseCurrency.type'))}
                </Table.Cell>
                <Table.Cell className={cn('currency')}>
                  {getAssetDisplayText(get(order, 'instrument.baseCurrency'))}
                </Table.Cell>
                <Table.Cell />
                {this.props.user.canInitiateSettlement && (
                  <Table.Cell>
                    <Checkbox
                      id={[
                        COMPONENT_NAME,
                        this.instanceId,
                        'fillCheckbox',
                        fill.id
                      ].join('-')}
                      checked={this.state.selectedFillIds.includes(fill.id)}
                      disabled={this.state.showSecondConfirmation}
                      onChange={(_, field) => this._handleChangeCheckbox(fill, field)}
                    />
                  </Table.Cell>
                )}
              </Table.Row>
            );
          })}
        </Table.Body>
        {this._renderFooter({ selectedFills })}
      </StyledTable>
    );
  }

  _renderWarningMessage() {
    return (
      <StyledDiv className={COMPONENT_NAME}>
        <Message content={copyText.warningMessage} warning />
      </StyledDiv>
    );
  }

  _renderFooter({ selectedFills }) {
    if (selectedFills.length === 0) return null;

    const isUserBuySide = this.props.orgType === CLIENT_BUY_SIDE;

    let netFlows = getNetFills(selectedFills, isUserBuySide, get(this.props, 'currencies'));

    netFlows = orderBy(netFlows, 'quantity', 'desc');

    return (
      <Table.Footer>
        <Table.Row>
          <Table.Cell
            colSpan={13}
            padding={20}
            styleName={CELL_STYLE_FOOTER}
            textAlign="right">
            <div className={cn('footerLining')}>
              <InitiateSettlementForm
                fromAccounts={filterAccountsByNetFlows(
                  this.props.accounts,
                  netFlows,
                  false
                )}
                netFlows={netFlows}
                netted={selectedFills.length > 1}
                selectedFillIds={this.state.selectedFillIds}
                showSecondConfirmation={this.state.showSecondConfirmation}
                toAccounts={filterAccountsByNetFlows(
                  this.props.accounts,
                  netFlows,
                  true
                )}
                onCancel={this._handleCancel}
                onFirstConfirmation={this._handleFirstConfirmation.bind(this)}
                onSecondConfirmation={this._handleSecondConfirmation.bind(this)}
              />
            </div>
          </Table.Cell>
        </Table.Row>
      </Table.Footer>
    );
  }

  _handleCancel = () => {
    this.setState({ selectedFillIds: [], showSecondConfirmation: false });
  };

  _handleChangeCheckbox = (fill, field) => {
    const selected = this.state.selectedFillIds
    const selectedFillIds = field.checked
      ? selected.length
        ? [...selected, fill.id]
        : filterByInstrument(this.props.fills, get(fill, 'order.instrument.id'))
      : selected.filter(fillId => fillId !== fill.id)
    this.setState({selectedFillIds});
  };

  _handleFirstConfirmation = () => {
    this.setState({ showSecondConfirmation: true });
  };

  _handleSecondConfirmation = allocationInputs => {
    this.props.onInitiate({
      allocationInputs,
      fillIds: this.state.selectedFillIds
    });
  };
}

const filterByInstrument = (fills, instrumentID) => {
  return fills.reduce((accum, f) => {
    if (get(f, 'order.instrument.id') === instrumentID) accum.push(f.id);
    return accum;
  }, []);
};

const filterAccountsByNetFlows = createSelector(
  (accounts, netFlows, isIncoming) => accounts,
  (accounts, netFlows, isIncoming) => netFlows,
  (accounts, netFlows, isIncoming) => isIncoming,
  (accounts, netFlows, isIncoming) => {
    const accountsGroupedByCurrencyId = getFilteredAccountsGroupedByCurrencyId(
      accounts
    );
    const netFlowsKeyedByCurrencyId = keyBy(netFlows, 'currencyId');

    const currencyIds = netFlows.reduce((accum, netFlow) => {
      return isIncoming
        ? netFlow.quantity >= 0
          ? [...accum, netFlow.currencyId]
          : accum
        : netFlow.quantity < 0
        ? [...accum, netFlow.currencyId]
        : accum;
    }, []);

    return currencyIds.reduce((collectedAccounts, currencyId) => {
      let accounts = accountsGroupedByCurrencyId[currencyId] || [];

      accounts = accounts.reduce((accum, account) => {
        if (netFlowsKeyedByCurrencyId[currencyId].currencyType === 'FIAT') {
          if (
            !has(account, 'custodian.operatingVenue.id') &&
            (get(account, 'custodian.type') === OrgType.EXTERNAL_ENTITY || get(account, 'customCustodian'))
          ) {
            accum = [...accum, account];
          }
        } else {
          accum = [...accum, account];
        }
        return accum;
      }, []);

      return [...collectedAccounts, ...accounts];
    }, []);
  }
);
