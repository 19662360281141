import * as React from 'react';
import { IconProps, TooltipProps, makeStyles, Tooltip } from '@omniex/poms-ui';
import { IconWithRef } from '@omniex/poms-ui/lib/components/Icon';

export type IconWithTooltipProps = IconProps & {
  Icon: IconWithRef;
  title: TooltipProps['title'];
}

export const useIconWithTooltipStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
});

// Wrap icon in div to suppress misguided warning

export function IconWithTooltip({ title, Icon, ...props }: IconWithTooltipProps) {
  const cls = useIconWithTooltipStyles();
  return (
    <Tooltip title={title}>
      <div className={cls.root}>
        <Icon {...props} />
      </div>
    </Tooltip>
  );
}
