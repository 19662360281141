import { Redirect } from 'react-router-dom';
import React, { Component } from 'react';

import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { hasRoleType } from '@omniex/onx-poms-entity-helpers/lib/utils/UserUtils';
import OrgType from '@omniex/onx-poms-entity-helpers/lib/enums/OrgType';
import UserRoleType from '@omniex/onx-poms-entity-helpers/lib/enums/UserRoleType';

import {
  ADMIN_PAGE_PATH,
  CUSTODY_PAGE_PATH,
  DASHBOARD_PAGE_PATH,
  PORTFOLIO_PAGE_PATH,
  QUOTE_PAGE_PATH,
  REQUESTS_PAGE_PATH,
  SETTLEMENT_PAGE_PATH
} from './constants/paths';

export default class RootRedirect extends Component {
  render() {
    const { user } = this.props;
    if (hasRoleType(user, UserRoleType.TRADER) || hasRoleType(user, UserRoleType.SYSTEM_OPERATOR))
      return get(user, 'org.type') === OrgType.CLIENT_SELL_SIDE
        ? <Redirect to={QUOTE_PAGE_PATH} />
        : <Redirect to={DASHBOARD_PAGE_PATH} />
    else if (hasRoleType(user, UserRoleType.ADMIN)) return <Redirect to={ADMIN_PAGE_PATH} />
    else if (hasRoleType(user, UserRoleType.CRYPTO_ACCOUNT_CONTROLLER) || hasRoleType(user, UserRoleType.FIAT_ACCOUNT_CONTROLLER)) return <Redirect to={CUSTODY_PAGE_PATH} />
    else if (hasRoleType(user, UserRoleType.PORTFOLIO_AUDITOR) || hasRoleType(user, UserRoleType.PORTFOLIO_MANAGER)) return <Redirect to={PORTFOLIO_PAGE_PATH} />
    else if (hasRoleType(user, UserRoleType.SETTLEMENT_OFFICER)) return <Redirect to={SETTLEMENT_PAGE_PATH} />
    else if (hasRoleType(user, UserRoleType.SYSTEM_INTEGRATOR)) return <Redirect to={REQUESTS_PAGE_PATH} />
    else return <Redirect to={DASHBOARD_PAGE_PATH} />
  }
}
