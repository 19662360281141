/* eslint no-sequences: 0 */
import { makeStyles } from '@omniex/poms-ui/lib/themes';
import { colors } from '@omniex/onx-common-ui/lib/styles';

const flex = ({ d = 'row', w = 'nowrap', jc = 'flex-start', ai = 'center' } = {}) => ({ display: 'flex', flexFlow: `${d} ${w}`, justifyContent: jc, alignItems: ai })

export const useReportClasses = makeStyles({
  root: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  controls: {
    ...flex(),
    marginBottom: 16,
  },
  left: flex(),
  right: {
    ...flex({ jc: 'flex-end' }),
    flexGrow: 1,
  },
  toggleData: {
    ...flex(),
    marginRight: 16,
  },
  reset: {
    ...flex(),
    marginRight: 24,
  },
  download: flex(),
  buttonLabel: {
    color: 'inherit !important',
  },
}, { name: 'ReportPage' })

export const useGroupControlsClasses = makeStyles({
  label: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  groupControls: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    height: 32,
  },
  addBtn: {
    display: 'flex',
    cursor: 'pointer',
  },
  navigateNext: {
    color: colors.grey,
    marginRight: '-5px important!',
  },
}, { name: 'ReportGroupControls' })

// const gray = (n, e=0.66147516201919) => 0.45*(1-Math.cos(Math.PI*n**e/(2*9**e))) // generates 0.03 to 0.45
const gray = (n, e = 0.5951426294808) => 0.45 * (1 - Math.cos(Math.PI * n ** e / (2 * 9 ** e))) // generates 0.04 to 0.45
const bgColor = n => ({ backgroundColor: `rgba(0,0,0,${gray(n)})` })
const rowDepthStyles = new Array(10).fill(0).reduce((s, _, i) => (s[`d${i}`] = bgColor(i), s), {})
export const useTableClasses = makeStyles({
  root: {
    whiteSpace: 'nowrap',
    overflow: 'scroll',
    height: 'calc(100vh - 282px)',
  },
  table: {
    // whiteSpace: 'nowrap',
    // overflow: 'scroll',
    // height: 'calc(100vh - 282px)',
  },
  header: {},
  headerRow: {},
  headerCell: {
    '&:not(:first-child)': {
      paddingLeft: 16,
    },
    '&:not(:last-child)': {
      paddingRight: 16,
    },
  },
  body: {},
  row: {
    '&:hover': {
      backgroundColor: 'rgba(0, 111, 191, .1) !important'
    }
  },
  collapsibleRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 111, 191, .1) !important'
    }
  },
  cell: {
    '&:not(:first-child)': {
      paddingLeft: 16,
    },
    '&:not(:last-child)': {
      paddingRight: 16,
    },
  },
  left: {
    textAlign: 'left',
  },
  center: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
  table2: {
    display: 'flex',
    flexFlow: 'row nowrap',
    whiteSpace: 'nowrap',
    overflow: 'scroll',
    height: 'calc(100vh - 282px)',
  },
  table3: {
    whiteSpace: 'nowrap',
    overflow: 'auto',
    height: 'calc(100vh - 282px)',
  },
  ...rowDepthStyles,
  column: {
    '&:not(:first-child)': {
      paddingLeft: 16,
    },
    '&:not(:last-child)': {
      paddingRight: 16,
    },
  },
  cell2: {
    height: 21,
  },
  muiCell: {
    display: 'flex',
    alignItems: 'center',
  },
  collapseArrow: {
    opacity: '0.6',
    marginLeft: '-13px !important',
    verticalAlign: 'middle',
  },
}, { name: 'ReportTable' })

export const useTableCellStyles = makeStyles({
  cell: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
}, { name: 'ReportTableCell' })

export const useColumnHeaderClasses = makeStyles({
  controls: {
    width: 0,
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'row',
  },
  inactiveFilter: {
    color: colors.grey,
    cursor: 'pointer',
    marginRight: '0em !important',
    opacity: '0.8 !important',

    '&:hover, &:active': {
      color: colors.black,
      opacity: '1 !important',
    },
  },
  activeFilter: {
    color: colors.blue,
    cursor: 'pointer',
    marginRight: '0em !important',
    opacity: '0.8 !important',

    '&:hover, &:active': {
      opacity: '1 !important',
    }
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '-5px',
  },
  header: {
    color: 'rgba(0, 0, 0, 0.87)',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'right',
    fontWeight: 'bold',
    texAlign: 'right',
  },
  left: {
    justifyContent: 'flex-start',
  },
  right: {
    justifyContent: 'flex-end',
  },
  center: {
    justifyContent: 'center'
  },
  menuItemLabel: {
    color: 'rgba(0, 0, 0, 0.87)',
    paddingLeft: '5px',
  },
  root: {
    '&:not(:first-child)': {
      paddingLeft: 10,
    },
    '&:not(:last-child)': {
      paddingRight: 10,
    },
  },
  sort: {
    color: colors.grey,
    marginRight: '2px !important',
    maxWidth: '17px',
    opacity: '0.8 !important',
    padding: '0px',
  },
  sortContainer: {
    minHeight: '22px',
    minWidth: '17px',
  }
}, { name: 'ReportColumnHeader' })
