const tradePageMarketHistorySectionCopy = {
  chartRange1Month: '1 Month',
  chartRange1Week: '1 Week',
  chartRange1Year: '1 Year',
  chartRange2Weeks: '2 Weeks',
  chartRange3Months: '3 Months',
  chartRange6Months: '6 Months',
  chartRangeAll: 'Since Inception',
  chartTypeCandlestick: 'Candlestick',
  chartTypeLine: 'Line',
  chartTypeOhlc: ' OHLC',
  errorMessage:
    'Sorry, no data available for the selected instrument and feed.',
  feedLabelDefaultFeeds: 'Default Feeds',
  feedLabelExchangeFeeds: 'Exchange Feeds',
  feedSymbolCoinMarketCap: 'CoinMarketCap',
  feedSymbolCryptoCompare: 'CryptoCompare',
  inputPlaceholder_instrumentDisplayName: 'Instrument',
  inputPlaceholder_feedSymbol: 'Feed',
  loadingMessage: 'Loading...',
  sectionTitle: 'Market History'
};

export default tradePageMarketHistorySectionCopy;
