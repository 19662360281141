import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import AccountType from '@omniex/onx-poms-entity-helpers/lib/enums/AccountType';

const CUSTOM_ASSET_PREFIX = 'CUSTOM-';
const SYSTEM_CUSTODIAN_ID = '1';
const UNSETTLED = 'Unsettled Activities';
const SELF_CUSTODY = 'Self Custody';

export function generateCurrencyKey(account) {
  return get(account, 'hasCustomAsset') === true
    ? `${CUSTOM_ASSET_PREFIX}${account.currency.id}`
    : account.currency.id;
}

export function getCustodianName(account) {
  if (account?.custodian?.id === SYSTEM_CUSTODIAN_ID) return UNSETTLED;
  else if (account?.custodian?.id === undefined) return account?.customCustodian?.name || SELF_CUSTODY;
  else return account?.custodian?.name;
}

export function getTotalAccountBalance(account) {
  return account.type === AccountType.FUTURE
    ? get(account, 'position.quantity')
    : account.balanceAtCustodianAsReportedTotal || account.temporaryCalculatedBalance
}

const accountUtils = {
  generateCurrencyKey,
  getCustodianName,
  getTotalAccountBalance
};

export default accountUtils;
