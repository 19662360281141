import gql from 'graphql-tag';

export default gql`
  query getInstruments {
    instruments: getInstruments {
      id
      symbol
      type
      baseAsset {
        id
        symbol
        expiry
        name
        type
      }
      baseCurrency: baseAsset {
        id
        symbol
        expiry
        name
        type
      }
      marginAsset {
        id
        symbol
        expiry
        name
        type
      }
      termAsset {
        id
        symbol
        expiry
        name
        type
      }
      termCurrency: termAsset {
        id
        symbol
        expiry
        name
        type
      }
      mdInstrument {
        id
      }
      displayName
      identifier
    }
  }
`;
