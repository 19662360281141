import * as React from 'react';
import { useMemo } from 'react';
import { getFillColumns } from './multigridColumns';
import { ROW_HEIGHT, TABLE_HEADER_HEIGHT } from './shared';
import { Fill, SortParams } from '../../../types';
import { IndexRange } from 'react-virtualized';
import { Filter, FilterMap } from '../../../types';
import InfiniteMultiGrid from '../multigrid/InfiniteMultiGrid';
import { MultiGridHandleSortParams } from '../multigrid';

type FillBlotterTableProps = {
  fetchNextPage: (indices: IndexRange) => Promise<void>
  fills: Array<Fill>
  fillsCount: number
  fillsQueryLoading: boolean
  filters?: FilterMap
  height?: number
  handleSort?: (params: MultiGridHandleSortParams) => void
  selectedOrderId?: string
  setFilter: (c: string) => (f: Filter) => void
  sortParams: SortParams
}

const FillBlotterTable: React.FC<FillBlotterTableProps> = ({
  fetchNextPage,
  fills,
  fillsCount,
  fillsQueryLoading,
  filters,
  height,
  handleSort,
  selectedOrderId,
  setFilter,
  sortParams,
}) => {
  const columns = useMemo(() => getFillColumns(selectedOrderId == null), [selectedOrderId]);

  return (
    <InfiniteMultiGrid
      columns={columns}
      data={fills}
      headerHeight={TABLE_HEADER_HEIGHT}
      height={height}
      filters={filters}
      fixedRowCount={1}
      setFilter={setFilter}
      isNextPageLoading={fillsQueryLoading}
      loadNextPage={fetchNextPage}
      onSort={handleSort}
      remoteRowCount={fillsCount}
      rowHeight={ROW_HEIGHT}
      sortBy={sortParams.orderParameter}
      sortDirection={sortParams.orderDirection}
    />
  );
}

export default React.memo(FillBlotterTable);
