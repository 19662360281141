const cryptoSectionCopy = {
  addCustodian: 'Add Custodian',
  errorMessageCreateCustodianFailed:
    'An error occured while attempting to add your new custodian. Please try again.',
  errorMessageCreateInstructionFailed:
    'An error occured while attempting to add your new instruction. Please try again.',
  errorMessageCreateManualAccountFailed:
    'An error occured while attempting to add your new wallet. Please try again.',
  errorMessageDeleteInstructionFailed:
    'An error occured while attempting to delete the selected instruction. Please try again.',
  errorMessageUpdateAccountFailed:
    'An error occured while attempting to update your wallet. Please try again.',
  errorMessageUpdateCustodianFailed:
    'An error occured while attempting to update your custodian. Please try again.',
  errorMessageUpdateInstructionFailed:
    'An error occured while attempting to update the selected instruction. Please try again.',
  nullCustodian: 'Self Custody',
  sectionTitle: 'Crypto',
  showAccountFormTabLabel: 'Add Wallet',
  successMessageCreateCustodian:
    'Your new custodian was successfully created.',
  successMessageCreateInstruction:
    'Your new instruction was successfully created.',
  successMessageCreateManualAccount:
    'Your new wallet was successfully created.',
  successMessageDeleteInstruction:
    'The selected instruction was successfully deleted.',
  successMessageUpdateAccount: 'Your wallet was successfully updated.',
  successMessageUpdateCustodian: 'Your custodian was successfully updated.',
  successMessageUpdateInstruction:
    'The selected instruction was successfully updated.'
};

export default cryptoSectionCopy;
