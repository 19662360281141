export const blotterCopyText = {
  cancelOrder: 'Cancel Order',
  resetFilters: 'Reset Filters',
};

export const selectedOrderCopyText = {
  orderId: 'Order ID',
  emsId: 'EMS Order ID',
  created: 'Order Created',
  completed: 'Order Completed',
  reqDuration: 'Requested Duration',
  actDuration: 'Actual Duration',
  trader: 'Trader',
  portfolio: 'Portfolio',
  venue: 'Venue(s)',
  instrument: 'Instrument',
  side: 'Side',
  qtyOrdered: 'Quantity Ordered',
  qtyFilled: 'Quantity Filled',
  execType: 'Execution Type',
  orderType: 'Order Type',
  tif: 'Time in Force',
  participation: 'Participation Rate',
  displayQty: 'Display Quantity',
  stop: 'Stop Price',
  limit: 'Limit Price',
  avgFillPrice: 'Average Fill Price',
  fees: 'Total Fees',
};

export default blotterCopyText;
