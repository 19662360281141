import gql from 'graphql-tag';

export default gql`
  mutation updateUserAppState(
    $lastNotificationCheckTime: String,
    $otc: OTCDataInput
  ) {
    user: updateUserAppState(
      lastNotificationCheckTime: $lastNotificationCheckTime,
      otc: $otc,
    ) {
      id
      appState {
        lastNotificationCheckTime
        otc {
          templates {
              id
              instrumentId
              orderType
              quantity
              venueIds
            }
          tiles {
            id
            instrumentId
            orderType
            quantity
            venueIds
            limitPrice
            stopPrice
          }
        }
      }
    }
  }
`;
