const dmaExecutionFormCopy = {
  cancelButtonLabel: 'Cancel',
  contractsLabel: 'contract',
  inputPlaceholder_customOrderId: 'Client Order ID (Optional)',
  inputPlaceholder_instrumentDisplayName: 'Instrument',
  inputPlaceholder_limitPrice: 'Limit Price',
  inputPlaceholder_orderType: 'Order Type',
  inputPlaceholder_quantity: 'Quantity',
  inputPlaceholder_stopPrice: 'Stop Price',
  inputPlaceholder_venueId: 'Venue',
  orderSideBuy: 'BUY',
  orderSideSell: 'SELL',
  orderSuccessButtonLabel: 'Order Placed',
  proceedButtonLabel: 'Place Order',
  projectedTotalPrice: 'Projected Total Price: %marketValue%',
  quantityUnitFutures: 'Cont.',
  submitButtonLabel: 'Place Order',
  warningMessage_quantityBelow:
    'Your order of %quantity% %instrument% is below the %venue% minimum of %minSize%. Please increase the quantity to a value greater than or equal to %minSize% %baseCurrency%.',
  warningMessage_quantityAbove:
    'Your order of %quantity% %instrument% is above the %venue% maximum of %maxSize%. Please decrease the quantity to a value less than or equal to %maxSize% %baseCurrency%.',
  warningMessage_quantityExceedsBasePositionQuantity:
    'Your order to sell %quantity% %unit% of %instrument% exceeds the amount you currently hold. Please decrease the quantity to a value less than or equal to %basePositionQuantity% contracts.',
  warningMessage_limitPriceBelow:
    'Your limit price of %limitPrice% %termCurrency% is below the %venue% minimum of %minPrice%. Please increase the price to a value greater than or equal to %minPrice% %termCurrency%.',
  warningMessage_limitPriceAbove:
    'Your limit price of %limitPrice% %termCurrency% is above the %venue% maximum of %maxPrice%. Please decrease the price to a value less than or equal to %maxPrice% %termCurrency%.',
  warningMessage_stopPriceBelow:
    'Your stop price of %stopPrice% %termCurrency% is below the %venue% minimum of %minPrice%. Please increase the price to a value greater than or equal to %minPrice% %termCurrency%.',
  warningMessage_stopPriceAbove:
    'Your stop price of %stopPrice% %termCurrency% is above the %venue% maximum of %maxPrice%. Please decrease the price to a value less than or equal to %maxPrice% %termCurrency%.',
  warningMessage_termSizeBelow:
    'Based on estimated average execution price, your order of %quantity% %instrument% does not meet the %venue% minimum term size of %minTermSize% %termCurrency%. Please adjust the order to meet the term size requirements to avoid potential rejection by the exchange.',
  warningMessage_limitPriceTrunc:
    'Your limit price of %preTruncPrice% has been truncated to %limitPrice% to meet the minimum price increment allowed by the exchange.',
  warningMessage_stopPriceTrunc:
    'Your stop price of %preTruncPrice% has been truncated to %stopPrice% to meet the minimum price increment allowed by the exchange.'
};

export default dmaExecutionFormCopy;
