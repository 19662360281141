import * as React from 'react'
import { Icon, Input, Popup } from 'semantic-ui-react'
import { makeStyles } from '@omniex/poms-ui/lib/themes'
import { colors } from '@omniex/onx-common-ui/lib/styles'
import config from '../../config'

const semanticStyles = {
  container: {
    fontSize: '0.9em',
    padding: '8px 16px',
  }
}

const useStyles = makeStyles({
  icon: {
    color: colors.warningColor,
    margin: '0.4em 0em 0.4em 0.4em'
  },
  inputWithWarning: {
    display: 'flex'
  },
  label: {
    fontSize: '0.9em'
  },
  container: {
    fontSize: '0.9em',
    padding: '8px 16px',
  }
}, { name: 'InputWithWarning' })

const InputWithWarning = ({ name = '', placeholder = '', value = '', warning = '', onChange = _ => _ }) => {
  const cls = useStyles()
  return (
    <div className={cls.inputWithWarning}>
      <Input
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      {config.enableInputWarnings && (
        <Popup
          flowing
          hoverable
          hideOnScroll
          inverted
          style={semanticStyles.container}
          trigger={<div className={cls.icon}><Icon name='warning sign' size='large'/></div>}>
          <div className={cls.label}>{warning}</div>
        </Popup>
      )}
    </div>
  )
}

export default InputWithWarning
