import { func, number } from 'prop-types';
import { decode } from 'jsonwebtoken';
import axios from 'axios';
import React, { PureComponent } from 'react';

import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import config from '../config';
import TokenExpirationModal from '../ui/components/TokenExpirationModal';

const COMPONENT_NAME = 'TokenExpirationProvider';

const CHECK_ACTIVITY_INTERVAL = 180; // 3 minutes (dev 10 seconds)
const MIN_RECORD_ACTIVITY_INTERVAL = 10; // Throttles recording activity events (seconds)
const SESSION_EXPIRATION_WARNING_SECONDS = 300; // 5 Minutes

axios.defaults.withCredentials = true;

export default class TokenExpirationProvider extends PureComponent {
  static propTypes = {
    maintenanceDate: number,
    onLogout: func
  };

  static defaultProps = { onLogout: noop };

  state = { showTokenModal: false };

  componentDidMount() {
    this._mostRecentActivity = Math.floor(new Date().getTime() / 1000);
    this._refreshToken();

    this._initializeActivityListeners();
    this._initializeTokenRefreshInterval();
  }

  render() {
    return (
      <TokenExpirationModal
        className={COMPONENT_NAME}
        expirationTime={this.state.tokenExp}
        show={this.state.showTokenModal}
        onLogout={this.props.onLogout}
        onRefreshToken={() => {
          this._handleClickRefreshToken();
        }}
      />
    );
  }

  _handleClickRefreshToken() {
    this._refreshToken();
    this.setState({ showTokenModal: false });
  }

  _initializeActivityListeners() {
    document.addEventListener('mousemove', event => {
      this._registerActivity();
    });
    document.addEventListener('click', event => {
      this._registerActivity();
    });
  }

  _initializeTokenRefreshInterval() {
    this.tokenRefreshInterval = setInterval(async () => {
      if (this.state.showTokenModal) return;
      clearTimeout(this.tokenExpirationModalTimer);

      const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
      const tokenIAT = this.tokenIAT || 0;
      const tokenExp = this.state.tokenExp || Infinity; // Default future timestamp

      const tokenAge = currentTimeInSeconds - tokenIAT;
      const timeSinceLastActivity =
        currentTimeInSeconds - this._mostRecentActivity;

      const secondsUntilSessionWarning =
        tokenExp - SESSION_EXPIRATION_WARNING_SECONDS - currentTimeInSeconds;

      const secondsUntilMaintenanceRedirect = Math.floor(
        this.props.maintenanceDate / 1000 - currentTimeInSeconds
      );

      // Early exit if token is going to expire at maintenance window
      if (
        Math.abs(
          secondsUntilMaintenanceRedirect -
            secondsUntilSessionWarning -
            SESSION_EXPIRATION_WARNING_SECONDS
        ) < 2 // 2 second grace period difference between the two times
      ) {
        return;
      }

      // Fetch New Token
      if (timeSinceLastActivity < tokenAge) {
        this._refreshToken();
      } else {
        // Show Warning Modal
        this.tokenExpirationModalTimer = setTimeout(() => {
          this.setState({ showTokenModal: true });
        }, secondsUntilSessionWarning * 1000);
      }
    }, CHECK_ACTIVITY_INTERVAL * 1000);
  }

  _registerActivity() {
    if (this.state.showTokenModal) return;

    const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
    if (
      currentTimeInSeconds - this._mostRecentActivity >
      MIN_RECORD_ACTIVITY_INTERVAL
    ) {
      clearTimeout(this.tokenExpirationModalTimer);
      this._mostRecentActivity = currentTimeInSeconds;
    }
  }

  async _refreshToken() {
    try {
      let response = await axios.post(
        config.login.baseUrl.slice(0, config.login.baseUrl.lastIndexOf('/')) +
          '/refresh-token',
        {}
      );

      const decodedToken = decode(get(response, 'data.token'));
      this.tokenIAT = decodedToken.iat;

      this.setState({
        showTokenModal: false,
        tokenExp: decodedToken.exp
      });
    } catch (error) {
      window.location.href = window.encodeURI(
        `${config.login.baseUrl}?redirect=${window.location.href}`
      );
    }
  }

  componentWillUnmount() {
    clearTimeout(this.tokenExpirationModalTimer);
    clearInterval(this.tokenRefreshInterval);
  }
}
