import { useQuery, useMutation, useSubscription } from 'react-apollo';
import React from 'react';

import { ERROR_PERMISSION_DENIED } from '@omniex/onx-common-ui/lib/errors';
import { safeExecAsync } from '../utils/FunctionUtils';
import acceptSettlementMutation from '../apollo/graphql/acceptSettlement';
import advanceAllocationOnConfirmMutation from '../apollo/graphql/advanceAllocationOnConfirm';
import ErrorPage from '@omniex/onx-common-ui/lib/pages/ErrorPage';
import getBuySideCsvData from '../apollo/graphql/buy-side/getBuySideCsvData';
import getSellSideCsvData from '../apollo/graphql/sell-side/getSellSideCsvData';
import getCurrencies from '../apollo/graphql/getCurrencies';
import getInstruments from '../apollo/graphql/getInstruments';
import getOrg from '../apollo/graphql/getOrg';
import getPortfolio from '../apollo/graphql/getPortfolio';
import getSettlements from '../apollo/graphql/getSettlements';
import getUnsettledOtcFills from '../apollo/graphql/getUnsettledOtcFills';
import initiateSettlementMutation from '../apollo/graphql/initiateSettlement';
import OrgType from '@omniex/poms-core/lib/enums/OrgType';
import refreshSettlements from '../apollo/graphql/refreshSettlements';
import refreshUnsettledOtcFills from '../apollo/graphql/refreshUnsettledOtcFills';
import rejectSettlementMutation from '../apollo/graphql/rejectSettlement';
import releaseFillsMutation from '../apollo/graphql/releaseFills';
import SettlementPage from '../ui/pages/SettlementPage';

const SettlementPageWithAuth = ({apiClient, fixApiClient, user, portfolio = {} }) => {
  const orgId = user?.org?.id
  const portfolioId = portfolio?.id

  const useApiQuery = (q, o) => useQuery(q, { client: apiClient, ...o })
  const useClientQuery = (q, o) => useQuery(q, { client: fixApiClient, ...o })
  const useClientMutation = (m, o) => useMutation(m, { client: fixApiClient, ...o })
  const useClientSubscription = (s, o) => useSubscription(s, { client: fixApiClient, ...o })

  const qCurrencies = useApiQuery(getCurrencies)
  const qInstruments = useApiQuery(getInstruments)
  const qOrg = useApiQuery(getOrg, {skip: !orgId})
  const qPortfolio = useApiQuery(getPortfolio, {skip: !portfolioId, variables: {id: portfolioId}})

  const csvQuery = user?.org?.type === OrgType.CLIENT_SELL_SIDE ? getSellSideCsvData : getBuySideCsvData
  const csvVars = {orderParameter: 'timeCreated', orderDirection: 'desc'}
  const qCsvData = useClientQuery(csvQuery, {fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true, variables: csvVars})

  const [acceptSettlement, mAcceptSettlement] = useClientMutation(acceptSettlementMutation)
  const [advanceAllocationOnConfirm, mAdvanceAllocationOnConfirm] = useClientMutation(advanceAllocationOnConfirmMutation)
  const [initiateSettlement, mInitiateSettlement] = useClientMutation(initiateSettlementMutation)
  const [rejectSettlement, mRejectSettlement] = useClientMutation(rejectSettlementMutation)
  const [releaseFills, mReleaseFills] = useClientMutation(releaseFillsMutation)

  const qUnsettledFills = useClientQuery(getUnsettledOtcFills, {fetchPolicy: 'network-only'})
  const sUnsettledFills = useClientSubscription(refreshUnsettledOtcFills)
  const qSettlements = useClientQuery(getSettlements, {fetchPolicy: 'network-only'})
  const sSettlements = useClientSubscription(refreshSettlements)

  return (
    <SettlementPage
      acceptSettlement={variables => safeExecAsync(acceptSettlement, {variables})}
      acceptSettlementMutationError={mAcceptSettlement?.error}
      acceptSettlementMutationProcessing={mAcceptSettlement?.loading}
      acceptSettlementMutationResult={mAcceptSettlement?.data}
      advanceAllocationOnConfirm={variables => safeExecAsync(advanceAllocationOnConfirm, {variables})}
      advanceAllocationOnConfirmMutationError={mAdvanceAllocationOnConfirm?.error}
      advanceAllocationOnConfirmMutationProcessing={mAdvanceAllocationOnConfirm?.loading}
      advanceAllocationOnConfirmMutationResult={mAdvanceAllocationOnConfirm?.data}
      csvDataQueryData={qCsvData?.data}
      csvDataQueryError={qCsvData?.error}
      csvDataQueryLoading={qCsvData?.loading}
      currenciesQueryData={qCurrencies?.data}
      currenciesQueryError={qCurrencies?.error}
      currenciesQueryLoading={qCurrencies?.loading}
      fetchCsvData={args => safeExecAsync(qCsvData?.refetch, args)}
      initiateSettlement={variables => safeExecAsync(initiateSettlement, {variables})}
      initiateSettlementMutationError={mInitiateSettlement?.error}
      initiateSettlementMutationProcessing={mInitiateSettlement?.loading}
      initiateSettlementMutationResult={mInitiateSettlement?.data}
      instrumentsQueryData={qInstruments?.data}
      instrumentsQueryError={qInstruments?.error}
      instrumentsQueryLoading={qInstruments?.loading}
      orgQueryData={qOrg?.data}
      orgQueryError={qOrg?.error}
      orgQueryLoading={qOrg?.loading}
      portfolioQueryData={qPortfolio?.data}
      portfolioQueryError={qPortfolio?.error}
      portfolioQueryLoading={qPortfolio?.loading}
      rejectSettlement={variables => safeExecAsync(rejectSettlement, {variables})}
      rejectSettlementMutationError={mRejectSettlement?.error}
      rejectSettlementMutationProcessing={mRejectSettlement?.loading}
      rejectSettlementMutationResult={mRejectSettlement?.data}
      releaseFills={variables => safeExecAsync(releaseFills, {variables})}
      releaseFillsMutationError={mReleaseFills?.error}
      releaseFillsMutationProcessing={mReleaseFills?.loading}
      releaseFillsMutationResult={mReleaseFills?.data}
      settlementsQueryData={qSettlements?.data}
      settlementsQueryError={qSettlements?.error}
      settlementsQueryLoading={qSettlements?.loading}
      settlementsSubscriptionData={sSettlements?.data}
      settlementsSubscriptionError={sSettlements?.error}
      settlementsSubscriptionLoading={sSettlements?.loading}
      unsettledOtcFillsQueryData={qUnsettledFills?.data}
      unsettledOtcFillsQueryError={qUnsettledFills?.error}
      unsettledOtcFillsQueryLoading={qUnsettledFills?.loading}
      unsettledOtcFillsSubscriptionData={sUnsettledFills?.data}
      unsettledOtcFillsSubscriptionError={sUnsettledFills?.error}
      unsettledOtcFillsSubscriptionLoading={sUnsettledFills?.loading}
      user={user}
    />
  )
}

const SettlementPageContainer = ({user, ...props}) => user?.canViewSettlement
  ? <SettlementPageWithAuth user={user} {...props} /> 
  : <ErrorPage type={ERROR_PERMISSION_DENIED} />

export default SettlementPageContainer
