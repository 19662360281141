import gql from 'graphql-tag';

export default gql`
  mutation cancelOrder($id: ID!) {
    order: cancelOrder(id: $id) {
      id
      fixVenueOrderID
    }
  }
`;
