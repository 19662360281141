import { createSelector } from 'reselect';
import { generateCurrencyKey } from '../utils/AccountUtils';

import getCurrenciesKeyedById from './getCurrenciesKeyedById';

export function populateAccountsWithCurrency(accounts, currencies) {
  if (!Array.isArray(accounts) || accounts.length === 0) return [];

  const currenciesKeyedById = getCurrenciesKeyedById(currencies);

  return accounts.map(account => ({
    ...account,
    currency:
      currenciesKeyedById[generateCurrencyKey(account)] || account.currency
  }));
}

export default createSelector(
  (accounts, currencies) => accounts,
  (accounts, currencies) => currencies,
  populateAccountsWithCurrency
);
