import gql from 'graphql-tag';

export default gql`
  mutation updateTradeSettings($input: TradeSettingsInput!) {
    user: updateTradeSettings(input: $input) {
      id
      settings {
        trade {
          executeOnSingleClickOTC
        }
      }
    }
  }
`;
