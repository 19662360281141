import * as React from 'react';
import { debounce } from 'lodash';

const MAX_WAIT_MULTIPLIER = 3;

export function useTrigger<T extends (...args: any[]) => any>(
  trigger: string | undefined,
  callback: T,
  ...args: Parameters<T>
): void {
  const [lastTrigger, setLastTrigger] = React.useState('');

  const execRefetch = React.useCallback(async () => {
    try {
      await callback(...args);
    } catch(e) {
      console.error(e);
    }
  }, [callback, args]);

  React.useEffect(() => {
    if (trigger && trigger !== lastTrigger) {
      setLastTrigger(trigger);
      execRefetch();
    }
  }, [lastTrigger, trigger, execRefetch]);
}

export function useConditionalTrigger<T extends (...args: any[]) => any>(
  trigger: string | undefined,
  callback: T,
  conditionOrConditions: boolean | boolean[],
  ...args: Parameters<T>
): void {
  const [lastTrigger, setLastTrigger] = React.useState('');

  const execRefetch = React.useCallback(async () => {
    try {
      await callback(...args);
    } catch(e) {
      console.error(e);
    }
  }, [callback, args]);

  React.useEffect(() => {
    if (trigger && trigger !== lastTrigger) {
      setLastTrigger(trigger);
      if (Array.isArray(conditionOrConditions) ? conditionOrConditions.every(c => c) : conditionOrConditions) {
        execRefetch();
      }
    }
  }, [lastTrigger, trigger, execRefetch, conditionOrConditions]);
}

export function useDebouncedTrigger<T extends (...args: any[]) => Promise<any>>(
  trigger: string | undefined,
  callback: T,
  wait?: number,
  ...args: Parameters<T>
): void {
  const opts = React.useMemo(() => wait ? { maxWait: wait * MAX_WAIT_MULTIPLIER } : undefined, [wait]);
  const debouncedFn = React.useCallback(debounce(callback, wait, opts), [callback, opts, wait]);

  useTrigger(trigger, debouncedFn, ...args);
}

export function useEnhancedTrigger<T extends (...args: any[]) => Promise<any>>(
  trigger: string | undefined,
  callback: T,
  conditionOrConditions: boolean | boolean[],
  wait?: number,
  ...args: Parameters<T>
): void {
  const opts = React.useMemo(() => wait ? { maxWait: wait * 3 } : undefined, [wait]);
  const debouncedFn = React.useCallback(debounce(callback, wait, opts), [callback, opts, wait]);

  useConditionalTrigger(trigger, debouncedFn, conditionOrConditions, ...args);
}
