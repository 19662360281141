import { createSelector } from 'reselect';
import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import getCurrenciesKeyedById from './getCurrenciesKeyedById';
import getInstrumentsKeyedById from './getInstrumentsKeyedById';
import populateInstrumentsWithCurrencies from './populateInstrumentsWithCurrencies';

export function populateUnsettledFills(fills, currencies, instruments) {
  if (
    !Array.isArray(fills) ||
    fills.length === 0 ||
    !Array.isArray(currencies) ||
    currencies.length === 0 ||
    !Array.isArray(instruments) ||
    instruments.length === 0
  ) {
    return [];
  }

  const currenciesKeyedById = getCurrenciesKeyedById(currencies);

  instruments = populateInstrumentsWithCurrencies(instruments, currencies);
  const instrumentsKeyedById = getInstrumentsKeyedById(instruments);

  return fills.map(fill => {
    const instrument = instrumentsKeyedById[get(fill, 'order.instrument.id')];

    // NOTE: Might not need filledQuantity after demo
    const filledQuantity =
      get(fill, 'specifiedCurrency.id') === get(instrument, 'baseCurrency.id')
        ? fill.price * fill.quantity
        : fill.quantity / fill.price;

    return {
      ...fill,
      filledQuantity,
      order: {
        ...fill.order,
        instrument
      },
      specifiedCurrency: currenciesKeyedById[fill.specifiedCurrency.id]
    };
  });
}

export default createSelector(
  (fills, currencies, instruments) => fills,
  (fills, currencies, instruments) => currencies,
  (fills, currencies, instruments) => instruments,
  populateUnsettledFills
);
