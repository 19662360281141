const claimedQuoteRequestsTableCopyText = {
  cancelButtonLabel: 'Cancel',
  label2Way: '2-WAY',
  popupHeaderClientOrderID: 'Client Order ID',
  popupHeaderEMSOrderID: 'EMS Order ID',
  tableHeaderBidPrice: 'Bid Price',
  tableHeaderBidQuantity: 'Bid Quantity',
  tableHeaderClient: 'Client',
  tableHeaderInstrument: 'Instrument',
  tableHeaderOfferPrice: 'Offer Price',
  tableHeaderOfferQuantity: 'Offer Quantity',
  tableHeaderOrderID: 'Order ID',
  tableHeaderQuantity: 'Quantity',
  tableHeaderQuoteID: 'Quote ID',
  tableHeaderSide: 'Side',
  tableHeaderTimestamp: 'Timestamp',
  warningMessage: 'You are not working on any quote requests at this time.'
};

export default claimedQuoteRequestsTableCopyText;
