import { arrayOf, func, number, shape, string } from 'prop-types';
import { createSelector } from 'reselect';
import React, { Component, Fragment } from 'react';
import styled from 'react-emotion';

import { cumulativeSum } from '@omniex/poms-core/lib/utils/NumberUtils';
import { get } from '@omniex/poms-core/lib/utils/ObjectUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import { getPriceFormat } from '../../utils/DisplayUtils';
import { keyBy } from '@omniex/poms-core/lib/utils/CollectionUtils';
import { noop } from '@omniex/poms-core/lib/utils/FunctionUtils';
import { sortBy } from '@omniex/poms-core/lib/utils/CollectionUtils';
import copyText from './OrderBookTable.copyText';
import AssetType from '@omniex/poms-core/lib/enums/AssetType';
import MarketDataEntryType from '@omniex/poms-core/lib/enums/MarketDataEntryType';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import OrderAlgorithmStrategy from '@omniex/poms-core/lib/enums/OrderAlgorithmStrategy';
import OrderFeature from '@omniex/poms-core/lib/enums/OrderFeature';
import Table, {
  CELL_FORMAT_NUMBER
} from '@omniex/onx-common-ui/lib/semantic/react/Table';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/message.css');
require('@omniex/onx-common-ui/lib/semantic/css/table.css');

const COMPONENT_NAME = 'OrderBookTable';
const StyledDiv = styled('div')`
  padding: 14px;
`;

const StyledTable = styled(Table)`

  .${COMPONENT_NAME}-quantityOffers {
    text-align: left !important;
    padding: 0.3em !important;
  }
  .${COMPONENT_NAME}-entry {
    height: 25px;
    padding: 0.30em !important;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class OrderBookTable extends Component {
  static propTypes = {
    executionType: string.isRequired,
    algorithmStrategy: string,
    instrument: shape({
      id: string.isRequired,
      baseAsset: shape({
        id: string.isRequired,
        symbol: string.isRequired,
        type: string.isRequired
      }).isRequired,
      termAsset: shape({
        id: string.isRequired,
        symbol: string.isRequired,
        type: string.isRequired
      }).isRequired
    }),
    entries: arrayOf(
      shape({
        id: string.isRequired,
        fixMDStreamID: string.isRequired,
        price: number.isRequired,
        quantity: number.isRequired,
        type: string.isRequired,
        venueSymbol: string.isRequired,
      })
    ),
    onSelect: func,
    venues: arrayOf(
      shape({
        id: string.isRequired,
        name: string.isRequired,
        symbol: string.isRequired,
      })
    ),
  };

  static defaultProps = { onSelect: noop };

  render() {
    let entries = get(this.props, 'entries', []);

    if (entries.length === 0) {
      return (
        <StyledDiv className={COMPONENT_NAME}>
          <Message content={copyText.warningMessage} warning />
        </StyledDiv>
      );
    }

    entries = sortBy(entries, 'price');

    const type = entries[0].type;

    entries = type === MarketDataEntryType.OFFER ? entries : entries.reverse();

    entries = entries.slice(0, 50);

    const venuesMap = keyBy(get(this.props, 'venues', []), 'symbol');

    const showCumulativeQuantity = _showCumulativeQuantity(
      this.props.executionType,
      this.props.algorithmStrategy
    );

    const cumulativeQuantities = showCumulativeQuantity
      ? _generateCumulativeQuantities(entries)
      : [];

    return (
      <StyledTable className={COMPONENT_NAME}>
        <Table.Header>
          <Table.Row>
            {this.props.executionType === OrderFeature.ALGO &&
            type === MarketDataEntryType.BID ? (
              <Table.HeaderCell>{copyText.headerVenue}</Table.HeaderCell>
            ) : null}
            {type === MarketDataEntryType.BID && showCumulativeQuantity ? (
              <Table.HeaderCell textAlign="right">
                {`${copyText.headerCumulativeQuantity} (${getAssetDisplayText(
                  get(this.props, 'instrument.baseAsset')
                )})`}
              </Table.HeaderCell>
            ) : null}
            <Table.HeaderCell
              textAlign={type === MarketDataEntryType.BID ? 'right' : 'left'}>
              {type === MarketDataEntryType.BID
                ? `${copyText.headerQuantity} (${getAssetDisplayText(
                  get(this.props, 'instrument.baseAsset')
                  )})`
                : `${copyText.headerOfferPrice} (${getAssetDisplayText(
                  get(this.props, 'instrument.termAsset')
                  )})`}
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign={type === MarketDataEntryType.BID ? 'right' : 'left'}>
              {type === MarketDataEntryType.BID
                ? `${copyText.headerBidPrice} (${getAssetDisplayText(
                  get(this.props, 'instrument.termAsset')
                  )})`
                : `${copyText.headerQuantity} (${getAssetDisplayText(
                  get(this.props, 'instrument.baseAsset')
                  )})`}
            </Table.HeaderCell>
            {type === MarketDataEntryType.OFFER && showCumulativeQuantity ? (
              <Table.HeaderCell>
                {`${copyText.headerCumulativeQuantity} (${getAssetDisplayText(
                  get(this.props, 'instrument.baseAsset')
                )})`}
              </Table.HeaderCell>
            ) : null}
            {this.props.executionType === OrderFeature.ALGO &&
            type === MarketDataEntryType.OFFER ? (
              <Table.HeaderCell textAlign="right">
                {copyText.headerVenue}
              </Table.HeaderCell>
            ) : null}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {entries.map((entry, idx) =>
            this._renderTableRow(entry, type, cumulativeQuantities[idx], venuesMap)
          )}
        </Table.Body>
      </StyledTable>
    );
  }

  _renderTableRow(entry, type, cumulativeQuantity, venuesMap) {
    const showCumulativeQuantity = _showCumulativeQuantity(
      this.props.executionType,
      this.props.algorithmStrategy
    );

    return (
      <Table.Row
        className={cn('entry')}
        key={entry.id}
        onClick={this._handleClickRow.bind(this, entry, cumulativeQuantity)}>
        {type === MarketDataEntryType.BID ? (
          <Fragment>
            {this.props.executionType === OrderFeature.ALGO
              ? this._renderVenueCell(entry, type, venuesMap)
              : null}
            {showCumulativeQuantity
              ? this._renderQuantityCell({ quantity: cumulativeQuantity }, type)
              : null}
            {this._renderQuantityCell(entry, type)}
            {this._renderPriceCell(entry, type)}
          </Fragment>
        ) : (
          <Fragment>
            {this._renderPriceCell(entry, type)}
            {this._renderQuantityCell(entry, type)}
            {showCumulativeQuantity
              ? this._renderQuantityCell({ quantity: cumulativeQuantity }, type)
              : null}
            {this.props.executionType === OrderFeature.ALGO
              ? this._renderVenueCell(entry, type, venuesMap)
              : null}
          </Fragment>
        )}
      </Table.Row>
    );
  }

  _renderPriceCell(entry, type) {
    return (
      <Table.Cell
        className={type === MarketDataEntryType.BID ? cn('entry') : cn('quantityOffers')}
        format={getPriceFormat(entry.price)}
        formatType={CELL_FORMAT_NUMBER}>
        {entry.price}
      </Table.Cell>
    );
  }

  _renderQuantityCell(entry, type) {
    const format = {
      [AssetType.FIAT]: '0,0.00',
      [AssetType.CRYPTO]: '0,0.00000000',
      [AssetType.FUTURE]: '0,0000',
      [AssetType.OPTION]: '0,0'
    }[get(this.props, 'instrument.baseAsset.type')] || '0,0.00000000';

    return (
      <Table.Cell
        className={type === MarketDataEntryType.BID ? cn('entry') : cn('quantityOffers')}
        textAlign={type === MarketDataEntryType.BID ? 'right' : 'left'}
        format={format}>
        {entry.quantity}
      </Table.Cell>
    );
  }

  _renderVenueCell(entry, type, venuesMap) {
    const venueName = get(venuesMap, `${entry.venueSymbol}.name`, 'N/A');

    return (
      <Table.Cell
        className={cn('entry')}
        textAlign={type === MarketDataEntryType.BID ? 'left' : 'right'}>
        {venueName}
      </Table.Cell>
    );
  }

  _handleClickRow(entry, cumulativeQuantity) {
    this.props.onSelect({ ...entry, cumulativeQuantity });
  }
}

const _showCumulativeQuantity = createSelector(
  (execType, algo) => execType,
  (execType, algo) => algo,
  (execType, algo) =>
    execType === OrderFeature.DMA ||
    (execType === OrderFeature.ALGO &&
      [OrderAlgorithmStrategy.SOR, OrderAlgorithmStrategy.ICE].includes(algo))
);

const _generateCumulativeQuantities = createSelector(
  entries => entries,
  entries => cumulativeSum(entries.map(entry => entry.quantity))
);
