import { arrayOf, bool, number, oneOf, shape, string } from 'prop-types';
import { Loader, Segment, Menu } from 'semantic-ui-react';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { get, has } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getPositions } from '../../utils/PositionUtils';
import copyText from './DashboardPagePortfolioPositionsSection.copyText';
import AssetType from '@omniex/poms-core/lib/enums/AssetType';
import ErrorBoundary from '../components/ErrorBoundary';
import generateCurrentPriceLookupTable from '../../selectors/generateCurrentPriceLookupTable';
import populateAccountsWithCurrency from '../../selectors/populateAccountsWithCurrency';
import PortfolioPositionsTable from '../components/PortfolioPositionsTable';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/header.css');
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/loader.css');
require('@omniex/onx-common-ui/lib/semantic/css/segment.css');

const COMPONENT_NAME = 'DashboardPagePortfolioPositionsSection';

const StyledSection = styled('section')`
  .${COMPONENT_NAME}-icon {
    font-size: 1em !important;
    padding: 2px !important;
  }

  .${COMPONENT_NAME}-componentWrapper {
    height: 351px;

    &.withScroll {
      overflow: auto;
    }
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class DashboardPagePortfolioPositionsSection extends PureComponent {
  static propTypes = {
    accountsWithBalancesQueryData: shape({
      accounts: arrayOf(
        shape({
          id: string.isRequired,
          balanceAtCustodianAsReportedTotal: number,
          temporaryCalculatedBalance: number
        }).isRequired
      )
    }),
    accountsWithBalancesQueryLoading: bool,
    currenciesQueryData: shape({
      currencies: arrayOf(
        shape({
          id: string.isRequired,
          type: oneOf(Object.values(AssetType)).isRequired,
          symbol: string.isRequired
        })
      )
    }),
    currenciesQueryLoading: bool,
    currentPricesQueryData: shape({
      currentPrices: arrayOf(
        shape({
          baseCurrency: shape({ id: string.isRequired }).isRequired,
          price: number.isRequired
        })
      )
    }),
    currentPricesQueryLoading: bool,
    orgQueryData: shape({
      org: shape({
        homeCurrency: shape({
          id: string.isRequired
        }).isRequired
      })
    }),
    orgQueryLoading: bool
  };

  render() {
    const loading =
      this.props.currenciesQueryLoading ||
      !has(this.props, 'currenciesQueryData.currencies') ||
      this.props.currentPricesQueryLoading ||
      !has(this.props, 'currentPricesQueryData.currentPrices') ||
      this.props.orgQueryLoading ||
      !has(this.props, 'orgQueryData.org') ||
      this.props.accountsWithBalancesQueryLoading ||
      !has(this.props, 'accountsWithBalancesQueryData.accounts');

    const accounts = populateAccountsWithCurrency(
      get(this.props, 'accountsWithBalancesQueryData.accounts'),
      get(this.props, 'currenciesQueryData.currencies')
    );

    const aggregatePositions = getPositions(accounts);

    const currentPriceLookupTable = generateCurrentPriceLookupTable(
      get(this.props, 'currentPricesQueryData.currentPrices'),
      get(this.props, 'orgQueryData.org.homeCurrency.id')
    );

    return (
      <StyledSection className={COMPONENT_NAME}>
        <Menu attached="top" style={{ height: 46 }}>
          <Menu.Item header icon="table" content={copyText.sectionTitle} />
        </Menu>
        <Segment attached="bottom">
          <div className={cn('componentWrapper withScroll')}>
            {loading ? (
              <Loader active content={copyText.loadingMessage} size="medium" />
            ) : (
              <ErrorBoundary message={copyText.error}>
                <PortfolioPositionsTable
                  currentPriceLookupTable={currentPriceLookupTable}
                  homeCurrency={get(this.props, 'orgQueryData.org.homeCurrency')}
                  onDashboardPage={true}
                  positions={aggregatePositions}
                />
              </ErrorBoundary>
            )}
          </div>
        </Segment>
      </StyledSection>
    );
  }
}
