import React from 'react';

import { displayInHomeCurrency, getFiatSymbol } from '../../utils/DisplayUtils';
import { format } from '@omniex/onx-common-js/lib/utils/NumberUtils';
import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import { groupBy, sortBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import { isEmpty } from '@omniex/onx-common-js/lib/utils/LangUtils';
import AccountType from '@omniex/onx-poms-entity-helpers/lib/enums/AccountType';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './PortfolioBreakdownChart.copyText.js';
import AssetType from '@omniex/poms-core/lib/enums/AssetType';
import HighchartsAdapter from '../../ext/react-highcharts';
import BaseMessage from '@omniex/onx-common-ui/lib/semantic/react/Message';

// NOTE: The order of these imports matters. Do not change.
import '@omniex/onx-common-ui/lib/semantic/css/message.css';

export const GROUPING_ATTRIBUTE_CURRENCY = 'account.currency.id';
export const GROUPING_ATTRIBUTE_CUSTODIAN = 'account.custodian.id';
const CURRENCY = GROUPING_ATTRIBUTE_CURRENCY;
const CUSTODIAN = GROUPING_ATTRIBUTE_CUSTODIAN;

const WARNING = 'warningMessage';
const ERROR = 'errorMessage';

const { FUTURE } = AccountType;

const COMPONENT_NAME = 'PortfolioBreakdownChart';

const DEFAULT_MARGIN = 45;
const DEFAULT_PIE_SIZE = 270;
const SYSTEM_CUSTODIAN_ID = '1';

const Message = ({type}) => (
  <div className={COMPONENT_NAME}>
    <BaseMessage content={copyText[type]} error={type === ERROR} warning={type === WARNING} />
  </div>
)

const PortfolioBreakdownChart = props => {
  const { positions, homeCurrency, priceLookupTable: prices, initialGroupingAttribute: grpAttr } = props;

  const grouping = grpAttr === CURRENCY ? 'groupedByCurrency' : 'groupedByCustodian';
  const className = `${COMPONENT_NAME} ${grouping}`;

  return !positions || !positions.length
    ? <Message type={WARNING} />
    : !homeCurrency || !homeCurrency.id || isEmpty(prices)
    ? <Message type={ERROR} />
    : <HighchartsAdapter domProps={{ className }} config={getChartConfig(props)} isPureConfig />;
}

const getChartConfig = props => ({
  chart: {
    type: 'pie',
    height: props.height,
    events: {
      drilldown: event => handleDrilldown(event, props),
      drillup: event => handleDrillup(event, props),
    },
    margin: props.margin || DEFAULT_MARGIN,
    spacing: [0, 0, 0, 0],
  },
  drilldown: {
    activeDataLabelStyle: {
      color: colors.black,
      textDecoration: 'none',
    },
    drillUpButton: {
      position: {
        align: 'left',
        verticalAlign: 'top',
        x: 10,
      },
      relativeTo: 'spacingBox',
    },
  },
  lang: {
    drillUpText: 'Reset',
  },
  legend: {
    align: 'right',
    enabled: true,
    layout: 'vertical',
    verticalAlign: 'bottom',
  },
  plotOptions: {
    pie: {
      center: ['50%', '50%'],
      showInLegend: true,
      size: props.size || DEFAULT_PIE_SIZE,
    },
  },
  series: [
    {
      data: getData(props),
      name: 'Breakdown',
    },
  ],
  title: {
    style: {
      display: 'inherit',
      fontFamily: 'Lato',
      fontSize: '1.2em',
    },
    text:
      props.initialGroupingAttribute === CURRENCY
        ? copyText.titleByCurrency
        : copyText.titleByCustody,
  },
  subtitle: {
    style: {
      color: 'red',
      display: anyNegative(props.positions) ? 'inherit' : 'none',
      fontSize: '1em',
      fontStyle: 'italic',
    },
    text: copyText.warningNegBalance,
  },
  tooltip: {
    formatter: function() {
      return renderTooltipContent({
        ...this,
        initialGroupingAttribute: props.initialGroupingAttribute,
        homeCurrency: props.homeCurrency
      });
    },
    useHTML: true,
  },
})

const validPositions = p => getAssetDisplayText(get(p, 'account.currency')) && (p.quantity > 1e-8 || p.account.type === FUTURE)

const getData = ({priceLookupTable: prices, positions, initialGroupingAttribute: grpAttr}) => {
  positions = groupBy(positions.filter(validPositions), p => get(p, grpAttr, '_'));

  const ids = Object.keys(positions);
  const byCurr = grpAttr === CURRENCY;

  const totals = ids.reduce((totals, id) => {
    totals[id] = positions[id].reduce((total, {quantity = 0, account = {}}) => {
      const { currency, position } = account || {};
      const price = prices[currency.id] || 0;
      total.value += position ? getLiquidationValue(position, prices) : quantity * price || 0;
      total.quantity += quantity || 0;

      return total;
    }, { value: 0, quantity: 0 });

    return totals;
  }, {});

  return sortBy(ids.map(id => {
    const { value, quantity } = totals[id];
    const position = positions[id][0]; // NOTE: positions[id] is guaranteed to be a nonempty array because positions is the result of groupBy and id is one of its keys
    const type = get(position, 'account.currency.type');
    const custId = get(position, 'account.custodian.id');
    const custodian = get(position, 'account.custodian.name') || copyText.chartLabelSelfCustody;
    const currency = getAssetDisplayText(
      get(position, 'account.currency'),
      copyText.chartLabelSelfCustody
    ).concat(
      type === AssetType.FUTURE
        ? ` (${custodian})`
        : ''
    );
    const name = custId === SYSTEM_CUSTODIAN_ID && !byCurr
      ? copyText.chartLabelUnsettled
      : byCurr
      ? currency
      : custodian;

      return { name, drilldown: id, y: value, quantity, type };
  }), p => -p.y);
}

const getLiquidationValue = ({liquidationValue: v, marginAssetId: id}, prices) => v * prices[id] || 0

const anyNegative = (p = []) => p.some(({quantity: q, account: a = {}}) => q < 0 && a.type !== FUTURE)

const handleDrilldown = ({target: chart, seriesOption, point = {}}, {positions, homeCurrency, initialGroupingAttribute: grpAttr, priceLookupTable: prices}) => {
  if (seriesOption) return;

  const wasByCurr = grpAttr === CURRENCY;

  const data = sortBy(positions.reduce((data, {quantity, account = {}}) => {
    const { currency = {}, position, custodian = {} } = account || {};
    const { id: currId, type } = currency || {};
    const custId = (custodian || {}).id || '_';

    if ((wasByCurr ? currId : custId) !== point.drilldown) return data;

    const price = prices[currId] || 0;
    const value = (position ? getLiquidationValue(position, prices) : quantity * price) || 0;

    const selfCustody = custId === SYSTEM_CUSTODIAN_ID;
    const name = selfCustody && wasByCurr
      ? copyText.chartLabelUnsettled
      : wasByCurr
      ? ((custodian || {}).name || copyText.chartLabelSelfCustody)
      : getAssetDisplayText(currency);

    if (name && !isNaN(value)) data.push([name, value, quantity, type]);

    return data;
  }, []), p => -p[1]);

  const sum = i => (sum, item) => sum + Math.max(0, parseFloat(item[i]) || 0)
  const quantity = (data.reduce(sum(2), 0) || 0).toFixed(3);
  const qtyFormat = '0,000.00';
  const value = format((data.reduce(sum(1), 0) || 0).toFixed(2), qtyFormat);

  const { symbol = '' } = homeCurrency || {};
  const currText = `${quantity} ${point.name} (${displayInHomeCurrency(value, symbol)})`;
  const custText = `${displayInHomeCurrency(value, symbol)}`;

  const title = `${point.name} ${wasByCurr ? copyText.brokenByCustody : copyText.brokenByCurrency}`;
  const custodianSubtitle = custText;
  const currencySubtitle = currText;
  const subtitle = `${wasByCurr ? currencySubtitle : custodianSubtitle}${anyNegative(positions) ? '*' : ''}`;
  const style = { color: 'black', display: 'inherit', fontSize: '1em', fontStyle: 'normal' };

  chart.setTitle({ text: title });
  chart.setSubtitle({ style, text: subtitle });
  chart.addSeriesAsDrilldown(point, { name: point.name, data });
}

const handleDrillup = ({ target: chart }, { positions, initialGroupingAttribute: grpAttr }) => {
  chart.setTitle({text: grpAttr === CURRENCY ? copyText.titleByCurrency : copyText.titleByCustody});
  chart.setSubtitle({
    style: { color: 'red', display: anyNegative(positions) ? 'inherit' : 'none', fontSize: '1em', fontStyle: 'italic' },
    text: copyText.warningNegBalance,
  });
}

const table = rows => `<table>${rows.join('')}</table>`.replace(/>[\s\n]+</g, '><')
const tr = (cells = []) => `<tr>${cells.join('')}</tr>`
const td = (content = '', align = 'right', bold = false, th = false, cs = false, style = null) => {
  const tag = th ? 'th' : 'td';
  const colspan = th || cs ? ' colspan="2"' : '';
  const fontWeight = bold ? '; font-weight: bold' : '';
  return `<${tag}${colspan} style="${style || `text-align: ${align}${fontWeight}`}">${content}</${tag}>`
}

const renderTooltipContent = ({ point = {}, series = {}, key, y = 0, initialGroupingAttribute: grpAttr, homeCurrency = {}, color = '', percentage = 0 }) => {
  const isDrillDown = !point.quantity;
  const { userOptions = {} } = series || {};
  const { data = [] } = userOptions || {};
  const { name = '' } = series || {};
  const { symbol = '' } = getFiatSymbol(homeCurrency) || {};
  const { quantity: pointQty, type: pointType } = point || {};
  const info = (data || []).find(p => p[0] === key) || []; // NOTE: info looks like [name, value, quantity, type], see handleDrilldown's data variable

  const quantity = (isDrillDown ? info[2] : pointQty) || 0;
  const type = isDrillDown ? info[3] : pointType;

  const isFiat = type === AssetType.FIAT;
  const byCurrency = grpAttr === CURRENCY;
  const byCustodian = grpAttr === CUSTODIAN;

  const showQty = quantity !== y && (byCurrency || (byCustodian && isDrillDown));
  const showHeader = (byCustodian && !isDrillDown) || (byCurrency && isDrillDown);

  const qtyFormat = isFiat ? '0,000.00': '0,0.000';
  const qtyStr = format(quantity, qtyFormat);
  const yStr = format(y, '0,000.00');
  const pctStr = `${format(percentage, '0.0')}%`;

  const rowStyle = `text-align: center${color ? `; color: ${color}` : ''}; font-size: 1.1em; font-weight: bold; padding-bottom: 5px`;

  return table([
    showHeader ? tr([td(key, 'center', true, true)]) : '',
    showQty ? tr([td(qtyStr), td(showHeader ? name : key, 'left', true)]) : '',
    tr([td(yStr), td(symbol, 'left', true)]),
    tr([td(pctStr, 'center', true, false, true, rowStyle)]),
  ])
}

export default PortfolioBreakdownChart
