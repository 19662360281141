const initiateSettlementFormCopy = {
  cancelButtonLabel: 'Cancel',
  confirmationContent: 'If you proceed, a settlement request will be sent to your counterparty.',
  confirmationContentOffline: 'The selected transactions will no longer be available for settlement via the Settlement Center by you or your counterparty',
  confirmationHeader: 'Are you sure you want to proceed?',
  depositInstructionDescription_missing: 'Missing Deposit Instructions',
  depositInstructionDescription_notNeeded: 'No Deposit Instructions Needed',
  incomingLabel: 'Incoming',
  initiateSettlementButtonLabel: 'Initiate Settlement',
  inputPlaceholder_selectFromWallet: 'Select Outgoing Wallet',
  inputPlaceholder_selectFromAddress: 'Select Outgoing Address',
  inputPlaceholder_selectToWallet: 'Select Incoming Wallet',
  inputPlaceholder_selectToAddress: 'Select Incoming Address',
  netSettleButtonLabel: 'Net Settle',
  outgoingLabel: 'Outgoing',
  settleButtonLabel: 'Settle',
  settledOffline: 'Settled Offline',
  tableHeaderCurrency: 'Currency',
  tableHeaderDirection: 'Direction',
  tableHeaderAddress: 'Address/Account',
  tableHeaderWallet: 'Wallet/Institution',
  tableHeaderQuantity: 'Quantity'
};

export default initiateSettlementFormCopy;
