import { arrayOf, func, shape, string } from 'prop-types';
import { Button, Form } from 'semantic-ui-react';
import React, { Fragment, PureComponent } from 'react';
import styled from 'react-emotion';

import { has } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { isBlank } from '@omniex/onx-common-js/lib/utils/StringUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { orderBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import { filterMap } from '@omniex/poms-core/lib/utils/FunctionUtils';
import copyText from './UpdateUserRolesForm.copyText';
import FormSelect from '@omniex/onx-common-ui/lib/semantic/react/FormSelect';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import MultiSelect from './MultiSelect';
import UserRoleStatus from '@omniex/onx-poms-entity-helpers/lib/enums/UserRoleStatus';
import UserRoleType from '@omniex/onx-poms-entity-helpers/lib/enums/UserRoleType';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/button.css');
require('@omniex/onx-common-ui/lib/semantic/css/form.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');
require('@omniex/onx-common-ui/lib/semantic/css/dropdown.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const userRoleTypes = [
  UserRoleType.CRYPTO_ACCOUNT_CONTROLLER,
  UserRoleType.FIAT_ACCOUNT_CONTROLLER,
  UserRoleType.PORTFOLIO_AUDITOR,
  UserRoleType.SETTLEMENT_OFFICER,
  UserRoleType.TRADER
];

const allRolesOption = { label: 'All Roles', value: '*' }
const allPortfoliosOption = { label: 'All Portfolios', value: '*' }

const COMPONENT_NAME = 'UpdateUserRoleForm';

const StyledForm = styled(Form)`
  min-width: 280px;

  .${COMPONENT_NAME}-multiSelect {
    margin-bottom: 1em;

    input[tabindex='0'] {
      padding: 0 8px !important;
    }
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

const changed = (last, next) => {
  const all = [...new Set([...last, ...next])];
  return all.some(v => last.includes(v) !== next.includes(v))
}

const getUser = (id, users) => (users || []).find(u => u.id === id);

export default class UpdateUserRolesForm extends PureComponent {
  static propTypes = {
    portfolios: arrayOf(shape({
      id: string.isRequired,
      name: string.isRequired,
    })),
    users: arrayOf(
      shape({
        id: string.isRequired,
        name: string.isRequired,
        roles: arrayOf(
          shape({
            id: string.isRequired,
            status: string.isRequired,
            type: string.isRequired
          })
        ),
        username: string.isRequired
      })
    ),
    onUpdate: func
  };

  static defaultProps = { onUpdate: noop };

  state = getInitialState();

  render() {
    const { users, portfolios } = this.props;

    const userOptions = orderBy(users.map(user => ({
      value: user.id,
      label: user.name,
    })), 'label');

    const portfolioOptions = orderBy(portfolios.map(p => ({
      value: p.id,
      label: `${p.name}${p.active ? '' : ' (inactive)'}`,
    })), 'value');

    const userRoleTypeOptions = userRoleTypes.map(userRoleType => ({
      value: userRoleType,
      label: copyText[`userRoleTypeOption_${userRoleType}`]
    }));

    return (
      <StyledForm
        className={COMPONENT_NAME}
        warning
        onSubmit={this._handleSubmit}>
        <FormSelect
          name="userId"
          options={userOptions}
          placeholder={copyText.inputPlaceholder_userId}
          value={this.state.userOption}
          onChange={this._handleChangeField}
        />
        <MultiSelect
          className={cn('multiSelect')}
          name="roleTypes"
          allOption={allRolesOption}
          isDisabled={!this.state.userId}
          options={userRoleTypeOptions}
          placeholder={copyText.inputPlaceholder_roleTypes}
          value={this.state.roleTypeOptions}
          onChange={this._handleChangeField}
        />
        <MultiSelect
          className={cn('multiSelect')}
          name="portfolios"
          allOption={allPortfoliosOption}
          isDisabled={!this.state.userId}
          options={portfolioOptions}
          placeholder={copyText.inputPlaceholder_portfolios}
          value={this.state.portfolioOptions}
          onChange={this._handleChangeField}
        />
        {this.state.showProceedButton ? (
          <Fragment>
            <Message
              content={copyText.confirmationMessageContent}
              header={copyText.confirmationMessageHeader}
              warning
            />
            <Form.Field>
              <Button
                color="orange"
                disabled={!this._canSave() || this.props.processing}
                fluid
                loading={this.props.processing}
                type="submit">
                {copyText.proceedButtonLabel}
              </Button>
            </Form.Field>
            <Form.Field>
              <Button
                fluid
                type="reset"
                onClick={this._handleClickCancelButton}>
                {copyText.cancelButtonLabel}
              </Button>
            </Form.Field>
          </Fragment>
        ) : (
          <Form.Field>
            <Button
              color="blue"
              disabled={!this._canSave()}
              fluid
              loading={this.props.processing}
              onClick={this._handleClickSubmitButton}>
              {copyText.submitButtonLabel}
            </Button>
          </Form.Field>
        )}
      </StyledForm>
    );
  }

  _canSave() {
    const { userId, roleTypes, portfolios } = this.state;
    const { users } = this.props;

    if (isBlank(userId) || !roleTypes?.length) return false;

    const user = getUser(userId, users);

    const portfoliosChanged = changed((user?.portfolios || []).map(p => p.id), portfolios);
    const roleTypesChanged = changed((user?.roles || []).map(r => r.type), roleTypes);

    return portfoliosChanged || roleTypesChanged;
  }

  _handleChangeField = (field, event) => {
    let { value } = field;

    if (Array.isArray(field)) {
      value = [];
      field.forEach(input => value.push(input.value));
    }

    const change = { [event.name]: value };
    let changes = change;

    if (has(change, 'userId')) {
      const roleTypeOptions = this._getActiveUserRoles(change.userId);
      const roleTypes = roleTypeOptions.map(o => o.value);
      const portfolioOptions = this._getUserPortfolios(change.userId);
      const portfolios = portfolioOptions.map(o => o.value);

      changes = {
        ...changes,
        roleTypeOptions,
        roleTypes,
        portfolioOptions,
        portfolios,
        userOption: field
      };
    }

    if (has(change, 'roleTypes')) changes = { ...changes, roleTypeOptions: field };

    if (has(change, 'portfolios')) changes = { ...changes, portfolioOptions: field };

    this.setState(changes);
  };

  _handleClickSubmitButton = event => {
    event.preventDefault();

    this.setState({ showProceedButton: true });
  };

  _handleClickCancelButton = event => {
    event.preventDefault();

    this.setState({ showProceedButton: false });
  };

  _handleSubmit = event => {
    event.preventDefault();

    this.props.onUpdate({
      userId: this.state.userId,
      roleTypes: this.state.roleTypes,
      portfolioIds: this.state.portfolios,
    });

    this.setState(getInitialState());
  };

  _getActiveUserRoles(userId) {
    const user = getUser(userId, this.props.users);
    return filterMap(user?.roles || [], r => r.status === UserRoleStatus.ACTIVE, r => ({
      value: r.type,
      label: copyText[`userRoleTypeOption_${r.type}`],
    }));
  }

  _getUserPortfolios(userId) {
    const user = getUser(userId, this.props.users);
    return filterMap(
      this.props.portfolios || [],
      p => user?.portfolios?.some?.(({ id }) => id === p.id),
      p => ({ value: p.id, label: p.name })
    );
  }
}

function getInitialState() {
  return {
    portfolioOptions: [],
    roleTypeOptions: [],
    showProceedButton: false,
    userId: '',
    userOption: ''
  };
}
