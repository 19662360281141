import { createSelector } from 'reselect';

import { orderBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';

export function getFeedOptions(feeds) {
  if (!Array.isArray(feeds) || feeds.length === 0) return [];

  return orderBy(
    feeds.map(feed => ({
      value: feed.symbol,
      label: feed.name
    })),
    feed => feed.label.toLowerCase()
  );
}

export default createSelector(
  feeds => feeds,
  getFeedOptions
);
