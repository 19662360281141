import { Button, Form } from 'semantic-ui-react';
import React from 'react';
import styled from 'react-emotion';

import {
  getMaintenanceCalendarStartTime,
  getMaintenanceStartWeekday,
  getTimeToMaintenance,
  getUserTimeZone,
  getUTCMaintenanceCalendarStartTime,
  getUTCMaintenanceStartTime,
  getUTCMaintenanceStartWeekday,
  getUTCTimeZone,
  isAfterMaintenanceEnd
} from '../../utils/TimeUtils.js';
import copyText from './DurationForm.copyText';
import { format as formatNumber } from '@omniex/onx-common-js/lib/utils/NumberUtils';
import { getAlgoName } from '@omniex/onx-poms-entity-helpers/lib/utils/OrderUtils';
import { isNil } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { MAINTENANCE_DURATION } from '../../constants/maintenance.js';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import { pluralize } from '@omniex/onx-common-js/lib/utils/StringUtils';

const COMPONENT_NAME = 'DurationForm';

const StyledMessage = styled(Message)`
  .${COMPONENT_NAME}-durationMessage {
      margin-top: 0.2em;
      margin-bottom: 0.5em;
    }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export const DurationForm = ({ visible, strategy, duration, unit, onShorten, onKeepOpen, onCancel }) => {
  if (!visible) return null;

  const { afterHeader, afterUpper, afterLower, duringHeader, duringUpper, duringLower } = copyText
  const [header, upperContent, lowerContent, keepOpenFlag] = isAfterMaintenanceEnd(duration, unit)
    ? [afterHeader, afterUpper, afterLower, true]
    : [duringHeader, duringUpper, duringLower, false]

  return (
    <>
      <StyledMessage
        warning
        header={header}
        content={
          <>
            <div className={cn('durationMessage')}>{formatMessage(upperContent, strategy, duration, unit)}</div>
            <div>{formatMessage(lowerContent, strategy, duration, unit)}</div>
          </>
        }/>
      <Form.Field>
        <Button color="orange" fluid type="button" onClick={onShorten}>{'Shorten Duration'}</Button>
      </Form.Field>
      {keepOpenFlag && (
        <Form.Field>
          <Button color="orange" fluid type="button" onClick={onKeepOpen}>{'Keep Open'}</Button>
        </Form.Field>
      )}
      <Form.Field>
        <Button fluid type="reset" onClick={onCancel}>{'Cancel'}</Button>
      </Form.Field>
    </>
  )
}

function formatMessage(msg, strat, dur, unit) {
  const hoursToMaintenance = parseFloat(getTimeToMaintenance('hours').toFixed(1));

  msg = msg
    .replace('%algoStrategy%', getAlgoName(strat))
    .replace('%algoDuration%', `${getDisplayFormattedNum(parseInt(dur))} ${pluralize(unit.toLowerCase(), parseInt(dur))}`)
    .replace('%hoursToMaintenance%', `${hoursToMaintenance} ${pluralize('hour', hoursToMaintenance)}`)
    .replace('%maintenanceDuration%', `${MAINTENANCE_DURATION} ${pluralize('hour', MAINTENANCE_DURATION)}`)
    .replace('%maintenanceStartTime%', `${getMaintenanceCalendarStartTime()} ${getUserTimeZone()}`);

  msg = getUserTimeZone() === getUTCTimeZone()
    ? msg.replace('%maintenanceStartTimeUTC%', '')
    : getMaintenanceStartWeekday() === getUTCMaintenanceStartWeekday()
    ? msg.replace('%maintenanceStartTimeUTC%', `(${getUTCMaintenanceStartTime()} UTC) `)
    : msg.replace('%maintenanceStartTimeUTC%', `(${getUTCMaintenanceCalendarStartTime()} UTC) `);

  return msg;
}

function getDisplayFormattedNum(num) {
  if (isNil(num) || isNaN(num)) {
    return '';
  };

  const re = /^[0-9]+\.?(?<frac>[0-9]*)e(?<exp>[-+]?[0-9]+)$/;
  const f = n => n.toExponential().match(re).groups;
  // eslint-disable-next-line no-sequences
  const countDigits = (n, frac, exp) => ({ frac, exp } = f(n), Math.max(0, frac.length - exp));
  const format = `0,0.${'0'.repeat(countDigits(num))}`;

  return formatNumber(num, format);
}

export default DurationForm;
