import * as React from 'react';
import { useRef } from 'react';
import { BaseProps, ClassNameMap, fonts, makeStyles } from '@omniex/poms-ui';
import Popover from '@material-ui/core/Popover';
import clsx from 'clsx';
import { EditIcon } from '@omniex/poms-ui/lib/components/Icon';
import { Size, fontSizeStyles } from './shared';

export type SimpleInputDialogClassKey = 'root' | 'enabled' | 'disabled' | 'popover' | 'paper' | 'label' | 'icon' | Size;
export type SimpleInputDialogProps = BaseProps<HTMLDivElement, SimpleInputDialogClassKey> & {
  disabled?: boolean;
  label: React.ReactNode;
  onClose?: () => void;
  onOpen?: () => void;
  open: boolean;
  size?: keyof typeof fonts.displayFonts;
}

type UseStyles = (props?: any) => ClassNameMap<SimpleInputDialogClassKey>
const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    ...fonts.displayFonts.mediumStrong,
    // ...(fonts.displayFonts[size || 'mediumStrong'] || fonts.displayFonts.mediumStrong)
  },
  enabled: {
    cursor: 'pointer',
  },
  disabled: {
    cursor: 'inherit',
  },
  popover: {},
  paper: {
    maxWidth: 1000,
    backgroundColor: palette.canvas.main,
    padding: '16px 20px',
    border: `1px solid ${palette.borderStrong.main}`,
    borderRadius: 4,
  },
  ...fontSizeStyles as object,
}), { name: 'simple-inputdialog' }) as UseStyles;


export function SimpleInputDialog(props: SimpleInputDialogProps) {
  const { children, className, classes, disabled, label, size = 'mediumStrong', onClose, onOpen, open, ...rest } = props;
  const cls = useStyles();
  const anchorEl = useRef<HTMLDivElement>(null);

  return (
    <div className={classes?.root}>
      <div
        {...rest}
        ref={anchorEl}
        className={clsx(
          cls.root, cls[size], className, classes?.label,
          disabled ? [cls.disabled, classes?.disabled] : [cls.enabled, classes?.enabled])}
        onClick={disabled ? undefined : onOpen}
      >
        {label}
        {!disabled && <EditIcon className={classes?.icon} size={size} />}
      </div>
      <Popover
        className={clsx(cls.popover, classes?.popover)}
        classes={{
          paper: clsx(cls.paper, classes?.paper),
        }}
        open={open && !!anchorEl.current}
        anchorEl={anchorEl.current}
        onClose={onClose}
        PaperProps={{elevation: 0}}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {children}
      </Popover>
    </div>
  );
}
