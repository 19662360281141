export const MAINTENANCE_REDIRECT_DAY_EST = 2; //Tuesday
export const MAINTENANCE_REDIRECT_HOUR_EST = 17;
export const MAINTENANCE_END_DAY_EST = 2;
export const MAINTENANCE_END_HOUR_EST = 20;
const MAINTENANCE_REDIRECT_HOUR_CONVERSION = MAINTENANCE_REDIRECT_DAY_EST * 24 + MAINTENANCE_REDIRECT_HOUR_EST;
const MAINTENANCE_END_HOUR_CONVERSION = MAINTENANCE_END_DAY_EST * 24 + MAINTENANCE_END_HOUR_EST;
const HOURS_IN_FULL_WEEK = 168;
export const MAINTENANCE_DURATION = ((MAINTENANCE_END_HOUR_CONVERSION - MAINTENANCE_REDIRECT_HOUR_CONVERSION) + HOURS_IN_FULL_WEEK) % HOURS_IN_FULL_WEEK;
export const MAINTENANCE_TIME_ZONE = 'America/New_York'; //EST
export const MAINTENANCE_WARNING_MINUTES_BEFORE_REDIRECT = 20;
export const SECONDS_IN_FULL_WEEK = 604800;

const maintenance = {
  MAINTENANCE_REDIRECT_DAY_EST,
  MAINTENANCE_REDIRECT_HOUR_EST,
  MAINTENANCE_END_DAY_EST,
  MAINTENANCE_END_HOUR_EST,
  MAINTENANCE_DURATION,
  MAINTENANCE_TIME_ZONE,
  MAINTENANCE_WARNING_MINUTES_BEFORE_REDIRECT,
  SECONDS_IN_FULL_WEEK
};

export default maintenance;
