import * as React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@omniex/poms-ui/lib/themes';
import { fade } from '@material-ui/core/styles/colorManipulator'
import Typography, { TypographyTypeMap } from '@material-ui/core/Typography'

const SIZE = 44

const useSvgCirclesStyles = makeStyles(theme => ({
  root: {
    transition: theme.transitions.create('transform'),
  },
  circle: {
    transition: theme.transitions.create('stroke-dashoffset'),
    stroke: theme.palette.primary.main,
  },
  background: {
    stroke: fade(theme.palette.primary.main, 0.1),
  },
  inner: {
    transition: theme.transitions.create('stroke-dashoffset'),
    stroke: theme.palette.secondary.main,
  },
  innerBackground: {
    stroke: fade(theme.palette.secondary.main, 0.1),
  },
}));

const useProgressStyles = makeStyles({
  root: {
      position: 'relative',
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'center',
      alignItems: 'center',
  },
  circles: {
      position: 'absolute',
      top: 0,
      left: 0,
  },
  text: {
      lineHeight: 1,
  },
});

type SvgCirclesProps = {
  className: string
  size: number
  value: number
  inner: boolean
  innerValue: number
  thickness: number
  innerThickness: number
}

type ProgressProps = {
  progress: number
  size?: number
  thickness?: number
  textVariant?: TypographyTypeMap['props']['variant']
  inner?: boolean
  innerProgress?: number
  innerThickness?: number
  innerTextVariant?: TypographyTypeMap['props']['variant']
  className?: string
  circlesClassName?: string
  textClassName?: string
  innerTextClassName?: string
}

const Circle: React.FC<React.SVGProps<SVGCircleElement>> = props => <circle cx={SIZE} cy={SIZE} fill="none" {...props} />

const SvgCircles: React.FC<SvgCirclesProps> = ({
  className,
  size,
  value,
  inner,
  innerValue,
  thickness,
  innerThickness
}) => {
  const classes = useSvgCirclesStyles();
  const radius = (SIZE - thickness) / 2
  const innerRadius = (SIZE - (2 * thickness) - innerThickness) / 2
  const circumference = Math.PI * (SIZE - thickness)
  const innerCircumference = Math.PI * (SIZE - 2 * thickness - innerThickness)

  const rootStyle = {
    transform: 'rotate(-90deg)',
    lineHeight: 1,
    width: size,
    height: size,
  }

  const circleStyle = {
    strokeDasharray: circumference.toFixed(3),
    strokeDashoffset: `${(((100 - value) / 100) * circumference).toFixed(3)}px`,
  }

  const backgroundStyle = {
    strokeDasharray: circumference.toFixed(3),
    strokeDashoffset: 0,
  }

  const innerStyle = {
    strokeDasharray: innerCircumference.toFixed(3),
    strokeDashoffset: `${(((100 - innerValue) / 100) * innerCircumference).toFixed(3)}px`,
  }

  const innerBackgroundStyle = {
    strokeDasharray: innerCircumference.toFixed(3),
    strokeDashoffset: 0,
  }

  return (
    <div className={clsx(classes.root, className)} style={rootStyle}>
      <svg viewBox={`${SIZE / 2} ${SIZE / 2} ${SIZE} ${SIZE}`}>
        <Circle style={backgroundStyle} className={classes.background} r={radius} strokeWidth={thickness} />
        <Circle style={circleStyle} className={classes.circle} r={radius} strokeWidth={thickness} />
        {inner && <Circle style={innerBackgroundStyle} className={classes.innerBackground} r={innerRadius} strokeWidth={innerThickness} />}
        {inner && <Circle style={innerStyle} className={classes.inner} r={innerRadius} strokeWidth={innerThickness} />}
      </svg>
    </div>
  )
};

const Progress: React.FC<ProgressProps> = ({
    progress,
    size = 64,
    thickness = 3.6,
    textVariant = 'button',
    inner = false,
    innerProgress = 0,
    innerThickness = 3.6,
    innerTextVariant = 'caption',
    className,
    circlesClassName,
    textClassName,
    innerTextClassName,
}) => {
  const classes = useProgressStyles();
  return (
    <div className={clsx(classes.root, className)} style={{width: size, height: size}}>
      <SvgCircles
        className={clsx(classes.circles, circlesClassName)}
        size={size}
        value={progress}
        inner={inner}
        innerValue={innerProgress}
        thickness={thickness}
        innerThickness={innerThickness} />
      <Typography className={clsx(classes.text, textClassName)} variant={textVariant}>{progress}%</Typography>
      {inner && <Typography className={clsx(classes.text, innerTextClassName)} variant={innerTextVariant}>{innerProgress}%</Typography>}
    </div>
  );
};


export default Progress
