const quotePageBlotterSectionCopy = {
  csvHeader_filledCurrency: 'Specified Currency',
  csvHeader_filledQuantity: 'Quantity Filled',
  csvHeader_fixClientID: 'Client ID',
  csvHeader_fixClOrdID: 'Client Order ID',
  csvHeader_fixOrderID: 'EMS Order ID',
  csvHeader_fixQuoteID: 'Quote ID',
  csvHeader_fixVenueOrderID: 'Venue Order ID',
  csvHeader_instrumentSymbol: 'Instrument',
  csvHeader_limitCurrency: 'Term Currency',
  csvHeader_limitPrice: 'Limit Price',
  csvHeader_side: 'Side',
  csvHeader_specifiedCurrency: 'Specified Currency',
  csvHeader_specifiedQuantity: 'Quantity Ordered',
  csvHeader_status: 'Status',
  csvHeader_timeLastUpdated: 'Time Stamp',
  csvLabel_download: 'Download',
  loadingMessage: 'Loading...',
  perPageLabel: 'Per Page',
  sectionTitle: 'Blotter',
  tabLabelCompletedOrders: 'Completed Orders'
};

export default quotePageBlotterSectionCopy;
