const csvDownloadFormCopy = {
  csvHeader_algorithmActualDuration: 'Algo Act. Duration',
  csvHeader_algorithmDuration: 'Algo Req. Duration',
  csvHeader_algorithmExpiration: 'Algorithm Expiration',
  csvHeader_algorithmStrategy: 'Algorithm Strategy',
  csvHeader_averageFillPrice: 'Average Price',
  csvHeader_averageFillCurrency: 'Term Currency',
  csvHeader_counterParty: 'Counter Party',
  csvHeader_customOrderId: 'Client Order ID',
  csvHeader_direction: 'Direction',
  csvHeader_displayQuantity: 'Display Quantity',
  csvHeader_executionType: 'Execution Type',
  csvHeader_expiryType: 'TIF',
  csvHeader_feeCost: 'Fee Cost',
  csvHeader_feeCurrency: 'Fee Currency',
  csvHeader_fillCurrency: 'Term Currency',
  csvHeader_fillPrice: 'Fill Price',
  csvHeader_fillQuantity: 'Qty Filled',
  csvHeader_fillTimeCreated: 'Timestamp',
  csvHeader_fixAllocID: 'Allocation ID',
  csvHeader_fixClOrdID: 'Order ID',
  csvHeader_fixExecID: 'Exec ID',
  csvHeader_fixOrderID: 'EMS Order ID',
  csvHeader_fixQuoteID: 'Quote ID',
  csvHeader_fixVenueOrderID: 'Venue Order ID',
  csvHeader_incomingCurrency: 'Incoming Currency',
  csvHeader_incomingFromAddress: 'Incoming From Address-Account',
  csvHeader_incomingFromWallet: 'Incoming From Wallet-Institution',
  csvHeader_incomingQuantity: 'Incoming Quantity',
  csvHeader_incomingToAddress: 'Incoming To Address-Account',
  csvHeader_incomingToWallet: 'Incoming To Wallet-Institution',
  csvHeader_instrument: 'Instrument',
  csvHeader_hypePrice: 'Decision Price',
  csvHeader_instrumentSymbol: 'Instrument',
  csvHeader_instrumentType: 'Type',
  csvHeader_limitPrice: 'Limit Price',
  csvHeader_notionalValue: 'Notional Value',
  csvHeader_notionalCurrency: 'Currency',
  csvHeader_orderType: 'Order Type',
  csvHeader_outgoingCurrency: 'Outgoing Currency',
  csvHeader_outgoingFromAddress: 'Outgoing From Address-Account',
  csvHeader_outgoingFromWallet: 'Outgoing From Wallet-Institution',
  csvHeader_outgoingQuantity: 'Outgoing Quantity',
  csvHeader_outgoingToAddress: 'Outgoing To Address-Account',
  csvHeader_outgoingToWallet: 'Outgoing To Wallet-Institution',
  csvHeader_participationRate: 'Participation Rate',
  csvHeader_requestCancelTrader: 'Canceling Trader',
  csvHeader_side: 'Side',
  csvHeader_source: 'Source',
  csvHeader_specifiedCurrency: 'Specified Currency',
  csvHeader_specifiedQuantity: 'Qty Ordered',
  csvHeader_status: 'Status',
  csvHeader_stopPrice: 'Stop Price',
  csvHeader_termCurrency: 'Term Currency',
  csvHeader_timeCreated: 'Time Created',
  csvHeader_timeLastUpdated: 'Last Update',
  csvHeader_toParty: 'To Party',
  csvHeader_totalFees: 'Total Fees',
  csvHeader_totalPrice: 'Total Price',
  csvHeader_totalPriceCurrency: 'Currency',
  csvHeader_traderName: 'Trader',
  csvHeader_venueName: 'Venue',
  downloadButtonLabel: 'Download',
  inputPlaceholder_endDate: 'End Date',
  inputPlaceholder_startDate: 'Start Date',
  noData: '--',
  systemCancel: 'System',
  tabLabelCompletedOrders: 'Completed Orders',
  tabLabelFills: 'Fills',
  tabLabelWorkingOrders: 'Working Orders'
};

export default csvDownloadFormCopy;
