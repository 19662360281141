import { arrayOf, bool, func, number, object, string } from 'prop-types';
import { Icon, Popup } from 'semantic-ui-react';
import React, { Fragment, PureComponent } from 'react';
import styled from 'react-emotion';

import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import {
  getLiquidationValue,
  getMargin,
  getUnrealizedPnL,
  getValue,
  getValueTooltip
} from '../../utils/PositionUtils';
import { displayInHomeCurrency, getPriceFormat } from '../../utils/DisplayUtils';
import { getCustodianName, getTotalAccountBalance } from '../../utils/AccountUtils';
import { isNearZero } from '@omniex/onx-common-js/lib/utils/NumberUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { sortBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './PortfolioPositionsCustodianTable.copyText';
import Table, {
  CELL_FORMAT_MONEY,
  CELL_FORMAT_NUMBER
} from '@omniex/onx-common-ui/lib/semantic/react/Table';

const COMPONENT_NAME = 'PortfolioPositionsCustodianTable';

const MIN_QUANTITY = 0.0000000001;

const SHORT_SUFFIX = 'SHORT';

const StyledTable = styled(Table)`
  max-width: 100%;
  padding-bottom: 2em;
  padding-top: 2em;

  .${COMPONENT_NAME}-iconHeader {
    min-width: 50px;
  }

  .${COMPONENT_NAME}-header {
    max-width: 150px;
  }

  .${COMPONENT_NAME}-negative, .${COMPONENT_NAME}-short {
    color: ${colors.red};
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class PortfolioPositionsCustodianTable extends PureComponent {
  static propTypes = {
    accounts: arrayOf(object),
    activities: arrayOf(object),
    activitiesLoading: bool,
    currency: object,
    currentPrice: number,
    currentPriceLookupTable: object,
    forFutures: bool,
    openAccountId: string,
    handleExpandedRow: func,
    renderExpandedContent: func
  };

  static defaultProps = {
    handleExpandedRow: noop,
    renderExpandedContent: noop
  };

  render() {
    //TODO: selector
    const accounts = sortBy(this.props.accounts, a => getCustodianName(a));

    return (
      <StyledTable>
        <Table.Header>
          {this.props.forFutures === true ? (
            <Table.Row>
              <Table.HeaderCell className={cn('iconHeader')} />
              <Table.HeaderCell className={cn('header')} textAlign="right">
                {copyText.tableHeaderCustodian}
              </Table.HeaderCell>
              <Table.HeaderCell className={cn('header')} textAlign="right">
                {copyText.tableHeaderInstrument}
              </Table.HeaderCell>
              <Table.HeaderCell className={cn('header')} textAlign="right">
                {copyText.tableHeaderQuantity}
              </Table.HeaderCell>
              <Table.HeaderCell className={cn('header')} textAlign="right">
                {copyText.tableHeaderValue}
              </Table.HeaderCell>
              <Table.HeaderCell className={cn('header')} textAlign="right">
                {copyText.tableHeaderEntryPrice}
              </Table.HeaderCell>
              <Table.HeaderCell className={cn('header')} textAlign="right">
                {copyText.tableHeaderMarkPrice}
              </Table.HeaderCell>
              <Table.HeaderCell className={cn('header')} textAlign="right">
                {copyText.tableHeaderLiquidationPrice}
              </Table.HeaderCell>
              <Table.HeaderCell className={cn('header')} textAlign="right">
                {copyText.tableHeaderMargin}
              </Table.HeaderCell>
              <Table.HeaderCell className={cn('header')} textAlign="right">
                {copyText.tableHeaderUnrealizedPnL}
              </Table.HeaderCell>
              <Table.HeaderCell className={cn('header')} textAlign="right">
                {copyText.tableHeaderLiquidationValue}
              </Table.HeaderCell>
            </Table.Row>
          ) : (
            <Table.Row>
              <Table.HeaderCell className={cn('iconHeader')} />
              <Table.HeaderCell className={cn('header')}>
                {copyText.tableHeaderCustodian}
              </Table.HeaderCell>
              <Table.HeaderCell className={cn('header')} textAlign="right">
                {copyText.tableHeaderQuantity}
              </Table.HeaderCell>
              <Table.HeaderCell className={cn('header')} textAlign="right">
                {copyText.tableHeaderPrice}
              </Table.HeaderCell>
              <Table.HeaderCell className={cn('header')} textAlign="right">
                {copyText.tableHeaderCurrentValue}
              </Table.HeaderCell>
            </Table.Row>
          )}
        </Table.Header>
        <Table.Body>
          {accounts.map(account => {
            const balance = getTotalAccountBalance(account);

            const marketValue = balance * this.props.currentPrice;
            const custodianName = getCustodianName(account);

            const activities = this.props.activities;

            const { position } = account;

            return (
              <Fragment key={account.id}>
                <Table.Row
                  onClick={
                    this.props.renderExpandedContent
                      ? this._handleRowClick.bind(this, account)
                      : undefined
                  }>
                  <Table.Cell padding="0px 0px 0px 10px" textAlign="right">
                    <Icon
                      name={`triangle ${
                        this.props.openAccountId === account.id
                          ? 'down'
                          : 'right'
                      }`}
                    />
                  </Table.Cell>
                  <Table.Cell>{custodianName}</Table.Cell>
                  {this.props.forFutures === true ? (
                    <Fragment>
                      <Table.Cell textAlign="right">
                        <span>{getAssetDisplayText(account.currency).replace(SHORT_SUFFIX,'')}</span>
                        {getAssetDisplayText(account.currency).includes(SHORT_SUFFIX) && <span className={cn('short')}>{SHORT_SUFFIX}</span>}
                      </Table.Cell>
                      <Table.Cell
                        className={position.quantity < 0 ? cn('negative') : null}
                        textAlign="right">
                        {position.quantity}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Popup
                          content={getValueTooltip(
                            account,
                            this.props.homeCurrency
                          )}
                          inverted
                          position="top left"
                          trigger={
                            <span className={cn('value')}>
                              {getValue(account)}
                            </span>
                          }
                        />
                      </Table.Cell>
                      <Table.Cell
                        format={getPriceFormat(position.entryPrice)}
                        formatType={CELL_FORMAT_MONEY}>
                        {position.entryPrice}
                      </Table.Cell>
                      <Table.Cell formatType={CELL_FORMAT_MONEY}>
                        {position.markPrice}
                      </Table.Cell>
                      <Table.Cell formatType={CELL_FORMAT_MONEY}>
                        {position.liquidationPrice}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {getMargin(account)}
                      </Table.Cell>
                      <Table.Cell
                        textAlign="right"
                        className={position.unrealizedPnL < 0 ? cn('negative') : null}>
                        {getUnrealizedPnL(account)}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {displayInHomeCurrency(
                          getLiquidationValue(account, this.props.currentPriceLookupTable),
                          this.props.homeCurrency?.symbol
                        )}
                      </Table.Cell>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Table.Cell
                        formatType={CELL_FORMAT_NUMBER}
                        format="0.00000000">
                        {balance}
                      </Table.Cell>
                      <Table.Cell
                        formatType={CELL_FORMAT_MONEY}
                        format={getPriceFormat(get(this.props, 'currentPrice'))}
                        homecurrency={this.props.homeCurrency}>
                        {get(this.props, 'currentPrice')}
                      </Table.Cell>
                      <Table.Cell
                        formatType={CELL_FORMAT_MONEY}
                        format="0,0.00"
                        homecurrency={this.props.homeCurrency}>
                        {isNearZero(marketValue, MIN_QUANTITY) ? 0 : marketValue}
                      </Table.Cell>
                    </Fragment>
                  )}
                </Table.Row>
                {this.props.openAccountId === account.id ? (
                  <Table.Row>
                    <Table.Cell
                      colSpan={this.props.forFutures === true ? 11 : 6}
                      padding={30}>
                      {this.props.renderExpandedContent(
                        activities,
                        this.props.activitiesLoading,
                        account,
                        this.props.currentPrice,
                        this.props.currency
                      )}
                    </Table.Cell>
                  </Table.Row>
                ) : null}
              </Fragment>
            );
          })}
        </Table.Body>
      </StyledTable>
    );
  }

  _handleRowClick = (account, event) => {
    event.stopPropagation();
    this.props.handleExpandedRow(account.id);
  };
}
