import { arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import { Button, Popup } from 'semantic-ui-react';
import React, { Fragment, PureComponent } from 'react';
import styled from 'react-emotion';

import { get, has } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import { isBlank } from '@omniex/onx-common-js/lib/utils/StringUtils';
import { keyBy, sortBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { opaque } from '@omniex/onx-common-ui/lib/colors/utils';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './ClaimedQuoteRequestsTable.copyText';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import OrderStatus from '@omniex/onx-poms-entity-helpers/lib/enums/OrderStatus';
import ProcessOrderForm from '../components/ProcessOrderForm';
import QuoteStatus from '@omniex/onx-poms-entity-helpers/lib/enums/QuoteStatus';
import SendQuoteForm from './SendQuoteForm';
import Table, {
  CELL_FORMAT_NUMBER,
  CELL_FORMAT_TIMESTAMP_UTC
} from '@omniex/onx-common-ui/lib/semantic/react/Table';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/button.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');
require('@omniex/onx-common-ui/lib/semantic/css/table.css');
require('@omniex/onx-common-ui/lib/semantic/css/popup.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const COMPONENT_NAME = 'ClaimedQuoteRequestsTable';

const popupStyles = {
  container: {
    fontSize: '0.9em',
    padding: '8px 16px'
  },
  identifierLabel: {
    fontSize: '0.9em',
    textAlign: 'right'
  },
  identifier: {
    fontFamily: 'monospace',
    fontSize: '0.9em'
  }
};

const StyledTable = styled(Table)`
  .${COMPONENT_NAME}-currency {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .${COMPONENT_NAME}-expandedRowContent {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .${COMPONENT_NAME}-fixID {
    font-family: monospace;
    font-size: 0.9em;
  }

  .${COMPONENT_NAME}-quantity {
    padding-right: 0.5em !important;
  }

  .${COMPONENT_NAME}-actionLink {
    background-color: white !important;
    color: ${colors.orange};
    font-size: 0.9em !important;
    font-weight: normal;
    margin: 0 10px !important;
    padding: 0 !important;
  }

  .${COMPONENT_NAME}-actionLink:hover {
    color: ${opaque(colors.orange, 0.8)} !important;
    text-decoration: underline;
  }

  .${COMPONENT_NAME}-buttonWrapper {
    width: 60px !important;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class ClaimedQuoteRequestsTable extends PureComponent {
  static propTypes = {
    expandedRequestId: string,
    processingCancelOrder: bool,
    processingFillOrder: bool,
    processingSendQuote: bool,
    requests: arrayOf(
      shape({
        id: string.isRequired,
        fixClientID: string.isRequired,
        instrument: shape({
          id: string.isRequired,
          baseCurrency: object,
          displayName: string.isRequired,
          termCurrency: object
        }).isRequired,
        quotes: arrayOf(
          shape({
            id: string.isRequired,
            bidPrice: number.isRequired,
            fixQuoteID: string,
            offerPrice: number.isRequired,
            status: string.isRequired
          })
        ),
        specifiedCurrency: shape({
          id: string.isRequired,
          symbol: string.isRequired
        }).isRequired,
        side: string,
        specifiedQuantity: number.isRequired,
        timeCreated: string.isRequired,
        timeLastUpdated: string
      })
    ),
    orders: arrayOf(
      shape({
        id: string.isRequired,
        fixClOrdID: string.isRequired,
        fixOrderID: string,
        fixQuoteID: string.isRequired,
        fixVenueOrderID: string
      })
    ),
    onCancelOrder: func,
    onFillOrder: func,
    onSendQuote: func
  };

  static defaultProps = {
    onCancelOrder: noop,
    onFillOrder: noop,
    onSendQuote: noop
  };

  render() {
    const ordersKeyedByFixQuoteID = keyBy(this.props.orders, 'fixQuoteID');
    const requests = sortBy(this.props.requests, 'timeCreated').reverse();

    if (requests.length === 0) {
      return this._renderWarningMessage();
    }

    return (
      <StyledTable className={COMPONENT_NAME}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{copyText.tableHeaderTimestamp}</Table.HeaderCell>
            <Table.HeaderCell>{copyText.tableHeaderClient}</Table.HeaderCell>
            <Table.HeaderCell>
              {copyText.tableHeaderInstrument}
            </Table.HeaderCell>
            <Table.HeaderCell>{copyText.tableHeaderSide}</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              {copyText.tableHeaderQuantity}
            </Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell>{copyText.tableHeaderQuoteID}</Table.HeaderCell>
            <Table.HeaderCell>{copyText.tableHeaderOrderID}</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {requests.map(request => {
            const quotes = get(request, 'quotes', []);
            const activeQuote = quotes.find(quote =>
              [QuoteStatus.VALID, QuoteStatus.ACCEPTED].includes(quote.status)
            );

            let activeOrder;
            if (
              [
                OrderStatus.ACTIVE,
                OrderStatus.SENT,
                OrderStatus.PENDING_CANCEL
              ].includes(get(activeQuote, 'order.status'))
            ) {
              activeOrder = ordersKeyedByFixQuoteID[activeQuote.fixQuoteID];
            }

            return (
              <Fragment key={request.id}>
                <Table.Row>
                  <Table.Cell formatType={CELL_FORMAT_TIMESTAMP_UTC}>
                    {request.timeCreated}
                  </Table.Cell>
                  <Table.Cell>{request.fixClientID}</Table.Cell>
                  <Table.Cell>{get(request, 'instrument.displayName')}</Table.Cell>
                  <Table.Cell>
                    {isBlank(request.side) ? copyText.label2Way : request.side}
                  </Table.Cell>
                  <Table.Cell
                    className={cn('quantity')}
                    formatType={CELL_FORMAT_NUMBER}
                    format="0.00000000">
                    {request.specifiedQuantity}
                  </Table.Cell>
                  <Table.Cell className={cn('currency')}>
                    {getAssetDisplayText(get(request, 'specifiedCurrency'))}
                  </Table.Cell>
                  <Table.Cell
                    className={
                      has(activeQuote, 'fixQuoteID') ? cn('fixID') : ''
                    }>
                    {get(activeQuote, 'fixQuoteID', 'N/A')}
                  </Table.Cell>
                  <Table.Cell
                    className={
                      has(activeOrder, 'fixVenueOrderID') ? cn('fixID') : ''
                    }>
                    <Popup
                      flowing
                      hideOnScroll
                      hoverable
                      inverted
                      style={popupStyles.container}
                      trigger={
                        <span>
                          {get(activeOrder, 'fixVenueOrderID', 'N/A')}
                        </span>
                      }>
                      <table>
                        <tbody>
                          <tr>
                            <td style={popupStyles.identifierLabel}>
                              {copyText.popupHeaderEMSOrderID}:
                            </td>
                            <td style={popupStyles.identifier}>
                              {get(activeOrder, 'fixOrderID') ===
                              get(activeOrder, 'fixVenueOrderID')
                                ? 'N/A'
                                : get(activeOrder, 'fixOrderID')}
                            </td>
                          </tr>
                          <tr>
                            <td style={popupStyles.identifierLabel}>
                              {copyText.popupHeaderClientOrderID}:
                            </td>
                            <td style={popupStyles.identifier}>
                              {get(activeOrder, 'fixClOrdID', 'N/A')}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Popup>
                  </Table.Cell>
                  <Table.Cell className={cn('buttonWrapper')} textAlign="right">
                    {!activeOrder ? (
                      <Button
                        color="orange"
                        disabled={
                          this.props.processingFillOrder ||
                          this.props.processingRejectOrder ||
                          this.props.processingSendQuote
                        }
                        size="mini"
                        onClick={this._handleClickCancelButton.bind(
                          this,
                          request.id
                        )}>
                        {copyText.cancelButtonLabel}
                      </Button>
                    ) : null}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell colSpan={9}>
                    <div className={cn('expandedRowContent')}>
                      {!activeOrder ? (
                        <SendQuoteForm
                          initialBidPrice={get(activeQuote, 'bidPrice')}
                          initialOfferPrice={get(activeQuote, 'offerPrice')}
                          instrument={request.instrument}
                          processing={this.props.processingSendQuote}
                          requestId={request.id}
                          side={request.side}
                          onSubmit={this.props.onSendQuote}
                        />
                      ) : (
                        <ProcessOrderForm
                          order={activeOrder}
                          processingCancel={this.props.processingCancelOrder}
                          processingFill={this.props.processingFillOrder}
                          onCancel={this.props.onCancelOrder}
                          onFill={this.props.onFillOrder}
                        />
                      )}
                    </div>
                  </Table.Cell>
                </Table.Row>
              </Fragment>
            );
          })}
        </Table.Body>
      </StyledTable>
    );
  }

  _renderWarningMessage() {
    return (
      <div className={COMPONENT_NAME}>
        <Message content={copyText.warningMessage} warning />
      </div>
    );
  }

  _handleClickCancelButton(requestId) {
    this.props.onCancel(requestId);
  }
}
