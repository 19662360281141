const userRolesTableCopy = {
  cellLabelAdmin: 'ADMIN',
  roleTypeLookup_ADMIN: 'Admin',
  roleTypeLookup_CRYPTO_ACCOUNT_CONTROLLER: 'Crypto Account Controller',
  roleTypeLookup_FIAT_ACCOUNT_CONTROLLER: 'Fiat Account Controller',
  roleTypeLookup_PORTFOLIO_AUDITOR: 'Portfolio Auditor',
  roleTypeLookup_PORTFOLIO_MANAGER: 'Portfolio Manager',
  roleTypeLookup_SETTLEMENT_OFFICER: 'Settlement Officer',
  roleTypeLookup_TRADER: 'Trader',
  tableHeaderName: 'Name',
  tableHeaderRole: 'Role',
  tableHeaderPortfolios: 'Portfolios',
  tableHeaderStatus: 'Active',
  tableHeaderUsername: 'User Name',
  warningMessage: 'Please assign at least 1 role to this new user.'
};

export default userRolesTableCopy;
