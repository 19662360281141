import { bool, func, object } from 'prop-types';
import React from 'react';
import styled from 'react-emotion';

import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import QuotePageBlotterSection from './QuotePageBlotterSection';
import QuotePageRequestsSection from './QuotePageRequestsSection';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/grid.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');
require('@omniex/onx-common-ui/lib/semantic/css/popup.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const COMPONENT_NAME = 'QuotePage';

const StyledDiv = styled('div')`
  height: 100%;
  overflow: hidden;

  .${COMPONENT_NAME}-sectionWrapper {
    padding-top: 20px;
  }

  .${COMPONENT_NAME}-sectionWrapper:last-child {
    margin-bottom: 60px;
  }

  .ErrorPage {
    padding: 20px 0 0 0;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

const QuotePage = ({
  activeOrdersQueryData,
  activeOrdersQueryError,
  activeOrdersQueryLoading,
  activeOrdersSubscriptionData,
  activeOrdersSubscriptionError,
  activeOrdersSubscriptionLoading,
  activeQuoteRequestsQueryData,
  activeQuoteRequestsQueryError,
  activeQuoteRequestsQueryLoading,
  activeQuoteRequestsSubscriptionData,
  cancelOrder = noop,
  cancelOrderMutationError,
  cancelOrderMutationProcessing,
  cancelOrderMutationResult,
  cancelQuoteRequest = noop,
  cancelQuoteRequestMutationError,
  cancelQuoteRequestMutationProcessing,
  cancelQuoteRequestMutationResult,
  claimQuoteRequest = noop,
  claimQuoteRequestMutationError,
  claimQuoteRequestMutationProcessing,
  claimQuoteRequestMutationResult,
  // TODO currencies can be removed once the maker's `getRecentlyCompletedOrders` and `getActiveOrders` endpoint returns symbol along with type `Currency`
  currenciesQueryData,
  currenciesQueryError,
  currenciesQueryLoading,
  fillOrder = noop,
  fillOrderMutationError,
  fillOrderMutationProcessing,
  fillOrderMutationResult,
  instrumentsQueryData,
  instrumentsQueryError,
  instrumentsQueryLoading,
  recentlyCompletedOrdersQueryData,
  recentlyCompletedOrdersQueryError,
  recentlyCompletedOrdersQueryLoading,
  recentlyCompletedOrdersSubscriptionData,
  recentlyCompletedOrdersSubscriptionError,
  recentlyCompletedOrdersSubscriptionLoading,
  sendQuote = noop,
  sendQuoteMutationError,
  sendQuoteMutationProcessing,
  sendQuoteMutationResult,

}) => (
  <StyledDiv className={COMPONENT_NAME}>
      <main>
        <div id="quotes" className={cn('sectionWrapper')}>
          <QuotePageRequestsSection
            activeOrdersQueryData={activeOrdersQueryData}
            activeOrdersQueryError={activeOrdersQueryError}
            activeOrdersQueryLoading={activeOrdersQueryLoading}
            activeOrdersSubscriptionData={activeOrdersSubscriptionData}
            activeOrdersSubscriptionError={activeOrdersSubscriptionError}
            activeOrdersSubscriptionLoading={activeOrdersSubscriptionLoading}
            activeQuoteRequestsQueryData={activeQuoteRequestsQueryData}
            activeQuoteRequestsQueryError={activeQuoteRequestsQueryError}
            activeQuoteRequestsQueryLoading={activeQuoteRequestsQueryLoading}
            activeQuoteRequestsSubscriptionData={activeQuoteRequestsSubscriptionData}
            cancelOrder={cancelOrder}
            cancelOrderMutationError={cancelOrderMutationError}
            cancelOrderMutationProcessing={cancelOrderMutationProcessing}
            cancelOrderMutationResult={cancelOrderMutationResult}
            cancelQuoteRequest={cancelQuoteRequest}
            cancelQuoteRequestMutationError={cancelQuoteRequestMutationError}
            cancelQuoteRequestMutationProcessing={cancelQuoteRequestMutationProcessing}
            cancelQuoteRequestMutationResult={cancelQuoteRequestMutationResult}
            claimQuoteRequest={claimQuoteRequest}
            claimQuoteRequestMutationError={claimQuoteRequestMutationError}
            claimQuoteRequestMutationProcessing={claimQuoteRequestMutationProcessing}
            claimQuoteRequestMutationResult={claimQuoteRequestMutationResult}
            // this can be removed from maker's `getActiveOrders` returns the entire currency object
            currenciesQueryData={currenciesQueryData}
            currenciesQueryError={currenciesQueryError}
            currenciesQueryLoading={currenciesQueryLoading}
            fillOrder={fillOrder}
            fillOrderMutationError={fillOrderMutationError}
            fillOrderMutationProcessing={fillOrderMutationProcessing}
            fillOrderMutationResult={fillOrderMutationResult}
            instrumentsQueryData={instrumentsQueryData}
            instrumentsQueryError={instrumentsQueryError}
            instrumentsQueryLoading={instrumentsQueryLoading}
            sendQuote={sendQuote}
            sendQuoteMutationError={sendQuoteMutationError}
            sendQuoteMutationProcessing={sendQuoteMutationProcessing}
            sendQuoteMutationResult={sendQuoteMutationResult}
          />
        </div>
        <div id="blotter" className={cn('sectionWrapper')}>
          <QuotePageBlotterSection
            // this should be all currencies
            currenciesQueryData={currenciesQueryData}
            currenciesQueryError={currenciesQueryError}
            currenciesQueryLoading={currenciesQueryLoading}
            instrumentsQueryData={instrumentsQueryData}
            instrumentsQueryError={instrumentsQueryError}
            instrumentsQueryLoading={instrumentsQueryLoading}
            recentlyCompletedOrdersQueryData={recentlyCompletedOrdersQueryData}
            recentlyCompletedOrdersQueryError={recentlyCompletedOrdersQueryError}
            recentlyCompletedOrdersQueryLoading={recentlyCompletedOrdersQueryLoading}
            recentlyCompletedOrdersSubscriptionData={recentlyCompletedOrdersSubscriptionData}
            recentlyCompletedOrdersSubscriptionError={recentlyCompletedOrdersSubscriptionError}
            recentlyCompletedOrdersSubscriptionLoading={recentlyCompletedOrdersSubscriptionLoading}
          />
        </div>
      </main>
  </StyledDiv>
)

QuotePage.propTypes = {
  activeOrdersQueryData: object,
  activeOrdersQueryError: object,
  activeOrdersQueryLoading: bool,
  activeOrdersSubscriptionData: object,
  activeOrdersSubscriptionError: object,
  activeOrdersSubscriptionLoading: bool,
  activeQuoteRequestsQueryData: object,
  activeQuoteRequestsQueryError: object,
  activeQuoteRequestsQueryLoading: bool,
  activeQuoteRequestsSubscriptionData: object,
  cancelOrder: func,
  cancelOrderMutationError: object,
  cancelOrderMutationProcessing: bool,
  cancelOrderMutationResult: object,
  cancelQuoteRequest: func,
  cancelQuoteRequestMutationError: object,
  cancelQuoteRequestMutationProcessing: bool,
  cancelQuoteRequestMutationResult: object,
  claimQuoteRequest: func,
  claimQuoteRequestMutationError: object,
  claimQuoteRequestMutationProcessing: bool,
  claimQuoteRequestMutationResult: object,
  currenciesQueryData: object,
  currenciesQueryError: object,
  currenciesQueryLoading: bool,
  fillOrder: func,
  fillOrderMutationError: object,
  fillOrderMutationProcessing: bool,
  fillOrderMutationResult: object,
  instrumentsQueryData: object,
  instrumentsQueryError: object,
  instrumentsQueryLoading: bool,
  recentlyCompletedOrdersQueryData: object,
  recentlyCompletedOrdersQueryError: object,
  recentlyCompletedOrdersQueryLoading: bool,
  recentlyCompletedOrdersSubscriptionData: object,
  recentlyCompletedOrdersSubscriptionError: object,
  recentlyCompletedOrdersSubscriptionLoading: bool,
  sendQuote: func,
  sendQuoteMutationError: object,
  sendQuoteMutationProcessing: bool,
  sendQuoteMutationResult: object,
  user: object,
}

export default QuotePage
