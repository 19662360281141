import { createSelector } from 'reselect';

import { groupBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';

export function getMarketDataEntriesGroupedByType(entries) {
  if (!Array.isArray(entries) || entries.length === 0) return [];

  return groupBy(entries, 'type');
}

export default createSelector(
  accounts => accounts,
  getMarketDataEntriesGroupedByType
);
