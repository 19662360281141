const proceedMessageCopy = {
  algoConfirmationContent: 'If you proceed, an algorithm will be executed using the specified strategy.',
  algoConfirmationHeader: 'You are about to place an order for ~%marketValue%. Are you sure you want to proceed?',
  algoInsufficientFunds: 'Our records indicate insufficient funds at the following selected venues for this trade. Execute anyway?',
  dmaConfirmationContent: 'If you proceed, an order will be sent to the selected exchange.',
  dmaConfirmationHeader: 'You are about to place an order for ~%marketValue%. Are you sure you want to proceed?',
  dmaInsufficientFunds: 'Our records indicate there are insufficient funds (%fiatSymbol%%availableFunds%%outgoingCurrency%) available to complete this trade. Execute anyway?',
  contractMessageHeader:
    'You are about to place an order to %side% %quantity% %contractPluralization% of %instrument% at ~%price% with:',
  contractOrderValue: 'Order Value:',
  contractAvailableBalance: 'Available Balance:',
  contractPositionAfterExecution: 'Position After Exec.:',
  contractMarkPrice: 'Mark Price:',
};

export default proceedMessageCopy;
