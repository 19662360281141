// Ryan wrote this in Client Support Tool
export const clamp = (n, min = n, max = n) => Math.min(max, Math.max(min, n))

/*
 * Map a number n in the range [iMin, iMax] to its equivalent value in the
 * range [oMin, oMax]. For example, mapToRange(0.25, 0, 1, 0, 100) === 25.
 * If clampInput is true (default), n is clamped to [iMin, iMax].
 */
export const mapToRange = (n, iMin, iMax, oMin, oMax, clampInput = true) => iMin !== iMax
    ? (clampInput ? clamp(n, iMin, iMax) : n) * (oMax - oMin) / (iMax - iMin)
    : 0
