import gql from 'graphql-tag';

export default gql`
  mutation createApiKey($label: String!) {
    apiKey: createApiKey(label: $label) {
      active
      id
      key
      name
      org {
        id
      }
      secret
      timeCreated
      timeLastUpdated
    }
  }
`;
