import gql from 'graphql-tag';

// TODO re-evaluate if this should just replace getInstruments. all the calls with to getInstruments end up
// having additional lookups to get the full base and term currencies
export default gql`
  query getInstruments {
    instruments: getInstruments {
      id
      symbol
      type
      baseAsset {
        id
        name
        symbol
        type
        expiry
      }
      termAsset {
        id
        name
        symbol
        type
        expiry
      }
      marginAsset {
        id
        name
        symbol
        type
        expiry
      }
      mdInstrument {
        id
      }
      displayName
      identifier
    }
  }
`;
