const requestsIntegrationTableCopy = {
  accountHolderOrg: 'Account Holder Organization:',
  actionButtonActivate: 'Activate',
  actionButtonBlock: 'Block',
  actionButtonDelete: 'Delete',
  inputPlaceholder_accNumber: 'Account Number',
  orgNameOmniex: 'Organization Name @ Omniex:',
  selectedOrgNames: 'Organization Name @ Silvergate',
  silvergateAccountNumber: 'Account Number @ Silvergate:',
  tableHeaderOrg: 'Organization',
  tableHeaderProvider: 'Venue Provider',
  tableHeaderRequestDate: 'Request Date',
  tableHeaderType: 'Venue Type',
  tableHeaderUsername: 'User',
  typeCustodian: 'Custodian',
  venueSilvergate: 'Silvergate',
  warningMessageBlocked: 'There are no blocked integrations at this time.',
  warningMessageRequested: 'There are no requested integrations at this time.',
  warningMessageVerified: 'There are no verified integrations at this time.'
};

export default requestsIntegrationTableCopy;
