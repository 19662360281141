const manualEntryFormCopy = {
  accountNamePrefixUnsettled: 'Unsettled',
  cancelButtonLabel: 'Cancel',
  createNewAssetLabel: '+ Create Asset',
  createNewAccountLabel: '+ Create New Account',
  inputPlaceholder_account: 'Account',
  inputPlaceholder_adjustedCostBasis: 'Total Adjusted Cost Basis',
  inputPlaceholder_asset: 'Asset',
  inputPlaceholder_assetName: 'Asset Name',
  inputPlaceholder_assetSymbol: 'Asset Symbol (e.g. XTC)',
  inputPlaceholder_custodian: 'Custodian',
  inputPlaceholder_datestamp: 'Datestamp',
  inputPlaceholder_executionPrice: 'Execution Price',
  inputPlaceholder_fees: 'Total Explicit Fees',
  inputPlaceholder_fromAccountId_instrument: 'From Account (Instrument %side%)',
  inputPlaceholder_fromAccountId: 'From Account',
  inputPlaceholder_positionQuantity: 'Quantity',
  inputPlaceholder_price: 'Price',
  inputPlaceholder_quantity: 'Quantity',
  inputPlaceholder_termCurrencyReferenceRate: 'Term Currency Reference Rate',
  inputPlaceholder_time: 'HH:MM',
  inputPlaceholder_toAccountId_instrument: 'To Account (Instrument %side%)',
  inputPlaceholder_toAccountId: 'To Account',
  inputPlaceholder_venueId: 'Venue',
  integrationTypeOption_CUSTODIAN_SILVERGATE: 'Silvergate Custodian',
  integrationTypeOption_EXCHANGE_BEQUANT: 'BeQuant Exchange',
  integrationTypeOption_EXCHANGE_BINANCE: 'Binance Exchange',
  integrationTypeOption_EXCHANGE_BINANCEUS: 'Binance US Exchange',
  integrationTypeOption_EXCHANGE_BITFINEX: 'Bitfinex Exchange',
  integrationTypeOption_EXCHANGE_BITFLYER: 'Bitflyer Exchange',
  integrationTypeOption_EXCHANGE_BITMEX: 'BitMEX Exchange',
  integrationTypeOption_EXCHANGE_BITSTAMP: 'Bitstamp Exchange',
  integrationTypeOption_EXCHANGE_BITTREX: 'Bittrex Exchange',
  integrationTypeOption_EXCHANGE_BYBIT: 'Bybit Exchange',
  integrationTypeOption_EXCHANGE_CROSSTOWER: 'CrossTower Exchange',
  integrationTypeOption_EXCHANGE_DERIBIT: 'Deribit Exchange',
  integrationTypeOption_EXCHANGE_FTX: 'FTX Exchange',
  integrationTypeOption_EXCHANGE_GDAX: 'Coinbase Pro Exchange',
  integrationTypeOption_EXCHANGE_GEMINI: 'Gemini Exchange',
  integrationTypeOption_EXCHANGE_HITBTC: 'HitBTC Exchange',
  integrationTypeOption_EXCHANGE_HUOBI: 'Huobi Exchange',
  integrationTypeOption_EXCHANGE_HUOBIDM: 'Huobi DM Exchange',
  integrationTypeOption_EXCHANGE_ITBIT: 'itBit Exchange',
  integrationTypeOption_EXCHANGE_KRAKEN: 'Kraken Exchange',
  integrationTypeOption_EXCHANGE_KUCOIN: 'KuCoin Exchange',
  integrationTypeOption_EXCHANGE_LIQUID: 'Liquid Exchange',
  integrationTypeOption_EXCHANGE_LMAX: 'LMAX Exchange',
  integrationTypeOption_EXCHANGE_OKCOIN: 'OKCoin Exchange',
  integrationTypeOption_EXCHANGE_OKEX: 'OKEx Exchange',
  integrationTypeOption_EXCHANGE_POLONIEX: 'Poloniex Exchange',
  integrationTypeOption_EXCHANGE_SEEDCX: 'Seed CX Exchange',
  instrumentSideBase: 'Base',
  instrumentSideTerm: 'Term',
  saveButtonLabel: 'Save',
  transactionTypeOption_BUY: 'Buy',
  transactionTypeOption_DEPOSIT: 'Deposit',
  transactionTypeOption_SELL: 'Sell',
  transactionTypeOption_WITHDRAWAL: 'Withdrawal'
};

export default manualEntryFormCopy;
