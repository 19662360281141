import { Dictionary, OTCOrderTypeOption, OTCBaseData, OTCTileData, Order, OTCTemplateData, OTCOrderType } from '../../../types';
import ORDER_TYPE from '@omniex/poms-core/lib/enums/OrderType';
import { fonts, Palette } from '@omniex/poms-ui';
import { PaletteColor } from '@material-ui/core/styles/createPalette';
import OrderStatus from '@omniex/poms-core/lib/enums/OrderStatus';
import OrderSide from '@omniex/poms-core/lib/enums/OrderSide';
import OrderExpiryType from '@omniex/poms-core/lib/enums/OrderExpiryType';

export const OTC_ORDER_TYPES: ReadonlyArray<Readonly<OTCOrderTypeOption>> = [
  {
    name: OTCOrderType.AS_QUOTED,
    expiryType: OrderExpiryType.FOK,
    hasLimitPrice: false, // limit price inferred from quote
    hasStopPrice: false,
    resting: false,
    orderType: ORDER_TYPE.PREVIOUSLY_QUOTED,
  },
  {
    name: OTCOrderType.MARKET,
    expiryType: OrderExpiryType.FOK,
    hasLimitPrice: false,
    hasStopPrice: false,
    resting: false,
    orderType: ORDER_TYPE.MARKET,
  },
  {
    name: OTCOrderType.LIMIT_FOK,
    expiryType: OrderExpiryType.FOK,
    hasLimitPrice: true,
    hasStopPrice: false,
    resting: false,
    orderType: ORDER_TYPE.LIMIT,
  },
  {
    name: OTCOrderType.LIMIT_GTC,
    expiryType: OrderExpiryType.GTC,
    hasLimitPrice: true,
    hasStopPrice: false,
    resting: true,
    orderType: ORDER_TYPE.LIMIT,
  },
  {
    name: OTCOrderType.STOP_MARKET,
    expiryType: OrderExpiryType.FOK,
    hasLimitPrice: false,
    hasStopPrice: true,
    resting: true,
    orderType: ORDER_TYPE.STOP_LOSS,
  },
  {
    name: OTCOrderType.STOP_LIMIT,
    expiryType: OrderExpiryType.FOK,
    hasLimitPrice: true,
    hasStopPrice: true,
    resting: true,
    orderType: ORDER_TYPE.STOP_LIMIT,
  },
];

export function filterDict<T = any>(
  object: Dictionary<T>,
  filterFn: (value: T, key: string, object: Dictionary<T>) => unknown,
) {
  const res: Dictionary<T> = {};
  for (const key of Object.keys(object)) {
    const value = object[key];
    if (filterFn(value, key, object)) res[key] = value;
  }

  return res;
}

export function cloneBase<T extends OTCBaseData>(original: T) {
  const { id, instrumentId, orderType, quantity, venueIds } = original;
  const copy: OTCBaseData = {};
  if (id) copy.id = id;
  if (instrumentId) copy.instrumentId = instrumentId;
  if (orderType) copy.orderType = orderType;
  if (quantity) copy.quantity = quantity;
  if (venueIds && venueIds.length) copy.venueIds = [...venueIds];
  return copy as T extends OTCTemplateData ? OTCTemplateData : OTCBaseData;
}

export function cloneForm<T extends OTCBaseData>(original: T) {
  const { limitPrice, stopPrice } = (original as OTCTileData);
  const copy: Partial<OTCTileData> = cloneBase(original);
  if (limitPrice) copy.limitPrice = limitPrice;
  if (stopPrice) copy.stopPrice = stopPrice;
  return copy as T extends OTCTileData
    ? OTCTileData : T extends OTCTemplateData
    ? OTCTemplateData : OTCBaseData;
}

export type Size = keyof typeof fonts.displayFonts

export type SizeStyles = Partial<Record<Size, React.CSSProperties>>

export const sizeStyles: SizeStyles = Object.keys(fonts.displayFonts).reduce((styles: SizeStyles, size) => {
  styles[size as Size] = {
    ...fonts.displayFonts[size as Size],
    height: fonts.displayFonts[size as Size].lineHeight,
  }
  return styles
}, {})

export const fontSizeStyles: SizeStyles = Object.keys(fonts.displayFonts).reduce((styles: SizeStyles, size) => {
  styles[size as Size] = {
    ...fonts.displayFonts[size as Size],
  }
  return styles
}, {})

export type Color = keyof Palette

export type ColorStyles = Partial<Record<Color, React.CSSProperties>>

export type ColorStylesFunc = (palette: Palette) => ColorStyles

export const colorStyles: ColorStylesFunc = palette => Object.keys(palette).reduce((styles: ColorStyles, color) => {
  const c = (palette[color as Color] as PaletteColor)?.main
  if (c) styles[color as Color] = { color: c }
  return styles
}, {})

export const backgroundColorStyles: ColorStylesFunc = palette => Object.keys(palette).reduce((styles: ColorStyles, color) => {
  const c = (palette[color as Color] as PaletteColor)?.main
  if (c) styles[color as Color] = { backgroundColor: c }
  return styles
}, {})

export const statusToColor: Record<string, keyof Palette> = {
  [OrderSide.BUY]: 'buy',
  [OrderSide.SELL]: 'sell',
  [OrderStatus.CANCELED]: 'alert1',
  [OrderStatus.FILLED]: 'affirm',
  [OrderStatus.REJECTED]: 'alert1',
}

export const getStatusColorKey = ({ error, side, status }: Partial<Pick<Order, 'side' | 'status'> & { error: boolean }>) =>
  error ? 'alert1' : statusToColor[status as OrderStatus] || statusToColor[side as OrderSide]

export const statusToText: Record<string, string> = {
  [OrderStatus.ACTIVE]: 'ACTIVE',
  [OrderStatus.CANCELED]: 'CANCELED',
  [OrderStatus.CANCELLATION_REQUESTED]: 'CANCELLATION REQUESTED',
  [OrderStatus.FILLED]: 'FILLED',
  [OrderStatus.PARTIALLY_FILLED]: 'ACTIVE',
  [OrderStatus.PENDING_CANCEL]: 'PENDING CANCEL',
  [OrderStatus.REJECTED]: 'REJECTED',
  [OrderStatus.SENT]: 'ACTIVE',
}
