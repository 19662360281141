import { arrayOf, func, object, shape, string } from 'prop-types';
import { Button, Form, Message } from 'semantic-ui-react';
import { createSelector } from 'reselect';
import React, { Fragment, PureComponent } from 'react';
import styled from 'react-emotion';

import { isEmpty } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { sortBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import copyText from './SaveFiatAccountForm.copyText';
import FormSelect from '@omniex/onx-common-ui/lib/semantic/react/FormSelect';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/button.css');
require('@omniex/onx-common-ui/lib/semantic/css/form.css');
require('@omniex/onx-common-ui/lib/semantic/css/input.css');
require('@omniex/onx-common-ui/lib/semantic/css/dropdown.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const COMPONENT_NAME = 'SaveFiatAccountForm';

const StyledForm = styled(Form)`
  min-width: 280px;

  .${COMPONENT_NAME}-secondConfirmationMessage {
    font-size: 1.3em;
  }

  .${COMPONENT_NAME}-buttonWrapper {
    text-align: right;
  }

  .${COMPONENT_NAME}-button {
    margin: 0;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class SaveFiatAccountForm extends PureComponent {
  static propType = {
    currencies: arrayOf(
      shape({
        id: string.isRequired,
        symbol: string.isRequired,
        type: string.isRequired
      })
    ),
    homeCurrency: shape({
      id: string.isRequired
    }),
    custodians: arrayOf(
      shape({
        id: string.isRequired,
        name: string.isRequired
      })
    ),
    usedCurrenciesGroupedByCustodian: object,
    onCancel: func,
    onSave: func
  };

  static defaultProps = {
    currencies: [],
    custodians: [],
    onCancel: noop,
    onSave: noop
  };

  state = {
    currencyId: '',
    custodian: {},
    freeForm: '',
    name: '',
    showSecondConfirmation: false
  };

  _getCustodianOptions = createSelector(
    custodians => custodians,
    getCustodianOptions
  );

  _getCurrencyOptions = createSelector(
    currencies => currencies,
    getCurrencyOptions
  );

  render() {
    const { custodian, showSecondConfirmation } = this.state;
    const { currencies, custodians, existingCurrencies } = this.props;

    const existing = existingCurrencies[`${custodian.id}_${custodian.__typename}`] || []
    let currencyOptions = this._getCurrencyOptions(currencies.filter(currency => !existing.some(e => e.currencyId === currency.id)));
    currencyOptions = sortBy(currencyOptions, 'text');

    const custodianOptions = this._getCustodianOptions(custodians);

    return (
      <StyledForm
        className={COMPONENT_NAME}
        autoComplete="off"
        warning={this.state.showSecondConfirmation}>
        <FormSelect
          name="custodian"
          options={custodianOptions}
          placeholder={copyText.inputPlaceholder_custodianId}
          onChange={this._handleChangeField}
        />
        <FormSelect
          name="currencyId"
          isDisabled={!custodian.id}
          options={currencyOptions}
          placeholder={copyText.inputPlaceholder_currencyId}
          onChange={this._handleChangeField}
        />
        {showSecondConfirmation ? (
          <Fragment>
            <Message
              content={copyText.confirmationMessageContent}
              header={copyText.confirmationMessageHeader}
              warning
            />
            <Form.Field className={cn('buttonWrapper')}>
              <Button
                className={cn('button')}
                color="orange"
                disabled={!this._canSave()}
                fluid
                size="tiny"
                type="submit"
                onClick={this._handleSubmit.bind(this)}>
                {copyText.proceedButtonLabel}
              </Button>
            </Form.Field>
            <Form.Field className={cn('buttonWrapper')}>
              <Button
                className={cn('button')}
                fluid
                size="tiny"
                type="reset"
                onClick={this._handleClickCancelConfirmation.bind(this)}>
                {copyText.cancelButtonLabel}
              </Button>
            </Form.Field>
          </Fragment>
        ) : (
          <Fragment>
            <Message
              content={copyText.confirmationMessageContent}
              header={copyText.confirmationMessageHeader}
              warning
            />
            <Form.Field className={cn('buttonWrapper')}>
              <Button
                className={cn('button')}
                color="orange"
                disabled={!this._canSave()}
                fluid
                size="tiny"
                onClick={this._handleClickFirstConfirmationButton.bind(this)}>
                {copyText.saveButtonLabel}
              </Button>
            </Form.Field>
            <Form.Field className={cn('buttonWrapper')}>
              <Button
                className={cn('button')}
                fluid
                size="tiny"
                type="reset"
                onClick={this._handleClickCancelButton.bind(this)}>
                {copyText.cancelButtonLabel}
              </Button>
            </Form.Field>
          </Fragment>
        )}
      </StyledForm>
    );
  }

  _canSave() {
    const {custodian, currencyId, } = this.state
    return (
      !isEmpty(custodian) &&
      !isEmpty(currencyId)
    );
  }

  _handleChangeField = (event, input) => {
    const name = input.name;
    const value = event.value || input.value;

    let changes = { [name]: value };

    if (changes.custodian) {
      changes.currencyId = '';
      changes.freeForm = '';
    }

    if (changes.currencyId) {
      changes.freeForm = '';
    }

    this.setState(changes);
  };

  _handleClickCancelButton = event => {
    event.preventDefault();

    this.props.onCancel();
  };

  _handleClickFirstConfirmationButton = event => {
    event.preventDefault();
    this.setState({ showSecondConfirmation: true });
  };

  _handleClickCancelConfirmation = event => {
    event.preventDefault();
    this.setState({ showSecondConfirmation: false });
  };

  _handleSubmit = event => {
    event.preventDefault();
    const { currencyId, freeForm, name, custodian} = this.state;

    let custodianId, customCustodianId
    if (custodian.__typename === 'CustomCustodian') {
      customCustodianId = custodian.id;
    } else {
      custodianId = custodian.id;
    }

    this.props.onSave({
      currencyId,
      ...(!isEmpty(name) ? { name } : {}),
      ...(!isEmpty(custodianId) ? { custodianId } : {}),
      ...(!isEmpty(customCustodianId) ? { customCustodianId } : {}),
      ...(!isEmpty(freeForm) ? { freeForm } : {}),
      ...(!isEmpty(freeForm) ? { description: 'Default' } : {})
    });
  };
}

function getCurrencyOptions(currencies) {
  if (!Array.isArray(currencies) || !currencies.length) return [];

  return currencies.map(currency => ({
    label: currency.symbol,
    value: currency.id
  }));
}

function getCustodianOptions(custodians) {
  if (!Array.isArray(custodians) || !custodians.length) {
    return [];
  }

  return custodians.map(custodian => ({
    label: custodian.name,
    value: custodian,
  }));
}
