import React from 'react';
import { useCallback, useEffect, useMemo } from 'react';

import { AlertCircleIcon, AlertInfoIcon, AlertOctagonIcon, AlertTriangleIcon } from '@omniex/poms-ui/lib/components/Icon';
import { ApolloClient, NetworkStatus } from 'apollo-client';
import { fonts } from '@omniex/poms-ui/lib/constants';
import { defaultLightTheme } from '@omniex/poms-ui/lib/themes';
import { getExecutionType } from '@omniex/poms-core/lib/utils/OrderUtils';
import { Grid, GridBody, GridCell, GridCellHead, GridRow } from '@omniex/poms-ui/lib/components/Grid';
import { GridCellAlignment } from '@omniex/poms-ui/lib/types';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { makeStyles } from '@omniex/poms-ui';
import { useQuery } from '@apollo/react-hooks';
import NotificationsInspector from '@omniex/poms-ui/lib/components/Notifications/NotificationsInspector/NotificationsInspector';
import OrderAlgorithmStrategy from '@omniex/poms-core/lib/enums/OrderAlgorithmStrategy';

import { CategoricalFilterHook, CategoricalQueryArgs, useCategoricalFilter } from '../../hooks/useCategoricalFilter';
import { changed } from '../../../utils/PerformanceUtils';
import { computeDisplayFields } from '../../../utils/OrderNotificationUtils';
import { DateRangeQueryArgs } from '../../hooks/useDateRangeFilter';
import { DistinctNotificationsValueData, PaginatedNotificationQData } from '../../../types';
import { executionType, getExecutionTypeOptions } from '../../../utils/FilterUtils';
import { ExclusiveCategoryFilterHook, ExclusiveCategoryQueryArgs, useExclusiveCategoryFilter } from '../../hooks/useExclusiveCategoryFilter';
import { getDateFormatSimple } from '../../../utils/DisplayUtils'
import { mergeUpdate } from '../../../utils/QueryUtils';
import { NumericalFilterHook, NumericalQueryArgs, useNumericalFilter } from '../../hooks/useNumericalFilter';
import { SortOrder, useSort } from '../../hooks/useSort';
import getDistinctNotificationValues from '../../../apollo/graphql/buy-side/getDistinctNotificationValues';
import getPaginatedOrderNotifications from '../../../apollo/graphql/buy-side/getPaginatedOrderNotifications';
import OrderIDPopup from '../OrderIDPopupComponent';

const INITIAL_LIMIT = 100
const INITIAL_OFFSET = 0
const PAGE_LIMIT = 100
const SCROLL_THRESHOLD = 50

const DEFAULT_PAGINATED_VARS: NotificationPaginationVariables = {
  filters: {},
  limit: INITIAL_LIMIT,
  offset: INITIAL_OFFSET,
  sortDirection: 'DESC',
  sortParameter: 'timeCreated'
}
interface AllNotificationsInspectorProps {
  apiClient: ApolloClient<NormalizedCacheObject> | undefined;
  onRowClick: (fixClOrdID: string) => void;
  onClose: () => void;
  open?: boolean
}

type ExecutionTypeFilterArgs = { algorithmStrategy?: CategoricalQueryArgs, feature?: CategoricalQueryArgs}

type CondensedFilter = CategoricalQueryArgs | DateRangeQueryArgs | ExclusiveCategoryQueryArgs | ExecutionTypeFilterArgs | NumericalQueryArgs

type CondensedFilters = { [column: string]: CondensedFilter }
interface NotificationPaginationVariables {
  filters: CondensedFilters
  limit: number
  offset: number
  sortDirection: SortOrder
  sortParameter: string
}

const useStyles = makeStyles(({ palette }) => ({
  errorIcon: { ...fonts.displayFonts.small, color: palette.alert1.main },
  infoIcon: { ...fonts.displayFonts.small, color: palette.system2.main },
  successIcon: { ...fonts.displayFonts.small, color: palette.affirm.main },
  warningIcon: { ...fonts.displayFonts.small, color: palette.alert2.main },
  gridCellHead: { fontSize: 13, fontWeight: 600, height: '50px' },
  gridCell: { fontSize: 13, lineHeight: '16px' },
  notificationInspector:{
    '& .MuiContainer-maxWidthLg': { maxWidth: '1400px' },
    '& .MuiContainer-root': {
      width: '100%',
      '& > div:nth-child(2)': {height: 'calc(100% - 70px)' },
      '& > div > div > div': { height: '70px' }, // Lmk when someone finds a better way
      '& > div > div > div > div': { height: '70px' },
      '& > div > div > div > div > div': { fontSize: 16, fontWeight: 600 },
    },
    '& .MuiPaper-root': { height: '80%' },
  }
}), { name: 'AllNotificationsInspector', defaultTheme: defaultLightTheme });

export const AllNotificationsInspector = ({ apiClient, onRowClick, onClose, open = false }: AllNotificationsInspectorProps) => {

  // Styles

  const classes = useStyles();

  const Icons: Record<string, any> = useMemo(() => ({
    error: <AlertOctagonIcon className={classes.errorIcon} size="large"/>,
    info: <AlertInfoIcon className={classes.infoIcon} size="large" />,
    success: <AlertCircleIcon className={classes.successIcon} size="large" />,
    warning: <AlertTriangleIcon className={classes.warningIcon} size="large" />
  }), [classes])

  // Filters

  const { data: distinctData } = useQuery<DistinctNotificationsValueData, {}>(getDistinctNotificationValues, { client: apiClient, fetchPolicy: 'network-only' });

  const executionTypeOptions = useMemo(() => (
    getExecutionTypeOptions({
      algorithmStrategies: distinctData?.distinctValues.algorithmStrategies || [],
      features: distinctData?.distinctValues.features || []
    }).sort((a, b) => a.value.localeCompare(b.value))
  ), [distinctData])

  const executionFilter = useCategoricalFilter({ options: executionTypeOptions })

  // const fixClOrdIDOptions = useMemo(() => (
  //   distinctData?.distinctValues.fixClOrdIDs
  //     .map(value => ({ name: value, value }))
  //     .sort((a, b) => a.name.localeCompare(b.name)) || []
  // ), [distinctData])

  // const fixClOrdIDFilter = useCategoricalFilter({ options: fixClOrdIDOptions })

  const instrumentOptions = useMemo(() => (
    distinctData?.distinctValues.instruments
      .map(({ displayName, id, identifier }) => ({ name: identifier ?? displayName, value: id }))
      .sort((a, b) => a.name.localeCompare(b.name)) || []
  ), [distinctData])

  const instrumentFilter = useCategoricalFilter({ options: instrumentOptions })

  const quantityFilter = useNumericalFilter({});

  const sideOptions = useMemo(() => (
    distinctData?.distinctValues.sides.map(value => ({ name: value, value })) || []
  ), [distinctData])

  const sideFilter = useExclusiveCategoryFilter({ options: sideOptions });

  // const timeCreatedFilter = useDateRangeFilter({});

  const venueOptions = useMemo(() => (
    distinctData?.distinctValues.venues
      .map(({ id, name }) => ({ name, value: id }))
      .sort((a, b) => a.name.localeCompare(b.name)) || []
  ), [distinctData])

  const venueFilter = useCategoricalFilter({ options: venueOptions })

  const condensedFilters: CondensedFilters = useMemo(() => {
    return {
      ...condenseCategoricalFilterOptions('executionType', executionFilter),
      // ...condenseCategoricalFilterOptions('fixClOrdIDs', fixClOrdIDFilter),
      ...condenseCategoricalFilterOptions('instrumentIds', instrumentFilter),
      ...condenseNumericalFilterOptions('quantity', quantityFilter),
      ...condenseExclusiveCategoryFilterOptions('side', sideFilter),
      // ...condenseDateRangeFilterOptions('timeCreated', timeCreatedFilter),
      ...condenseCategoricalFilterOptions('venueIds', venueFilter),
    }
  }, [executionFilter, instrumentFilter, quantityFilter, sideFilter, venueFilter])

  // Sort

  const { queryArgs: sortQueryArgs, handleSort } = useSort('timeCreated');
  
  // Paginated Data

  const { data: paginatedData, fetchMore: fetchNextPage, loading, refetch: refetchData, networkStatus, variables: queryVars } = useQuery<PaginatedNotificationQData, NotificationPaginationVariables>(getPaginatedOrderNotifications, {
    client: apiClient,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true, 
    variables: DEFAULT_PAGINATED_VARS
  })

  const isFetching = networkStatus === NetworkStatus.refetch || networkStatus === NetworkStatus.fetchMore || loading;

  const rows = useMemo(() => {
    let rowData = paginatedData?.notificationData?.notifications || []
    return rowData.map(row => {
      const { orderDetailText = '--', status = 'info', subject = '--' } = computeDisplayFields(row.order, row) || {}
      const executionType = getExecutionType(row.order)
      return {
        icon: status,
        timeCreated: getDateFormatSimple(row.timeCreated, true),
        executionType,
        reason: subject,
        text: orderDetailText,
        venue: row.venue?.name || '--',
        fixClOrdID: {
          fixClOrdID: row.order?.fixClOrdID,
          fixOrderID: row.order?.fixOrderID,
          fixQuoteID: row.order?.fixQuoteID,
          fixVenueOrderID: row.order?.feature !== 'ALGO' ? row.order?.fixVenueOrderID : undefined,
          source: row.order?.source,
          venue: row.order?.venue,
        },
        side: row.order?.side,
        quantity: row.order?.specifiedQuantity,
        instrument: row.order?.instrument?.identifier ?? row.order?.instrument?.displayName
      }
    })
  }, [paginatedData])

  const paginationVars = useMemo(() => ({
    filters: condensedFilters,
    limit: PAGE_LIMIT,
    offset: rows.length,
    sortDirection: sortQueryArgs.direction,
    sortParameter: sortQueryArgs.id
  }), [condensedFilters, rows, sortQueryArgs])

  const refetchPaginationVars = useMemo(() => ({ ...paginationVars, limit: INITIAL_LIMIT, offset: INITIAL_OFFSET }), [paginationVars])

  const loadMore = useCallback(async () => {
    try {
        await fetchNextPage({ variables: paginationVars, updateQuery: notificationUpdateQuery })
    } catch (e) {
      console.error(e);
    }
  }, [fetchNextPage, paginationVars])

  const getFreshData = useCallback(async () => {
    try {
      await refetchData({ ...paginationVars, offset: INITIAL_OFFSET })
    } catch (e) {
      console.error(e);
    }
  }, [refetchData, paginationVars])

  useEffect(() => {
    // For refetching on sort/filter change
    if (changed(queryVars, refetchPaginationVars) && !isFetching) getFreshData();
  }, [getFreshData, isFetching, queryVars, refetchPaginationVars]);

  // Table Configuration

  const headers = [
    {
      id: 'icon',
      align: 'center',
      name: '',
      render: (icon: any) => Icons[icon],
      width: '35'
    },
    {
      id: 'timeCreated',
      name: 'Timestamp',
      // filterData: timeCreatedFilter.filterData, //Temporarily removed until date picker is ready
      // onFilter: timeCreatedFilter.handleFilter,
      onSort: () => handleSort('timeCreated'),
      width: '140'
    },
    {
      id: 'reason',
      name: 'Type',
      width: '160'
    },
    {
      id: 'text',
      name: 'Notification',
      width: '350'
    },
    {
      id: 'venue',
      name: 'Venue',
      filterData: venueFilter.filterData,
      onFilter: venueFilter.handleFilter,
      width: '90'
    },
    {
      id: 'fixClOrdID',
      name: 'Order ID',
      // filterData: fixClOrdIDFilter.filterData, //Temporarily removed until string matching is ready
      // onFilter: fixClOrdIDFilter.handleFilter,
      // onSort: () => handleSort('fixClOrdID'),
      render: (orderData: any) => (
        <OrderIDPopup
          fixClOrdID={orderData.fixClOrdID}
          fixOrderID={orderData.fixOrderID}
          fixQuoteID={orderData.fixQuoteID}
          fixVenueOrderID={orderData.feature !== 'ALGO' ? orderData.fixVenueOrderID : undefined}
          source={orderData.source}
          venue={orderData.venue}
        />
      ),
      width: '120'
    },
    {
      id: 'side',
      name: 'Side',
      filterData: sideFilter.filterData,
      onFilter: sideFilter.handleFilter,
      onSort: () => handleSort('side'),
      width: '70'
    },
    {
      align: 'right',
      id: 'quantity',
      name: 'Qty',
      filterData: quantityFilter.filterData,
      onFilter: quantityFilter.handleFilter,
      onSort: () => handleSort('quantity'),
      width: '110'
    },
    {
      id: 'instrument',
      name: 'Instrument',
      filterData: instrumentFilter.filterData,
      onFilter: instrumentFilter.handleFilter,
      width: '150'
    },
    {
      id: 'executionType',
      name: 'Execution Type',
      filterData: executionFilter.filterData,
      onFilter: executionFilter.handleFilter,
      onSort: () => handleSort('executionType', 'ASC'),
      width: '190'
    },
  ];

  const rowCount = rows.length
  const totalCount = paginatedData?.notificationData?.count

  // Render

  return (
    <NotificationsInspector className={classes.notificationInspector} onClose={onClose} open={open} title="All Notifications">
      <Grid
        hasMore={!!totalCount && (rowCount < totalCount)}
        loadMore={loadMore}
        scrollThreshold={(rowCount - SCROLL_THRESHOLD) / rowCount}
        size="small">
        <GridBody>
          <GridRow header>
            {
              headers.map((header: Record<string, any>, key) => {
                return <GridCellHead
                  align={header.align as GridCellAlignment}
                  className={classes.gridCellHead}
                  key={key}
                  filterData={header.filterData}
                  iconSize={'large'}
                  onFilter={header.onFilter}
                  onSort={header.onSort}
                  pinned={header.pinned}
                  sortOrder={sortQueryArgs.id === header.id ? sortQueryArgs.direction.toLowerCase() as ('asc' | 'desc') : undefined}
                  width={header.width}>
                    { header.name }
                </GridCellHead>
              })
            }
          </GridRow>
          {
            rows.map((row: any, key) => {
              return <GridRow key={key} onClick={() => onRowClick(row.fixClOrdID.fixClOrdID)}>
                {
                  headers.map((header: Record<string, any>, key) => {
                    return <GridCell
                      align={header.align as GridCellAlignment}
                      className={classes.gridCell}
                      key={`${header.id}-${key}`}
                      pinned={header.pinned}
                      width={header.width}>
                        {header.render?.(row[header.id]) || row[header.id]}
                    </GridCell>
                  })
                }
              </GridRow>
            })
          }
        </GridBody>
      </Grid>
    </NotificationsInspector>
  );
};

export default AllNotificationsInspector;

// Helpers

const condenseCategoricalFilterOptions = (columnId: string, filter: CategoricalFilterHook): CondensedFilters => {
  let condensedOptions: any;
  const { queryArgs, filterData: { options, values }} = filter;

  if (queryArgs.length && queryArgs.length !== options.length) {
    if (columnId === 'executionType') {
      const features: Array<string> = [];
      const algorithmStrategies: Array<string> = [];
      for (const selection of values) {
        if (executionType.some(({ value }) => value === selection)) features.push(selection);
        else {
          if (selection === OrderAlgorithmStrategy.VWAP) algorithmStrategies.push(OrderAlgorithmStrategy.SVWAP);
          algorithmStrategies.push(selection);
        }
      }
      condensedOptions = {};
      if (features.length) condensedOptions.feature = features;
      if (algorithmStrategies.length) condensedOptions.algorithmStrategy = algorithmStrategies;
    } else {
      condensedOptions = values;
    }
  }
  return { [columnId]: condensedOptions };
}

// const condenseDateRangeFilterOptions = (columnId: string, filter: DateRangeFilterHook): CondensedFilters => {
//   let condensedOptions: any = {}
//   const { filterData: { condition, initialDate, endDate }} = filter

//   if (condition && initialDate) {
//     condensedOptions = { [columnId]: { condition, initialDate } }
//     if (condition === 'between' && endDate) {
//       condensedOptions.endDate = endDate
//     }
//   }
//   return  condensedOptions;
// }

const condenseExclusiveCategoryFilterOptions = (columnId: string, filter: ExclusiveCategoryFilterHook): CondensedFilters => (
  filter.filterData.value !== null ? { [columnId]: filter.filterData.value } : {}
)

const condenseNumericalFilterOptions = (columnId: string, filter: NumericalFilterHook): CondensedFilters => {
  const { queryArgs: { value, sign }} = filter

  return (
    (value !== undefined && sign !== undefined)
      ? { [columnId]: { value, sign: sign === String.fromCharCode(0x2260) ? '!=' : sign } }
      : {}
  )
}

const notificationUpdateQuery = (previousResult: any, { fetchMoreResult }: any) => (
  fetchMoreResult
    ? {
      notificationData: {
        count: fetchMoreResult.notificationData.count,
        notifications: mergeUpdate(previousResult.notificationData.notifications, fetchMoreResult.notificationData.notifications),
        __typename: fetchMoreResult.notificationData.__typename,
      }
    } : previousResult
)

