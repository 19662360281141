import { Icon } from 'semantic-ui-react';
import React from 'react';
import styled from 'react-emotion';

import { colors } from '@omniex/onx-common-ui/lib/styles';
import { format as formatNumber } from '@omniex/onx-common-js/lib/utils/NumberUtils';
import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import { getPriceFormat } from '../../utils/DisplayUtils';
import getProjectedPrice from '../../selectors/getProjectedPrice';
import InstrumentType from '@omniex/onx-poms-entity-helpers/lib/enums/InstrumentType';
import { isEmpty } from '@omniex/onx-common-js/lib/utils/LangUtils';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import txt from './ProjectedPriceMessage.copyText';

const COMPONENT_NAME = 'ProjectedPriceMessage';
const { FUTURE } = InstrumentType;

const StyledMessage = styled(Message)`
  .${COMPONENT_NAME}-projectedHeader {
    font-size: 16px !important;
    text-align: left !important;
  }

  .${COMPONENT_NAME}-projectedLabel {
    justify-content: left;
    text-align: left;
  }

  .${COMPONENT_NAME}-projectedPriceValue {
    text-align: right;
  }

  .${COMPONENT_NAME}-projectedPriceMessage {
    background-color: ${colors.infoBackgroundColor};
    padding: 8px !important;
  }

  .${COMPONENT_NAME}-projectedPriceTable {
    width: 100%;
  }
  .${COMPONENT_NAME}-projectedWarningIcon {
  }

  .${COMPONENT_NAME}-projectedWarningLabel {
    align-items: bottom;
    font-size: 12px;
    line-height: 1.2em;
    padding-left: 2px;
    padding-top: 5px;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

const print = n => formatNumber(n, getPriceFormat(n))

const Price = ({ base, instrument, label, price, show = true }) => show && (
  <tr>
    <td className={cn('projectedLabel')}>{label}</td>
    <td className={cn('projectedPriceValue')}>{print(price)} {getAssetDisplayText(get(instrument, `${base ? 'base' : 'term'}Asset`))}</td>
  </tr>
)

const Warn = ({ show, warning }) => show && (
  <tr>
    <td className={cn('projectedWarningIcon')}><Icon name='warning sign' size='large'/></td>
    <td className={cn('projectedWarningLabel')}>{warning}</td>
  </tr>
)

const ProjectedPriceMessage = ({ accounts, entries, gtc, includeSorFees, instrument, isSor = false, limitPrice, orderType, qtyToFill, side, stopPrice, venueIds, venueOptions }) => {
  if (isNaN(parseFloat(qtyToFill)) || isEmpty(venueIds)) return null;

  const { avgPrice, qtyFilled, totalPrice, warn, completeEstimate } = getProjectedPrice({accounts, entries, gtc, instrument, limitPrice, orderType, qtyToFill, side, stopPrice, venueIds, venueOptions});

  return (
    <StyledMessage className={cn('projectedPriceMessage')} data-position="top left" data-inverted name="sorProjectedPrice">
      <table className={cn('projectedPriceTable')}>
        <thead>
          <tr>
            <th className={cn('projectedHeader')} colSpan={2}>{txt.header}</th>
          </tr>
        </thead>
        <tbody>
          <Price instrument={instrument} label={txt.avgPrice} price={avgPrice} />
          <Price instrument={instrument} label={txt.qtyFilled} price={qtyFilled} base />
          <Price instrument={instrument} label={txt.totalPrice} price={totalPrice} show={instrument.type !== FUTURE} />
        </tbody>
      </table>
      <table>
        <tbody>
          <Warn show={warn && (!isSor || completeEstimate)} warning={txt.qtyWarning} />
          <Warn show={warn && isSor && !completeEstimate} warning={txt.sorQtyWarning.replace(/%quantity%/g, `${qtyToFill-qtyFilled}`).replace(/%baseAsset%/g, `${getAssetDisplayText(get(instrument, 'baseAsset'))}`)} />
          <Warn show={includeSorFees} warning={txt.feesWarning} />
        </tbody>
      </table>
    </StyledMessage>
  );
}

export default ProjectedPriceMessage
