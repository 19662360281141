import React, { Component } from 'react'

import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';

const ErrorMessage = ({message}) => message ? <Message content={message} error /> : null

export default class ErrorBoundary extends Component {
  state = { error: null, errorInfo: null }

  componentDidCatch(error, errorInfo) {
    console.log({error, errorInfo})
    this.setState({error, errorInfo})
  }

  render() {
    const { children, message = null } = this.props
    const { error } = this.state
    return error ? <ErrorMessage message={message} /> : children
  }
}
