const accountActivityTableCopyText = {
  accountNamePrefixUnsettled: 'Unsettled',
  journalLabel: 'MANUAL ENTRY',
  missingDateLabel: 'Pending...',
  notApplicable: 'N/A',
  popupDeleteWarningHeader: 'Are you sure you want to proceed?',
  popupDeleteWarningMessage:
    'Deleting this account activity will result in other account activities being automatically re-calculated.',
  tableHeaderAccountName: 'Account Name',
  tableHeaderActualTransaction: 'Transaction Type',
  tableHeaderDate: 'Date',
  tableHeaderInstrument: 'Instrument',
  tableHeaderQuantity: 'Quantity',
  tableHeaderSource: 'Activity Origin',
  tableHeaderVenue: 'Venue',
  warningMessage: 'There are no account activities to display at this time.'
};

export default accountActivityTableCopyText;
