import * as React from 'react';

import { Button } from 'semantic-ui-react';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@omniex/poms-ui/lib/themes';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import copyText from './copyText';

const insertKeyName = (name: string, message: string) => {
  return message.replace('%name%', name);
};

const useStyles = makeStyles((theme) => ({
  buttonRow: {
    flexDirection: 'row',
  },
  cancelButton: {
    textAlign: 'center',
    width: '30%',
  },
  confirmButton: {
    width: '30%',
    textAlign: 'center',
    color: 'white !important',
    backgroundColor: 'orange !important',
  },
  paper: {
    width: '50vw',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  popupHeader: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  popupNote: {
    fontWeight: 'bold',
    margin: theme.spacing(1),
  }
}));

type DisableApiKeyPopupProps = {
  closePopup: () => void,
  disableApiKey: () => void,
  label: string,
  showPopup: boolean
};

const DisableApiKeyPopup: React.FC<DisableApiKeyPopupProps> = ({
  closePopup,
  disableApiKey,
  label,
  showPopup
}) => {
  // Styles
  const classes = useStyles();

  return (
    <Modal
      open={showPopup}
      className={classes.modal}
    >
      <Paper square className={classes.paper}>
        <Typography variant="h6">{copyText.disablePopupHeader}</Typography>
        <Divider />
        <Typography variant="subtitle1" className={classes.popupNote}>{insertKeyName(label, copyText.disablePopupNote)}</Typography>
        <div className={classes.buttonRow}>
          <Button
            onClick={closePopup}
            variant="text"
            size="small"
            className={classes.cancelButton}
          >
            {copyText.cancelButton}
          </Button>
          <Button
            onClick={disableApiKey}
            variant="text"
            size="small"
            className={classes.confirmButton}
          >
            {copyText.confirmButton}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

export default DisableApiKeyPopup;
