import gql from 'graphql-tag';

export default gql`
  subscription refreshMarketDataFeed {
    marketDataEntries: refreshMarketDataFeed {
      id
      fixMDReqID
      instrument {
        id
      }
      fixMDStreamID
      type
      price
      quantity
      venueSymbol
    }
  }
`;
