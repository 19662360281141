const saveFiatAccountFormCopy = {
  cancelButtonLabel: 'Cancel',
  confirmationMessageContent:
    'Your new wallet will appear in the table to the left.',
  confirmationMessageHeader: 'Do you want to proceed?',
  inputPlaceholder_currencyId: 'Currency',
  inputPlaceholder_customName: 'Account Name',
  inputPlaceholder_custodianId: 'Custodian',
  inputPlaceholder_freeForm: 'Account Number',
  proceedButtonLabel: 'Proceed',
  saveButtonLabel: 'Save'
};

export default saveFiatAccountFormCopy;
