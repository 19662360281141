const preTradeEstimatesCopy = {
  adjusted: 'Adjusted ',
  avgPostingIntervalAmountHeader: 'Avg. Posting Interval Amt',
  duration: 'Duration: ',
  executionScheduleHeader: 'Order Execution Schedule',
  header: 'Pre-trade Estimates',
  intervalDuration: 'Interval Duration: ',
  intervalHeader: 'Number of Intervals: ',
  na: 'N/A',
  orderDuration: 'Order Duration: ',
  participation: 'Participation: ',
  postingIntervalDuration: 'Posting Interval Duration: ',
  postingIntervalHeader: '# of Posting Intervals: ',
  volume: 'Volume: ',
};

export default preTradeEstimatesCopy;
