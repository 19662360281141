/* eslint no-sequences: 0 */
import React, { useState } from 'react'

import Box from '@material-ui/core/Box'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Chip from '@material-ui/core/Chip'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import AddIcon from '@material-ui/icons/Add'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import { fmtColumnLabel } from './utils'
import { useGroupControlsClasses } from './styles'

const NestedMenuItem = ({ chip, o, path, onClickMenuItemCb }) => {
  const [anchor, setAnchor] = useState(null)
  const open = e => setAnchor(e.currentTarget)
  const close = () => setAnchor(null)

  const cls = useGroupControlsClasses()

  return (
    <MenuItem key={o.category} className={cls.label} value={o.category} onMouseEnter={open} onMouseLeave={close}>
      {fmtColumnLabel(o.category)}
      <NavigateNextIcon className={cls.navigateNext} />
      <Menu
        anchorEl={anchor}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        disablePortal
        elevation={1}
        hideBackdrop
        MenuListProps={{ style: { pointerEvents: 'all' } }}
        onClose={close}
        open={!!anchor}
        style={{ pointerEvents: 'none' }}>
        {o.value.map(v =>
          <MenuItem key={v} className={cls.label} value={v} onClick={onClickMenuItemCb({ category: o.category, value: v }, chip, path)}>
            {fmtColumnLabel(v)}
          </MenuItem>
        )}
      </Menu>
    </MenuItem>
  )
}

const AddGroupMenu = ({
  chip,
  path,
  options,
  showAllOptions,
  close,
  onClickMenuItemCb,
  anchor,
  anchorOr = { vertical: 'top', horizontal: 'right' },
  transformOr = { vertical: 'top', horizontal: 'left' }
}) => {
  const cls = useGroupControlsClasses()
  return (
    <Menu
      anchorEl={anchor}
      anchorOrigin={anchorOr}
      elevation={1}
      getContentAnchorEl={null}
      keepMounted
      onClose={close}
      open={!!anchor}
      PaperProps={{ style: { overflow: 'hidden' } }}
      transformOrigin={transformOr}>
      {options.filter(o => showAllOptions || !path.map(p => p.category).includes(o.category)).map(o =>
        o.value.length > 1
          ? <NestedMenuItem key={o.category} chip={chip} o={o} path={path} onClickMenuItemCb={onClickMenuItemCb} />
          : <MenuItem key={o.category} className={cls.label} value={o.category} onClick={onClickMenuItemCb({ category: o.category, value: o.value[0] }, chip, path)}>
              {fmtColumnLabel(o.category)}
            </MenuItem>
      )}
    </Menu>
  )
}

const PathItem = ({ c, options, path, setPath, setCollapsedRows }) => {
  const [anchor, setAnchor] = useState(null)
  const open = e => setAnchor(e.currentTarget)
  const close = () => setAnchor(null)
  const remove = c => () => {
    setPath(path.filter(p => p.category !== c.category))
    setCollapsedRows([])
    close()
  }
  const replace = (c, o, p) => () => {
    c.value !== o.value && setPath(p.filter(_ => (o.category === c.category) || (_.category !== c.category)).map(_ => _.category === o.category ? c : _))
    setCollapsedRows([])
    close()
  }

  return (
    <div>
      <Chip label={fmtColumnLabel(c.value)} variant="outlined" size="medium" onDelete={remove(c)} onClick={open} />
      <AddGroupMenu
        chip={c}
        path={path}
        options={options}
        showAllOptions
        close={close}
        onClickMenuItemCb={replace}
        anchor={anchor}
        anchorOr={{ vertical: 'bottom', horizontal: 'left' }}
        transformOr={{ vertical: 'top', horizontal: 'left' }} />
    </div>
  )
}

const AddGroupButton = ({ className, options, path, setPath }) => {
  const [anchor, setAnchor] = useState(null)
  const open = e => setAnchor(e.currentTarget)
  const close = () => setAnchor(null)
  const add = c => () => (setPath([...path.filter(p => p.category !== c.category), c]), close())

  return path.length < options.length && (
    <div className={className}>
      <Chip variant="outlined" label={<AddIcon color="action" />} onClick={open} />
      <AddGroupMenu path={path} options={options} close={close} onClickMenuItemCb={add} anchor={anchor} />
    </div>
  )
}

const GroupControls = ({ path, setPath, options, setCollapsedRows }) => {
  const menuOptions = options.reduce((acc, o) => {
    const existing = acc.find(a => a.category === o.category)
    existing ? existing.value.push(o.value) : acc.push({ category: o.category, value: [o.value] })
    return acc
  }, [])

  const cls = useGroupControlsClasses()

  return (
    <div className={cls.groupControls}>
      <Box mr={2} className={cls.label} fontSize="subtitle1.fontSize">Groups</Box>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="default" />}>
        {path.map(c => <PathItem key={c.value} c={c} options={menuOptions} path={path} setPath={setPath} setCollapsedRows={setCollapsedRows} />)}
        {path.length < menuOptions.length && <AddGroupButton className={cls.addBtn} options={menuOptions} path={path} setPath={setPath} />}
      </Breadcrumbs>
    </div>
  )
}

export default GroupControls
