const saveCredentialsFormCopy = {
  credentialsForTradeTitle: 'Credentials Read & Trade',
  credentialsForViewTitle: 'Credentials Read Only',
  credentialsReadOnlyMessage:
    'Please add an API Key with %Read permission only/%. This key will be used to keep your data in sync between Omniex and the exchange.',
  credentialsTradeReadMessage:
    'Please add an API Key with %Read and Trade permissions/%. This key will be used to trade on the integrated exchange. The key should not have Withdraw permissions enabled.',
  tooltipContent_ftxFixClientId: 'To integrate a Subaccount at FTX, the Subaccount Name is required to successfully place orders at FTX via the API.',
  tooltipContent_geminiFixCompId: 'The FIX Comp ID assigned to your account is required to successfully place orders at Gemini via the API.',
  inputPlaceholder_fixCompId: 'FIX Comp ID (Trade)',
  inputPlaceholder_fixClientId: 'FIX Client ID (Trade)',
  inputPlaceholder_ftxFixClientId: 'Subaccount Name',
  inputPlaceholder_key: 'Key (Read & Trade)',
  inputPlaceholder_keyForView: 'Key (Read-only)',
  inputPlaceholder_passphrase: 'Passphrase (Read & Trade)',
  inputPlaceholder_passphraseForView: 'Passphrase (Read-only)',
  inputPlaceholder_password: 'Password (Read & Trade)',
  inputPlaceholder_passwordForView: 'Password (Read-only)',
  inputPlaceholder_secret: 'Secret (Read & Trade)',
  inputPlaceholder_secretForView: 'Secret (Read-only)',
  inputPlaceholder_type: 'Type',
  inputPlaceholder_userId: 'User ID',
  inputPlaceholder_userIdForView: 'User ID'
};

export default saveCredentialsFormCopy;
