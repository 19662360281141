import { createSelector } from 'reselect';

import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import getCurrenciesKeyedById from './getCurrenciesKeyedById';
import getInstrumentsKeyedById from './getInstrumentsKeyedById';

// TODO currencies can be removed once `specifiedCurrency` has the whole currency attached
export function populateQuoteRequestsWithInstruments(
  quoteRequests,
  instruments,
  currencies
) {
  if (
    !Array.isArray(quoteRequests) ||
    quoteRequests.length === 0 ||
    !Array.isArray(instruments) ||
    instruments.length === 0 ||
    !Array.isArray(currencies) ||
    currencies.length === 0
  ) {
    return [];
  }

  const currenciesKeyedById = getCurrenciesKeyedById(currencies);
  const instrumentsKeyedById = getInstrumentsKeyedById(instruments);

  return quoteRequests.map(request => {
    const instrument = instrumentsKeyedById[get(request, 'instrument.id')];

    const specifiedCurrency =
      currenciesKeyedById[get(request, 'specifiedCurrency.id')];

    return {
      ...request,
      instrument,
      specifiedCurrency
    };
  });
}

export default createSelector(
  (quoteRequests, instruments, currencies) => quoteRequests,
  (quoteRequests, instruments, currencies) => instruments,
  (quoteRequests, instruments, currencies) => currencies,
  populateQuoteRequestsWithInstruments
);
