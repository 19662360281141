const saveCryptoAccountFormCopy = {
  cancelButtonLabel: 'Cancel',
  confirmationMessageContent:
    'Your new wallet will appear in the table to the left.',
  confirmationMessageHeader: 'Do you want to proceed?',
  freeTextWarning: 'Warning: for your security, avoid providing personally identifying information.',
  inputPlaceholder_currencyId: 'Asset',
  inputPlaceholder_customName: 'Wallet Name',
  inputPlaceholder_custodianId: 'Custodian',
  inputPlaceholder_description: 'Description (optional)',
  inputPlaceholder_walletAddress: 'Wallet Address',
  proceedButtonLabel: 'Proceed',
  saveButtonLabel: 'Save',
  selfCustodian: 'Self Custodian'
};

export default saveCryptoAccountFormCopy;
