import gql from 'graphql-tag';

export default gql`
  query getPaginatedFillsByOrderId(
    $orderId: ID!
    $params: PaginationParams!
    $filters: FillFilters
    $portfolioId: ID
  ) {
    fillData: getPaginatedFillsByOrderId(
      orderId: $orderId
      params: $params
      filters: $filters
      portfolioId: $portfolioId
    ) {
      fills {
        id
        feeCost
        feeCurrency {
          id
          symbol
          name
          type
        }
        fixExecID
        quantity
        price
        timeCreated
        specifiedCurrency {
          id
          symbol
          name
          type
        }
        venue {
          id
          name
        }
        order {
          id
          algorithmDuration
          algorithmStrategy
          feature
          fixQuoteID
          specifiedQuantity
          instrument {
            id
            type
            symbol
            displayName
            baseAsset {
              id
              symbol
              expiry
              name
              type
            }
            marginAsset {
              id
              symbol
              name
              type
            }
            termAsset {
              id
              symbol
              name
              type
            }
          }
          side
          type
          fixOrderID
          fixClOrdID
          fixVenueOrderID
        }
      }
      count
    }
  }
`;
