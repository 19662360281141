import { createSelector } from 'reselect';

import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { groupBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';

export function getSettlementsGroupedByCounterParty(settlements, homeOrgId) {
  if (!Array.isArray(settlements) || settlements.length === 0) return [];

  return groupBy(settlements, settlement => {
    if (settlement.initiatorOrg === null) return settlement; // NOTE: Temporary until backend implemented. Settlments should always have allocations.

    return get(settlement, 'initiatorOrg.id') === homeOrgId
      ? settlement.respondentOrg.id
      : settlement.initiatorOrg.id;
  });
}

export default createSelector(
  (settlements, homeOrgId) => settlements,
  (settlements, homeOrgId) => homeOrgId,
  getSettlementsGroupedByCounterParty
);
