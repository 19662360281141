import gql from 'graphql-tag';

export default gql`
  query getExchangeAccounts($integrationId: ID) {
    exchangeAccounts: getExchangeAccounts(integrationId: $integrationId) {
      id
      currency {
        id
      }
      custodian {
        id
        operatingVenue {
          id
        }
      }
      name
      quantity
      type
    }
  }
`;
