import * as React from 'react';
import { useMemo, useState } from 'react';
import { Container, Portal } from '@material-ui/core';
import { makeStyles, TextButton, SheetDrawer } from '@omniex/poms-ui';
import { OTCBaseData, OTCTileData, OTCFormOptions, QuoteRequest, OTCOrderBook, OTCOrderBookLevel, HydratedOTCTileData, OTCTemplateData } from '../../../types/otc';
import { AddIcon, HamburgerIcon } from '@omniex/poms-ui/lib/components/Icon';
import { nanoid } from 'nanoid';
import OTCList from './OTCList';
import OTCTile from './OTCTile';
import { Dictionary, BaseUser, NonNullify, Venue, Org, PopulatedInstrument } from '../../../types';
import { ApolloClient } from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { cloneBase, cloneForm } from './shared';
import { useQuoteFeed, useUpdateAppState } from './hooks';
import { useOTCOrders } from './hooks/useOTCOrders';
import OTCCopyText from './OTC.copy';

const MAX_TILES = 15;
const MAX_TEMPLATES = 15;
const MAX_LENGTH = 16;

const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: 'flex',
    flexFlow: 'row wrap',
    backgroundColor: palette.canvas.main,
    padding: 20,
    paddingBottom: 0,
    height: '100%',
  },
  drawer: {
    width: 'fit-content',
    top: 0,
  },
  drawerInner: {
    borderLeftWidth: 2,
    marginLeft: 0,
    padding: 0,
    overflow: 'hidden overlay',
    transition: 'transform 0.15s ease-in-out',
  },
}), { name: 'onx-execution-section-demo' });

export type OTCSectionProps = {
  apiClient?: ApolloClient<NormalizedCacheObject>
  buttonContainerRef: React.RefObject<React.ReactInstance>
  fixSenderCompID: Org['fixSenderCompID']
  instruments: PopulatedInstrument[]
  keyedInstruments: Dictionary<PopulatedInstrument>
  keyedVenues: Dictionary<Venue>
  portfolioId?: string
  refreshDataNonce?: string
  setInspectedOrder: (fixClOrdID?: string) => void
  takerApiClient?: ApolloClient<NormalizedCacheObject>
  user: BaseUser
  venues: Venue[]
}

export function OTCSection({
  apiClient,
  buttonContainerRef,
  fixSenderCompID,
  instruments,
  keyedInstruments,
  keyedVenues,
  portfolioId,
  refreshDataNonce,
  setInspectedOrder,
  takerApiClient,
  user,
  venues,
}: OTCSectionProps) {
  const cls = useStyles();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [tiles, setTiles] = useState<OTCTileData[]>(getInitialData('tiles', user, keyedVenues));
  const [templates, setTemplates] = useState<OTCTemplateData[]>(getInitialData('templates', user, keyedVenues));

  /* Derived Data  */
  const singleClick = useMemo(() => user?.settings?.trade?.executeOnSingleClickOTC ?? false, [user]);

  const hydratedTiles = useMemo(() => tiles.map((tile) => {
    const { instrumentId, venueIds } = tile;
    const instrument: PopulatedInstrument | undefined = keyedInstruments[instrumentId ?? ''];
    const venues = venueIds?.map(vId => keyedVenues[vId]);
    const hydrated: HydratedOTCTileData = {
      keys: [],
      ...tile,
      ...(instrument && { instrument }),
      ...(venues && { venues })
    };

    hydrated.keys = getQRKeys(hydrated, fixSenderCompID);
    return hydrated;
  }), [fixSenderCompID, keyedInstruments, keyedVenues, tiles]);

  const hydratedTemplates = useMemo(() => templates.map((template) => {
    const { instrumentId, venueIds } = template;
    return {
      ...template,
      ...(!!instrumentId && { instrument: keyedInstruments[instrumentId] }),
      ...(!!venueIds?.length && { venues: venueIds.map(vId => keyedVenues[vId]) })
    };
  }), [keyedInstruments, keyedVenues, templates]);

  const formOptions: OTCFormOptions = useMemo(() => ({ instruments, venues, }), [instruments, venues]);

  /* Effects */
  useUpdateAppState(tiles, templates, apiClient);
  const keyedQRs = useQuoteFeed(hydratedTiles, takerApiClient);
  const [keyedOrders, placeOrder, removeOrder, loadingSet, errorSet] = useOTCOrders(keyedQRs, refreshDataNonce, portfolioId, apiClient, takerApiClient);

  /* Fns Internal to OTCSection */
  const createTile = (original: OTCBaseData) => {
    if (tiles.length >= MAX_TILES) return;
    setTiles(tiles => [...tiles, { ...cloneBase(original), id: nanoid() }]);
  }

  /* Fns External to OTCSection */
  // Tile Management
  const createNewTile = () => createTile({});
  const copyTile = (index: number) => createTile(tiles[index]);
  const loadTile = (index: number) => createTile(templates[index]);

  const deleteTile = (index: number) => {
    removeOrder(tiles[index].id);
    setTiles(tiles => tiles.filter((_, i) => i !== index));
  }

  const resetTile = (index: number) => {
    removeOrder(tiles[index].id);
    setTiles(tiles => tiles.map((t, i) => i === index ? cloneForm(t) : t));
  }

  function updateTile<T extends keyof Omit<OTCTileData, 'id'>>(index: number, field: T, value: OTCTileData[T]) {
    const original = tiles[index];

    const updated = { ...original, [field]: value };

    if (field === 'side' && (singleClick || original.side === value)) {
      // if (we click buy or sell and (user has singleClick setting or side was already clicked)) we place order
      placeOrder(updated.id, { ...updated, keys: [...hydratedTiles[index].keys] });
    } else if (field === 'instrumentId') {
      // reset limit and stop price, filter venues to remove any who aren't configured for this instrument
      updated.limitPrice = undefined;
      updated.stopPrice = undefined;
      if (updated.venueIds) {
        updated.venueIds = filterVenues(updated.venueIds, updated.instrumentId as string, keyedVenues);
      }
    } else if (['quantity', 'limitPrice', 'stopPrice'].includes(field) && (value as string).length > MAX_LENGTH) {
      return;
    }

    setTiles(tiles => tiles.map((t, i) => i === index ? updated : t));
  }

  // Template Management
  const createTemplate = (index: number) => {
    if (templates.length >= MAX_TEMPLATES) return;
    setTemplates(templates => [...templates, { ...cloneBase(tiles[index]), id: nanoid() }]);
  }

  const deleteTemplate = (index: number) => {
    setTemplates(templates => templates.filter((_, i) => i !== index));
  }

  return (
    <Container className={cls.root} maxWidth={false}>
      <Portal container={buttonContainerRef.current}>
        <TextButton IconComponent={AddIcon} disabled={tiles.length >= MAX_TILES} onClick={createNewTile}>
          {OTCCopyText.newTile}
        </TextButton>
        <TextButton IconComponent={HamburgerIcon} onClick={() => setIsDrawerVisible(p => !p)} />
      </Portal>
      {hydratedTiles.map((tile, i) => {
        const order = keyedOrders[tile.id];
        const loading = loadingSet.has(tile.id);
        const error = errorSet.has(tile.id);
        const book = loading ? [] : getBook(tile, keyedQRs, keyedVenues, fixSenderCompID);
        return (
          <OTCTile
            key={tile.id}
            onChange={updateTile}
            onDelete={deleteTile}
            onDismiss={resetTile}
            onInspect={setInspectedOrder}
            book={book}
            error={error}
            formData={tile}
            formOptions={formOptions}
            index={i}
            loading={loading}
            order={order}
            singleClick={singleClick}
          />
        )
      })}
      <SheetDrawer classes={{root: cls.drawer, innerContainer: cls.drawerInner}} visible={false || isDrawerVisible}>
        <OTCList
          copyTile={copyTile}
          deleteTile={deleteTile}
          loadTile={loadTile}
          resetTile={resetTile}
          createTemplate={createTemplate}
          deleteTemplate={deleteTemplate}
          keyedOrders={keyedOrders}
          tiles={hydratedTiles}
          templates={hydratedTemplates}
        />
      </SheetDrawer>
    </Container>
  );
}

const filterVenues = (
  venueIds: string[],
  instrumentId: string,
  keyedVenues: Dictionary<Venue>
) => venueIds.filter(venueId => keyedVenues[venueId].instrumentConfigurations
      .some(({ instrument }) => instrument?.id === instrumentId));

const getInitialData = (type: 'tiles' | 'templates', user: BaseUser, keyedVenues: Dictionary<Venue>) => {
  const data = [...user.appState?.otc?.[type] ?? []];
  for (const d of data) {
    if (d.instrumentId && d.venueIds?.length) {
      d.venueIds = filterVenues(d.venueIds, d.instrumentId, keyedVenues);
    }
  }
  return data;
}

export const getQRKeys = ({ instrument, quantity, venues }: Pick<HydratedOTCTileData, 'instrument' | 'quantity' | 'venues'>, fixSenderCompID: string)  => {
  if (!instrument || !quantity || !venues?.length) return [];
  return venues.map(venue => [quantity, instrument.symbol, venue.symbol, fixSenderCompID].join(''));;
}

const getBook = (
  data: HydratedOTCTileData,
  keyedQRs: Dictionary<QuoteRequest>,
  keyedVenues: Dictionary<Venue>,
  fixSenderCompID: string,
) => {
  const book: OTCOrderBook = [];
  const [quotefulQRs, quotelessQRs] = getQRKeys(data, fixSenderCompID).reduce<[NonNullify<QuoteRequest, 'quote'>[], QuoteRequest[]]>((qrs, key) => {
    const qr = keyedQRs[key];
    const [withQuote, withoutQuote] = qrs;
    if (qr) {
      if (qr.quote) withQuote.push(qr as NonNullify<QuoteRequest, 'quote'>);
      else withoutQuote.push(qr);
    }
    return qrs;
  }, [[], []]) ?? [[], []];

  const getVenueName = (v: QuoteRequest['venue']) => v.shortName || keyedVenues[v.id].shortName || v.name || keyedVenues[v.id].name;
  const bids = [...quotefulQRs].sort((a, b) => b.quote.bidPrice - a.quote.bidPrice);
  const offers = [...quotefulQRs].sort((a, b) => a.quote.offerPrice - b.quote.offerPrice);

  for (let i = 0; i < quotefulQRs.length; ++i) {
    const bid = bids[i];
    const offer = offers[i];
    book.push([getVenueName(bid.venue), bid.quote.bidPrice, offer.quote.offerPrice, getVenueName(offer.venue)]);
  }

  book.push(...quotelessQRs.map<OTCOrderBookLevel>(qr => [getVenueName(qr.venue), qr.status, qr.status, getVenueName(qr.venue)]));

  return book;
}

export default OTCSection;
