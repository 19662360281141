import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import OrgType from '@omniex/onx-poms-entity-helpers/lib/enums/OrgType';
import permissions, {
  AFFIRM_SETTLEMENT,
  CANCEL_ORDER,
  CONFIRM_SETTLEMENT,
  DELETE_MANUAL_ACTIVITY,
  DOWNLOAD_PORTFOLIO_CSV,
  DOWNLOAD_SETTLEMENT_CSV,
  DOWNLOAD_TRADE_CSV,
  INITIATE_SETTLEMENT,
  READ_ONLY,
  RECON_BALANCES,
  RECON_ORDERS,
  UPDATE_MANUAL_POSITION,
  UPDATE_NOTIFICATION_SETTINGS,
  UPDATE_USER_PASSWORD,
  VIEW_ADMIN_PAGE,
  VIEW_CUSTODY_PAGE,
  VIEW_CUSTODY_PAGE_CRYPTO_SECTION,
  VIEW_CUSTODY_PAGE_FIAT_SECTION,
  VIEW_DASHBOARD_PAGE,
  VIEW_MANUAL_ENTRY_FORM,
  VIEW_PORTFOLIO_PAGE,
  VIEW_QUOTE_PAGE,
  VIEW_REQUESTS_PAGE,
  VIEW_SETTINGS_PAGE,
  VIEW_SETTLEMENT_PAGE,
  VIEW_TRADE_PAGE_EXECUTION_SECTION,
  VIEW_TRADE_PAGE,
  VIEW_USER_MANAGEMENT_PAGE,
  VIEW_REPORT_PAGE,
} from '../config/permissions';
import UserRoleType from '@omniex/onx-poms-entity-helpers/lib/enums/UserRoleType';

const { TRADER } = UserRoleType
const { CLIENT_BUY_SIDE: BUY } = OrgType

export default class User {
  constructor(user = {}) {
    Object.assign(this, user);
    const { roles = [], org: { type: orgType } = {}} = user;
    this._actions = roles.reduce((actions, { type } ) => {
      const key = type === TRADER ? `${orgType === BUY ? 'BUY' : 'SELL'}_SIDE_${TRADER}` : type;
      actions.push(...get(permissions, key, []));
      return actions;
    }, []);

    this._canViewAdmin =                    this._actions.includes(VIEW_ADMIN_PAGE);
    this._canViewCustody =                  this._actions.includes(VIEW_CUSTODY_PAGE);
    this._canViewCustodyCrypto =            this._actions.includes(VIEW_CUSTODY_PAGE_CRYPTO_SECTION);
    this._canViewCustodyFiat =              this._actions.includes(VIEW_CUSTODY_PAGE_FIAT_SECTION);
    this._canViewDashboard =                this._actions.includes(VIEW_DASHBOARD_PAGE);
    this._canViewManualEntryForm =          this._actions.includes(VIEW_MANUAL_ENTRY_FORM);
    this._canViewPortfolio =                this._actions.includes(VIEW_PORTFOLIO_PAGE);
    this._canViewQuote =                    this._actions.includes(VIEW_QUOTE_PAGE);
    this._canViewReport =                   this._actions.includes(VIEW_REPORT_PAGE);
    this._canViewRequests =                 this._actions.includes(VIEW_REQUESTS_PAGE);
    this._canViewSettings =                 this._actions.includes(VIEW_SETTINGS_PAGE);
    this._canViewSettlement =               this._actions.includes(VIEW_SETTLEMENT_PAGE);
    this._canViewTrade =                    this._actions.includes(VIEW_TRADE_PAGE);
    this._canViewTradeExecution =           this._actions.includes(VIEW_TRADE_PAGE_EXECUTION_SECTION);
    this._canViewUserManagement =           this._actions.includes(VIEW_USER_MANAGEMENT_PAGE);
    this._canAffirmSettlement =             this._actions.includes(AFFIRM_SETTLEMENT);
    this._canCancelOrder =                  this._actions.includes(CANCEL_ORDER);
    this._canConfirmSettlement =            this._actions.includes(CONFIRM_SETTLEMENT);
    this._canDeleteManualActivity  =        this._actions.includes(DELETE_MANUAL_ACTIVITY);
    this._canDownloadPortfolioCSV  =        this._actions.includes(DOWNLOAD_PORTFOLIO_CSV);
    this._canDownloadSettlementCSV  =       this._actions.includes(DOWNLOAD_SETTLEMENT_CSV);
    this._canDownloadTradeCSV  =            this._actions.includes(DOWNLOAD_TRADE_CSV);
    this._canInitiateSettlement  =          this._actions.includes(INITIATE_SETTLEMENT);
    this._canReadOnly =                     this._actions.includes(READ_ONLY);
    this._canReconBalances =                this._actions.includes(RECON_BALANCES);
    this._canReconOrders =                  this._actions.includes(RECON_ORDERS);
    this._canUpdateNotificationSettings =   this._actions.includes(UPDATE_NOTIFICATION_SETTINGS);
    this._canUpdateManualPosition =         this._actions.includes(UPDATE_MANUAL_POSITION);
    this._canUpdateUserPassword =           this._actions.includes(UPDATE_USER_PASSWORD);
  }

  get canViewAdmin()                  { return this._canViewAdmin };
  get canViewCustody()                { return this._canViewCustody };
  get canViewCustodyCrypto()          { return this._canViewCustodyCrypto };
  get canViewCustodyFiat()            { return this._canViewCustodyFiat };
  get canViewDashboard()              { return this._canViewDashboard };
  get canViewManualEntryForm()        { return this._canViewManualEntryForm };
  get canViewPortfolio()              { return this._canViewPortfolio };
  get canViewQuote()                  { return this._canViewQuote };
  get canViewReport()                 { return this._canViewReport };
  get canViewRequests()               { return this._canViewRequests };
  get canViewSettings()               { return this._canViewSettings };
  get canViewSettlement()             { return this._canViewSettlement };
  get canViewTrade()                  { return this._canViewTrade };
  get canViewTradeExecution()         { return this._canViewTradeExecution };
  get canViewUserManagement()         { return this._canViewUserManagement };
  get canAffirmSettlement()           { return this._canAffirmSettlement };
  get canCancelOrder()                { return this._canCancelOrder };
  get canConfirmSettlement()          { return this._canConfirmSettlement };
  get canDeleteManualActivity()       { return this._canDeleteManualActivity };
  get canDownloadPortfolioCSV()       { return this._canDownloadPortfolioCSV };
  get canDownloadSettlementCSV()      { return this._canDownloadSettlementCSV };
  get canDownloadTradeCSV()           { return this._canDownloadTradeCSV };
  get canInitiateSettlement()         { return this._canInitiateSettlement };
  get canReadOnly()                   { return this._canReadOnly };
  get canReconBalances()              { return this._canReconBalances };
  get canReconOrders()                { return this._canReconOrders };
  get canUpdateManualPosition()       { return this._canUpdateManualPosition };
  get canUpdateNotificationSettings() { return this._canUpdateNotificationSettings };
  get canUpdateUserPassword()         { return this._canUpdateUserPassword };

}
