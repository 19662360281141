import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import { Loader, Label, Menu, Segment } from 'semantic-ui-react';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { get, has } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import copyText from './RequestsPageIntegrationsSection.copyText';
import IntegrationStatus from '@omniex/poms-core/lib/enums/IntegrationStatus';
import IntegrationType from '@omniex/poms-core/lib/enums/IntegrationType';
import RequestsIntegrationsTable from '../components/RequestsIntegrationsTable';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/header.css');
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/loader.css');
require('@omniex/onx-common-ui/lib/semantic/css/label.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');
require('@omniex/onx-common-ui/lib/semantic/css/segment.css');

const TAB_LABEL_BLOCKED = copyText.tabLabelBlocked;
const TAB_LABEL_REQUESTED = copyText.tabLabelRequested;
const TAB_LABEL_VERIFIED = copyText.tabLabelVerified;

const DEFAULT_TAB_LABEL = TAB_LABEL_REQUESTED;

const COMPONENT_NAME = 'RequestsPageIntegrationsSection';

const StyledSection = styled('section')`
  .${COMPONENT_NAME}-segment {
    min-height: 400px;
    padding: 14px !important;
  }

  .${COMPONENT_NAME}-menuItem {
    justify-content: center !important;
    width: 200px !important;
  }

  .${COMPONENT_NAME}-tabContentWrapper {
    min-height: 500px;
    overflow: auto;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class RequestsPageIntegrationsSection extends PureComponent {
  static propTypes = {
    activateIntegration: func,
    activateIntegrationMutationError: object,
    activateIntegrationMutationProcessing: bool,
    activateIntegrationMutationResult: object,
    blockRequestedIntegration: func,
    blockRequestedIntegrationMutationError: object,
    blockRequestedIntegrationMutationProcessing: bool,
    blockRequestedIntegrationMutationResult: object,
    clientAccountsListQueryData: object,
    clientAccountsListQueryError: object,
    clientAccountsListQueryLoading: bool,
    deleteIntegration: func,
    deleteIntegrationMutationError: object,
    deleteIntegrationMutationProcessing: bool,
    deleteIntegrationMutationResult: object,
    requestedIntegrationsQueryData: shape({
      integrations: arrayOf(
        shape({
          status: string.isRequired
        })
      )
    }),
    requestedIntegrationsQueryError: object,
    requestedIntegrationsQueryLoading: bool,
    verifiedIntegrationsQueryData: shape({
      integrations: arrayOf(
        shape({
          type: string.isRequired
        })
      )
    }),
    verifiedIntegrationsQueryError: object,
    verifiedIntegrationsQueryLoading: bool
  };

  static defaultProps = {
    activateIntegration: noop,
    blockRequestedIntegration: noop,
    deleteIntegration: noop
  };

  state = { visibleTab: DEFAULT_TAB_LABEL };

  render() {
    const loading =
      this.props.clientAccountsListQueryLoading ||
      !has(this.props, 'clientAccountsListQueryData.clientAccountsList') ||
      this.props.requestedIntegrationsQueryLoading ||
      !has(this.props, 'requestedIntegrationsQueryData.integrations');

    const requestedIntegrations = get(
      this.props,
      'requestedIntegrationsQueryData.integrations',
      []
    ).filter(integration => integration.status === IntegrationStatus.REQUESTED);

    const blockedIntegrations = get(
      this.props,
      'requestedIntegrationsQueryData.integrations',
      []
    ).filter(integration => integration.status === IntegrationStatus.BLOCKED);

    const verifiedIntegrations = get(
      this.props,
      'verifiedIntegrationsQueryData.integrations',
      []
    ).filter(
      integration => integration.type === IntegrationType.CUSTODIAN_SILVERGATE
    );

    const existingIntegratedCompanyNames = verifiedIntegrations.map(
      integration => integration.credentialsPreview
    );

    return (
      <StyledSection className={COMPONENT_NAME}>
        <Menu attached="top">
          <Menu.Item content={copyText.sectionTitle} header icon="globe" />
          <Menu.Item
            className={cn('menuItem')}
            active={this.state.visibleTab === TAB_LABEL_REQUESTED}
            onClick={this._handleChangeVisibleTab.bind(
              this,
              TAB_LABEL_REQUESTED
            )}>
            {copyText.tabLabelRequested}
            <Label>{requestedIntegrations.length}</Label>
          </Menu.Item>
          <Menu.Item
            className={cn('menuItem')}
            active={this.state.visibleTab === TAB_LABEL_BLOCKED}
            onClick={this._handleChangeVisibleTab.bind(
              this,
              TAB_LABEL_BLOCKED
            )}>
            {copyText.tabLabelBlocked}
            <Label>{blockedIntegrations.length}</Label>
          </Menu.Item>
          <Menu.Item
            className={cn('menuItem')}
            active={this.state.visibleTab === TAB_LABEL_VERIFIED}
            onClick={this._handleChangeVisibleTab.bind(
              this,
              TAB_LABEL_VERIFIED
            )}>
            {copyText.tabLabelVerified}
            <Label>{verifiedIntegrations.length}</Label>
          </Menu.Item>
        </Menu>
        <Segment className={cn('segment')} attached="bottom">
          <div className={cn('tabContentWrapper')}>
            {loading ? (
              <Loader
                className={cn('loader')}
                active
                content={copyText.loadingMessage}
                size="medium"
              />
            ) : (
              this._renderTabContent({
                blockedIntegrations,
                existingIntegratedCompanyNames,
                requestedIntegrations,
                verifiedIntegrations
              })
            )}
          </div>
        </Segment>
      </StyledSection>
    );
  }

  _renderTabContent({
    blockedIntegrations,
    existingIntegratedCompanyNames,
    requestedIntegrations,
    verifiedIntegrations
  }) {
    switch (this.state.visibleTab) {
      case TAB_LABEL_REQUESTED:
        return this._renderRequestsIntegrationsTable(
          requestedIntegrations,
          existingIntegratedCompanyNames
        );
      case TAB_LABEL_BLOCKED:
        return this._renderRequestsIntegrationsTable(
          blockedIntegrations,
          existingIntegratedCompanyNames
        );
      case TAB_LABEL_VERIFIED:
        return this._renderRequestsIntegrationsTable(
          verifiedIntegrations,
          existingIntegratedCompanyNames
        );
      default:
        return null;
    }
  }

  _renderRequestsIntegrationsTable(
    integrations,
    existingIntegratedCompanyNames
  ) {
    return (
      <RequestsIntegrationsTable
        activateProcessing={this.props.activateIntegrationMutationProcessing}
        clientAccounts={get(
          this.props,
          'clientAccountsListQueryData.clientAccountsList',
          []
        )}
        existingIntegratedCompanyNames={existingIntegratedCompanyNames}
        integrations={integrations}
        visibleTab={this.state.visibleTab}
        onActivate={this._handleActivateIntegration}
        onBlock={this._handleBlockIntegration}
        onDelete={this._handleDeleteIntegration}
      />
    );
  }

  _handleActivateIntegration = inputs => {
    this.props.activateIntegration(inputs);
  };

  _handleBlockIntegration = inputs => {
    this.props.blockRequestedIntegration(inputs);
  };

  _handleDeleteIntegration = inputs => {
    this.props.deleteIntegration(inputs);
  };

  _handleChangeVisibleTab = tabName => {
    this.setState({ visibleTab: tabName });
  };
}
