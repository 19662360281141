import { arrayOf, bool, func, shape, string } from 'prop-types';
import { Icon } from 'semantic-ui-react';
import React, { Fragment, PureComponent } from 'react';
import styled from 'react-emotion';

import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getAssetSymbolAndName } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { opaque } from '@omniex/onx-common-ui/lib/colors/utils';
import { sortBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './AccountsTable.copyText';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import Table from '@omniex/onx-common-ui/lib/semantic/react/Table';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');
require('@omniex/onx-common-ui/lib/semantic/css/table.css');
require('@omniex/onx-common-ui/lib/semantic/css/popup.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const COMPONENT_NAME = 'AccountsTable';

const StyledTable = styled(Table)`
  border-bottom: 1px solid ${colors.internalBorderColor} !important;
  flex: 1 !important;
  margin: 0 14px !important;

  :hover {
    cursor: pointer;
  }

  .${COMPONENT_NAME}-icon {
    color: ${colors.blue} !important;
    font-size: 1em !important;

    :hover {
      color: ${opaque(colors.blue, 0.8)} !important;
      cursor: pointer;
    }
  }

  .${COMPONENT_NAME}-warningMessage {
    margin-top: 5px !important;
  }
`;

const StyledDiv = styled('div')`
  padding: 14px;
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class AccountsTable extends PureComponent {
  static propTypes = {
    accountIdEditing: string,
    accounts: arrayOf(
      shape({
        id: string.isRequired,
        name: string.isRequired,
        currency: shape({
          id: string.isRequired,
          name: string.isRequired
        }),
        instructions: arrayOf(
          shape({
            id: string.isRequired,
            accountId: string,
            description: string,
            walletAddress: string,
            xpubKey: string,
            xpubParentId: string
          })
        ),
        isFiat: bool,
        timeCreated: string.isRequired
      })
    ),
    isFiat: bool,
    renderExpandedAccountDetailTable: func,
    renderExpandedAccountUpdateForm: func,
    onSelectEditAccount: func,
    onUpdateAccount: func
  };

  static defaultProps = {
    onSelectEditAccount: noop,
    renderExpandedAccountDetailTable: noop,
    renderExpandedAccountUpdateForm: noop,
    onUpdateAccount: noop
  };

  state = { showDetailAccountId: this.props.showDetailAccountId };

  render() {
    const canEdit = get(this.props, 'accounts.[0].custodian.id') === 'N/A';

    const accounts = sortBy(get(this.props, 'accounts', []), 'name');

    if (accounts.length === 0) {
      return this._renderWarningMessage();
    }

    return (
      <StyledTable className={COMPONENT_NAME}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {this.props.isFiat
                ? copyText.tableHeaderCurrency
                : copyText.tableHeaderWallet}
            </Table.HeaderCell>
            {canEdit ? (
              <Fragment>
                <Table.HeaderCell>
                  {copyText.tableHeaderCurrency}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  {copyText.tableHeaderRename}
                </Table.HeaderCell>
              </Fragment>
            ) : null}
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {accounts.map(account => this._renderRow(account, canEdit))}
        </Table.Body>
      </StyledTable>
    );
  }

  _renderRow(account, canEdit) {
    return (
      <Fragment key={account.id}>
        <Table.Row onClick={this._handleClickAccount.bind(this, account)}>
          {canEdit ? (
            <Table.Cell>
              {
                <Fragment>
                  <Icon
                    name={`triangle ${
                      this.state.showDetailAccountId === account.id
                        ? 'down'
                        : 'right'
                    }`}
                  />
                  {account.name}
                </Fragment>
              }
            </Table.Cell>
          ) : (
            <Table.Cell>
              {
                <Fragment>
                  <Icon
                    name={`triangle ${
                      this.state.showDetailAccountId === account.id
                        ? 'down'
                        : 'right'
                    }`}
                  />
                  {getAssetSymbolAndName(account.currency)}
                </Fragment>
              }
            </Table.Cell>
          )}

          {canEdit ? (
            <Table.Cell>{getAssetSymbolAndName(account.currency)}</Table.Cell>
          ) : null}
          <Table.Cell textAlign="center">
            {canEdit ? (
              <Icon
                name="edit"
                className={cn('icon')}
                onClick={this._handleClickEdit.bind(this, account)}
              />
            ) : null}
          </Table.Cell>
        </Table.Row>

        {this.props.accountIdEditing === account.id ? (
          <Table.Row>
            <Table.Cell colSpan={3}>
              {this.props.renderExpandedAccountUpdateForm(account)}
            </Table.Cell>
          </Table.Row>
        ) : null}

        {this.state.showDetailAccountId === account.id ? (
          <Table.Row>
            <Table.Cell colSpan={3}>
              {this.props.renderExpandedAccountDetailTable(account)}
            </Table.Cell>
          </Table.Row>
        ) : null}
      </Fragment>
    );
  }

  _renderWarningMessage() {
    return (
      <StyledDiv className={COMPONENT_NAME}>
        <Message
          className={cn('warningMessage')}
          content={copyText.warningMessage}
          warning
        />
      </StyledDiv>
    );
  }

  _handleClickEdit = (account, event) => {
    event.stopPropagation();

    this.props.onSelectEditAccount(account.id);
  };

  _handleClickCancel = account => {
    this.props.onCancelUpdateAccount();
  };

  _handleClickAccount = account => {
    this.setState(currentState => ({
      showDetailAccountId:
        currentState.showDetailAccountId !== account.id ? account.id : null
    }));
  };

  _handleUpdateAccount = attributes => {
    //TODO: flesh this out with validation
    this.props.onUpdateAccount(attributes);
  };

  _handlePopupOpen(id) {
    this.setState({ popupRowId: id });
  }

  _handlePopupClose() {
    this.setState({ popupRowId: undefined });
  }
}
