import { Button, Form } from 'semantic-ui-react';
import { bool, func, number, shape, string } from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { format } from '@omniex/onx-common-js/lib/utils/NumberUtils';
import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './ProcessOrderForm.copyText';
import AssetType from '@omniex/poms-core/lib/enums/AssetType';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import OrderSide from '@omniex/onx-poms-entity-helpers/lib/enums/OrderSide';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/button.css');
require('@omniex/onx-common-ui/lib/semantic/css/form.css');

const COMPONENT_NAME = 'ProcessOrderForm';

const StyledForm = styled(Form)`
  max-width: 50% !important;
  padding: 0 15px;

  .${COMPONENT_NAME}-copyText {
    margin-top: 0 !important;
    white-space: normal !important;
  }

  .${COMPONENT_NAME}-fieldGroup {
    justify-content: flex-end;
  }

  .${COMPONENT_NAME}-fillButton {
    background-color: ${colors.positiveTextColor} !important;
    color: white !important;
    width: 72px;
  }

  .${COMPONENT_NAME}-fillButton, .${COMPONENT_NAME}-rejectButton {
    margin: 0;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class ProcessOrderForm extends PureComponent {
  static propTypes = {
    order: shape({
      id: string.isRequired,
      instrument: shape({
        termCurrency: shape({
          symbol: string.isRequired,
          type: string.isRequired
        }).isRequired
      }).isRequired,
      limitPrice: number.isRequired,
      side: string.isRequired,
      specifiedCurrency: shape({ symbol: string.isRequired }).isRequired
    }),
    processingCancel: bool,
    processingFill: bool,
    onCancel: func,
    onFill: func
  };

  static defaultProps = { onFill: noop };

  render() {
    return (
      <StyledForm className={COMPONENT_NAME} warning>
        <p className={cn('copyText')}>
          {copyText.confirmationContent
            .replace(
              '%side%',
              get(this.props, 'order.side') === OrderSide.BUY
                ? copyText.offerTerm
                : copyText.bidTerm
            )
            .replace(
              '%price%',
              `${format(
                get(this.props, 'order.limitPrice'),
                get(this.props, 'order.instrument.termCurrency.type') ===
                AssetType.FIAT
                  ? '0.00'
                  : '0.00000000'
              )} ${getAssetDisplayText(get(this.props, 'order.instrument.termCurrency'))}`
            )}
        </p>
        <Message
          className={cn('message')}
          content={copyText.messageContent}
          header={copyText.messageHeader}
          size="mini"
          warning
        />
        <Form.Group className={cn('fieldGroup')}>
          <Form.Field>
            <Button
              className={cn('fillButton')}
              disabled={this.props.processingFill}
              onClick={this._handleFillOrder}
              size="tiny"
              type="submit">
              {copyText.fillButtonLabel}
            </Button>
          </Form.Field>
          <Form.Field>
            <Button
              className={cn('rejectButton')}
              disabled={this.props.processingCancel}
              negative
              onClick={this._handleCancelOrder}
              size="tiny"
              type="submit">
              {copyText.rejectButtonLabel}
            </Button>
          </Form.Field>
        </Form.Group>
      </StyledForm>
    );
  }

  _handleFillOrder = event => {
    event.preventDefault();
    this.props.onFill(get(this.props, 'order.id'));
  };

  _handleCancelOrder = event => {
    event.preventDefault();
    this.props.onCancel(get(this.props, 'order.id'));
  };
}
