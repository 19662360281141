import { ApolloClient } from 'apollo-client';
import { ApolloLink, split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { WebSocketLink } from 'apollo-link-ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import apm from '../../apm';

const ERROR_UNAUTHORIZED = 'Unauthorized';

export default function createClient(baseUrl, options = {}) {
  const cache = new InMemoryCache();

  const httpLink = new HttpLink({
    uri: `${baseUrl}/${options.taker ? "query" : "graphql"}`,
    fetchOptions: {
      credentials: 'include'
    }
  });

  const errorLink = onError(({ networkError, graphQLErrors, operation }) => {
    if (networkError && networkError.statusCode === 401) {
      window.location.href = window.encodeURI(
        `${options.loginUrl}?redirect=${window.location.href}`
      );
    } else if (networkError || graphQLErrors) {
      apm.captureError(networkError || new Error ('GraphQL Error'));
    }
  });

  let link = httpLink;

  const apmLink = new ApolloLink((operation, forward) => {
    if (!operation.operationName) return forward(operation);
    
    const transaction = apm.startTransaction(operation.operationName, 'graphql');

    if (!transaction) return forward(operation);
    
    if (Object.keys(operation.variables).length > 0) transaction.addContext(operation.variables);

    return forward(operation).map(response => {
      transaction.end();
      return response;
    })
  });

  if (options.withSubscriptions) {
    const webSocketBaseUrl = /^https/i.test(baseUrl)
      ? baseUrl.replace(/^https/i, 'wss')
      : baseUrl.replace(/^http/i, 'ws');

    const wsClient = new SubscriptionClient(`${webSocketBaseUrl}/${options.taker ? "query" : "graphql-subscriptions"}`, {
      reconnect: true,
      connectionCallback: (error) => {
        if (
          error &&
          error.message === ERROR_UNAUTHORIZED &&
          options.loginUrl
        ) {
          window.location.href = window.encodeURI(options.loginUrl);
        }
      }
    });

    let wsTransaction;
    let isConnected = false;
    const onConnect = typeof options?.onConnect === 'function' ? options.onConnect : () => {};
    const onDisconnect = typeof options?.onDisconnect === 'function' ? options.onDisconnect : () => {};
    const onReconnect = typeof options?.onReconnect === 'function' ? options.onReconnect : () => {};

    const closeTransaction = () => {
      if (wsTransaction) wsTransaction.end();
    }
    const startTransaction = (name) => {
      wsTransaction = apm.startTransaction(name, 'websocket');
    }

    wsClient.onConnecting(() => startTransaction('connected'));
    wsClient.onConnected(() => {
      isConnected = true;
      onConnect();
      closeTransaction();
    });

    wsClient.onReconnecting(() => startTransaction('reconnected'));
    wsClient.onReconnected(() => {
      isConnected = true;
      onReconnect();
      closeTransaction();
    });

    wsClient.onDisconnected(() => {
      if (!isConnected) return;
      isConnected = false;
      onDisconnect();
      startTransaction('disconnected');
      setTimeout(closeTransaction, 100);
    });

    const webSocketLink = new WebSocketLink(wsClient);

    link = split(
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      webSocketLink,
      httpLink
    );
  }

  link = ApolloLink.from([apmLink, errorLink, link]);

  const client = new ApolloClient({
    link,
    cache
  });

  client.__operations_cache__ = new Map();

  return client;
}
