import gql from 'graphql-tag';

export default gql`
  mutation updatePortfolio($id: ID!, $name: String!, $active: Boolean!) {
    portfolio: updatePortfolio(id: $id, name: $name, active: $active) {
      id
      name
      active
      isDefault
    }
  }
`;
