import React from 'react';
import ReactDOM from 'react-dom';

import apiClient from './_registry/apiClient';
import App from './App';
import config from './config';

document.title = config.title;

ReactDOM.render(
  <App loginUrl={config.login.baseUrl} apiClient={apiClient} brandUrl={config.urls.brand} />,
  document.getElementById('root')
);
