import { Accordion, Icon, Popup } from 'semantic-ui-react';
import { arrayOf, number, shape, string } from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { formatNum } from '../../utils/SettlementUtils';
import { get } from '@omniex/poms-core/lib/utils/ObjectUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import { getDateFormat, getDateFormatSimple } from '../../utils/DisplayUtils';
import { isNil } from '@omniex/poms-core/lib/utils/LangUtils';
import copyText from './SettlementFillsTable.copyText';
import OrgType from '@omniex/poms-core/lib/enums/OrgType';
import Table from '@omniex/onx-common-ui/lib/semantic/react/Table';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/accordion.css');
require('@omniex/onx-common-ui/lib/semantic/css/table.css');
require('@omniex/onx-common-ui/lib/semantic/css/popup.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const { CLIENT_BUY_SIDE } = OrgType;

const COMPONENT_NAME = 'SettlementFillsTable';

const popupStyles = {
  container: {
    fontSize: '0.9em',
    padding: '8px 16px'
  },
  identifierLabel: {
    fontSize: '0.9em',
    textAlign: 'right'
  },
  identifier: {
    fontFamily: 'monospace',
    fontSize: '0.9em'
  }
};

// prettier-ignore
const StyledAccordion = styled(Accordion)`
  display: flex;
  flex-direction: column;

  .${COMPONENT_NAME}-fillsWrapper {
    flex: 1;
  }

  .${COMPONENT_NAME}-accordionTitle {
    padding: 0 !important;
  }

  .${COMPONENT_NAME}-quantity, .${COMPONENT_NAME}-price, .${COMPONENT_NAME}-quantityHeader, .${COMPONENT_NAME}-priceHeader {
    padding-right: 0.25em !important;
    text-align: right !important;
  }

  .${COMPONENT_NAME}-type {
    text-align: center !important;
  }

  .${COMPONENT_NAME}-fixID {
    font-family: monospace;
    font-size: 0.9em;
  }

  .${COMPONENT_NAME}-currency {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class SettlementFillsTable extends PureComponent {
  static propTypes = {
    fills: arrayOf(
      shape({
        id: string.isRequired,
        fixExecID: string.isRequired,
        order: shape({
          feature: string,
          fixClOrdID: string,
          fixOrderID: string,
          fixQuoteID: string,
          fixVenueOrderID: string,
          instrument: shape({
            id: string.isRequired,
            baseCurrency: shape({
              id: string,
              symbol: string
            }),
            displayName: string.isRequired,
            termCurrency: shape({
              id: string,
              symbol: string
            })
          }),
          side: string.isRequired
        }),
        price: number.isRequired,
        quantity: number.isRequired,
        timeCreated: string
      })
    ),
    org: shape({
      id: string.isRequired,
      fixSenderCompID: string.isRequired,
      type: string.isRequired,
    }),
  };

  state = { isActive: false };

  render() {
    const { org } = this.props
    return (
      <StyledAccordion className={cn('fillsWrapper')}>
        <Accordion.Title
          className={cn('accordionTitle')}
          active={this.state.isActive}
          onClick={this._handleToggleShowFills}>
          <Icon name="dropdown" />
          {copyText.accordionTitleShowFills}
        </Accordion.Title>
        <Accordion.Content active={this.state.isActive}>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {copyText.tableHeaderTimeExecuted}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {org.type === CLIENT_BUY_SIDE
                    ? copyText.tableHeaderVenueOrderID
                    : copyText.tableHeaderClientOrderID}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {copyText.tableHeaderFixOrderID}
                </Table.HeaderCell>
                <Table.HeaderCell className={cn('type')}>
                  {copyText.tableHeaderExecType}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {copyText.tableHeaderInstrument}
                </Table.HeaderCell>
                <Table.HeaderCell>{copyText.tableHeaderSide}</Table.HeaderCell>
                <Table.HeaderCell
                  className={cn('priceHeader')}
                  textAlign="right">
                  {copyText.tableHeaderFillPrice}
                </Table.HeaderCell>
                <Table.HeaderCell />
                <Table.HeaderCell
                  className={cn('quantityHeader')}
                  textAlign="right">
                  {copyText.tableHeaderQuantity}
                </Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.fills.map(fill => this._renderFill(fill))}
            </Table.Body>
          </Table>
        </Accordion.Content>
      </StyledAccordion>
    );
  }

  _renderFill(fill) {
    const { org } = this.props
    return (
      <Table.Row key={fill.id}>
        <Table.Cell>
          <Popup
            flowing
            hoverable
            hideOnScroll
            inverted
            style={popupStyles.container}
            trigger={<span>{getDateFormatSimple(fill.timeCreated)}</span>}>
            <div style={popupStyles.identifierLabel}>
              <span>{getDateFormat(fill.timeCreated)}</span>
            </div>
          </Popup>
        </Table.Cell>
        <Table.Cell className={cn('fixID')}>
          {org.type === CLIENT_BUY_SIDE
            ? get(fill, 'order.fixVenueOrderID')
            : get(fill, 'order.fixClOrdID')}
        </Table.Cell>
        <Table.Cell className={cn('fixID')}>
          <Popup
            flowing
            hoverable
            inverted
            mouseLeaveDelay={400}
            style={popupStyles.container}
            trigger={
              <span>
                {org.type === CLIENT_BUY_SIDE
                  ? get(fill, 'order.fixClOrdID')
                  : get(fill, 'order.fixVenueOrderID')}
              </span>
            }>
            <table>
              <tbody>
                <tr>
                  <td style={popupStyles.identifierLabel}>
                    {copyText.popupLabelExecID}:
                  </td>
                  <td style={popupStyles.identifier}>{fill.fixExecID}</td>
                </tr>
                <tr>
                  <td style={popupStyles.identifierLabel}>
                    {copyText.popupLabelEMSOrderID}:
                  </td>
                  <td style={popupStyles.identifier}>
                    {isNil(get(fill, 'order.fixOrderID'))
                      ? copyText.notAvailable
                      : get(fill, 'order.fixOrderID')}
                  </td>
                </tr>
                <tr>
                  <td style={popupStyles.identifierLabel}>
                    {copyText.popupLabelFixQuoteID}:
                  </td>
                  <td style={popupStyles.identifier}>
                    {get(fill, 'order.fixQuoteID') || copyText.notAvailable}
                  </td>
                </tr>
              </tbody>
            </table>
          </Popup>
        </Table.Cell>
        <Table.Cell className={cn('type')}>{fill?.order?.feature}</Table.Cell>
        <Table.Cell>{get(fill, 'order.instrument.displayName')}</Table.Cell>
        <Table.Cell>{get(fill, 'order.side')}</Table.Cell>
        <Table.Cell
          className={cn('price')}
          ifNotNumber={copyText.notAvailable}>
          {formatNum(fill.price, get(fill, 'order.instrument.termCurrency.type'))}
        </Table.Cell>
        <Table.Cell className={cn('currency')}>
          {getAssetDisplayText(get(fill, 'order.instrument.termCurrency'))}
        </Table.Cell>
        <Table.Cell
          className={cn('quantity')}
          ifNotNumber={copyText.notAvailable}>
          {formatNum(fill.quantity, get(fill, 'order.instrument.baseCurrency.type'))}
        </Table.Cell>
        <Table.Cell className={cn('currency')}>
          {getAssetDisplayText(get(fill, 'order.instrument.baseCurrency'))}
        </Table.Cell>
      </Table.Row>
    );
  }

  _handleToggleShowFills = () => {
    this.setState(currentState => ({ isActive: !currentState.isActive }));
  };
}
