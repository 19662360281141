/* eslint-disable no-sequences */
import React, { Component } from 'react';
import { arrayOf, func, number, object, shape, string } from 'prop-types';
import styled from 'react-emotion';

import { changed } from '../../utils/PerformanceUtils';
import { mutationShape, queryShape } from '../../utils/PropTypeUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import TradePageBlotterSection from '../components/Blotter/TradeBlotterSection';
import TradePageExecutionSection from './TradePageExecutionSection';
import TradePageMarketDepthSection from './TradePageMarketDepthSection';
import TradePageMarketHistorySection from './TradePageMarketHistorySection';
import { unique } from '@omniex/onx-common-js/lib/utils/ArrayUtils';
import ErrorBoundary from '../components/ErrorBoundary';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/grid.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');
require('@omniex/onx-common-ui/lib/semantic/css/popup.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const COMPONENT_NAME = 'TradePage';

const StyledDiv = styled('div')`
  height: 100%;
  overflow: hidden;

  .${COMPONENT_NAME}-column {
    flex-direction: column;
    height: 100%;
  }

  .${COMPONENT_NAME}-columnWrapper {
    flex-basis: 0;
    flex-grow: 1;
    min-height: 0;
    min-width: 0;

    :first-child {
      flex-grow: 2;
      margin-right: 20px;
    }

    :last-child {
      flex-grow: 1.17;
    }
  }

  .${COMPONENT_NAME}-row {
    display: flex;
    margin-top: 20px;
  }

  .${COMPONENT_NAME}-sectionWrapper {
    padding-top: 20px;
  }

  .${COMPONENT_NAME}-sectionWrapper:last-child {
    margin-bottom: 60px;
  }

  .ErrorPage {
    padding: 20px 0 0 0;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

// takes an object mapping keys to arrays of values and returns an array
// containing the unique truthy values of the concatenation of the values
const allUnique = o => unique(Object.values(o).reduce((all, v) => (all.push(...v), all), [])).filter(_ => _)
const toArray = o => Array.isArray(o) ? o : [o]
const memo = cb => {
  let prevArgs, result
  return (...args) => {
    if (changed(prevArgs, args)) {
      prevArgs = args
      result = cb(...args)
    }
    return result
  }
}

export default class TradePage extends Component {
  static propTypes = {
    accountsWithBalancesQuery: queryShape(object, false).isRequired,
    configureMarketDataFeed: func,
    currenciesQuery: queryShape(object, false).isRequired,
    currentPricesQuery: queryShape(object, false).isRequired,
    fetchAccounts: func,
    fetchCurrencies: func,
    fetchCurrentPrices: func,
    fetchOrders: func,
    fetchInstrumentBySymbol: func,
    fetchTradeHistory: func,
    fixApiClient: object,
    instrumentBySymbolQuery: queryShape(object, false).isRequired,
    integrationsQuery: queryShape(object, false).isRequired,
    marketDataFeedSubscription: queryShape(object, false).isRequired,
    newNotifications: arrayOf(
      shape({
        id: string.isRequired,
        order: shape({
          algorithmStrategy: string,
          feature: string,
          fixClOrdID: string,
          instrument: shape({
            displayName: string,
            type: string
          }),
          side: string,
          specifiedQuantity: number,
        }),
        orderId: string,
        reason: string,
        text: string,
        timeCreated: string,
        venue: shape({
          id: string.isRequired,
          name: string
        })
      })
    ),
    notificationFixClOrdID: string,
    orgQuery: queryShape(object, false).isRequired,
    paginatedOrdersQuery: queryShape(object, false).isRequired,
    placeOrderForAlgorithmicExecution: func,
    placeOrderForAlgorithmicExecutionMutation: mutationShape.isRequired,
    placeOrderForSpecificExchange: func,
    placeOrderForSpecificExchangeMutation: mutationShape.isRequired,
    recentInputHistoryQueryData: object,
    refreshDataOnFill: string,
    setNotificationFixClOrdID: func,
    takerApiClient: object,
    apiClient: object,
    tradeHistoryQuery: queryShape(object, false).isRequired,
    updateRecentInputHistory: func,
    user: object.isRequired,
    portfolio: object,
  }

  static defaultProps = {
    fetchAccounts: noop,
    fetchCurrencies: noop,
    fetchCurrentPrices: noop,
    fetchOrders: noop,
    fetchTradeHistory: noop,
    fixApiClient: {},
    placeOrderForAlgorithmicExecution: noop,
    placeOrderForSpecificExchange: noop,
    updateRecentInputHistory: noop,
    accountsWithBalancesQuery: {},
    currenciesQuery: {},
    currentPricesQuery: {},
    integrationsQuery: {},
    marketDataFeedSubscription: {},
    newNotifications: [],
    notificationFixClOrdID: {},
    orgQuery: {},
    paginatedOrderQuery: {},
    placeOrderForAlgorithmicExecutionMutation: {},
    placeOrderForSpecificExchangeMutation: {},
    portfolio: {},
    recentInputHistoryQuery: {},
    refreshDataOnFill: undefined,
    setNotificationFixClOrdID: noop,
    takerApiClient: {},
    apiClient: {},
    tradeHistoryQuery: {},
    user: {},
  }

  state = {
    instruments: {
      ALGO: [],
      DMA: [],
      OTC: [],
    },
  }

  updateInstruments = (key, newInstruments) => {
    const { instruments } = this.state
    const updated = {...instruments, [key]: newInstruments}
    this.setState({instruments: updated})
    return this._requestMarketData(allUnique(updated).sort())
  }

  _requestMarketData = memo(instrumentIds => {
    return this.props.configureMarketDataFeed({ instrumentIds })
  })

  _request = k => ids => this.updateInstruments(k, toArray(ids))

  render() {
    return (
      <StyledDiv className={COMPONENT_NAME}>
          <main>
            <div className={cn('row')}>
              <div className={cn('columnWrapper')}>
                <div className={cn('column')}>
                  <ErrorBoundary>
                    <TradePageMarketHistorySection
                      fetchInstrumentBySymbol={this.props.fetchInstrumentBySymbol}
                      fetchTradeHistory={this.props.fetchTradeHistory}
                      instrumentBySymbolQuery={this.props.instrumentBySymbolQuery}
                      orgQuery={this.props.orgQuery}
                      recentInputHistoryQuery={this.props.recentInputHistoryQuery}
                      tradeHistoryQuery={this.props.tradeHistoryQuery}
                      updateRecentInputHistory={this.props.updateRecentInputHistory}
                    />
                  </ErrorBoundary>
                </div>
              </div>
              <div className={cn('columnWrapper')}>
                <div className={cn('column')}>
                  <ErrorBoundary>
                    <TradePageMarketDepthSection
                      configureMarketDataFeed={this.props.configureMarketDataFeed}
                      instruments={this.state.instruments}
                      marketDataFeedSubscription={this.props.marketDataFeedSubscription}
                      orgQuery={this.props.orgQuery}
                      recentInputHistoryQuery={this.props.recentInputHistoryQuery}
                      updateRecentInputHistory={this.props.updateRecentInputHistory}
                      updateInstruments={this.updateInstruments.bind(this)}
                    />
                  </ErrorBoundary>
                </div>
              </div>
            </div>
            {this.props.user.canViewTradeExecution && (
              <div className={cn('sectionWrapper')}>
                <ErrorBoundary>
                  <TradePageExecutionSection
                    accountsWithBalancesQuery={this.props.accountsWithBalancesQuery}
                    apiClient={this.props.apiClient}
                    configureMarketDataFeed={this.props.configureMarketDataFeed}
                    currenciesQuery={this.props.currenciesQuery}
                    currentPricesQuery={this.props.currentPricesQuery}
                    fetchAccounts={this.props.fetchAccounts}
                    fetchCurrencies={this.props.fetchCurrencies}
                    fetchCurrentPrices={this.props.fetchCurrentPrices}
                    fetchOrders={this.props.fetchOrders}
                    integrationsQuery={this.props.integrationsQuery}
                    marketDataFeedSubscription={this.props.marketDataFeedSubscription}
                    orgQuery={this.props.orgQuery}
                    paginatedOrders={this.props.paginatedOrdersQuery}
                    placeOrderForAlgorithmicExecution={this.props.placeOrderForAlgorithmicExecution}
                    placeOrderForAlgorithmicExecutionMutation={this.props.placeOrderForAlgorithmicExecutionMutation}
                    placeOrderForSpecificExchange={this.props.placeOrderForSpecificExchange}
                    placeOrderForSpecificExchangeMutation={this.props.placeOrderForSpecificExchangeMutation}
                    portfolioId={this.props.portfolio?.id}
                    recentInputHistoryQuery={this.props.recentInputHistoryQuery}
                    refreshDataNonce={this.props.refreshDataOnFill}
                    requestedFeeds={this.state.instruments}
                    setInspectedOrder={this.props.setNotificationFixClOrdID}
                    takerApiClient={this.props.takerApiClient}
                    updateInstruments={this.updateInstruments.bind(this)}
                    updateRecentInputHistory={this.props.updateRecentInputHistory}
                    user={this.props.user}
                  />
                </ErrorBoundary>
              </div>
            )}
            <div className={cn('sectionWrapper')}>
              <ErrorBoundary>
                <TradePageBlotterSection
                  apiClient={this.props.apiClient}
                  takerApiClient={this.props.takerApiClient}
                  newNotifications={this.props.newNotifications}
                  notificationFixClOrdID={this.props.notificationFixClOrdID}
                  orgQuery={this.props.orgQuery}
                  portfolioId={this.props.portfolio?.id}
                  refreshDataOnFill={this.props.refreshDataOnFill}
                  setNotificationFixClOrdID={this.props.setNotificationFixClOrdID}
                  user={this.props.user}
                />
              </ErrorBoundary>
            </div>
          </main>
      </StyledDiv>
    );
  }
}
