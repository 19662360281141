const accountDetailTableCopyText = {
  account: 'account',
  addAccountButtonLabel: 'Add Account',
  addAddressButtonLabel: 'Add Address',
  addXPubButtonLabel: 'Add HD Wallet Key',
  notAvailable: 'N/A',
  popupDeleteWarningHeader: 'Do you want to proceed?',
  popupDeleteWarningMessage:
    'Are you sure you want to permanently delete this instruction?',
  proceedButtonLabel: 'Proceed',
  tableHeaderAccountNumber: 'Account Number',
  tableHeaderDelete: 'Delete',
  tableHeaderDescription: 'Description',
  tableHeaderIBAN: 'IBAN',
  tableHeaderUpdate: 'Update',
  tableHeaderWalletAddress: 'Address',
  wallet: 'wallet',
  warningMessage: 'There are no instructions to display for this %account%.'
};

export default accountDetailTableCopyText;
