const FillBlotterTableCopyText = {
  noData: '--',
  popupLabelEMSOrderID: 'EMS Order ID',
  popupLabelQuoteID: 'Quote ID',
  popupLabelVenueOrderID: 'Venue Order ID',
  tableHeaderExecID: 'Exec ID',
  tableHeaderExecutionType: 'Execution Type',
  tableHeaderExpiryType: 'Expiry Type',
  tableHeaderFeeCost: 'Fees',
  tableHeaderFillPrice: 'Fill Price',
  tableHeaderInstrument: 'Instrument',
  tableHeaderInstrumentType: 'Type',
  tableHeaderOrderID: 'Order ID',
  tableHeaderOrderType: 'Order Type',
  tableHeaderQuantityFilled: 'Quantity',
  tableHeaderQuantityOrdered: 'Quantity Ordered',
  tableHeaderSide: 'Side',
  tableHeaderTimeExecuted: 'Timestamp',
  tableHeaderTimeInForce: 'Time In Force',
  tableHeaderVenue: 'Venue',
  tableHeaderVenueOrderID: 'Venue Order ID',
  warningMessage: 'There are no fills to display at this time.'
};

export default FillBlotterTableCopyText;
