import gql from 'graphql-tag';

const getPaginatedOrders = gql`
  query getPaginatedOrders(
    $params: PaginationParams!
    $filters: OrderFilters
    $portfolioId: ID
  ) {
    orderData: getPaginatedOrders(
      params: $params
      filters: $filters
      portfolioId: $portfolioId
    ) {
      orders {
        id
        algorithmDuration
        algorithmStrategy
        calculatedQuantityFilled
        calculatedAverageFillPrice
        calculatedTotalFees
        customOrderId
        displayQuantity
        expiryType
        feature
        feeCost
        feeCurrency {
          id
          name
          type
          symbol
        }
        fixCancellationClOrdID
        fixClientID
        fixClOrdID
        fixOrderID
        fixQuoteID
        fixVenueOrderID
        hypePrice
        instrument {
          id
          type
          symbol
          displayName
          baseAsset {
            id
            symbol
            expiry
            name
            type
          }
          marginAsset {
            id
            symbol
            name
            type
          }
          termAsset {
            id
            symbol
            name
            type
          }
        }
        limitPrice
        notifications {
          id
          reason
          text
          timeCreated
          venue {
            id
            name
          }
        }
        participationRate
        portfolio {
          id
          name
        }
        reasonCanceled
        reasonCancellationRejected
        reasonRejected
        requestCancelTrader {
          id
          username
        }
        side
        source
        specifiedCurrency {
          id
          name
          type
          symbol
        }
        specifiedQuantity
        status
        stopPrice
        timeCreated
        timeLastUpdated
        trader {
          id
          username
        }
        type
        venue {
          id
          name
        }
        venueNames
        venueNamesSelected
      }
      count
    }
  }
`;

export default getPaginatedOrders;
