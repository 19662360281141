import gql from 'graphql-tag';

export default gql`
  query getTopList {
    topList: getTopList {
      id
      circulatingSupply
      currencyName
      currencySymbol
      marketCap
      price
      priceChange24Hour
      rank
      volume24Hour
    }
  }
`;
