import { arrayOf, shape, string } from 'prop-types';
import { Message } from 'semantic-ui-react';
import React, { PureComponent } from 'react';

import copyText from './OtcVenueRelationshipsTable.copyText';
import Table from '@omniex/onx-common-ui/lib/semantic/react/Table';

const COMPONENT_NAME = 'OtcVenueRelationshipsTable';

export default class OtcVenueRelationshipsTable extends PureComponent {
  static propTypes = {
    relationships: arrayOf(
      shape({
        id: string.isRequired,
        status: string.isRequired,
        venue: shape({
          id: string.isRequired,
          name: string.isRequired
        }).isRequired
      })
    )
  };

  static defaultProps = { relationships: [] };

  render() {
    return this.props.relationships.length > 0 ? (
      <Table className={COMPONENT_NAME}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{copyText.tableHeaderName}</Table.HeaderCell>
            <Table.HeaderCell>{copyText.tableHeaderStatus}</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.relationships.map(relationship =>
            this._renderTableRow(relationship)
          )}
        </Table.Body>
      </Table>
    ) : (
      this._renderWarningMessage()
    );
  }

  _renderTableRow(relationship) {
    return (
      <Table.Row key={relationship.id}>
        <Table.Cell>{relationship.venue.name}</Table.Cell>
        <Table.Cell>
          {copyText[`statusLabel_${relationship.status}`]}
        </Table.Cell>
        <Table.Cell />
      </Table.Row>
    );
  }

  _renderWarningMessage() {
    return (
      <div className={COMPONENT_NAME}>
        <Message content={copyText.warningMessage} warning />
      </div>
    );
  }
}
