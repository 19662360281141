import { arrayOf, bool, object, shape, string } from 'prop-types'
import { pick } from '@omniex/onx-common-js/lib/utils/ObjectUtils'

export const arrayOfShape = (data, required = false) => arrayOf(required ? shape(data).isRequired : shape(data))

export const mutationShape = shape({ error: object, processing: bool, result: object })

export const queryShape = (data, wrap = true) => shape({ data: wrap ? shape(data) : data, error: object, loading: bool })

const str = string.isRequired
export const userShape = shape({ name: str, org: shape({ name: str, type: str }) })

export const cleanQuery = q => q && typeof q === 'object' ? pick(q, ['data', 'loading', 'error']) : q
