import gql from 'graphql-tag';

export default gql`
  mutation createCustomCustodian(
    $bankAddress: String
    $description: String
    $name: String!
    $swiftCode: String
  ) {
    custodian: createCustomCustodian(
      bankAddress: $bankAddress
      description: $description
      name: $name
      swiftCode: $swiftCode
    ) {
      id
    }
  }
`;
