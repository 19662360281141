import * as React from 'react';
import { makeStyles, fonts } from '@omniex/poms-ui';
import ListItem from '@material-ui/core/ListItem';
import { OTCOrder, HydratedOTCTemplateData } from '../../../types/otc';
import { AddIcon, SaveIcon, DeleteIcon, MenuRightIcon, MenuDownIcon, CloseIcon } from '@omniex/poms-ui/lib/components/Icon';
import { statusToText } from './shared';
import { Order } from '../../../types';
import { isOrderActive, isOrderUnhappy, isOrderTerminal } from '../Blotter/shared';
import ORDER_SIDE from '@omniex/poms-core/lib/enums/OrderSide';
import clsx from 'clsx';
import { IconWithTooltip } from './IconWithTooltip';
import OTCCopyText from './OTC.copy';

export type ListHeaderProps = {
  isOpen: boolean;
  label: string;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const useListHeaderStyles = makeStyles(({ palette }) => ({
  root: {
    backgroundColor: palette.canvas.main,
    borderBottom: '1px solid',
    borderBottomColor: palette.divider,
    height: 42,
    padding: '12px 8px 12px 8px',
    ...fonts.displayFonts.smallStrong,
    fontWeight: 700,
  },
  label: {
    color: palette.level1.main,
  },
  icon: {
    color: palette.level2.main,
    marginRight: 4,
    '&:hover': {
      color: palette.level1.main,
    }
  },
}));

export function ListHeader({label, isOpen, onClick}: ListHeaderProps) {
  const cls = useListHeaderStyles();
  const Icon = isOpen ? MenuDownIcon : MenuRightIcon;

  return (
    <ListItem button className={cls.root} onClick={onClick}>
      <Icon className={cls.icon} size="small" />
      <span className={cls.label}>{label}</span>
    </ListItem>
  )
}

const useOTCListItemStyles = makeStyles(({ palette }) => ({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: palette.bkg1.main,
    borderBottom: `1px solid ${palette.borderDivider.main}`,
    height: 46,
    padding: '0px 12px',
    '&:hover': {
      backgroundColor: palette.bkg2.main,
    }
  },
  content: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  selected: {
    backgroundColor: palette.hov1.main,
  },
  left: {
    overflow: 'hidden',
  },
  right: {},
  info: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    height: fonts.displayFonts.small.lineHeight,
    '& > *:not(:last-child)': {
      marginRight: 4,
    },
  },
  orderType: {
    color: palette.level2.main,
    ...fonts.displayFonts.small,
  },
  instrument: {
    color: palette.level1.main,
    ...fonts.displayFonts.smallStrong,
    fontWeight: 700,
  },
  quantity: {
    color: palette.level3.main,
    ...fonts.displayFonts.small,
    fontWeight: 700,
  },
  venues: {
    color: palette.level2.main,
    ...fonts.displayFonts.xxSmall,
    height: fonts.displayFonts.xxSmall.lineHeight,
  },
  icons: {
    flex: '0 0 51px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: 12,
    height: fonts.displayFonts.small.lineHeight,
  },
  icon: {
    color: palette.level3.main,
    cursor: 'pointer',
    '&:hover': {
      color: palette.level1.main,
    }
  },
  buy: {
    color: `${palette.buy.main} !important`,
  },
  sell: {
    color: `${palette.sell.main} !important`,
  },
  success: {
    color: `${palette.affirm.main} !important`,
  },
  failure: {
    color: `${palette.alert1.main} !important`,
  },
  status: {
    ...fonts.displayFonts.xSmallStrong,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}), { name: 'otc-list-item' });

const Dash = () => <>&mdash;</>

export type OTCListItemProps = {
  onAdd: (index: number) => void;
  onDelete: (index: number) => void;
  onDismiss?: (index: number) => void;
  onSave?: (index: number) => void;
  index: number;
  hydratedData: HydratedOTCTemplateData;
  order?: Order | OTCOrder;
  variant: 'tile' | 'template';
}

export function OTCListItem({
  onAdd,
  onDelete,
  onDismiss,
  onSave,
  index,
  hydratedData: { instrument, orderType, quantity, venues },
  order,
  variant,
}: OTCListItemProps) {
  const cls = useOTCListItemStyles();
  const colorCn = order && (isOrderActive(order)
    ? order.side === ORDER_SIDE.BUY ? cls.buy : cls.sell
    : isOrderUnhappy(order) ? cls.failure : cls.success);

  return (
    <ListItem className={cls.root} classes={{ selected: cls.selected }}>
      <div className={cls.content}>
        <div className={cls.left}>
          <div className={cls.info}>
            <span className={clsx(cls.orderType, colorCn)}>{orderType ?? <Dash />}</span>
            <span className={clsx(cls.instrument, colorCn)}>{instrument?.displayName ?? <Dash />}</span>
            <span className={clsx(cls.quantity, colorCn)}>{quantity || <Dash />}</span>
          </div>
          <div className={cls.venues}>
            {venues?.map(v => v.shortName ?? v.name).join(', ') || <Dash />}
          </div>
        </div>
        <div className={cls.right}>
          <div className={cls.icons}>
            {onDismiss && order && isOrderTerminal(order) ? (
                <IconWithTooltip Icon={CloseIcon} className={cls.icon} size="small" onClick={() => onDismiss(index)} title={OTCCopyText.dismiss} />
            ) : ((!order || !isOrderActive(order)) &&
              <>
                {onSave && <IconWithTooltip Icon={SaveIcon} className={cls.icon} size="small" onClick={() => onSave(index)} title={OTCCopyText.save} />}
                <IconWithTooltip Icon={AddIcon} className={cls.icon} size="small" onClick={() => onAdd(index)} title={variant === 'tile' ? OTCCopyText.duplicate : OTCCopyText.addTile} />
                <IconWithTooltip Icon={DeleteIcon} className={cls.icon} size="small" onClick={() => onDelete(index)} title={variant === 'tile' ? OTCCopyText.deleteTile : OTCCopyText.deleteTemplate} />
              </>
            )}
          </div>
          <div className={clsx(cls.status, colorCn)}>
            {order && <span>{statusToText[order.status]}</span>}
          </div>
        </div>
      </div>
    </ListItem>
  );
}
