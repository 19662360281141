const accountInstructionFormCopyText = {
  cancelButtonLabel: 'Cancel',
  inputPlaceholder_description: 'Description',
  inputPlaceholder_freeForm: 'Account Number',
  inputPlaceholder_iban: 'IBAN (optional, e.g. CH5611112222333344445)',
  inputPlaceholder_walletAddress: 'Blockchain Address',
  inputPlaceholder_xypub: `xpub- or ypub- key (m/x'/x'/x' form)`,
  inputPlaceholder_xyzpub: `xpub-, ypub- or zpub- key (m/x'/x'/x' form)`,
  invalidWalletAddress:
    'The entered wallet address is invalid. Please try again.',
  saveButtonLabel: 'Save'
};

export default accountInstructionFormCopyText;
