import { useCallback, useMemo, useState } from 'react'

import { FilterOption } from '@omniex/poms-ui/lib/types'

type CategoricalState = Array<string>

export type CategoricalQueryArgs = CategoricalState

type CategoricalOptions = Array<FilterOption>

type CategoricalFilterHookArguments = {
  defaultState?: CategoricalState
  options: CategoricalOptions
}

export type CategoricalFilterHook = {
  filterData: {
    options: CategoricalOptions
    values: CategoricalState
    type: 'category'
  }
  queryArgs: CategoricalQueryArgs
  handleFilter: (input: CategoricalState) => void
}

export function useCategoricalFilter({ defaultState, options }: CategoricalFilterHookArguments): CategoricalFilterHook {

  const [values, setValues] = useState<CategoricalState>(defaultState || [])

  const handleFilter = useCallback((input: CategoricalState) => setValues(input), [setValues])

  const queryArgs = useMemo(() => values, [values])

  return {
    filterData: {
      options,
      values,
      type: 'category'
    },
    queryArgs,
    handleFilter
  }
}
