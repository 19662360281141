const portfolioPositionsTableCopy = {
  subheaderCrypto: 'Crypto',
  subheaderFiat: 'Fiat',
  subheaderFuture: 'Futures & Perpetual Swaps',
  tableHeaderAsset: 'Asset',
  tableHeaderCurrentPrice: 'Current Price',
  tableHeaderExpiration: 'Expiration',
  tableHeaderQuantity: 'Quantity',
  tableHeaderTotalAdjustedCostBasis: 'Total Adjusted Cost Basis',
  tableHeaderAverageAdjustedCostBasis: 'Average Adjusted Cost Basis',
  tableHeaderCurrentMarketValue: 'Current Market Value',
  tableHeaderProfitLoss: 'Profit/Loss',
  tableHeaderValue: 'Value',
  tableHeaderEntryPrice: 'Entry Price',
  tableHeaderMarkPrice: 'Mark Price',
  tableHeaderLiquidationPrice: 'Liquidation Price',
  tableHeaderLiquidationValue: 'Liquidation Value',
  tableHeaderMargin: 'Margin',
  tableHeaderUnrealizedPnL: 'Unrealized PnL (ROE %)',
  warningMessageNoCryptoPositions: 'There are no open crypto positions associated with your portfolio at this time.',
  warningMessageNoFiatPositions: 'There are no fiat balances associated with your portfolio at this time.',
  warningMessageNoPositions: 'There are no positions associated with your portfolio at this time.'
};

export default portfolioPositionsTableCopy;
