import { arrayOf, func, shape, string } from 'prop-types';
import { Icon, Popup } from 'semantic-ui-react';
import React, { Fragment, PureComponent } from 'react';
import styled from 'react-emotion';

import { colors } from '@omniex/onx-common-ui/lib/styles';
import { formatNum } from '../../utils/SettlementUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import { getDateFormat, getDateFormatSimple } from '../../utils/DisplayUtils';
import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { isEmpty } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { orderBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import copyText from './ConfirmSettlementsBlotterTable.copyText';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import Table from '@omniex/onx-common-ui/lib/semantic/react/Table';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/table.css');

const COMPONENT_NAME = 'ConfirmSettlementsBlotterTable';

const popupStyles = {
  container: {
    fontSize: '0.9em',
    padding: '8px 16px'
  },
  identifierLabel: {
    fontSize: '0.9em',
    textAlign: 'right'
  },
};

const StyledTable = styled(Table)`
  .${COMPONENT_NAME}-expandedContent {
    border: 1px solid ${colors.borderColor};
  }

  .${COMPONENT_NAME}-fixID {
    font-family: monospace;
    font-size: 0.9em;
  }

  .${COMPONENT_NAME}-warningMessage {
    margin-top: 5px !important;
  }

  .${COMPONENT_NAME}-centerCell {
    text-align: center !important;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class ConfirmSettlementsBlotterTable extends PureComponent {
  static propTypes = {
    fixSenderCompID: string,
    settlements: arrayOf(
      shape({
        id: string.isRequired,
        allocations: arrayOf(
          shape({
            id: string.isRequired,
            currency: shape({
              id: string.isRequired,
              symbol: string.isRequired
            }).isRequired
          })
        ),
        fixAllocID: string.isRequired,
        initiatorOrg: shape({ name: string.isRequired }).isRequired,
        respondentOrg: shape({ name: string.isRequied }).isRequired
      })
    ),
    renderExpandedContent: func
  };

  static defaultProps = { renderExpandedContent: noop };

  state = {};

  static getDerivedStateFromProps(nextProps, prevState) {
    if (isEmpty(prevState)) return getInititalState(nextProps);

    let stateChanges = {};

    return stateChanges;
  }

  render() {
    if (get(this.props, 'settlements.length', 0) === 0) {
      return this._renderWarningMessage();
    }

    const settlements = orderBy(
      get(this.props, 'settlements', []),
      'timeCreated',
      'desc'
    );

    return (
      <StyledTable className={COMPONENT_NAME}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {copyText.tableHeaderTimeExecuted}
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Popup
                flowing
                hoverable
                hideOnScroll
                inverted
                style={popupStyles.container}
                trigger={<span>{copyText.tableHeaderFixAllocID}</span>}>
                <div style={popupStyles.identifierLabel}>
                  <span>{copyText.popupLabelFixAllocID}</span>
                </div>
              </Popup>
            </Table.HeaderCell>
            <Table.HeaderCell className={cn('centerCell')}>
              <Icon color="green" name="plus" />
              {copyText.tableHeaderIncoming}
            </Table.HeaderCell>
            <Table.HeaderCell className={cn('centerCell')}>
              <Icon color="red" name="minus" />
              {copyText.tableHeaderOutgoing}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {copyText.tableHeaderInitiatorOrg}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {copyText.tableHeaderRespondentOrg}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {settlements.map(settlement => this._renderRow(settlement))}
        </Table.Body>
      </StyledTable>
    );
  }

  _renderRow(settlement) {
    const active = !this.state.collapsedSettlementIds.includes(settlement.id);
    const { fixSenderCompID } = this.props;
    const { pos, neg } = settlement.allocations.reduce((flows, a) => {
      a.fixToCompID === fixSenderCompID ? flows.pos.push(a) : flows.neg.push(a);
      return flows;
    }, {pos: [], neg: []});

    return (
      <Fragment key={settlement.id}>
        <Table.Row
          onClick={
            this.props.renderExpandedContent
              ? this._handleClickExpandRow.bind(this, settlement.id)
              : undefined
          }>
          <Table.Cell>
            {!this.props.renderExpandedContent ? null : (
              <Icon
                name={`triangle ${
                  !this.state.collapsedSettlementIds.includes(settlement.id)
                    ? 'down'
                    : 'right'
                }`}
              />
            )}
            <Popup
              flowing
              hoverable
              hideOnScroll
              inverted
              style={popupStyles.container}
              trigger={<span>{getDateFormatSimple(settlement.timeCreated)}</span>}>
              <div style={popupStyles.identifierLabel}>
                <span>{getDateFormat(settlement.timeCreated)}</span>
              </div>
            </Popup>
          </Table.Cell>
          <Table.Cell className={cn('fixID')}>
            {settlement.fixAllocID}
          </Table.Cell>
          <Table.Cell className={cn('centerCell')}>{this._renderAllocationData(pos)}</Table.Cell>
          <Table.Cell className={cn('centerCell')}>{this._renderAllocationData(neg)}</Table.Cell>
          <Table.Cell>{get(settlement, 'initiatorOrg.name')}</Table.Cell>
          <Table.Cell>{get(settlement, 'respondentOrg.name')}</Table.Cell>
        </Table.Row>
        {active ? (
          <Table.Row>
            <Table.Cell
              className={cn('expandedContent')}
              colSpan={6}>
              {this.props.renderExpandedContent(settlement)}
            </Table.Cell>
          </Table.Row>
        ) : null}
      </Fragment>
    );
  }

  _renderWarningMessage() {
    return (
      <div className={COMPONENT_NAME}>
        <Message
          className={cn('warningMessage')}
          content={copyText.warningMessageNoSettlements}
          warning
        />
      </div>
    );
  }

  _renderAllocationData(allocations) {
    return allocations.map((allocation, index) => (
      <span key={allocation.id}>
        {`${formatNum(allocation.quantity, allocation.currency.type)
        } ${getAssetDisplayText(allocation.currency)
        } ${allocations.length - 1 !== index ? '/ ' : ''}`}
      </span>
    ));
  }

  _handleClickExpandRow = settlementId => {
    this.setState(currentState => ({
      collapsedSettlementIds: currentState.collapsedSettlementIds.includes(
        settlementId
      )
        ? currentState.collapsedSettlementIds.filter(n => settlementId !== n)
        : [...currentState.collapsedSettlementIds, settlementId]
    }));
  };
}

function getInititalState(props) {
  return {
    collapsedSettlementIds:
      get(props, 'settlements.length', 0) <= 1
        ? []
        : props.settlements.map(settlement => settlement.id)
  };
}
