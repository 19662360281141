import { createSelector } from 'reselect';
import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import getCurrenciesKeyedById from './getCurrenciesKeyedById';

export function populateInstrumentsWithCurrencies(instruments, currencies) {
  if (
    !Array.isArray(instruments) ||
    instruments.length === 0 ||
    !Array.isArray(currencies) ||
    currencies.length === 0
  ) {
    return [];
  }

  const currenciesKeyedById = getCurrenciesKeyedById(currencies);

  return instruments.map(instrument => ({
    ...instrument,
    baseCurrency: currenciesKeyedById[instrument.baseAsset.id],
    termCurrency: currenciesKeyedById[instrument.termAsset.id],
    baseAsset: currenciesKeyedById[instrument.baseAsset.id],
    termAsset: currenciesKeyedById[instrument.termAsset.id],
    marginAsset: currenciesKeyedById[get(instrument, 'marginAsset.id')]
  }));
}

export default createSelector(
  (instruments, currencies) => instruments,
  (instruments, currencies) => currencies,
  populateInstrumentsWithCurrencies
);
