import gql from 'graphql-tag';

export default gql`
  query getCustomCustodians {
    customCustodians: getCustomCustodians {
      bankAddress
      description
      id
      name
      swiftCode
      type
    }
  }
`;
