import * as React from 'react'
import { useEffect, useCallback, useState, useMemo } from 'react';
import { ApolloProvider } from 'react-apollo';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { StylesProvider } from '@material-ui/core/styles';
import { generateClassName } from '@omniex/poms-ui/lib/themes';

import { ERROR_UNAUTHENTICATED, ERROR_UNEXPECTED } from '@omniex/onx-common-ui/lib/errors';
import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { hasRoleType } from '@omniex/onx-poms-entity-helpers/lib/utils/UserUtils';
import { isBlank } from '@omniex/onx-common-js/lib/utils/StringUtils';
import { isError } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { safeExecAsync } from './utils/FunctionUtils';
import AppRouter from './AppRouter';
import createClient from './apollo/utils/createClient';
import ErrorPage from '@omniex/onx-common-ui/lib/pages/ErrorPage';
import getAuthenticatedUser from './apollo/graphql/getAuthenticatedUser';
import LoadingPage from './ui/pages/LoadingPage';
import normalizeError from './apollo/utils/normalizeError';
import OrgType from '@omniex/onx-poms-entity-helpers/lib/enums/OrgType';
import RedirectingPage from './ui/pages/RedirectingPage';
import User from './utils/AuthUtils';
import UserRoleType from '@omniex/onx-poms-entity-helpers/lib/enums/UserRoleType';
import updateRecentInputHistory from './apollo/graphql/updateRecentInputHistory';
import copyText from './App.copyText';
import apm from './apm';
import { v4 as uuidv4 } from 'uuid';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/reset.css');
require('@omniex/onx-common-ui/lib/semantic/css/site.css');

const LAST_SELECTED_PORTFOLIO_KEY = 'PageLayoutHeaderMenu_portfolio'

const App = ({ apiClient, loginUrl, brandUrl }) => {
  const [websocketConnected, setWebsocketConnected] = useState(false);

  const { data, error, loading } = useQuery(getAuthenticatedUser, { client: apiClient })
  const [updateInputHistory] = useMutation(updateRecentInputHistory, { client: apiClient })

  useEffect(_ => {
    if (document.referrer === loginUrl) window.localStorage.clear()
  }, [loginUrl])

  const user = new User({ ...data?.authenticatedUser, inputHistory: data?.inputHistory })

  useEffect(() => {
    apm.setUserContext({
      id: user.id,
      username: user.name,
      email: user.emailAddress,
    });
  }, [user])

  useEffect(() => {
    apm.addLabels({ correlationId: uuidv4() })
  }, []);

  const [lastSelectedPortfolio] = user?.inputHistory?.[LAST_SELECTED_PORTFOLIO_KEY] || []

  const portfolios = user?.org?.portfolios || []
  const defaultPortfolio = portfolios.length === 1
    ? portfolios[0]
    : portfolios.find(p => p.id === lastSelectedPortfolio) || portfolios.find(p => p.isDefault)

  const [portfolio, setPortfolio] = useState(defaultPortfolio)
  const portfolioInvalid = !portfolio || !portfolios.some(p => p.id === portfolio?.id)

  const updatePortfolio = (portfolio) => {
    const variables = { inputs: [{ key: LAST_SELECTED_PORTFOLIO_KEY, value: portfolio?.id }] }

    safeExecAsync(updateInputHistory, { variables })
    setPortfolio(portfolio)
  }

  if (portfolioInvalid && defaultPortfolio) setPortfolio(defaultPortfolio)

  const logout = useCallback((q = '') => {
    const url = `${loginUrl}${typeof q === 'string' ? q : ''}`
    window.location.href = window.encodeURI(url)
  }, [loginUrl])

  const orgType = get(user, 'org.type')
  const buySide = orgType === OrgType.CLIENT_BUY_SIDE
  const fixApiUrl = get(user, `org.${buySide ? 'taker' : 'maker'}ApiBaseUrl`)
  const fixApiClient = useMemo(() => {
    if (!fixApiUrl) return null;
    const onConnect = () => setWebsocketConnected(true);
    const onDisconnect = () => setWebsocketConnected(false);
    const onReconnect = () => setWebsocketConnected(true);
    return createClient(fixApiUrl, { withSubscriptions: true, loginUrl, taker: buySide, onConnect, onDisconnect, onReconnect });
  }, [fixApiUrl, buySide, loginUrl])

  if (loading) return <LoadingPage message="Authenticating..." />

  const errorType = normalizeError(error)
  if (errorType === ERROR_UNAUTHENTICATED) return <RedirectingPage href={`${loginUrl}?redirect=${window.location.href}`} />
  if (isError(error)) return <ErrorPage type={errorType} />

  const sysAdmin = hasRoleType(user, UserRoleType.SYSTEM_ADMIN)

  if (sysAdmin) return <RedirectingPage href={brandUrl} />
  if (isBlank(fixApiUrl)) return <ErrorPage type={ERROR_UNEXPECTED} />

  if (!portfolios?.length && !user.canViewAdmin) return <ErrorPage header={copyText.errNoPortfoliosHeader} content={copyText.errNoPortfoliosContent} />

  return (
    <ApolloProvider client={apiClient}>
      <StylesProvider generateClassName={generateClassName}>
        <div className="App">
          <AppRouter
            apiClient={apiClient}
            loginUrl={loginUrl}
            fixApiClient={fixApiClient}
            user={user}
            onLogout={logout}
            portfolio={portfolio}
            setPortfolio={updatePortfolio}
            websocketConnected={websocketConnected}
          />
        </div>
      </StylesProvider>
    </ApolloProvider>
  )
}

export default App
