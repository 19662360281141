import gql from 'graphql-tag';

const getDistinctOrderValues = gql`
  query getDistinctOrderValues {
    distinctValues: getDistinctOrderValues {
      algorithmStrategies
      features
      portfolios {
        id
      }
      instruments {
        id
        displayName
        identifier
        symbol
      }
      traders {
        id
        username
      }
      venues {
        id
        name
      }
    }
  }
`;

export default getDistinctOrderValues;
