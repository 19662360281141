import gql from 'graphql-tag';

export default gql`
  query getClientAssets($orgId: ID) {
    assets: getClientAssets(orgId: $orgId) {
      id
      name
      price
      source
      symbol
      type
    }
  }
`;
