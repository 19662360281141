import gql from 'graphql-tag';

export default gql`
  mutation initiateSettlement(
    $fillIds: [ID!]!
    $allocationInputs: [AllocationInput!]!
  ) {
    settlement: initiateSettlement(
      fillIds: $fillIds
      allocationInputs: $allocationInputs
    ) {
      id
    }
  }
`;
