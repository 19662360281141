import * as React from 'react';
import { Order } from '../../../types';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import copyText from '../OrderBlotterTable.copyText';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@omniex/poms-ui/lib/themes';
import { getStatusColor } from './shared';
// @ts-ignore
import OrderStatus from '@omniex/poms-core/lib/enums/OrderStatus';

type OrderStatusPopupProps = {
  order: Order
}

const useStyles = makeStyles<OrderStatusPopupProps>({
  cancelLabel: {
    display: 'flex',
    flexDirection: 'column'
  },
  container: {
    fontSize: '0.9em',
    padding: '8px 16px',
    position: 'absolute'
  },
  identifierLabel: {
    fontSize: '0.9em',
    textAlign: 'right'
  },
  identifier: {
    fontFamily: 'monospace',
    fontSize: '0.9em'
  },
  info: {
    fontSize: '0.9em',
    textAlign: 'center'
  },
  questionIcon: {
    paddingLeft: '4px'
  },
  text: {
    color: ({ order }) => getStatusColor(order.status),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}, { name: 'OrderStatusPopup' });

const OrderStatusPopup: React.FC<OrderStatusPopupProps> = (props) => {
  const cls = useStyles(props);
  const { order } = props;

  return (
    [
      OrderStatus.CANCELLATION_REQUESTED,
      OrderStatus.PENDING_CANCEL,
      OrderStatus.CANCELED,
      OrderStatus.REJECTED
    ].includes(order.status) ? (
        <Tooltip
          interactive
          className={cls.cancelLabel}
          title={order.status === OrderStatus.REJECTED ? (
            <div>
              {order.reasonRejected || `${copyText.popupStatusRejection} ${copyText.notAvailable}`}
            </div>
          ) : (
              <div className={cls.cancelLabel}>
                <span>
                  {copyText.popupStatusCancel} {order.requestCancelTrader?.username || copyText.popupLabelSystem}
                </span>
                <span>
                  {copyText.popupStatusCanceled}
                  {order.reasonCanceled || copyText.notAvailable}
                  <a
                    href={copyText.popupStatusCanceledUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={cls.questionIcon}
                  >
                    <HelpOutlineIcon />
                  </a>
                </span>
                {order.reasonCancellationRejected &&
                  <span>
                    {copyText.popupStatusCancellationRejected}
                    {order.reasonCancellationRejected}
                  </span>
                }
              </div>
            )}
        >
          <span className={cls.text}>{order.status}</span>
        </Tooltip>
      ) : (
        <span className={cls.text}>{order.status}</span>
      )
  );
};

export default OrderStatusPopup;
