import { bool, func, shape, string } from 'prop-types';
import { Button, Form, Input } from 'semantic-ui-react';
import React, { Component } from 'react';
import styled from 'react-emotion';

import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import copyText from './AccountUpdateForm.copyText';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/button.css');
require('@omniex/onx-common-ui/lib/semantic/css/input.css');
require('@omniex/onx-common-ui/lib/semantic/css/form.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const COMPONENT_NAME = 'AccountUpdateForm';

const StyledForm = styled(Form)`
  display: flex !important;
  font-size: 1em !important;
  margin-bottom: 28px !important;
  margin-right: 14px !important;
  margin-top: 14px !important;
  max-width: 300px;

  .field {
    border-right-color: 'blue';
    margin-bottom: 0 !important;

    :first-child {
      margin-right: 14px !important;
      max-width: 200px;
      min-width: 200px;
    }

    :nth-child(2) {
      margin-right: 14px !important;
    }
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class AccountUpdateForm extends Component {
  static propTypes = {
    account: shape({
      id: string.isRequired,
      name: string.isRequired
    }),
    isFiat: bool,
    onCancel: func,
    onSave: func
  };

  static defaultProps = {
    onCancel: noop,
    onSave: noop
  };

  state = {
    name: get(this.props, 'account.name', '') //TODO: use get initialstate on first render to prevent flicker
  };

  render() {
    return (
      <StyledForm className={cn('accountUpdateForm')} autoComplete="off">
        <Form.Field className={cn('fieldAddress')}>
          <Input
            name="name"
            placeholder={copyText.inputPlaceholder_name}
            required
            value={this.state.name}
            onChange={this._handleChangeField}
          />
        </Form.Field>
        <Form.Field>
          <Button
            color="orange"
            disabled={!this._canSave() || this.props.processing}
            fluid
            loading={this.props.processing}
            size="tiny"
            type="submit"
            onClick={this._handleClickSubmit.bind(this)}>
            {copyText.saveButtonLabel}
          </Button>
        </Form.Field>
        <Form.Field>
          <Button
            fluid
            loading={this.props.processing}
            size={'tiny'}
            type="reset"
            onClick={this._handleClickCancel.bind(this)}>
            {copyText.cancelButtonLabel}
          </Button>
        </Form.Field>
      </StyledForm>
    );
  }

  _canSave() {
    return (
      get(this.state, 'name.length', 0) > 0 &&
      this.state.name !== get(this.props, 'account.name', '')
    );
  }

  _handleChangeField = (event, input) => {
    const changes = { [input.name]: input.value };

    if (changes.description && changes.description.length > 36) {
      return;
    }

    this.setState(changes);
  };

  _handleClickCancel = event => {
    event.preventDefault();

    this.props.onCancel();
  };

  _handleClickSubmit = event => {
    event.preventDefault();

    const { name } = this.state;
    const accountId = get(this.props, 'account.id');

    this.props.onSave({
      accountId,
      name
    });
  };
}
