import { createSelector } from 'reselect';

import getVenueOptions from './getVenueOptions';
import VenueType from '@omniex/onx-poms-entity-helpers/lib/enums/VenueType';

export function getGroupedVenueOptions(venues) {
  if (!Array.isArray(venues) || venues.length === 0) return [];

  const [exchange, otc] = venues.reduce(
    (venues, venue) => {
      if (venue.type === VenueType.EXCHANGE) {
        venues[0].push(venue);
      } else if (venue.type === VenueType.OTC) {
        venues[1].push(venue);
      }
      return venues;
    },
    [[], []]
  );

  return [
    { label: 'Exchange', options: getVenueOptions(exchange) },
    { label: 'OTC', options: getVenueOptions(otc) }
  ];
}

export default createSelector(
  venues => venues,
  getGroupedVenueOptions
);
