const portfolioBreakdownChartCopy = {
  chartLabelSelfCustody: 'Self Custody',
  chartLabelUnsettled: 'Unsettled',
  brokenByCurrency: 'broken down by Asset',
  brokenByCustody: 'broken down by Custody',
  titleByCurrency: 'Breakdown by Asset',
  titleByCustody: 'Breakdown by Custody',
  warningNegBalance: 'At least one account with a negative balance has been excluded from this data.',
  errorMessage: 'Portfolio breakdown cannot be generated at this time.',
  warningMessage: 'There are no positions associated with your portfolio at this time.'
};

export default portfolioBreakdownChartCopy;
