import { Decimal } from 'decimal.js';
import { Icon } from 'semantic-ui-react';
import React from 'react';
import styled from 'react-emotion';

import { FMT_STR_CRYPTO, FMT_STR_FIAT, getFiatSymbol, truncateTrailingZeroes } from '../../utils/DisplayUtils';
import { format as formatNumber } from '@omniex/onx-common-js/lib/utils/NumberUtils';
import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import { isNil } from '@omniex/onx-common-js/lib/utils/LangUtils';
import AssetType from '@omniex/poms-core/lib/enums/AssetType';
import calculateMarketPrice from '../../selectors/calculateMarketPrice';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './ProceedMessage.copyText';
import InstrumentType from '@omniex/onx-poms-entity-helpers/lib/enums/InstrumentType';
import OrderAlgorithmStrategy from '@omniex/onx-poms-entity-helpers/lib/enums/OrderAlgorithmStrategy';
import OrderSide from '@omniex/onx-poms-entity-helpers/lib/enums/OrderSide';
import OrderType from '@omniex/onx-poms-entity-helpers/lib/enums/OrderType';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import { pluralize } from '@omniex/onx-common-js/lib/utils/StringUtils';

const COMPONENT_NAME = 'ProceedMessage';

const StyledMessage = styled(Message)`

.${COMPONENT_NAME}-confirmContent {
}

.${COMPONENT_NAME}-confirmMessage {
  white-space: normal;
}

.${COMPONENT_NAME}-contractContent {
  margin-top: 8px;
  padding-left: 8px;
}

.${COMPONENT_NAME}-contractContentLabel {
  flex-grow: 1;
}

.${COMPONENT_NAME}-contractContentRow {
  display: flex;
  flex-flow: row nowrap;
}

.${COMPONENT_NAME}-contractContentValue {
}

.${COMPONENT_NAME}-contractFooter {
  margin-top: 8px;
}

.${COMPONENT_NAME}-insufficientFundsIcon{
}

.${COMPONENT_NAME}-insufficientFundsMessage {
  font-size: 12px;
  line-height: 1.2em;
}

.${COMPONENT_NAME}-insufficientFundsTable{
  color: ${colors.red};
  margin-top: 0.5em;
}

.${COMPONENT_NAME}-insufficientFundsVenues{
  color: ${colors.red};
  display: flex;
  justify-content: center;
  margin-top: 0.5em;
}

.${COMPONENT_NAME}-insufficientFundsVenue{
  font-size: 12px;
  line-height: 1.2em;
}

.${COMPONENT_NAME}-insufficientFundsVenueAllocation{
  font-size: 12px;
  line-height: 1.2em;
  padding-left: 15px;
  text-align: right;
}
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export const ProceedMessage = ({
  algoOrder,
  algorithmStrategy,
  currentPrices,
  basePositionQuantity,
  entriesGroupedByType,
  instrument,
  limitPrice,
  marginPositionQuantity,
  orderType,
  projectedPrice,
  quantity,
  side,
  stopPrice,
  termPositionQuantity,
  visible,
  venueOptions,
}) => {

  if (!visible) return null;

  let header, content, insufficientFunds;

  if (instrument.type === InstrumentType.FUTURE) {
    header = getConfirmationHeaderForContracts({
      entriesGroupedByType,
      instrument,
      algoOrder,
      limitPrice,
      orderType,
      quantity,
      side,
      stopPrice,
      text: copyText.contractMessageHeader,
    });

    content = getConfirmationContentForContracts({
      basePositionQuantity,
      currentPrices,
      instrument,
      marginPositionQuantity,
      quantity,
      side
    });
  } else if (algoOrder) {
    const expectedPrice = algorithmStrategy === OrderAlgorithmStrategy.ICE
      ? limitPrice * quantity
      : algorithmStrategy === OrderAlgorithmStrategy.SOR
      ? projectedPrice.desiredTotalPrice || 0
      : calculateMarketPrice(entriesGroupedByType) * quantity;

    header = getConfirmationHeader({
      instrument,
      price: expectedPrice,
      text: copyText.algoConfirmationHeader,
    });

    content = copyText.algoConfirmationContent;

    const venuesWithInsufficientFunds = venueOptions.filter(v => 
        side === OrderSide.BUY
          ? v.termAsset?.quantity < expectedPrice || (v.termAsset?.quantity === expectedPrice && projectedPrice.insufficientOrderBookEntryBalance)
          : v.baseAsset?.quantity < quantity || (v.baseAsset?.quantity === quantity && projectedPrice.insufficientOrderBookEntryBalance)
    );

    const hasInsufficientFunds = venuesWithInsufficientFunds.length > 0;

    insufficientFunds = hasInsufficientFunds ? getAlgoInsufficientFundsMessage({ venueOptions: venuesWithInsufficientFunds, instrument, side }) : null;
  } else {
    const expectedPrice = getExpectedPrice(orderType, quantity, limitPrice, stopPrice, entriesGroupedByType, algoOrder, instrument)

    const hasInsufficientFunds = side === OrderSide.BUY
      ? termPositionQuantity < expectedPrice
      : basePositionQuantity < quantity;

    header = getConfirmationHeader({
      instrument,
      price: expectedPrice,
      text: copyText.dmaConfirmationHeader
    });

    content = copyText.dmaConfirmationContent;

    insufficientFunds = hasInsufficientFunds ? getInsufficientFundsMessage({
      basePositionQuantity,
      instrument,
      side,
      termPositionQuantity,
      text: copyText.dmaInsufficientFunds,
    }) : null;
  }

  return (
    <StyledMessage
      className={cn('confirmMessage')}
      content={
        <>
          <div className={cn('confirmContent')}>{content}</div>
          {insufficientFunds}
        </>
      }
      header={header}
      warning
    />
  )
}

function getConfirmationHeader({ instrument, price, text }) {
  return text.replace(
    '%marketValue%',`${
      get(instrument, 'termAsset.type') === AssetType.FIAT
        ? formatNumber(price, FMT_STR_FIAT)
        : getDisplayFormattedNum(price)
    } ${getAssetDisplayText(get(instrument, 'termAsset'))}`
  );
}

function getConfirmationHeaderForContracts({ entriesGroupedByType, instrument, algoOrder, limitPrice, orderType, quantity, side, stopPrice, text }) {
  const expectedPrice = getExpectedPrice(orderType, quantity, limitPrice, stopPrice, entriesGroupedByType, algoOrder, instrument);
  const asset = hasContractSize(instrument) || hasContractPriceMultiplier(instrument) ? get(instrument, 'contractSizeAsset') : get(instrument, 'termAsset');
  const notional = hasContractSize(instrument) || hasContractPriceMultiplier(instrument) ? ' notional' : '';
  const formattedPrice =  asset?.type === AssetType.FIAT ? formatNumber(expectedPrice, FMT_STR_FIAT) : getDisplayFormattedNum(expectedPrice);

  return text
    .replace('%contractPluralization%', pluralize('contract', parseInt(quantity)))
    .replace('%instrument%', get(instrument, 'displayName'))
    .replace('%price%', `${formattedPrice} ${getAssetDisplayText(asset)}${notional}`)
    .replace('%quantity%', quantity)
    .replace('%side%', side.toLowerCase());
}

function getConfirmationContentForContracts({ basePositionQuantity, currentPrices, instrument, marginPositionQuantity, quantity, side }) {
  const positionAfterExecution = side === OrderSide.BUY
    ? Decimal.add(basePositionQuantity, quantity).toNumber()
    : Decimal.sub(basePositionQuantity, quantity).toNumber();

  const {
    marginAsset,
    markPrice,
    marginValue,
  } = get(currentPrices, `${get(instrument, 'baseAsset.id')}`, {});

  const marginAssetSymbol = getAssetDisplayText(marginAsset);

  const makeRow = (label, value) => (
    <div className={cn('contractContentRow')}>
      <div className={cn('contractContentLabel')}>{label}</div>
      <div className={cn('contractContentValue')}>{value}</div>
    </div>
  );

  return (
    <>
      <div className={cn('contractContent')}>
        {makeRow(copyText.contractOrderValue, `${formatNumber(quantity * marginValue, FMT_STR_CRYPTO)} ${marginAssetSymbol}`)}
        {makeRow(copyText.contractAvailableBalance, `${formatNumber(marginPositionQuantity, FMT_STR_CRYPTO)} ${marginAssetSymbol}`)}
        {makeRow(copyText.contractPositionAfterExecution, positionAfterExecution)}
        {makeRow(copyText.contractMarkPrice, markPrice)}
      </div>
      <div className={cn('contractFooter')}>
        {copyText.dmaConfirmationContent}
      </div>
    </>
  );
}

function getDisplayFormattedNum(num) {
  if (isNil(num) || isNaN(num)) {
    return '';
  };

  const re = /^[0-9]+\.?(?<frac>[0-9]*)e(?<exp>[-+]?[0-9]+)$/;
  const f = n => n.toExponential().match(re).groups;
  // eslint-disable-next-line no-sequences
  const countDigits = (n, frac, exp) => ({ frac, exp } = f(n), Math.max(0, frac.length - exp));
  const format = `0,0.${'0'.repeat(countDigits(num))}`;

  return formatNumber(num, format);
}

const hasContractSize = (instrument) => 
  instrument.type === InstrumentType.FUTURE &&
  typeof instrument.contractSize === 'number';

const hasContractPriceMultiplier = (instrument) =>
  instrument.type === InstrumentType.FUTURE &&
  typeof instrument.contractPriceMultiplier === 'number';

/**
 * The expected price varies depending on whether the order is an algo or dma order.
 * All algo orders use the calculated market price based on the market data entries,
 * as do market dma orders. Other dma orders use different prices depending on their type.
 *
 * @param {String} t order type or algorithm strategy
 * @param {Number} q quantity
 * @param {Number} lp limit price
 * @param {Number} sp stop price
 * @param {Object} egbt market data entries grouped by type
 * @param {Boolean} algoOrder
 * @param {Object} instrument
 * @returns {Number} expected price, 0 by default
 */
const getExpectedPrice = (t, q, lp, sp, egbt, algoOrder, instrument) => {
  let multiplier;

  if (hasContractSize(instrument)) {
    multiplier = instrument.contractSize;
  } else if (hasContractPriceMultiplier(instrument)) {
    multiplier = instrument.contractPriceMultiplier * calculateMarketPrice(egbt);
  } else if (algoOrder) {
    multiplier = calculateMarketPrice(egbt);
  } else {
    multiplier = ({
      [OrderType.LIMIT]: () => lp,
      [OrderType.MARKET]: () => calculateMarketPrice(egbt),
      [OrderType.STOP_LIMIT]: () => lp,
      [OrderType.STOP_LOSS]: () => sp
    }[t.split(':')[0]] || (_ => 0))();
  }

  return q * multiplier;
}

function getInsufficientFundsOptions({ basePositionQuantity, instrument, side, termPositionQuantity }){
  const { baseAsset, termAsset } = instrument;

  let availableFunds = '';
  let fiatSymbol = '';
  let outgoingCurrency = '';

  if (side === OrderSide.BUY) {
    if (termAsset.type === AssetType.FIAT){
      availableFunds = formatNumber(termPositionQuantity, FMT_STR_FIAT);
      fiatSymbol = getFiatSymbol(termAsset.symbol);
    } else {
      availableFunds = formatNumber(truncateTrailingZeroes(termPositionQuantity), FMT_STR_CRYPTO);
      outgoingCurrency = getAssetDisplayText(termAsset);
    }
  } else {
    availableFunds = truncateTrailingZeroes(basePositionQuantity);
    outgoingCurrency = getAssetDisplayText(baseAsset);
  }

  return {
    availableFunds, 
    fiatSymbol,
    outgoingCurrency
  }
}

function getInsufficientFundsMessage({ basePositionQuantity, instrument, side, termPositionQuantity, text }) {
  const { availableFunds, fiatSymbol, outgoingCurrency } = getInsufficientFundsOptions({ basePositionQuantity, instrument, side, termPositionQuantity });

  const message = text
    .replace('%availableFunds%', availableFunds)
    .replace('%fiatSymbol%', fiatSymbol)
    .replace('%outgoingCurrency%', ` ${outgoingCurrency}`);

  return (
    <div>
      <table className={cn('insufficientFundsTable')}>
        <tbody>
          <tr>
            <td className={cn('insufficientFundsIcon')}>
              <Icon name='warning sign' size='large'/>
            </td>
            <td className={cn('insufficientFundsMessage')}>
              {message}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

const VenueOption = ({  side, instrument, venue }) => {
  const { termAsset, baseAsset, label } = venue;
  const { quantity: termPositionQuantity } = termAsset;
  const { quantity: basePositionQuantity } = baseAsset;

  const { availableFunds, fiatSymbol, outgoingCurrency } = getInsufficientFundsOptions({ basePositionQuantity, instrument, side, termPositionQuantity });

  return (
    <tr>
      <td className={cn('insufficientFundsVenue')}>
        {label}
      </td>
      <td className={cn('insufficientFundsVenueAllocation')}>{fiatSymbol}{availableFunds} {outgoingCurrency}</td>
    </tr>
  );
}

function getAlgoInsufficientFundsMessage({ venueOptions, ...props}){
  return (
    <div>
      <table className={cn('insufficientFundsTable')}>
        <tbody>
          <tr>
            <td className={cn('insufficientFundsIcon')}>
              <Icon name='warning sign' size='large'/>
            </td>
            <td className={cn('insufficientFundsMessage')}>
              {copyText.algoInsufficientFunds}
            </td>
          </tr>
        </tbody>
      </table>
      <div className={cn('insufficientFundsVenues')}>
        <table>
          <tbody>
            {venueOptions.map((v, i) => <VenueOption key={i} {...props} venue={v} />)}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ProceedMessage;
