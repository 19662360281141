const integrationsTableCopy = {
  blockedIntegration: `The Integration with Silvergate has been blocked. Please contact the Omniex Operations team`,
  enableFuturesTooltip: 'Enable Futures allows you to trade and view futures-related information in Edge. Both Trade and Read API keys should be generated from your futures account at Binance with the ‘Enable Futures’ option enabled under API restriction settings.',
  popupRemoveWarningHeader: 'Are you sure you want to proceed?',
  popupRemoveWarningMessage: 'Removing this integration will permanently disable functionality provided by the %name% API.',
  requestedIntegration: `The Integration with Silvergate is pending confirmation by the Omniex Operations team`,
  status_BLOCKED: 'Blocked',
  status_REQUESTED: 'Requested',
  status_REVIEW: 'Needs Review',
  status_UNVERIFIED: 'Unverified',
  status_VERIFIED: 'Verified',
  tableHeaderEnableFutures: 'Enable Futures',
  tableHeaderKeyPreview: 'Key Preview (Trade & Read)',
  tableHeaderKeyForViewPreview: 'Key Preview (Read-Only)',
  tableHeaderName: 'Name',
  tableHeaderStatus: 'Status',
  type_CUSTODIAN_SILVERGATE: 'Silvergate Custodian',
  type_EXCHANGE_BEQUANT: 'BeQuant Exchange',
  type_EXCHANGE_BINANCE: 'Binance Exchange',
  type_EXCHANGE_BINANCEUS: 'Binance US Exchange',
  type_EXCHANGE_BITFINEX: 'Bitfinex Exchange',
  type_EXCHANGE_BITFLYER: 'Bitflyer Exchange',
  type_EXCHANGE_BITMEX: 'BitMEX Exchange',
  type_EXCHANGE_BITSTAMP: 'Bitstamp Exchange',
  type_EXCHANGE_BITTREX: 'Bittrex Exchange',
  type_EXCHANGE_BYBIT: 'Bybit Exchange',
  type_EXCHANGE_CROSSTOWER: 'CrossTower Exchange',
  type_EXCHANGE_DERIBIT: 'Deribit Exchange',
  type_EXCHANGE_FTX: 'FTX Exchange',
  type_EXCHANGE_GDAX: 'Coinbase Pro Exchange',
  type_EXCHANGE_GEMINI: 'Gemini Exchange',
  type_EXCHANGE_HITBTC: 'HitBTC Exchange',
  type_EXCHANGE_HUOBI: 'Huobi Exchange',
  type_EXCHANGE_HUOBIDM: 'Huobi DM Exchange',
  type_EXCHANGE_ITBIT: 'itBit Exchange',
  type_EXCHANGE_KRAKEN: 'Kraken Exchange',
  type_EXCHANGE_KUCOIN: 'KuCoin Exchange',
  type_EXCHANGE_LIQUID: 'Liquid Exchange',
  type_EXCHANGE_LMAX: 'LMAX Exchange',
  type_EXCHANGE_OKCOIN: 'OKCoin Exchange',
  type_EXCHANGE_OKEX: 'OKEx Exchange',
  type_EXCHANGE_POLONIEX: 'Poloniex Exchange',
  type_EXCHANGE_SEEDCX: 'Seed CX Exchange',
  warningMessage: 'There are no integrations configured for your organization at this time.'
};

export default integrationsTableCopy;
