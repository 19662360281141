import * as React from 'react';
import { useState } from 'react';

import { Button, Form, Input } from 'semantic-ui-react';
import { makeStyles } from '@omniex/poms-ui/lib/themes';

// @ts-ignore
import { colors } from '@omniex/onx-common-ui/lib/styles';
// @ts-ignore
import { isBlank } from '@omniex/poms-core/lib/utils/StringUtils';

import copyText from './copyText';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(0),
  },
  buttonWrapper: {
    textAlign: 'right',
  },
  requestContent: {
    fontWeight: 'bold',
  },
  requestInfo: {
    border: `1px solid ${colors.solidBorderColor}`,
    borderRadius: theme.spacing(0.3),
    padding: theme.spacing(1),
  },
  requestRow: {
    margin: theme.spacing(1, 0, 1, 2),
  },
  root: {
    minWidth: '280px',
  },
}));

type CreateApiKeyFormProps = {
  createApiKey: (label: string) => void,
  onCancel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
};

const CreateApiKeyForm: React.FC<CreateApiKeyFormProps> = ({
  createApiKey,
  onCancel
}) => {
  // Styles
  const classes = useStyles();

  // State
  const [label, setLabel] = useState<string>('');

  // Derived Data
  const canSave = !isBlank(label);

  return (
    <Form
      autoComplete="off"
      className={classes.root}
      size={"small"}>
      <Form.Field>
        <Input
          name={'label'}
          onChange={(i, e) => setLabel(e.value)}
          placeholder={copyText.labelInputPlaceholder}
          required
          value={label}
        />
      </Form.Field>
      <Form.Field className={classes.buttonWrapper}>
        <Button
          className={classes.button}
          color="blue"
          disabled={!canSave}
          fluid
          onClick={() => createApiKey(label)}
          size="tiny"
        >
          {copyText.saveButton}
        </Button>
      </Form.Field>
      <Form.Field className={classes.buttonWrapper}>
        <Button
          className={classes.button}
          fluid
          onClick={onCancel}
          size="tiny"
          type="reset"
        >
          {copyText.cancelButton}
        </Button>
      </Form.Field>
    </Form>
  );
};

export default CreateApiKeyForm;
