import { createSelector } from 'reselect';

import { groupBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import { isEmpty } from '@omniex/onx-common-js/lib/utils/LangUtils';

export function getFilteredAccountsGroupedByCurrencyId(accounts) {
  if (!Array.isArray(accounts) || accounts.length === 0) return [];

  return groupBy(
    accounts.filter(account => !isEmpty(account.instructions)),
    'currency.id'
  );
}

export default createSelector(
  accounts => accounts,
  getFilteredAccountsGroupedByCurrencyId
);
