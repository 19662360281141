import gql from 'graphql-tag';

export default gql`
  mutation placeOrderForAlgorithmicExecution(
    $instrumentId: ID!
    $venueIds: [ID!]
    $side: String!
    $specifiedCurrencyId: ID!
    $specifiedQuantity: Float!
    $algorithmStrategy: String!
    $algorithmDuration: Float
    $expireTime: Int
    $orderType: String!
    $expiryType: String!
    $limitPrice: Float
    $stopPrice: Float
    $displayQuantity: Float
    $includeSorFees: Boolean
    $executeSorIfUnfilled: Boolean
    $intervals: Int
    $participationRate: Float
    $customOrderId: String
    $portfolioId: ID!
  ) {
    order: placeOrderForAlgorithmicExecution(
      instrumentId: $instrumentId
      venueIds: $venueIds
      side: $side
      specifiedCurrencyId: $specifiedCurrencyId
      specifiedQuantity: $specifiedQuantity
      algorithmStrategy: $algorithmStrategy
      algorithmDuration: $algorithmDuration
      expireTime: $expireTime
      orderType: $orderType
      expiryType: $expiryType
      limitPrice: $limitPrice
      stopPrice: $stopPrice
      displayQuantity: $displayQuantity
      includeSorFees: $includeSorFees
      executeSorIfUnfilled: $executeSorIfUnfilled
      intervals: $intervals
      participationRate: $participationRate
      customOrderId: $customOrderId
      portfolioId: $portfolioId
    ) {
      id
      fixClOrdID
      status
    }
  }
`;
