import gql from 'graphql-tag';

export default gql`
  subscription NewOrderNotification {
    newOrderNotification {
      id
      reason
      text
      timeCreated
      venue {
        id
        name
      }
      order {
        algorithmStrategy
        feature
        side
        fixClOrdID
        specifiedQuantity
        instrument {
          displayName
          type
        }
      }
    }
  }
`;
