import * as React from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Unstable_TrapFocus as TrapFocus } from '@material-ui/unstyled';

/* eslint-disable no-sequences */
import { colors, layout } from '@omniex/onx-common-ui/lib/styles';
import { Dropdown, Icon, Image, Menu } from 'semantic-ui-react';
import { filterMap } from '@omniex/poms-core/lib/utils/FunctionUtils';
import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getExecutionType } from '@omniex/poms-core/lib/utils/OrderUtils';
import { isEmpty } from '@omniex/poms-core/lib/utils/LangUtils';
import { orderBy } from '@omniex/poms-core/lib/utils/CollectionUtils';
import { useMutation } from 'react-apollo';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment-timezone';
import OrgType from '@omniex/poms-core/lib/enums/OrgType';
import PersonOutline from '@material-ui/icons/PersonOutline';
import Select from '@omniex/onx-common-ui/lib/react-select/Dropdown';
import styled from 'react-emotion';

import {
  computeDisplayFields,
  displayNotification,
  getQtyOrdered,
} from '../../utils/OrderNotificationUtils';
import {
  NotificationCenter,
  NotificationsIcon,
} from '../components/NotificationCenter';
import { safeExecAsync } from '../../utils/FunctionUtils';
import { AllNotificationsInspector } from '../components/NotificationCenter/AllNotificationsInspector';
import config from '../../config';
import DesktopOrderNotification from '../components/DesktopOrderNotification/Notification';
import getOrderNotificationsSince from '../../apollo/graphql/getOrderNotificationsSince';
import labels from './PageLayoutHeaderMenu.copyText';
import MaintenanceCountdownMenuItem from '../components/MaintenanceCountdownMenuItem';
import updateUserAppStateMutation from '../../apollo/graphql/updateUserAppState';

import {
  ADMIN_PAGE_PATH,
  CUSTODY_PAGE_PATH,
  DASHBOARD_PAGE_PATH,
  PORTFOLIO_PAGE_PATH,
  QUOTE_PAGE_PATH,
  REPORT_PAGE_PATH,
  REQUESTS_PAGE_PATH,
  ROOT_PATH,
  SETTINGS_PAGE_PATH,
  SETTLEMENT_PAGE_PATH,
  TRADE_PAGE_PATH,
  USER_MGMT_PAGE_PATH,
} from '../../constants/paths';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/image.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');
require('@omniex/onx-common-ui/lib/semantic/css/dropdown.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

export const FIXED_HEIGHT = 56;

const MILLIS_PER_HOUR = 60 * 60 * 1000;

const COMPONENT = 'PageLayoutHeaderMenu';
const loginUrl = config.login.baseUrl.replace(/\/login$/, '/maintenance');

// prettier-ignore
const StyledMenu = styled(Menu)`
  background-color: ${colors.navBarBackground} !important;
  border-radius: 0 !important;
  box-shadow: ${colors.navBarBoxShadow} !important;
  color: ${colors.navBarText} !important;
  height: ${layout.menuBarHeight}px;
  position: fixed !important;
  width: 100vw;
  z-index: 1001 !important;

  .${COMPONENT}-logo {
    ::before {
      background: ${colors.navBarDividerBG} !important;
      background-color: ${colors.navBarDividerBGC} !important;
    }
  }

  .${COMPONENT}-item {
    align-items: center;
    color: ${colors.navBarText} !important;
    flex: 1 1 140px !important;
    height: ${layout.menuBarHeight}px;
    justify-content: center;
    max-width: 140px;
    min-width: 80px;
    padding: 0px !important;

    :first-child {
      justify-content: normal;
      padding-left: 10px;
      padding-right: 10px;
    }

    ::before {
      background: ${colors.navBarDividerBG} !important;
      background-color: ${colors.navBarDividerBGC} !important;
    }
  }

  .${COMPONENT}-active {
    align-items: center;
    background-color: ${colors.navBarSelectedTabBackground} !important;
    border-bottom: ${layout.navBarActiveUnderline}px solid ${colors.navBarSelectedTabUnderline};
    color: ${colors.navBarText} !important;
    flex: 1 1 140px !important;
    height: ${layout.menuBarHeight}px;
    justify-content: center;
    max-width: 140px;
    min-width: 80px;
    padding: 0px !important;

    ::before {
      background: ${colors.navBarDividerBG} !important;
      background-color: ${colors.navBarDividerBGC} !important;
    }
  }

  .${COMPONENT}-product {
    color: ${colors.navBarSubtitle} !important;
    display: ${layout.productNameDisplay} !important;
    flex: 1 1 270px !important;
    font-size: 12px;
    justify-content: flex-start;
    max-width: 270px;
    min-width: 200px;
    padding: 0px 0px 0px 0px !important;
    /* padding-right: 100px !important; */

    ::before {
      background: ${colors.navBarDividerBG} !important;
      background-color: ${colors.navBarDividerBGC} !important;
    }

    div {
      padding: 0px 0px 0px 16%;
      width: 100%;
    }
  }

  .${COMPONENT}-right {
    flex: 0 0 auto !important;
  }

  .${COMPONENT}-user {
    color: ${colors.navBarText} !important;
    font-style: ${layout.navBarUserFontStyle} !important;
    font-weight: ${layout.navBarUserFontWeight} !important;

    ::before {
      background: ${colors.navBarDividerBG} !important;
      background-color: ${colors.navBarDividerBGC} !important;
    }
  }

  .${COMPONENT}-userIcon {
    margin-right: 4px;
  }

  .${COMPONENT}-org {
    color: ${colors.navBarOrgName};
    font-size: ${layout.navBarOrgFontSize}px;
    font-style: ${layout.navBarOrgFontStyle};
    font-weight: ${layout.navBarOrgFontWeight};
    margin-bottom: 2px;
  }

  .${COMPONENT}-portfolio {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .portfolioInput__value-container {
    flex-wrap: nowrap;
    padding: 2px 8px;
    width: 150px;
  }

  .portfolioInput__single-value {
    max-width: 100%;
    position: relative;
    transform: none;
  }

  .${COMPONENT}-dropdownIcon {
    color: ${colors.navBarText} !important;
    margin-right: 0 !important;

    ::before {
      background: ${colors.navBarDividerBG} !important;
      background-color: ${colors.navBarDividerBGC} !important;
    }
  }

  .${COMPONENT}-dropdown {
    margin-right: 15px;

    ::before {
      background: ${colors.navBarDividerBG} !important;
      background-color: ${colors.navBarDividerBGC} !important;
    }
  }

  .${COMPONENT}-dropdown-menu {
    margin-right: 1em !important;

    ::before {
      background: ${colors.navBarDividerBG} !important;
      background-color: ${colors.navBarDividerBGC} !important;
    }
  }

  .${COMPONENT}-notification-item {
    width: 90px
  }
`;



const processNotifications = (user, lastNotificationCheckTime, notifications = [], setNotificationFixClOrdID, history) => filterMap( //Note: sorting handled in db query
  notifications,
  n => displayNotification(user, n, 'notificationCenter'),
  (n, id) => {
    const { orderDetailText = '', status = '', subject = '' } = computeDisplayFields(n.order, n) || {};

    const noDescription = isEmpty(orderDetailText);

    const onClick = () => {
      if (n.order?.fixClOrdID) {
        history.replace({ pathname: TRADE_PAGE_PATH });
        setNotificationFixClOrdID(n.order?.fixClOrdID);
      }
    }
    return {
      description: noDescription ? `${getQtyOrdered(n.order)} ${getExecutionType(n.order)}` : orderDetailText,
      id,
      information: noDescription ? '' : getQtyOrdered(n.order),
      informationAlt: noDescription ? '' : getExecutionType(n.order),
      onClick,
      read: (lastNotificationCheckTime?.getTime() > 0) && (new Date(n.timeCreated) < lastNotificationCheckTime),
      timestamp: moment(n.timeCreated).fromNow(),
      title: subject,
      type: status,
    };
  }
);

const cn = elementName => `${COMPONENT}-${elementName}`;

const DropdownLink = ({ url, label }) => <Dropdown.Item as="a" href={url} rel="noopener noreferrer" target="_blank">{label}</Dropdown.Item>

const User = ({ user }) => (
  <>
    <PersonOutline className={cn('userIcon')} fontSize="large" />
    <div>
      <div className={cn('org')}>{get(user, 'org.name')}</div>
      <div>{get(user, 'name')}</div>
    </div>
  </>
);

const Tabs = ({ user, current, onClick }) => {
  const Tab = useCallback(({ label, path }) => (
    <Menu.Item className={cn(path === current ? 'active' : 'item')} name={label} href={path} onClick={onClick} />
  ), [current, onClick])

  return (
    <>
      {user.canViewDashboard      && <Tab label={labels.dashboard}  path={DASHBOARD_PAGE_PATH} />}
      {user.canViewPortfolio      && <Tab label={labels.portfolio}  path={PORTFOLIO_PAGE_PATH} />}
      {user.canViewTrade          && <Tab label={labels.trade}      path={TRADE_PAGE_PATH} />}
      {user.canViewSettlement     && <Tab label={labels.settlement} path={SETTLEMENT_PAGE_PATH} />}
      {user.canViewCustody        && <Tab label={labels.custody}    path={CUSTODY_PAGE_PATH} />}
      {user.canViewReport         && <Tab label={labels.report}     path={REPORT_PAGE_PATH} />}
      {user.canViewAdmin          && <Tab label={labels.admin}      path={ADMIN_PAGE_PATH} />}
      {user.canViewUserManagement && <Tab label={labels.userMgmt}   path={USER_MGMT_PAGE_PATH} />}
      {user.canViewQuote && user.org?.type === OrgType.CLIENT_SELL_SIDE && <Tab label={labels.quote} path={QUOTE_PAGE_PATH} />}
      {user.canViewRequests       && <Tab label={labels.requests}   path={REQUESTS_PAGE_PATH} />}
    </>
  )
}

const Portfolio = ({ portfolio, setPortfolio, user }) => {
  const portfolioOptions = orderBy((user?.org?.portfolios || []), 'id').map(p => ({ value: p, label: p?.name }))
  const portfolioValue = portfolioOptions.find(o => o?.value?.id === portfolio?.id)
  return portfolioOptions.length > 1 && (
    <>
      <Menu.Item>{labels.portfolio}</Menu.Item>
      <Menu.Item>
        <Select
          classNamePrefix="portfolioInput"
          name="portfolio"
          isSearchable={false}
          options={portfolioOptions}
          placeholder={labels.portfolio}
          value={portfolioValue}
          onChange={({ value }, _) => setPortfolio(value)}
        />
      </Menu.Item>
    </>
  )
}

const PageLayoutHeaderMenu = ({
  apiClient,
  fixApiClient,
  maintenanceDate,
  newNotifications,
  onChangePath,
  onLogout,
  portfolio,
  setNewNotifications,
  setNotificationFixClOrdID,
  setPortfolio,
  showMaintenanceWarning,
  user,
}) => {

  // History
  const history = useHistory();

  // Location
  const location = useLocation();

  // Refs
  const historyCutoff = useRef(new Date(Date.now() - 24*MILLIS_PER_HOUR));

  // Queries
  const qNotifications = useQuery(
    getOrderNotificationsSince,
    { 
      client: apiClient, 
      skip: !user.canViewTrade, 
      notifyOnNetworkStatusChange: true,
      variables: { time: historyCutoff.current, limit: 50, offset: 0 } 
    }
  );

  // Mutations
  const [updateUserAppState] = useMutation(updateUserAppStateMutation, { client: apiClient });

  // State
  const [showNotifications, setShowNotifications] = useState(false);
  const [showAllNotifications, setShowAllNotifications] = useState(false);
  const [lastNotificationCheckTime, setLastNotificationCheckTime] = useState(null);

  const updateLastNotificationCheckTime = useCallback(() => {
    setLastNotificationCheckTime(new Date());
    safeExecAsync(updateUserAppState, { variables: {
      lastNotificationCheckTime: new Date().toISOString()
    }});
  }, [updateUserAppState]);

  // On Click Handlers
  const onClick = useCallback((e, { href }) => (e.preventDefault(), href && onChangePath(href)), [onChangePath]);

  const closeNotificationCenter = useCallback((e) => {
    updateLastNotificationCheckTime();
    setShowNotifications(false);
  }, [updateLastNotificationCheckTime]);

  const onBlurNotificationCenter = useCallback((e) => {
    if (!e?.relatedTarget) closeNotificationCenter();
  }, [closeNotificationCenter]);

  const onClickNotificationIcon = useCallback(() => {
    if (showNotifications) { // On close
      closeNotificationCenter(); // Icon is disabled when center is open, so this should never run
    } else { // On open
      setShowNotifications(true);
    }
  }, [closeNotificationCenter, showNotifications]);

  const allNotificationsAction = useCallback(() => {
    closeNotificationCenter();
    setShowAllNotifications(true);
  }, [closeNotificationCenter])

  const onCloseAllNotifications = useCallback(() => setShowAllNotifications(false), [setShowAllNotifications])

  const addNotification = (n) => setNewNotifications(notifs => [n, ...notifs]);

  const onNotificationRowClick = useCallback((fixClOrdID) => {
    if (fixClOrdID) {
      history.replace({ pathname: TRADE_PAGE_PATH });
      setNotificationFixClOrdID(fixClOrdID);
    }
  }, [history, setNotificationFixClOrdID])

  if (user && !lastNotificationCheckTime && new Date(user.appState?.lastNotificationCheckTime).getTime() > 0) {
    setLastNotificationCheckTime(new Date(user.appState?.lastNotificationCheckTime))
  };

  const onLoadMoreNotifications = () => {
    if (qNotifications?.loading || !notificationsHasNextPage) return;

    return qNotifications?.fetchMore({
      variables: {
        offset: qNotifications?.data?.getOrderNotificationsSince?.notifications.length ?? 0
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const p = prev?.getOrderNotificationsSince?.notifications ?? [];
        const n = fetchMoreResult?.getOrderNotificationsSince?.notifications ?? [];
        return Object.assign({}, prev, {
          getOrderNotificationsSince: {
            ...prev.getOrderNotificationsSince,
            notifications: [...p, ...n]
          }
        });
      }
    });
  }

  // Memoized Data
  const allNotifications = useMemo(
    () => [...newNotifications, ...(qNotifications?.data?.getOrderNotificationsSince?.notifications || [])],
    [newNotifications, qNotifications]
  );
  const processedNotifications = useMemo(
    () => processNotifications(user, lastNotificationCheckTime, allNotifications, setNotificationFixClOrdID, history),
    [allNotifications, history, lastNotificationCheckTime, setNotificationFixClOrdID, user]
  );
  const newNotificationsCount = useMemo(
    () => processedNotifications.filter(n => !n.read).length,
    [processedNotifications]
  );

  // Derived Data
  const notificationsTotalCount = qNotifications?.data?.getOrderNotificationsSince?.count ?? 0;
  const notificationsCurrentCount = qNotifications?.data?.getOrderNotificationsSince?.notifications.length ?? 0;
  const notificationsHasNextPage = notificationsTotalCount !== notificationsCurrentCount;
  const notificationsCount = processedNotifications.length + newNotifications.length;

  return (
    <>
      <StyledMenu className={COMPONENT} as="header" color="blue" inverted>
        <Menu.Item className={cn('logo')} href={ROOT_PATH}>
          <Image height="30" src={config.urls.brandLogo} />
        </Menu.Item>
        {showMaintenanceWarning && <MaintenanceCountdownMenuItem expirationDate={maintenanceDate} loginUrl={loginUrl} />}
        <Menu.Item className={cn('product')} ><div>{labels.product}</div></Menu.Item>
        <Tabs user={user} current={location.pathname} onClick={onClick} />
        <Menu.Menu className={cn('right')} position="right">
          <Portfolio portfolio={portfolio} setPortfolio={setPortfolio} user={user} />
          <Menu.Item className={cn('user')} content={<User user={user} />} />
          <Menu.Item className={cn('notification-item')}>
            <NotificationsIcon
              newNotificationsCount={newNotificationsCount}
              notificationsCount={notificationsCount + newNotifications.length}
              onClick={onClickNotificationIcon}
              showNotifications={showNotifications}
              user={user}
            />
          </Menu.Item>
          <Dropdown className={cn('dropdown')} icon={<Icon className={cn('dropdownIcon')} name="setting" size="big" />} item pointing="top right">
            <Dropdown.Menu className={cn('dropdown-menu')}>
              <Dropdown.Item href={SETTINGS_PAGE_PATH} onClick={onClick}>{labels.settings}</Dropdown.Item>
              <Dropdown.Divider />
              {config.urls.support && <DropdownLink url={config.urls.support} label={labels.support} />}
              {config.urls.tos && <DropdownLink url={config.urls.tos} label={labels.termsOfUse} />}
              {(config.urls.support || config.urls.tos) && <Dropdown.Divider />}
              <Dropdown.Item onClick={onLogout}>{labels.logout}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </StyledMenu>
      <TrapFocus open={showNotifications}>
        <div tabIndex={-1} onBlur={onBlurNotificationCenter}>
          <NotificationCenter
            allNotificationsAction={allNotificationsAction}
            count={(notificationsHasNextPage ? notificationsCount + 1 : notificationsCount)}
            notifications={processedNotifications}
            showNotifications={showNotifications}
            onLoadMore={onLoadMoreNotifications}
            isRowLoaded={index => !notificationsHasNextPage || index < notificationsCount}
          />
        </div>
      </TrapFocus>
      {showAllNotifications && <AllNotificationsInspector onClose={onCloseAllNotifications} onRowClick={onNotificationRowClick} open={showAllNotifications}/>}
      <DesktopOrderNotification addNotification={addNotification} apiClient={fixApiClient} user={user} />
    </>
  )
}

export default PageLayoutHeaderMenu
