import React from 'react';
import { ApolloProvider } from 'react-apollo';

import { ERROR_PERMISSION_DENIED } from '@omniex/onx-common-ui/lib/errors';
import ErrorPage from '@omniex/onx-common-ui/lib/pages/ErrorPage';
import ReportPage from '../ui/pages/ReportPage';

const ReportPageContainer = ({ user, apiClient, portfolio = {} }) => user?.canViewReport ? (
  <ApolloProvider client={apiClient}>
    <ReportPage
      apiClient={apiClient}
      portfolio={portfolio}
    />
  </ApolloProvider>
) : <ErrorPage type={ERROR_PERMISSION_DENIED} />

export default ReportPageContainer
