const marketHistoryChartCopy = {
  chartTooltipLabelChange: 'Change',
  chartTooltipLabelClose: 'Close',
  chartTooltipLabelHigh: 'High',
  chartTooltipLabelLow: 'Low',
  chartTooltipLabelOpen: 'Open',
  chartTooltipLabelVolume: 'Volume',
  warningMessage: 'No historical market data to render at this time.',
  yAxisTitleTextPrice: 'Price',
  yAxisTitleTextVolume: 'Volume'
};

export default marketHistoryChartCopy;
