import { Accordion, Icon, Label, Loader, Segment } from 'semantic-ui-react';
import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import { createSelector } from 'reselect';
import React, { Fragment, PureComponent } from 'react';
import styled from 'react-emotion';

import { get, has } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { groupBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import { isError } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import AffirmAllocationsTable from '../components/AffirmAllocationsTable';
import AffirmSettlementsBlotterTable from '../components/AffirmSettlementsBlotterTable';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './SettlementPageMainSection.copyText';
import CsvDataType from '@omniex/onx-poms-entity-helpers/lib/enums/CsvDataType';
import CsvDownloadForm from '../components/CsvDownloadForm';
import getFilteredAccountsGroupedByCurrencyId from '../../selectors/getFilteredAccountsGroupedByCurrencyId';
import { getNetFills, getNetSettlements } from '../../utils/SettlementUtils';
import getSettlementsGroupedByCounterParty from '../../selectors/getSettlementsGroupedByCounterParty';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import Menu from '@omniex/onx-common-ui/lib/semantic/react/Menu';
import NetFlow from '../components/NetFlow';
import OrgType from '@omniex/onx-poms-entity-helpers/lib/enums/OrgType';
import populateAllocations from '../../selectors/populateAllocations';
import populateUnsettledFills from '../../selectors/populateUnsettledFills';
import SettlementStatus from '@omniex/onx-poms-entity-helpers/lib/enums/SettlementStatus';
import ConfirmSettlementsTable from '../components/ConfirmSettlementsTable';
import UnsettledTradeTable from '../components/UnsettledTradeTable';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/loader.css');
require('@omniex/onx-common-ui/lib/semantic/css/label.css');
require('@omniex/onx-common-ui/lib/semantic/css/segment.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');

const TAB_LABEL_AFFIRM = copyText.tabLabelAffirm;
const TAB_LABEL_CONFIRM = copyText.tabLabelConfirm;
const TAB_LABEL_INITIATE = copyText.tabLabelInitiate;

const DEFAULT_TAB_LABEL = TAB_LABEL_INITIATE;

const ERROR_MESSAGE_KEY_ACCEPT_SETTLEMENT = 'errorMessageAcceptSettlement';
const ERROR_MESSAGE_KEY_ADVANCE_ALLOCATION_ON_CONFIRM =
  'errorMessageAdvanceAllocationOnConfirm';
const ERROR_MESSAGE_KEY_INITIATE_SETTLEMENT = 'errorMessageInitiateSettlement';
const ERROR_MESSAGE_KEY_REJECT_SETTLEMENT = 'errorMessageRejectSettlement';
const ERROR_MESSAGE_KEY_RELEASE_FILLS = 'errorMessageReleaseFills';

const COMPONENT_NAME = 'SettlementPageMainSection';

const StyledSection = styled('section')`
  /* min-width: 0; TODO: determine suitable min-width */

  .${COMPONENT_NAME}-menuItem {
    justify-content: center;
    width: 200px;

    &.active {
      border-top-color: ${colors.subTabActiveLine};
    }
  }

  .${COMPONENT_NAME}-segment {
    min-height: 400px;
    padding: 0;
  }

  .${COMPONENT_NAME}-countLabel {
    background-color: #aaa;
    color: white;
    line-height: 0.6em !important;
    margin-left: 1em;
    min-height: 0 !important;
    min-width: 0 !important;
    padding: 0.2em 0.5em !important;
    vertical-align: middle;
  }

  .${COMPONENT_NAME}-accordionTitle {
    background-color: ${colors.transparentBlack};
    border-bottom: 1px solid ${colors.borderColor};
    padding-left: 0.5em !important;
    display: flex;
  }

  .${COMPONENT_NAME}-accordionContent {
    border-bottom: 1px solid ${colors.borderColor};
    padding: 1em !important;
  }

  .${COMPONENT_NAME}-titleLabel {
    min-width: 300px !important;
  }

  .${COMPONENT_NAME}-flows {
    margin-left: 7px;
    display: flex;
  }

  .${COMPONENT_NAME}-flow {
    min-width: 200px !important;
  }

  .${COMPONENT_NAME}-csvFormWrapper {
    display: flex !important;
    height: 22px !important;
    justify-content: flex-end !important;
  }

  .${COMPONENT_NAME}-tabCount.changed {
    background: ${colors.red} !important;
  }

  .${COMPONENT_NAME}-errorMessage + .${COMPONENT_NAME}-segment {
    border-top-color: ${colors.errorBorderColor};
  }

  .${COMPONENT_NAME}-successMessage + .${COMPONENT_NAME}-segment {
    border-top-color: ${colors.successBorderColor};
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class SettlementPageMainSection extends PureComponent {
  static propTypes = {
    acceptSettlement: func,
    acceptSettlementMutationError: object,
    acceptSettlementMutationProcessing: bool,
    acceptSettlementMutationResult: object,
    advanceAllocationOnConfirm: func,
    advanceAllocationOnConfirmMutationError: object,
    advanceAllocationOnConfirmMutationProcessing: bool,
    advanceAllocationOnConfirmMutationResult: object,
    csvDataQueryData: object,
    csvDataQueryError: object,
    csvDataQueryLoading: bool,
    currenciesQueryData: object,
    currenciesQueryError: object,
    currenciesQueryLoading: bool,
    initiateSettlement: func,
    initiateSettlementMutationError: object,
    initiateSettlementMutationProcessing: bool,
    initiateSettlementMutationResult: object,
    instrumentsQueryData: object,
    instrumentsQueryError: object,
    instrumentsQueryLoading: bool,
    orgQueryData: object,
    orgQueryError: object,
    orgQueryLoading: bool,
    portfolioQueryData: object,
    portfolioQueryError: object,
    portfolioQueryLoading: bool,
    rejectSettlement: func,
    rejectSettlementMutationError: object,
    rejectSettlementMutationProcessing: bool,
    rejectSettlementMutationResult: object,
    releaseFills: func,
    releaseFillsMutationError: object,
    releaseFillsMutationProcessing: bool,
    releaseFillsMutationResult: object,
    settlementsQueryData: shape({
      settlements: arrayOf(shape({ status: string.isRequired }))
    }),
    settlementsQueryError: object,
    settlementsQueryLoading: bool,
    settlementsSubscriptionData: object,
    settlementsSubscriptionError: object,
    settlementsSubscriptionLoading: bool,
    unsettledOtcFillsQueryData: object,
    unsettledOtcFillsQueryError: object,
    unsettledOtcFillsQueryLoading: bool,
    unsettledOtcFillsSubscriptionData: object,
    unsettledOtcFillsSubscriptionError: object,
    unsettledOtcFillsSubscriptionLoading: bool,
    user: object.isRequired
  };

  static defaultProps = {
    acceptSettlement: noop,
    advanceAllocationOnConfirm: noop,
    initiateSettlement: noop,
    rejectSettlement: noop,
    releaseFills: noop
  };

  state = {
    affirmIDs: [],
    initiateIDs: [],
    visibleTab: DEFAULT_TAB_LABEL,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const stateChanges = {};

    for (const key of [
      'acceptSettlementMutationProcessing',
      'advanceAllocationOnConfirmMutationProcessing',
      'initiateSettlementMutationProcessing',
      'rejectSettlementMutationProcessing',
      'releaseFillsMutationProcessing',
      'settlementsQueryData',
      'settlementsSubscriptionData',
      'unsettledOtcFillsQueryData',
      'unsettledOtcFillsSubscriptionData'
    ]) {
      if (has(nextProps, key) && get(prevState, key) !== get(nextProps, key)) {
        stateChanges[key] = nextProps[key];
      }
    }

    let prevSettlements;
    let currentSettlements;

    if (
      stateChanges.initiateSettlementMutationProcessing === false &&
      isError(nextProps.initiateSettlementMutationError)
    ) {
      stateChanges.errorMessageKey = ERROR_MESSAGE_KEY_INITIATE_SETTLEMENT;
    }

    if (
      stateChanges.acceptSettlementMutationProcessing === false &&
      isError(nextProps.acceptSettlementMutationError)
    ) {
      stateChanges.errorMessageKey = ERROR_MESSAGE_KEY_ACCEPT_SETTLEMENT;
    }

    if (
      stateChanges.rejectSettlementMutationProcessing === false &&
      isError(nextProps.rejectSettlementMutationError)
    ) {
      stateChanges.errorMessageKey = ERROR_MESSAGE_KEY_REJECT_SETTLEMENT;
    }

    if (
      stateChanges.releaseFillsMutationProcessing === false &&
      isError(nextProps.releaseFillsMutationError)
    ) {
      stateChanges.errorMessageKey = ERROR_MESSAGE_KEY_RELEASE_FILLS;
    }

    if (
      stateChanges.advanceAllocationOnConfirmMutationProcessing === false &&
      isError(nextProps.advanceAllocationOnConfirmMutationError)
    ) {
      stateChanges.errorMessageKey = ERROR_MESSAGE_KEY_ADVANCE_ALLOCATION_ON_CONFIRM;
    }

    if (
      stateChanges.unsettledOtcFillsSubscriptionData &&
      prevState.visibleTab !== TAB_LABEL_INITIATE
    ) {
      stateChanges.initiateTabCountChange =
        get(
          prevState,
          'unsettledOtcFillsSubscriptionData.unsettledOtcFills.length',
          0
        ) <
        get(
          stateChanges,
          'unsettledOtcFillsSubscriptionData.unsettledOtcFills.length',
          0
        );
    }

    if (prevState.visibleTab !== TAB_LABEL_AFFIRM) {
      const affirmStatuses = [
        SettlementStatus.PENDING_REJECT,
        SettlementStatus.RECEIVED,
        SettlementStatus.SENT
      ];
      prevSettlements = get(
        prevState,
        'settlementsSubscriptionData.settlements',
        []
      ).filter(settlement => affirmStatuses.includes(settlement.status));

      currentSettlements = get(
        stateChanges,
        'settlementsSubscriptionData.settlements',
        []
      ).filter(settlement => affirmStatuses.includes(settlement.status));

      if (
        get(prevSettlements, 'length', 0) < get(currentSettlements, 'length', 0)
      ) {
        stateChanges.affirmTabCountChange = true;
      }

      if (
        get(prevSettlements, 'length', 0) > get(currentSettlements, 'length', 0)
      ) {
        stateChanges.affirmTabCountChange = false;
      }
    }

    if (prevState.visible !== TAB_LABEL_CONFIRM) {
      const settlementTabStatus = [
        SettlementStatus.ACCEPTED,
        SettlementStatus.COMPLETED
      ];

      prevSettlements =
        get(prevState, 'settlementsSubscriptionData.settlements') ||
        get(prevState, 'settlementsQueryData.settlements', []);

      prevSettlements = prevSettlements.filter(settlement =>
        settlementTabStatus.includes(settlement.status)
      );

      currentSettlements = get(
        stateChanges,
        'settlementsSubscriptionData.settlements',
        []
      ).filter(settlement => settlementTabStatus.includes(settlement.status));

      if (
        get(prevSettlements, 'length', 0) < get(currentSettlements, 'length', 0)
      ) {
        stateChanges.confirmTabCountChange = true;
      }
    }

    return stateChanges;
  }

  render() {
    const loading =
      this.props.unsettledOtcFillsQueryLoading ||
      !has(this.props, 'unsettledOtcFillsQueryData.unsettledOtcFills') ||
      this.props.settlementsQueryLoading ||
      !has(this.props, 'settlementsQueryData.settlements') ||
      this.props.portfolioQueryLoading ||
      !has(this.props, 'portfolioQueryData.portfolio') ||
      this.props.currenciesQueryLoading ||
      !has(this.props, 'currenciesQueryData.currencies') ||
      this.props.instrumentsQueryLoading ||
      !has(this.props, 'instrumentsQueryData.instruments') ||
      this.props.orgQueryLoading ||
      !has(this.props, 'orgQueryData.org');

    const unsettledOtcFills =
      get(this.props, 'unsettledOtcFillsSubscriptionData.unsettledOtcFills') ||
      get(this.props, 'unsettledOtcFillsQueryData.unsettledOtcFills', []);

    let settlements =
      get(this.props, 'settlementsSubscriptionData.settlements') ||
      get(this.props, 'settlementsQueryData.settlements', []);

    settlements = populateAllocations(
      settlements,
      get(this.props, 'currenciesQueryData.currencies', []),
      get(this.props, 'portfolioQueryData.portfolio.accounts', [])
    );

    const affirmTabSettlements = settlements.filter(settlement =>
      [
        SettlementStatus.PENDING_REJECT,
        SettlementStatus.RECEIVED,
        SettlementStatus.SENT
      ].includes(settlement.status)
    );

    const confirmTabSettlements = settlements.filter(settlement =>
      [SettlementStatus.ACCEPTED, SettlementStatus.COMPLETED].includes(
        settlement.status
      )
    );

    const fills = populateUnsettledFills(
      unsettledOtcFills,
      get(this.props.currenciesQueryData, 'currencies'),
      get(this.props.instrumentsQueryData, 'instruments')
    );

    let settlementsCounterPartyMap;
    let unsettledOtcFillsCounterPartyMap;

    switch (this.state.visibleTab) {
      case TAB_LABEL_AFFIRM:
        settlementsCounterPartyMap = getSettlementsGroupedByCounterParty(
          affirmTabSettlements,
          get(this.props, 'orgQueryData.org.id')
        );
        break;
      case TAB_LABEL_INITIATE:
        const isBuySide = get(this.props, 'orgQueryData.org.type') === OrgType.CLIENT_BUY_SIDE;
        unsettledOtcFillsCounterPartyMap = isBuySide ? groupBy(fills, 'venue.org.id') : groupBy(fills, 'org.id');
        break;
      default:
        break;
    }

    const populatedCsvData = {};

    populatedCsvData.settlements = populateAllocations(
      get(this.props, 'csvDataQueryData.data.settlements', []),
      get(this.props, 'currenciesQueryData.currencies', []),
      get(this.props, 'portfolioQueryData.portfolio.accounts', [])
    );

    return (
      <StyledSection className={COMPONENT_NAME}>
        <Menu attached="top">
          <Menu.Item
            content={copyText.sectionTitle}
            header
            icon="handshake outline"
          />
          <Menu.Tab
            className={cn('menuItem')}
            active={this.state.visibleTab === TAB_LABEL_INITIATE}
            onClick={this._handleChangeVisibleTab.bind(
              this,
              TAB_LABEL_INITIATE
            )}>
            {copyText.tabLabelInitiate}
            <Label
              className={cn(
                `tabCount${this.state.initiateTabCountChange ? ' changed' : ''}`
              )}>
              {fills.length}
            </Label>
          </Menu.Tab>
          <Menu.Tab
            className={cn('menuItem')}
            active={this.state.visibleTab === TAB_LABEL_AFFIRM}
            onClick={this._handleChangeVisibleTab.bind(this, TAB_LABEL_AFFIRM)}>
            {copyText.tabLabelAffirm}
            <Label
              className={cn(
                `tabCount${this.state.affirmTabCountChange ? ' changed' : ''}`
              )}>
              {affirmTabSettlements.length}
            </Label>
          </Menu.Tab>
          <Menu.Tab
            className={cn('menuItem')}
            active={this.state.visibleTab === TAB_LABEL_CONFIRM}
            onClick={this._handleChangeVisibleTab.bind(
              this,
              TAB_LABEL_CONFIRM
            )}>
            {copyText.tabLabelConfirm}
            <Label
              className={cn(
                `tabCount${this.state.confirmTabCountChange ? ' changed' : ''}`
              )}>
              {confirmTabSettlements.length}
            </Label>
          </Menu.Tab>
          {this.state.visibleTab === TAB_LABEL_CONFIRM ? (
            <Menu.Menu position="right">
              <Menu.Item
                active={this.state.showCsvDownloadForm}
                content={copyText.csvDownloadTabLabel}
                icon="download"
                onClick={this._handleClickOpenCsvForm}
              />
            </Menu.Menu>
          ) : null}
        </Menu>
        {this._renderMessage()}
        {this.state.showCsvDownloadForm && this.props.user.canDownloadSettlementCSV && (
          <Segment attached>
            <div className={cn('csvFormWrapper')}>
              <CsvDownloadForm
                data={populatedCsvData.settlements}
                dataType={CsvDataType.SETTLEMENT}
                org={get(this.props, 'orgQueryData.org')}
                processing={this.props.csvDataQueryLoading}
                onDownload={this._handleCsvDownload}
              />
            </div>
          </Segment>
        )}
        <Segment className={cn('segment')} attached="bottom">
          {loading ? (
            <Loader
              className={cn('loader')}
              active
              content={copyText.loadingMessage}
              size="medium"
            />
          ) : (
            this._renderTabContent(
              this.state.visibleTab === TAB_LABEL_INITIATE
                ? unsettledOtcFillsCounterPartyMap
                : settlementsCounterPartyMap,
              confirmTabSettlements
            )
          )}
        </Segment>
      </StyledSection>
    );
  }

  _renderMessage() {
    if (this.state.errorMessageKey) {
      return (
        <Message
          className={cn('errorMessage')}
          attached
          closeOnClick
          content={copyText[this.state.errorMessageKey]}
          error
          icon="warning sign"
        />
      );
    }
  }

  _renderTabContent(counterPartyMap, settlements) {
    switch (this.state.visibleTab) {
      case TAB_LABEL_AFFIRM:
        return this._renderAccordionsByCounterParty(counterPartyMap, props => (
          <AffirmSettlementsBlotterTable
            renderExpandedContent={this._renderAffirmAllocationsTable.bind(this)}
            senderCompID={get(this.props, 'orgQueryData.org.fixSenderCompID')}
            settlements={props.tableData}
          />
        ));
      case TAB_LABEL_CONFIRM:
        return (
          <ConfirmSettlementsTable
            currencies={get(this.props, 'currenciesQueryData.currencies')}
            instruments={get(this.props, 'instrumentsQueryData.instruments')}
            org={get(this.props, 'orgQueryData.org')}
            fixSenderCompID={get(this.props, 'orgQueryData.org.fixSenderCompID')}
            settlements={settlements}
            user={this.props.user}
            onAdvance={this._handleAdvanceAllocation.bind(this)}
          />
        );
      case TAB_LABEL_INITIATE:
        return this._renderAccordionsByCounterParty(counterPartyMap, props => (
          <UnsettledTradeTable
            accounts={get(this.props, 'portfolioQueryData.portfolio.accounts')}
            currencies={get(this.props, 'currenciesQueryData.currencies')}
            fills={props.tableData}
            orgType={get(this.props, 'orgQueryData.org.type')}
            user={this.props.user}
            onInitiate={this._handleInitiateSettlement.bind(this)}
          />
        ));
      default:
        return null;
    }
  }

  _renderAccordionsByCounterParty(counterPartyMap, RenderTable) {
    const { affirmIDs, initiateIDs, visibleTab } = this.state;
    const currencies = get(this.props, 'currenciesQueryData.currencies');

    return (
      <Accordion fluid className={cn('counterPartyWrapper')}>
        {Object.keys(counterPartyMap).map(counterPartyId => {
          const tableData = counterPartyMap[counterPartyId];
          const active = visibleTab === TAB_LABEL_INITIATE ? initiateIDs.includes(counterPartyId) : affirmIDs.includes(counterPartyId);

          const isUserBuySide = get(this.props, 'orgQueryData.org.type') === OrgType.CLIENT_BUY_SIDE;
          const senderCompID = get(this.props, 'orgQueryData.org.fixSenderCompID') || '';
          const net = visibleTab === TAB_LABEL_INITIATE ? getNetFills(tableData, isUserBuySide, currencies) : getNetSettlements(tableData, senderCompID, currencies);

          const actionNeeded = visibleTab === TAB_LABEL_AFFIRM && tableData.some(s => s.fixInitiatorCompID !== senderCompID);

          return (
            <Fragment key={counterPartyId}>
              <Accordion.Title
                className={cn('accordionTitle')}
                active={active}
                onClick={this._handleSelectCounterParty.bind(
                  this,
                  counterPartyId
                )}>
                <Icon name="dropdown" />
                <div className={cn('titleLabel')}>
                  <span>
                    {this.state.visibleTab === TAB_LABEL_INITIATE
                      ? isUserBuySide
                        ? get(tableData[0], 'venue.org.name')
                        : get(tableData[0], 'org.name')
                      : get(this.props, 'orgQueryData.org.id') ===
                        get(tableData[0], 'initiatorOrg.id')
                      ? get(tableData[0], 'respondentOrg.name')
                      : get(tableData[0], 'initiatorOrg.name')}
                  </span>
                  <Label size="small" circular className={cn('countLabel')} {...(actionNeeded ? {color: "red"} : {})}>
                    {tableData.length}
                  </Label>
                </div>
                <NetFlow net={net} />
              </Accordion.Title>
              <Accordion.Content
                className={cn('accordionContent')}
                active={active}>
                <RenderTable tableData={tableData} />
              </Accordion.Content>
            </Fragment>
          );
        })}
      </Accordion>
    );
  }

  _renderAffirmAllocationsTable(settlement) {
    if (!this.props.user.canAffirmSettlement) {
      return (
        <Message
          className={cn('warningMessage')}
          content={copyText.permissionDenied}
          warning
        />
      )
    }

    const accountsGroupedByCurrencyId = getFilteredAccountsGroupedByCurrencyId(
      get(this.props, 'portfolioQueryData.portfolio.accounts')
    );

    const [fromAccounts, toAccounts] = _collectAccountsFromAllocations(
      settlement.allocations,
      accountsGroupedByCurrencyId
    );

    settlement.fills = populateUnsettledFills(
      settlement.fills,
      get(this.props, 'currenciesQueryData.currencies'),
      get(this.props, 'instrumentsQueryData.instruments')
    );

    return (
      <AffirmAllocationsTable
        fromAccounts={fromAccounts}
        org={get(this.props, 'orgQueryData.org')}
        settlement={settlement}
        toAccounts={toAccounts}
        onAccept={this._handleAcceptSettlement}
        onReject={this._handleRejectSettlement}
        onRelease={this._handleReleaseFills}
      />
    );
  }

  _handleAdvanceAllocation = attributes => {
    this.setState({ errorMessageKey: undefined }, () =>
      this.props.advanceAllocationOnConfirm(attributes)
    );
  };

  _handleAcceptSettlement = inputs => {
    this.setState({ errorMessageKey: undefined }, () =>
      this.props.acceptSettlement(inputs)
    );
  };

  _handleCsvDownload = inputs => {
    this.props.fetchCsvData(inputs);
  };

  _handleInitiateSettlement = inputs => {
    this.setState({ errorMessageKey: undefined }, () =>
      this.props.initiateSettlement(inputs)
    );
  };

  _handleRejectSettlement = inputs => {
    this.setState({ errorMessageKey: undefined }, () =>
      this.props.rejectSettlement(inputs)
    );
  };

  _handleReleaseFills = inputs => {
    this.setState({ errorMessageKey: undefined }, () =>
      this.props.releaseFills(inputs)
    );
  };

  _handleChangeVisibleTab = tabName => {
    this.setState(currentState => ({
      affirmTabCountChange:
        tabName === TAB_LABEL_AFFIRM
          ? false
          : currentState.affirmTabCountChange,
      errorMessageKey: undefined,
      confirmTabCountChange:
        tabName === TAB_LABEL_CONFIRM
          ? false
          : currentState.confirmTabCountChange,
      initiateTabCountChange:
        tabName === TAB_LABEL_INITIATE
          ? false
          : currentState.initiateTabCountChange,
      showCsvDownloadForm: false,
      visibleTab: tabName
    }));
  };

  _handleClickOpenCsvForm = () => {
    this.setState(currentState => ({
      showCsvDownloadForm: !currentState.showCsvDownloadForm
    }));
  };

  _handleSelectCounterParty = counterPartyId => {
    const {affirmIDs, initiateIDs, visibleTab} = this.state;
    const addOrRemove = (ids, id) => ids.includes(id) ? ids.filter(curr => curr !== id) : [...ids, id];

    if (visibleTab === TAB_LABEL_INITIATE) {
      this.setState({initiateIDs: addOrRemove(initiateIDs, counterPartyId)});
    } else if (visibleTab === TAB_LABEL_AFFIRM) {
      this.setState({affirmIDs: addOrRemove(affirmIDs, counterPartyId)});
    }
  };
}

const _collectAccountsFromAllocations = createSelector(
  (allocations, groupedAccounts) => allocations,
  (allocations, groupedAccounts) => groupedAccounts,
  (allocations, groupedAccounts) => {
    return allocations.reduce(
      ([fromAccounts, toAccounts], allocation) => {
        fromAccounts = get(allocation, 'fromAccount.id')
          ? fromAccounts
          : groupedAccounts[allocation.currency.id]
          ? [...fromAccounts, ...groupedAccounts[allocation.currency.id]]
          : [];

        toAccounts = get(allocation, 'toAccount.id')
          ? toAccounts
          : groupedAccounts[allocation.currency.id]
          ? [...toAccounts, ...groupedAccounts[allocation.currency.id]]
          : [];

        return [fromAccounts, toAccounts];
      },
      [[], []]
    );
  }
);
