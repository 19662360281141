import gql from 'graphql-tag';

const getPaginatedOrderNotifications = gql`
  query getPaginatedOrderNotifications(
    $filters: NotificationFilters
    $limit: Int!
    $offset: Int!
    $sortDirection: SortDirection!
    $sortParameter: NotificationSortField!
  ) {
    notificationData: getPaginatedOrderNotifications(
      filters: $filters
      limit: $limit
      offset: $offset
      sortDirection: $sortDirection
      sortParameter: $sortParameter
    ) {
      notifications {
        id
        reason
        text
        timeCreated
        venue {
          id
          name
        }
        order {
          id
          algorithmStrategy
          feature
          fixClOrdID
          fixOrderID
          fixQuoteID
          fixVenueOrderID
          instrument {
            id
            identifier
            displayName
          }
          side
          specifiedQuantity
          source
          venue {
            id
            name
          }
        }
      }
      count
    }
  }
`;

export default getPaginatedOrderNotifications;
