import { bool, shape } from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './NotificationSettingsComponent.copyText';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import { filterMap } from '@omniex/poms-core/lib/utils/FunctionUtils';

const COMPONENT_NAME = 'NotificationSettingsComponent';

const StyledDiv = styled('div')`
  min-width: 150px;

 .${COMPONENT_NAME}-section {
    display: flex;
  }

  .${COMPONENT_NAME}-sectionTitle {
    color: ${colors.grey};
    display: inline-block;
    font-size: 12px;
    min-width: 150px;
    text-align: right;
  }

  .${COMPONENT_NAME}-deliveryMethodContainer {
    margin-left: 10px;
  }

  .${COMPONENT_NAME}-deliveryMethod {
    margin-bottom: 10px;
  }

  .${COMPONENT_NAME}-deliveryMethodConditions {
    color: ${colors.grey};
    font-size: 12px;
    margin-left: 25px;
  }

  .${COMPONENT_NAME}-deliveryMethodTitle {
    margin: 0;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class NotificationSettingsComponent extends PureComponent {
  static propTypes = {
    userSettings: shape({
      notificationDeliveryMethods_email: shape({
        notificationConditions_orderCancelled: bool,
        notificationConditions_orderFilled: bool,
        notificationConditions_orderPlaced: bool,
        notificationConditions_venueExcludedFromAlgo: bool,
        notificationConditions_venueRemovedFromAlgo: bool,
        notificationConditions_algoPaused: bool
      }),
      notificationDeliveryMethods_desktop: shape({
        notificationConditions_orderCancelled: bool,
        notificationConditions_orderFilled: bool,
        notificationConditions_orderPlaced: bool,
        notificationConditions_venueExcludedFromAlgo: bool,
        notificationConditions_venueRemovedFromAlgo: bool,
        notificationConditions_algoPaused: bool
      }),
    })
  };

  static defaultProps = {};

  render() {
    return (
      <StyledDiv className={COMPONENT_NAME}>
        {this._renderNotificationDeliveryMethods(
          this.props.userSettings
        )}
      </StyledDiv>
    );
  }

  _renderNotificationDeliveryMethods(props) {
    const notificationDeliveryMethods_email = getActiveNotifications(props?.notificationDeliveryMethods_email ?? {});
    const notificationDeliveryMethods_desktop = getActiveNotifications((window.Notification.permission === 'granted' && props?.notificationDeliveryMethods_desktop) ?? {});

    const deliveryMethods = {
      notificationDeliveryMethods_email,
      notificationDeliveryMethods_desktop,
    };

    const hasDeliveryMethods = Object.values(deliveryMethods).some(deliveryMethod => deliveryMethod.length > 0);

    return hasDeliveryMethods ? (
      <div className={cn('section')}>
        <p className={cn('sectionTitle')}>{copyText.sectionTitle_notificationDeliveryMethods}:</p>
        <div className={cn('deliveryMethodContainer')}>
          {filterMap(Object.keys(deliveryMethods), key => deliveryMethods[key].length > 0, key => (
            <div className={cn('deliveryMethod')} key={key}>
              <p className={cn('deliveryMethodTitle')}>{copyText[key]}</p>
              <NotificationConditions conditions={deliveryMethods[key]} />
            </div>
          ))}
        </div>
      </div>

    ) : (
      <Message
        className={cn('warningMessage')}
        attached
        content={copyText.warningMessageNoSettings}
        warning
      />
    );
  }
}

const NotificationConditions = ({ conditions }) => (
  <div className={cn('deliveryMethodConditions')}>
    {conditions.map((key, index) => (
      <div key={`condition-${index}`}>{copyText[key]}</div>
    ))}
  </div>
);

const getActiveNotifications = (settings) =>
  Object.entries(settings)
    .filter(([key, value]) => value === true)
    .map(([key]) => key);
