import * as React from 'react';
import Decimal from 'decimal.js'
import { useEffect, useMemo, useState } from 'react';
import { makeStyles, Button, fonts, Menu, Input } from '@omniex/poms-ui';
import { ClassNameMap, BaseProps } from '@omniex/poms-ui/lib/types';
import { CloseIcon, PriceDownIcon, PriceUpIcon } from '@omniex/poms-ui/lib/components/Icon';
import clsx from 'clsx';
import { OTCTileData, OTCOrderBook, OTCFormOptions, OTCOrder, HydratedOTCTileData, OTCOrderType, OTCSortDirection } from '../../../types';
import { SimpleInputDialog } from './SimpleInputDialog';
import { OTC_ORDER_TYPES, Color, colorStyles, backgroundColorStyles, getStatusColorKey, statusToText } from './shared';
import { PopulatedInstrument, Order } from '../../../types';
import OrderStatus from '@omniex/poms-core/lib/enums/OrderStatus';
import { formatPrice } from '../../../utils/DisplayUtils';
import { isOrderActive, isOrderHappy, isOrderUnhappy } from '../Blotter/shared';
import { IconWithTooltip } from './IconWithTooltip';
import OTCCopyText from './OTC.copy';
import QuoteRequestSide from '@omniex/poms-core/lib/enums/QuoteRequestSide';

const { BUY, SELL } = QuoteRequestSide;

const MIN_ROWS = 3;

type FlexProps = { dir?: string, wrap?: string, ai?: string, jc?: string }
const flex = ({dir = 'row', wrap = 'nowrap', ai = 'center', jc = 'center'}: FlexProps = {}) => ({
  display: 'flex',
  flexFlow: `${dir} ${wrap}`,
  alignItems: ai,
  justifyContent: jc,
})

const useTileHeadStyles = makeStyles(({ palette }) => ({
  root: {
    ...flex({jc: 'space-between'}),
    height: 35,
    padding: '9px 12px 6px 12px',
    width: 300,
    backgroundColor: palette.bkg3.main,
    borderRadius: '8px 8px 0px 0px',
  },
  qtyGroup: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 20,
  },
  deleteButton: {
    ...fonts.displayFonts.mediumStrong,
    cursor: 'pointer',
    color: palette.level4.main,
    '&:hover': {
      color: palette.level1.main,
    },
  },
  label: {
    color: `${palette.level1.main} !important`, // spec off
    ...fonts.displayFonts.mediumStrong,
    fontWeight: '700 !important' as any,
    height: '20px',
    '& > svg': {
      color: palette.level4.main,
    },
    '&:hover > svg': {
      color: palette.level1.main,
    },
  },
  italic: {
    fontStyle: 'italic',
  },
  spacedInput: {
    marginRight: 8,
  },
  menuButton: {
    height: '20px',
    padding: 0,
    minWidth: 100,
    width: 'auto',
    '&:hover': {
      color: palette.level1.main,
    },
  },
  menuIcon: {
    top: 0,
    color: palette.level4.main,
    '&:hover': {
      color: palette.level1.main,
    },
  },
}), { name: 'otc-tile-head' });

type IndicatorProps = Partial<Pick<Order, 'side' | 'status'> & { error: boolean }>

type UseIndicatorStyles = (props?: IndicatorProps) => ClassNameMap<'root' | Color>
const useIndicatorStyles = makeStyles(({ palette }) => ({
  root: {
    width: '100%',
    height: 5,
  },
  ...backgroundColorStyles(palette) as object,
}), { name: 'otc-indicator' }) as UseIndicatorStyles

const Indicator = (props: IndicatorProps) => {
  const cls = useIndicatorStyles()
  const color = getStatusColorKey(props) || 'level4'
  return <div className={clsx(cls.root, cls[color as Color])} />
}

type StatusProps = {
  onInspect: (fixClOrdID?: string) => void;
  order: Pick<Order, 'reasonCanceled' | 'reasonRejected' | 'requestCancelTrader' | 'side' | 'status' | 'fixClOrdID'>;
  error?: boolean;
}
type UseStatusStyles = () => ClassNameMap<'title' | 'copy' | 'inspector' | Color>

const useStatusStyles = makeStyles<{}>(({ palette }) => ({
  title: {
    ...flex({jc: 'flex-start'}),
    ...fonts.displayFonts.capsMedium,
    fontWeight: 700,
    height: 34,
    paddingLeft: 12,
  },
  copy: {
    ...fonts.displayFonts.xSmall,
    paddingLeft: 12,
  },
  inspector: {
    cursor: 'pointer',
  },
  ...colorStyles(palette) as object,
}), { name: 'otc-order-status' }) as UseStatusStyles;

const Status = ({ onInspect, order, error }: StatusProps) => {
  const cls = useStatusStyles()
  const {
    reasonCanceled,
    reasonRejected,
    requestCancelTrader,
    side,
    status,
  } = order;
  const color = getStatusColorKey({ side, status, error }) || 'level1';
  const text = status ? statusToText[status] : error ? 'ERROR' : '';
  const orderInspectorLink = order.fixClOrdID && (
    <>
      {status && isOrderActive({ status }) ? OTCCopyText.inspectorActive : OTCCopyText.inspector}
      <span className={clsx(cls.inspector, cls.sell)} onClick={() => onInspect(order.fixClOrdID)}>Order Details</span>.
    </>
  )
  const description = (() => {
    if (status && isOrderActive({ status })) return OTCCopyText.active;
    if (error) return OTCCopyText.error;
    switch (status) {
      case OrderStatus.FILLED: return OTCCopyText.filled;
      case OrderStatus.CANCELED: return requestCancelTrader ? `Order canceled by: ${requestCancelTrader.username}` : `Cancellation Reason: ${reasonCanceled}`;
      case OrderStatus.REJECTED: return reasonRejected;
    }
  })();

  return (
    <>
      <div className={clsx(cls.title, cls[color])}>{text}</div>
      {description && <div className={clsx(cls.copy, cls.level1)}>{description}</div>}
      {orderInspectorLink && <div className={clsx(cls.copy, cls.level1)}>{orderInspectorLink}</div>}
    </>
  )
}

type DividerProps = {spacer?: boolean}
const useDividerStyles = makeStyles(({ palette }) => ({
  divider: {
    borderTop: `1px solid ${palette.borderDivider.main}`,
    width: '100%',
  },
  transparent: {
    borderTop: `1px solid transparent`,
    width: '100%',
  },
}), { name: 'otc-divider' })
const Divider = ({spacer}: DividerProps) => {
  const { divider, transparent } = useDividerStyles()
  return <div className={spacer ? transparent : divider} />
}



type TRProps = {cols: Array<string | number>, head?: boolean, index?: number, displaySide?: QuoteRequestSide | null}
type CellProps = {className?: string, children: React.ReactNode}
const useTRStyles = makeStyles(({ palette }) => ({
  cell: {
    padding: 0,
    ...fonts.displayFonts.xxSmall,
    textTransform: 'capitalize',
    // ...fonts.displayFonts[head ? 'colHeadXS' : 'xxSmall'],
    // ...(head && {
    //   fontWeight: 700,
    //   position: 'sticky',
    //   top: 0,
    //   borderBottom: `1px solid ${palette.borderDivider.main}`,
    // }),
    '&:first-child': { paddingLeft: 12 },
    '&:last-child': { paddingRight: 12 },
  },
  headCell: {
    ...fonts.displayFonts.colHeadXS,
    fontWeight: 700,
    position: 'sticky',
    top: 0,
    borderBottom: `1px solid ${palette.borderDivider.main}`,
  },
  row: { height: 24 },
  headRow: { height: 26 },
  left: { textAlign: 'left' },
  right: { textAlign: 'right' },
  mid: { borderRight: `1px solid ${palette.borderDivider.main}` },
  innerPadRight: { paddingRight: 8 },
  innerPadLeft: { paddingLeft: 8 },
  normal: {
    backgroundColor: palette.canvas.main,
  },
  selected: {
    backgroundColor: palette.bkg3.main,
  },
}), { name: 'otc-order-book-rows' })
const TH = ({className, children}: CellProps) => <th className={className}>{children}</th>
const TD = ({className, children}: CellProps) => <td className={className}>{children}</td>
const TR = ({cols, head = false, index, displaySide}: TRProps): JSX.Element => {
  const { row, headRow, cell, headCell, left, right, mid, innerPadRight, innerPadLeft, normal, selected } = useTRStyles()
  const Cell = head ? TH : TD
  const iMid = (cols.length/2)|0
  return (
    <tr className={clsx(row, head && headRow)}>
      {cols.map((c, i) => {
        const justify = i % 2 ? right : left // (i < iMid ? left : right)
        const border = i === iMid - 1 && !head && mid
        const padRight = i === iMid - 1 && innerPadRight
        const padLeft = i === iMid && innerPadLeft
        const background = !head && index === 0 && ((displaySide === BUY && i >= 2) || (displaySide === SELL && i < 2)) ? selected : normal;
        const className = clsx(cell, head && headCell, justify, border, padRight, padLeft, background)
        c = Number.isNaN(parseFloat(c as string))
          ? c === 'ACTIVE'
            ? 'PENDING'
            : c
          : formatPrice(parseFloat(c as string))
        return <Cell key={i} className={className}>{c}</Cell>
      })}
    </tr>
  )
}

type TableProps = {className: string, levels: Array<Array<string | number>>, displaySide: QuoteRequestSide | null}
const useTableStyles = makeStyles({
  head: {},
  body: {},
  table: {
    borderCollapse: 'separate',
    borderSpacing: 0,
  },
}, { name: 'otc-order-book-table' });
const Table = ({className, levels, displaySide}: TableProps): JSX.Element => {
  const cls = useTableStyles()
  const [head, ...rows] = levels
  if (rows.length) {
    while (rows.length < MIN_ROWS) {
      rows.push(['', '', '', '']);
    }
  }
  return (
    <table className={clsx(cls.table, className)}>
      <thead className={cls.head}><TR cols={head} head /></thead>
      <tbody className={cls.body}>{rows.map((cols, i) => <TR key={i} cols={cols} index={i} displaySide={displaySide} />)}</tbody>
    </table>
  )
}



const useOrderBookStyles = makeStyles(({ palette }) => ({
  root: {
    height: 98,
    overflow: 'auto',
  },
  table: {
    width: '100%',
  },
  leftTable: {
    paddingLeft: 12,
    borderRight: `1px solid ${palette.divider}`,
  },
  rightTable: {
    paddingRight: 12,
  }
}), { name: 'otc-order-book' })
const OrderBook = ({levels, displaySide}: {levels: Array<Array<string | number>>, displaySide: QuoteRequestSide | null}) => {
  const cls = useOrderBookStyles()
  return <div className={cls.root}><Table className={cls.table} levels={levels} displaySide={displaySide} /></div>
}



type PricesProps = {
  className?: string
  bid?: string | number
  bidDir?: number
  ask?: string | number
  askDir?: number
  spread?: string | number
}
const usePricesStyles = makeStyles(({ palette }) => ({
  root: {
    ...flex(),
    // padding: '8px 12px',
    width: 300,
    height: 40,
  },
  spread: {
    flex: '0 0 auto',
    ...flex(),
    ...fonts.displayFonts.xSmall,
    color: palette.level1.main,
    margin: '0px 8px',
    fontWeight: 700,
    width: 42,
  },
  bid: {
    position: 'relative',
    ...flex({jc: 'flex-end'}),
    flex: '1 1 100px',
    ...fonts.displayFonts.largeStrong,
    height: fonts.displayFonts.largeStrong.lineHeight,
    marginRight: fonts.displayFonts.xSmall.lineHeight,
    fontWeight: 700,
    width: '50%',
  },
  bidIcon: {
    marginRight: `calc(-1*${fonts.displayFonts.xSmall.lineHeight})`,
  },
  ask: {
    position: 'relative',
    ...flex({jc: 'flex-start'}),
    flex: '1 1 100px',
    ...fonts.displayFonts.largeStrong,
    height: fonts.displayFonts.largeStrong.lineHeight,
    marginLeft: fonts.displayFonts.xSmall.lineHeight,
    fontWeight: 700,
    width: '50%',
  },
  askIcon: {
    marginLeft: `calc(-1*${fonts.displayFonts.xSmall.lineHeight})`,
  },
  up: {
    color: palette.affirm.main,
  },
  down: {
    color: palette.alert1.main,
  },
  flat: {
    color: palette.level2.main,
  },
}), { name: 'otc-top-of-book' })
const colorCls = (cls: ClassNameMap<string>, dir: number) => dir > 0 ? cls.up : dir < 0 ? cls.down : cls.flat
const Prices = ({className, bid, bidDir = 0, ask, askDir = 0, spread}: PricesProps) => {
  const cls = usePricesStyles()
  const BidIcon = bidDir > 0 ? PriceUpIcon : PriceDownIcon
  const AskIcon = askDir > 0 ? PriceUpIcon : PriceDownIcon
  const showBidIcon = typeof bid !== 'undefined' && bid > 0 && bidDir !== 0
  const showAskIcon = typeof ask !== 'undefined' && ask > 0 && askDir !== 0
  const bidText = typeof bid !== 'undefined' && bid !== '' ? formatPrice(bid) : ''
  const askText = typeof ask !== 'undefined' && ask !== '' ? formatPrice(ask) : ''
  return (
    <div className={clsx(cls.root, className)}>
      <div className={clsx(cls.bid, colorCls(cls, bidDir))}>
        {bidText}
        {showBidIcon && <BidIcon className={cls.bidIcon} size="xSmall" />}
      </div>
      <div className={cls.spread}>{spread}</div>
      <div className={clsx(cls.ask, colorCls(cls, askDir))}>
        {showAskIcon && <AskIcon className={cls.askIcon} size="xSmall" />}
        {askText}
      </div>
    </div>
  )
}



type SummaryProps = { instrument: PopulatedInstrument, quantity: number, price: number, error?: boolean }
const useSummaryStyles = makeStyles(({ palette }) => ({
  root: {
    ...flex({dir: 'column', ai: 'flex-start'}),
    paddingLeft: 12,
    height: 40,
  },
  row1: {
    ...fonts.displayFonts.mediumStrong,
    fontWeight: 700,
    color: palette.level1.main,
  },
  row2: {
    ...fonts.displayFonts.xSmall,
    color: palette.level2.main,
  },
}), { name: 'otc-order-summary' })
const Summary = ({ instrument, quantity, price, error }: SummaryProps) => {
  const { root, row1, row2 } = useSummaryStyles()
  const { baseAsset: { symbol: base }, termAsset: { symbol: term } } = instrument
  const total = quantity * price
  const filled = `${quantity} ${base}`
  const at = `@ VWAP ${price}`
  const paid = `For ${formatPrice(total)} ${term}`
  return (
    <div className={root}>
      <div className={row1}>{filled} {!error && at}</div>
      {!error && <div className={row2}>{paid}</div>}
    </div>
  )
}



type OrderParamLabelProps = {text: string, has?: boolean, value?: string | number, unit?: string, showUnit?: boolean}
const useOrderParamLabelStyles = makeStyles({
  root: {...flex({jc: 'flex-start'}), '&:not(:last-child)': { marginRight: 8 }},
  label: { ...fonts.displayFonts.xSmall, marginRight: 4 },
  value: { ...fonts.displayFonts.xSmallStrong, fontWeight: 700 },
}, { name: 'otc-order-parameter' })
const OrderParamLabel = (props: OrderParamLabelProps) => {
  const cls = useOrderParamLabelStyles()
  const { text, has, value, unit, showUnit = false } = props
  return has && value ? (
    <div className={cls.root}>
      <div className={cls.label}>{text}</div>
      <div className={cls.value}>{`${value}${unit && showUnit ? ` ${unit}` : ''}`}</div>
    </div>
  ) : null
}

type OrderParamsLabelProps = {
  placeholder: string
  params: {
    hasLimitPrice: boolean
    hasStopPrice: boolean
  }
  limitPrice?: string | number
  stopPrice?: string | number
  unit?: string
  showUnit?: boolean
}
const useOrderParamsLabelStyles = makeStyles({
  root: flex({jc: 'flex-start'})
}, { name: 'otc-order-parameters' })
const OrderParamsLabel = (props: OrderParamsLabelProps) => {
  const cls = useOrderParamsLabelStyles()
  const { placeholder, params: { hasLimitPrice, hasStopPrice }, limitPrice, stopPrice, unit, showUnit } = props
  return (hasLimitPrice && limitPrice) || (hasStopPrice && stopPrice) ? (
    <div className={cls.root}>
      <OrderParamLabel text="Stop" has={hasStopPrice} value={stopPrice} unit={unit} showUnit={showUnit} />
      <OrderParamLabel text="Limit" has={hasLimitPrice} value={limitPrice} unit={unit} showUnit={showUnit} />
    </div>
  ) : (
    <div className={cls.root}>{placeholder}</div>
  )
}

enum FieldGroup {
  Instrument = 0,
  Quantity,
  Venues,
  OrderType,
  OrderParams,
}

export type OTCTileClassKey =
  | 'root' | 'select' | 'popoverPaper' | 'selectInput' | 'venueRow' | 'menuButton' | 'venueIcon' | 'orderTypeMenu'
  | 'pricesRow' | 'orderTypeRow' | 'orderTypeRoot' | 'orderParams' | 'orderParamsInput' | 'orderBookRow'
  | 'buttonRow' | 'button' | 'singleClickBuy' | 'singleClickSell' | 'dismissButtonSuccess' | 'dismissButtonFailure'
  | 'footer';

export type OTCTileProps = Pick<BaseProps<HTMLDivElement, OTCTileClassKey>, 'classes' | 'className'> & {
  onChange: <T extends keyof Omit<OTCTileData, 'id'>>(index: number, field: T, value: OTCTileData[T]) => void;
  onDelete: (index: number) => void;
  onDismiss: (index: number) => void;
  onInspect: (fixClOrdID?: string) => void;
  book: OTCOrderBook;
  formData: HydratedOTCTileData;
  formOptions: OTCFormOptions;
  index: number;
  error?: boolean;
  loading: boolean;
  order?: Order | OTCOrder;
  priceDirection?: number;      // -1 or 0 or 1
  singleClick: boolean;
}

// const useStyles = makeStyles<Pick<OTCTileProps, 'singleClick'>, OTCTileClassKey>(({ palette }) => ({
const useStyles = makeStyles(({ palette }) => ({
  root: {
    position: 'relative',
    width: 300,
    marginRight: 20,
    marginBottom: 20,
    zIndex: 0,
    borderRadius: 6,
    '&::after': {
      borderRadius: 'inherit',
      border: `1px solid ${palette.borderTrans.main}`,
      content: '""',
      pointerEvents: 'none',
      position: 'absolute',
      inset: 0,
    },
  },
  row: flex({jc: 'space-between'}),
  popoverPaper: {
    boxShadow: 'none',
  },
  popoverInput: {
    '& > .MuiInputBase-root': {
      fontSize: 16, // NOTE: this is required to get the floating label text to inherit the correct font size
    },
  },
  venueRow: {
    ...flex({jc: 'flex-start'}),
    padding: '0px 12px',
    height: 34,
    width: 300,
  },
  menuButton: {
    padding: '0px 14px 0px 0px',
    fontWeight: '700 !important' as any,
  },
  menuIcon: {
    top: 0,
    color: palette.level4.main,
    '&:hover': {
      color: palette.level1.main,
    },
  },
  orderTypeMenu: {
    marginRight: 8,
  },
  pricesRow: {
    ...flex({jc: 'center'}),
    width: 300,
    height: 40,
  },
  orderTypeRow: {
    ...flex({jc: 'flex-start'}),
    padding: '0px 12px',
    width: 300,
    height: 34,
  },
  orderTypeRoot: {
    padding: '0px 14px 0px 0px',
  },
  orderParams: {
    ...fonts.displayFonts.xSmall,
    height: fonts.displayFonts.xSmall.lineHeight,
    '& > svg': {
      color: palette.level4.main,
    },
    '&:hover > svg': {
      color: palette.level1.main,
    },
  },
  orderParamsInput: {
    '&:not(:last-child)': {
      marginRight: 8,
    },
  },
  orderBookRow: {
    width: 300,
    height: 98,
  },
  buttonRow: {
    ...flex({jc: 'center'}),
    width: 300,
    height: 40,
  },
  button: {
    margin: '0px 4px',
    minWidth: 100,
  },
  singleClickButton: {
    // margin: '0px 4px',
    // minWidth: 100,
    '&:hover > .MuiButton-label': {
      color: palette.canvas.main,
    },
  },
  singleClickBuy: {
    '&:hover::after': {
      backgroundColor: `${palette.buy.main} !important`,
    },
  },
  singleClickSell: {
    '&:hover::after': {
      backgroundColor: `${palette.sell.main} !important`,
    },
  },
  dismissButtonSuccess: {
    width: 100,
    backgroundColor: `${palette.affirm.main} !important`,
  },
  dismissButtonFailure: {
    width: 100,
    backgroundColor: `${palette.alert1.main} !important`,
  },
  footer: {
    height: 8,
    backgroundColor: palette.hov2.main,
    borderRadius: '0px 0px 8px 8px',
  },
  list: {
    maxHeight: 400,
  }
}), { name: 'otc-tile' });

export function OTCTile({
  book,
  classes,
  className,
  formData,
  formOptions,
  index,
  loading,
  error,
  onChange,
  onDelete,
  onDismiss,
  onInspect,
  singleClick = true,
  order,
}: OTCTileProps) {
  const cls = useStyles();
  const headCls = useTileHeadStyles();
  const [openField, setOpenField] = useState<FieldGroup | null>(null);
  const [lastL1, setLastL1] = useState<[number, number]>(); // [bid, ask]
  const [bidDir, setBidDir] = useState<OTCSortDirection>(0);
  const [askDir, setAskDir] = useState<OTCSortDirection>(0);
  const [sideHovering, setSideHovering] = useState<QuoteRequestSide | null>(null);
  const quantityRef = React.useRef<HTMLInputElement>(null);
  const stopRef = React.useRef<HTMLInputElement>(null);
  const limitRef = React.useRef<HTMLInputElement>(null);

  const { instrumentId, limitPrice, instrument: selectedInstrument, orderType, quantity, side, stopPrice, venues: selectedVenues, venueIds } = formData;
  const { instruments } = formOptions;

  const selectedOrderType = useMemo(() => OTC_ORDER_TYPES.find(ot => ot.name === orderType) ?? null, [orderType]);

  const venues = useMemo(() => (
    !!instrumentId
      ? formOptions.venues.filter(({ instrumentConfigurations: ic }) => ic.some(({ instrument: i }) => i?.id === instrumentId))
      : [...formOptions.venues]
    ).sort((a, b) => a.name.localeCompare(b.name)),
    [formOptions.venues, instrumentId]);

  const selectedInstrumentString = selectedInstrument?.displayName || selectedInstrument?.identifier ||  'Instrument';
  const selectedTermString = selectedInstrument?.termAsset?.symbol;
  const selectedBaseString = selectedInstrument?.baseAsset?.symbol;
  const selectedVenuesString = !selectedVenues?.length ? 'Select Venues' : selectedVenues.map(({shortName, name}) => shortName || name).join(', ');
  const selectedOrderTypeString = selectedOrderType?.name ?? 'Order Type';

  const filledFields = [!!instrumentId, !!quantity, !!venueIds && venueIds.length, !!orderType, (!selectedOrderType?.hasStopPrice || !!stopPrice) && (!selectedOrderType?.hasLimitPrice || !!limitPrice)];

  const orderSent = !!order || !!error || loading;
  // every necessary field is filled and there is at least one quote present
  const canPlaceOrder = filledFields.every(f => f) && typeof book[0]?.[1] === 'number' && !orderSent;
  const isActive = !!order && isOrderActive(order);

  function updateField<T extends keyof Omit<OTCTileData, 'id'>>(field: T, value: OTCTileData[T]) {
    onChange(index, field, value);
    // For the single-select dropdowns, we perform wizard logic here
    if (!!value) {
      if (field === 'instrumentId') {
        if (!filledFields[FieldGroup.Quantity]) setOpenField(FieldGroup.Quantity);
        else setOpenField(null);
      } else if (field === 'orderType') {
        const ot = OTC_ORDER_TYPES.find(({ name }) => name === value);
        if (filledFields.slice(0, FieldGroup.OrderType).every(f => f) && ot && ((ot.hasLimitPrice && !limitPrice) || (ot.hasStopPrice && !stopPrice))) setOpenField(FieldGroup.OrderParams);
        else setOpenField(null);
      }
    }
  }

  const handleClose = (fg: FieldGroup) => {
    if (
      [FieldGroup.Quantity, FieldGroup.Venues].includes(fg) &&
      filledFields.slice(0, fg + 1).every(f => f) &&
      !filledFields[fg + 1]
    ) {
      setOpenField(fg + 1);
    } else {
      setOpenField(null);
    }
  }

  const bidAskSuffix = selectedTermString ? ` (${selectedTermString})` : ''
  const bookHeader = ['Venue', `Bid${bidAskSuffix}`, `Ask${bidAskSuffix}`, 'Venue']

  const getPriceDir = (cur: number, prev: number) => cur < prev ? -1 : cur > prev ? 1 : 0;

  const handleMouseOverSell = singleClick ? () => setSideHovering(SELL) : undefined;
  const handleMouseOverBuy = singleClick ? () => setSideHovering(BUY) : undefined;
  const handleMouseLeave = singleClick ? () => setSideHovering(null) : undefined;
  const displaySide = side ?? sideHovering;

  useEffect(() => {
    if (!book.length) return;
    const [, bid, ask] = book[0];
    if (typeof bid !== 'number' || typeof ask !== 'number') return;

    if (!lastL1) {
      setLastL1([bid, ask]);
    } else if (bid !== lastL1[0] || ask !== lastL1[1]) {
      setBidDir(getPriceDir(bid, lastL1[0]));
      setAskDir(getPriceDir(ask, lastL1[1]));
      setLastL1([bid, ask]);
    }
  }, [book, lastL1]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (openField !== null && [FieldGroup.Quantity, FieldGroup.OrderParams].includes(openField)) {
      const ref = openField === FieldGroup.Quantity
        ? quantityRef
        : selectedOrderType?.hasStopPrice
        ? stopRef
        : limitRef;

      if (ref.current) ref.current.focus();
      else {
        intervalId = setInterval(() => {
          if (ref.current) {
            ref.current.focus();
            clearInterval(intervalId);
          }
        }, 50);
      }
    }

    return () => intervalId && clearInterval(intervalId);
  }, [openField, selectedOrderType]);

  const bid = book[0]?.[1] ?? '';
  const ask = book[0]?.[2] ?? '';
  const spread = bid > 0 && ask > 0 ? Decimal.sub(ask, bid).toPrecision(5).slice(0, 6) : ''
  const quantityFilled = (order as Order | undefined)?.calculatedQuantityFilled ?? 0;
  const price = (order as Order | undefined)?.calculatedAverageFillPrice ?? 0;
  const instrument = order?.instrument ?? selectedInstrument;

  return (
    <div className={clsx(cls.root, classes?.root, className)}>
      <div className={clsx(cls.row, headCls.root)}>
        {orderSent ? (
          <div className={clsx(headCls.menuButton, headCls.label)}>{instrument?.displayName ?? instrument?.identifier}</div>
        ) : (
          <Menu
            classes={{ list: cls.list, paper: cls.popoverPaper }}
            filterable
            items={instruments.map(({ id, displayName }) => ({ name: displayName, value: id, type: 'item' }))}
            onChange={(v) => updateField('instrumentId', v as string)}
            onClose={() => handleClose(FieldGroup.Instrument)}
            onOpen={() => setOpenField(FieldGroup.Instrument)}
            open={openField === FieldGroup.Instrument}
            placeholder={selectedInstrumentString}
            popupButtonSize="medium"
            popupClasses={{ root: headCls.menuButton, endIcon: headCls.menuIcon, label: headCls.label }}
            renderLabel={_ => selectedInstrumentString}
            value={instrumentId}
            variant="menu"
          />
        )}
        <div className={headCls.qtyGroup}>
          {orderSent ? (
            <>
              <span className={clsx(headCls.label, headCls.spacedInput)}>
                {order?.status === OrderStatus.FILLED ? side === BUY ? OTCCopyText.bought : OTCCopyText.sold : side === BUY ? OTCCopyText.buy : OTCCopyText.sell}
              </span>
              <span className={clsx(headCls.label, headCls.spacedInput)}>{quantity}</span>
            </>
          ) : (
            <>
              {displaySide && <span className={clsx(headCls.label, headCls.spacedInput)}>{displaySide === BUY ? OTCCopyText.buy : OTCCopyText.sell}</span>}
              <SimpleInputDialog
                className={clsx(headCls.label, headCls.spacedInput, !quantity && headCls.italic)}
                label={quantity || 'Quantity'}
                onOpen={() => setOpenField(FieldGroup.Quantity)}
                onClose={() => handleClose(FieldGroup.Quantity)}
                open={openField === FieldGroup.Quantity}
              >
                <Input
                  inputRef={quantityRef}
                  className={cls.popoverInput}
                  type="number"
                  value={quantity || ''}
                  label="Quantity"
                  onBlur={() => handleClose(FieldGroup.Quantity)}
                  onChange={(e) => updateField('quantity', e.target.value as string)}
                  onKeyPress={(e) => e.key === 'Enter' && handleClose(FieldGroup.Quantity)}
                  size="small"
                  suffix={selectedBaseString}
                />
              </SimpleInputDialog>
            </>
          )}
          <IconWithTooltip Icon={CloseIcon} className={headCls.deleteButton} onClick={() => onDelete(index)} size="mediumStrong" title={OTCCopyText.deleteTile} />
        </div>
      </div>
      <Indicator status={order?.status} side={order?.side} />
        <div className={cls.venueRow}>
          {orderSent ? (
            <span className={cls.menuButton}>{selectedVenuesString}</span>
          ) : (
            <Menu
              classes={{ list: cls.list, paper: cls.popoverPaper }}
              items={venues.map(({ id, name, shortName }) => ({ name: shortName ? `${name} (${shortName})` : name, value: id, type: 'item' }))}
              MenuListProps={{
                autoFocus: true,
                onKeyDownCapture: (e) => ['Enter', 'Tab'].includes(e.key) && (e.preventDefault(), e.stopPropagation(), handleClose(FieldGroup.Venues)),
              }}
              multiple
              onChange={(v) => updateField('venueIds', v as string[])}
              onClose={() => handleClose(FieldGroup.Venues)}
              onOpen={() => setOpenField(FieldGroup.Venues)}
              open={openField === FieldGroup.Venues}
              placeholder={selectedVenuesString}
              popupButtonSize="xSmall"
              popupClasses={{ root: cls.menuButton, endIcon: cls.menuIcon }}
              renderLabel={_ => selectedVenuesString}
              value={venueIds ?? []}
              variant="menu"
            />
          )}
        </div>
      <Divider />
      {orderSent && instrument ? (
        <Summary error={error} instrument={instrument} quantity={quantityFilled} price={price} />
      ) : (
        <Prices bid={bid} bidDir={bidDir} ask={ask} askDir={askDir} spread={spread} />
      )}
      <Divider />
      <div className={cls.orderTypeRow}>
        {orderSent ? (
          <span className={clsx(cls.menuButton, cls.orderTypeMenu)}>{selectedOrderTypeString}</span>
        ) : (
          <Menu
            classes={{ list: cls.list, paper: cls.popoverPaper }}
            items={OTC_ORDER_TYPES.map(({ name }) => ({ name, value: name, type: 'item' }))}
            MenuListProps={{
              autoFocus: true,
            }}
            onChange={(v) => updateField('orderType', v as OTCOrderType)}
            onClose={() => handleClose(FieldGroup.OrderType)}
            onOpen={() => setOpenField(FieldGroup.OrderType)}
            open={openField === FieldGroup.OrderType}
            placeholder={selectedOrderTypeString}
            popupButtonSize="xSmall"
            popupClasses={{ root: clsx(cls.menuButton, cls.orderTypeMenu), endIcon: cls.menuIcon }}
            renderLabel={_ => selectedOrderTypeString}
            value={orderType}
            variant="menu"
          />
        )}
        {selectedOrderType && (selectedOrderType.hasStopPrice || selectedOrderType.hasLimitPrice) && (
          <SimpleInputDialog
            className={clsx(cls.orderParams, !(limitPrice || stopPrice) && headCls.italic)}
            disabled={orderSent}
            label={
              <OrderParamsLabel
                placeholder={selectedOrderType.hasStopPrice && selectedOrderType.hasLimitPrice
                  ? OTCCopyText.combinedParamLabel : selectedOrderType.hasStopPrice ? OTCCopyText.stopPrice : OTCCopyText.limitPrice}
                params={selectedOrderType}
                limitPrice={limitPrice}
                stopPrice={stopPrice}
                unit={selectedTermString}
                showUnit={false}
              />
            }
            onOpen={() => setOpenField(FieldGroup.OrderParams)}
            onClose={() => handleClose(FieldGroup.OrderParams)}
            open={openField === FieldGroup.OrderParams}
            size="xSmall"
          >
            {selectedOrderType.hasStopPrice && (
              <Input
                inputRef={stopRef}
                className={clsx(cls.orderParamsInput, cls.popoverInput)}
                type="number"
                value={stopPrice || ''}
                label="Stop Price"
                onBlur={() => !selectedOrderType.hasLimitPrice && handleClose(FieldGroup.OrderParams)}
                onChange={(e) => updateField('stopPrice', e.target.value as string)}
                onKeyPress={(e) => e.key === 'Enter' && (selectedOrderType.hasLimitPrice ? limitRef.current?.focus() : handleClose(FieldGroup.OrderParams))}
                size="small"
                suffix={selectedTermString}
              />
            )}
            {selectedOrderType.hasLimitPrice && (
              <Input
                inputRef={limitRef}
                className={clsx(cls.orderParamsInput, cls.popoverInput)}
                type="number"
                value={limitPrice || ''}
                label="Limit Price"
                onBlur={() => handleClose(FieldGroup.OrderParams)}
                onChange={(e) => updateField('limitPrice', e.target.value as string)}
                onKeyPress={(e) => e.key === 'Enter' && handleClose(FieldGroup.OrderParams)}
                size="small"
                suffix={selectedTermString}
              />
            )}
          </SimpleInputDialog>
        )}
      </div>
      <Divider />
      <div className={cls.orderBookRow}>
        {!orderSent ? (
          <OrderBook levels={[bookHeader, ...book]} displaySide={displaySide} />
        ) : (
          <Status order={order as Order ?? { side, status: '' }} error={error} onInspect={onInspect} />
        )}
      </div>
      <Divider />
      <div className={cls.buttonRow}>
        {orderSent ? (
          <Button
            className={order && isOrderHappy(order) ? cls.dismissButtonSuccess : order && isOrderUnhappy(order) ? cls.dismissButtonFailure : cls.button}
            color={isActive ? 'outline' : undefined}
            onClick={() => onDismiss(index)}
            size="small"
            variant={isActive ? 'outlined' : 'contained'}
          >
            Dismiss
          </Button>
        ) : (
          <>
            <Button
              className={clsx(cls.button, singleClick && [cls.singleClickButton, cls.singleClickSell])}
              disabled={!canPlaceOrder}
              color={side === SELL ? 'sell' : 'outline'}
              onClick={() => updateField('side', SELL)}
              onMouseEnter={handleMouseOverSell}
              onMouseLeave={handleMouseLeave}
              size="small"
              variant="outlined"
            >
              Sell
            </Button>
            <Button
              className={clsx(cls.button, singleClick && [cls.singleClickButton, cls.singleClickBuy])}
              disabled={!canPlaceOrder}
              color={side === BUY ? 'buy' : 'outline'}
              onClick={() => updateField('side', BUY)}
              onMouseEnter={handleMouseOverBuy}
              onMouseLeave={handleMouseLeave}
              size="small"
              variant="outlined"
            >
              Buy
            </Button>
          </>
        )}
      </div>
      <div className={cls.footer} />
    </div>
  );
}

export default OTCTile;
