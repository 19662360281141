import * as React from 'react';
//@ts-ignore -> until onx-common-ui exports types
import { layout } from '@omniex/onx-common-ui/lib/styles';
import { makeStyles } from '@omniex/poms-ui/lib/themes';
import { Notifications } from '@omniex/poms-ui/lib/components/Notifications';
import { NotificationItem } from '@omniex/poms-ui/lib/components/Notifications/NotificationItem';
import { NotificationItemProps } from '@omniex/poms-ui/lib/types';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  InfiniteLoader,
  List,
  ListRowProps
} from 'react-virtualized';
import copyText from './NotificationCenter.copyText';
import LinearProgress from '@material-ui/core/LinearProgress';

const COMPONENT = 'NotificationCenter';

const useStyles = makeStyles({
  container: {
    zIndex: '1001 !important' as any, // Same z-index as PageLayoutHeaderMenu
    position: 'fixed',
    width: '480px',
    fontFamily: '"Roboto", sans-serif !important',
  },
  itemContainer: {
    '& div:nth-child(1) > div:nth-child(2) > div:nth-child(2)': {
      fontWeight: '600'
    },
  }
}, { name: COMPONENT });
export interface NotificationCenterProps {
  allNotificationsAction: () => void
  onLoadMore: () => Promise<any>
  isRowLoaded: (index: number) => boolean
  notifications: Array<NotificationItemProps>
  showNotifications: boolean
  count: number
};

const NotificationCenter = ({
  allNotificationsAction = () => null,
  onLoadMore,
  isRowLoaded,
  notifications = [],
  showNotifications = false,
  count = 0,
}: NotificationCenterProps) => {
  const cls = useStyles();
  const cache = new CellMeasurerCache({ defaultHeight: 75, fixedWidth: true });  

  const rowRenderer = ({ index, key, style, parent }: ListRowProps) => {
    let content;
    if (!isRowLoaded(index)) {
      content = <LinearProgress />;
    } else {
      content = <NotificationItem {...notifications[index]} />;
    }

    return (
      <CellMeasurer
        key={key} 
        parent={parent}
        cache={cache}
        rowIndex={index}
        columnIndex={0}
        width={480}
      >
        <div style={style} className={cls.itemContainer}>{content}</div>
      </CellMeasurer>
    );
  };

  return (
    <Notifications
      allNotificationsAction={allNotificationsAction}
      className={cls.container}
      open={showNotifications}
      title={copyText.trayTitle}
      triggerTopOffset={layout.menuBarHeight}
    >
      <InfiniteLoader
        isRowLoaded={({ index }) => isRowLoaded(index)}
        loadMoreRows={() => onLoadMore()}
        rowCount={count}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ height, width }) => (
              <List
                ref={registerChild}
                width={width}
                height={height}
                onRowsRendered={onRowsRendered}
                rowCount={count}
                rowRenderer={rowRenderer}
                rowHeight={cache.rowHeight}
              />
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    </Notifications>
  );
};

export default NotificationCenter;
