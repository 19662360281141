import gql from 'graphql-tag';

const getDistinctNotificationValues = gql`
  query getDistinctNotificationValues {
    distinctValues: getDistinctNotificationValues {
      algorithmStrategies
      features
      fixClOrdIDs
      instruments {
        id
        displayName
        identifier
        symbol
      }
      sides
      venues {
        id
        name
      }
    }
  }
`;

export default getDistinctNotificationValues;
