import { arrayOf, func, number, object, shape, string } from 'prop-types';
import { Button } from 'semantic-ui-react';
import clsx from 'clsx';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { formatNum } from '../../utils/SettlementUtils';
import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { orderBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import AllocationStatus from '@omniex/onx-poms-entity-helpers/lib/enums/AllocationStatus';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './ConfirmAllocationsTable.copyText';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import SettlementFillsTable from './SettlementFillsTable';
import Table from '@omniex/onx-common-ui/lib/semantic/react/Table';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/button.css');
require('@omniex/onx-common-ui/lib/semantic/css/table.css');

const COMPONENT_NAME = 'ConfirmAllocationsTable';

const StyledDiv = styled('div')`
  margin-bottom: 14px !important;
  max-width: 950px;
  padding: 20px !important;

  .${COMPONENT_NAME}-quantity, .${COMPONENT_NAME}-quantityHeader {
    padding-right: 0.25em !important;
  }

  .${COMPONENT_NAME}-currency {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .${COMPONENT_NAME}-borderlessCell {
    border: none !important;
  }

  .${COMPONENT_NAME}-rightCell {
    text-align: right !important;
  }

  .${COMPONENT_NAME}-leftCell {
    text-align: left !important;
  }

  .${COMPONENT_NAME}-instruction {
    max-width: 400px;
    overflow: visible;
  }

  .${COMPONENT_NAME}-statusButton {
    text-align: center;
    width: 105px !important;
  }

  .${COMPONENT_NAME}-statusHeader {
    padding-right: 50px !important;
  }

  .${COMPONENT_NAME}-statusLabel {
    border-radius: 0.2em;
    font-size: 0.85em;
    text-align: center;
    width: 105px;

    &.sent {
      background: ${colors.white};
      border: 1px solid ${colors.blue};
      color: ${colors.blue};
    }

    &.success {
      background: ${colors.white};
      border: 1px solid ${colors.positiveTextColor};
      color: ${colors.positiveTextColor};
    }
  }

  .${COMPONENT_NAME}-statusIcon {
    font-size: 1.5em;
    line-height: normal;
    margin-left: 5px !important;
  }

  .${COMPONENT_NAME}-permissionDeniedMessage {
    text-align: center;
  }
`;

const WarningDiv = styled('div')`
  text-align: center;
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class ConfirmAllocationsTable extends PureComponent {
  static propTypes = {
    settlement: shape({
      allocations: arrayOf(
        shape({
          id: string.isRequired,
          currency: shape({
            symbol: string.isRequierd,
            type: string.isRequired
          }),
          fromInstructions: shape({
            id: string.isRequired,
            freeForm: string.isRequired,
            walletAddress: string.isRequired
          }),
          quantity: number.isRequired,
          toInstructions: shape({
            id: string.isRequired,
            freeForm: string.isRequired,
            walletAddress: string.isRequired
          })
        })
      )
    }),
    fills: arrayOf(object),
    org: shape({
      id: string.isRequired,
      fixSenderCompID: string.isRequired,
    }),
    user: object.isRequired,
    onAdvance: func
  };

  static defaultProps = {
    onAdvance: noop,
  };

  render() {
    let allocations = get(this.props, 'settlement.allocations') || [];
    const fills = get(this.props, 'settlement.fills') || [];

    if (!allocations.length && !fills.length) {
      return this._renderWarningMessage();
    }

    allocations = orderBy(allocations, 'quantity', 'desc');
    return (
      <StyledDiv className={COMPONENT_NAME}>
        {allocations.length
          ? <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell className={clsx(cn('borderlessCell'), cn('rightCell'))}>
                    {copyText.tableHeaderDirection}
                  </Table.HeaderCell>
                  <Table.HeaderCell className={clsx(cn('quantityHeader'), cn('borderlessCell'), cn('rightCell'))}>
                    {copyText.tableHeaderQuantity}
                  </Table.HeaderCell>
                  <Table.HeaderCell className={cn('borderlessCell')} />
                  <Table.HeaderCell>{copyText.tableHeaderWallet}</Table.HeaderCell>
                  <Table.HeaderCell>{copyText.tableHeaderAddress}</Table.HeaderCell>
                  <Table.HeaderCell className={cn('borderlessCell')}>
                    {copyText.tableHeaderCounterpartyDetails}
                  </Table.HeaderCell>
                  <Table.HeaderCell className={clsx(cn('statusHeader'), cn('borderlessCell'), cn('leftCell'))}>
                    {copyText.tableHeaderStatus}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {allocations.map((allocation, index) => this._renderRow(allocation, index))}
              </Table.Body>
            </Table>
          : <Message content={copyText.settledOffline} warning />
        }
        <SettlementFillsTable fills={fills} org={get(this.props, 'org')} />
      </StyledDiv>
    );
  }

  _renderRow(allocation = {}, index) {
    const { org } = this.props
    const incoming = org.fixSenderCompID === allocation.fixToCompID
    const instruction = (incoming ? allocation.toInstruction : allocation.fromInstruction) || {}
    const account = (incoming ? allocation.toAccount : allocation.fromAccount) || {}

    return (
      <Table.Row key={allocation.id}>
        <Table.Cell className={clsx(cn('borderlessCell'), cn('rightCell'))}>
          {incoming ? copyText.incomingLabel : copyText.outgoingLabel}
        </Table.Cell>
        <Table.Cell className={clsx(cn('quantity'), cn('borderlessCell'), cn('rightCell'))}>
          {formatNum(allocation.quantity, get(allocation, 'currency.type'))}
        </Table.Cell>
        <Table.Cell className={clsx(cn('currency'), cn('borderlessCell'))}>
          {getAssetDisplayText(get(allocation, 'currency'))}
        </Table.Cell>
        <Table.Cell className={cn('borderlessCell')}>
          {account.name || copyText.noDataLabel}
        </Table.Cell>
          <Instruction instruction={instruction} />
          <CounterpartyDetails allocation={allocation} incoming={incoming} />
        <Table.Cell className={clsx(cn('borderlessCell'), cn('leftCell'))}>
            {this._renderActionComponent(allocation, index)}
        </Table.Cell>
      </Table.Row>
    );
  }



  _renderActionComponent(allocation, index) {
    const isSender = allocation.fixFromCompID === get(this.props, 'org.fixSenderCompID');

    if (
      allocation.status === AllocationStatus.CLEARED ||
      (isSender && allocation.status === AllocationStatus.INITIATED)
    ) {
      return (
        <Button className={cn('statusButton')} disabled size="mini">
          {copyText.buttonLabelCompleted}
        </Button>
      );
    }

    if (!this.props.user.canConfirmSettlement) {
      return index === 0 ? (
        <Message
          className={cn('permissionDeniedMessage')}
          content={copyText.permissionDenied}
          warning
        />
      ) : null;
    }

    if (!isSender) {
      return (
        <Button
          className={cn('statusButton')}
          color="blue"
          size="mini"
          type="submit"
          onClick={this._handleSubmitClearAllocation.bind(this, allocation.id)}>
          {copyText.buttonLabelFundsReceived}
        </Button>
      );
    }

    return (
      <Button
        className={cn('statusButton')}
        color="orange"
        size="mini"
        onClick={this._handleSubmitClearAllocation.bind(this, allocation.id)}>
        {copyText.buttonLabelAcceptFunds}
      </Button>
    );
  }

  _renderWarningMessage() {
    return (
      <WarningDiv className={COMPONENT_NAME}>
        <Message
          className={cn('warningMessage')}
          content={copyText.settledOffline}
          warning
        />
      </WarningDiv>
    );
  }

  _handleSubmitClearAllocation = allocationId => {
    this.props.onAdvance({ allocationId });
  };
}

  const Instruction = ({ instruction }) => (
    <Table.Cell className={clsx(cn('instruction'), cn('borderlessCell'))}>
      {instruction.iban || instruction.freeForm || instruction.walletAddress || copyText.noDataLabel}
    </Table.Cell>
  )

  const CounterpartyDetails = ({ allocation = {}, incoming }) => {
    const instruction = (incoming ? allocation.fromInstruction : allocation.toInstruction) || {}
    const account = (incoming ? allocation.fromAccount : allocation.toAccount) || {}
    const custodian = account.customCustodian || account.custodian || {}

    return (
      <Table.Cell className={cn('borderlessCell')}>
        {instruction.walletAddress
          ? <>{instruction.walletAddress}</>
          : <>
              {custodian.name || copyText.noDataLabel}
              {!incoming && <OutgoingDetails customCustodian={custodian} instruction={instruction} />}
            </>
        }
      </Table.Cell>
    )
  }

  const OutgoingDetails = ({ customCustodian = {}, instruction = {} }) => (
    <div>
      {customCustodian.bankAddress && <div>{customCustodian.bankAddress}</div>}
      {customCustodian.swiftCode && <div>{copyText.swiftLabel} {customCustodian.swiftCode}</div>}
      {instruction.iban && <div>{copyText.ibanLabel} {instruction.iban}</div>}
      {instruction.freeForm && <div>{copyText.accountNumberLabel} {instruction.freeForm}</div>}
    </div>
  )
