import { arrayOf, bool, func, number, shape, string, objectOf } from 'prop-types';
import { Button, Checkbox, Form, Icon, Input, Label, Popup } from 'semantic-ui-react';
import { createSelector } from 'reselect';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';
import { components } from 'react-select';

import calculateMarketPrice from '../../selectors/calculateMarketPrice';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import { convertTime, UNIT } from '../../utils/UnitUtils';
import copyText from './AlgoExecutionForm.copyText';
import { Decimal } from 'decimal.js';
import Dropdown from '@omniex/onx-common-ui/lib/semantic/react/Dropdown';
import { getDropdownStyles } from '@omniex/onx-common-ui/lib/react-select/Dropdown';
import DurationForm from './DurationForm';
import ExpiryType from '@omniex/poms-core/lib/enums/OrderExpiryType';
import FormSelect from '@omniex/onx-common-ui/lib/semantic/react/FormSelect';
import { get, has } from '@omniex/poms-core/lib/utils/ObjectUtils';
import { getAlgoName } from '@omniex/poms-core/lib/utils/OrderUtils';
import { format as formatNumber } from '@omniex/onx-common-js/lib/utils/NumberUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import getGroupedInstrumentOptions from '../../selectors/getGroupedInstrumentOptions';
import { getDisplayFormattedNum, formatNumberWithoutTrailingZeroes, getNumberFormat } from '../../utils/DisplayUtils';
import getInstrumentFromNameVenue from '../../selectors/getInstrumentFromNameVenue';
import getVenueOptions from '../../selectors/getVenueOptions';
import getVenuesWithInstrument from '../../selectors/getVenuesWithInstrument';
import getProjectedPrice from '../../selectors/getProjectedPrice';
import { getMaintenanceStartDate, getTimeToExpiry, isAfterMaintenanceStart, isAfterExpiry } from '../../utils/TimeUtils.js';
import InstrumentType from '@omniex/poms-core/lib/enums/InstrumentType';
import { isEmpty, isNil } from '@omniex/poms-core/lib/utils/LangUtils';
import { isFloat, isInt, pluralize } from '@omniex/poms-core/lib/utils/StringUtils';
import { keyBy } from '@omniex/poms-core/lib/utils/CollectionUtils';
import MarketDataEntryType from '@omniex/poms-core/lib/enums/MarketDataEntryType';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import { noop } from '@omniex/poms-core/lib/utils/FunctionUtils';
import OrderAlgorithmStrategy from '@omniex/poms-core/lib/enums/OrderAlgorithmStrategy';
import OrderSide from '@omniex/poms-core/lib/enums/OrderSide';
import OrderType from '@omniex/poms-core/lib/enums/OrderType';
import AssetType from '@omniex/poms-core/lib/enums/AssetType';
import PreTradeEstimates from './PreTradeEstimates';
import ProceedMessage from './ProceedMessage';
import ProjectedPriceMessage from './ProjectedPriceMessage';
import MultiSelect, { getUngroupedOptions, popupStyles, styles as dropdownStyles } from './MultiSelect';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/button.css');
require('@omniex/onx-common-ui/lib/semantic/css/form.css');
require('@omniex/onx-common-ui/lib/semantic/css/input.css');
require('@omniex/onx-common-ui/lib/semantic/css/label.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const allVenuesOption = { label: 'All Venues', value: '*' }

const algoOptions = (strats, isFutures, isPSwap) => (
  isFutures
    ? strats.filter(isPSwap ? algoEnabledForPSwaps : algoEnabledForFutures)
    : strats
  ).map(s => ({ value: s, label: getAlgoName(s) }));

const algorithmStrategyOptions = (isFutures, isPSwap, enablePTWAPSE) => [
  {
    label: 'Passive',
    options: algoOptions([
      OrderAlgorithmStrategy.PTWAP,
      enablePTWAPSE && OrderAlgorithmStrategy.PTWAPSE,
      OrderAlgorithmStrategy.PVWAP,
      OrderAlgorithmStrategy.PEGGER,
      OrderAlgorithmStrategy.POV,
    ].filter(_ => _), isFutures, isPSwap),
  },
  {
    label: 'Benchmark',
    options: algoOptions([
      OrderAlgorithmStrategy.TWAP,
      OrderAlgorithmStrategy.SVWAP,
    ], isFutures, isPSwap),
  },
  {
    label: 'Situational',
    options: algoOptions([
      OrderAlgorithmStrategy.ICE,
      OrderAlgorithmStrategy.SOR,
      OrderAlgorithmStrategy.SPREAD,
    ], isFutures, isPSwap),
  },
]

const ALGO_CONFIG = {
  [OrderAlgorithmStrategy.ICE]: {
    limitPrice: true,
    duration: true,
    pSwaps: true,
    futures: true,
  },
  [OrderAlgorithmStrategy.PEGGER]: {
    limitPrice: true,
    duration: true,
    pSwaps: true,
    futures: true,
  },
  [OrderAlgorithmStrategy.POV]: {
    limitPrice: true,
    pSwaps: true,
  },
  [OrderAlgorithmStrategy.PTWAP]: {
    limitPrice: true,
    intervals: true,
    duration: true,
    pSwaps: true,
    futures: true,
    postingIntervals: true, 
  },
  [OrderAlgorithmStrategy.PTWAPSE]: {
    limitPrice: true,
    intervals: true,
    duration: true,
  },
  [OrderAlgorithmStrategy.PVWAP]: {
    limitPrice: true,
    duration: true,
    pSwaps: true,
  },
  [OrderAlgorithmStrategy.SOR]: {
    limitPrice: true,
    stopPrice: true,
  },
  [OrderAlgorithmStrategy.SPREAD]: {
    duration: true,
  },
  [OrderAlgorithmStrategy.SVWAP]: {
    limitPrice: true,
    duration: true,
    pSwaps: true,
  },
  [OrderAlgorithmStrategy.TWAP]: {
    limitPrice: true,
    intervals: true,
    duration: true,
    pSwaps: true,
    futures: true,
  },
};

const checkConfig = (field, algo) => ALGO_CONFIG[algo]?.[field] ?? false;
const algoEnabledForFutures = algo => checkConfig('futures', algo);
const algoEnabledForPSwaps = algo => checkConfig('pSwaps', algo);
const algoHasDuration = algo => checkConfig('duration', algo);
const algoHasIntervals = algo => checkConfig('intervals', algo);
const algoHasPostingIntervals = algo => checkConfig('postingIntervals', algo);
const algoHasLimitPrice = algo => checkConfig('limitPrice', algo);
const algoHasStopPrice = algo => checkConfig('stopPrice', algo);

const instrumentIsFutures = instrument => get(instrument, 'type') === InstrumentType.FUTURE;
const instrumentIsPSwap = instrument => instrumentIsFutures(instrument) && isNil(get(instrument, 'baseAsset.expiry'));

const BASE = 'BASE';
// const TERM = 'TERM';

const DEFAULT_DECIMALS = 8;
const DEFAULT_DURATION_UNIT = UNIT.MINUTES;
const DEFAULT_QUANTITY_TYPE = BASE;
const MAX_NUMERIC_VALUE = 2e12;
const MAX_PERCENT = 50;
const MIN_PERCENT = 1e-2;
const MIN_TWAP_INTERVAL_DURATION = 10;
const MIN_TWAP_INTERVALS = 10;
const MIN_TWAP_SECONDS = MIN_TWAP_INTERVALS * MIN_TWAP_INTERVAL_DURATION;
const PARTICIPATION_RATE_PRECISION = 1e-4;
const SECONDS_PER_MINUTE = 60;
const SECONDS_PER_HOUR = 60 * 60;
const SECONDS_PER_DAY = 24 * 60 * 60;

const durationUnitOptions = [
  { value: UNIT.MINUTES, text: copyText.durationUnitOptionMinutes },
  { value: UNIT.HOURS, text: copyText.durationUnitOptionHours },
  { value: UNIT.DAYS, text: copyText.durationUnitOptionDays },
];

const percentageOptions = ['25%', '50%', '75%', '100%'];

const unitStr = {
  [UNIT.SECONDS]: 'second',
  [UNIT.MINUTES]: 'minute',
  [UNIT.HOURS]: 'hour',
  [UNIT.DAYS]: 'day',
}

const sorOrderTypes = [
  [OrderType.MARKET, ExpiryType.IOC],
  [OrderType.LIMIT, ExpiryType.IOC],
  [OrderType.LIMIT, ExpiryType.GTC],
  [OrderType.STOP_LIMIT, ExpiryType.GTC],
  [OrderType.STOP_LOSS, ExpiryType.GTC],
].map(([orderType, expiryType]) => ({label: `${orderType !== OrderType.STOP_LOSS ? orderType.replace('_', ' ') : 'STOP MARKET'} - ${expiryType}`, value: {orderType, expiryType}}));

const COMPONENT_NAME = 'AlgoExecutionForm';

// stylelint-disable
const StyledForm = styled(Form)`
  min-width: 280px;

  .${COMPONENT_NAME}-selection {
    min-width: unset !important;

    &.withFixedWidth {
      width: 100px !important;
    }
  }

  .${COMPONENT_NAME}-textInput input {
    text-align: right !important;
  }

  .${COMPONENT_NAME}-multiSelect {
    margin-bottom: 1em;
    min-width: unset !important;

    &.withFixedWidth {
      width: 100px !important;
    }

    input[tabindex='0'] {
      height: 6px;
      padding: 0 8px !important;
      width: 0 !important;
    }
  }

  .${COMPONENT_NAME}-submitButtonWrapper {
    text-align: right;
  }

  .${COMPONENT_NAME}-submitButton {
    margin: 0;
  }

  .${COMPONENT_NAME}-proceedButton {
    margin: 0 0 14px 0;
  }

  .${COMPONENT_NAME}-confirmMessage {
    white-space: normal;
  }

  .${COMPONENT_NAME}-buttonGroup {
    .button {
      background-color: ${colors.white};
      border: 1px solid ${colors.borderColor} !important;
    }

    .button:hover:not(.${COMPONENT_NAME}-selectedButton) {
      background-color: ${colors.blue} !important;
      color: ${colors.white};
      opacity: 0.45;
    }
  }

  .${COMPONENT_NAME}-selectedButton {
    background-color: ${colors.blue} !important;
    color: ${colors.white} !important;
  }

  .${COMPONENT_NAME}-quantityLabel {
    min-width: 4em;
    text-align: center;
  }

  .${COMPONENT_NAME}-quantityButtonWrapper {
    display: flex;
    justify-content: space-between;
    width: 81%;
  }

  .${COMPONENT_NAME}-quantityButton {
    background-color: white !important;
    border: 1px solid ${colors.borderColor} !important;
    color: black !important;
    font-size: 0.75em !important;
    height: 25px !important;
    padding: 1px !important;
    width: 50px !important;

    &.selected {
      background-color: ${colors.blue} !important;
      color: ${colors.white} !important;
    }
  }

  .${COMPONENT_NAME}-inputLabel {
    min-width: 4em;
    text-align: center;
  }

  .${COMPONENT_NAME}-participationRateLabel {
    min-width: 2em;
    text-align: center;
  }

  .${COMPONENT_NAME}-participationRateField {
    height: 38px !important;
  }

  .${COMPONENT_NAME}-checkbox {
    display: flex;
    justify-content: flex-end;
  }

  .${COMPONENT_NAME}-projectedHeader {
    text-align: left !important;
    font-size: 16px !important;
  }

  .${COMPONENT_NAME}-projectedLabel {
    text-align: left;
    justify-content: left;
  }

  .${COMPONENT_NAME}-projectedPriceValue {
    text-align: right;
  }

  .${COMPONENT_NAME}-projectedPriceMessage {
    background-color: ${colors.infoBackgroundColor};
    padding: 8px !important;
  }

  .${COMPONENT_NAME}-projectedPriceTable {
    width: 100%;
  }
  .${COMPONENT_NAME}-projectedWarningIcon {
  }

  .${COMPONENT_NAME}-projectedWarningLabel {
    align-items: bottom;
    font-size: 12px;
    padding-left: 2px;
    padding-top: 5px;
    line-height: 1.2em;
  }

  .${COMPONENT_NAME}-twapIntervalsGroup {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    display: inline;
  }

  .${COMPONENT_NAME}-twapIntervalsCheckbox {
    padding-bottom: 9px;
    padding-right: 0 !important;
  }

  .${COMPONENT_NAME}-twapIntervalsCheckbox label {
    white-space: nowrap;
  }

  .${COMPONENT_NAME}-AllocationAmmount {
    width: 75px;
    text-align: right;
    font-weight: normal;
    font-size: 0.85em;
    color: rgba(0,0,0,0.85);
  }

  .${COMPONENT_NAME}-AllocationSymbol {
    font-size: 0.8em;
    font-weight: lighter;
    color: rgba(0,0,0,0.6);
    padding-left: 2px;
  }

  .${COMPONENT_NAME}-AllocationAmountUnselected {
    width: 75px;
    text-align: right;
    font-weight: normal;
    font-size: 0.85em;
    color: rgba(0,0,0,0.65);
  }

  .${COMPONENT_NAME}-AllocationSymbolUnselected {
    font-size: 0.8em;
    font-weight: lighter;
    color: rgba(0,0,0,0.4);
    padding-left: 2px;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class AlgoExecutionForm extends PureComponent {
  static propTypes = {
    instruments: arrayOf(
      shape({
        id: string.isRequired,
        baseAsset: shape({
          id: string.isRequired,
          symbol: string.isRequired
        }),
        termAsset: shape({
          id: string.isRequired,
          symbol: string.isRequired
        })
      })
    ),
    org: shape({
      enableCustomOrderId: bool,
      enablePTWAPSE: bool,
    }),
    processing: bool,
    recentInstrumentDisplayNames: arrayOf(string),
    selectedMarketDataEntry: shape({
      price: number.isRequired,
      type: string.isRequired,
      cumulativeQuantity: number
    }),
    venues: arrayOf(
      shape({
        id: string.isRequired,
        name: string.isRequired,
        symbol: string.isRequired,
        instrumentConfigurations: arrayOf(
          shape({
            instrument: shape({
              id: string.isRequired,
              displayName: string.isRequired,
              baseAsset: shape({
                id: string.isRequired,
                symbol: string.isRequired
              }).isRequired,
              termAsset: shape({
                id: string.isRequired,
                symbol: string.isRequired
              }).isRequired
            })
          })
        )
      })
    ),
    onChange: func,
    onSubmit: func,
    positionsKeyedByCurrencyId: objectOf(number),
  };

  static defaultProps = {
    instruments: [],
    onChange: noop,
    onSubmit: noop,
    recentInstrumentDisplayNames: [],
  };

  state = {};

  componentDidUpdate() {
    const { venues } = this.props;
    const { instrumentDisplayName } = this.state;
    const venueOptions = getVenueOptions(venues, instrumentDisplayName);
    
    this.props.fetchAccounts(venueOptions);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (isEmpty(prevState)) return getInitialState(nextProps);

    const stateChanges = {};

    if (
      prevState.selectedMarketDataEntry !== nextProps.selectedMarketDataEntry &&
      !prevState.showProceedButton
    ) {
      stateChanges.selectedMarketDataEntry = nextProps.selectedMarketDataEntry;
      stateChanges.limitPrice = get(
        nextProps,
        'selectedMarketDataEntry.price',
        ''
      ).toString();

      stateChanges.side =
        get(
          nextProps,
          'selectedMarketDataEntry.type',
          MarketDataEntryType.OFFER
        ) === MarketDataEntryType.BID
          ? OrderSide.SELL
          : OrderSide.BUY;

      stateChanges.quantity = get(
        nextProps,
        'selectedMarketDataEntry.cumulativeQuantity',
        ''
      ).toString();
    }

    if (
      prevState.showSuccessButton === false &&
      nextProps.showSuccessButton === true
    ) {
      return getInitialState(nextProps, {
        algorithmStrategy: prevState.algorithmStrategy,
        expiryType: prevState.expiryType,
        orderType: prevState.orderType,
        side: prevState.side,
      });
    }

    if (prevState.instrumentDisplayName && !prevState.hasInputChanged) {
      const instrument = getInstrumentFromNameVenue(nextProps.venues, prevState.instrumentDisplayName, 'id', prevState.venueIds?.[0])

      stateChanges.instrumentOption = isEmpty(instrument)
        ? undefined
        : {
            value: prevState.instrumentDisplayName,
            label: prevState.instrumentDisplayName
          };
    }

    if (prevState.venueIds || !prevState.hasInputChanged) {
      const venueOptions = getVenueOptions(nextProps.venues, prevState.instrumentDisplayName);

      stateChanges.venueOptions = appendAllocationsToVenueOptions(venueOptions, nextProps.accountsWithBalancesQueryData?.accounts ?? []).reduce(
        (accum, option) =>
          prevState.venueIds.includes(option.value)
            ? [...accum, option]
            : accum,
        []
      );
    }

    return stateChanges;
  }

  render() {
    const {
      instruments,
      org,
      processing,
      recentInstrumentDisplayNames,
      showSuccessButton,
      venues,
    } = this.props;
  
    const {
      adjustedDuration,
      algorithmDuration,
      algorithmStrategy,
      algorithmStrategyOption,
      customOrderId,
      durationUnit,
      instrumentDisplayName,
      instrumentOption,
      quantity,
      showDurationForm,
      showProceedButton,
      side,
      venueIds,
    } = this.state;

    const venueOptions = appendAllocationsToVenueOptions(getVenueOptions(venues, instrumentDisplayName), this.props.accountsWithBalancesQueryData?.accounts ?? []);
    // Use first venue option as reference to get instrument type
    const isFutures = instrumentIsFutures(getInstrumentFromNameVenue(venues, instrumentDisplayName, 'id', venueOptions?.[0]?.value));

    const nameToInst = mapNameToInst(instruments);
    // Use reference venue to find true instrument
    const referenceVenueId = isFutures ? venueIds?.[0] : venueOptions?.[0]?.value;
    const instrument = nameToInst[instrumentDisplayName]
      ? getInstrumentFromNameVenue(venues, instrumentDisplayName, 'id', referenceVenueId)
      : null;

    const recentInstruments = recentInstrumentDisplayNames.reduce((options, n) => {
      if (n && nameToInst[n]) options.push({ displayName: n });
      return options;
    }, []);
    const groupedInstrumentOptions = getGroupedInstrumentOptions(instruments, recentInstruments);

    // NOTE: this is temporarily hard-coded to Gemini until a more general approach is found
    const onlyGemini = venueIds?.length === 1 && venueIds?.[0] === '7';
    const enablePTWAPSE = org?.enablePTWAPSE && onlyGemini;
    const algoOptions = algorithmStrategyOptions(isFutures, instrumentIsPSwap(instrument), enablePTWAPSE);

    const warningShown = showProceedButton || showDurationForm;
    const warningOrNoInstrument = !instrumentDisplayName || warningShown;

    return (
      <StyledForm
        className={COMPONENT_NAME}
        autoComplete="off"
        warning={warningShown}
        onSubmit={this._handleSubmit.bind(this, instrument)}>
        <FormSelect
          className={cn('selection')}
          name="instrumentDisplayName"
          isDisabled={warningShown}
          options={groupedInstrumentOptions}
          placeholder={copyText.inputPlaceholder_instrumentDisplayName}
          value={instrumentOption}
          onChange={this._handleChangeField}
        />
        {this._renderInstrumentDropdown(!isFutures, venueOptions, warningOrNoInstrument)}
        <Form.Field>
          <Button.Group className={cn('buttonGroup')} fluid size="tiny">
            <Button
              className={side === OrderSide.BUY ? cn('selectedButton') : null}
              disabled={warningShown}
              name="side"
              type="button"
              value={OrderSide.BUY}
              onClick={this._handleChangeField}>
              {copyText.orderSideBuy}
            </Button>
            <Button
              className={side === OrderSide.SELL ? cn('selectedButton') : null}
              disabled={warningShown}
              name="side"
              type="button"
              value={OrderSide.SELL}
              onClick={this._handleChangeField}>
              {copyText.orderSideSell}
            </Button>
          </Button.Group>
        </Form.Field>
        <Form.Field>
          <Input
            className={cn('textInput')}
            name="quantity"
            disabled={warningOrNoInstrument}
            label={
              // <Select
              //   className={cn('selection withFixedWidth')}
              //   name="quantityType"
              //   disabled={this.props.disabled || !this.state.orderType}
              //   options={[
              //     {
              //       value: BASE,
              //       text: get(instrument, 'baseCurrency.symbol')//Note: use getAssetDisplayText if uncommented
              //     },
              //     {
              //       value: TERM,
              //       text: get(instrument, 'termCurrency.symbol')//Note: use getAssetDisplayText if uncommented
              //     }
              //   ]}
              //   value={this.state.quantityType}
              //   onChange={this._handleChangeField}
              // />
              <Label
                className={cn('quantityLabel')}
                basic
                content={isFutures ? copyText.quantityUnitFutures : getAssetDisplayText(get(instrument, 'baseAsset'))}
              />
            }
            labelPosition="right"
            placeholder={copyText.inputPlaceholder_quantity}
            value={quantity}
            onChange={this._handleChangeField}
            onBlur={this._handleFieldBlur('quantity')}
          />
        </Form.Field>
        {!isFutures && (
          <Form.Field
            className={cn('quantityButtonWrapper')}
            disabled={warningShown}>
            {percentageOptions.map(this._renderPercentageOptionButton.bind(this))}
          </Form.Field>
        )}
        <FormSelect
          className={cn('selection')}
          name="algorithmStrategy"
          isDisabled={warningOrNoInstrument}
          options={algoOptions}
          placeholder={copyText.inputPlaceholder_algorithmStrategy}
          value={algorithmStrategyOption}
          onChange={this._handleChangeField}
        />
        {this._renderOrderTypeInput()}
        {this._renderDisplayQuantityInput(instrument)}
        {this._renderStopPriceInput(instrument)}
        {this._renderLimitPriceInput(instrument)}
        {this._renderSorFeesCheckbox()}
        {this._renderAlgorithmDurationInput()}
        {this._renderParticipationRateInput()}
        {this._renderSorIfUnfilledCheckbox()}
        {this._renderOverrideTwapIntervalsInput()}
        {org?.enableCustomOrderId && (
          <Form.Field>
            <Input
              className={cn('textInput')}
              name="customOrderId"
              disabled={warningOrNoInstrument}
              placeholder={copyText.inputPlaceholder_customOrderId}
              value={customOrderId}
              onChange={this._handleChangeField}
            />
          </Form.Field>
        )}
        {this._renderQuantityError()}
        {this._renderFuturesDurationError()}
        {this._renderTwapIntervalsError()}
        {this._renderSorGtcWarning(instrument)}
        {this._renderPreTradeEstimates(instrument)}
        <DurationForm
          visible={showDurationForm}
          strategy={algorithmStrategy}
          duration={adjustedDuration || algorithmDuration}
          unit={durationUnit}
          onShorten={this._handleClickShortenButton}
          onKeepOpen={this._handleClickKeepOpenButton}
          onCancel={this._handleClickCancelButton}
        />
        {showProceedButton ? (
          this._renderProceedForm()
        ) : !showDurationForm && (
          <Form.Field className={cn('submitButtonWrapper')}>
            <Button
              className={cn('submitButton')}
              color={showSuccessButton ? 'green' : 'blue'}
              disabled={(!this._canSave() || processing) && !showSuccessButton}
              fluid
              type="button"
              onClick={this._handleClickSubmitButton}>
              {showSuccessButton ? (
                <span>
                  <Icon name="check" /> {copyText.orderSuccessButtonLabel}
                </span>
              ) : (
                copyText.submitButtonLabel
              )}
            </Button>
          </Form.Field>
        )}
      </StyledForm>
    );
  }

  _renderOrderTypeInput = _ => {
    const { algorithmStrategy, orderType, expiryType, showProceedButton, showDurationForm } = this.state;
    const value = sorOrderTypes.find(ot => ot.value.orderType === orderType && ot.value.expiryType === expiryType)
    return algorithmStrategy === OrderAlgorithmStrategy.SOR && (
      <FormSelect
        className={cn('selection')}
        name="orderType"
        isDisabled={showProceedButton || showDurationForm}
        options={sorOrderTypes}
        placeholder={copyText.inputPlaceholder_orderType}
        value={value}
        onChange={this._handleChangeField}
      />
    )
  }

  _renderSorGtcWarning = instrument => {
    const { algorithmStrategy, expiryType, limitPrice, quantity, side, stopPrice } = this.state;
    const isSorGtc = algorithmStrategy === OrderAlgorithmStrategy.SOR && expiryType === ExpiryType.GTC;
    if (!isSorGtc || parseFloat(quantity) <= 0 || ((parseFloat(limitPrice) <= 0 && parseFloat(stopPrice) <= 0))) return;
    if (and(parseFloat(limitPrice) > 0, parseFloat(stopPrice) > 0, isInvalidStopLimit(side, limitPrice, stopPrice))) {
      return <Message 
        visible 
        negative 
        header={copyText.limitPriceWarningHeader.replace(/%relativeValue%/g, side === OrderSide.SELL ? 'High' : 'Low')}
        content={copyText.limitPriceWarning.replace(/%direction%/, side === OrderSide.SELL ? 'below' : 'above')}>
      </Message>;
    }
    const { projectedPrice: { limitPriceExceeded, stopPriceExceeded } } = this._getSorProjectedPrice(instrument);
    if (stopPriceExceeded) return <Message visible warning header={copyText.sorStopGtcWarningHeader} content={copyText.sorStopGtcWarning} />
    if (limitPriceExceeded) return <Message visible warning header={copyText.sorLimitGtcWarningHeader} content={copyText.sorLimitGtcWarning} />
  }

  _renderAlgorithmDurationInput() {
    return algoHasDuration(this.state.algorithmStrategy) ? (
      <Form.Field>
        <Input
          className={cn('textInput')}
          name="algorithmDuration"
          disabled={!this.state.instrumentDisplayName || this.state.showProceedButton || this.state.showDurationForm}
          label={
            <Dropdown
              className={cn('selection withFixedWidth')}
              name="durationUnit"
              options={durationUnitOptions}
              selection
              value={this.state.durationUnit}
              onChange={this._handleChangeField}
            />
          }
          labelPosition="right"
          placeholder={copyText.inputPlaceholder_algorithmDuration}
          value={this.state.algorithmDuration}
          onChange={this._handleChangeField}
          onBlur={this._handleFieldBlur('algorithmDuration')}
        />
      </Form.Field>
    ) : null;
  }

  _renderDisplayQuantityInput(instrument) {
    return this.state.algorithmStrategy === OrderAlgorithmStrategy.ICE ? (
      <Form.Field>
        <Input
          className={cn('textInput')}
          name="displayQuantity"
          disabled={isEmpty(instrument) || this.state.showProceedButton || this.state.showDurationForm}
          label={
            // <Select
            //   className={cn('selection withFixedWidth')}
            //   name="quantityType"
            //   disabled={this.props.disabled || !this.state.orderType}
            //   options={[
            //     {
            //       value: BASE,
            //       text: get(instrument, 'baseCurrency.symbol')//Note: use getAssetDisplayText if uncommented
            //     },
            //     {
            //       value: TERM,
            //       text: get(instrument, 'termCurrency.symbol')//Note: use getAssetDisplayText if uncommented
            //     }
            //   ]}
            //   value={this.state.quantityType}
            //   onChange={this._handleChangeField}
            // />
            <Label
              className={cn('quantityLabel')}
              basic
              content={instrumentIsFutures(instrument) ? copyText.quantityUnitFutures : getAssetDisplayText(get(instrument, 'baseAsset'))}
            />
          }
          labelPosition="right"
          placeholder={copyText.inputPlaceholder_displayQuantity}
          value={this.state.displayQuantity}
          onChange={this._handleChangeField}
          onBlur={this._handleFieldBlur('displayQuantity')}
        />
      </Form.Field>
    ) : null;
  }

  _renderInstrumentDropdown = (venueDropdownIsMulti, venueOptions, isDisabled = false) => (
    venueDropdownIsMulti
      ? <MultiSelect
          className={cn('multiSelect')}
          name="venueIds"
          allOption={allVenuesOption}
          isDisabled={isDisabled}
          options={venueOptions}
          placeholder={copyText.inputPlaceholder_venueIds}
          value={this.state.venueOptions}
          onChange={this._handleChangeField}
          components={{ Option, MultiValue }}
        />
      : <FormSelect
          className={cn('selection')}
          styles={{ ...getDropdownStyles(), ...dropdownStyles }}
          name="venueIds"
          isDisabled={isDisabled}
          isSearchable={false}
          options={venueOptions}
          placeholder={copyText.inputPlaceholder_venueIds}
          value={this.state.venueOptions}
          components={{ Option: SingleSelectOption, SingleValue }}
          onChange={this._handleChangeField}
        />
  )

  _renderLimitPriceInput = instrument => {
    const { algorithmStrategy, orderType } = this.state;
    const showInput = and(
      !isEmpty(algorithmStrategy),
      algoHasLimitPrice(algorithmStrategy),
      orderType !== OrderType.MARKET,
      orderType !== OrderType.STOP_LOSS
    )
    if (showInput) {
      const { ICE, SOR } = OrderAlgorithmStrategy;
      const optional = ![ICE, SOR].includes(algorithmStrategy);
      const placeholder = optional ? copyText.inputPlaceholder_limitPriceOptional : copyText.inputPlaceholder_limitPrice;

      return (
        <Form.Field>
          <Input
            className={cn('textInput')}
            name="limitPrice"
            disabled={this.state.showProceedButton || this.state.showDurationForm}
            label={
              <Label
                basic
                className={cn('inputLabel')}
                content={getAssetDisplayText(get(instrument, 'termAsset'))}
              />
            }
            labelPosition={'right'}
            placeholder={placeholder}
            value={this.state.limitPrice}
            onChange={this._handleChangeField}
            onBlur={this._handleFieldBlur('limitPrice')}
          />
        </Form.Field>
      );
    }
  }

  _renderStopPriceInput = instrument => {
    const { algorithmStrategy, orderType } = this.state;
    const showInput = and(
      !isEmpty(algorithmStrategy),
      algoHasStopPrice(algorithmStrategy),
      orderType !== OrderType.MARKET,
      orderType !== OrderType.LIMIT
    )
    if (showInput) {
      return (
        <Form.Field>
          <Input
            className={cn('textInput')}
            name="stopPrice"
            disabled={this.state.showProceedButton || this.state.showDurationForm}
            label={
              <Label
                basic
                className={cn('inputLabel')}
                content={getAssetDisplayText(get(instrument, 'termAsset'))}
              />
            }
            labelPosition={'right'}
            placeholder={copyText.inputPlaceholder_stopLossPrice}
            value={this.state.stopPrice}
            onChange={this._handleChangeField}
            onBlur={this._handleFieldBlur('stopPrice')}
          />
        </Form.Field>
      );
    }
  }

  _renderParticipationRateInput() {
    return (this.state.algorithmStrategy === OrderAlgorithmStrategy.POV) && (
    <Form.Field className={cn('participationRateField')}>
      <Input
        style={{float: 'left', width: '17em'}}
        className={cn('textInput')}
        name="participationRate"
        disabled={this.state.showProceedButton || this.state.showDurationForm}
        label={
          <Label
            basic
            className={cn('participationRateLabel')}
            content={'%'}
          />
        }
        labelPosition={'right'}
        placeholder={'% of Volume'}
        value={this.state.participationRate}
        onChange={this._handleChangeField}
      />
      <span style={{float: 'right', marginRight: '0.6em', marginTop: '0.6em'}}>
        <Popup
          hoverable
          hideOnScroll
          inverted
          wide
          position='top center'
          style={{fontSize: '1.1em'}}
          content={copyText.participationRatePopup}
          trigger={<Icon link name="question circle outline" />}
        />
      </span>
    </Form.Field>
    );
  }

  _renderSorFeesCheckbox() {
    return OrderAlgorithmStrategy.SOR === this.state.algorithmStrategy ? (
      <Form.Field className={cn('checkbox')}>
        <Checkbox
          name="sorFees"
          disabled={this.state.showProceedButton || this.state.showDurationForm}
          checked={this.state.includeSorFees}
          label={copyText.includeFeesLabel}
          onChange={this._handleChangeSorFeesCheckbox}
        />
      </Form.Field>
    ) : null;
  }

  _renderPreTradeEstimates(instrument) {
    const { 
      adjustedDuration, 
      algorithmDuration, 
      algorithmStrategy, 
      durationUnit, 
      includeSorFees, 
      limitPrice, 
      orderType, 
      overrideTwapIntervals, 
      participationRate, 
      quantity,
      side, 
      stopPrice,
      twapIntervals, 
      venueIds, 
    } = this.state;

    if (OrderAlgorithmStrategy.SOR === algorithmStrategy)  {
      const { projectedPriceArgs } = this._getSorProjectedPrice(instrument);
      const isValidLimit = limitPrice || (orderType !== OrderType.LIMIT && orderType !== OrderType.STOP_LIMIT);
      const isValidStop = stopPrice || (orderType !== OrderType.STOP_LOSS && orderType !== OrderType.STOP_LIMIT);
      const isValidStopLimit = !limitPrice || !stopPrice || !isInvalidStopLimit(side, limitPrice, stopPrice);
      return ( isValidLimit && 
               isValidStop &&
               isValidStopLimit &&
        <Form.Field>
          <ProjectedPriceMessage {...projectedPriceArgs} isSor={true} includeSorFees={includeSorFees} />
        </Form.Field>
      );
    }

    if (OrderAlgorithmStrategy.POV === algorithmStrategy) {
      return (
        <Form.Field>
          <PreTradeEstimates
            instrument={instrument}
            venueIds={venueIds}
            participationRate={parseFloat(participationRate)}
            quantity={parseFloat(quantity)}
          />
        </Form.Field>
      )
    }

    if (!algoHasDuration(algorithmStrategy)) return null;

    const duration = convertTime(parseInt(algorithmDuration),  durationUnit, UNIT.SECONDS);

    const adjusted = adjustedDuration 
                      ? parseInt(adjustedDuration) 
                      : algorithmStrategy === OrderAlgorithmStrategy.PTWAP && !overrideTwapIntervals && duration > (50 * SECONDS_PER_MINUTE)
                          ? Math.ceil(duration / (5 * SECONDS_PER_MINUTE)) * (5 * SECONDS_PER_MINUTE)
                          : null;

    return (
      <Form.Field>
        <PreTradeEstimates
          adjustedDuration={adjusted}
          algorithmStrategy={algorithmStrategy}
          duration={duration}
          instrument={instrument}
          isPostingInterval={algoHasPostingIntervals(algorithmStrategy)}
          venueIds={venueIds}
          quantity={parseFloat(quantity)}
          twapIntervals={overrideTwapIntervals && isInt(twapIntervals) ? parseInt(twapIntervals) : getDefaultPTWAPInterval(algorithmStrategy, duration)}
        />
      </Form.Field>
    )
  }

  _renderSorIfUnfilledCheckbox() {
    return OrderAlgorithmStrategy.PEGGER === this.state.algorithmStrategy ? (
      <Form.Field className={cn('checkbox')}>
        <Checkbox
          name="sorIfUnfilled"
          disabled={this.state.showProceedButton || this.state.showDurationForm}
          checked={this.state.executeSorIfUnfilled}
          label={copyText.executeSorIfUnfilledLabel}
          onChange={this._handleChangeSorIfUnfilledCheckbox}
        />
      </Form.Field>
    ) : null;
  }

  _renderOverrideTwapIntervalsInput() {
    if (!algoHasIntervals(this.state.algorithmStrategy)) return null;

    const checkboxLabel = this.state.algorithmStrategy === OrderAlgorithmStrategy.PTWAP ? copyText.overridePTwapIntervalsLabel : copyText.overrideTwapIntervalsLabel;
    const checkbox = (
      <Form.Field className={cn('twapIntervalsCheckbox')}>
        <Checkbox
          name="overrideTwapIntervals"
          disabled={this.state.showProceedButton || this.state.showDurationForm}
          checked={this.state.overrideTwapIntervals}
          label={checkboxLabel}
          onChange={this._handleChangeOverrideTwapIntervalsCheckbox}
        />
      </Form.Field>
    );
    const checkboxWithPopup = (
      <Popup
        inverted
        wide="very"
        hideOnScroll
        content={copyText.overrideTwapIntervalsTooltip}
        trigger={checkbox}
      />
    );

    return (
      <Form.Group className={cn('twapIntervalsGroup')}>
        { this.state.algorithmStrategy === OrderAlgorithmStrategy.TWAP ? checkboxWithPopup : checkbox}
        <Form.Field width={5}>
          <Input
            name="twapIntervals"
            disabled={!this.state.overrideTwapIntervals}
            value={this.state.twapIntervals}
            onChange={this._handleChangeField}
            onBlur={this._handleFieldBlur('twapIntervals')}
          />
        </Form.Field>
      </Form.Group>
    );
  }

  _renderProceedForm() {
    const {
      basePositionQuantity,
      currentPrices,
      entriesGroupedByType,
      marginPositionQuantity,
      termPositionQuantity,
      processing,
      venues
    } = this.props;

    const {
      algorithmStrategy,
      instrumentDisplayName,
      limitPrice,
      quantity,
      side,
      stopPrice,
      venueIds,
      venueOptions
    } = this.state;

    const instrument = getInstrumentFromNameVenue(venues, instrumentDisplayName, 'id', venueIds?.[0]);

    return (
      <>
        <ProceedMessage
          algoOrder={true}
          algorithmStrategy={algorithmStrategy}
          basePositionQuantity={basePositionQuantity}
          currentPrices={currentPrices}
          entriesGroupedByType={entriesGroupedByType}
          instrument={instrument}
          limitPrice={limitPrice}
          marginPositionQuantity={marginPositionQuantity}
          termPositionQuantity={termPositionQuantity}
          projectedPrice={this._getSorProjectedPrice(instrument).projectedPrice}
          quantity={quantity}
          side={side}
          stopPrice={stopPrice}
          visible={this._canSave()}
          venueOptions={venueOptions}
           />
        <Form.Field>
          <Button
            className={cn('proceedButton')}
            color="orange"
            disabled={!this._canSave() || processing}
            fluid
            loading={processing}
            type="submit">
            {copyText.proceedButtonLabel}
          </Button>
        </Form.Field>
        <Form.Field>
          <Button
            fluid
            onClick={this._handleClickCancelButton}
            type="reset">
            {copyText.cancelButtonLabel}
          </Button>
        </Form.Field>
      </>
    )
  }

  _renderQuantityError() {
    const { instrumentDisplayName, quantity, side, venueIds } = this.state;
    const { basePositionQuantity, venues } = this.props;
    const instrument = getInstrumentFromNameVenue(venues, instrumentDisplayName, 'id', venueIds?.[0]);
    const qty = parseFloat(quantity) || 0;
    const sellingMoreThanHeld = get(instrument, 'mdInstrument.id') && side === OrderSide.SELL && qty > basePositionQuantity;

    if (sellingMoreThanHeld) {
      const content = copyText.quantityError
        .replace(/%basePositionQuantity%/g, `${getDisplayFormattedNum(basePositionQuantity)}`)
        .replace(/%instrument%/g, `${instrument.displayName}`)
        .replace(/%unit%/g, `${pluralize(copyText.contractsLabel, qty)}`)
        .replace(/%quantity%/g, `${getDisplayFormattedNum(qty)}`);

      return <Message negative header={copyText.quantityExceedsHoldings} content={content} />
    }
  }

  _renderFuturesDurationError() {
    if (!isNil(this.state.futuresDurationError)) {
      return <Message negative header={copyText.durationPastExpiration} content={this.state.futuresDurationError} />
    }
  }

  _renderTwapIntervalsError() {
    if (algoHasIntervals(this.state.algorithmStrategy) && !isNil(this.state.twapIntervalsError)) {
      return (
        <Message negative header={'Invalid Interval Parameters'} content={this._getTwapIntervalsError()} />
      )
    }
  }

  _renderPercentageOptionButton(percentageOption) {
    return (
      <Button
        key={percentageOption}
        className={cn(`quantityButton ${this.state.percentageOption === percentageOption ? 'selected' : ''}`)}
        name="percentageOption"
        disabled={this.state.disabled || !this.state.instrumentDisplayName || isEmpty(this.state?.venueIds)}
        size="mini"
        type="button"
        value={percentageOption}
        onClick={this._handleChangeField}>
        {percentageOption}
      </Button>
    );
  }

  _canSave() {
    let isInvalidAlgoDuration,
      isInvalidDisplayQuantity,
      isInvalidLimitPrice,
      isInvalidParticipationRate,
      isInvalidStopPrice,
      isInvalidStopLimitPrice,
      isInvalidTwapIntervals = false;

    const {
      adjustedDuration,
      algorithmDuration,
      algorithmStrategy,
      displayQuantity,
      durationUnit,
      futuresDurationError,
      instrumentDisplayName,
      limitPrice,
      orderType,
      overrideTwapIntervals,
      participationRate,
      quantity,
      side,
      stopPrice,
      twapIntervals,
      venueIds,
    } = this.state;

    const { ICE, POV, PTWAPSE, SOR } = OrderAlgorithmStrategy;

    if (algoHasDuration(algorithmStrategy)) {
      const durationInSeconds = adjustedDuration
        ? parseInt(adjustedDuration)
        : convertTime(algorithmDuration|0, (durationUnit || UNIT.MINUTES), UNIT.SECONDS);
      isInvalidAlgoDuration = [
        !isInt(adjustedDuration || algorithmDuration),
        ((adjustedDuration || algorithmDuration)|0) < 0,
        !isNil(futuresDurationError),
        algoHasIntervals(algorithmStrategy) && durationInSeconds < 100,
      ].some(Boolean);
    }

    if (algoHasLimitPrice(algorithmStrategy)) {
      isInvalidLimitPrice =
        (algorithmStrategy === ICE && (isEmpty(limitPrice) || parseFloat(limitPrice) < 0)) ||
        (!isEmpty(limitPrice) && parseFloat(limitPrice) < 0);
    }

    if (algoHasStopPrice(algorithmStrategy)) {
      isInvalidStopPrice = !isEmpty(stopPrice) && parseFloat(stopPrice) < 0;
    }

    if (algorithmStrategy === ICE) {
      isInvalidDisplayQuantity =
        !isFloat(displayQuantity) ||
        parseFloat(displayQuantity) < 0 ||
        parseFloat(displayQuantity) > MAX_NUMERIC_VALUE ||
        parseFloat(quantity) < parseFloat(displayQuantity);
    }

    if (algorithmStrategy === POV) {
      isInvalidParticipationRate =
        !isFloat(participationRate) ||
        parseFloat(participationRate) < MIN_PERCENT ||
        parseFloat(participationRate) > MAX_PERCENT;
    }

    if (algorithmStrategy === SOR && (orderType === OrderType.LIMIT || orderType === OrderType.STOP_LIMIT)) {
      isInvalidLimitPrice = !(parseFloat(limitPrice) > 0);
    }
    if (algorithmStrategy === SOR && (orderType === OrderType.STOP_LOSS || orderType === OrderType.STOP_LIMIT)) {
      isInvalidStopPrice = !(parseFloat(stopPrice) > 0);
    }

    if (orderType === OrderType.STOP_LIMIT) {
      isInvalidStopLimitPrice = isInvalidStopLimit(side, limitPrice, stopPrice);
    }

    if (
        (algoHasIntervals(algorithmStrategy) && overrideTwapIntervals) ||
        algorithmStrategy === PTWAPSE
      ) {
      const intervals = parseInt(twapIntervals);
      isInvalidTwapIntervals =
        isNaN(intervals) ||
        intervals < 10 ||
        intervals > this._getTwapMaxIntervals();
    }

    return (
      !isEmpty(instrumentDisplayName) &&
      venueIds.length > 0 &&
      isFloat(quantity) &&
      parseFloat(quantity) > 0 &&
      parseFloat(quantity) < MAX_NUMERIC_VALUE &&
      Object.values(OrderAlgorithmStrategy).includes(algorithmStrategy) &&
      !isInvalidAlgoDuration &&
      !isInvalidDisplayQuantity &&
      !isInvalidLimitPrice &&
      !isInvalidParticipationRate &&
      !isInvalidStopPrice &&
      !isInvalidStopLimitPrice &&
      !isInvalidTwapIntervals
    );
  }

  _getSorProjectedPrice = instrument => {
    const { expiryType, limitPrice, orderType, quantity, side, stopPrice, venueIds } = this.state;

    const accounts = get(this.props, 'accountsWithBalancesQueryData.accounts', []);

    const entries = this.props.entriesGroupedByType[side === OrderSide.BUY ? MarketDataEntryType.OFFER : MarketDataEntryType.BID];
    const venueSymbols = getVenuesWithInstrument(this.props.venues, instrument.displayName).map(v => v.symbol);
    const venueOptions = keyBy(this.props.venues.filter(v => venueSymbols.includes(v.symbol)), 'id');

    const projectedPriceArgs = {
      accounts,
      entries,
      gtc: expiryType === ExpiryType.GTC,
      instrument,
      limitPrice,
      orderType,
      qtyToFill: quantity,
      side,
      stopPrice,
      venueIds,
      venueOptions,
    };

    return {
      projectedPrice: getProjectedPrice(projectedPriceArgs) || {},
      projectedPriceArgs,
    };
  }

  _getTwapIntervalsError = () => {
    if(this.state.twapIntervalsError === 'minIntervals') return copyText.twapIntervalsMinimumError;
    else if(this.state.twapIntervalsError === 'minTime') return copyText.twapTimeMinimumError;
    else {
      const { algorithmDuration, durationUnit } = this.state;
      return copyText.twapIntervalsFrequencyError
        .replace('%algoDuration%', `${getDisplayFormattedNum(parseInt(algorithmDuration))} ${pluralize(unitStr[durationUnit], parseInt(algorithmDuration || 0))}`)
        .replace('%maxIntervals%', `${getDisplayFormattedNum(parseInt(this._getTwapMaxIntervals()))}`)
    }
  };

  _validateTwapIntervals = () => {
    const { adjustedDuration, algorithmDuration, algorithmStrategy, durationUnit, twapIntervals } = this.state;

    if (!algoHasIntervals(algorithmStrategy) || !isInt(adjustedDuration || algorithmDuration)) return;

    const intervals = parseInt(twapIntervals);
    const durationInSec = adjustedDuration
      ? parseInt(adjustedDuration)
      : convertTime(parseInt(algorithmDuration), durationUnit, UNIT.SECONDS)
    const twapIntervalsError = durationInSec < MIN_TWAP_SECONDS
      ? 'minTime'
      : intervals < MIN_TWAP_INTERVALS
      ? 'minIntervals'
      : intervals > this._getTwapMaxIntervals()
      ? 'freq'
      : null;

    if (this.state.twapIntervalsError !== twapIntervalsError) this.setState({ twapIntervalsError });
  };

  _getTwapMaxIntervals = () => {
    const { adjustedDuration, algorithmDuration, durationUnit } = this.state;

    if (isNaN(parseFloat(adjustedDuration || algorithmDuration))) return 0;

    const durationInSec = adjustedDuration
      ? parseInt(adjustedDuration)
      : convertTime(parseInt(algorithmDuration), durationUnit, UNIT.SECONDS)
    const maxIntervals = Math.max(MIN_TWAP_INTERVALS, Math.floor(durationInSec / MIN_TWAP_INTERVAL_DURATION)); // max of 1 execution per 10 seconds, limit never lower than 10

    return maxIntervals;
  };

  _validateFuturesDuration = () => {
    const { algorithmDuration, algorithmStrategy, durationUnit, instrumentDisplayName, venueIds } = this.state;
    const { venues } = this.props;

    const instrument = getInstrumentFromNameVenue(venues, instrumentDisplayName, 'id', venueIds?.[0]);

    const durationPastExpiration = algoHasDuration(algorithmStrategy) && instrumentIsFutures(instrument)
      && !instrumentIsPSwap(instrument) && isAfterExpiry(algorithmDuration, durationUnit, instrument.baseAsset.expiry);

    let futuresDurationError = null;
    if (durationPastExpiration) {
      let unit = durationUnit;
      let maxDuration = Math.trunc(getTimeToExpiry(instrument.baseAsset.expiry, unit));
      if (maxDuration === 0) {
        const units = durationUnitOptions.map(u => u.value);
        for (let i = units.indexOf(unit) - 1; i >= 0 && maxDuration === 0; i--) {
          unit = units[i];
          maxDuration = Math.trunc(getTimeToExpiry(instrument.baseAsset.expiry, unit));
        }
      }
      futuresDurationError = copyText.futuresDurationError
        .replace(/%maxDuration%/g, `${maxDuration}`)
        .replace(/%durationUnit%/g, `${pluralize(unitStr[unit], maxDuration)}`);
    }

    if (this.state.futuresDurationError !== futuresDurationError) this.setState({ futuresDurationError });
  }

  _handleChangeField = (field, event) => {
    let { value } = field;
    const { name } = event;

    if (has(event, 'value')) value = event.value;
    if (Array.isArray(field)) value = field.map(i => i.value);

    const change = { [name]: value };
    let changes = change;

    const validator = validators[name];
    if (validator && !validator.test(change[name])) return;

    if (has(change, 'orderType')) {
      const { orderType, expiryType } = value;
      changes = { orderType, expiryType };
      if (orderType === OrderType.MARKET || orderType === OrderType.STOP_LOSS) changes.limitPrice = '';
      if (orderType === OrderType.MARKET || orderType === OrderType.LIMIT) changes.stopPrice = '';
    }

    if (has(change, 'quantity') || has(change, 'side')) changes = { ...changes, percentageOption: '' };

    if (has(change, 'algorithmStrategy')) changes = {
      ...changes,
      adjustedDuration: null,
      algorithmDuration: '',
      displayQuantity: '',
      expiryType: ExpiryType.GTC,
      futuresDurationError: null,
      limitPrice: '',
      orderType: OrderType.LIMIT,
      participationRate: '',
      algorithmStrategyOption: field,
      overrideTwapIntervals: false,
      stopPrice: '',
      twapIntervalsError: null,
      twapIntervals: '',
    };

    if (has(change, 'instrumentDisplayName')) {
      const venueOptions = appendAllocationsToVenueOptions(getVenueOptions(this.props.venues, change.instrumentDisplayName), this.props.accountsWithBalancesQueryData?.accounts ?? []);
      let venueIds = venueOptions.map(option => option.value);
      const instrument = getInstrumentFromNameVenue(this.props.venues, change.instrumentDisplayName, 'id', venueIds?.[0]);
      if (instrument.type !== InstrumentType.CURRENCY) venueIds = [venueIds[0]];

      changes = {
        ...changes,
        adjustedDuration: null,
        algorithmDuration: '',
        algorithmStrategy: '',
        algorithmStrategyOption: '',
        displayQuantity: '',
        expiryType: ExpiryType.GTC,
        futuresDurationError: null,
        instrumentOption: field,
        limitPrice: '',
        orderType: OrderType.LIMIT,
        participationRate: '',
        quantity: '',
        quantityType: DEFAULT_QUANTITY_TYPE,
        stopPrice: '',
        twapIntervals: '',
        venueIds,
        venueOptions,
      };
    }

    if (has(change, 'venueIds')) {
      changes = { ...changes, algorithmStrategy: '', algorithmStrategyOption: null };

      // NOTE: this block is necessary to accommodate the fact that the dropdown component
      // used in the form changes depending on whether the selected instrument is a derivative
      if (typeof change.venueIds === 'string') {
        const instrument = getInstrumentFromNameVenue(this.props.venues, this.state.instrumentDisplayName, 'id', change.venueIds);
        if (instrument?.type !== InstrumentType.CURRENCY) changes = { ...changes, venueIds: [change.venueIds] };
      }
    }

    if (has(change, 'percentageOption')) {
      let quantity;
      const percentageOption = parseInt(change.percentageOption) * 0.01;
      const baseQty = this.props.basePositionQuantity;
      const termQty = this.props.termPositionQuantity;

      if (this.state.side === OrderSide.SELL) {
        quantity = percentageOption * baseQty;
      } else if (this.state.side === OrderSide.BUY) {
        const marketPrice = calculateMarketPrice(this.props.entriesGroupedByType);
        quantity = (percentageOption * termQty) / (marketPrice === 0 ? 1 : marketPrice);
      }

      quantity = Math.max(quantity || 0, 0); // make sure quantity is a non-negative number

      if (isFinite(quantity)) changes = { ...changes, quantity: simplifyFloatString(quantity.toFixed(8)) };
    }

    if (this.state.algorithmStrategy === OrderAlgorithmStrategy.PTWAPSE) {
      if (has(change, 'algorithmDuration')) {
        if (!isInt(change.algorithmDuration)) {
          changes = {
            ...changes,
            adjustedDuration: null,
            twapIntervals: ''
          }
        } else if (!this.state.overrideTwapIntervals || !isInt(this.state.twapIntervals)) {
          let [intervalLength, intervalNum] = defaultIntervals(change.algorithmDuration, this.state.durationUnit || UNIT.MINUTES)

          const adjustedDuration = (intervalLength * intervalNum).toString()

          changes = {
            ...changes,
            adjustedDuration: parseInt(adjustedDuration) !== convertTime(parseInt(change.algorithmDuration), this.state.durationUnit || UNIT.MINUTES, UNIT.SECONDS) ? adjustedDuration : null,
            twapIntervals: intervalNum.toString()
          }
        }
      }
  
      if (has(change, 'durationUnit') && !this.state.overrideTwapIntervals && isInt(this.state.algorithmDuration)) {
        let [intervalLength, intervalNum] = defaultIntervals(this.state.algorithmDuration, change.durationUnit || UNIT.MINUTES)

        const adjustedDuration = (intervalLength * intervalNum).toString()
        changes = {
          ...changes,
          adjustedDuration: parseInt(adjustedDuration) !== convertTime(parseInt(this.state.algorithmDuration), change.durationUnit || UNIT.MINUTES, UNIT.SECONDS) ? adjustedDuration : null,
          twapIntervals: intervalNum.toString()
        }
      }
    }

    changes.hasInputChanged = true;

    this.setState(changes, _ => {
      this.props.onChange(changes);
      if (['algorithmDuration', 'durationUnit'].includes(name)) this._validateFuturesDuration();
      if (['algorithmDuration', 'twapIntervals', 'durationUnit'].includes(name)) this._validateTwapIntervals();
    });
  };

  _handleChangeSorFeesCheckbox = _ => {
    const includeSorFees = !this.state.includeSorFees;
    this.setState({ includeSorFees }, _ => this.props.onChange({ includeSorFees }));
  }

  _handleChangeSorIfUnfilledCheckbox = _ => this.setState({ executeSorIfUnfilled: !this.state.executeSorIfUnfilled })

  _handleChangeOverrideTwapIntervalsCheckbox = _ => {
    let changes = {
      overrideTwapIntervals: !this.state.overrideTwapIntervals,
    }

    if (this.state.algorithmStrategy === OrderAlgorithmStrategy.PTWAPSE) {
      if (changes.overrideTwapIntervals) {
        changes = {
          ...changes,
          adjustedDuration: null
        }
      } else if (isInt(this.state.algorithmDuration)) {
        let [intervalLength, intervalNum] = defaultIntervals(this.state.algorithmDuration, this.state.durationUnit || UNIT.MINUTES)
      
        const adjustedDuration = (intervalLength * intervalNum).toString()

        changes = {
          ...changes,
          adjustedDuration: parseInt(adjustedDuration) !== convertTime(parseInt(this.state.algorithmDuration), this.state.durationUnit || UNIT.MINUTES, UNIT.SECONDS) ? adjustedDuration : null,
          twapIntervals: intervalNum.toString()
        }
      }
    }

    this.setState(changes, this._validateTwapIntervals)
  }

  _handleClickKeepOpenButton = event => {
    event.preventDefault();
    this.setState({ showDurationForm: false, showProceedButton: true });
  }

  _handleClickShortenButton = event => {
    event.preventDefault();
    this.setState({ shortenOnSubmit: true, showDurationForm: false, showProceedButton: true });
  }

  _handleClickSubmitButton = event => {
    event.preventDefault();
    const showDurationForm = !isEmpty(this.state.algorithmDuration) && isAfterMaintenanceStart(this.state.algorithmDuration, this.state.durationUnit);
    this.setState({ showDurationForm, showProceedButton: !showDurationForm });
  };

  _handleClickCancelButton = event => {
    event.preventDefault();
    this.setState({ shortenOnSubmit: false, showDurationForm: false, showProceedButton: false });
  };

  _handleFieldBlur = field => _ => this.setState({ [field]: simplifyFloatString(this.state[field]) })

  _handleSubmit = (instrument, event) => {
    event.preventDefault();

    const {
      adjustedDuration,
      algorithmDuration,
      algorithmStrategy,
      displayQuantity,
      durationUnit,
      executeSorIfUnfilled,
      expiryType,
      includeSorFees,
      limitPrice,
      orderType,
      overrideTwapIntervals,
      participationRate,
      quantity,
      quantityType,
      shortenOnSubmit,
      side,
      stopPrice,
      twapIntervals,
      venueIds,
    } = this.state;

    const { ICE, PEGGER, POV, PTWAP, PTWAPSE, SOR, TWAP } = OrderAlgorithmStrategy;

    const limit = parseFloat(limitPrice) || 0;
    const stop = parseFloat(stopPrice) || 0;

    const duration = adjustedDuration
      ? parseInt(adjustedDuration)
      : convertTime(parseInt(algorithmDuration) || 0, durationUnit || UNIT.MINUTES, UNIT.SECONDS);
    const expireTime = (getMaintenanceStartDate().getTime()/1000)|0;

    const specifiedCurrencyId = quantityType === BASE ? get(instrument, 'baseAsset.id') : get(instrument, 'termAsset.id');
    const instrumentId = get(instrument, 'id')

    const algoParams = {
      [ICE]: { displayQuantity: parseFloat(displayQuantity) },
      [PEGGER]: { executeSorIfUnfilled: executeSorIfUnfilled},
      [POV]: { participationRate: truncateFloat(participationRate/100) },
      [PTWAP]: overrideTwapIntervals && isInt(twapIntervals) ? { intervals: Math.floor(duration / (twapIntervals)) } : {},
      [PTWAPSE]: { intervals: twapIntervals|0 },
      [SOR]: { includeSorFees },
      [TWAP]: overrideTwapIntervals ? { intervals: twapIntervals|0 } : {},
    }[algorithmStrategy] || {};

    if (algoHasLimitPrice(algorithmStrategy) && limit > 0) algoParams.limitPrice = limit;
    if (algoHasStopPrice(algorithmStrategy) && stop > 0) algoParams.stopPrice = stop;
    if (algoHasDuration(algorithmStrategy)) {
      if (shortenOnSubmit) algoParams.expireTime = expireTime;
      else algoParams.algorithmDuration = duration;
    }

    const order = {
      algorithmStrategy,
      customOrderId: this.state.customOrderId,
      expiryType: expiryType,
      instrumentId,
      orderType: orderType,
      side,
      specifiedCurrencyId,
      specifiedQuantity: parseFloat(quantity),
      venueIds: venueIds.length > 0 ? venueIds : null,
      ...algoParams,
    };

    this.props.onSubmit(order);
  };
}

const getInitialState = (props, overrides = {}) => ({
  adjustedDuration: null,
  algorithmDuration: '',
  algorithmStrategy: '',
  customOrderId: '',
  executeSorIfUnfilled: false,
  expiryType: ExpiryType.GTC,
  displayQuantity: '',
  durationUnit: DEFAULT_DURATION_UNIT,
  futuresDurationError: null,
  includeSorFees: false,
  instrumentDisplayName: props.selectedInstrumentDisplayName,
  limitPrice: '',
  orderType: OrderType.LIMIT,
  overrideTwapIntervals: false,
  participationRate: '',
  quantity: '',
  quantityType: DEFAULT_QUANTITY_TYPE,
  selectedMarketDataEntry: props.selectedMarketDataEntry,
  shortenOnSubmit: false,
  showDurationForm: false,
  showProceedButton: false,
  showSuccessButton: false,
  side: OrderSide.BUY,
  stopPrice: '',
  twapIntervals: '',
  twapIntervalsError: null,
  venueIds: props.selectedVenueIds,
  ...overrides,
})

const float = /^\d*\.?\d{0,8}$/;
const int = /^\d*$/;
const validators = {
  quantity: float,
  displayQuantity: float,
  adjustedDuration: int,
  algorithmDuration: int,
  limitPrice: float,
  participationRate: /^\d*\.?\d{0,2}$/,
  stopPrice: float,
  twapIntervals: int,
};

const isInvalidStopLimit = (side, lp, sp) => side === OrderSide.SELL ? parseFloat(lp) > parseFloat(sp) : parseFloat(sp) > parseFloat(lp)

const truncateFloat = (value, precision = PARTICIPATION_RATE_PRECISION) => {
  precision = precision > 0 ? precision : Math.pow(10, -DEFAULT_DECIMALS)
  return Decimal.div(parseFloat(value) || 0, precision).trunc().mul(precision).toNumber()
}

const simplifyFloatString = num => isFloat(num)
  ? parseFloat(num).toString()
  : num === '.' //Copy behavior of DMA Execution Form
  ? '0'
  : '';

const mapNameToInst = createSelector(i => i, i => keyBy(i, 'displayName'))

const Allocation = ({ asset, isSelected=true, disablePopup=false }) => {
  if(!asset?.quantityLabel || !asset?.symbol) return null;

  const { quantityLabel: label, symbol, quantity: value, type } = asset;
  const hasOverflow = symbol.slice(5) !== '';
  const symbolWithOverflow = hasOverflow ? symbol.slice(0, 5) + '...' : symbol;

  const allocationAmmountClassName = isSelected ? 'AllocationAmmount' : 'AllocationAmountUnselected';
  const allocationSymbolClassName = isSelected ? 'AllocationSymbol' : 'AllocationSymbolUnselected';

  const format = getNumberFormat(type);
  const minSignificantDecimals = type === AssetType.FIAT ? 2 : 1;

  return (
    !disablePopup 
      ? <Popup
          flowing
          hideOnScroll
          hoverable
          inverted
          position="bottom center"
          style={popupStyles.container}
          content={`${formatNumberWithoutTrailingZeroes(value, format, minSignificantDecimals)} ${symbol}`}
          trigger={
            <span className={cn(allocationAmmountClassName)}>
              {label}<span className={cn(allocationSymbolClassName)}>{symbolWithOverflow}</span>
            </span>
          }
          verticalOffset={10} 
        /> 
      : <span className={cn(allocationAmmountClassName)}>
          {label}<span className={cn(allocationSymbolClassName)}>{symbolWithOverflow}</span>
        </span>
  )
}

const Option = props => {
  const { data, selectProps, label } = props;
  const value = selectProps?.value ?? [];
  const options = selectProps?.options;
  const allSelected = value.length === getUngroupedOptions(options).length;
  const isSelected = props.isSelected || allSelected;
  const { baseAsset, termAsset } = data;

  return (
    <div>
      <components.Option {...props}>
        <div style={{ display: 'flex'}}>
          <input
              type="checkbox"
              checked={isSelected}
              onChange={() => null}
            />
            <label style={{ flexGrow: 1 }}>{label}</label>
            <Allocation 
              asset={baseAsset}
              isSelected={isSelected} 
            />
            <Allocation 
              asset={termAsset}
              isSelected={isSelected} 
            /> 
        </div>
      </components.Option>
    </div>
  );
};

const SingleValueLabel = ({ label, baseAsset, termAsset, ...props }) => (
  <div style={{ display: 'flex', paddingRight: 5 }}>
    <label style={{ flexGrow: 1 }}>{label}</label>
    <Allocation 
      asset={baseAsset}
      {...props}
    />
    <Allocation 
      asset={termAsset}
      {...props}
    /> 
  </div>
)


const SingleSelectOption = props => {
  const { data, label } = props;
  const { baseAsset, termAsset } = data;
  return (
    <components.Option {...props}>
      <SingleValueLabel 
        label={label} 
        baseAsset={baseAsset}
        termAsset={termAsset}
      />
    </components.Option>
  );
}

const MultiValue = props => {
  const { data, selectProps, options } = props;
  const value = selectProps?.value ?? [];
  const index = value.indexOf(data);

  // Only create MultiValue component for 0th value
  if (index) return null;

  const allSelected = value.length === getUngroupedOptions(options).length;

  const allLabel = p => get(get(p, 'options', []).find(o => o.value === '*'), 'label', 'All Items');
  
  let { baseAsset, termAsset } = value.reduce((accum, v) => {
    accum.baseAsset = { ...v.baseAsset, quantity: accum.baseAsset.quantity + (v.baseAsset?.quantity ?? 0) };
    accum.termAsset = { ...v.termAsset, quantity: accum.termAsset.quantity + (v.termAsset?.quantity ?? 0) };

    return accum;
  }, { baseAsset: { quantity: 0 }, termAsset: { quantity: 0 }});

  baseAsset.quantityLabel = allocationFormatter(baseAsset, getAllocationScale(baseAsset.quantity));
  termAsset.quantityLabel = allocationFormatter(termAsset, getAllocationScale(termAsset.quantity));

  const label = value.length === 1
    ? data?.label ?? 'N/A'
    : allSelected
      ? `${allLabel(props)} (${value.length})`
      : `${value.length} ${allLabel(props).split(' ')[1] || 'Items'}`

  const menuOpen = get(props, 'selectProps.menuIsOpen', false);

  return (
    <components.SingleValue {...props}>
      {value.length > 1 && !menuOpen
        ? <Popup
            flowing
            hideOnScroll
            hoverable
            inverted
            position="bottom center"
            style={popupStyles.container}
            trigger={
              <div>
                <SingleValueLabel 
                  label={label} 
                  baseAsset={baseAsset}
                  termAsset={termAsset}
                  disablePopup={true}
                />
              </div>
            }
            verticalOffset={10}>
            <div style={popupStyles.info}>
              {value
                .filter(v => v.value !== '*')
                .sort(v => v.label)
                .map((v, i) => <div key={i}>{v.label}</div>)
              }
            </div>
          </Popup>
        : <SingleValueLabel 
            label={label} 
            baseAsset={baseAsset}
            termAsset={termAsset}
          />
      }
    </components.SingleValue>
  );
}

const SingleValue = props => {
  const { label, baseAsset, termAsset } = props.data;
  return (
    <components.SingleValue {...props}>
      <SingleValueLabel 
        label={label} 
        baseAsset={baseAsset}
        termAsset={termAsset}
      />
    </components.SingleValue>
  );

}

const defaultIntervals = (duration = '', durationUnit = '') => {
  const converted = convertTime(parseInt(duration), durationUnit, UNIT.SECONDS);

  let intervalLength = converted <= SECONDS_PER_HOUR // duration <= 1 hour
    ? 5 * SECONDS_PER_MINUTE
    : converted <= SECONDS_PER_DAY // 1 hour < duration <= 1 day
    ? 10 * SECONDS_PER_MINUTE
    : 20 * SECONDS_PER_MINUTE // duration > 1 day

  const intervalNum = Math.max(Math.floor(converted / intervalLength), MIN_TWAP_INTERVALS)

  if (intervalNum * intervalLength > converted) intervalLength = Math.floor(converted / intervalNum)

  return [intervalLength, intervalNum] // [length (sec), num of intervals]
}

const getDefaultPTWAPInterval = (algorithmStrategy, duration) => {
  if (algorithmStrategy !== OrderAlgorithmStrategy.PTWAP || !duration) return null;

  if (duration < (50 * SECONDS_PER_MINUTE)) return 10;
  else return Math.ceil(duration / (5 * SECONDS_PER_MINUTE));
};

const getAllocationAppender = bucket => {
  switch (bucket) {
    case 1: return 'K';
    case 2: return 'M';
    case 3: return 'B';
    case 4: return 'T';
    default: return '';
  }
}

const getAllocationPrepender = asset => {
  const number = asset.quantity || 0;
  const numberIsNegative = number < 0;
  return numberIsNegative ? '-' : '';
}

// If number has more than 6 digits we need an appender
// examples
// 10,000 -> 10 K
// 10,000,000 -> 10,000 K -> 10 M
// 10,000,000,000 -> 10,000,000 K -> 10,000 M -> 10 B
const shortenNumberByBucket = (number, bucket) => {
  if (bucket > 0) {
    // Get rid of any decimal places and make number an integer. 
    number = Math.floor(number);
    number = String(number);
    number = number.slice(0, -3 * bucket);
  }

  return Number(number);
}

const getMaxDecimalPlaces = assetType => {
  const decimals = getNumberFormat(assetType).split('.')[1] || '';
  return decimals.length;
}

const getAllocationNumberFormat = (assetType, availableDecimalPlaces) => {
  const maxDecimalPlaces = getMaxDecimalPlaces(assetType);
  const decimalPlaces = Math.min(maxDecimalPlaces, availableDecimalPlaces || 0);
  const digits = '0,0';
  const decimals = '0'.repeat(decimalPlaces);
  const dot = decimalPlaces > 0 ? '.' : '';
 
  return digits + dot + decimals;
}

const getAllocationNumber = (asset, scale) => {
  const { bucket, decimalPlaces } = scale;
  const { quantity, type } = asset;
  
  // Remove the negative sign to get accurate number of digits in number
  const number = shortenNumberByBucket(Math.abs(quantity || 0), bucket);
  const format = getAllocationNumberFormat(type, decimalPlaces);
  
  return formatNumber(number, format);;
}

const allocationFormatter = (asset, scale) => {
  const { bucket } = scale;
  const prepender = getAllocationPrepender(asset);
  const appender = getAllocationAppender(bucket);
  const number = getAllocationNumber(asset, scale);

  return prepender + number + appender;
}

const getMaxNumberFromVenueOptions = (venueOptions, field) => 
  venueOptions.reduce((max, venue) => {
    // Get absolute value in case it is negative. 
    // Max number isn't the number with the highest value but the number with the highest scaling. 
    // i.e -10,000,000 vs 10,000. The scaling for -10,000,000 is millions even if its negative
    const number = Math.abs(get(venue, field, 0));
    if(number > max) max = number;
    return max;
  }, Number.NEGATIVE_INFINITY);

const getAllocationBucket = number => {
  const numberOfDigits = String(Math.floor(Math.abs(number))).length;
  // Only bucket number if it has 7 or more digits
  // Math.max to coalesce all negative numbers to zero
  const numDigitsToBucket = Math.max(0, numberOfDigits - 6); 
  return Math.ceil(numDigitsToBucket / 3);
};

const getAllocationDecimalPlaces = number => {
  const numberOfDigits = String(Math.floor(Math.abs(number))).length;
  const MAX_DECIMAL_PLACES = 3;
  
  // Number must have 3 or less digits to display any decimals
  // 2312.3 -> 2312  | 0 spots for decimals
  // 231.23 -> 231.2 | 1 spots for decimals
  // 23.123 -> 23.12 | 2 spots for decimals
  const numPlacesLeftForDecimals = Math.max(0, 4 - numberOfDigits);
  return Math.min(MAX_DECIMAL_PLACES, numPlacesLeftForDecimals);
}

const getAllocationScale = maxNumber => {
  const bucket = getAllocationBucket(maxNumber);
  const decimalPlaces = getAllocationDecimalPlaces(maxNumber);

  return { bucket, decimalPlaces };
}

const getQuantityFromAccount = (account) => 
  account?.position?.quantity
    || account?.balanceAtCustodianAsReportedFree
    || account?.temporaryCalculatedBalance
    || 0;

const appendAllocationsToVenueOptions = (venueOptions, accounts) => {
  const venueOptionsWithQuantities =  venueOptions.map(v => {
    const venueAccounts = accounts.filter(account => get(account, 'custodian.operatingVenue.id') === v.value);
    const basetAssetAccount = venueAccounts.find(account => get(account, 'currency.id') === v?.baseAsset?.id);
    const termAssetAccount = venueAccounts.find(account => get(account, 'currency.id') === v?.termAsset?.id);

    const baseAsset = {
      quantity: getQuantityFromAccount(basetAssetAccount),
      ...v.baseAsset
    };

    const termAsset = {
      quantity: getQuantityFromAccount(termAssetAccount),
      ...v.termAsset
    }

    return { 
      ...v,
      baseAsset,
      termAsset,
    }
  });

  const baseAssetBucket = getAllocationScale(getMaxNumberFromVenueOptions(venueOptionsWithQuantities, 'baseAsset.quantity'));
  const termAssetBucket = getAllocationScale(getMaxNumberFromVenueOptions(venueOptionsWithQuantities, 'termAsset.quantity'));

  return venueOptionsWithQuantities.map(({ baseAsset, termAsset, ...v}) => ({ 
        ...v, 
        baseAsset: { ...baseAsset, quantityLabel: allocationFormatter(baseAsset, baseAssetBucket) },
        termAsset: { ...termAsset, quantityLabel: allocationFormatter(termAsset, termAssetBucket) }
    }));
}

const and = (...args) => args.every(arg => arg)
