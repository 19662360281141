import { getExecutionType } from '@omniex/poms-core/lib/utils/OrderUtils';
import { getTimeOnly, getNumberFormat, formatNumberWithoutTrailingZeroes } from './DisplayUtils';
import AssetType from '@omniex/poms-core/lib/enums/AssetType';
import {
  allNotificationSettings,
  getVenueRemovalDetails,
  getReasonDetails,
  isNotificationConfigured,
  notificationReasonToSetting
} from '@omniex/poms-core/lib/utils/NotificationUtils';
import OrderNotificationReason from '@omniex/poms-core/lib/enums/OrderNotificationReason';

const BLOTTER_NOTIFICATION_BLACKLIST = [
  OrderNotificationReason.PLACED,
  OrderNotificationReason.CANCELED,
  OrderNotificationReason.FILLED,
];

export const userEnabledDesktopNotifications = (user) => (
  user?.canViewTrade &&
  allNotificationSettings.some(s => user?.settings?.notificationDeliveryMethods_desktop?.[s])
)

const userCanViewNotification = (user, notification) => (
  user?.canViewTrade &&
  user?.settings?.notificationDeliveryMethods_desktop?.[notificationReasonToSetting[notification?.reason]]
);

export const displayNotification = (user = {}, notification = {}, component = '') => {
  switch (component) {
    case 'desktopNotification': {
      return isNotificationConfigured(notification) && userCanViewNotification(user, notification);
    }
    case 'notificationCenter': {
      return isNotificationConfigured(notification) && userCanViewNotification(user, notification);
    }
    case 'blotter': {
      return isNotificationConfigured(notification) && !BLOTTER_NOTIFICATION_BLACKLIST.includes(notification?.reason);
    }
    default: {
      return false;
    }
  }
}

export const getQtyOrdered = ({ instrument, side, specifiedQuantity }) => {
  const quantityFormat = getNumberFormat(instrument?.type);
  const minSignificantDecimals = instrument?.type === AssetType.FIAT ? 2 : 1;
  const formattedQty = formatNumberWithoutTrailingZeroes(specifiedQuantity, quantityFormat, minSignificantDecimals);
  return `${side} ${formattedQty} ${instrument?.displayName || ''}`
};

export const computeDisplayFields = (order = {}, notification = {}) => {
  const { reason, text, timeCreated, venue } = notification;

  // Get status, subject, desktop text and order details text from notification info
  const getDetails = getReasonDetails[reason];
  if (!getDetails) return null;

  const executionType = getExecutionType(order);
  const qtyOrdered = getQtyOrdered(order);
  const timestamp = getTimeOnly(timeCreated) || '';
  const venueName = venue?.name || '';

  const additionalDetails = getDetails({ executionType, qtyOrdered, timestamp, venueName });

  // For Venue Removal notifications, get the subject and order details text keyed by the notification text (FIX field 58)
  let specificDetails = {};

  if (reason === OrderNotificationReason.VENUE_REMOVAL) {
    const instrumentSymbol = order?.instrument?.displayName || '';

    const getSpecificDetails = getVenueRemovalDetails(text);
    specificDetails = getSpecificDetails({ executionType, instrumentSymbol, qtyOrdered, text, venueName });
  };

  return {
    ...notification,
    ...additionalDetails,
    ...specificDetails,
  };
}
