import { Header } from 'semantic-ui-react';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import copyText from './AlgorithmStrategyDescriptionComponent.copyText';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/header.css');

const COMPONENT_NAME = 'AlgorithmStrategyDescriptionComponent';

const StyledDiv = styled('div')`
  .header {
    margin-top: 1.2em !important;
    margin-bottom: 0.8em !important;
    margin-left: 0.6em !important;

    :first-child {
      margin-top: 0.6em !important;
    }
  }

  > p{
    margin-left: 2.2em !important;
  }
`;

export default class AlgorithmStrategyDescriptionComponent extends PureComponent {
  render() {
    return (
      <StyledDiv className={COMPONENT_NAME}>
        <Header>{copyText.headerIce}</Header>
        <p>{copyText.bodyIce}</p>
        <Header>{copyText.headerPtwap}</Header>
        <p>{copyText.bodyPtwap}</p>
        <Header>{copyText.headerPvwap}</Header>
        <p>{copyText.bodyPvwapUpper}</p>
        <p>{copyText.bodyPvwapLower}</p>
        <Header>{copyText.headerPegger}</Header>
        <p>{copyText.bodyPegger}</p>
        <Header>{copyText.headerPov}</Header>
        <p>{copyText.bodyPovUpper}</p>
        <p>{copyText.bodyPovLower}</p>
        <Header>{copyText.headerSor}</Header>
        <p>{copyText.bodySor}</p>
        <Header>{copyText.headerSpread}</Header>
        <p>{copyText.bodySpread}</p>
        <Header>{copyText.headerTwap}</Header>
        <p>{copyText.bodyTwap}</p>
        <Header>{copyText.headerSvwap}</Header>
        <p>{copyText.bodySvwapUpper}</p>
        <p>{copyText.bodySvwapLower}</p>
      </StyledDiv>
    );
  }
}
