import gql from 'graphql-tag';

export default gql`
  mutation saveIntegration(
    $fixClientId: String
    $fixClientIdForView: String
    $fixCompId: String
    $key: String!
    $password: String
    $secret: String
    $keyForView: String
    $passwordForView: String
    $secretForView: String
    $type: String
    $portfolioId: ID!
  ) {
    integration: saveIntegration(
      fixClientId: $fixClientId
      fixClientIdForView: $fixClientIdForView
      fixCompId: $fixCompId
      key: $key
      password: $password
      secret: $secret
      keyForView: $keyForView
      passwordForView: $passwordForView
      secretForView: $secretForView
      type: $type
      portfolioId: $portfolioId
    ) {
      id
      type
      provider {
        id
      }
      portfolioId
    }
  }
`;
