/* eslint no-sequences: 0 */
import papa from 'papaparse'
import { saveAs } from 'file-saver'

import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils'
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import { groupBy, sortBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils'
import { isNil } from '@omniex/onx-common-js/lib/utils/LangUtils'
import { isEmpty } from '@omniex/onx-common-js/lib/utils/LangUtils'
import { unique } from '@omniex/onx-common-js/lib/utils/ArrayUtils'

import { convertTime, UNIT } from '../../../utils/UnitUtils'


const fmt_ms = (n, p = 3) => `${(n || 0).toFixed(p)}ms`

export class PerformanceTimer {
  constructor(p = 3) {
    this._p = p
    this._start = performance.now()
    this._events = {}
    this._markers = []
  }

  _now = _ => performance.now() - this._start

  start = tag => this._events[tag] = { start: this._now() }

  stop = tag => {
    const event = this._events[tag] || { start: 0 }
    event.time = this._now() - event.start
    this._events[tag] = event
  }

  mark = tag => {
    const now = this._now()
    const n = this._markers.length
    const last = n > 0 ? this._markers[n - 1] : { time: now }
    this._markers.push({ tag, time: now, delta: now - last.time })
  }

  output = _ => {
    const events = Object.keys(this._events).reduce((events, tag) => {
      const { start, time } = this._events[tag]
      events[tag] = { start: fmt_ms(start, this._p), time: fmt_ms(time, this._p) }
      return events
    }, {})

    const markers = this._markers.map(({ tag, time, delta }) => ({
      tag,
      time: `${fmt_ms(delta, this._p)} (${fmt_ms(time, this._p)})`,
    }))

    return {
      ...(isEmpty(events) ? {} : { events }),
      ...(isEmpty(markers) ? {} : { markers }),
    }
  }
}


/**
 * Formatting
 *
 * Various functions for formatting data for display in the table, as well as determining other
 * visual properties like sorting, filtering, alignment, and visibility of columns and values.
 */

const _algo = {
  '1': 'VWAP',
  '1000': 'TWAP',
  '1001': 'SOR',
  '1002': 'SPREAD',
  '1003': 'ICEBERG',
  '1004': 'PVWAP',
  '1005': 'PEGGER',
  '1006': 'VWAP',
  '1007': 'FOE',
  '1008': 'PTWAP-SE',
}
export const fmtAlgo = t => {
  // NOTE: POV, PTWAP, and PVWAP all use id=1004
  if (t.algo_id === '1004' && !t.duration) return 'POV'
  if (t.algo_id === '1004' && t.time_weighted) return 'PTWAP'
  return _algo[t.algo_id] || ''
}

const colLabels = {
  count: 'Orders',
  algo: 'Algo',
  instrument: 'Instrument',
  comp_id: 'Comp ID',
  ticket_id: 'Order ID',
  order_status: 'Status',
  side: 'Side',
  ticket_amount: 'Qty Ordered',
  ticket_price: 'Limit Price',
  duration: 'Req. Duration',
  amount_filled: 'Qty Filled',
  price_average: 'Average Price',
  preprice: 'Pre-Trade Price',
  slippage: 'Slippage (bps)',
  num_fills: 'Fills',
  create_time: 'Create Time (UTC)',
  wgt_duration: 'Wgt. Duration',
  norm_wgt_duration: 'Wgt. Duration (%)',
  last_exec_time: 'Last Execution (UTC)',
  slippage_usd: 'Slippage (USD)',
  ticket_volume_usd: 'Volume (USD)',
  total_exec_time: 'Act. Duration',
  interval: 'Interval',
  year: 'Year',
  quarter: 'Quarter',
  month: 'Month',
  day: 'Day',
}
export const fmtColumnLabel = c => colLabels[c] || ''

export const fmtFloat = (x, p) => Math.round(x * 10 ** p) / 10 ** p
export const fmtFloat10 = x => fmtFloat(x, 10)
export const fmtFloat2 = x => fmtFloat(x, 2)
export const fmtFloatFixed2 = x => (fmtFloat2(x) || 0).toFixed(2)

const __date__ = new Date() // reusable Date instance to avoid reallocations/GC
export const fmtTimestamp = t => (__date__.setTime(t * 1e-3), __date__.toISOString())
const _dhms = [[UNIT.DAYS, 'd'], [UNIT.HOURS, 'h'], [UNIT.MINUTES, 'm'], [UNIT.SECONDS, 's']]
export const fmtTimeDHMS = (time, unit, p = 0) => _dhms.reduce((str, [u, s], i, a) => {
  const [old,] = a[i - 1] || []
  time = convertTime(time).from(old || unit).to(u)
  const t = i === a.length - 1 ? fmtFloat(time, p) : time | 0
  time -= t
  str.push(t && `${t}${s}`)
  return str
}, []).filter(_ => _).join(' ')
export const fmtMicroseconds = (t, p = 0) => fmtTimeDHMS(t, UNIT.MICROSECONDS, p)
export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
export const fmtMonth = s => {
  const [y, m,] = s.split('-').map(n => parseInt(n))
  return [months[m - 1], y].join('-')
}

export const addCommas = n => {
  if (Math.log10(Math.abs(n)) < 3) return `${n}`
  const s = []
  let [i = 0, d = 0] = `${n}`.split('.')
  while (i | 0 >= 1) {
    s.push(`${i | 0}`.slice(-3))
    i /= 1000
  }
  return `${s.reverse().join(',')}${d ? `.${d}` : ''}`
}

export const fmtCrypto = (n, a) => `${addCommas(fmtFloat10(n))}${a ? ` ${a}` : ''}`
export const fmtCryptoBase = (n, b, _) => fmtCrypto(n, b)
export const fmtCryptoTerm = (n, _, t) => fmtCrypto(n, t)
export const fmtFiat = n => addCommas(fmtFloatFixed2(n))

const colValFunc = {
  amount_filled: fmtCryptoBase,
  ticket_amount: fmtCryptoBase,
  price_average: fmtCryptoTerm,
  preprice: fmtCryptoTerm,
  slippage: s => fmtFloat10(1e4 * s),
  create_time: fmtTimestamp,
  wgt_duration: d => fmtMicroseconds(d, 1),
  norm_wgt_duration: d => fmtFloatFixed2(100 * d),
  last_exec_time: fmtTimestamp,
  price_conv: fmtFloat10,
  slippage_usd: fmtFiat,
  ticket_volume_usd: fmtFiat,
  duration: fmtMicroseconds,
  total_exec_time: fmtMicroseconds,
  month: fmtMonth,
}
const hideValue = (c, v, t, p) => isNil(v) || (t === 'data' && p.includes(c))
export const fmtColumnValue = (c, v, { __type, base, term } = {}, p = []) => hideValue(c, v, __type, p) ? '' : (colValFunc[c] || (_ => _))(v, base, term) || '-'

const filterableColumns = ['algo', 'instrument', 'order_status', 'side']
export const filterable = c => filterableColumns.includes(c)
export const getFilterOptions = rows => filterableColumns.reduce((o, c) => (o[c] = unique(rows.map(r => r[c])).sort(), o), {})

export const collapsible = (r, path, showData) => r.__chain && (showData || path.length !== r.__depth)
export const getCollapsibleRows = (rows, path, showData) => rows.filter(r => collapsible(r, path, showData)).map(r => r.__chain)

const _align = {
  count: 'right',
  ticket_id: 'center',
  algo: 'center',
  side: 'center',
  instrument: 'center',
  ticket_volume_usd: 'right',
  order_status: 'center',
  ticket_amount: 'right',
  amount_filled: 'right',
  num_fills: 'right',
  price_average: 'right',
  preprice: 'right',
  ticket_price: 'right',
  slippage: 'right',
  slippage_usd: 'right',
  duration: 'right',
  total_exec_time: 'right',
  wgt_duration: 'right',
  norm_wgt_duration: 'right',
  create_time: 'center',
  last_exec_time: 'center',
  year: 'center',
  quarter: 'center',
  month: 'center',
  day: 'center',
}
export const align = c => _align[c] || 'left'

export const sortDirections = {
  ASCENDING: -1,
  DESCENDING: 1,
  NONE: 0,
}

export const defaultSort = {
  col: 'create_time',
  dir: sortDirections.DESCENDING,
}

export const excludedColumns = [
  'client_id',
  'comp_id',
  'algo_id',
  '__typename',
  'instrument_id',
  'last_exec_id',
  'price_conv',
]

export const includedColumns = [
  'ticket_id',
  'algo',
  'instrument',
  'ticket_volume_usd',
  'ticket_amount',
  'amount_filled',
  'num_fills',
  'preprice',
  'price_average',
  'slippage_usd',
  'slippage',
  'duration',
  'total_exec_time',
  // 'wgt_duration',
  'norm_wgt_duration',
  'create_time',
  'last_exec_time',
  'order_status',
  'side',
  'ticket_price',
]
export const getColumnKeys = (rows = [], path = [], excl = excludedColumns) => [
  ...(path.length ? [...path, 'count'] : []),
  ...includedColumns.filter(c => !path.includes(c)),
]


/**
 * Add computed columns
 *
 * Note that, to avoid unnecessary allocation/garbage collection, this function mutates the elements of the
 * array passed in as the first argument. If the original data need to be preserved unchanged, make a shallow
 * copy of them before passing them into this function.
 */

export const addComputedColumns = (rows, instruments) => (rows.forEach(t => {
  const instrument = instruments[t.instrument_id] || {}
  t.instrument = instrument.displayName || ''
  t.algo = fmtAlgo(t)
  t.ticket_volume_usd = t.amount_filled * t.price_average * t.price_conv
  t.preprice_volume_usd = t.amount_filled * t.preprice * t.price_conv
  t.norm_wgt_duration = t.wgt_duration / (t.last_exec_time - t.create_time)
  t.total_exec_time = t.last_exec_time - t.create_time

  t.base = getAssetDisplayText(get(instrument, 'baseAsset'))
  t.term = getAssetDisplayText(get(instrument, 'termAsset'))

  const created = fmtTimestamp(t.create_time)
  const date = created.slice(0, 10) // YYYY-MM-DD
  const [y, m,] = date.split('-').map(n => parseInt(n))
  const qtrStart = 0 // 0-based index of first month of quarter, e.g. 2 for 1st quarter starting in March
  const q = ((((m - qtrStart + 11) % 12) / 3) | 0) + 1 // m-1 to get 0-based month, - qtrStart to get offset from start, + 12 % 12 to stay in 0-11 range, / 3 months per qtr, | 0 to drop frac, + 1 so q is in [1,2,3,4]
  t.year = created.slice(0, 4) // YYYY
  t.quarter = `${y}-Q${q}` // YYYY-Qq
  t.month = created.slice(0, 7) // YYYY-MM
  t.day = date // YYYY-MM-DD
}), rows)


/**
 * Generate tree
 *
 * Given a set of rows and a path (i.e. a list of columns), gbp generates the tree induced by the path
 * by recursively iterating over the set of rows once for each element in the path. Nodes in the tree
 * store three types of data:
 *
 *   1) the "all" attribute, which contains summary data obtained by applying an aggregation function (cb)
 *      to the subtree contained by the node
 *   2) the "data" attribute, which is a pointer to either an array of data rows if the path here is empty,
 *      meaning the children are leaf nodes, or an object containing child nodes with keys given by values
 *      of the current column in the path and values given by the nodes for the subtrees generated by
 *      grouping the rows by that column
 *   3) a shit ton of metadata tracking the current state of the tree generation process (i.e. where we are,
 *      how we got here, and where we still need to go), as well as some other useful information (e.g. the
 *      current weights or the data type of the keys of the child nodes)
 *
 * Recursion is employed by removing the first element of the path, grouping rows by that column, and then
 * calling gbp with the remaining path on each resulting group. The recursion halts when the path is empty.
 * Finally, the private arguments required for recursion are protected by aliasing the function with the
 * full signature (_gbp) behind a publicly exported function with a restricted signature (gbp or groupByPath).
 */

const getGroups = (rows, k) => Object.values(groupBy(rows, k))
const groupStats = group => group.reduce((a, g) => (a.n += g.n, a.w += g.w, a.w2 += g.w2, a), { n: 0, w: 0, w2: 0 }) // n = count, w = usd volume (avg price), w2 = usd volume (preprice)
const addToChain = (chain = '', key) => chain.split(':').filter(_ => _).concat([key]).join(':')
const reducer = (k, fn, path, cb, fullPath, depth, chain) => (gs, g) => (gs[g[0][k]] = fn(g, path, cb, fullPath, depth, { key: k, value: g[0][k] }, addToChain(chain, g[0][k])), gs)
const _gbp = (rows = [], [k, ...path], cb = _ => _, fullPath, depth = 0, { key, value } = {}, chain) => {
  const data = isNil(k) ? rows : getGroups(rows, k).reduce(reducer(k, _gbp, path, cb, fullPath, depth + 1, chain), {})
  const groups = isNil(k) ? rows.map(r => ({ n: 1, w: r.ticket_volume_usd, w2: r.preprice_volume_usd, all: r })) : Object.values(data)
  const { n, w, w2 } = groupStats(groups)
  return {
    key,
    value,
    dataKeyType: k,
    path: isNil(k) ? [] : [k, ...path],
    remainingPath: path,
    fullPath,
    depth,
    n,
    w,
    w2,
    count: rows.length,
    all: cb(groups, { n, w, w2 }),
    data,
    chain,
  }
}
export const gbp = (rows = [], path, cb = _ => _) => _gbp(rows, path, cb, path, 0)
export { gbp as groupByPath }


/**
 * Traverse tree
 *
 * The flatten function traverses the generated tree to produce a flattened array of rows for use in
 * rendering the final table. Flattening occurs by recursively traversing the tree based on whether
 * the children of the current node are leaf nodes (an array) or internal nodes (an object). The output
 * is an array containing a summary row followed by either the children (if they are leaves) or the
 * result of calling flatten on the children (if they are internal nodes). Recursion halts when we
 * reach a node whose data attribute is an array, which is guaranteed to occur at a finite depth as
 * long as the input is the result of calling gbp with a finite path.
 */

const summaryRow = g => ({ count: g.count, ...g.all, ...(g.key ? { [g.key]: g.value } : {}), __type: 'summary', __depth: g.depth, __chain: g.chain })
const addSummaryRows = (g, data) => g.depth || true ? [summaryRow(g), ...data] : data
const sortObj = (obj, sorter) => sorter ? Object.keys(obj.data).sort(sorter(obj)).map(k => obj.data[k]) : Object.values(obj.data)
const flattenObj = (obj, collapsedRows, sorter) => sortObj(obj, sorter).reduce((vs, v) => (vs.push(...flatten(v, collapsedRows, sorter)), vs), [])
const getGroupData = (obj, collapsedRows, sorter) => Array.isArray(obj.data) ? obj.data.map(r => ({ ...r, __type: 'data', __depth: obj.depth + 1 })) : flattenObj(obj, collapsedRows, sorter)
export const flatten = (obj, collapsedRows, sorter) => addSummaryRows(obj, collapsedRows.includes(obj.chain) ? [] : getGroupData(obj, collapsedRows, sorter))


/**
 * Aggregators
 *
 * Each aggregator is a function that takes weights and returns a reducer that uses those weights
 * to compute the aggregated total (typically a weighted average or sum). The weights are as follows:
 *   n = total number of rows of data below the current level
 *   w = total USD volume contained in this level, computed using the average filled price (i.e. ticket_volume_usd)
 *   w2 = total USD volume contained in this level, computed using the pre-price (i.e. preprice_volume_usd)
 */

const aggregators = {
  count:                ({ n, w, w2 }) => (s, v) => s + v.n,
  num_fills:            ({ n, w, w2 }) => (s, v) => s + v.all.num_fills,
  ticket_volume_usd:    ({ n, w, w2 }) => (s, v) => s + v.all.ticket_volume_usd,
  slippage_usd:         ({ n, w, w2 }) => (s, v) => s + v.all.slippage_usd,
  slippage:             ({ n, w, w2 }) => (s, v) => s + v.w2 * v.all.slippage / w2,
  norm_wgt_duration:    ({ n, w, w2 }) => (s, v) => s + v.w * v.all.norm_wgt_duration / w,
}
const initial = {
  count: 0,
  num_fills: 0,
  ticket_volume_usd: 0,
  slippage_usd: 0,
  slippage: 0,
  norm_wgt_duration: 0,
}
const aggReducer = (rows, stats) => (agg, k) => (agg[k] = rows.reduce(aggregators[k](stats), initial[k]), agg)
export const aggregate = (rows, stats) => Object.keys(aggregators).reduce(aggReducer(rows, stats), {})


/**
 * Column visibility helpers
 */

export const summaryColumns = path => unique([...path, 'count', ...Object.keys(aggregators)])
export const visibleColumns = (path, showData) => path.length && showData
  ? unique([...path, 'count', ...includedColumns])
  : path.length
  ? summaryColumns(path)
  : ['count', ...includedColumns]


/**
 * Generate table
 *
 * The table is generated by the following steps:
 *   1) make a shallow copy of the input array of tickets so any mutations are kept localized
 *   2) add computed columns (e.g. usd volume) to each row
 *   3) perform an initial sort (defaults to descending create time)
 *   4) compute and store the options for filters (done before filtering to ensure all options are included rather than just those that remain after the filters are applied)
 *   5) apply the given filters
 *   6) sort lexicographically by the given path so that all grouping columns have a default order
 *   7) construct the tree using gbp
 *   8) prepare a sorting function to use during tree traversal
 *   9) use flatten to traverse the tree once without any collapsed sections to get a list of all rows
 *   10) use flatten to traverse the tree again with collapsed sections included to get a list of visible rows
 *   11) filter out any data rows if showData is false
 *   12) determine which columns should be visible for the given path and visible rows
 */

export const generateTable = ({ tickets, instruments, path, filters, sortCol, sortDir, showData, collapsedRows = [] }) => {
  const timer = new PerformanceTimer()
  timer.start('generate_table')
  timer.mark('begin')

  let rows = tickets.map(t => Object.keys(t).reduce((o, k) => (o[k] = t[k], o), {}))
  timer.mark('clone_tickets_obj_keys_reduce')

  rows = addComputedColumns(rows, instruments)
  timer.mark('add_computed_columns')

  sortCol = sortCol || defaultSort.col
  sortDir = sortDir || defaultSort.dir

  const getSorter = (col, dir) => (a, b) => a[col] < b[col] ? dir : a[col] > b[col] ? -dir : 0

  if (sortCol !== defaultSort.col) {
    rows.sort(getSorter(defaultSort.col, defaultSort.dir))
    timer.mark('sort_rows_by_create_time')
  }

  rows.sort(getSorter(sortCol, sortDir))
  timer.mark(`sort_rows_by_${sortCol}_${sortDir === -1 ? 'asc' : 'desc'}`)

  const filterOptions = getFilterOptions(rows)
  timer.mark('get_filter_options')

  Object.keys(filters).forEach(k => {
    const f = filters[k] || []
    const o = filterOptions[k] || []
    if (f.length && o.length && f.length < o.length) {
      rows = rows.filter(r => f.includes(r[k]))
      timer.mark(`filter_rows_by_${k}`)
    }
  })

  rows = sortBy(rows, path)
  timer.mark('sort_rows_by_path')

  rows = gbp(rows, path, aggregate)
  timer.mark('group_rows_gbp')

  const sortVal = (obj, k) => get(obj, `data.${k}.all.${sortCol}`, k)
  const compare = (obj, k1, k2) => sortVal(obj, k1) < sortVal(obj, k2)
  const skipSort = obj => obj.dataKeyType !== sortCol && path.includes(sortCol)
  const sortFunc = obj => skipSort(obj) ? _ => 0 : (a, b) => compare(obj, a, b) ? sortDir : compare(obj, b, a) ? -sortDir : 0
  const sorter = sortDir && summaryColumns(path).includes(sortCol) ? sortFunc : null
  const allRows = flatten(rows, [], sorter)
  timer.mark('flatten_rows_1')

  let visibleRows = flatten(rows, collapsedRows, sorter)
  timer.mark('flatten_rows_2')

  if (path.length && !showData) {
    visibleRows = visibleRows.filter(r => r.__type !== 'data')
    timer.mark('remove_data_rows')
  }

  const cols =  visibleColumns(path, (showData && visibleRows.some(r => r.__type === 'data')))
  timer.mark('get_column_keys')

  timer.stop('generate_table')

  return { rows: allRows, visibleRows, cols, filterOptions }
}


/**
 * Generate CSV file
 */

const csvColLabel = {
  ...colLabels,
  duration: 'Req. Duration (s)',
  total_exec_time: 'Act. Duration (s)',
  __type: 'Row Type',
  __depth: 'Report Depth',
  __index: 'Sort Index',
}
const csvCol = c => csvColLabel[c] || c
const microToSec = t => fmtFloat(t * 1e-6, 6)
const csvValFunc = {
  ...colValFunc,
  wgt_duration: microToSec,
  duration: microToSec,
  total_exec_time: microToSec,
  amount_filled: fmtFloat10,
  ticket_amount: fmtFloat10,
  price_average: fmtFloat10,
  preprice: fmtFloat10,
  slippage_usd: fmtFloat10,
  ticket_volume_usd: fmtFloat10,
}
const csvVal = (c, v) => (csvValFunc[c] || (_ => _))(v)
const indexRows = rows => rows.map((r, i) => Object.keys(r).reduce((rs, c) => (rs[csvCol(c)] = csvVal(c, r[c]), rs), { [csvCol('__index')]: i }))
const dateStr = d => d.toISOString().replace(/\.\d+Z/, '').replace(/:/g, '.')
export const csv = (rows, path, showData, startDate, endDate) => {
  const filename = `tca_report_${dateStr(startDate)}_${dateStr(endDate)}.csv`
  const columns = [...visibleColumns(path, showData), '__type', '__depth', '__index'].map(csvCol)
  const csvRows = indexRows(showData ? rows : rows.filter(r => r.__type !== 'data'))
  const text = papa.unparse(csvRows, { columns })
  const blob = new Blob([text], { type: 'text/csv' })
  saveAs(blob, filename)
}
