import { DistinctValueExecutionTypes } from '../types';
import OrderAlgorithmStrategy from '@omniex/poms-core/lib/enums/OrderAlgorithmStrategy';
import OrderFeature from '@omniex/poms-core/lib/enums/OrderFeature';
import { getAlgoName } from '@omniex/poms-core/lib/utils/OrderUtils';
import { FilterOption } from '@omniex/poms-ui/lib/types'

export const EXCLUDED_FEATURES = [OrderFeature.ALGO, OrderFeature.API];
export const EXCLUDED_ALGOS = [OrderAlgorithmStrategy.RFS, OrderAlgorithmStrategy.RFS_TWAP];

export const executionType = (Object.values(OrderFeature)).reduce((arr: Array<{value: string}>, value) => {
  if (!EXCLUDED_FEATURES.includes(value)) arr.push({ value });
  return arr;
}, []);

export const getExecutionTypeOptions = ({
  algorithmStrategies,
  features,
}: DistinctValueExecutionTypes['distinctValues']) => {
  let hasVWAP = false;
  return [...algorithmStrategies, ...features].reduce((arr: Array<FilterOption>, value) => {
    if (value === OrderAlgorithmStrategy.VWAP || value === OrderAlgorithmStrategy.SVWAP) {
      if (!hasVWAP) {
        arr.push({ name: OrderAlgorithmStrategy.VWAP, value: OrderAlgorithmStrategy.VWAP });
        hasVWAP = true;
      }
    } else if (executionType.some(({ value: v }) => value === v)) arr.push({ name: value, value });
    else if (!EXCLUDED_FEATURES.includes(value as OrderFeature) && !EXCLUDED_ALGOS.includes(value as OrderAlgorithmStrategy)) arr.push({ name: getAlgoName(value), value });
    return arr;
  }, []);
}

export default {
  EXCLUDED_ALGOS,
  EXCLUDED_FEATURES,
  executionType,
  getExecutionTypeOptions
}
