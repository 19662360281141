const portfolioPageBreakdownSectionCopy = {
  errorMessage: 'Portfolio breakdown cannot be generated at this time.',
  errorMessageOnlyClosedAndNegativePositions:
    "Sorry, we cannot render a breakdown chart at this time. We've detected you only have closed and negative positions.",
  errorMessageOnlyNegativePositions:
    "Sorry, we cannot render a breakdown chart at this time. We've detected you only have negative positions.",
  loadingMessage: 'Loading...',
  portfolioMarketValuePrefix: 'Current',
  refreshBalancesLabel: 'Refresh Balances',
  refreshBalancesProcessingLabel: 'Refreshing...',
  sectionTitle: 'Breakdown',
  warningMessageNoOpenPositions:
    "Sorry, we cannot render a breakdown chart at this time. We've detected you have no open positions.",
  warningMessageNoPositions:
    'There are no positions associated with your portfolio at this time.'
};

export default portfolioPageBreakdownSectionCopy;
