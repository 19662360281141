import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSubscription } from '@apollo/react-hooks';
import newOrderNotification from '../../../apollo/graphql/newOrderNotification';
import config from '../../../config';
import {
  computeDisplayFields,
  displayNotification,
} from '../../../utils/OrderNotificationUtils';

const DesktopOrderNotification = ({ addNotification, apiClient, user }) => {
  const history = useHistory();
  const location = useLocation();

  const onSubscriptionData = ({ subscriptionData }) => {
    const notification = subscriptionData.data.newOrderNotification;

    const newOrderNotification = computeDisplayFields(notification.order, notification);

    if (!newOrderNotification) return;

    addNotification(newOrderNotification);

    if (!displayNotification(user, notification, 'desktopNotification')) return;

    const { subject: title, desktopText: body, id: tag, order: { fixClOrdID }, timeCreated } = newOrderNotification;

    const n = new window.Notification(title, {
      body,
      tag,
      renotify: true,
      data: { path: `/trade/${fixClOrdID}` },
      icon: new URL(config.urls.notificationLogo, window.location.origin).toString(),
      timestamp: new Date(timeCreated).getTime(),
    });

    n.onclick = e => {
      if (location.pathname.startsWith('/trade')) history.replace(e.target.data.path);
      else history.push(e.target.data.path);

      e.target.close();
    }

  };

  useSubscription(newOrderNotification, { onSubscriptionData, client: apiClient });

  return <input type='hidden' name='dummy-for-react-web-notification' style={{ display: 'none' }} />
};

export default DesktopOrderNotification;
