export default {
  active: 'Active',
  cancel: 'Cancel',
  disabled: 'Disabled',
  enabled: 'Enabled',
  integrations: 'API Integrations',
  loading: 'Loading',
  name: 'Name',
  newPortfolio: 'New Portfolio',
  portfolioName: 'Portfolio Name',
  portfolios: 'Portfolios',
  save: 'Save',
  status: 'Status',
  users: 'Users',
}
