import { arrayOf, func, number, shape, string } from 'prop-types';
import { Icon, Popup } from 'semantic-ui-react';
import React, { Fragment, PureComponent } from 'react';
import styled from 'react-emotion';

import { colors } from '@omniex/onx-common-ui/lib/styles';
import { formatNum } from '../../utils/SettlementUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import { getDateFormat, getDateFormatSimple } from '../../utils/DisplayUtils';
import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { orderBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import copyText from './AffirmSettlementsBlotterTable.copyText';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import SettlementStatus from '@omniex/onx-poms-entity-helpers/lib/enums/SettlementStatus';
import Table from '@omniex/onx-common-ui/lib/semantic/react/Table';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');
require('@omniex/onx-common-ui/lib/semantic/css/table.css');

const COMPONENT_NAME = 'AffirmSettlementsBlotterTable';

const popupStyles = {
  container: {
    fontSize: '0.9em',
    padding: '8px 16px'
  },
  identifierLabel: {
    fontSize: '0.9em',
    textAlign: 'right'
  },
};

const StyledTable = styled(Table)`
  .${COMPONENT_NAME}-borderCell {
    border: 1px solid ${colors.borderColor};
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class AffirmSettlementsBlotterTable extends PureComponent {
  static propTypes = {
    senderCompID: string,
    settlements: arrayOf(
      shape({
        id: string.isRequired,
        allocations: arrayOf(
          shape({
            id: string.isRequired,
            currency: shape({
              id: string.isRequired,
              symbol: string.isRequired
            }),
            quantity: number.isRequired
          })
        ),
        fixAllocID: string.isRequired,
        timeCreated: string.isRequired,
        timeLastUpdated: string
      })
    ),
    renderExpandedContent: func
  };

  static defaultProps = { renderExpandedContent: noop };

  state = {};

  render() {
    if (this.props.settlements.length === 0) {
      return this._renderWarningMessage();
    }

    const settlements = orderBy(
      get(this.props, 'settlements', []),
      'timeCreated',
      'desc'
    );

    return (
      <StyledTable className={COMPONENT_NAME}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{copyText.tableHeaderTimestamp}</Table.HeaderCell>
            <Table.HeaderCell>
              <Popup
                flowing
                hoverable
                hideOnScroll
                inverted
                style={popupStyles.container}
                trigger={<span>{copyText.tableHeaderFixAllocID}</span>}>
                <div style={popupStyles.identifierLabel}>
                  <span>{copyText.popupLabelFixAllocID}</span>
                </div>
              </Popup>
            </Table.HeaderCell>
            <Table.HeaderCell>{copyText.tableHeaderStatus}</Table.HeaderCell>
            <Table.HeaderCell>
              <Icon color="green" name="plus" />
              {copyText.tableHeaderIncoming}
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Icon color="red" name="minus" />
              {copyText.tableHeaderOutgoing}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {settlements.map(settlement => this._renderSettlement(settlement))}
        </Table.Body>
      </StyledTable>
    );
  }

  _renderSettlement(settlement) {
    const { senderCompID } = this.props;
    const { pos, neg } = settlement.allocations.reduce((flows, a) => {
      a.fixToCompID === senderCompID ? flows.pos.push(a) : flows.neg.push(a);
      return flows;
    }, {pos: [], neg: []});
    return (
      <Fragment key={settlement.id}>
        <Table.Row
          onClick={
            this.props.renderExpandedContent
              ? this._handleClickExpandRow.bind(this, settlement.id)
              : undefined
          }>
          <Table.Cell>
            {!this.props.renderExpandedContent ? null : (
              <Icon
                name={`triangle ${
                  this.state.expandedSettlementId === settlement.id
                    ? 'down'
                    : 'right'
                }`}
              />
            )}
            <Popup
              flowing
              hoverable
              hideOnScroll
              inverted
              style={popupStyles.container}
              trigger={<span>{getDateFormatSimple(settlement.timeCreated)}</span>}>
              <div style={popupStyles.identifierLabel}>
                <span>{getDateFormat(settlement.timeCreated)}</span>
              </div>
            </Popup>
          </Table.Cell>
          <Table.Cell>{settlement.fixAllocID}</Table.Cell>
          <Table.Cell>
            {settlement.status === SettlementStatus.PENDING_REJECT
              ? copyText.rejectedLabel
              : this.props.senderCompID === settlement.fixInitiatorCompID
              ? copyText.pendingLabel
              : copyText.actionRequiredLabel}
          </Table.Cell>
          <Table.Cell>{this._renderAllocationData(pos)}</Table.Cell>
          <Table.Cell>{this._renderAllocationData(neg)}</Table.Cell>
        </Table.Row>
        {this.state.expandedSettlementId === settlement.id ? (
          <Table.Row>
            <Table.Cell className={cn('borderCell')} colSpan={5}>
              {this.props.renderExpandedContent(settlement)}
            </Table.Cell>
          </Table.Row>
        ) : null}
      </Fragment>
    );
  }

  _renderWarningMessage() {
    return (
      <div className={COMPONENT_NAME}>
        <Message
          className={cn('warningMessage')}
          content={copyText.warningMessageNoAffirmSettlements}
          warning
        />
      </div>
    );
  }

  _renderAllocationData(allocations) {
    return allocations.map((allocation, index) => (
      <span key={allocation.id}>
        {`${formatNum(allocation.quantity, allocation.currency.type)
        } ${getAssetDisplayText(allocation.currency)
        } ${allocations.length - 1 !== index ? '/ ' : ''}`}
      </span>
    ));
  }

  _handleClickExpandRow = settlementId => {
    this.setState(currentState => ({
      expandedSettlementId:
        currentState.expandedSettlementId === settlementId
          ? undefined
          : settlementId
    }));
  };
}
