import gql from 'graphql-tag';

export default gql `
query getVolume($instrumentId: ID!, $venueIds: [ID]!, $start: Int!, $end: Int!, $weeks: Int!, $seasonality: String!) {
  getVolume(
          instrumentId: $instrumentId
          venueIds: $venueIds
          start: $start
          end: $end
          weeks: $weeks
          seasonality: $seasonality
        )
  }
`;
