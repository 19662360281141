import gql from 'graphql-tag';

export default gql`
  query getRequestedIntegrations {
    integrations: getRequestedIntegrations {
      id
      org {
        id
        name
      }
      requestedBy
      requestedTime
      status
      type
    }
  }
`;
