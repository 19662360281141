import gql from 'graphql-tag';

export default gql`
  mutation rejectSettlement($id: ID!, $reasonRejected: String!) {
    settlement: rejectSettlement(id: $id, reasonRejected: $reasonRejected) {
      id
      status
    }
  }
`;
