const quotedOrderBlotterTableCopy = {
  notAvailable: 'N/A',
  popupHeaderClientOrderId: 'Client Order ID:',
  popupHeaderEMSOrderID: 'EMS Order ID:',
  tableHeaderClientID: 'Client ID',
  tableHeaderQuantityFilled: 'Quantity Filled',
  tableHeaderInstrument: 'Instrument',
  tableHeaderOrderID: 'Order ID',
  tableHeaderPrice: 'Price',
  tableHeaderQuantity: 'Specified Quantity',
  tableHeaderQuoteID: 'Quote ID',
  tableHeaderSide: 'Side',
  tableHeaderStatus: 'Status',
  tableHeaderTimestamp: 'Timestamp',
  tableHeaderTraderName: 'Trader',
  warningMessage: 'No completed orders at this time.'
};

export default quotedOrderBlotterTableCopy;
