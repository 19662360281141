import gql from 'graphql-tag';

export default gql`
  query getCurrencyStatistics($dateClosed: String, $window: Int) {
    currencyStatistics: getCurrencyStatistics(
      dateClosed: $dateClosed
      window: $window
    ) {
      baseSymbol
      termSymbol
      timeClosed
      correlation
      covariance
      window
      minTimeClosed
      maxTimeClosed
    }
  }
`;
