import { func, object } from 'prop-types';
import { queryShape, userShape } from '../../utils/PropTypeUtils';
import React from 'react';
import styled from 'react-emotion';

import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import AdminPageIntegrationsSection from './AdminPageIntegrationsSection';
import AdminPagePortfoliosSection from './AdminPagePortfoliosSection';
import AdminPageOrgSettingsSection from './AdminPageOrgSettingsSection';
import AdminPageVenueRelationshipsSection from './AdminPageVenueRelationshipsSection';
import ApiKeySection from '../components/EdgeApiKeys/ApiKeySection';
import OrgType from '@omniex/onx-poms-entity-helpers/lib/enums/OrgType';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');
require('@omniex/onx-common-ui/lib/semantic/css/popup.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const COMPONENT_NAME = 'AdminPage';

const StyledDiv = styled('div')`
  height: 100%;
  overflow: hidden;

  .${COMPONENT_NAME}-sectionWrapper {
    padding-top: 20px;

    :last-child {
      margin-bottom: 60px;
    }
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

const AdminPage = ({
  accountsQuery,
  createAccounts = noop,
  createAccountsMutation,
  createPortfolio = noop,
  mCreatePortfolio = {},
  createRequestedIntegration = noop,
  createRequestedIntegrationMutation,
  currenciesQuery,
  exchangeAccountsQuery,
  fetchAccounts = noop,
  fetchExchangeAccounts = noop,
  integrationsQuery,
  orgQuery,
  qPortfolios = {},
  saveIntegration = noop,
  saveIntegrationMutation,
  updateIntegrationCredentials = noop,
  updateIntegrationCredentialsMutation,
  updateIntegrationSettings = noop,
  updateIntegrationSettingsMutation,
  updateOrg = noop,
  updateOrgMutation,
  updatePortfolio = noop,
  mUpdatePortfolio = {},
  user,
  portfolio = {},
}) => (
  <StyledDiv className={COMPONENT_NAME}>
      <main>
        <div className={cn('sectionWrapper')}>
          <AdminPageOrgSettingsSection currenciesQuery={currenciesQuery} orgQuery={orgQuery} updateOrg={updateOrg} updateOrgMutation={updateOrgMutation} />
        </div>
        <div className={cn('sectionWrapper')}>
          <AdminPagePortfoliosSection
            user={user}
            createPortfolio={createPortfolio}
            mCreatePortfolio={mCreatePortfolio}
            qPortfolios={qPortfolios}
            updatePortfolio={updatePortfolio}
            mUpdatePortfolio={mUpdatePortfolio}
          />
        </div>
        <div className={cn('sectionWrapper')}>
          {user?.org?.type === OrgType.CLIENT_BUY_SIDE && <AdminPageVenueRelationshipsSection orgQuery={orgQuery} />}
        </div>
        <div className={cn('sectionWrapper')}>
          <AdminPageIntegrationsSection
            accountsQuery={accountsQuery}
            createAccounts={createAccounts}
            createAccountsMutation={createAccountsMutation}
            createRequestedIntegration={createRequestedIntegration}
            createRequestedIntegrationMutation={createRequestedIntegrationMutation}
            currenciesQuery={currenciesQuery}
            exchangeAccountsQuery={exchangeAccountsQuery}
            fetchAccounts={fetchAccounts}
            fetchExchangeAccounts={fetchExchangeAccounts}
            integrationsQuery={integrationsQuery}
            orgQuery={orgQuery}
            portfolio={portfolio}
            saveIntegration={saveIntegration}
            saveIntegrationMutation={saveIntegrationMutation}
            updateIntegrationCredentials={updateIntegrationCredentials}
            updateIntegrationCredentialsMutation={updateIntegrationCredentialsMutation}
            updateIntegrationSettings={updateIntegrationSettings}
            updateIntegrationSettingsMutation={updateIntegrationSettingsMutation}
            user={user}
          />
        </div>
        <div className={cn('sectionWrapper')}>
          <ApiKeySection />
        </div>
      </main>
  </StyledDiv>
)

AdminPage.propTypes = {
  accountsQuery: queryShape(object, false).isRequired,
  createAccounts: func,
  createAccountsMutation: queryShape(object, false).isRequired,
  createPortfolio: func,
  mCreatePortfolio: object,
  createRequestedIntegration: func,
  createRequestedIntegrationMutation: queryShape(object, false).isRequired,
  currenciesQuery: queryShape(object, false).isRequired,
  exchangeAccountsQuery: queryShape(object, false).isRequired,
  fetchAccounts: func,
  fetchExchangeAccounts: func,
  integrationsQuery: queryShape(object, false).isRequired,
  orgQuery: queryShape(object, false).isRequired,
  qPortfolios: object,
  saveIntegration: func,
  saveIntegrationMutation: queryShape(object, false).isRequired,
  updateIntegrationCredentials: func,
  updateIntegrationCredentialsMutation: queryShape(object, false).isRequired,
  updateIntegrationSettings: func,
  updateIntegrationSettingsMutation: queryShape(object, false).isRequired,
  updateOrg: func,
  updateOrgMutation: queryShape(object, false).isRequired,
  updatePortfolio: func,
  mUpdatePortfolio: object,
  user: userShape,
  portfolio: object,
}

export default AdminPage
