import gql from 'graphql-tag';

export default gql`
  mutation sendQuote($requestId: ID!, $bidPrice: Float, $offerPrice: Float) {
    quote: sendQuote(
      requestId: $requestId
      bidPrice: $bidPrice
      offerPrice: $offerPrice
    ) {
      id
      request {
        id
      }
    }
  }
`;
