import { Identifiable } from '../types';

/**
 * Merges updates between two arrays which may contain duplicates and shallow clones elements.
 *
 * @export
 * @template T
 * @param {Array<T>} previous
 * @param {Array<T>} next
 * @return {*}  {Array<T>}
 */
 export function mergeUpdate<T extends Identifiable = Identifiable>(
  previous: Array<T>,
  next: Array<T>
): Array<T> {
  const idSet: Set<string> = new Set();
  const resultArray: Array<T> = [];

  for (const el of previous) {
    if (!idSet.has(el.id)) {
      idSet.add(el.id);
      resultArray.push({ ...el });
    }
  }

  for (const el of next) {
    if (!idSet.has(el.id)) {
      idSet.add(el.id);
      resultArray.push({ ...el });
    }
  }

  return resultArray;
}
