import { Menu, Segment } from 'semantic-ui-react';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/header.css');
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/segment.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');

const COMPONENT_NAME = 'CreditsPageMainSection';

const StyledSection = styled('section')`
  .segment {
    min-height: 400px;
    padding: 15px;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class CreditsPageMainSection extends PureComponent {
  static propTypes = {};

  static defaultProps = {};

  render() {
    return (
      <StyledSection className={COMPONENT_NAME}>
        <Menu attached="top">
          <Menu.Item header icon="smile" content="Credits & Attributions" />
        </Menu>
        <Segment attached="bottom" className={cn('segment')}>
          <h2 style={{ color: 'red' }}>Coming Soon...</h2>
        </Segment>
      </StyledSection>
    );
  }
}
