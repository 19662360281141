import { arrayOfShape, queryShape, userShape } from '../../utils/PropTypeUtils';
import { arrayOf, func, object, shape, string } from 'prop-types';
import { Grid, Loader, Menu, Segment } from 'semantic-ui-react';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { get, has } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { isNil } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { isValid as isValidIdentifier } from '@omniex/onx-common-js/lib/utils/IdentifierUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { orderBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './AdminPageIntegrationsSection.copyText';
import CreateExchangeAccountsForm from '../components/CreateExchangeAccountsForm';
import IntegrationsTable from '../components/IntegrationsTable';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import populateAccountsWithCurrency from '../../selectors/populateAccountsWithCurrency';
import SaveIntegrationForm from '../components/SaveIntegrationForm';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/header.css');
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/loader.css');
require('@omniex/onx-common-ui/lib/semantic/css/grid.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');
require('@omniex/onx-common-ui/lib/semantic/css/segment.css');

const ERR_CREATE_PARTIAL = 'errorMessageCreateAccountsPartial';
const ERR_CREATE_UNEXPECTED = 'errorMessageCreateAccountsUnexpected';
const ERR_REQ_FAILED = 'errorMessageRequestIntegrationFailed';
const ERR_SAVE_FAILED = 'errorMessageSaveIntegrationFailed';
const ERR_SAVE_INVALID = 'errorMessageSaveIntegrationInvalidCredentials';
const ERR_UPDATE_FAILED = 'errorMessageUpdateIntegrationCredentialsFailed';
const ERR_UPDATE_INVALID = 'errorMessageUpdateIntegrationCredentialsInvalidCredentials';
const ACCOUNTS_CREATED = 'successMessageAccountsCreated';
const INTEGRATION_REQUESTED = 'successMessageIntegrationRequested';
const INTEGRATION_SAVED = 'successMessageIntegrationSaved';
const INTEGRATION_UPDATED = 'successMessageIntegrationCredentialsUpdated';

const COMPONENT_NAME = 'AdminPageIntegrationsSection';

const StyledSection = styled('section')`
  /* min-width: 0; TODO: determine suitable min-width */

  .${COMPONENT_NAME}-grid {
    min-height: 100px;
  }

  .${COMPONENT_NAME}-formWrapper {
    display: flex;
    justify-content: flex-end;
    margin-right: 15px;
  }

  .${COMPONENT_NAME}-loader {
    color: ${colors.grey};
  }

  .${COMPONENT_NAME}-loaderWrapper {
    display: flex;
    justify-content: center;
    padding: 100px 0;
  }

  .${COMPONENT_NAME}-segment {
    padding: 0;
  }

  .${COMPONENT_NAME}-errorMessage + .${COMPONENT_NAME}-segment {
    border-top-color: ${colors.errorBorderColor};
  }

  .${COMPONENT_NAME}-successMessage + .${COMPONENT_NAME}-segment {
    border-top-color: ${colors.successBorderColor};
  }

  /* TODO: temporary hack. This should be supported by ui/kit/Table itself */

  /* prettier-ignore */
  tr {
    td, th {
      &:first-child {
        width: 250px !important;
      }

      &:nth-child(2) {
        width: 150px;
      }
    }
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

const initState = _ => ({
  isEditingCredentials: true,
  isEditingCredentialsForView: true,
  errorMessageKey: null,
  successMessageKey: null,
  showSaveIntegrationForm: null,
  selectedIntegrationId: null,
})

export default class AdminPageIntegrationsSection extends PureComponent {
  static propTypes = {
    accountsQuery: queryShape({
      accounts: arrayOfShape({
        currency: shape({ id: string.isRequired })
      })
    }),
    createAccounts: func,
    createAccountsMutation: queryShape({ accounts: arrayOf(object) }),
    createRequestedIntegration: func,
    createRequestedIntegrationMutation: queryShape(object, false),
    currenciesQuery: queryShape({
      currencies: arrayOfShape({
        id: string.isRequired,
        type: string.isRequired,
        symbol: string.isRequired,
        name: string.isRequired
      })
    }),
    deleteIntegration: func,
    exchangeAccountsQuery: queryShape({
      exchangeAccounts: arrayOfShape({
        currency: shape({
          id: string.isRequired
        })
      })
    }),
    fetchAccounts: func,
    fetchExchangeAccounts: func,
    integrationsQuery: queryShape({
      integrations: arrayOfShape({ id: string.isRequired })
    }),
    orgQuery: queryShape({ org: object }),
    portfolio: object,
    saveIntegration: func,
    saveIntegrationMutation: queryShape(object, false),
    updateIntegrationCredentials: func,
    updateIntegrationCredentialsMutation: queryShape(object, false),
    updateIntegrationSettings: func,
    updateIntegrationSettingsMutation: queryShape(object, false),
    user: userShape,
  };

  static defaultProps = {
    createAccounts: noop,
    createRequestedIntegration: noop,
    deleteIntegration: noop,
    fetchAccounts: noop,
    fetchExchangeAccounts: noop,
    saveIntegration: noop,
    updateIntegrationCredentials: noop,
    updateIntegrationSettings: noop,
  };

  state = initState();

  _isMounted = true;

  render() {
    const loading = this.props.integrationsQuery.loading || !has(this.props, 'integrationsQuery.data.integrations');
    let integrations = get(this.props, 'integrationsQuery.data.integrations', []);
    integrations = orderBy(integrations, ['type']);

    return (
      <StyledSection className={COMPONENT_NAME}>
        <Menu attached="top">
          <Menu.Item content={copyText.sectionTitle} header icon="globe" />
          <Menu.Menu position="right">
            <Menu.Item
              disabled={loading}
              content={copyText.rightMenuItemLabel}
              icon="add"
              onClick={this._handleToggleSaveIntegrationForm.bind(this, undefined)}
            />
          </Menu.Menu>
        </Menu>
        {this.state.errorMessageKey ? (
          <Message
            className={cn('errorMessage')}
            attached
            closeOnClick
            content={copyText[this.state.errorMessageKey]}
            error
            icon="warning sign"
          />
        ) : null}
        {this.state.successMessageKey ? (
          <Message
            className={cn('successMessage')}
            attached
            closeOnClick
            content={<span>{this._parseSuccessMessage()}</span>}
            icon="check circle outline"
            success
          />
        ) : null}
        <Segment className={cn('segment')} attached="bottom">
          <Grid className={cn('grid')} celled="internally">
            {loading ? (
              <Grid.Row columns={1}>
                <Grid.Column width={16}>
                  <Loader
                    className={cn('loader')}
                    active
                    content={copyText.loadingMessage}
                    size="medium"
                  />
                </Grid.Column>
              </Grid.Row>
            ) : (
              <Grid.Row columns={this.state.showSaveIntegrationForm ? 2 : 1}>
                <Grid.Column
                  width={this.state.showSaveIntegrationForm ? 12 : 16}>
                  <IntegrationsTable
                    addReadOnlyCredentials={this._addReadOnlyCredentials} // NOTE: Temporary to migrate existing clients to the two-credentials model
                    integrations={integrations}
                    isEditingCredentials={this.state.isEditingCredentials}
                    isEditingCredentialsForView={this.state.isEditingCredentialsForView}
                    renderExpandedContent={this._renderCreateExchangeAccountsForm}
                    selectedIntegrationId={this.state.selectedIntegrationId}
                    showSaveIntegrationForm={this.state.showSaveIntegrationForm}
                    onDelete={this._handleDeleteIntegration}
                    onEditSettings={this._handleEditSettings}
                    onOpenEditForm={this._handleToggleSaveIntegrationForm}
                    onSelected={this._handleSelectedIntegration}
                    onUpdateIntegrationSettings= {this._handleUpdateIntegrationSettings}
                  />
                </Grid.Column>
                {this.state.showSaveIntegrationForm && (
                  <Grid.Column width={4}>
                    <SaveIntegrationForm
                      existingIntegrations={integrations}
                      integration={
                        isValidIdentifier(this.state.showSaveIntegrationForm)
                          ? integrations.find(integration => integration.id === this.state.showSaveIntegrationForm)
                          : null
                      }
                      isEditingCredentials={this.state.isEditingCredentials}
                      isEditingCredentialsForView={this.state.isEditingCredentialsForView}
                      isErrorDisplaying={Boolean(this.state.errorMessageKey)}
                      processing={this.props.saveIntegrationMutation.loading}
                      requestOrgName={this.props.user.org.name}
                      requestUsername={this.props.user.name}
                      size="small"
                      onCancel={this._handleToggleSaveIntegrationForm}
                      onRequest={this._handleCreateRequestedIntegration}
                      onSave={this._handleSaveIntegration}
                    />
                  </Grid.Column>
                )}
              </Grid.Row>
            )}
          </Grid>
        </Segment>
      </StyledSection>
    );
  }

  // NOTE: Temporary to migrate existing clients to the two-credentials model
  _addReadOnlyCredentials = integration => {
    this.setState(currentState => ({
      isEditingCredentials: false,
      isEditingCredentialsForView: true,
      showSaveIntegrationForm: currentState.showSaveIntegrationForm !== integration.id ? integration.id : undefined,
      selectedIntegrationId: currentState.selectedIntegrationId === integration.id ? integration.id : undefined
    }));
  }

  _renderCreateExchangeAccountsForm = integration => {
    if (isNil(integration.credentialsForViewPreview)) return null; // NOTE: Temporary to migrate existing clients to the two-credentials model

    const existingCurrencyIds = get(this.props, 'accountsQuery.data.accounts', []).map(account => account.currency.id);

    const exchangeAccounts = populateAccountsWithCurrency(
      get(this.props, 'exchangeAccountsQuery.data.exchangeAccounts', []).filter(
        exchangeAccount => !existingCurrencyIds.includes(get(exchangeAccount, 'currency.id'))
      ),
      get(this.props, 'currenciesQuery.data.currencies', [])
    );

    const homeCurrencyId = get(this.props, 'orgQuery.data.org.homeCurrency.id');

    const loading = get(this.props, 'exchangeAccountsQuery.loading') || get(this.props, 'accountsQuery.loading');

    if (loading) {
      return (
        <div className={cn('loaderWrapper')}>
          <Loader className={cn('loader')} inline active size="small" />
        </div>
      );
    }

    return (
      <div className={cn('formWrapper')}>
        <CreateExchangeAccountsForm
          exchangeAccounts={exchangeAccounts}
          homeCurrencyId={homeCurrencyId}
          integration={integration}
          onCancel={this._handleUnselectIntegration}
          onSubmit={this._handleCreateAccounts.bind(this)}
        />
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.portfolio !== prevProps.portfolio) this.setState(initState());
    if (prevState.selectedIntegrationId !== this.state.selectedIntegrationId && isValidIdentifier(this.state.selectedIntegrationId)) {
      this.props.fetchExchangeAccounts({ integrationId: this.state.selectedIntegrationId });

      const integration = get(this.props, 'integrationsQuery.data.integrations', [])
        .find(integration => integration.id === this.state.selectedIntegrationId);

      const custodianId = get(integration, 'provider.id');
      if (isValidIdentifier(custodianId)) {
        this.props.fetchAccounts({ custodianId });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _setState = (state, cb) => this._isMounted && this.setState(state, cb) // for updating state in async callbacks

  _handleCreateAccounts = async exchangeAccountIds => {
    const homeCurrencyId = get(this.props, 'orgQuery.data.org.homeCurrency.id');

    const exchangeAccounts = get(this.props, 'exchangeAccountsQuery.data.exchangeAccounts')
      .filter(account => exchangeAccountIds.includes(account.id) || account.currency.id === homeCurrencyId);

    const accounts = exchangeAccounts.map(account => ({
      // adjustedCostBasis:
      //   account.currency.id === homeCurrencyId
      //     ? 0
      //     : parseFloat(input[`adjustedCostBasis_${account.id}`]),
      adjustedCostBasis: 0,
      currencyId: account.currency.id,
      custodianId: account.custodian.id,
      integrationId: this.state.selectedIntegrationId,
      quantity: account.quantity
    }));

    this._setState({ errorMessageKey: undefined, successMessageKey: undefined});

    try {
      const { data } = await this.props.createAccounts({ accounts });
      if (!data || !has(data, 'accounts')) return this._setState({ selectedIntegrationId: undefined, errorMessageKey: ERR_CREATE_UNEXPECTED });
      const existingCurrencyIds = get(this.props, 'accountsQuery.data.accounts', []).map(account => account.currency.id);
      const exchangeAccounts = get(this.props, 'exchangeAccountsQuery.data.exchangeAccounts', []).filter(exchangeAccount => !existingCurrencyIds.includes(get(exchangeAccount, 'currency.id')));
      if (exchangeAccounts.length === get(data, 'accounts.length')) this._setState({ selectedIntegrationId: undefined, successMessageKey: ACCOUNTS_CREATED });
      else this._setState({ selectedIntegrationId: undefined, errorMessageKey: ERR_CREATE_PARTIAL })
    } catch (error) {
      this._setState({ selectedIntegrationId: undefined, errorMessageKey: ERR_CREATE_UNEXPECTED });
    }

  };

  _handleCreateRequestedIntegration = async input => {
    this._setState({ errorMessageKey: undefined, successMessageKey: undefined });

    try {
      const { data } = await this.props.createRequestedIntegration(input);
      if (!data || !has(data, 'integration')) return this._setState({ errorMessageKey: ERR_REQ_FAILED });
      this._setState({ showSaveIntegrationForm: false, successMessageKey: INTEGRATION_REQUESTED });
    } catch (error) {
      this._setState({ errorMessageKey: ERR_REQ_FAILED });
    }

  };

  // NOTE: currently not in use
  _handleDeleteIntegration = id => {
    this.setState({ errorMessageKey: undefined, successMessageKey: undefined });
    this.props.deleteIntegration(id);
  };

  _handleEditSettings = ({ integrationId, isEditingCredentials, isEditingCredentialsForView }) => {
    this.setState(currentState => {
      let showSaveIntegrationForm;

      const isEditingSameIntegration = currentState.showSaveIntegrationForm === integrationId;

      // Close SaveIntegrationForm if you click the same edit button twice
      if (isEditingSameIntegration && currentState.isEditingCredentials === isEditingCredentials && currentState.isEditingCredentialsForView === isEditingCredentialsForView) {
        showSaveIntegrationForm = false;
      }

      // Keep SaveIntegrationForm open and toggle between editing credentials & credentialsForView
      else if (isEditingSameIntegration && (currentState.isEditingCredentials || currentState.isEditingCredentialsForView)) {
        showSaveIntegrationForm = integrationId;
      }

      // Open (showSaveIntegrationForm = integrationId) or close (showSaveIntegrationForm = undefined) SaveIntegrationForm
      else {
        showSaveIntegrationForm = !isEditingSameIntegration ? integrationId : false;
      }

      return {
        selectedIntegrationId: undefined,
        isEditingCredentials,
        isEditingCredentialsForView,
        showSaveIntegrationForm
      };
    });
  };

  _handleSaveIntegration = async input => {
    this._setState({ errorMessageKey: undefined, successMessageKey: undefined });
    const isNewIntegration = this.state.isEditingCredentials && this.state.isEditingCredentialsForView;
    if (isNewIntegration) {

      const result = await this.props.saveIntegration(input);
      if (!result || !has(result, 'data.integration')) {
        const errorMessageKey = get(result, 'error.message', '').match(/parameters.key,parameters.secret/i) ? ERR_SAVE_INVALID : ERR_SAVE_FAILED;
        this._setState({ errorMessageKey });
      } else {
        this._setState({ showSaveIntegrationForm: false, selectedIntegrationId: get(result, 'data.integration.id'), successMessageKey: INTEGRATION_SAVED });
      }

    } else {

      const result = await this.props.updateIntegrationCredentials(input);
      if (!result || !has(result, 'data.integration')) {
        const errorMessageKey = get(result, 'error.message', '').match(/parameters.key,parameters.secret/i) ? ERR_UPDATE_INVALID : ERR_UPDATE_FAILED;
        this._setState({ errorMessageKey });
      } else {
        this._setState({ showSaveIntegrationForm: false, selectedIntegrationId: get(result, 'data.integration.id'), successMessageKey: INTEGRATION_UPDATED });
      }

    }
  };

  _handleSelectedIntegration = integration => {
    this.setState({
      isEditingCredentials: undefined,
      isEditingCredentialsForView: undefined,
      showSaveIntegrationForm: undefined,
      errorMessageKey: undefined,
      selectedIntegrationId:
        integration.id === this.state.selectedIntegrationId
          ? undefined
          : integration.id,
      successMessageKey: undefined
    });
  };

  _handleToggleSaveIntegrationForm = integrationId => {
    this.setState(currentState => ({
      selectedIntegrationId: undefined,
      isEditingCredentials: true,
      isEditingCredentialsForView: true,
      showSaveIntegrationForm: integrationId
        ? currentState.showSaveIntegrationForm === integrationId
          ? undefined
          : integrationId
        : !currentState.showSaveIntegrationForm
    }));
  };

  _handleUnselectIntegration = () => {
    this.setState({
      selectedIntegrationId: undefined,
      isEditingCredentials: false,
      isEditingCredentialsForView: false
    });
  };

  _handleUpdateIntegrationSettings = (id, settings) => {
    this.setState({
      errorMessageKey: undefined,
      successMessageKey: undefined,
    })

    this.props.updateIntegrationSettings({id, settings});
  }

  _parseSuccessMessage = () => {
    const successMessageCopy = copyText[this.state.successMessageKey];
    const integrationLabel = copyText[get(this.props, 'saveIntegrationMutation.data.integration.type') || get(this.props, 'updateIntegrationCredentialsMutation.data.integration.type')];
    return successMessageCopy.replace('%integrationType%', integrationLabel);
  };
}
