import { useMutation, useQuery, useSubscription, useLazyQuery } from 'react-apollo';
import React, { useState, useEffect, useCallback } from 'react';

import { ERROR_PERMISSION_DENIED } from '@omniex/onx-common-ui/lib/errors';
import { safeExecAsync } from '../utils/FunctionUtils';
import { cleanQuery } from '../utils/PropTypeUtils';
import configureMarketDataFeedMutation from '../apollo/graphql/buy-side/configureMarketDataFeed';
import ErrorPage from '@omniex/onx-common-ui/lib/pages/ErrorPage';
import getAccountsWithBalances from '../apollo/graphql/getAccountsWithBalances';
import getCurrencies from '../apollo/graphql/getCurrencies';
import getCurrentPrices from '../apollo/graphql/getCurrentPrices';
import getInstrumentBySymbol from '../apollo/graphql/getInstrumentBySymbol';
import getIntegrations from '../apollo/graphql/getIntegrations';
import getOrgRelationshipsWithInstruments from '../apollo/graphql/getOrgRelationshipsWithInstruments';
import getRecentInputHistory from '../apollo/graphql/getRecentInputHistoryByPortfolioId';
import getTradeHistory from '../apollo/graphql/getTradeHistory';
import OrgType from '@omniex/poms-core/lib/enums/OrgType';
import placeAlgoOrderMutation from '../apollo/graphql/buy-side/placeOrderForAlgorithmicExecution';
import placeDmaOrderMutation from '../apollo/graphql/buy-side/placeOrderForSpecificExchange';
import refreshPortfolioBalances from '../apollo/graphql/refreshPortfolioBalances';
import refreshDataOnFill from '../apollo/graphql/buy-side/refreshDataOnFill';
import refreshMarketDataFeed from '../apollo/graphql/buy-side/refreshMarketDataFeed';
import TradePage from '../ui/pages/TradePage';
import updateRecentInputHistoryMutation from '../apollo/graphql/updateRecentInputHistoryByPortfolioId';
import getPaginatedOrders from '../apollo/graphql/buy-side/getPaginatedOrders';
import config from '../config';

const paginationVars = {
  params: {
    limit: 50,
    offset: 0,
    orderDirection: 'DESC',
    orderParameter: 'timeLastUpdated',
  },
};

const TradePageWithAuth = ({apiClient, newNotifications = [], notificationFixClOrdID = null, portfolio = {}, setNotificationFixClOrdID, fixApiClient, user, websocketConnected }) => {
  const useApiQuery = (q, o) => useQuery(q, { client: apiClient, ...o })
  const useApiMutation = (m, o) => useMutation(m, { client: apiClient, ...o })
  const useTakerMutation = (m, o) => useMutation(m, { client: fixApiClient, ...o })
  const useTakerSubscription = (s, o) => useSubscription(s, { client: fixApiClient, ...o })

  const orgId = user?.org?.id
  const portfolioId = portfolio?.id

  const [fetchCurrencies, qCurrencies] = useLazyQuery(getCurrencies, { client: apiClient })
  const [fetchInstrumentBySymbol, qInstrumentBySymbol] = useLazyQuery(getInstrumentBySymbol, { client: apiClient, fetchPolicy: 'network-only' })
  const qIntegrations = useApiQuery(getIntegrations, {skip: !portfolioId, fetchPolicy: 'network-only', variables: { portfolioId }})
  const qOrg = useApiQuery(getOrgRelationshipsWithInstruments, {skip: !orgId, fetchPolicy: 'network-only', variables: { portfolioId }})
  const [fetchAccounts, qAccounts] = useLazyQuery(getAccountsWithBalances, { client: apiClient, fetchPolicy: 'network-only',  variables: { portfolioId } })
  const qPrices = useApiQuery(getCurrentPrices)
  const qTradeHistory = useApiQuery(getTradeHistory)
  const qInputHistory = useApiQuery(getRecentInputHistory, { variables: { portfolioId }})
  const [updateInputHistory, ] = useApiMutation(updateRecentInputHistoryMutation)
  const [fetchOrders, qOrders] = useLazyQuery(getPaginatedOrders, { client: apiClient, fetchPolicy: 'network-only', variables: { portfolioId, ...paginationVars } })

  const skip = user?.org?.type !== OrgType.CLIENT_BUY_SIDE


  const [placeAlgoOrder, mPlaceAlgoOrder] = useTakerMutation(placeAlgoOrderMutation, {skip})
  const [placeDmaOrder, mPlaceDmaOrder] = useTakerMutation(placeDmaOrderMutation, {skip})

  const [configureMarketDataFeed, ] = useTakerMutation(configureMarketDataFeedMutation, {skip})

  const sMarketData = useTakerSubscription(refreshMarketDataFeed, {skip, fetchPolicy: 'no-cache'})
  const sExecutions = useTakerSubscription(refreshDataOnFill)

  const [awaitingAccountBalancesRefetch, setAwaitingAccountBalancesRefetch] = useState(false)
  const [disconnectedRefreshDataOnFill, setDisconnectedRefreshDataOnFill] = useState(undefined);

  const handleAccountRefetch = useCallback(() => {
    const { called, loading, refetch } = qAccounts;
    if (document.hasFocus() && called && !loading) {
      setAwaitingAccountBalancesRefetch(false);
      refetch();
    }
  }, [qAccounts]);

  useEffect(() => {
    const handleFocus = () => {
      if (awaitingAccountBalancesRefetch) {
        handleAccountRefetch()
      }
    }

    window.addEventListener('focus', handleFocus);

    return () => {
      window.removeEventListener('focus', handleFocus)
    }
  }, [awaitingAccountBalancesRefetch, handleAccountRefetch])

  const onRefreshPortfolioBalances = () => {
    if (!document.hasFocus()) {
      // If Edge is not in focus, save the refetch for when the user gets back into focus
      setAwaitingAccountBalancesRefetch(true);
    } else {
      // Only refresh account/portfolio balances if user is actively using Edge to 
      // reduce unnecessary network requests, database queries, requests to exchanges
      handleAccountRefetch();
    }
  };

  useTakerSubscription(refreshPortfolioBalances, { variables: { portfolioId }, onSubscriptionData: onRefreshPortfolioBalances })

  useEffect(() => {
    let id;
    if (!websocketConnected) {
      const onInterval = () => setDisconnectedRefreshDataOnFill(new Date().toISOString());
      id = setInterval(onInterval, config.pollIntervals.executionReport);
    } else {
      setDisconnectedRefreshDataOnFill(undefined);
    }
    return () => id && clearInterval(id);
  }, [websocketConnected])

  return (
    <TradePage
      accountsWithBalancesQuery={                 qAccounts}
      apiClient={                                 apiClient}
      configureMarketDataFeed={                   variables => safeExecAsync(configureMarketDataFeed, {variables})}
      currenciesQuery={                           qCurrencies}
      currentPricesQuery={                        cleanQuery(qPrices)}
      fetchAccounts={                             variables => safeExecAsync(fetchAccounts, variables)}
      fetchCurrencies={                           _ => safeExecAsync(fetchCurrencies)}
      fetchCurrentPrices={                        variables => safeExecAsync(qPrices?.refetch, variables)}
      fetchOrders={                               variables => safeExecAsync(fetchOrders, variables)}
      fetchInstrumentBySymbol={                   variables => safeExecAsync(fetchInstrumentBySymbol, variables)}
      fetchTradeHistory={                         variables => safeExecAsync(qTradeHistory?.refetch, variables)}
      instrumentBySymbolQuery={                   qInstrumentBySymbol}
      integrationsQuery={                         cleanQuery(qIntegrations)}
      newNotifications={                          newNotifications}
      notificationFixClOrdID={                    notificationFixClOrdID}
      marketDataFeedSubscription={                cleanQuery(sMarketData)}
      orgQuery={                                  qOrg}
      paginatedOrdersQuery={                      qOrders}
      placeOrderForAlgorithmicExecution={         variables => safeExecAsync(placeAlgoOrder, {variables: {portfolioId, ...variables}})}
      placeOrderForAlgorithmicExecutionMutation={ cleanQuery(mPlaceAlgoOrder)}
      placeOrderForSpecificExchange={             variables => safeExecAsync(placeDmaOrder, {variables: {portfolioId, ...variables}})}
      placeOrderForSpecificExchangeMutation={     cleanQuery(mPlaceDmaOrder)}
      recentInputHistoryQuery={                   qInputHistory}
      refreshDataOnFill={                         disconnectedRefreshDataOnFill ?? cleanQuery(sExecutions)?.data?.refreshDataOnFill}
      setNotificationFixClOrdID={                 setNotificationFixClOrdID}
      takerApiClient={                            fixApiClient}
      tradeHistoryQuery={                         cleanQuery(qTradeHistory)}
      updateRecentInputHistory={                  ({ inputs: userInput }) => safeExecAsync(updateInputHistory, { variables: { inputs: { portfolioId, userInput }}})}
      user={                                      user}
      portfolio={                                 portfolio}
    />
  )
}

const TradePageContainer = ({user, ...props}) => user?.canViewTrade
  ? <TradePageWithAuth user={user} {...props} />
  : <ErrorPage type={ERROR_PERMISSION_DENIED} />

export default TradePageContainer
