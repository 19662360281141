const saveIntegrationsFormCopy = {
  cancelButtonLabel: 'Cancel',
  confirmationMessageContent: 'Please confirm that the API Key(s) you entered have the correct permissions.',
  credentialsMessage: 'You have already submitted all the necessary API Keys & Secrets.',
  errorMessage: "Sorry, we're unable to save your API Key & Secret at this time",
  inputPlaceholder_type: 'Integration',
  integrationTypeOption_CUSTODIAN_SILVERGATE: 'Silvergate Custodian',
  integrationTypeOption_EXCHANGE_BEQUANT: 'BeQuant Exchange',
  integrationTypeOption_EXCHANGE_BINANCE: 'Binance Exchange',
  integrationTypeOption_EXCHANGE_BINANCEUS: 'Binance US Exchange',
  integrationTypeOption_EXCHANGE_BITFINEX: 'Bitfinex Exchange',
  integrationTypeOption_EXCHANGE_BITFLYER: 'Bitflyer Exchange',
  integrationTypeOption_EXCHANGE_BITMEX: 'BitMEX Exchange',
  integrationTypeOption_EXCHANGE_BITSTAMP: 'Bitstamp Exchange',
  integrationTypeOption_EXCHANGE_BITTREX: 'Bittrex Exchange',
  integrationTypeOption_EXCHANGE_BYBIT: 'Bybit Exchange',
  integrationTypeOption_EXCHANGE_CROSSTOWER: 'CrossTower Exchange',
  integrationTypeOption_EXCHANGE_DERIBIT: 'Deribit Exchange',
  integrationTypeOption_EXCHANGE_FTX: 'FTX Exchange',
  integrationTypeOption_EXCHANGE_GDAX: 'Coinbase Pro Exchange',
  integrationTypeOption_EXCHANGE_GEMINI: 'Gemini Exchange',
  integrationTypeOption_EXCHANGE_HITBTC: 'HitBTC Exchange',
  integrationTypeOption_EXCHANGE_HUOBI: 'Huobi Exchange',
  integrationTypeOption_EXCHANGE_HUOBIDM: 'Huobi DM Exchange',
  integrationTypeOption_EXCHANGE_ITBIT: 'itBit Exchange',
  integrationTypeOption_EXCHANGE_KRAKEN: 'Kraken Exchange',
  integrationTypeOption_EXCHANGE_KUCOIN: 'KuCoin Exchange',
  integrationTypeOption_EXCHANGE_LMAX: 'LMAX Exchange',
  integrationTypeOption_EXCHANGE_LIQUID: 'Liquid Exchange',
  integrationTypeOption_EXCHANGE_OKCOIN: 'OKCoin Exchange',
  integrationTypeOption_EXCHANGE_OKEX: 'OKEx Exchange',
  integrationTypeOption_EXCHANGE_POLONIEX: 'Poloniex Exchange',
  integrationTypeOption_EXCHANGE_SEEDCX: 'Seed CX Exchange',
  requestDateTimezone: 'UTC',
  requestHeader: 'Account Integration Request:',
  requestItemDate: 'Request Date: ',
  requestItemHolder: 'Account Holder: ',
  requestItemProvider: 'Account Provider: ',
  requestItemUser: 'Requesting Party: ',
  requestMessageContent: 'Once requested, the Omniex Operations team will review and confirm the Integration.',
  saveButtonLabel: 'Submit'
};

export default saveIntegrationsFormCopy;
