import * as React from 'react';
import { Fragment, useMemo } from 'react';

import { Button, Icon } from 'semantic-ui-react';
import { makeStyles } from '@omniex/poms-ui/lib/themes';
import moment from 'moment';

// @ts-ignore
import { colors } from '@omniex/onx-common-ui/lib/styles';
// @ts-ignore
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
// @ts-ignore
import Table from '@omniex/onx-common-ui/lib/semantic/react/Table';

import { EdgeApiKey } from '../../../types';
import copyText from './copyText';

const useStyles = makeStyles((theme) => ({
  buttonColumn: {
    textAlign: 'center !important' as any
  },
  deleteButton: {
    background: 'none !important',
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0),
  },
  deleteIcon: {
    color: colors.blue,
    fontSize: '1.4em !important',
    opacity: 1,
  },
  loader: {
    color: colors.grey
  },
  textColumn: {
    paddingLeft: theme.spacing(0.5)
  },
}));

type ApiKeyTableProps = {
  apiKeys: Array<EdgeApiKey> | undefined
  onClickDisable: (apiKey: EdgeApiKey, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const ApiKeyTable: React.FC<ApiKeyTableProps> = ({
  apiKeys,
  onClickDisable
}) => {
  const classes = useStyles();

  const apiKeysList = useMemo<Array<EdgeApiKey>>(
    () => Array.from(apiKeys || []).sort((a, b) => a.timeCreated > b.timeCreated ? 1 : -1),
    [apiKeys]
  );

  return (
    <div>
      {apiKeysList.length > 0 ? (
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className={classes.textColumn}>{copyText.tableHeaderLabel}</Table.HeaderCell>
              <Table.HeaderCell>{copyText.tableHeaderKey}</Table.HeaderCell>
              <Table.HeaderCell>{copyText.tableHeaderTimeCreated}</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              apiKeysList.map(k => (
                <Fragment key={k.id}>
                  <Table.Row>
                    <Table.Cell width={4} className={classes.textColumn}>
                      {k.name}
                    </Table.Cell>
                    <Table.Cell width={5} className={classes.textColumn}>
                      {k.key}
                    </Table.Cell>
                    <Table.Cell width={2} className={classes.textColumn}>
                      {moment(k.timeCreated).format('lll')}
                    </Table.Cell>
                    <Table.Cell width={4} className={classes.buttonColumn}>
                      <Button
                        className={classes.deleteButton}
                        content={<Icon className={classes.deleteIcon} name="trash" />}
                        onClick={(e) => onClickDisable(k, e)}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Fragment>
              ))
            }
          </Table.Body>
        </Table>
      ) : (
        <Message content={copyText.noApiKeys} warning />
      )}
    </div>
  )
}

export default ApiKeyTable;
