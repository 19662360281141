import { Loader } from 'semantic-ui-react';
import { string } from 'prop-types';
import React, { Component } from 'react';
import styled from 'react-emotion';

import { colors } from '@omniex/onx-common-ui/lib/styles';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/loader.css');
require('@omniex/onx-common-ui/lib/semantic/css/dimmer.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const COMPONENT_NAME = 'RedirectingPage';

const StyledDiv = styled('div')`
  align-items: center;
  display: flex;
  height: 80vh;
  justify-content: center;

  .${COMPONENT_NAME}-loader {
    color: ${colors.grey};
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class RedirectingPage extends Component {
  static propTypes = {
    href: string.isRequired
  };

  componentWillMount() {
    window.location.href = window.encodeURI(this.props.href);
  }

  render() {
    return (
      <StyledDiv className={COMPONENT_NAME}>
        <Loader className={cn('loader')} active inline="centered" size="medium">
          Redirecting...
        </Loader>
      </StyledDiv>
    );
  }
}
