import { bool, func, object } from 'prop-types';
import { Grid, Menu, Segment } from 'semantic-ui-react';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { has } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { isEmpty, isError } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './SettingsPageUserInfoSection.copyText';
import ChangePasswordForm from '../components/ChangePasswordForm';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import UserInfoComponent from '../components/UserInfoComponent';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/segment.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const COMPONENT_NAME = 'SettingsPageUserInfoSection';

const StyledSection = styled('section')`
  /* min-width: 0; TODO: determine suitable min-width */

  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;

  .${COMPONENT_NAME}-segment {
    flex: 1 !important;
    padding-top: 0em !important;
    padding-bottom: 0em !important;
  }

  .${COMPONENT_NAME}-successMessage + .${COMPONENT_NAME}-segment {
    border-top-color: ${colors.successBorderColor};
  }

  .${COMPONENT_NAME}-errorMessage + .${COMPONENT_NAME}-segment {
    border-top-color: ${colors.errorBorderColor};
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

const ERROR_MESSAGE_KEY_UPDATE_AUTHENTICATED_USER_PASSWORD_FAILED =
  'errorMessageUpdateAuthenticatedUserPasswordFailed';
const SUCCESS_MESSAGE_KEY_UPDATED_AUTHENTICATED_USER_PASSWORD =
  'successMessageUpdatedAuthenticatedUserPassword';

export default class SettingsPageUserInfoSection extends PureComponent {
  static propTypes = {
    updateAuthenticatedUserPassword: func,
    updateAuthenticatedUserPasswordMutationError: object,
    updateAuthenticatedUserPasswordMutationProcessing: bool,
    updateAuthenticatedUserPasswordMutationResult: object,
    user: object
  };

  static defaultProps = { updateAuthenticatedUserPassword: noop };

  state = {};

  static getDerivedStateFromProps(nextProps, prevState) {
    const stateChanges = {};

    if (isEmpty(prevState)) {
      return getInitialState(nextProps);
    }

    if (
      prevState.updateAuthenticatedUserPasswordMutationProcessing !==
      nextProps.updateAuthenticatedUserPasswordMutationProcessing
    ) {
      stateChanges.updateAuthenticatedUserPasswordMutationProcessing =
        nextProps.updateAuthenticatedUserPasswordMutationProcessing;
    }

    if (
      stateChanges.updateAuthenticatedUserPasswordMutationProcessing === false
    ) {
      if (
        isError(nextProps.updateAuthenticatedUserPasswordMutationError) ||
        !has(nextProps, 'updateAuthenticatedUserPasswordMutationResult.user')
      ) {
        stateChanges.errorMessageKey = ERROR_MESSAGE_KEY_UPDATE_AUTHENTICATED_USER_PASSWORD_FAILED;
      } else {
        stateChanges.successMessageKey = SUCCESS_MESSAGE_KEY_UPDATED_AUTHENTICATED_USER_PASSWORD;
      }
    }

    return stateChanges;
  }

  render() {
    return (
      <StyledSection className={COMPONENT_NAME}>
        {this.state.successMessageKey && (
          <Message
            className={cn('successMessage')}
            attached
            closeOnClick
            content={copyText[this.state.successMessageKey]}
            icon="check circle outline"
            success
          />
        )}
        {this.state.errorMessageKey && (
          <Message
            className={cn('errorMessage')}
            attached
            closeOnClick
            content={copyText[this.state.errorMessageKey]}
            error
            icon="warning sign"
          />
        )}
        <Menu attached="top">
          <Menu.Item
            content={copyText.sectionTitle}
            header
            icon="info circle"
          />
          <Menu.Menu position="right">
            <Menu.Item
              disabled={false}
              content={'Change Password'}
              icon="lock"
              onClick={this._handleToggleChangePasswordForm.bind(this)}
            />
          </Menu.Menu>
        </Menu>
        <Segment className={cn('segment')} attached="bottom">
          <Grid className={cn('grid')} celled="internally">
            <Grid.Row columns={this.state.showChangePasswordForm ? 2 : 1}>
              <Grid.Column width={this.state.showChangePasswordForm ? 12 : 16}>
                <UserInfoComponent user={this.props.user} />
              </Grid.Column>
              {this.state.showChangePasswordForm && (
                <Grid.Column width={4}>
                  <ChangePasswordForm
                    handleClickCancelButton={this._handleToggleChangePasswordForm.bind(this)}
                    processing={this.props.updateAuthenticatedUserPasswordMutationProcessing}
                    onSave={this._handleSubmitChangePasswordForm.bind(this)}
                  />
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </Segment>
      </StyledSection>
    );
  }

  _handleSubmitChangePasswordForm(formInputs) {
    this.setState({
      errorMessageKey: undefined,
      successMessageKey: undefined
    });

    const { currentPassword, newPassword } = formInputs;

    this.props
      .updateAuthenticatedUserPassword({
        currentPassword,
        newPassword
      })
      .then(
        this.setState({
          showChangePasswordForm: false
        })
      );
  }

  _handleToggleChangePasswordForm() {
    this.setState(currentState => ({
      showChangePasswordForm: !currentState.showChangePasswordForm
    }));
  }
}

function getInitialState(props) {
  return {
    errorMessageKey: undefined,
    updateAuthenticatedUserPasswordMutationProcessing:
      props.updateAuthenticatedUserPasswordMutationProcessing,
    successMessageKey: undefined
  };
}
