import { createSelector } from 'reselect';

import { isValid as isValidIdentifier } from '@omniex/onx-common-js/lib/utils/IdentifierUtils';

export function getAccountOptions(accounts, currencyId) {
  if (
    !Array.isArray(accounts) ||
    accounts.length === 0 ||
    !isValidIdentifier(currencyId)
  )
    return [];

  return accounts.reduce((options, account) => {
    return account.currency.id === currencyId
      ? [
          ...options,
          {
            value: account.id,
            label: account.name
          }
        ]
      : options;
  }, []);
}

export default createSelector(
  (accounts, currencyId) => accounts,
  (accounts, currencyId) => currencyId,
  getAccountOptions
);
