import gql from 'graphql-tag';

const summary = 
	`slippageSummary {
		total_slippage_usd
		avg_ticket_slippage_usd
		avg_ticket_slippage_percent
		avg_ticket_slippage_bps
		total_filled_usd
		avg_ticket_filled_usd
	}`;

const tickets = 
	`tickets {
		client_id
		comp_id
		algo_id
		ticket_id
		order_status
		side
		instrument_id
		last_exec_id
		ticket_amount
		ticket_price
		duration
		amount_filled
		price_average
		preprice
		slippage
		num_fills
		create_time
		wgt_duration
		last_exec_time
		price_conv
		slippage_usd
		time_weighted
	}`;

const query = (config) => {
	const defaultConfig = { excludeSummary: false, excludeTickets: false };
	const { excludeSummary, excludeTickets } = config ? config : defaultConfig;
	return (
		gql`
		query getSlippageReport(
			$instrumentIds: [ID],
			$venueIds: [ID],
			$algoIds: [ID],
			$before: Int,
			$after: Int,
			$side: String,
			$orderStatus: String,
			$config: SlippageConfig,
			$portfolioId: ID,
		) {
			getSlippageReport(
				instrumentIds: $instrumentIds
				venueIds: $venueIds
				algoIds: $algoIds
				before: $before
				after: $after
				side: $side
				orderStatus: $orderStatus
				config: $config
				portfolioId: $portfolioId
			) {
				${ excludeSummary ? `` : summary }
				${ excludeTickets ? `` : tickets }
			}
		}
	`);
}

export default query
