import createSelector from 're-reselect';

import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { isEmpty } from '@omniex/onx-common-js/lib/utils/LangUtils';

const arrayIsValid = a => Array.isArray(a) && !isEmpty(a);

export function filterMarketDataEntriesByInstrumentsAndVenues(
  entries,
  instruments,
  venues
) {
  if (!arrayIsValid(entries) || !arrayIsValid(instruments)) return [];

  const mdInstrumentIds = instruments.reduce((map, i) => {
    map[get(i, 'mdInstrument.id') || i.id] = true;
    return map;
  }, {});

  let filteredEntries;

  if (arrayIsValid(venues)) {
    const venueSymbols = venues.reduce((map, v) => {
      map[v.symbol] = true;
      return map;
    }, {});

    filteredEntries = entries.filter(entry => (
      mdInstrumentIds[get(entry, 'instrument.id')] &&
      venueSymbols[entry.venueSymbol]
    ));
  } else {
    filteredEntries = entries.filter(entry =>
      mdInstrumentIds[get(entry, 'instrument.id')]
    );
  }

  return filteredEntries;
}

export default createSelector(
  (entries, instruments, venues) => entries,
  (entries, instruments, venues) => instruments,
  (entries, instruments, venues) => venues,
  filterMarketDataEntriesByInstrumentsAndVenues
)((entries, instruments, venues) =>
  instruments.map(i => i.id).join(' ').concat(
    arrayIsValid(venues) ? `:${venues.map(v => v.id).join(' ')}` : ''
  )
);
