import gql from 'graphql-tag';

export default gql`
  query getOrgRelationships($portfolioId: ID) {
    org: getOrg(portfolioId: $portfolioId) {
      id
      enableCustomOrderId
      enablePTWAPSE
      externalMarketDataSource
      fixSenderCompID
      homeCurrency {
        id
        symbol
      }
      name
      portfolios {
        id
        name
      }
      relationships {
        id
        org {
          id
          name
        }
        status
        venue {
          id
          name
          integrationType
          shortName
          supportsEsp
          supportsRfq
          symbol
          type
        }
      }
      takerApiBaseUrl
      type
      tz
    }
  }
`;
