import { arrayOf, bool, func, shape, string } from 'prop-types';
import { Button, Form } from 'semantic-ui-react';
import moment from '../../ext/moment';
import React, { Fragment, PureComponent } from 'react';
import styled from 'react-emotion';

import { get, has } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { isBlank } from '@omniex/onx-common-js/lib/utils/StringUtils';
import { isEmpty } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { keyBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import {
  needsFixClientId,
  needsFixCompId,
  needsThirdInput as needsPassphrase,
} from '@omniex/poms-core/lib/utils/IntegrationUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import config from '../../config/index';
import copyText from './SaveIntegrationForm.copyText';
import FormSelect from '@omniex/onx-common-ui/lib/semantic/react/FormSelect';
import IntegrationType from '@omniex/poms-core/lib/enums/IntegrationType';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import SaveCredentialsForm from './SaveCredentialsForm';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/button.css');
require('@omniex/onx-common-ui/lib/semantic/css/form.css');
require('@omniex/onx-common-ui/lib/semantic/css/input.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');
require('@omniex/onx-common-ui/lib/semantic/css/dropdown.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const supportedIntegrationTypes = [
  IntegrationType.CUSTODIAN_SILVERGATE,
  IntegrationType.EXCHANGE_BEQUANT,
  IntegrationType.EXCHANGE_BINANCE,
  IntegrationType.EXCHANGE_BINANCEUS,
  IntegrationType.EXCHANGE_BITFINEX,
  // IntegrationType.EXCHANGE_BITFLYER,
  IntegrationType.EXCHANGE_BITMEX,
  IntegrationType.EXCHANGE_BITSTAMP,
  IntegrationType.EXCHANGE_BITTREX,
  IntegrationType.EXCHANGE_BYBIT,
  // IntegrationType.EXCHANGE_CROSSTOWER,
  IntegrationType.EXCHANGE_DERIBIT,
  IntegrationType.EXCHANGE_FTX,
  IntegrationType.EXCHANGE_GDAX,
  IntegrationType.EXCHANGE_GEMINI,
  IntegrationType.EXCHANGE_HITBTC,
  IntegrationType.EXCHANGE_HUOBI,
  IntegrationType.EXCHANGE_HUOBIDM,
  // IntegrationType.EXCHANGE_ITBIT,
  IntegrationType.EXCHANGE_KRAKEN,
  IntegrationType.EXCHANGE_KUCOIN,
  // IntegrationType.EXCHANGE_LIQUID,
  IntegrationType.EXCHANGE_LMAX,
  // IntegrationType.EXCHANGE_OKCOIN,
  IntegrationType.EXCHANGE_OKEX,
  // IntegrationType.EXCHANGE_POLONIEX,
  // IntegrationType.EXCHANGE_SEEDCX,
];

const defaultIntegrationTypeOptions = supportedIntegrationTypes.map(
  integrationType => ({
    value: integrationType,
    label: copyText[`integrationTypeOption_${integrationType}`]
  })
);

const COMPONENT_NAME = 'SaveIntegrationForm';

const StyledForm = styled(Form)`
  min-width: 280px;

  .${COMPONENT_NAME}-buttonWrapper {
    text-align: right;
  }

  .${COMPONENT_NAME}-button {
    margin: 0;
  }

  .${COMPONENT_NAME}-confirmMessage {
    display: flex !important;
    white-space: normal;
  }

  .${COMPONENT_NAME}-requestContent {
    font-weight: bold;
  }

  .${COMPONENT_NAME}-requestInfo {
    border: 1px solid ${colors.solidBorderColor};
    border-radius: 0.28571429rem;
    padding: 10px;
  }

  .${COMPONENT_NAME}-requestRow {
    margin: 10px 0 10px 20px;
  }

  .ui.disabled.input {
    opacity: 1.45;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class SaveIntegrationForm extends PureComponent {
  static propTypes = {
    existingIntegrations: arrayOf(shape({ type: string })),
    integration: shape({
      id: string.isRequired,
      type: string.isRequired,
    }),
    isEditingCredentials: bool,
    isEditingCredentialsForView: bool,
    isErrorDisplaying: bool,
    processing: bool,
    requestOrgName: string,
    requestUsername: string,
    size: string,
    onCancel: func,
    onRequest: func,
    onSave: func,
  };

  static defaultProps = {
    onCancel: noop,
    onRequest: noop,
    onSave: noop,
  };

  state = {};

  static getDerivedStateFromProps(nextProps, prevState) {
    if (isEmpty(prevState)) return getInitialState(nextProps);

    const stateChanges = {};

    if (
      get(nextProps, 'integration') &&
      get(prevState, 'type') !== nextProps.integration.type
    ) {
      stateChanges.id = nextProps.integration.id;
      stateChanges.type = nextProps.integration.type;
    }

    if (
      prevState.isErrorDisplaying === false &&
      nextProps.isErrorDisplaying === true
    ) {
      stateChanges.isErrorDisplaying = nextProps.isErrorDisplaying;
      stateChanges.showSecondConfirmation = false;
    }

    if (
      prevState.isErrorDisplaying === true &&
      nextProps.isErrorDisplaying === false
    ) {
      stateChanges.isErrorDisplaying = nextProps.isErrorDisplaying;
    }

    return stateChanges;
  }

  render() {
    const existingIntegrationsKeyedByType = keyBy(
      this.props.existingIntegrations,
      'type'
    );

    const integrationTypeOptions = has(this.props, 'integration.type')
      ? defaultIntegrationTypeOptions.filter(
          option => option.value === this.props.integration.type
        )
      : defaultIntegrationTypeOptions.filter(
          // hacky fix for now, clients can have multiple integrations for a single custodian
          option => !existingIntegrationsKeyedByType[option.value] || option.value === IntegrationType.CUSTODIAN_SILVERGATE
        );

    return (
      <StyledForm
        className={COMPONENT_NAME}
        autoComplete="off"
        size={this.props.size}
        onSubmit={
          this.state.type === IntegrationType.CUSTODIAN_SILVERGATE
            ? this._handleRequestIntegration
            : this._handleSubmit
        }>
        <FormSelect
          name="type"
          isDisabled={
            has(this.props, 'integration.type') ||
            this.state.showSecondConfirmation
          }
          isSearchable={false}
          options={integrationTypeOptions}
          placeholder={copyText.inputPlaceholder_type}
          onChange={this._handleChangeField}
        />
        {this.state.type === IntegrationType.CUSTODIAN_SILVERGATE ? (
          this._renderSilvergateForm(
            this.props.requestOrgName,
            this.props.requestUsername
          )
        ) : (
          <Fragment>
            {this._renderSaveCredentialsForm({ isForView: false })}
            {this._renderSaveCredentialsForm({ isForView: true })}
          </Fragment>
        )}
        {this.state.showSecondConfirmation ? (
          <Fragment>
            <Message
              className={cn('confirmMessage')}
              content={copyText.confirmationMessageContent}
              warning
            />
            <Form.Field className={cn('buttonWrapper')}>
              <Button
                className={cn('button')}
                color="orange"
                disabled={!this._canSave()}
                fluid
                loading={this.props.processing}
                size="tiny"
                type="submit">
                {copyText.saveButtonLabel}
              </Button>
            </Form.Field>
          </Fragment>
        ) : (
          <Form.Field className={cn('buttonWrapper')}>
            <Button
              className={cn('button')}
              color="blue"
              disabled={!this._canSave()}
              fluid
              size="tiny"
              onClick={
                this.state.type === IntegrationType.CUSTODIAN_SILVERGATE
                  ? undefined
                  : this._handleFirstConfirmation
              }>
              {copyText.saveButtonLabel}
            </Button>
          </Form.Field>
        )}
        <Form.Field className={cn('buttonWrapper')}>
          <Button
            className={cn('button')}
            fluid
            size="tiny"
            type="reset"
            onClick={
              this.state.showSecondConfirmation
                ? this._handleSecondCancel
                : this._handleFirstCancel
            }>
            {copyText.cancelButtonLabel}
          </Button>
        </Form.Field>
      </StyledForm>
    );
  }

  _canSave() {
    let credentialsValid = true;
    let credentialsForViewValid = true;

    if (this.props.isEditingCredentials) {
      credentialsValid =
        (
          !isBlank(this.state.key) &&
          !isBlank(this.state.secret) &&
          !isBlank(this.state.type) &&
          !(needsPassphrase(this.state.type) && isBlank(this.state.password)) &&
          !(needsFixClientId(this.state.type) && isBlank(this.state.fixClientId)) &&
          !(needsFixCompId(this.state.type) && isBlank(this.state.fixCompId))
        ) ||
        this.state.type === IntegrationType.CUSTODIAN_SILVERGATE;
    }

    if (this.props.isEditingCredentialsForView) {
      credentialsForViewValid =
        (
          !isBlank(this.state.type) &&
          !isBlank(this.state.keyForView) &&
          !isBlank(this.state.secretForView) &&
          !(needsPassphrase(this.state.type) && isBlank(this.state.passwordForView)) &&
          !(needsFixClientId(this.state.type) && isBlank(this.state.fixClientIdForView))
        ) ||
        this.state.type === IntegrationType.CUSTODIAN_SILVERGATE;
    }

    return credentialsValid && credentialsForViewValid;
  }

  _renderSaveCredentialsForm = ({ isForView }) => {
    const isEditing = isForView
      ? this.props.isEditingCredentialsForView
      : this.props.isEditingCredentials;

    return isEditing ? (
      <SaveCredentialsForm
        handleChangeField={this._handleChangeField.bind(this)}
        integration={this.props.integration}
        isForView={isForView}
        fixClientId={isForView ? this.state.fixClientIdForView : this.state.fixClientId}
        fixCompId={isForView ? '' : this.state.fixCompId}
        keyValue={isForView ? this.state.keyForView : this.state.key}
        password={isForView ? this.state.passwordForView : this.state.password}
        secret={isForView ? this.state.secretForView : this.state.secret}
        showSecondConfirmation={this.state.showSecondConfirmation}
        type={this.state.type}
      />
    ) : null;
  };

  _renderSilvergateForm = (requestOrgName, requestUsername) => {
    return (
      <Fragment>
        <div className={cn('requestInfo')}>
          <span>{copyText.requestHeader}</span>
          <div className={cn('requestRow')}>
            {copyText.requestItemProvider}
            <span className={cn('requestContent')}>
              {copyText.integrationTypeOption_CUSTODIAN_SILVERGATE}
            </span>
          </div>
          <div className={cn('requestRow')}>
            {copyText.requestItemHolder}
            <span className={cn('requestContent')}>{requestOrgName}</span>
          </div>
          <div className={cn('requestRow')}>
            {copyText.requestItemUser}
            <span className={cn('requestContent')}>{requestUsername}</span>
          </div>
          <div className={cn('requestRow')}>
            {copyText.requestItemDate}
            <span className={cn('requestContent')}>
              {`${moment.utc().format()} ${copyText.requestDateTimezone}`}
            </span>
          </div>
        </div>
        <Message
          className={cn('confirmMessage')}
          content={copyText.requestMessageContent}
          warning
        />
      </Fragment>
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.type !== this.state.type) {
      this.setState({
        fixClientId: '',
        fixClientIdForView: '',
        fixCompId: '',
        key: '',
        keyForView: '',
        secret: '',
        secretForView: '',
        password: '',
        passwordForView: ''
      });
    }
  }

  _handleChangeField = (input, event) => {
    let { value } = input;
    const { name } = event;

    if (has(event, 'value')) {
      value = event.value;
    }

    const inputNames = [
      'fixClientId',
      'fixClientIdForView',
      'fixCompId',
      'key',
      'keyForView',
      'password',
      'passwordForView',
      'secret',
      'secretForView'
    ];

    if (inputNames.includes(name)) {
      value = value.trim();
    }

    let changes = { [name]: value };

    if (this.state.isEditingCredentials) {
      changes = {
        ...changes,
        fixClientIdForView: '',
        keyForView: '',
        secretForView: '',
        passwordForView: ''
      };
    }

    if (this.state.isEditingCredentialsForView) {
      changes = {
        ...changes,
        fixClientId: '',
        fixCompId: '',
        key: '',
        secret: '',
        password: ''
      };
    }

    if (name === 'type') {
      changes = { ...getInitialState(this.props), ...changes };
    }

    this.setState(changes);
  };

  _handleFirstCancel = event => {
    event.preventDefault();
    this.props.onCancel();
  };

  _handleFirstConfirmation = event => {
    event.preventDefault();
    this.setState({
      showSecondConfirmation: true
    });
  };

  _handleSecondCancel = event => {
    event.preventDefault();
    this.setState({
      showSecondConfirmation: false
    });
  };

  _handleSubmit = event => {
    event.preventDefault();

    const {
      id,
      fixClientId,
      fixClientIdForView,
      fixCompId,
      key,
      keyForView,
      password,
      passwordForView,
      secret,
      secretForView,
      type
    } = this.state;

    let params = { id, type };

    if (!isBlank(keyForView) && isBlank(key)) {
      params.isForView = true;
      params.fixClientId = fixClientIdForView;
      params.key = keyForView;
      params.password = passwordForView;
      params.secret = secretForView;
    } else {
      params = {
        ...params,
        isForView: false,
        fixClientId,
        fixClientIdForView,
        fixCompId,
        key,
        keyForView,
        password,
        passwordForView,
        secret,
        secretForView
      };
    }

    this.props.onSave(params);
  };

  _handleRequestIntegration = event => {
    event.preventDefault();
    this.props.onRequest({
      baseUrl: config.login.baseUrl,
      type: this.state.type
    });
  };
}

function getInitialState(props) {
  return {
    id: get(props, 'integration.id', ''),
    isErrorDisplaying: get(props, 'isErrorDisplaying', false),
    fixClientId: '',
    fixClientIdForView: '',
    fixCompId: '',
    key: '',
    keyForView: '',
    password: '',
    passwordForView: '',
    secret: '',
    secretForView: '',
    showSecondConfirmation: false,
    type: get(props, 'integration.type', '')
  };
}
