import gql from 'graphql-tag';

export default gql`
  mutation acceptSettlement($id: ID!, $instructionIds: [ID!]) {
    settlement: acceptSettlement(id: $id, instructionIds: $instructionIds) {
      id
      fixAllocID
      status
    }
  }
`;
