import { createSelector } from 'reselect';

import { keyBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';

export function getVenuesKeyedById(venues) {
  if (!Array.isArray(venues) || venues.length === 0) return {};

  return keyBy(venues, 'id');
}

export default createSelector(venues => venues, getVenuesKeyedById);
