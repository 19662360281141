import { arrayOf, func, object, shape, string } from 'prop-types';
import { Button, Form, Message } from 'semantic-ui-react';
import { createSelector } from 'reselect';
import React, { Fragment, PureComponent } from 'react';
import styled from 'react-emotion';

import { get, has } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getAssetDisplayText, getAssetSymbolAndName } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import InputWithWarning from './InputWithWarning';
import { isEmpty } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { sortBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import copyText from './SaveCryptoAccountForm.copyText';
import AssetType from '@omniex/poms-core/lib/enums/AssetType';
import FormSelect from '@omniex/onx-common-ui/lib/semantic/react/FormSelect';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/button.css');
require('@omniex/onx-common-ui/lib/semantic/css/form.css');
require('@omniex/onx-common-ui/lib/semantic/css/input.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const COMPONENT_NAME = 'SaveCryptoAccountForm';

const NO_CUSTODIAN_ID = '-1';

const StyledForm = styled(Form)`
  min-width: 280px;

  .${COMPONENT_NAME}-secondConfirmationMessage {
    font-size: 1.3em;
  }

  .${COMPONENT_NAME}-buttonWrapper {
    text-align: right;
  }

  .${COMPONENT_NAME}-button {
    margin: 0;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class SaveCryptoAccountForm extends PureComponent {
  static propType = {
    currencies: arrayOf(
      shape({
        id: string.isRequired,
        symbol: string.isRequired,
        type: string.isRequired
      })
    ),
    existingCurrencies: object,
    custodians: arrayOf(
      shape({
        id: string.isRequired,
        name: string.isRequired
      })
    ),
    onCancel: func,
    onSave: func,
    selfCustodianName: string,
  };

  static defaultProps = {
    currencies: [],
    existingCurrencies: {},
    onCancel: noop,
    onSave: noop,
    selfCustodianName: '',
  };

  state = {
    currencyId: '',
    name: '',
    showSecondConfirmation: false,
    selectedCustodian: {},
  };

  _getCustodianOptions = createSelector(
    (custodians, selfCustodianName) => custodians,
    (custodians, selfCustodianName) => selfCustodianName,
    getCustodianOptions
  );

  _getCurrencyOptions = createSelector(
    currencies => currencies,
    getCurrencyOptions
  );

  render() {
    const { selectedCustodian, name, showSecondConfirmation } = this.state;
    const { currencies, existingCurrencies } = this.props;

    const existing = existingCurrencies[`${selectedCustodian.id}_${selectedCustodian.__typename}`] || []
    let currencyOptions = selectedCustodian.id === NO_CUSTODIAN_ID
      ? this._getCurrencyOptions(currencies)
      : this._getCurrencyOptions(currencies.filter(currency => !existing.some(e => e.currencyId === currency.id)));
    currencyOptions = sortBy(currencyOptions, 'text');

    const custodianOptions = this._getCustodianOptions(get(this.props, 'custodians', []), this.props.selfCustodianName);

    return (
      <StyledForm
        className={COMPONENT_NAME}
        autoComplete="off"
        warning={showSecondConfirmation}>
        <FormSelect
          name="selectedCustodian"
          options={custodianOptions}
          placeholder={copyText.inputPlaceholder_custodianId}
          onChange={this._handleChangeField}
        />
        {selectedCustodian.id === NO_CUSTODIAN_ID ? (
          <Form.Field>
            <InputWithWarning
              name="name"
              placeholder={copyText.inputPlaceholder_customName}
              value={name}
              warning={copyText.freeTextWarning}
              onChange={this._handleChangeField}
            />
          </Form.Field>
        ) : null}
        <FormSelect
          name="currencyId"
          isDisabled={!selectedCustodian.id}
          options={currencyOptions}
          placeholder={copyText.inputPlaceholder_currencyId}
          onChange={this._handleChangeField}
        />
        {showSecondConfirmation ? (
          <Fragment>
            <Message
              content={copyText.confirmationMessageContent}
              header={copyText.confirmationMessageHeader}
              warning
            />
            <Form.Field className={cn('buttonWrapper')}>
              <Button
                className={cn('button')}
                color="orange"
                disabled={!this._canSave()}
                fluid
                size="tiny"
                type="submit"
                onClick={this._handleSubmit.bind(this)}>
                {copyText.proceedButtonLabel}
              </Button>
            </Form.Field>
            <Form.Field className={cn('buttonWrapper')}>
              <Button
                className={cn('button')}
                fluid
                size="tiny"
                type="reset"
                onClick={this._handleClickCancelConfirmation.bind(this)}>
                {copyText.cancelButtonLabel}
              </Button>
            </Form.Field>
          </Fragment>
        ) : (
          <Fragment>
            <Message
              content={copyText.confirmationMessageContent}
              header={copyText.confirmationMessageHeader}
              warning
            />
            <Form.Field className={cn('buttonWrapper')}>
              <Button
                className={cn('button')}
                color="orange"
                disabled={!this._canSave()}
                fluid
                size="tiny"
                onClick={this._handleClickFirstConfirmationButton.bind(this)}>
                {copyText.saveButtonLabel}
              </Button>
            </Form.Field>
            <Form.Field className={cn('buttonWrapper')}>
              <Button
                className={cn('button')}
                fluid
                size="tiny"
                type="reset"
                onClick={this._handleClickCancelButton.bind(this)}>
                {copyText.cancelButtonLabel}
              </Button>
            </Form.Field>
          </Fragment>
        )}
      </StyledForm>
    );
  }

  _canSave() {
    return !isEmpty(this.state.selectedCustodian) && !isEmpty(this.state.currencyId);
  }

  _handleChangeField = (input, event) => {
    const name = event.name;

    const value = has(input, 'nativeEvent') ? event.value : input.value;

    let changes = { [name]: value };

    if (changes.selectedCustodian) {
      changes.currencyId = '';
    }

    this.setState(changes);
  };

  _handleClickCancelButton = event => {
    event.preventDefault();

    this.props.onCancel();
  };

  _handleClickFirstConfirmationButton = event => {
    event.preventDefault();
    this.setState({ showSecondConfirmation: true });
  };

  _handleClickCancelConfirmation = event => {
    event.preventDefault();
    this.setState({ showSecondConfirmation: false });
  };

  _handleSubmit = event => {
    event.preventDefault();
    let { currencyId, name, selectedCustodian } = this.state;

    let custodianId, customCustodianId
    if (selectedCustodian.__typename === 'CustomCustodian') {
      customCustodianId = selectedCustodian.id;
    } else if (selectedCustodian.id !== NO_CUSTODIAN_ID) {
      custodianId = selectedCustodian.id;
    }

    this.props.onSave({
      currencyId,
      ...(!isEmpty(name) ? { name } : {}),
      ...(!isEmpty(custodianId) ? { custodianId } : {}),
      ...(!isEmpty(customCustodianId) ? { customCustodianId } : {}),
      description: 'Default',
    });
  };
}

function getCurrencyOptions(currencies) {
  if (!Array.isArray(currencies) || currencies.length === 0) return [];

  return currencies
    .filter(currency => currency.type === AssetType.CRYPTO)
    .map(currency => ({
      text: getAssetSymbolAndName(currency),
      label: getAssetDisplayText(currency),
      value: currency.id
    }));
}

function getCustodianOptions(custodians, selfCustodianName) {
  if (!Array.isArray(custodians) || custodians.length === 0) {
    return [
      {
        value: {id: NO_CUSTODIAN_ID},
        label: copyText.selfCustodian
      }
    ];
  }

  return [
    {
      label: 'Self Custodian',
      options: [
        {
          value: {id: NO_CUSTODIAN_ID},
          label: copyText.selfCustodian
        }
      ]
    },
    {
      label: 'Custodians',
      options: custodians.reduce((opts, c) => {
        if (c.name !== selfCustodianName) opts.push({ label: c.name, value: c });
        return opts;
      }, []),
    }
  ];
}
