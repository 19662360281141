import { createSelector } from 'reselect';

import MarketDataEntryType from '@omniex/onx-poms-entity-helpers/lib/enums/MarketDataEntryType';
import { isEmpty } from '@omniex/onx-common-js/lib/utils/LangUtils';

export function findTopOfBook(entries) {
  if (!Array.isArray(entries) || isEmpty(entries)) {
    return [{}, {}];
  }

  let highestBid = { price: -Infinity };
  let lowestOffer = { price: Infinity };

  entries.forEach(entry => {
    if (entry.type === MarketDataEntryType.BID && entry.price > highestBid.price) {
      highestBid = entry;
    } else if (entry.type === MarketDataEntryType.OFFER && entry.price < lowestOffer.price) {
      lowestOffer = entry;
    }
  });

  if (highestBid.price === -Infinity) highestBid = {};
  if (lowestOffer.price === Infinity) lowestOffer = {};

  return [highestBid, lowestOffer];
}

export default createSelector(
  entries => entries,
  findTopOfBook
);
