import { useCallback, useMemo, useState } from 'react'

import { Sign } from '../../types'

type NumericalFilterOption = {
  name: string
  value: Sign
}

type NumericalState = {
  sign: Sign | undefined
  value: number | undefined
}

type NumericalFilterHookArguments = {
  defaultState?: NumericalState
}

export type NumericalQueryArgs = {
  sign?: Sign
  value?: number
}

export type NumericalFilterHook = {
  filterData: {
    options: Array<NumericalFilterOption>
    condition: Sign | undefined
    value: number | undefined
    type: 'numberRange'
  }
  queryArgs: NumericalQueryArgs
  handleFilter: (sign: Sign | undefined, value: number | undefined) => void
}

const options: Array<{ name: string, value: Sign }> = [
  { name: '<', value: '<' },
  { name: '<=', value: '<=' },
  { name: '>', value: '>' },
  { name: '>=', value: '>=' },
  { name: '=', value: '=' },
  { name: '!=', value: '!=' },
]

const defaultStateVars: NumericalState = {
  sign: undefined,
  value: undefined
}

export function useNumericalFilter({ defaultState }: NumericalFilterHookArguments): NumericalFilterHook {

  const [selection, setSelection] = useState<NumericalState>(defaultState || defaultStateVars)

  const handleFilter = useCallback((sign: Sign | undefined, value: number | undefined) => {
    setSelection({ sign, value })
  }, [setSelection])

  const queryArgs = useMemo(() => {
    let args: NumericalQueryArgs = {}
    if (selection?.value) {
      args.sign = selection.sign
      args.value = selection.value
    }
    return args
  }, [selection])

  return {
    filterData: {
      options,
      type: 'numberRange',
      condition: selection.sign,
      value: selection.value
    },
    queryArgs,
    handleFilter
  }
}
