import createSelector from 're-reselect';

import { get } from '@omniex/poms-core/lib/utils/ObjectUtils';
import { isEmpty } from '@omniex/poms-core/lib/utils/LangUtils';

// Instrument should be uniquely identified by display name and venue id or symbol
export function getInstrumentFromNameVenue(venues, instrumentDisplayName, venueParam, paramValue) {
  if (!Array.isArray(venues) || isEmpty(instrumentDisplayName) || !venueParam || !paramValue) return {};

  const venue = venues.find(v => v[venueParam] === paramValue) || {};
  const ic = (venue.instrumentConfigurations || []).find(ic => get(ic, 'instrument.displayName') === instrumentDisplayName) || {};
  return ic.instrument || {};
}

export default createSelector(
  (venues, instrumentDisplayName, venueParam, paramValue) => venues,
  (venues, instrumentDisplayName, venueParam, paramValue) => instrumentDisplayName,
  (venues, instrumentDisplayName, venueParam, paramValue) => venueParam,
  (venues, instrumentDisplayName, venueParam, paramValue) => paramValue,
  getInstrumentFromNameVenue
)((venues, instrumentDisplayName, venueParam, paramValue) => `${instrumentDisplayName || ''}:${paramValue || ''}`
);
