import { arrayOf, func, number, shape, string } from 'prop-types';
import { Button, Form } from 'semantic-ui-react';
import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { isNil } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { pluralize } from '@omniex/onx-common-js/lib/utils/StringUtils';
import AssetType from '@omniex/poms-core/lib/enums/AssetType';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './CreateExchangeAccountsForm.copyText';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';
import Table from '@omniex/onx-common-ui/lib/semantic/react/Table';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/button.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');
require('@omniex/onx-common-ui/lib/semantic/css/table.css');
require('@omniex/onx-common-ui/lib/semantic/css/form.css');
require('@omniex/onx-common-ui/lib/semantic/css/input.css');

const COMPONENT_NAME = 'CreateExchangeAccountsForm';

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

const StyledDiv = styled('div')`
  .${COMPONENT_NAME}-warningMessage {
    margin-bottom: 10px !important;
  }
`;

// prettier-ignore
const StyledForm = styled(Form)`
  .${COMPONENT_NAME}-adjustedCostBasis > input {
    text-align: right;
  }

  .${COMPONENT_NAME}-currency {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .${COMPONENT_NAME}-fieldGroup {
    justify-content: flex-end;
  }

  .${COMPONENT_NAME}-quantity, .${COMPONENT_NAME}-quantityHeader, .${COMPONENT_NAME}-price, .${COMPONENT_NAME}-priceHeader {
    padding-right: 0.25em !important;
  }

  .${COMPONENT_NAME}-saveButton {
    margin: 0;
  }

  .${COMPONENT_NAME}-cancelButton {
    background: ${colors.orange} !important;
    color: ${colors.white} !important;
    margin: 0;
    opacity: 0.9;

    :hover {
      opacity: 1;
    }
  }
`;

export default class CreateExchangeAccountsForm extends PureComponent {
  static propTypes = {
    exchangeAccounts: arrayOf(
      shape({
        id: string.isRequired,
        name: string.isRequired,
        currency: shape({
          id: string.isRequired,
          symbol: string.isRequired
        }).isRequired,
        quantity: number.isRequired,
        type: string.isRequired
      })
    ),
    integration: shape({
      type: string.isRequired
    }),
    homeCurrencyId: string,
    onCancel: func,
    onSubmit: func
  };

  static defaultProps = {
    onCancel: noop,
    onSubmit: noop
  };

  state = {};

  render() {
    if (isNil(this.props.integration.credentialsForViewPreview)) return null;

    if (get(this.props, 'exchangeAccounts.length', 0) === 0) {
      return this._renderWarningMessage(this.props.integration);
    }

    return (
      <StyledForm
        className={COMPONENT_NAME}
        warning
        onSubmit={this._handleSubmit}>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell border="none">
                {copyText.tableHeaderName}
              </Table.HeaderCell>
              <Table.HeaderCell border="none">
                {copyText.tableHeaderType}
              </Table.HeaderCell>
              <Table.HeaderCell
                className={cn('quantityHeader')}
                border="none"
                textAlign="right">
                {copyText.tableHeaderQuantity}
              </Table.HeaderCell>
              <Table.HeaderCell border="none" />
              {/* <Table.HeaderCell border="none" textAlign="right">
                {copyText.tableHeaderAdjustedCost}
              </Table.HeaderCell> */}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.exchangeAccounts.map(account => (
              <Table.Row key={account.id}>
                <Table.Cell border="none">{account.name}</Table.Cell>
                <Table.Cell border="none">{account.type}</Table.Cell>
                <Table.Cell className={cn('quantity')} border="none" textAlign="right">
                  {this._renderQty(account)}
                </Table.Cell>
                <Table.Cell className={cn('currency')} border="none">
                  {this._renderUnit(account)}
                </Table.Cell>
                {/* <Table.Cell border="none">
                  <Form.Field>
                    <Input
                      name={account.id}
                      className={cn('adjustedCostBasis')}
                      disabled={
                        account.currency.id === this.props.homeCurrencyId
                      }
                      size="small"
                      value={
                        account.currency.id === this.props.homeCurrencyId
                          ? 'N/A'
                          : this.state[`adjustedCostBasis_${account.id}`] || ''
                      }
                      onChange={this._handleChangeField}
                    />
                  </Form.Field>
                </Table.Cell> */}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Form.Group className={cn('fieldGroup')}>
          <Form.Field>
            <Button
              className={cn('saveButton')}
              color="blue"
              // disabled={this._canSave()}
              size="tiny"
              type="submit">
              {copyText.saveButtonLabel}
            </Button>
          </Form.Field>
          <Form.Field>
            <Button
              className={cn('cancelButton')}
              size="tiny"
              type="reset"
              onClick={this._handleClickCancelButton}>
              {copyText.cancelButtonLabel}
            </Button>
          </Form.Field>
        </Form.Group>
      </StyledForm>
    );
  }

  _renderQty = account => {
    const currency = get(account, 'currency', {});
    const prefix = currency.type === AssetType.FUTURE && (currency.symbol || '').search(/-SHORT|-LONG/) <= -1
      ? `${account.quantity > 0 ? copyText.futureLongLabel : copyText.futureShortLabel} `
      : '';

    return currency.type === AssetType.FUTURE
      ? `${prefix}${Math.abs(account.quantity).toString()}`
      : account.quantity.toFixed(8)
  }

  _renderUnit = account => get(account, 'currency.type') === AssetType.FUTURE
    ? pluralize(copyText.futureUnitLabel, Math.abs(account.quantity))
    : account.currency.symbol

  _renderWarningMessage(integration) {
    return (
      <StyledDiv className={COMPONENT_NAME}>
        <Message
          className={cn('warningMessage')}
          content={copyText.warningMessage.replace(
            '%exchange%',
            copyText[`type_${get(integration, 'type', '')}`]
          )}
          warning
        />
      </StyledDiv>
    );
  }

  // _canSave() {
  //   return this.props.exchangeAccounts.some(account =>
  //     account.currency.id === this.props.homeCurrencyId
  //       ? false
  //       : !this.state[`adjustedCostBasis_${account.id}`]
  //   );
  // }

  // _handleChangeField = (event, input) => {
  //   let { name, value } = input;

  //   if (!/^\d*\.?\d{0,8}$/.test(value)) {
  //     return;
  //   }

  //   // let changes = {
  //   //   [`adjustedCostBasis_${name}`]: value
  //   // };

  //   this.setState(changes);
  // };

  _handleClickCancelButton = event => {
    event.preventDefault();
    this.props.onCancel();
  };

  _handleSubmit = event => {
    event.preventDefault();

    const exchangeAccountIds = this.props.exchangeAccounts.map(
      account => account.id
    );

    this.props.onSubmit(exchangeAccountIds);
  };
}
