const projectedPriceMessageCopy = {
  avgPrice: 'Avg. Price: ',
  feesWarning: 'Warning: Estimates do not include fees.',
  header: 'Pre-trade Estimates',
  qtyFilled: 'Qty. Filled: ',
  qtyWarning: 'Warning: Estimates may not be accurate at the quantity specified.',
  sorQtyWarning: 'Warning: Unable to provide estimate for remaining quantity of %quantity% %baseAsset% since the order quantity exceeds the full depth of the displayed order book.',
  totalPrice: 'Total: ',
};

export default projectedPriceMessageCopy;
