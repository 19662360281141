import { useQuery, useMutation } from 'react-apollo';
import React from 'react';

import { ERROR_PERMISSION_DENIED } from '@omniex/onx-common-ui/lib/errors';
import { safeExecAsync } from '../utils/FunctionUtils';
import ErrorPage from '@omniex/onx-common-ui/lib/pages/ErrorPage';
import getPortfoliosQuery from '../apollo/graphql/getPortfolios';
import getUsers from '../apollo/graphql/getUsers';
import updateUserMutation from '../apollo/graphql/updateUser';
import UserManagementPage from '../ui/pages/UserManagementPage';

const UserManagementPageWithAuth = ({apiClient, user }) => {
  const useApiQuery = (q, o) => useQuery(q, { client: apiClient, ...o })
  const orgId = user?.org?.id
  const qPortfolios = useApiQuery(getPortfoliosQuery)
  const qUsers = useApiQuery(getUsers, { skip: !orgId, variables: { orgId } })
  const [updateUser, mUpdateUser] = useMutation(updateUserMutation, {
    client: apiClient,
    refetchQueries: [
      { query: getUsers, skip: !orgId, variables: { orgId } },
      { query: getPortfoliosQuery },
    ],
  })

  return (
    <UserManagementPage
      qPortfolios={qPortfolios}
      updateUser={variables => safeExecAsync(updateUser, {variables})}
      updateUserMutationError={mUpdateUser?.error}
      updateUserMutationResult={mUpdateUser?.data}
      updateUserMutationProcessing={mUpdateUser?.loading}
      usersQueryData={qUsers?.data}
      usersQueryError={qUsers?.error}
      usersQueryLoading={qUsers?.loading}
    />
  )
}

const UserManagementPageContainer = ({ user, ...props }) => user?.canViewUserManagement
  ? <UserManagementPageWithAuth user={user} {...props} /> 
  : <ErrorPage type={ERROR_PERMISSION_DENIED} />

export default UserManagementPageContainer
