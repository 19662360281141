import gql from 'graphql-tag';

export default gql`
 query getInstrumentBySymbol($symbol: String!) {
    instrument: getInstrumentBySymbol(symbol: $symbol) {
      id
      symbol
      type
      baseAsset {
        id
        name
        symbol
        type
      }
      termAsset {
        id
        name
        symbol
        type
      }
      mdInstrument {
        id
      }
      displayName
      identifier
    }
  }
`;
