const OrderBlotterTableCopyText = {
  algoActualDuration: 'Algo Act. Duration',
  algoRequestedDuration: 'Algo Req. Duration',
  cancelOrderButtonLabel: 'cancel',
  noData: '--',
  notAvailable: 'N/A',
  popupLabelEMSOrderID: 'EMS Order ID',
  popupLabelOrderID: 'Order ID',
  popupLabelQuoteID: 'Quote ID',
  popupLabelSystem: 'System',
  popupLabelTimeExpired: 'Time Expired',
  popupLabelTimeExpires: 'Time Expires',
  popupLabelVenueOrderID: 'Venue Order ID',
  popupStatusCancel: 'Canceled by: ',
  popupStatusCanceled: 'Reason Canceled: ',
  popupStatusCanceledUrl: 'https://omniex.atlassian.net/servicedesk/customer/portal/1/article/1667793126?src=2065727773',
  popupStatusCancellationRejected: 'Cancellation Rejected: ',
  popupStatusRejection: 'Reason Rejected: ',
  portfolio: 'Portfolio',
  statusQuoted: 'QUOTED',
  stopMarket: 'STOP MARKET',
  tableHeaderAlgorithmExpiration: 'Algo Expiration',
  tableHeaderAlgorithmStrategy: 'Algorithm Strategy',
  tableHeaderAverageFillPrice: 'Average Price',
  tableHeaderCustomOrderId: 'Client Order ID',
  tableHeaderDecisionPrice: 'Decision Price',
  tableHeaderDisplayQuantity: 'Display Quantity',
  tableHeaderExecutionType: 'Execution Type',
  tableHeaderInstrument: 'Instrument',
  tableHeaderInstrumentType: 'Type',
  tableHeaderLimitPrice: 'Limit Price',
  tableHeaderOrderID: 'Order ID',
  tableHeaderOrderType: 'Order Type',
  tableHeaderParticipationRate: 'Participation Rate',
  tableHeaderQuantityFilled: 'Qty Filled',
  tableHeaderQuantityOrdered: 'Qty Ordered',
  tableHeaderRequestCancelTrader: 'Canceling Trader',
  tableHeaderSide: 'Side',
  tableHeaderStatus: 'Status',
  tableHeaderStopPrice: 'Stop Price',
  tableHeaderTimeInForce: 'TIF',
  tableHeaderTimestamp: 'Time Created',
  tableHeaderTimeLastUpdated: 'Last Update',
  tableHeaderTotalFees: 'Total Fees',
  tableHeaderTraderName: 'Trader',
  tableHeaderVenue: 'Venue',
  utcLabel: 'UTC',
  warningMessage: 'There are no orders to display at this time.'
};

export default OrderBlotterTableCopyText;
