import { bool, func, string } from 'prop-types';
import { Button, Form } from 'semantic-ui-react';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getAssetSymbolAndName } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import { HOME_CURRENCY, MARKET_DATA_SOURCE } from '../../constants/orgSettings';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import copyText from './EditOrgSettingsForm.copyText';
import ExternalMarketDataSource from '@omniex/onx-poms-entity-helpers/lib/enums/ExternalMarketDataSource';
import FormSelect from '@omniex/onx-common-ui/lib/semantic/react/FormSelect';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/button.css');
require('@omniex/onx-common-ui/lib/semantic/css/form.css');
require('@omniex/onx-common-ui/lib/semantic/css/dropdown.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const marketDataSourceOptions = [
  { value: ExternalMarketDataSource.COIN_MARKET_CAP, label: copyText.marketDataSourceCoinMarketCap },
  { value: ExternalMarketDataSource.CRYPTO_COMPARE, label: copyText.marketDataSourceCryptoCompare },
];

const COMPONENT_NAME = 'EditOrgSettingsForm';

const EDITABLE_SETTINGS = [HOME_CURRENCY, MARKET_DATA_SOURCE];

const StyledForm = styled(Form)`
  min-width: 280px;
`;

export default class EditOrgSettingsForm extends PureComponent {
  static propTypes = {
    editingField: string,
    processing: bool,
    onCancel: func,
    onUpdate: func
  };

  static defaultProps = {
    onCancel: noop,
    onUpdate: noop
  };

  state = {};

  render() {
    const {editingField, homeCurrencies, org} = this.props;
    const homeCurrencySourceOptions = homeCurrencies.map(c => ({ value: c.symbol, label: getAssetSymbolAndName(c, false) }));
    const currentValues = {[HOME_CURRENCY]: get(org, 'homeCurrency.symbol'), [MARKET_DATA_SOURCE]: get(org, 'externalMarketDataSource')};
    const fieldOptions = {[HOME_CURRENCY]: homeCurrencySourceOptions, [MARKET_DATA_SOURCE]: marketDataSourceOptions};
    return (
      <StyledForm className={COMPONENT_NAME} onSubmit={this._handleSubmit}>
        {EDITABLE_SETTINGS.map(settingName => (editingField === settingName &&
          <FormSelect
            key={settingName}
            name={settingName}
            isSearchable={false}
            options={fieldOptions[settingName]}
            placeholder={currentValues[settingName] || copyText[`inputPlaceholder_${settingName}`]}
            onChange={this._handleChangeField}
          />
        ))}
        <Form.Field>
          <Button
            color="orange"
            disabled={!this.state[editingField]}
            fluid
            loading={this.props.processing}
            size="tiny"
            type="submit">
            {copyText.submitButtonLabel}
          </Button>
        </Form.Field>
        <Form.Field>
          <Button
            fluid
            size="tiny"
            type="reset"
            onClick={this._handleClickCancelButton}>
            {copyText.cancelButtonLabel}
          </Button>
        </Form.Field>
      </StyledForm>
    );
  }

  _handleChangeField = (field, event) => {
    this.setState({ [event.name]: field.value, reset: false });
  };

  _handleClickCancelButton = event => {
    event.preventDefault();
    this.props.onCancel();
  };

  _handleSubmit = event => {
    event.preventDefault();
    this.props.onUpdate({
      externalMarketDataSource: this.state[MARKET_DATA_SOURCE],
      homeCurrencySymbol: this.state[HOME_CURRENCY],
    });
  };
}
