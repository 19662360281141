import _HighmapsAdapter from 'react-highcharts/ReactHighmaps';
import _HighstockAdapter from 'react-highcharts/ReactHighstock';
import _HighchartsAdapter from 'react-highcharts';
import enableHeatmap from 'highcharts/modules/heatmap';
import enableDrilldown from 'highcharts/modules/drilldown';

const defaultOptions = {
  lang: {
    thousandsSep: ','
  },
  credits: {
    enabled: false
  },
  title: {
    style: {
      display: 'none'
    },
    text: null
  },
  subtitle: {
    style: {
      display: 'none'
    },
    text: null
  }
};

enableHeatmap(_HighchartsAdapter.Highcharts);
enableDrilldown(_HighchartsAdapter.Highcharts);

_HighmapsAdapter.Highcharts.setOptions(defaultOptions);
_HighstockAdapter.Highcharts.setOptions(defaultOptions);
_HighchartsAdapter.Highcharts.setOptions(defaultOptions);

export const HighmapsAdapter = _HighmapsAdapter;
export const HighstockAdapter = _HighstockAdapter;
export const HighchartsAdapter = _HighchartsAdapter;
export default HighchartsAdapter;
