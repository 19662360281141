/* eslint no-sequences: 0 */
import * as React from 'react'
import { Button, Checkbox, Form, Grid, Input, Loader, Menu, Popup, Segment, Icon } from 'semantic-ui-react'
import styled from 'react-emotion'

import { orderBy } from '@omniex/poms-core/lib/utils/CollectionUtils'
import Table from '@omniex/onx-common-ui/lib/semantic/react/Table'
import text from './AdminPagePortfoliosSection.copyText'
import { colors } from '@omniex/onx-common-ui/lib/styles';

const { useState } = React

const MILLIS_DELAY = 200;

const C = 'AdminPagePortfolioSection'
const cn = l => `${C}-${l}`

const Section = styled('div')`
  .${C}-body {
    padding: 0px;
  }

  .${C}-cell {
    min-width: 250px;
  }

  .${C}-form {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .${C}-grid {
    min-height: 100px;
  }

  .${C}-table {
    padding: 0px;
  }

  .${C}-action i.${C}-edit {
    color: ${colors.blue} !important;
  }

  .${C}-action .${C}-edit-button {
    background-color: transparent;
    box-shadow: none;
    border: none;
    outline: none;
  }
`
const Portfolios = ({portfolios, onClick, selectedPortfolio}) => (
  <Table className={cn('table')} selectable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>{text.name}</Table.HeaderCell>
        <Table.HeaderCell>{text.integrations}</Table.HeaderCell>
        <Table.HeaderCell>{text.users}</Table.HeaderCell>
        <Table.HeaderCell>{text.status}</Table.HeaderCell>
        <Table.HeaderCell/>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {orderBy(portfolios || [], 'id').map(p => (
        <Table.Row key={p.id} active={p.id === selectedPortfolio?.id}>
          <Table.Cell className={cn('cell')} collapsing>{p.name}</Table.Cell>
          <Table.Cell className={cn('cell')} collapsing>{p.integrations?.length || 0}</Table.Cell>
          {p.users?.length ? (
            <Popup
              flowing
              closeOnTriggerBlur
              inverted
              mouseEnterDelay={MILLIS_DELAY}
              mouseLeaveDelay={MILLIS_DELAY}
              position="top left"
              trigger={<Table.Cell className={cn('cell')} collapsing>{p.users.length}</Table.Cell>}
            >
              {p.users.map(u => <div key={u.username}>{u.name}</div>)}
            </Popup>
          ) : (
            <Table.Cell className={cn('cell')} collapsing>0</Table.Cell>
          )}
          <Table.Cell className={cn('cell')} collapsing>{p.active ? text.active : text.disabled}</Table.Cell>
          <Table.Cell className={cn("action")}>
            <Button icon disabled={selectedPortfolio !== null} transparent="true" onClick={_ => onClick(p)} className={cn('edit-button')}>
              <Icon name="edit"  className={cn('edit')} />
            </Button>
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
)

const Spinner = _ => (
  <Grid.Row columns={1}>
    <Grid.Column width={16}>
      <Loader active content={text.loading} size="medium" />
    </Grid.Column>
  </Grid.Row>
)

const PortfolioForm = ({names, portfolio, save, close}) => {
  const [name, setName] = useState(portfolio?.name || '')
  const [active, setActive] = useState(portfolio ? !!portfolio?.active : true)

  const validName = name?.length && !names.includes(name)
  const canSave = validName && (name !== portfolio?.name || active !== portfolio?.active)
  const canToggleEnabled = portfolio && !portfolio.isDefault

  return (
    <Form className={cn('form')}>
      <Form.Field width={16}>
        <label>{text.portfolioName}</label>
        <Input value={name} placeholder={text.name} onChange={(_, {value}) => setName(value)} />
      </Form.Field>
      {canToggleEnabled &&(
        <Form.Field width={16}>
          <Checkbox toggle checked={active} label={text.enabled} onChange={(_, {checked}) => setActive(checked)} />
        </Form.Field>
      )}
      <Form.Field width={16}>
        <Button fluid size="tiny" color="blue" disabled={!canSave} onClick={_ => save({...portfolio, name, active})}>{text.save}</Button>
      </Form.Field>
      <Form.Field width={16}>
        <Button fluid size="tiny" color="orange" onClick={close}>{text.cancel}</Button>
      </Form.Field>
    </Form>
  )
}

const AdminPagePortfoliosSection = ({user, qPortfolios, createPortfolio, mCreatePortfolio, updatePortfolio, mUpdatePortfolio}) => {
  const [showForm, setShowForm] = useState(false)
  const [portfolio, setPortfolio] = useState(null)
  const [saving, setSaving] = useState(false)

  const loading = saving || qPortfolios?.loading || mCreatePortfolio?.loading || mUpdatePortfolio?.loading
  const names = qPortfolios?.data?.portfolios?.map(p => p.name)?.filter(n => portfolio?.name !== n) || []

  const onClickRow = p => !portfolio && !showForm  && (setPortfolio(p), setShowForm(true))
  const toggle = _ => (setPortfolio(null), setShowForm(!showForm))
  const close = _ => (setPortfolio(null), setShowForm(false))
  const save = async p => {
    try {
      close()
      setSaving(true)
      await (portfolio ? updatePortfolio : createPortfolio)(p)
    } catch (e) {
      console.error(e)
    } finally {
      setSaving(false)
    }
  }

  return (
    <Section>
      <Menu attached="top">
        <Menu.Item content={text.portfolios} header icon="folder open" />
        <Menu.Menu position="right">
          <Menu.Item disabled={loading} content={text.newPortfolio} icon="add" onClick={toggle} />
        </Menu.Menu>
      </Menu>
      <Segment attached="bottom" className={cn('body')}>
        <Grid className={cn('grid')} celled="internally">
          {loading ? (
            <Spinner />
          ) : (
            <Grid.Row columns={showForm ? 2 : 1}>
              <Grid.Column width={showForm ? 12 : 16}>
                <Portfolios portfolios={qPortfolios?.data?.portfolios} onClick={onClickRow} selectedPortfolio={portfolio}/>
              </Grid.Column>
              {showForm && (
                <Grid.Column width={4}>
                  <PortfolioForm names={names} portfolio={portfolio} save={save} close={close} />
                </Grid.Column>
              )}
            </Grid.Row>
          )}
        </Grid>
      </Segment>
    </Section>
  )
}

export default AdminPagePortfoliosSection
