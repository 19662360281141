import gql from 'graphql-tag';

export default gql`
  mutation createRequestedIntegration($baseUrl: String, $portfolioId: ID!, $type: String!) {
    integration: createRequestedIntegration(
      baseUrl: $baseUrl
      portfolioId: $portfolioId
      type: $type
    ) {
      id
      portfolioId
      status
      type
    }
  }
`;
