import IntegrationType from '@omniex/poms-core/lib/enums/IntegrationType';

const brand = process.env.REACT_APP_INSTALLATION || 'omniex';

const omniexTitle = 'Omniex Edge';
const title = {
  omniex: omniexTitle,
  six: 'SIX Digital Asset Ecosystem',
}[brand];

const omniexProduct = 'Edge';
const product = {
  omniex: omniexProduct,
  six: 'Digital Asset Ecosystem',
}[brand];

const omniexLogoUrl = '/static/images/omniex/navbar-logo.svg';
const brandLogoUrl = {
  omniex: omniexLogoUrl,
  six: '/static/images/six/navbar-logo.png',
}[brand] || omniexLogoUrl;

const omniexFullLogoUrl = '/static/images/omniex/notification-logo.png';
const brandNotificationUrl = {
  omniex: omniexFullLogoUrl,
  six: '/static/images/six/navbar-logo.png',
}[brand] || omniexFullLogoUrl;

const omniexSupportUrl = 'https://omniex.atlassian.net/servicedesk/customer/portal/1';
const supportUrl = {
  omniex: omniexSupportUrl,
  six: null, // TODO: replace with SIX Support URL
}[brand];

const omniexTosUrl = 'https://omniex.io/terms-of-use';
const tosUrl = {
  omniex: omniexTosUrl,
  six: null, // TODO: replace with SIX ToS URL
}[brand];

const omniexBrandUrl = 'https://omniex.io';
const brandUrl = {
  omniex: omniexBrandUrl,
  six: 'https://www.six-group.com/',
}[brand];

const enableInputWarnings = {
  omniex: false,
  six: true
}[brand];

const { EXCHANGE_BINANCE, EXCHANGE_BITMEX, EXCHANGE_BYBIT, EXCHANGE_DERIBIT, EXCHANGE_FTX, EXCHANGE_HUOBIDM, EXCHANGE_OKEX } = IntegrationType;

const config = {
  api: {
    baseUrl: process.env.REACT_APP_API_BASE_URL,
  },
  enableInputWarnings,
  futures: {
    /**
     * NOTE: Add integration types to this list to support futures on additional venues.
     * Used in selectors/collectVenuesFromRelationships to add supportsFutures boolean to venues,
     * which is used in selectors/collectInstrumentsFromVenues to filter instruments for trade.
     */
    supportedVenues: [EXCHANGE_BINANCE, EXCHANGE_BITMEX, EXCHANGE_BYBIT, EXCHANGE_DERIBIT, EXCHANGE_FTX, EXCHANGE_HUOBIDM, EXCHANGE_OKEX]
  },
  login: {
    baseUrl: process.env.REACT_APP_LOGIN_BASE_URL,
  },
  logger: {
    level: process.env.REACT_APP_LOG_LEVEL,
  },
  pollIntervals: {
    executionReport: parseInt(process.env.REACT_APP_EXECUTION_REPORT_POLL_INTERVAL) || 5000
  },
  product,
  title,
  urls: {
    brand: brandUrl,
    brandLogo: brandLogoUrl,
    notificationLogo: brandNotificationUrl,
    support: supportUrl,
    tos: tosUrl,
  },
};

export default config;
