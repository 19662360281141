const userInfoComponentCopy = {
  labelUserId: 'User ID',
  labelEmailAddress: 'Email Address',
  labelName: 'Name',
  labelOrgName: 'Organization Name',
  labelRole_ADMIN: 'Admin',
  labelRole_ANALYST: 'Analyst',
  labelRole_CRYPTO_ACCOUNT_CONTROLLER: 'Crypto Account Controller',
  labelRole_FIAT_ACCOUNT_CONTROLLER: 'Fiat Account Controller',
  labelRole_PORTFOLIO_MANAGER: 'Portfolio Manager',
  labelRole_SETTLEMENT_OFFICER: 'Settlement Officer',
  labelRole_TRADER: 'Trader',
  labelRoles: 'Roles',
  labelTimeCreated: 'Active Since',
  labelUsername: 'Username'
};

export default userInfoComponentCopy;
