import { createSelector } from 'reselect';

import { getTotalAccountBalance } from '../utils/AccountUtils';
import AccountType from '@omniex/onx-poms-entity-helpers/lib/enums/AccountType'

export function getVenuePositionsFromAccountsWithBalances(accounts) {
  if (!Array.isArray(accounts) || accounts.length === 0) return [];

  return accounts.reduce((positions, account) => {
    const quantity = getTotalAccountBalance(account);

    if (!Number.isFinite(quantity)) return positions;

    if (quantity > 0 || (account.type === AccountType.FUTURE && Math.abs(quantity) > 0)) positions.push({ account, quantity });

    return positions;
  }, []);
}

export default createSelector(accounts => accounts, getVenuePositionsFromAccountsWithBalances);
