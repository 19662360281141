import { arrayOf, number, string, shape } from 'prop-types';
import { Popup } from 'semantic-ui-react';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import { getPriceFormat } from '../../utils/DisplayUtils';
import { isNil } from '@omniex/onx-common-js/lib/utils/LangUtils';
import copyText from './QuotedOrderBlotterTable.copyText';
import AssetType from '@omniex/poms-core/lib/enums/AssetType';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import Table, {
  CELL_FORMAT_NUMBER,
  CELL_FORMAT_TIMESTAMP_UTC
} from '@omniex/onx-common-ui/lib/semantic/react/Table';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/message.css');
require('@omniex/onx-common-ui/lib/semantic/css/table.css');
require('@omniex/onx-common-ui/lib/semantic/css/popup.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const COMPONENT_NAME = 'QuotedOrderBlotterTable';

const popupStyles = {
  container: {
    fontSize: '0.9em',
    padding: '8px 16px'
  },
  identifierLabel: {
    fontSize: '0.9em',
    textAlign: 'right'
  },
  identifier: {
    fontFamily: 'monospace',
    fontSize: '0.9em'
  }
};

// prettier-ignore
const StyledTable = styled(Table)`
  .${COMPONENT_NAME}-currency {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .${COMPONENT_NAME}-fixID {
    font-family: monospace;
    font-size: 0.9em;
  }

  .${COMPONENT_NAME}-quantity, .${COMPONENT_NAME}-quantityHeader, .${COMPONENT_NAME}-price, .${COMPONENT_NAME}-priceHeader {
    padding-right: 0.25em !important;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class QuotedOrderBlotterTable extends PureComponent {
  static propTypes = {
    orders: arrayOf(
      shape({
        id: string.isRequired,
        fixClientID: string.isRequired,
        fixOrderID: string,
        fixQuoteID: string,
        fixVenueOrderID: string,
        instrument: shape({
          id: string.isRequired,
          baseCurrency: shape({
            id: string.isRequired,
            symbol: string.isRequired
          }),
          displayName: string.isRequired,
          termCurrency: shape({
            id: string.isRequired,
            symbol: string.isRequired
          })
        }).isRequired,
        limitPrice: number.isRequired,
        quote: shape({
          id: string.isRequired,
          venueTrader: shape({
            id: string.isRequired,
            username: string.isRequired
          })
        }),
        specifiedCurrency: shape({
          id: string.isRequired,
          symbol: string.isRequired
        }).isRequired,
        side: string.isRequired,
        status: string.isRequired,
        specifiedQuantity: number.isRequired,
        timeCreated: string.isRequired,
        timeLastUpdated: string
      })
    )
  };

  render() {
    if (this.props.orders.length === 0) {
      return this._renderWarningMessage();
    }

    return (
      <StyledTable className={COMPONENT_NAME}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{copyText.tableHeaderTimestamp}</Table.HeaderCell>
            <Table.HeaderCell>
              {copyText.tableHeaderTraderName}
            </Table.HeaderCell>
            <Table.HeaderCell>{copyText.tableHeaderClientID}</Table.HeaderCell>
            <Table.HeaderCell>{copyText.tableHeaderQuoteID}</Table.HeaderCell>
            <Table.HeaderCell>{copyText.tableHeaderOrderID}</Table.HeaderCell>
            <Table.HeaderCell>{copyText.tableHeaderStatus}</Table.HeaderCell>
            <Table.HeaderCell>
              {copyText.tableHeaderInstrument}
            </Table.HeaderCell>
            <Table.HeaderCell>{copyText.tableHeaderSide}</Table.HeaderCell>
            <Table.HeaderCell
              className={cn('quantityHeader')}
              textAlign="right">
              {copyText.tableHeaderQuantity}
            </Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell className={cn('priceHeader')} textAlign="right">
              {copyText.tableHeaderPrice}
            </Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell
              className={cn('quantityHeader')}
              textAlign="right">
              {copyText.tableHeaderQuantityFilled}
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.orders.map(order => {
            return (
              <Table.Row key={order.id}>
                <Table.Cell
                  format="YYYY-MM-DD HH:mm:ss"
                  formatType={CELL_FORMAT_TIMESTAMP_UTC}>
                  {order.timeCreated}
                </Table.Cell>
                <Table.Cell>
                  {get(order, 'quote.venueTrader.username', 'N/A')}
                </Table.Cell>
                <Table.Cell>{order.fixClientID}</Table.Cell>
                <Table.Cell className={cn('fixID')}>
                  {order.fixQuoteID}
                </Table.Cell>
                <Table.Cell className={cn('fixID')}>
                  <Popup
                    flowing
                    hoverable
                    hideOnScroll
                    inverted
                    style={popupStyles.container}
                    trigger={<span>{order.fixVenueOrderID}</span>}>
                    <table>
                      <tbody>
                        <tr>
                          <td style={popupStyles.identifierLabel}>
                            {copyText.popupHeaderEMSOrderID}
                          </td>
                          <td style={popupStyles.identifier}>
                            {isNil(order.fixOrderID)
                              ? copyText.notAvailable
                              : order.fixOrderID}
                          </td>
                        </tr>
                        <tr>
                          <td style={popupStyles.identifierLabel}>
                            {copyText.popupHeaderClientOrderId}
                          </td>
                          <td style={popupStyles.identifier}>
                            {isNil(order.fixClOrdID)
                              ? copyText.notAvailable
                              : order.fixClOrdID}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Popup>
                </Table.Cell>
                <Table.Cell>{order.status}</Table.Cell>
                <Table.Cell>{get(order, 'instrument.displayName')}</Table.Cell>
                <Table.Cell>{order.side}</Table.Cell>
                <Table.Cell
                  className={cn('quantity')}
                  format={
                    get(order, 'specifiedCurrency.type') === AssetType.FIAT
                      ? '0.00'
                      : '0.00000000'
                  }
                  formatType={CELL_FORMAT_NUMBER}>
                  {order.specifiedQuantity}
                </Table.Cell>
                <Table.Cell className={cn('currency')}>
                  {getAssetDisplayText(get(order, 'specifiedCurrency'))}
                </Table.Cell>
                <Table.Cell
                  className={cn('price')}
                  format={getPriceFormat(order.limitPrice)}
                  formatType={CELL_FORMAT_NUMBER}
                  ifNotNumber="--">
                  {order.limitPrice}
                </Table.Cell>
                <Table.Cell className={cn('currency')}>
                  {getAssetDisplayText(get(order, 'instrument.termCurrency'))}
                </Table.Cell>
                <Table.Cell
                  className={cn('quantity')}
                  format={
                    get(order, 'specifiedCurrency.type') === AssetType.FIAT
                      ? '0.00'
                      : '0.00000000'
                  }
                  formatType={CELL_FORMAT_NUMBER}
                  ifNotNumber="--">
                  {order.filledQuantity}
                </Table.Cell>
                <Table.Cell className={cn('currency')}>
                  {getAssetDisplayText(get(order, 'specifiedCurrency'))}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </StyledTable>
    );
  }

  _renderWarningMessage() {
    return (
      <div className={COMPONENT_NAME}>
        <Message content={copyText.warningMessage} warning />
      </div>
    );
  }
}
