const createExchangeAccountsFormCopy = {
  cancelButtonLabel: 'Cancel',
  futureShortLabel: 'Short',
  futureLongLabel: 'Long',
  futureUnitLabel: 'Contract',
  saveButtonLabel: 'Save',
  tableHeaderAdjustedCost: 'Adjusted Cost Basis',
  tableHeaderCurrency: 'Currency',
  tableHeaderName: 'Name',
  tableHeaderQuantity: 'Quantity',
  tableHeaderType: 'Type',
  type_CUSTODIAN_SILVERGATE: 'Silvergate',
  type_EXCHANGE_BEQUANT: 'BeQuant',
  type_EXCHANGE_BINANCE: 'Binance',
  type_EXCHANGE_BINANCEUS: 'Binance US',
  type_EXCHANGE_BITFINEX: 'Bitfinex',
  type_EXCHANGE_BITMEX: 'BitMEX',
  type_EXCHANGE_BITFLYER: 'Bitflyer',
  type_EXCHANGE_BITSTAMP: 'Bitstamp',
  type_EXCHANGE_BITTREX: 'Bittrex',
  type_EXCHANGE_BYBIT: 'Bybit',
  type_EXCHANGE_CROSSTOWER: 'CrossTower',
  type_EXCHANGE_DERIBIT: 'Deribit',
  type_EXCHANGE_FTX: 'FTX',
  type_EXCHANGE_GDAX: 'Coinbase Pro',
  type_EXCHANGE_GEMINI: 'Gemini',
  type_EXCHANGE_HITBTC: 'HitBTC',
  type_EXCHANGE_HUOBI: 'Huobi',
  type_EXCHANGE_HUOBIDM: 'Huobi DM',
  type_EXCHANGE_ITBIT: 'itBit',
  type_EXCHANGE_KRAKEN: 'Kraken',
  type_EXCHANGE_KUCOIN: 'KuCoin',
  type_EXCHANGE_LIQUID: 'Liquid',
  type_EXCHANGE_LMAX: 'LMAX',
  type_EXCHANGE_OKCOIN: 'OKCoin',
  type_EXCHANGE_OKEX: 'OKEx',
  type_EXCHANGE_POLONIEX: 'Poloniex',
  type_EXCHANGE_SEEDCX: 'Seed CX',
  warningMessage: 'Your %exchange% account has been fully integrated on the platform.'
};

export default createExchangeAccountsFormCopy;
