const orderBookTableCopy = {
  headerBidPrice: 'Bid Price',
  headerCumulativeQuantity: 'Cumulative Quantity',
  headerOfferPrice: 'Offer Price',
  headerQuantity: 'Quantity',
  headerVenue: 'Venue',
  warningMessage:
    "We're unable to display an order book for the specified venue at this time."
};

export default orderBookTableCopy;
