import gql from 'graphql-tag';

export default gql`
  mutation releaseFills($id: ID!) {
    settlement: releaseFills(id: $id) {
      id
      status
    }
  }
`;
