import React, { useState, useRef, useEffect } from 'react';

import Box from '@material-ui/core/Box';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { CancelReportButton, DownloadReportButton } from './Buttons';

import axios, { CancelTokenSource } from 'axios';

import moment from '../../../ext/moment';
import MomentUtils from '@date-io/moment'
import triggerFileDownload from './triggerFileDownload';

import config from '../../../config';
import copyText from './copyText';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { CondensedFilterOption } from '../../../types';
import { Moment } from 'moment';

const DATETIME_PICKER_FORMAT = 'L';
const DATETIME_PICKER_MIN_DATE = '2018-01-01';

const { CancelToken, isCancel } = axios;

const getReport = axios.create({
  baseURL: new URL('/report', config.api.baseUrl).toString(),
  withCredentials: true,
  method: 'post',
  responseType: 'blob',
});

export type ReportType = 'FILL' | 'ORDER';

export type Inputs = {
  [column: string]: CondensedFilterOption | undefined
}

export interface DownloadReportProps {
  reportType: ReportType
  inputs: Inputs
};

const formatStartDate = (date: MaterialUiPickersDate): string =>
  moment(date)
  .utc()
  .hour(0)
  .toISOString();

const formatEndDate = (date: MaterialUiPickersDate): string =>
  moment(date)
  .utc()
  .hour(23)
  .minute(59)
  .second(59)
  .toISOString();



const DownloadReport = ({ reportType, inputs }: DownloadReportProps) => {
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [reportDownloading, setReportDownloading] = useState<boolean>(false);

  const cancelToken = useRef<CancelTokenSource | null>(null);

  const disableDownload = !(startDate && endDate);

  const getNewCancelToken = () => {
    cancelToken.current = CancelToken.source();
    return cancelToken.current.token;
  };

  const handleChangeStartDate = (date: MaterialUiPickersDate) => {
    if (date && endDate?.isBefore(date)) {
      setEndDate(date);
    }
    setStartDate(date);
  };

  const handleChangeEndDate = (date: MaterialUiPickersDate) => setEndDate(date && startDate?.isAfter(endDate) ? startDate : date);

  const onDownload = async () => {
    if (reportDownloading) return;

    const config = {
      url: reportType.toLowerCase(),
      cancelToken: getNewCancelToken(),
      data: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        endDate: formatEndDate(endDate),
        startDate: formatStartDate(startDate),
        ...inputs
      }
    }

    setReportDownloading(true);

    try {
      const response = await getReport(config)

      triggerFileDownload(response, reportType)
      setReportDownloading(false);
    } catch (err) {
      if (!isCancel(err)) setReportDownloading(false);
    }
  };

  const onCancel = () => {
    cancelToken.current?.cancel();
    setReportDownloading(false);
  };

  useEffect(() => () => cancelToken.current?.cancel(), []);

  return (
    <Box display='flex' alignItems='stretch' padding='5px' flexDirection='row'>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          id='start-date-picker'
          variant='inline'
          format={DATETIME_PICKER_FORMAT}
          minDate={DATETIME_PICKER_MIN_DATE}
          autoOk
          disableToolbar
          disableFuture
          placeholder={copyText.inputPlaceholder_startDate}
          value={startDate}
          onChange={handleChangeStartDate}
          inputVariant='outlined'
          size='small'
          style={{margin: 5, flex: '1 1 auto'}}
        />
        <DatePicker
          id='end-date-picker'
          variant='inline'
          format={DATETIME_PICKER_FORMAT}
          minDate={DATETIME_PICKER_MIN_DATE}
          autoOk
          disableToolbar
          placeholder={copyText.inputPlaceholder_endDate}
          value={endDate}
          onChange={handleChangeEndDate}
          inputVariant='outlined'
          size='small'
          style={{margin: 5, flex: '1 1 auto'}}
        />
      </MuiPickersUtilsProvider>  
      {
        reportDownloading 
          ? <CancelReportButton onClick={onCancel}/> 
          : <DownloadReportButton onClick={onDownload} disabled={disableDownload}/>
      }
    </Box>
  );
};

export default DownloadReport;
