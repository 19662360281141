import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { createStyles, makeStyles } from '@omniex/poms-ui/lib/themes';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DownloadReport } from '../DownloadReport';
import { BlotterTab } from './shared';
import { SortParams, CondensedFilterOptions } from '../../../types';
import clsx from 'clsx';
// @ts-ignore
import { colors } from '@omniex/onx-common-ui/lib/styles';
import blotterCopyText from './copyText';

const useStyles = makeStyles((theme) =>
  createStyles({
    indicator: {
      height: 5,
      backgroundColor: '#006fbf',
    },
    labelContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    counter: {
      padding: '1px 4px',
      borderRadius: '4px',
      marginLeft: 8,
      background:
        theme.palette.type === 'light'
          ? 'rgba(15, 32, 57, 0.6)'
          : 'rgba(255, 255, 255, 0.5)',
      color: theme.palette.type === 'light' ? 'white' : 'black',
    },
    changedCounter: {
      background: colors.red,
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      border:
        theme.palette.type === 'light'
          ? '1px solid #CFD2D7'
          : '1px solid #2F343B',
      borderRadius: '4px 4px 0 0',
      background: theme.palette.type === 'light' ? 'white' : '#0F151D',
      filter:
        theme.palette.type === 'light'
          ? 'drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.1))'
          : '',
    },
    button: {
      margin: theme.spacing(1),
    },
    label: {
      color: 'rgba(0, 0, 0, 0.87) !important',
    }
  }),
);

type TradeBlotterToolbarProps = {
  canDownloadTradeCSV: boolean
  fillCount: number
  handleChangeTab: (_event: React.ChangeEvent<{}>, newValue: BlotterTab) => void
  isLoading: boolean
  onOpenCsvForm: () => void
  orderCount: number
  portfolioId?: string
  resetFilters: (tab: BlotterTab) => void
  showResetFilters: boolean
  visibleTab: BlotterTab
  filters: CondensedFilterOptions
  sortParams: SortParams
}

const TradeBlotterToolbar: React.FC<TradeBlotterToolbarProps> = ({
  canDownloadTradeCSV,
  fillCount,
  handleChangeTab,
  isLoading,
  onOpenCsvForm,
  orderCount,
  portfolioId,
  resetFilters,
  showResetFilters,
  visibleTab,
  filters,
  sortParams
}) => {
  const [showCsvDownloadForm, setShowCsvDownloadForm] = useState(false);
  const [fillChanged, setFillChanged] = useState(false);
  const [orderChanged, setOrderChanged] = useState(false);
  const lastFillCount = useRef(0);
  const lastOrderCount = useRef(0);
  const classes = useStyles();


  const handleClick = () => {
    setShowCsvDownloadForm(prev => !prev);
    onOpenCsvForm();
  };

  useEffect(() => {
    if (lastFillCount.current !== fillCount) {
      lastFillCount.current = fillCount;
      if (visibleTab === BlotterTab.Orders) setFillChanged(true);
    }
    if (lastOrderCount.current !== orderCount) {
      lastOrderCount.current = orderCount;
      if (visibleTab === BlotterTab.Fills) setOrderChanged(true);
    }
  }, [fillCount, orderCount, visibleTab]);

  const onChangeTab = (event: React.ChangeEvent<{}>, value: BlotterTab) => {
    if (value === BlotterTab.Orders) setOrderChanged(false);
    else setFillChanged(false);
    handleChangeTab(event, value);
  }

  return (
    <Grid id='blotter-toolbar' container justify='space-between' className={classes.container}>
      <Grid container item xs={6}>
        <Tabs
          classes={{ indicator: classes.indicator }}
          value={visibleTab}
          onChange={onChangeTab}
          indicatorColor='primary'
        >
          <Tab
            label={
              <div className={classes.labelContainer}>
                Orders
                <div className={clsx(classes.counter, orderChanged && classes.changedCounter)}>{orderCount}</div>
              </div>
            }
            value={BlotterTab.Orders} />
          <Tab
            label={
              <div className={classes.labelContainer}>
                Fills
                <div className={clsx(classes.counter, fillChanged && classes.changedCounter)}>{fillCount}</div>
              </div>
            }
            value={BlotterTab.Fills}
          />
        </Tabs>
      </Grid>
      <Grid container item xs={6} justify='flex-end' alignItems='center'>
        {isLoading && <CircularProgress disableShrink size={30} />}
        {showResetFilters && (
          <Button onClick={() => resetFilters(visibleTab)} classes={{ label: classes.label }}>
            {blotterCopyText.resetFilters}
          </Button>
        )}
        {canDownloadTradeCSV && (
          <Button
            className={classes.button}
            classes={{ label: classes.label, }}
            onClick={handleClick}
            startIcon={<GetAppIcon />}
          >
            CSV
          </Button>
        )}
      </Grid>
      <Grid container item xs={12} justify='flex-end'>
        {showCsvDownloadForm && (
          <DownloadReport
            key={visibleTab === BlotterTab.Fills ? 'FILL' : 'ORDER'}
            reportType={visibleTab === BlotterTab.Fills ? 'FILL' : 'ORDER'}
            inputs={{ ...filters, ...sortParams, portfolioId }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default TradeBlotterToolbar;
