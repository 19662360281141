import gql from 'graphql-tag';

export default gql`
  mutation deleteIntegration($integrationId: ID!) {
    integration: deleteIntegration(integrationId: $integrationId) {
      id
      status
      type
    }
  }
`;
