import gql from 'graphql-tag';

export default gql`
  mutation placeOrderForSpecificExchange(
    $venueId: ID!
    $instrumentId: ID!
    $side: String!
    $specifiedCurrencyId: ID!
    $specifiedQuantity: Float!
    $type: String!
    $limitPrice: Float
    $stopPrice: Float
    $expiryType: String!
    $customOrderId: String
    $portfolioId: ID!
  ) {
    order: placeOrderForSpecificExchange(
      venueId: $venueId
      instrumentId: $instrumentId
      side: $side
      specifiedCurrencyId: $specifiedCurrencyId
      specifiedQuantity: $specifiedQuantity
      type: $type
      limitPrice: $limitPrice
      stopPrice: $stopPrice
      expiryType: $expiryType
      customOrderId: $customOrderId
      portfolioId: $portfolioId
    ) {
      id
      fixClOrdID
      fixVenueOrderID
      fixOrderID
      status
    }
  }
`;
