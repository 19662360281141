import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import { Label, Loader, Segment } from 'semantic-ui-react';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { get, has } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import {
  isEmpty,
  isError,
  isNil
} from '@omniex/onx-common-js/lib/utils/LangUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { populateQuoteRequestsWithInstruments } from '../../selectors/populateQuoteRequestsWithInstruments';
import ClaimedQuoteRequestsTable from '../components/ClaimedQuoteRequestsTable';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './QuotePageRequestsSection.copyText';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import Menu from '@omniex/onx-common-ui/lib/semantic/react/Menu';
import OrderStatus from '@omniex/onx-poms-entity-helpers/lib/enums/OrderStatus';
// import populateInstrumentsWithCurrencies from '../../selectors/populateInstrumentsWithCurrencies';
import populatePaginatedOrders from '../../selectors/populatePaginatedOrders';
import QuoteStatus from '@omniex/onx-poms-entity-helpers/lib/enums/QuoteStatus';
import UnclaimedQuoteRequestsTable from '../components/UnclaimedQuoteRequestsTable';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/label.css');
require('@omniex/onx-common-ui/lib/semantic/css/loader.css');
require('@omniex/onx-common-ui/lib/semantic/css/segment.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');

const ERROR_MESSAGE_KEY_CLAIM_QUOTE_REQUEST_FAILED =
  'errorMessageClaimQuoteRequestFailed';
const ERROR_MESSAGE_KEY_CANCEL_ORDER_FAILED = 'errorMessageCancelOrderFailed';
const ERROR_MESSAGE_KEY_CANCEL_QUOTE_REQUEST_FAILED =
  'errorMessageCancelQuoteRequestFailed';
const ERROR_MESSAGE_KEY_FILL_ORDER_FAILED = 'errorMessageFillOrderFailed';
const ERROR_MESSAGE_KEY_SEND_QUOTE_FAILED = 'errorMessageSendQuoteFailed';

const SUCCESS_MESSAGE_KEY_ORDER_CANCELED = 'successMessageOrderCanceled';
const SUCCESS_MESSAGE_KEY_QUOTE_REQUEST_CANCELED =
  'successMessageQuoteRequestCanceled';
const SUCCESS_MESSAGE_KEY_ORDER_FILLED = 'successMessageOrderFilled';

const TAB_CLAIMED = 'TAB_CLAIMED';
const TAB_UNCLAIMED = 'TAB_UNCLAIMED';

const DEFAULT_TAB = TAB_UNCLAIMED;

const COMPONENT_NAME = 'QuotePageRequestsSection';

const StyledSection = styled('section')`
  .${COMPONENT_NAME}-segment {
    min-height: 400px;
  }

  .${COMPONENT_NAME}-menuItem {
    justify-content: center;
    width: 140px;
  }

  .${COMPONENT_NAME}-errorMessage + .${COMPONENT_NAME}-segment {
    border-top-color: ${colors.errorBorderColor};
  }

  .${COMPONENT_NAME}-successMessage {
    code {
      font-size: 0.9em;
    }
  }

  .${COMPONENT_NAME}-successMessage + .${COMPONENT_NAME}-segment {
    border-top-color: ${colors.successBorderColor};
  }

  .${COMPONENT_NAME}-tabCount.changed {
    background: ${colors.red} !important;
  }

  .${COMPONENT_NAME}-tableWrapper {
    overflow: auto;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class QuotePageRequestsSection extends PureComponent {
  static propTypes = {
    activeOrdersQueryData: shape({
      orders: arrayOf(
        shape({
          instrument: shape({ id: string.isRequired }).isRequired,
          specifiedCurrency: shape({ id: string.isRequired }).isRequired
        })
      )
    }),
    activeOrdersQueryError: object,
    activeOrdersQueryLoading: bool,
    activeOrdersSubscriptionData: shape({
      orders: arrayOf(
        shape({
          instrument: shape({ id: string.isRequired }).isRequired,
          specifiedCurrency: shape({ id: string.isRequired }).isRequired
        })
      )
    }),
    activeOrdersSubscriptionError: object,
    activeOrdersSubscriptionLoading: bool,
    activeQuoteRequestsQueryData: shape({
      quoteRequests: arrayOf(
        shape({
          id: string.isRequired,
          quotes: arrayOf(
            shape({
              id: string.isRequired,
              order: shape({ status: string.isRequired }),
              status: string.isRequired
            })
          ).isRequired,
          venueTrader: shape({ id: string.isRequired })
        })
      )
    }),
    activeQuoteRequestsQueryLoading: bool,
    activeQuoteRequestsSubscriptionData: shape({
      quoteRequests: arrayOf(
        shape({
          id: string.isRequired,
          quotes: arrayOf(
            shape({
              id: string.isRequired,
              order: shape({ status: string.isRequired }),
              status: string.isRequired
            })
          ).isRequired,
          venueTrader: shape({ id: string.isRequired })
        })
      )
    }),
    cancelOrder: func,
    cancelOrderMutationError: object,
    cancelOrderMutationProcessing: bool,
    cancelOrderMutationResult: shape({
      order: shape({
        id: string.isRequired,
        fixVenueOrderID: string.isRequired
      })
    }),
    cancelQuoteRequest: func,
    cancelQuoteRequestMutationError: object,
    cancelQuoteRequestMutationProcessing: bool,
    cancelQuoteRequestMutationResult: shape({
      quoteRequest: shape({ id: string.isRequired })
    }),
    claimQuoteRequest: func,
    claimQuoteRequestMutationError: object,
    claimQuoteRequestMutationProcessing: bool,
    claimQuoteRequestMutationResult: shape({
      quoteRequest: shape({ id: string.isRequired })
    }),
    currenciesQueryData: shape({
      currencies: arrayOf(shape({ id: string.isRequired }))
    }),
    currenciesQueryError: object,
    currenciesQueryLoading: bool,
    fillOrder: func,
    fillOrderMutationError: object,
    fillOrderMutationProcessing: bool,
    fillOrderMutationResult: shape({
      order: shape({
        id: string.isRequired,
        fixVenueOrderID: string.isRequired
      })
    }),
    instrumentsQueryData: shape({
      instruments: arrayOf(shape({
        id: string.isRequired,
        displayName: string.isRequired
      }))
    }),
    instrumentsQueryError: object,
    instrumentsQueryLoading: bool,
    sendQuote: func,
    sendQuoteMutationError: object,
    sendQuoteMutationProcessing: bool,
    sendQuoteMutationResult: shape({
      quote: shape({ id: string.isRequired })
    })
  };

  static defaultProps = {
    cancelOrder: noop,
    cancelQuoteRequest: noop,
    claimQuoteRequest: noop,
    fillOrder: noop,
    sendQuote: noop
  };

  state = {};

  static getDerivedStateFromProps(nextProps, prevState) {
    if (isEmpty(prevState)) return getInitialState(nextProps);

    const stateChanges = {};

    for (const key of [
      'activeQuoteRequestsQueryData',
      'activeQuoteRequestsSubscriptionData',
      'cancelOrderMutationProcessing',
      'cancelQuoteRequestMutationProcessing',
      'claimQuoteRequestMutationProcessing',
      'claimQuoteRequestMutationResult',
      'fillOrderMutationProcessing',
      'sendQuoteMutationProcessing'
    ]) {
      if (has(nextProps, key) && get(prevState, key) !== get(nextProps, key)) {
        stateChanges[key] = nextProps[key];
      }
    }

    if (
      prevState.selectedTab !== TAB_UNCLAIMED &&
      has(stateChanges, 'activeQuoteRequestsSubscriptionData.quoteRequests')
    ) {
      const prevUnclaimedRequests = get(
        prevState,
        'activeQuoteRequestsSubscriptionData.quoteRequests',
        []
      ).filter(request => !has(request, 'venueTrader.id'));

      const nextUnclaimedRequests = stateChanges.activeQuoteRequestsSubscriptionData.quoteRequests.filter(
        request => !has(request, 'venueTrader.id')
      );

      stateChanges.unclaimedTabCountChange =
        nextUnclaimedRequests.length !== 0 &&
        nextUnclaimedRequests.length !== prevUnclaimedRequests.length;
    }

    if (
      stateChanges.sendQuoteMutationProcessing === false &&
      !isError(nextProps.sendQuoteMutationError) &&
      has(nextProps, 'sendQuoteMutationResult.quote')
    ) {
      stateChanges.expandedRequestId = null;
      stateChanges.isLoadingLastSentQuote = true;
    }

    if (
      has(stateChanges, 'activeQuoteRequestsSubscriptionData.quoteRequests') &&
      stateChanges.activeQuoteRequestsSubscriptionData.quoteRequests.find(
        request =>
          request.id ===
          get(nextProps, 'sendQuoteMutationResult.quote.request.id')
      )
    ) {
      stateChanges.isLoadingLastSentQuote = false;
    }

    if (
      prevState.claimQuoteRequestMutationProcessing === true &&
      stateChanges.claimQuoteRequestMutationProcessing === false
    ) {
      if (
        isError(nextProps.claimQuoteRequestMutationError) &&
        !has(nextProps, 'claimQuoteRequestMutationResult.quoteRequest')
      ) {
        stateChanges.errorMessageKey = ERROR_MESSAGE_KEY_CLAIM_QUOTE_REQUEST_FAILED;
      } else {
        stateChanges.isLoadingLastClaimedRequest = true;
      }
    }

    // TODO: Refactor to a more readable solution after v1.

    if (
      has(stateChanges, 'activeQuoteRequestsSubscriptionData.quoteRequests') &&
      !get(
        get(
          prevState,
          'activeQuoteRequestsSubscriptionData.quoteRequests',
          []
        ).find(
          request =>
            request.id ===
            get(prevState, 'claimQuoteRequestMutationResult.quoteRequest.id')
        ),
        'venueTrader.id'
      ) &&
      get(
        stateChanges.activeQuoteRequestsSubscriptionData.quoteRequests.find(
          request =>
            request.id ===
            get(prevState, 'claimQuoteRequestMutationResult.quoteRequest.id')
        ),
        'venueTrader.id'
      )
    ) {
      stateChanges.isLoadingLastClaimedRequest = false;
      stateChanges.selectedTab = TAB_CLAIMED;
    }

    if (
      stateChanges.sendQuoteMutationProcessing === false &&
      isError(nextProps.sendQuoteMutationError) &&
      !has(nextProps, 'sendQuoteMutationResult.quote')
    ) {
      stateChanges.errorMessageKey = ERROR_MESSAGE_KEY_SEND_QUOTE_FAILED;
    }

    if (
      prevState.fillOrderMutationProcessing === true &&
      stateChanges.fillOrderMutationProcessing === false
    ) {
      if (
        isError(nextProps.fillOrderMutationError) ||
        !has(nextProps, 'fillOrderMutationResult.order')
      ) {
        stateChanges.errorMessageKey = ERROR_MESSAGE_KEY_FILL_ORDER_FAILED;
      } else {
        stateChanges.successMessageKey = SUCCESS_MESSAGE_KEY_ORDER_FILLED;
      }
    }

    if (
      prevState.cancelOrderMutationProcessing === true &&
      stateChanges.cancelOrderMutationProcessing === false
    ) {
      if (
        isError(nextProps.cancelOrderMutationError) ||
        !has(nextProps, 'cancelOrderMutationResult.order')
      ) {
        stateChanges.errorMessageKey = ERROR_MESSAGE_KEY_CANCEL_ORDER_FAILED;
      } else {
        stateChanges.successMessageKey = SUCCESS_MESSAGE_KEY_ORDER_CANCELED;
      }
    }

    if (
      prevState.cancelQuoteRequestMutationProcessing === true &&
      stateChanges.cancelQuoteRequestMutationProcessing === false
    ) {
      if (
        isError(nextProps.cancelQuoteRequestMutationError) ||
        !has(nextProps, 'cancelQuoteRequestMutationResult.quoteRequest')
      ) {
        stateChanges.errorMessageKey = ERROR_MESSAGE_KEY_CANCEL_QUOTE_REQUEST_FAILED;
      } else {
        stateChanges.successMessageKey = SUCCESS_MESSAGE_KEY_QUOTE_REQUEST_CANCELED;
      }
    }

    return stateChanges;
  }

  render() {
    const loading =
      this.props.activeOrdersQueryLoading ||
      !has(this.props, 'activeOrdersQueryData.orders') ||
      this.props.activeQuoteRequestsQueryLoading ||
      !has(this.props, 'activeQuoteRequestsQueryData.quoteRequests') ||
      this.props.currenciesQueryLoading ||
      !has(this.props, 'currenciesQueryData.currencies') ||
      this.props.instrumentsQueryLoading ||
      !has(this.props, 'instrumentsQueryData.instruments');

    const currencies = get(this.props, 'currenciesQueryData.currencies');

    // const instruments = populateInstrumentsWithCurrencies(
    //   get(this.props, 'instrumentsQueryData.instruments'),
    //   currencies
    // );

    const instruments = get(this.props, 'instrumentsQueryData.instruments');

    let requests =
      get(this.props, 'activeQuoteRequestsSubscriptionData.quoteRequests') ||
      get(this.props, 'activeQuoteRequestsQueryData.quoteRequests');

    let orders =
      get(this.props, 'activeOrdersSubscriptionData.orders') ||
      get(this.props, 'activeOrdersQueryData.orders') ||
      [];

    // orders = populatePaginatedOrders(orders, instruments, currencies);
    orders = populatePaginatedOrders(orders, instruments);

    requests = populateQuoteRequestsWithInstruments(
      requests,
      instruments,
      currencies
    );

    let [claimedRequests, unclaimedRequests] = requests.reduce(
      ([claimedRequests, unclaimedRequests], request) =>
        has(request, 'venueTrader.id')
          ? [[...claimedRequests, request], unclaimedRequests]
          : [claimedRequests, [...unclaimedRequests, request]],
      [[], []]
    );

    claimedRequests = claimedRequests.filter(request => {
      const quotes = get(request, 'quotes', []);
      return quotes.length === 0
        ? true
        : !isNil(
            quotes.find(
              quote =>
                quote.status === QuoteStatus.VALID ||
                [
                  OrderStatus.ACTIVE,
                  OrderStatus.PENDING_CANCEL,
                  OrderStatus.SENT
                ].includes(get(quote, 'order.status'))
            )
          );
    });

    requests =
      this.state.selectedTab === TAB_UNCLAIMED
        ? unclaimedRequests
        : claimedRequests;

    return (
      <StyledSection className={COMPONENT_NAME}>
        <Menu attached="top">
          <Menu.Item header icon="comments" content={copyText.sectionTitle} />
          <Menu.Tab
            className={cn('menuItem')}
            active={this.state.selectedTab === TAB_UNCLAIMED}
            onClick={this._handleClickTab.bind(this, TAB_UNCLAIMED)}>
            <div>{copyText.tabLabelUnclaimed}</div>
            <Label
              className={cn(
                `tabCount${
                  this.state.unclaimedTabCountChange ? ' changed' : ''
                }`
              )}>
              {unclaimedRequests.length}
            </Label>
          </Menu.Tab>
          <Menu.Tab
            className={cn('menuItem')}
            active={this.state.selectedTab === TAB_CLAIMED}
            onClick={this._handleClickTab.bind(this, TAB_CLAIMED)}>
            <div>{copyText.tabLabelClaimed}</div>
            <Label>{claimedRequests.length}</Label>
          </Menu.Tab>
        </Menu>
        {this.state.successMessageKey && (
          <Message
            className={cn('successMessage')}
            attached
            closeOnClick
            content={
              /* TODO: fix onx-common-ui Message component to support onDismiss */
              <span
                dangerouslySetInnerHTML={this._getSuccessMessageInnerHTML()}
              />
            }
            icon="check circle outline"
            success
          />
        )}
        {this.state.errorMessageKey ? (
          <Message
            className={cn('errorMessage')}
            attached
            closeOnClick
            content={copyText[this.state.errorMessageKey]}
            error
            icon="warning sign"
          />
        ) : null}
        <Segment attached="bottom" className={cn('segment')}>
          {loading ? (
            <Loader
              className={cn('loader')}
              active
              content={copyText.loadingMessage}
              size="medium"
            />
          ) : (
            this._renderTabContent(requests, orders)
          )}
        </Segment>
      </StyledSection>
    );
  }

  _renderTabContent(requests, orders) {
    switch (this.state.selectedTab) {
      case TAB_UNCLAIMED:
        return (
          <UnclaimedQuoteRequestsTable
            processing={
              this.props.claimQuoteRequestMutationProcessing ||
              this.state.isLoadingLastClaimedRequest
            }
            requests={requests}
            onCancel={this._handleCancelQuoteRequest.bind(this)}
            onClaim={this._handleClaimQuoteRequest.bind(this)}
          />
        );
      case TAB_CLAIMED:
        return (
          <ClaimedQuoteRequestsTable
            orders={orders}
            onCancelOrder={this._handleCancelOrder.bind(this)}
            onFillOrder={this._handleFillOrder.bind(this)}
            processingCancelOrder={this.props.cancelOrderMutationProcessing}
            processingFillOrder={this.props.fillOrderMutationProcessing}
            processingSendQuote={
              this.props.sendQuoteMutationProcessing ||
              this.state.isLoadingLastSentQuote
            }
            requests={requests}
            onCancel={this._handleCancelQuoteRequest.bind(this)}
            onSendQuote={this._handleSendQuote.bind(this)}
          />
        );
      default:
        return null;
    }
  }

  _getSuccessMessageInnerHTML() {
    return {
      __html:
        this.state.successMessageKey === SUCCESS_MESSAGE_KEY_ORDER_FILLED
          ? copyText[this.state.successMessageKey].replace(
              '%fixVenueOrderID%',
              get(this.props, 'fillOrderMutationResult.order.fixVenueOrderID')
            )
          : this.state.successMessageKey === SUCCESS_MESSAGE_KEY_ORDER_CANCELED
          ? copyText[this.state.successMessageKey].replace(
              '%fixVenueOrderID%',
              get(this.props, 'cancelOrderMutationResult.order.fixVenueOrderID')
            )
          : copyText[this.state.successMessageKey]
    };
  }

  _handleClickTab = selectedTab => {
    this.setState(currentState => ({
      errorMessageKey: undefined,
      selectedTab,
      successMessageKey: undefined,
      unclaimedTabCountChange:
        selectedTab === TAB_UNCLAIMED
          ? false
          : currentState.unclaimedTabCountChange
    }));
  };

  _handleCancelQuoteRequest(requestId) {
    this.setState({
      errorMessageKey: undefined,
      successMessageKey: undefined
    });
    this.props.cancelQuoteRequest(requestId);
  }

  _handleClaimQuoteRequest(requestId) {
    this.setState({
      errorMessageKey: undefined,
      successMessageKey: undefined
    });
    this.props.claimQuoteRequest(requestId);
  }

  _handleSendQuote(formInputs) {
    this.setState({ errorMessageKey: undefined });
    this.props.sendQuote(formInputs);
  }

  _handleCancelOrder(orderId) {
    this.setState({
      errorMessageKey: undefined,
      successMessageKey: undefined
    });
    this.props.cancelOrder(orderId);
  }

  _handleFillOrder(orderId) {
    this.setState({
      errorMessageKey: undefined,
      successMessageKey: undefined
    });
    this.props.fillOrder(orderId);
  }
}

function getInitialState(props) {
  return {
    activeQuoteRequestsQueryData: props.activeQuoteRequestsQueryData,
    activeQuoteRequestsSubscriptionData:
      props.activeQuoteRequestsSubscriptionData,
    selectedTab: DEFAULT_TAB
  };
}
