import { useQuery, useMutation } from 'react-apollo';
import React from 'react';

import { ERROR_PERMISSION_DENIED } from '@omniex/onx-common-ui/lib/errors';
import { safeExecAsync } from '../utils/FunctionUtils';
import createCustomCustodianMutation from '../apollo/graphql/createCustomCustodian';
import createInstructionMutation from '../apollo/graphql/createInstruction';
import createManualAccountMutation from '../apollo/graphql/createManualAccount';
import CustodyPage from '../ui/pages/CustodyPage';
import deleteInstructionMutation from '../apollo/graphql/deleteInstruction';
import ErrorPage from '@omniex/onx-common-ui/lib/pages/ErrorPage';
import getCurrencies from '../apollo/graphql/getCurrencies';
import getCustomCustodians from '../apollo/graphql/getCustomCustodians';
import getOrg from '../apollo/graphql/getOrg';
import getPortfolio from '../apollo/graphql/getPortfolio';
import updateAccountMutation from '../apollo/graphql/updateAccount';
import updateCustomCustodianMutation from '../apollo/graphql/updateCustomCustodian';
import updateInstructionMutation from '../apollo/graphql/updateInstruction';

const CustodyPageWithAuth = ({apiClient,  user, portfolio = {} }) => {
  const useApiQuery = (q, o) => useQuery(q, { client: apiClient, ...o })
  const useApiMutation = (m, o) => useMutation(m, { client: apiClient, ...o })

  const orgId = user?.org?.id
  const portfolioId = portfolio?.id

  const qCurrencies = useApiQuery(getCurrencies)
  const qOrg = useApiQuery(getOrg, {skip: !orgId})
  const qPortfolio = useApiQuery(getPortfolio, {skip: !portfolioId, variables: {id: portfolioId}})
  const qCustomCustodians = useApiQuery(getCustomCustodians, {skip: !orgId, variables: {id: orgId}})

  const refetchCustomCustodians = [{query: getCustomCustodians, variables: {id: orgId}}]
  const refetchPortfolio = [{query: getPortfolio, variables: {id: portfolioId}}]

  const [createCustomCustodian, mCreateCustomCustodian] = useApiMutation(createCustomCustodianMutation, {refetchQueries: refetchCustomCustodians})
  const [updateCustomCustodian, mUpdateCustomCustodian] = useApiMutation(updateCustomCustodianMutation, {refetchQueries: refetchCustomCustodians})
  const [createManualAccount, mCreateManualAccount] = useApiMutation(createManualAccountMutation, {refetchQueries: refetchPortfolio})
  const [updateAccount, mUpdateAccount] = useApiMutation(updateAccountMutation, {refetchQueries: refetchPortfolio})
  const [createInstruction, mCreateInstruction] = useApiMutation(createInstructionMutation, {refetchQueries: refetchPortfolio})
  const [updateInstruction, mUpdateInstruction] = useApiMutation(updateInstructionMutation, {refetchQueries: refetchPortfolio})
  const [deleteInstruction, mDeleteInstruction] = useApiMutation(deleteInstructionMutation, {refetchQueries: refetchPortfolio})

  return (
    <CustodyPage
      createCustomCustodian={variables => safeExecAsync(createCustomCustodian, {variables})}
      createCustomCustodianMutationError={mCreateCustomCustodian?.error}
      createCustomCustodianMutationProcessing={mCreateCustomCustodian?.loading}
      createCustomCustodianMutationResult={mCreateCustomCustodian?.data}
      createInstruction={variables => safeExecAsync(createInstruction, {variables})}
      createInstructionMutationError={mCreateInstruction?.error}
      createInstructionMutationProcessing={mCreateInstruction?.loading}
      createInstructionMutationResult={mCreateInstruction?.data}
      createManualAccount={variables => safeExecAsync(createManualAccount, {variables})}
      createManualAccountMutationError={mCreateManualAccount?.error}
      createManualAccountMutationProcessing={mCreateManualAccount?.loading}
      createManualAccountMutationResult={mCreateManualAccount?.data}
      currenciesQueryData={qCurrencies?.data}
      currenciesQueryError={qCurrencies?.error}
      currenciesQueryLoading={qCurrencies?.loading}
      customCustodiansQuery={qCustomCustodians}
      deleteInstruction={variables => safeExecAsync(deleteInstruction, {variables})}
      deleteInstructionMutationError={mDeleteInstruction?.error}
      deleteInstructionMutationProcessing={mDeleteInstruction?.loading}
      deleteInstructionMutationResult={mDeleteInstruction?.data}
      orgQueryData={qOrg?.data}
      orgQueryError={qOrg?.error}
      orgQueryLoading={qOrg?.loading}
      portfolioQueryData={qPortfolio?.data}
      portfolioQueryError={qPortfolio?.error}
      portfolioQueryLoading={qPortfolio?.loading}
      updateAccount={variables => safeExecAsync(updateAccount, {variables})}
      updateAccountMutationError={mUpdateAccount?.error}
      updateAccountMutationProcessing={mUpdateAccount?.loading}
      updateAccountMutationResult={mUpdateAccount?.data}
      updateCustomCustodian={variables => safeExecAsync(updateCustomCustodian, {variables})}
      updateCustomCustodianMutationError={mUpdateCustomCustodian?.error}
      updateCustomCustodianMutationProcessing={mUpdateCustomCustodian?.loading}
      updateCustomCustodianMutationResult={mUpdateCustomCustodian?.data}
      updateInstruction={variables => safeExecAsync(updateInstruction, {variables})}
      updateInstructionMutationError={mUpdateInstruction?.error}
      updateInstructionMutationProcessing={mUpdateInstruction?.loading}
      updateInstructionMutationResult={mUpdateInstruction?.data}
      user={user}
    />
  )
}

const CustodyPageContainer = ({user, ...props}) => user?.canViewCustody
  ? <CustodyPageWithAuth user={user} {...props} /> 
  : <ErrorPage type={ERROR_PERMISSION_DENIED} />

export default CustodyPageContainer
