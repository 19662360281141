import * as React from 'react';
import { useState } from 'react';

import { func } from 'prop-types';
import { Router, Route, Switch } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';


import {
  ADMIN_PAGE_PATH,
  CREDITS_PAGE_PATH,
  CUSTODY_PAGE_PATH,
  DASHBOARD_PAGE_PATH,
  PORTFOLIO_PAGE_PATH,
  QUOTE_PAGE_PATH,
  REPORT_PAGE_PATH,
  REQUESTS_PAGE_PATH,
  ROOT_PATH,
  SETTINGS_PAGE_PATH,
  SETTLEMENT_PAGE_PATH,
  TRADE_PAGE_PATH,
  USER_MGMT_PAGE_PATH
} from './constants/paths';
import { ERROR_NOT_FOUND } from '@omniex/onx-common-ui/lib/errors';
import AdminPageContainer from './containers/AdminPageContainer';
import CreditsPageContainer from './containers/CreditsPageContainer';
import CustodyPageContainer from './containers/CustodyPageContainer';
import DashboardPageContainer from './containers/DashboardPageContainer';
import ErrorPage from '@omniex/onx-common-ui/lib/pages/ErrorPage';
import PageLayout from './ui/pages/PageLayout';
import PortfolioPageContainer from './containers/PortfolioPageContainer';
import QuotePageContainer from './containers/QuotePageContainer';
import ReportPageContainer from './containers/ReportPageContainer';
import RequestsPageContainer from './containers/RequestsPageContainer';
import RootRedirect from './RootRedirect';
import SettingsPageContainer from './containers/SettingsPageContainer';
import SettlementPageContainer from './containers/SettlementPageContainer';
import TradePageContainer from './containers/TradePageContainer';
import UserManagementPageContainer from './containers/UserManagementPageContainer';

const history = createBrowserHistory();

const AppRouter = ({
  apiClient,
  fixApiClient,
  onLogout = func,
  portfolio,
  setPortfolio = func,
  user = {},
  websocketConnected = false,
}) => {

  const [notificationFixClOrdID, setNotificationFixClOrdID] = useState(undefined);
  const [newNotifications, setNewNotifications] = useState([]);

  const handleChangePath = path => {
    history.push(path);
    window.scrollTo(0, 0);
  };

  return (
    <Router history={history}>
      <PageLayout
        apiClient={apiClient}
        fixApiClient={fixApiClient}
        newNotifications={newNotifications}
        onChangePath={handleChangePath}
        onLogout={onLogout}
        portfolio={portfolio}
        setNewNotifications={setNewNotifications}
        setNotificationFixClOrdID={setNotificationFixClOrdID}
        setPortfolio={setPortfolio}
        user={user}
      >
        <Switch>
          <Route
            exact
            path={ROOT_PATH}
            render={() => <RootRedirect user={user} />}
          />
          <Route
            exact
            path={ADMIN_PAGE_PATH}
            render={() => (
              <AdminPageContainer
                apiClient={apiClient}
                portfolio={portfolio}
                user={user}
              />
            )}
          />
          <Route
            exact
            path={USER_MGMT_PAGE_PATH}
            render={() => (
              <UserManagementPageContainer
                apiClient={apiClient}
                user={user}
              />
            )}
          />
          <Route
            exact
            path={REQUESTS_PAGE_PATH}
            render={() => (
              <RequestsPageContainer
                apiClient={apiClient}
                user={user}
              />
            )}
          />
          <Route
            exact
            path={SETTINGS_PAGE_PATH}
            render={() => (
              <SettingsPageContainer
                apiClient={apiClient}
                user={user}
              />
            )}
          />
          <Route
            exact
            path={DASHBOARD_PAGE_PATH}
            render={() => (
              <DashboardPageContainer
                apiClient={apiClient}
                portfolio={portfolio}
                user={user}
              />
            )}
          />
          <Route
            exact
            path={PORTFOLIO_PAGE_PATH}
            render={() => (
              <div>
                <PortfolioPageContainer
                  apiClient={apiClient}
                  portfolio={portfolio}
                  user={user}
                />
              </div>
            )}
          />
          <Route
            exact
            path={TRADE_PAGE_PATH + "/:fixClOrdID?"}
            render={() => (
              <TradePageContainer
                apiClient={apiClient}
                fixApiClient={fixApiClient}
                newNotifications={newNotifications}
                notificationFixClOrdID={notificationFixClOrdID}
                portfolio={portfolio}
                setNotificationFixClOrdID={setNotificationFixClOrdID}
                user={user}
                websocketConnected={websocketConnected}
              />
            )}
          />
          <Route
            exact
            path={SETTLEMENT_PAGE_PATH}
            render={() => (
              <SettlementPageContainer
                apiClient={apiClient}
                fixApiClient={fixApiClient}
                portfolio={portfolio}
                user={user}
              />
            )}
          />
          <Route
            exact
            path={QUOTE_PAGE_PATH}
            render={() => (
              <QuotePageContainer
                apiClient={apiClient}
                fixApiClient={fixApiClient}
                user={user}
              />
            )}
          />
          <Route
            exact
            path={CREDITS_PAGE_PATH}
            render={() => <CreditsPageContainer />}
          />
          <Route
            exact
            path={CUSTODY_PAGE_PATH}
            render={() => (
              <CustodyPageContainer
                apiClient={apiClient}
                portfolio={portfolio}
                user={user}
              />
            )}
          />
          <Route
            exact
            path={REPORT_PAGE_PATH}
            render={() => (
              <ReportPageContainer
                apiClient={apiClient}
                portfolio={portfolio}
                user={user}
              />
            )}
          />
          <Route render={() => <ErrorPage type={ERROR_NOT_FOUND} />} />
        </Switch>
      </PageLayout>
    </Router>
  );
}

export default AppRouter;
