import { arrayOf, func, shape, string } from 'prop-types';
import { Button, Checkbox, Icon, Label, Popup } from 'semantic-ui-react';
import React, { Fragment, PureComponent } from 'react';
import styled from 'react-emotion';

import { get, omit } from '@omniex/poms-core/lib/utils/ObjectUtils';
import { isFunction, isNil } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { opaque } from '@omniex/onx-common-ui/lib/colors/utils';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './IntegrationsTable.copyText';
import IntegrationStatus from '@omniex/poms-core/lib/enums/IntegrationStatus';
import IntegrationType from '@omniex/poms-core/lib/enums/IntegrationType';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import Table from '@omniex/onx-common-ui/lib/semantic/react/Table';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/button.css');
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');
require('@omniex/onx-common-ui/lib/semantic/css/table.css');
require('@omniex/onx-common-ui/lib/semantic/css/popup.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const COMPONENT_NAME = 'IntegrationsTable';

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

const StyledTable = styled(Table)`
  .${COMPONENT_NAME}-credentials {
    font-family: monospace;
  }

  .${COMPONENT_NAME}-editButton {
    background: none !important;
    color: ${colors.blue} !important;
    margin-left: 20px;
    padding: 0;

    :hover {
      color: ${opaque(colors.blue, 0.8)} !important;
    }
  }

  .${COMPONENT_NAME}-editIcon {
    font-size: 1em;
    opacity: 1 !important;
  }

  .${COMPONENT_NAME}-loader {
    color: ${colors.grey};
  }

  .${COMPONENT_NAME}-firstColumn {
    padding-left: 0.5em !important;
  }

  .${COMPONENT_NAME}-futuresColumn {
    padding: 0.4em 0.6em 0em !important;
  }

  .${COMPONENT_NAME}-custodian {
    cursor: auto !important;
  }

  .${COMPONENT_NAME}-selected {
    background: ${colors.selectedBackgroundColor};

    + tr:not(.${COMPONENT_NAME}-custodian, .${COMPONENT_NAME}-) {
      background: ${colors.selectedBackgroundColor};
    }

    + tr.expanded {
      background: ${colors.selectedBackgroundColor};
    }

    > td.${COMPONENT_NAME}-credentials.edit {
      color: ${colors.selectedTextColor} !important;

      > a.IntegrationsTable-editButton {
        color: ${colors.selectedTextColor} !important;
      }
    }
  }

  .${COMPONENT_NAME}-statusLabel {
    border-radius: 0.2em;
    font-size: 0.85em;
    text-align: center;
    width: 80px;

    &.blocked {
      background: ${colors.white};
      border: 1px solid ${colors.red};
      color: ${colors.red};
    }

    &.requested {
      background: ${colors.white};
      border: 1px solid ${colors.orange};
      color: ${colors.orange};
    }

    &.unverified {
      background: ${colors.white};
      border: 1px solid ${colors.negativeTextColor};
      color: ${colors.negativeTextColor};
    }

    &.verified {
      background: ${colors.white};
      border: 1px solid ${colors.positiveTextColor};
      color: ${colors.positiveTextColor};
    }

    &.review {
      background: ${colors.white};
      border: 1px solid ${colors.red};
      color: ${colors.red};
    }
  }

  .${COMPONENT_NAME}-questionIcon {
    color: ${colors.blue} !important;
    padding: 0.15em 0.6em;
    vertical-align: top !important;

    :hover {
      color: ${opaque(colors.blue, 0.8)} !important;
      cursor: pointer;
    }
  }
`;

export default class IntegrationsTable extends PureComponent {
  static propTypes = {
    addReadOnlyCredentials: func, // NOTE: Temporary to migrate existing clients to the two-credentials model
    integrations: arrayOf(
      shape({
        id: string.isRequired,
        credentialsPreview: string,
        credentialsForViewPreview: string,
        status: string.isRequired,
        type: string.isRequired
      })
    ),
    renderExpandedContent: func,
    onDelete: func,
    onOpenEditForm: func,
    onSelected: func,
    onUpdateIntegrationSettings: func,
  };

  static defaultProps = {
    addReadOnlyCredentials: noop, // NOTE: Temporary to migrate existing clients to the two-credentials model
    renderExpandedContent: noop,
    onDelete: noop,
    onOpenEditForm: noop,
    onSelected: noop,
    onUpdateIntegrationSettings: noop,
  };

  state = {};

  render() {
    if (get(this.props, 'integrations.length', 0) === 0) {
      return this._renderWarningMessage();
    }

    return (
      <StyledTable className={COMPONENT_NAME}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className={cn('firstColumn')}>{copyText.tableHeaderName}</Table.HeaderCell>
            <Table.HeaderCell>{copyText.tableHeaderStatus}</Table.HeaderCell>
            <Table.HeaderCell>{copyText.tableHeaderKeyPreview}</Table.HeaderCell>
            <Table.HeaderCell>{copyText.tableHeaderKeyForViewPreview}</Table.HeaderCell>
            <Table.HeaderCell>{copyText.tableHeaderEnableFutures}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.integrations.map(integration => (
            <Fragment key={integration.id}>
              <Table.Row
                className={cn(
                  [
                    this.props.selectedIntegrationId,
                    this.props.showSaveIntegrationForm
                  ].includes(integration.id)
                    ? 'selected'
                    : integration.type === IntegrationType.CUSTODIAN_SILVERGATE
                    ? 'custodian'
                    : ''
                )}
                onClick={
                  isFunction(this.props.renderExpandedContent) &&
                  integration.type !== IntegrationType.EXCHANGE_LMAX &&
                  integration.type !== IntegrationType.CUSTODIAN_SILVERGATE
                    ? this._handleClickExpandRow.bind(this, integration)
                    : this.props.addReadOnlyCredentials
                }>
                <Table.Cell width={4} className={cn('firstColumn')}>
                  {copyText[`type_${integration.type}`]}
                </Table.Cell>
                <Table.Cell width={4}>
                  <Label
                    className={cn(
                      integration.type ===
                        IntegrationType.CUSTODIAN_SILVERGATE ||
                        !isNil(integration.credentialsForViewPreview)
                        ? `statusLabel ${integration.status.toLowerCase()}`
                        : `statusLabel review`
                    )}>
                    {formatStatus(integration)}
                  </Label>
                </Table.Cell>
                <Table.Cell
                  width={4}
                  className={cn(
                    integration.type === IntegrationType.CUSTODIAN_SILVERGATE
                      ? ''
                      : get(this.props, 'isEditingCredentials')
                      ? 'credentials edit'
                      : 'credentials'
                  )}>
                  {formatCredentialsPreview(integration)}

                  {[
                    IntegrationType.CUSTODIAN_SILVERGATE,
                    IntegrationType.EXCHANGE_LMAX
                  ].includes(integration.type) ||
                  isNil(integration.credentialsPreview) ? null : (
                    <Button
                      className={cn('editButton')}
                      as="a"
                      content={<Icon className={cn('editIcon')} name="edit" />}
                      onClick={this._handleClickEditButton.bind(this, {
                        integrationId: integration.id,
                        isEditingCredentials: true,
                        isEditingCredentialsForView: false
                      })}
                    />
                  )}
                </Table.Cell>
                <Table.Cell
                  width={2}
                  className={cn(
                    integration.type === IntegrationType.CUSTODIAN_SILVERGATE
                      ? ''
                      : get(this.props, 'isEditingCredentialsForView')
                      ? 'credentials edit'
                      : 'credentials'
                  )}>
                  {formatCredentialsPreview(integration, true)}
                  {[
                    IntegrationType.CUSTODIAN_SILVERGATE,
                    IntegrationType.EXCHANGE_LMAX
                  ].includes(integration.type) ||
                  isNil(integration.credentialsForViewPreview) ? null : (
                    <Button
                      className={cn('editButton')}
                      as="a"
                      content={<Icon className={cn('editIcon')} name="edit" />}
                      onClick={this._handleClickEditButton.bind(this, {
                        integrationId: integration.id,
                        isEditingCredentials: false,
                        isEditingCredentialsForView: true
                      })}
                    />
                  )}
                </Table.Cell>
                <Table.Cell width={2} className={cn('futuresColumn')}>
                  {integration.type === IntegrationType.EXCHANGE_BINANCE && (
                  <div>
                    <Checkbox
                      checked={integration.settings?.enableFutures}
                      toggle
                      onClick={e => this._handleToggleFutures(integration, e)}
                    />
                    <Popup
                      hoverable
                      hideOnScroll
                      inverted
                      trigger={<Icon inverted className={cn('questionIcon')} name="question circle outline"/>}>
                      {copyText.enableFuturesTooltip}
                    </Popup>
                  </div>
                )}
                </Table.Cell>
              </Table.Row>
              {integration.id === this.props.selectedIntegrationId ? (
                <Table.Row className="expanded">
                  <Table.Cell border="none" colSpan={5}>
                    {this.props.renderExpandedContent(integration)}
                  </Table.Cell>
                </Table.Row>
              ) : null}
            </Fragment>
          ))}
        </Table.Body>
      </StyledTable>
    );
  }

  _renderWarningMessage() {
    return (
      <div className={COMPONENT_NAME}>
        <Message content={copyText.warningMessage} warning />
      </div>
    );
  }

  _handleClickDeleteButton(id) {
    this.props.onDelete(id);
  }

  _handleClickEditButton(parameters, event) {
    event.stopPropagation();

    const {
      integrationId,
      isEditingCredentials,
      isEditingCredentialsForView
    } = parameters;

    return this.props.onEditSettings({
      integrationId,
      isEditingCredentials,
      isEditingCredentialsForView
    });
  }

  _handleClickExpandRow(integration) {
    return isNil(integration.credentialsForViewPreview)
      ? this.props.addReadOnlyCredentials(integration)
      : this.props.onSelected(integration);
  }

  _handleCloseDeletePopup() {
    this.setState({ confirmDeleteInstrumentId: undefined });
  }

  _handleOpenDeletePopup(id) {
    this.setState({ confirmDeleteInstrumentId: id });
  }

  _handleToggleFutures = (integration, event) => {
    event.preventDefault();
    event.stopPropagation();

    this.props.onUpdateIntegrationSettings(
      integration.id,
      {
        ...omit(integration.settings, '__typename'),
        enableFutures: !integration.settings?.enableFutures,
      });
  };
}

function formatCredentialsPreview(integration, isForView) {
  if (integration.type === IntegrationType.CUSTODIAN_SILVERGATE) {
    if (isForView) return '';

    switch (integration.status) {
      case IntegrationStatus.BLOCKED:
        return copyText.blockedIntegration;
      case IntegrationStatus.REQUESTED:
        return copyText.requestedIntegration;
      default:
        return '';
    }
  }

  const credentialsToParse = isForView
    ? integration.credentialsForViewPreview || ''
    : integration.credentialsPreview || '';

  return `${credentialsToParse.split(',').join('*****')}`;
}

/**
 * Format `integration.status` for display in UI. Alternatively, display "Needs review" if `integration.credentialsForViewPreview` is missing
 * @param {Object} integration
 * @returns {String}
 */

function formatStatus(integration) {
  return integration.type === IntegrationType.CUSTODIAN_SILVERGATE ||
    !isNil(integration.credentialsForViewPreview)
    ? `${copyText[`status_${integration.status}`]}`
    : `${copyText.status_REVIEW}`;
}
