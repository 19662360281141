import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import createCachedSelector from 're-reselect';
import { Order, Notification, OrgVenueRelationship, Venue, PopulatedInstrument, Integration, Dictionary } from '../types';
import { keyBy, has } from 'lodash';
import config from '../config';
import InstrumentType from '@omniex/poms-core/lib/enums/InstrumentType';
import IntegrationType from '@omniex/poms-core/lib/enums/IntegrationType';
import { filterMap } from '@omniex/poms-core/lib/utils/FunctionUtils';
import { unique } from '@omniex/poms-core/lib/utils/ArrayUtils';

type State = {
  integrations?: Integration[]
  newNotifications?: Array<Notification>
  orders?: Array<Order>
  relationships?: Array<OrgVenueRelationship>
}

type Props = {
  supportsEsp?: boolean
  venueType?: string
}

const createBetterSelector = createSelectorCreator(
  defaultMemoize,
  (a: State, b: State) => a === b || unique(Object.keys(a).concat(Object.keys(b)))
    .every(k => a[k as keyof State] === b[k as keyof State])
);

const options = {
  selectorCreator: createBetterSelector,
  keySelector: ({ integrations }: State, props: Props) => `${!!integrations?.length}:${props?.venueType}:${!!props?.supportsEsp}`
};

const checkFuturesSupport = (venue: Venue) => config.futures.supportedVenues.includes(venue.integrationType);

const blacklistedVenues = [
  'liquid',
  'okcoin',
  'poloniex',
  'ftx',
]

const getVenue = (venue: Venue, integration?: Integration): Venue =>
  integration?.type === IntegrationType.EXCHANGE_BINANCE && !integration?.settings?.enableFutures
    ? {
      ...venue,
      supportsFutures: checkFuturesSupport(venue),
      instrumentConfigurations: venue.instrumentConfigurations.filter(vic => vic.instrument?.type !== InstrumentType.FUTURE)
    } : {
      ...venue,
      supportsFutures: checkFuturesSupport(venue)
    };

const getIntegrations = (state: State) => state.integrations ?? [];
const getNewNotifications = (state: State) => state.newNotifications ?? [];
const getOrders = (state: State) => state.orders ?? [];
const getRelationships = (state: State) => state.relationships ?? [];

const getKeyedIntegrations = createSelector(
  getIntegrations,
  (integrations) => keyBy(integrations, 'type')
);

export const getVenues = createSelector(
  getRelationships,
  (relationships) => relationships.map(r => r.venue)
);

export const getFilteredVenues = createCachedSelector<State, Props, Dictionary<Integration>, Venue[], Props, Venue[]>(
  [getKeyedIntegrations, getVenues, (_, props) => props ?? {}],
  (keyedIntegrations, venues, { venueType, supportsEsp }) => filterMap(
    venues,
    v => !blacklistedVenues.includes(v.symbol) && (v.type === venueType || !venueType || (supportsEsp && v.supportsEsp)),
    v => getVenue(v, keyedIntegrations[v.integrationType])
  )
)(options);

export const getKeyedFilteredVenues = createCachedSelector(
  getFilteredVenues,
  (venues) => keyBy(venues, 'id')
)(options);

export const getKeyedInstrumentsFromVenues = createCachedSelector(
  getFilteredVenues,
  (venues) => venues.reduce<Dictionary<PopulatedInstrument>>((acc, v) => {
    for (const { instrument } of v.instrumentConfigurations) {
      if (!instrument) return acc;

      const isValid = instrument.type === InstrumentType.CURRENCY ||
        (instrument.type === InstrumentType.FUTURE && !!v.supportsFutures);

      if (isValid && !has(acc, instrument.id)) {
        acc[instrument.id] = instrument;
      }
    }
    return acc;
  }, {})
)(options);

export const getSortedInstrumentsFromVenues = createCachedSelector(
  getKeyedInstrumentsFromVenues,
  (keyedInstruments) => Object.values(keyedInstruments).sort((a, b) => a.displayName.localeCompare(b.displayName))
)(options);


const populateOrder = (
  o: Order,
  newNotifications: Array<Notification>
): Order => {
  const keyedExistingNotifications = keyBy(o.notifications, 'id');

  const filteredNotifications = newNotifications.filter(n => (
    (n.order?.id === o.id) && (!keyedExistingNotifications[n.id])
  ))

  return {
    // would like to use Immer here but will save for future PR
    ...o,
    notifications: (o.notifications || []).concat(filteredNotifications),
  }
};

export const populateBlotterOrders = createSelector(
  getNewNotifications,
  getOrders,
  (newNotifications = [], orders) => orders.length ? orders.map(o => populateOrder(o, newNotifications)) : []
);
