import OrgVenueRelationshipStatus from '@omniex/onx-poms-entity-helpers/lib/enums/OrgVenueRelationshipStatus';

const exchangeVenueRelationshipsTableCopy = {
  [`statusLabel_${OrgVenueRelationshipStatus.CONFIRMED}`]: 'Verified',
  [`statusLabel_${OrgVenueRelationshipStatus.PENDING}`]: 'Pending',
  tableHeaderName: 'Name',
  tableHeaderStatus: 'Status',
  warningMessage:
    'There are no exchanges associated with your organization at this time.'
};

export default exchangeVenueRelationshipsTableCopy;
