import { createSelector } from 'reselect';

import { uniqueBy } from '@omniex/poms-core/lib/utils/ArrayUtils';

/**Generates instrument options list for dropdowns. Value flag
 * determines whether value of options is instrument id (ESP)
 * or instrument name (DMA, Algo, RFQ).
 * @param {Array} instruments Array of instruments.
 * @param {Boolean} valueId Value flag (defaults to false).
 * @returns {Array} Instrument options for dropdowns.
 */
export function getInstrumentOptions(instruments, valueId = false) {
  if (!Array.isArray(instruments) || instruments.length === 0) return [];

  return uniqueBy(instruments.map(i => ({
    label: i.displayName,
    value: valueId ? i.id : i.displayName
  })), 'value')
}

export default createSelector(
  (instruments, valueId) => instruments,
  (instruments, valueId) => valueId,
  getInstrumentOptions
);
