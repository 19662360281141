import React from 'react';
import styled from 'react-emotion';

import AssetType from '@omniex/poms-core/lib/enums/AssetType';
import apiClient from '../../_registry/apiClient'
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './PreTradeEstimates.copyText';
import { format as formatNumber } from '@omniex/onx-common-js/lib/utils/NumberUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import { getDHMString, getNumberFormat, formatNumberWithoutTrailingZeroes } from '../../utils/DisplayUtils';
import getDuration from '../../apollo/graphql/getDuration';
import getVolume from '../../apollo/graphql/getVolume';
import InstrumentType from '@omniex/poms-core/lib/enums/InstrumentType';
import { isNil, isEmpty } from '@omniex/onx-common-js/lib/utils/LangUtils';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import { pluralize } from '@omniex/poms-core/lib/utils/StringUtils';
import { useQuery } from '@apollo/react-hooks';
import OrderAlgorithmStrategy from '@omniex/poms-core/lib/enums/OrderAlgorithmStrategy';

const COMPONENT_NAME = 'PreTradeEstimates';

const StyledMessage = styled(Message)`
.${COMPONENT_NAME}-tableHeader {
  text-align: left !important;
  font-size: 16px !important;
}

.${COMPONENT_NAME}-label {
  text-align: left;
  justify-content: left;
}

.${COMPONENT_NAME}-value {
  text-align: right;
}

.${COMPONENT_NAME}-message {
  background-color: ${colors.infoBackgroundColor};
  padding: 8px !important;
}

.${COMPONENT_NAME}-table {
  width: 100%;
}
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

const MILLISECONDS_PER_SECOND = 1000;
const SECONDS_PER_MINUTE = 60;
const MINUTES_PER_HOUR = 60;


// VOLUME_BAR_GRANULARITY is the duration in seconds of one volume bar
const VOLUME_BAR_GRANULARITY = 5 * SECONDS_PER_MINUTE;

// WEEKS is the number of weeks ago from now to include in historical volume analysis
const WEEKS = 3;

// Seasonality is an enum indicating the comparison used to determine historical volume trends
const Seasonality = {
  DAY: 'DAY',
  WEEK: 'WEEK',
}

const getPreTradeDisplaySymbol = (instrument = {}, count = 0) => (
  instrument.type === InstrumentType.CURRENCY
    ? getAssetDisplayText(instrument.baseAsset)
    : pluralize('Contract', count)
)

const renderTableHead = (text = '') => (
  <thead>
    <tr>
      <th className={cn('tableHeader')}
        colSpan={2}>
        {text}
      </th>
    </tr>
  </thead>
)

const toTimerDisplay = (duration) => {
  const totalSec = duration
  const sec = totalSec % SECONDS_PER_MINUTE
  const totalMin = Math.floor(totalSec / SECONDS_PER_MINUTE)
  const min = totalMin % MINUTES_PER_HOUR
  const hours = Math.floor(totalMin / MINUTES_PER_HOUR)

  const timerDisplay = num => (num  < 10 ? '0' : '') + (num === 0 ? '0' : num.toString())

  return `${timerDisplay(hours)}:${timerDisplay(min)}:${timerDisplay(sec)}`
}

const AveragePostingIntervalAmount = ({ twapIntervals, instrument, quantity, isPostingInterval }) => {
  if (!isPostingInterval || !twapIntervals) return null;

  const baseAsset = instrument?.baseAsset;
  const minSigDecimals = baseAsset?.type === AssetType.FIAT ? 2 : 1;
  const averagePostIntervalAmount = quantity / parseInt(twapIntervals);
  const formattedAverageIntervalAmount = formatNumberWithoutTrailingZeroes(averagePostIntervalAmount, getNumberFormat(baseAsset), minSigDecimals);
  const symbol = getPreTradeDisplaySymbol(instrument, formattedAverageIntervalAmount);
  
  return  (
    <tr>
      <td className={cn('label')}>
        {copyText.avgPostingIntervalAmountHeader}
      </td>
      <td className={cn('value')}>
        {`${formattedAverageIntervalAmount} ${symbol}`}
      </td>
    </tr>
  );

}

const renderDurationTable = (duration) => (
  <table className={cn('table')}>
    {renderTableHead(copyText.header)}
    <tbody>
      <tr>
        <td className={cn('label')}>
          {copyText.duration}
        </td>
        <td className={cn('value')}>
          {duration === '' ? '< 1 minute' : duration}
        </td>
      </tr>
    </tbody>
  </table>
);

const renderVolumeTable = (volume, participation, instrument) => {

  const displayVolume = Math.max(volume, 0)
    ? `${volume} ${getPreTradeDisplaySymbol(instrument, volume)}`
    : `< 1 ${getPreTradeDisplaySymbol(instrument, 1)}`;

  return (
    <table className={cn('table')}>
      {renderTableHead(copyText.header)}
      <tbody>
        <tr>
          <td className={cn('label')}>{copyText.volume}</td>
          <td className={cn('value')}>{displayVolume}</td>
        </tr>
        <tr>
          <td className={cn('label')}>{copyText.participation}</td>
          <td className={cn('value')}>{formatNumber(participation, '0.00')}%</td>
        </tr>
      </tbody>
    </table>
  )
};

function getTimeRange(duration) {
  // NOTE: getVolume will round start and end down to nearest 5 min interval
  // We round down start here to nearest 5 min so query only changes every 5 mins
  const nowSecs = Math.floor(Date.now() / MILLISECONDS_PER_SECOND);
  const start = nowSecs - (nowSecs % VOLUME_BAR_GRANULARITY);
  const dt = Math.max(duration, VOLUME_BAR_GRANULARITY);
  const end = start + dt;
  return { start, end };
}

const OrderDuration = ({ adjustedDuration, isPostingInterval, orderDuration,  }) => {
  if (isPostingInterval) return null;

  return (
    <tr>
      <td className={cn('label')}>
        {copyText.orderDuration}
      </td>
      <td className={cn('value')}>
        {`${adjustedDuration ? copyText.adjusted : ''}${toTimerDisplay(adjustedDuration || orderDuration)}`}
      </td>
    </tr>
  );
};

const ExecutionScheduleTable = ({ 
  adjustedDuration,
  displayExecutionTable,
  instrument,
  isPostingInterval, 
  orderDuration, 
  twapIntervals, 
  quantity, 
}) => {
  if (!displayExecutionTable) return null;

  return (
    <table className={cn('table')}>
      {renderTableHead(copyText.executionScheduleHeader)}
      <tbody>
        <tr>
          <td className={cn('label')}>
            {isPostingInterval ? copyText.postingIntervalHeader : copyText.intervalHeader}
          </td>
          <td className={cn('value')}>
            {twapIntervals ?? copyText.na}
          </td>
        </tr>
        <tr>
          <td className={cn('label')}>
            {isPostingInterval ? copyText.postingIntervalDuration : copyText.intervalDuration}
          </td>
          <td className={cn('value')}>
            {twapIntervals ? toTimerDisplay(Math.floor((adjustedDuration || orderDuration) / twapIntervals)) : copyText.na}
          </td>
        </tr>
        <OrderDuration 
          adjustedDuration={adjustedDuration}
          isPostingInterval={isPostingInterval}
          orderDuration={orderDuration}
        />
        <AveragePostingIntervalAmount 
          twapIntervals={twapIntervals}
          isPostingInterval={isPostingInterval}
          quantity={quantity}
          instrument={instrument}
        />
      </tbody>
    </table>
  );
}

const VolumePrediction = ({ duration, instrument, quantity, venueIds }) => {
  const { start, end } = getTimeRange(duration);
  const variables = {
    instrumentId: instrument.id,
    venueIds,
    start,
    end,
    weeks: WEEKS,
    seasonality: Seasonality.WEEK,
  };
  // hooks must be before any conditional rendering
  const { loading, error, data } = useQuery(getVolume, {
    variables,
    client: apiClient,
    skip: duration === 0

  });
  if (duration === 0) return renderVolumeTable(0, 0, instrument);

  if (loading || error) return renderVolumeTable('', '', instrument);

  const volume = Math.floor(data.getVolume);
  const participation = volume === 0 ? 100 : (quantity / volume) * 100;
  return renderVolumeTable(volume, participation, instrument);
};

const DurationPrediction = ({ participationRate, instrument, quantity, venueIds }) => {
  const pov = participationRate / 100;

  const variables = {
    instrumentId: instrument.id,
    venueIds,
    pov,
    totalVolume: quantity,
    weeks: WEEKS,
    seasonality: Seasonality.WEEK,
  };

  const { loading, error, data } = useQuery(getDuration, {
    variables,
    client: apiClient,
    skip: quantity <= 0
  });
  if (quantity <= 0) return renderDurationTable('0');

  if (loading || error) return renderDurationTable('');

  const duration = data.getDuration;
  const formattedDuration = getDHMString(duration, 'seconds');
  return renderDurationTable(formattedDuration);
};

const validProps = (d, i, p, q, v) => !(isNil(i) || isNaN(q) || isEmpty(v) || (isNaN(d) === isNaN(p) || d === 0 || p === 0));

const shouldDisplayExecutionScheduleTable = (algorithmStrategy) => [
  OrderAlgorithmStrategy.PTWAP, 
  OrderAlgorithmStrategy.PTWAPSE,
].includes(algorithmStrategy);

const PreTradeEstimates = ({
  adjustedDuration,
  algorithmStrategy,
  duration,
  instrument,
  isPostingInterval,
  participationRate,
  quantity,
  twapIntervals,
  venueIds,
}) => validProps(duration, instrument, participationRate, quantity, venueIds) && (
  <StyledMessage className={cn('message')}
    data-position="top left"
    data-inverted
    name='preTradeEstimatesMessage'>
    {participationRate &&
      <DurationPrediction
        instrument={instrument}
        participationRate={participationRate}
        quantity={quantity}
        venueIds={venueIds}
      />
    }
    <ExecutionScheduleTable  
      adjustedDuration={adjustedDuration}
      displayExecutionTable={shouldDisplayExecutionScheduleTable(algorithmStrategy)}
      instrument={instrument}
      orderDuration={duration}
      isPostingInterval={isPostingInterval}
      quantity={quantity}
      twapIntervals={twapIntervals}
    />
    {duration &&
      <VolumePrediction
        duration={duration}
        instrument={instrument}
        quantity={quantity}
        venueIds={venueIds}
      />
    }
  </StyledMessage>
);

export default PreTradeEstimates;
