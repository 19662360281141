const updateTradeSettingsFormCopy = {
  cancelButtonLabel: 'Cancel',
  noSettings: 'You do not have any saved Trade settings.',
  saveButtonLabel: 'Update',
  otcClickLabel: 'OTC Tiles execute on',
  singleClickLabel: 'Single Click',
  doubleClickLabel: 'Double Click',
  title: 'Trade Settings',
};

export default updateTradeSettingsFormCopy;
