import { arrayOf, func, number, shape, string } from 'prop-types';
import { Icon, Loader, Menu, Segment } from 'semantic-ui-react';
import React, { Component } from 'react';
import styled from 'react-emotion';

import {
  CHART_TYPE_CANDLESTICK,
  CHART_TYPE_LINE,
  CHART_TYPE_OHLC,
  RANGE_1MONTH,
  RANGE_3MONTHS,
  RANGE_6MONTHS,
  RANGE_1YEAR,
  RANGE_ALL
} from '../components/MarketHistoryChart';
import { arrayOfShape, queryShape } from '../../utils/PropTypeUtils';
import { changed } from '../../utils/PerformanceUtils';
import { get, has } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { isBlank } from '@omniex/onx-common-js/lib/utils/StringUtils';
import { isEmpty } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { isValid as isValidIdentifier } from '@omniex/onx-common-js/lib/utils/IdentifierUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './TradePageMarketHistorySection.copyText';
import Dropdown from '@omniex/onx-common-ui/lib/react-select/Dropdown';
import filterTradeHistoryByInstrumentId from '../../selectors/filterTradeHistoryByInstrumentId';
import getFeedOptions from '../../selectors/getFeedOptions';
import getGroupedInstrumentOptions from '../../selectors/getGroupedInstrumentOptions';
import getInstrumentOptions from '../../selectors/getInstrumentOptions';
import getInstrumentFromNameVenue from '../../selectors/getInstrumentFromNameVenue';
import getVenuesWithInstrument from '../../selectors/getVenuesWithInstrument';
import MarketHistoryChart from '../components/MarketHistoryChart';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import VenueType from '@omniex/onx-poms-entity-helpers/lib/enums/VenueType';
import { getFilteredVenues, getSortedInstrumentsFromVenues } from '../../selectors/blotterSelectors';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/input.css');
require('@omniex/onx-common-ui/lib/semantic/css/loader.css');
require('@omniex/onx-common-ui/lib/semantic/css/segment.css');
require('@omniex/onx-common-ui/lib/semantic/css/form.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const chartTypeOptions = [
  {
    label: copyText.chartTypeCandlestick,
    value: CHART_TYPE_CANDLESTICK
  },
  {
    label: copyText.chartTypeLine,
    value: CHART_TYPE_LINE
  },
  {
    label: copyText.chartTypeOhlc,
    value: CHART_TYPE_OHLC
  }
];

const chartRangeOptions = [
  { label: copyText.chartRange1Month, value: RANGE_1MONTH },
  { label: copyText.chartRange3Months, value: RANGE_3MONTHS },
  { label: copyText.chartRange6Months, value: RANGE_6MONTHS },
  { label: copyText.chartRange1Year, value: RANGE_1YEAR },
  { label: copyText.chartRangeAll, value: RANGE_ALL }
];

const FEED_SYMBOL_COIN_MARKET_CAP = 'coinmarketcap';
const FEED_SYMBOL_CRYPTO_COMPARE = 'cryptocompare';

const defaultFeedOptions = [
  {
    label: copyText.feedSymbolCoinMarketCap,
    value: FEED_SYMBOL_COIN_MARKET_CAP
  },
  {
    label: copyText.feedSymbolCryptoCompare,
    value: FEED_SYMBOL_CRYPTO_COMPARE
  }
];

const DEFAULT_CHART_TYPE = CHART_TYPE_CANDLESTICK;
const DEFAULT_FEED_SYMBOL = FEED_SYMBOL_CRYPTO_COMPARE;
const DEFAULT_INSTRUMENT_NAME = 'BTC/USD';
const DEFAULT_RANGE = RANGE_3MONTHS;

const COMPONENT_NAME = 'TradePageMarketHistorySection';

const StyledSection = styled('section')`
  display: flex !important;
  flex-direction: column !important;
  min-height: 100% !important;

  .${COMPONENT_NAME}-loader {
    color: ${colors.grey};
  }

  .${COMPONENT_NAME}-selection {
    margin: 5px 10px;

    :first-child {
      width: 200px;
    }

    :nth-child(2) {
      width: 200px;
    }

    :nth-child(3) {
      width: 200px;
    }

    :last-child {
      width: 140px;
    }
  }

  .${COMPONENT_NAME}-segment {
    display: flex;
    flex: 1 !important;
    flex-direction: column;
    min-height: 400px;
  }

  .${COMPONENT_NAME}-errorMessage + .${COMPONENT_NAME}-segment {
    border-top-color: ${colors.errorBorderColor};
  }

  .${COMPONENT_NAME}-iconWrapper {
    align-items: center;
    display: flex !important;
    flex: 1 !important;
    height: 371px;
    justify-content: space-around;
  }

  .${COMPONENT_NAME}-icon {
    color: #e8e8e8 !important;
  }

  .${COMPONENT_NAME}-configSegment {
    height: 77px;
  }

  .${COMPONENT_NAME}-configOverflowWrapper {
    height: 100vh;
    overflow-x: auto;
  }

  .${COMPONENT_NAME}-configWrapper {
    height: auto;
    min-width: 750px;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

const currencyShape = shape({ id: string.isRequired }).isRequired;

export default class TradePageMarketHistorySection extends Component {
  static propTypes = {
    fetchInstrumentBySymbol: func,
    fetchTradeHistory: func,
    instrumentBySymbolQuery: queryShape({
      instruments: arrayOfShape({
        baseAsset: currencyShape,
        termAsset: currencyShape,
      })
    }),
    orgQuery: queryShape({
      org: shape({
        relationships: arrayOfShape({
          instumentConfigurations: arrayOfShape({
            instrument: shape({ id: string.isRequired })
          })
        })
      })
    }),
    recentInputHistoryQuery: queryShape({
      inputHistory: shape({
        TradePageMarketHistorySection_chartType: arrayOf(string),
        TradePageMarketHistorySection_feedSymbol: arrayOf(string),
        TradePageMarketHistorySection_instrumentDisplayName: arrayOf(string),
        TradePageMarketHistorySection_range: arrayOf(number),
      })
    }),
    tradeHistoryQuery: queryShape({
      tradeHistory: arrayOfShape({
        baseCurrency: shape({ id: string.isRequired }),
        instrument: shape({ id: string.isRequired }),
        termCurrency: shape({ id: string.isRequired }),
        timeClosed: string.isRequired,
      })
    }),
    updateRecentInputHistory: func,
  }

  static defaultProps = {
    fetchInstrumentBySymbol: noop,
    fetchTradeHistory: noop,
    updateRecentInputHistory: noop,
  }

  state = { loaded: false }

  shouldComponentUpdate(newProps, newState) {
    return changed(this.props, newProps) || changed(this.state, newState);
  }

  render() {
    const { instrumentDisplayName } = this.state;

    const loading = this._isLoading() || !this.state.loaded;
    const instruments = this._getVenueInstruments();

    const recentInstruments = this._inputHistoryFor('instrumentDisplayName', []).reduce((options, n) => {
      if (n) options.push({displayName: n});
      return options;
    }, []);

    const groupedInstrumentOptions = getGroupedInstrumentOptions(instruments, recentInstruments);

    const venues = getVenuesWithInstrument(
      this._getPopulatedVenues(),
      instrumentDisplayName
    );
    const feedOptions = getFeedOptions(venues);

    const groupedFeedOptions = [
      { label: copyText.feedLabelExchangeFeeds, options: feedOptions },
      { label: copyText.feedLabelDefaultFeeds, options: defaultFeedOptions },
    ];

    const instrument = this._getInstrument();

    const mdInstrumentId = get(instrument, 'mdInstrument.id') || get(instrument, 'id', '');

    const tradeHistory = filterTradeHistoryByInstrumentId(
      get(this.props, 'tradeHistoryQuery.data.tradeHistory', []),
      mdInstrumentId,
    );

    return (
      <StyledSection className={COMPONENT_NAME}>
        <Menu attached="top" borderless>
          <Menu.Item content={copyText.sectionTitle} header icon="line graph" />
        </Menu>
        {this._renderConfigSegment({ groupedFeedOptions, groupedInstrumentOptions, loading })}
        {this._renderMessage()}
        {this._renderContent({ instrument, loading, tradeHistory })}
      </StyledSection>
    );
  }

  _renderConfigSegment = ({ groupedFeedOptions, groupedInstrumentOptions, loading }) => !get(this.props, 'recentInputHistoryQuery.loading') && (
    <Segment attached className={cn('configSegment')}>
      <div className={cn('configOverflowWrapper')}>
        <div className={cn('configWrapper')}>
          <Dropdown
            className={cn('selection')}
            name="instrumentDisplayName"
            isDisabled={loading}
            options={groupedInstrumentOptions}
            placeholder={copyText.inputPlaceholder_instrumentDisplayName}
            value={this.state.instrumentOption}
            onChange={this._handleChangeField}
          />
          <Dropdown
            className={cn('selection')}
            name="feedSymbol"
            isDisabled={loading}
            isSearchable={false}
            options={groupedFeedOptions}
            placeholder={copyText.inputPlaceholder_feedSymbol}
            value={this.state.feedOption}
            onChange={this._handleChangeField}
          />
          <Dropdown
            className={cn('selection')}
            name="range"
            isDisabled={loading}
            isSearchable={false}
            options={chartRangeOptions}
            value={this.state.rangeOption}
            onChange={this._handleChangeField}
          />
          <Dropdown
            className={cn('selection')}
            name="chartType"
            isDisabled={loading}
            isSearchable={false}
            options={chartTypeOptions}
            value={this.state.chartTypeOption}
            onChange={this._handleChangeField}
          />
        </div>
      </div>
    </Segment>
  );

  _renderMessage = _ => !isBlank(this.state.errorMessage) && (
    <Message
      attached
      className={cn('errorMessage')}
      closeOnClick
      content={copyText.errorMessage}
      icon="warning sign"
      error
    />
  );

  _renderContent = ({ instrument, loading, tradeHistory }) => this.state.errorMessage ? (
    <Segment className={cn('segment')} attached="bottom">
      <div className={cn('iconWrapper')}>
        <Icon className={cn('icon')} name="warning sign" size="huge" />
      </div>
    </Segment>
  ) : loading ? (
    <Segment className={cn('segment')} attached="bottom">
      <Loader
        className={cn('loader')}
        active
        content={copyText.loadingMessage}
        size="medium"
      />
    </Segment>
  ) : (
    <Segment className={cn('segment')} attached={'bottom'}>
      <MarketHistoryChart
        bars={tradeHistory}
        chartType={this.state.chartType}
        instrument={instrument}
        range={this.state.range}
      />
    </Segment>
  );

  componentDidMount() {
    if (
      isEmpty(get(this.props, 'tradeHistoryQuery.data.tradeHistory')) &&
      !isEmpty(this.state.instrumentDisplayName) &&
      isValidIdentifier(this.state.feedSymbol)
    ) this._fetchTradeHistory();

    this.props.fetchInstrumentBySymbol({ variables: { symbol: DEFAULT_INSTRUMENT_NAME }});

    this._onComponentUpdate();
  }

  componentDidUpdate() {
    this._onComponentUpdate();
  }

  _handleChangeField = (field, event) => {
    const { value } = field;
    const { name } = event;

    if (this.state[name] === value) return;

    let changes = { [name]: value };

    if (changes.feedSymbol) changes = { ...changes, errorMessage: undefined, feedOption: field };
    if (changes.range) changes = { ...changes, rangeOption: field };
    if (changes.chartType) changes = { ...changes, chartTypeOption: field };

    let feedSymbol;

    if (changes.instrumentDisplayName) {
      const feedOptions = this._getFeedOptions(value);

      feedSymbol = feedOptions[0].value;

      changes = {
        ...changes,
        errorMessage: undefined,
        feedOption: feedOptions[0],
        feedSymbol,
        instrumentOption: field,
      };
    }

    this.props.updateRecentInputHistory({
      inputs: [
        { key: `${COMPONENT_NAME}_${name}`, value },
        ...(feedSymbol
          ? [{ key: `${COMPONENT_NAME}_feedSymbol`, value: feedSymbol }]
          : [])
      ]
    });

    this.setState(changes, _ => {
      if (
        changes.range === RANGE_ALL ||
        isValidIdentifier(changes.feedSymbol) ||
        (changes.instrumentDisplayName && feedSymbol)
      ) this._fetchTradeHistory();
    });
  }

  _fetchTradeHistory = _ => {
    const { feedSymbol, range } = this.state

    const instrumentId = (this._getInstrument() || {}).id

    this.props.fetchTradeHistory({
      instrumentId,
      feedSymbol,
      sinceInception: range === RANGE_ALL
    });
  };

  _isLoading = _ => (
    get(this.props, 'instrumentBySymbolQuery.loading') ||
    !has(this.props, 'instrumentBySymbolQuery.data.instrument') ||
    get(this.props, 'orgQuery.loading') ||
    !has(this.props, 'orgQuery.data.org') ||
    get(this.props, 'tradeHistoryQuery.loading') ||
    !has(this.props, 'tradeHistoryQuery.data.tradeHistory') ||
    get(this.props, 'recentInputHistoryQuery.loading') ||
    !has(this.props, 'recentInputHistoryQuery.data.inputHistory')
  );

  _getFeedOptions = instrumentDisplayName => {
    let venues = [];

    if (instrumentDisplayName) {
      venues = this._getPopulatedVenues()
      venues = getVenuesWithInstrument(venues, instrumentDisplayName);
    }

    return [ ...getFeedOptions(venues), ...defaultFeedOptions ];
  }

  _getInstrument = _ => {
    const { instrumentDisplayName, feedSymbol } = this.state

    const venues = this._getPopulatedVenues();
    const exchangeSymbol = getExchangeSymbol(venues, feedSymbol);

    return instrumentDisplayName === DEFAULT_INSTRUMENT_NAME
      ? this._getDefaultInstrument()
      : getInstrumentFromNameVenue(venues, instrumentDisplayName, 'symbol', exchangeSymbol)
  };

  _getDefaultInstrument = _ => get(this.props, 'instrumentBySymbolQuery.data.instrument', {})

  _getPopulatedVenues = _ => {
    const relationships = this.props.orgQuery?.data?.org?.relationships || [];
    const venues = getFilteredVenues({ relationships }, { venueType: VenueType.EXCHANGE })
    return venues;
  }

  _getVenueInstruments = _ => {
    const relationships = this.props.orgQuery?.data?.org?.relationships || [];
    const instruments = getSortedInstrumentsFromVenues({ relationships }, { venueType: VenueType.EXCHANGE })
    return instruments;
  }

  _inputHistoryFor = (str, dflt) => get(this.props, `recentInputHistoryQuery.data.inputHistory.${COMPONENT_NAME}_${str}`, dflt) || dflt;

  _getInputHistory = _ => ({
    chartType: this._inputHistoryFor('chartType[0]', DEFAULT_CHART_TYPE),
    feedSymbol: this._inputHistoryFor('feedSymbol[0]', DEFAULT_FEED_SYMBOL),
    instrumentDisplayName: this._inputHistoryFor('instrumentDisplayName[0]', DEFAULT_INSTRUMENT_NAME),
    range: this._inputHistoryFor('range[0]', DEFAULT_RANGE),
  });

  _onComponentUpdate = _ => {
    if (!this._isLoading()) {

      const inputHistory = get(this.props, 'recentInputHistoryQuery.data.inputHistory');

      if (inputHistory && !this.state.loaded) {
        const { chartType, feedSymbol, instrumentDisplayName, range } = this._getInputHistory();

        const chartTypeOption = chartTypeOptions.find(o => o.value === chartType);
        const feedOption = this._getFeedOptions(instrumentDisplayName).find(o => o.value === feedSymbol);
        const instrumentOption = (
          getInstrumentOptions(this._getVenueInstruments()).find(o => o.value === instrumentDisplayName) ||
          { label: DEFAULT_INSTRUMENT_NAME, value: DEFAULT_INSTRUMENT_NAME }
        );
        const rangeOption = chartRangeOptions.find(o => o.value === range);

        this.setState({
          loaded: true,
          chartType,
          feedSymbol,
          instrumentDisplayName,
          range,
          chartTypeOption,
          feedOption,
          instrumentOption,
          rangeOption,
        }, this._fetchTradeHistory);
      }
    }
  }
}

const getExchangeSymbol = (exchanges, feedSymbol) => defaultFeedOptions.map(f => f.value).includes(feedSymbol)
? (exchanges[0] || {}).symbol
: feedSymbol;
