export const apiKeysCopyText = {
  apiKeyCreated: 'API key "%name%" has been created.',
  apiKeyDisabled: 'API key "%name%" has been deleted.',
  cancelButton: 'Cancel',
  closeButton: 'Close',
  confirmButton: 'Confirm',
  copyButton: 'Copy',
  disablePopupHeader: 'Delete API Key',
  disablePopupNote: 'Are you sure you wish to delete API key "%name%"?',
  errorCreateUnexpected: 'An unexpected error occurred while trying to create your api key.',
  errorDisableUnexpected: 'An unexpected error occurred while trying to disable your api key.',
  keyHeader: 'API Key: ',
  labelHeader: 'Label: ',
  labelInputPlaceholder: 'Label',
  loadingMessage: 'Loading....',
  noApiKeys: 'There are no API keys configured for your organization at this time.',
  popupHeader: 'Add API Key',
  popupNote: 'Note: Please store these credentials in a safe place. For your protection, the API secret will not be shown again. If you misplace your API secret, please delete the API key and generate a new one.',
  popupSuccess: 'API key successfully generated and saved.',
  rightMenuItemLabel: "Add API Key",
  saveButton: 'Generate API Key',
  secretHeader: 'API Secret: ',
  sectionTitle: "Edge API Keys",
  tableHeaderKey: 'API Key',
  tableHeaderLabel: 'API Key Label',
  tableHeaderTimeCreated: 'Created On',
};

export default apiKeysCopyText;
