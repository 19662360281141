import { bool, func, object } from 'prop-types';
import { Grid, Loader, Menu, Segment } from 'semantic-ui-react';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { has } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { hasRoleType } from '@omniex/onx-poms-entity-helpers/lib/utils/UserUtils';
import { isEmpty, isError } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './UserManagementPageRolesSection.copyText';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import UpdateUserRolesForm from '../components/UpdateUserRolesForm';
import UserRolesTable from '../components/UserRolesTable';
import UserRoleType from '@omniex/onx-poms-entity-helpers/lib/enums/UserRoleType';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/loader.css');
require('@omniex/onx-common-ui/lib/semantic/css/grid.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');

const ERROR_MESSAGE_KEY_UPDATE_ROLES_FAILED = 'errorMessageUpdateRolesFailed';

const COMPONENT_NAME = 'UserManagementPageRolesSection';

const StyledSection = styled('section')`
  .${COMPONENT_NAME}-segment {
    padding: 0;
  }

  .${COMPONENT_NAME}-grid {
    min-height: 450px;
  }

  .${COMPONENT_NAME}-loader {
    color: ${colors.grey};
  }

  /* TODO: temporary hack. This should be supported by ui/kit/Table itself */

  /* prettier-ignore */
  tr {
    th, td {
      &:first-child {
        width: 400px !important;
      }
    }
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

const notSystem = user => !hasRoleType(user, UserRoleType.SYSTEM_ADMIN) && !hasRoleType(user, UserRoleType.SYSTEM_OPERATOR)
const notSystemOrAdmin = user => notSystem(user) && !hasRoleType(user, UserRoleType.ADMIN)

export default class UserManagementPageRolesSection extends PureComponent {
  static propTypes = {
    qPortfolios: object,
    updateUser: func,
    updateUserMutationError: object,
    updateUserMutationProcessing: bool,
    updateUserMutationResult: object,
    usersQueryData: object,
    usersQueryError: object,
    usersQueryLoading: bool
  };

  static defaultProps = { updateUser: noop };

  state = {};

  static getDerivedStateFromProps(nextProps, prevState) {
    if (isEmpty(prevState)) return getInitialState(nextProps);

    const stateChanges = {};

    if (
      prevState.updateUserMutationProcessing !==
      nextProps.updateUserMutationProcessing
    ) {
      stateChanges.updateUserMutationProcessing =
        nextProps.updateUserMutationProcessing;
    }

    if (
      prevState.updateUserMutationProcessing === true &&
      stateChanges.updateUserMutationProcessing === false
    ) {
      if (
        isError(nextProps.updateUserMutationError) ||
        !has(nextProps, 'updateUserMutationResult.user')
      ) {
        stateChanges.errorMessageKey = ERROR_MESSAGE_KEY_UPDATE_ROLES_FAILED;
      } else {
        stateChanges.waitingForRoles = false;
      }
    }

    return stateChanges;
  }

  render() {
    const loading = this.props.usersQueryLoading || !has(this.props, 'usersQueryData.users');

    return (
      <StyledSection className={COMPONENT_NAME}>
        <Menu attached="top">
          <Menu.Item content={copyText.sectionTitle} header icon="id badge" />
          <Menu.Menu position="right">
            <Menu.Item
              disabled={loading}
              content={copyText.rightMenuItemLabel}
              icon="add"
              onClick={this._handleToggleUpdateUserRolesForm.bind(this)}
            />
          </Menu.Menu>
        </Menu>
        {this.state.errorMessageKey ? (
          <Message
            className={cn('errorMessage')}
            attached
            closeOnClick
            content={copyText[this.state.errorMessageKey]}
            error
            icon="warning sign"
          />
        ) : null}
        {this.state.successMessageKey ? (
          <Message
            className={cn('successMessage')}
            attached
            closeOnClick
            content={copyText[this.state.successMessageKey]}
            icon="check circle outline"
            success
          />
        ) : null}
        <Segment className={cn('segment')} attached="bottom">
          <Grid className={cn('grid')} celled="internally">
            {loading ? (
              <Grid.Row columns={1}>
                <Grid.Column width={16}>
                  <Loader
                    className={cn('loader')}
                    active
                    content={copyText.loadingMessage}
                    size="medium"
                  />
                </Grid.Column>
              </Grid.Row>
            ) : (
              <Grid.Row columns={this.state.showUpdateUserRolesForm ? 2 : 1}>
                <Grid.Column width={this.state.showUpdateUserRolesForm ? 12 : 16}>
                  <UserRolesTable
                    result={this.props.updateUserMutationResult?.user}
                    show={this.state.showUpdateUserRolesForm}
                    users={(this.props.usersQueryData?.users || []).filter(notSystem)}
                    onUpdate={this._handleUpdateUserStatus}
                  />
                </Grid.Column>
                {this.state.showUpdateUserRolesForm && (
                  <Grid.Column width={4}>
                    <UpdateUserRolesForm
                      portfolios={this.props.qPortfolios?.data?.portfolios || []}
                      processing={this.state.waitingForRoles && this.props.updateUserMutationProcessing}
                      size="small"
                      users={(this.props.usersQueryData?.users || []).filter(notSystemOrAdmin)}
                      onUpdate={this._handleUpdateUserRoles}
                    />
                  </Grid.Column>
                )}
              </Grid.Row>
            )}
          </Grid>
        </Segment>
      </StyledSection>
    );
  }

  _handleUpdateUserRoles = inputs => {
    this.setState({
      errorMessageKey: undefined,
      successMessageKey: undefined,
      waitingForRoles: true
    });

    this.props.updateUser(inputs);
  };

  _handleUpdateUserStatus = inputs => {
    this.setState({
      errorMessageKey: undefined,
      successMessageKey: undefined
    });

    this.props.updateUser(inputs);
  };

  _handleToggleUpdateUserRolesForm() {
    this.setState(currentState => ({
      showUpdateUserRolesForm: !currentState.showUpdateUserRolesForm
    }));
  }
}

function getInitialState(props) {
  return {
    updateUserMutationProcessing: props.updateUserMutationProcessing
  };
}
