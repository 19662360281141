import gql from 'graphql-tag';

export default gql`
  query getTradeHistory(
    $instrumentId: ID
    $feedSymbol: String
    $sinceInception: Boolean
  ) {
    tradeHistory: getTradeHistory(
      instrumentId: $instrumentId
      feedSymbol: $feedSymbol
      sinceInception: $sinceInception
    ) {
      instrument {
        id
      }
      timeClosed
      baseCurrency {
        id
      }
      closingPrice
      highestPrice
      lowestPrice
      openingPrice
      termCurrency {
        id
      }
      volume
    }
  }
`;
