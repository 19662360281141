const marketOverviewTableCopy = {
  tableHeaderChange24H: 'Change (24H)',
  tableHeaderCirculatingSupply: 'Circulating Supply',
  tableHeaderCrypto: 'Name',
  tableHeaderCurrentPrice: 'Current Price',
  tableHeaderMarketCap: 'Market Cap',
  tableHeaderRank: 'Rank',
  tableHeaderVolume24H: 'Volume (24H)'
};

export default marketOverviewTableCopy;
