const reportPageCopy = {
  datepickerStart: 'Start',
  datepickerEnd: 'End',
  filtersLabel: 'Filters',
  menuItemLabelReport: 'Reports',
  noTickets: 'No tickets to display. Try adjusting the search range.',
  reportLoading: 'Generating report...',
  title: 'Reports',
  timeLabel: "Time:"
};

export default reportPageCopy;
