/* eslint no-sequences: 0 */
import { bool, func, object, shape, string } from 'prop-types';
import { queryShape } from '../../utils/PropTypeUtils';
import React from 'react';
import styled from 'react-emotion';

import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import CryptoSection from './CryptoSection';
import FiatSection from './FiatSection';
import AccountType from '@omniex/poms-core/lib/enums/AccountType';
import OrgType from '@omniex/poms-core/lib/enums/OrgType';
import VenueType from '@omniex/poms-core/lib/enums/VenueType';
import { getKeyedFilteredVenues } from '../../selectors/blotterSelectors';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');
require('@omniex/onx-common-ui/lib/semantic/css/popup.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const { CRYPTO, FIAT } = AccountType;

const COMPONENT_NAME = 'CustodyPage';

const StyledDiv = styled('div')`
  height: 100%;
  overflow: hidden;

  .${COMPONENT_NAME}-sectionWrapper {
    padding-top: 20px;

    :last-child {
      margin-bottom: 60px;
    }
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

const CustodyPage = ({
  createCustomCustodian = noop,
  createCustomCustodianMutationError,
  createCustomCustodianMutationProcessing,
  createCustomCustodianMutationResult,
  createInstruction = noop,
  createInstructionMutationError,
  createInstructionMutationProcessing,
  createInstructionMutationResult,
  createManualAccount = noop,
  createManualAccountMutationError,
  createManualAccountMutationProcessing,
  createManualAccountMutationResult,
  currenciesQueryData,
  currenciesQueryError,
  currenciesQueryLoading,
  currentPricesQueryData,
  currentPricesQueryError,
  currentPricesQueryLoading,
  customCustodiansQuery,
  deleteInstruction = noop,
  deleteInstructionMutationError,
  deleteInstructionMutationProcessing,
  deleteInstructionMutationResult,
  orgQueryData,
  orgQueryError,
  orgQueryLoading,
  portfolioQueryData,
  portfolioQueryError,
  portfolioQueryLoading,
  updateAccount = noop,
  updateAccountMutationError,
  updateAccountMutationProcessing,
  updateAccountMutationResult,
  updateCustomCustodian = noop,
  updateCustomCustodianMutationError,
  updateCustomCustodianMutationProcessing,
  updateCustomCustodianMutationResult,
  updateInstruction = noop,
  updateInstructionMutationError,
  updateInstructionMutationProcessing,
  updateInstructionMutationResult,
  user,
}) => {
  const customCustodians = (customCustodiansQuery?.data?.customCustodians || []).reduce((byType, c) => (byType[c.type]?.push(c), byType), {[FIAT]: [], [CRYPTO]: []})
  const exchanges = getKeyedFilteredVenues({ relationships: orgQueryData?.org?.relationships }, { venueType: VenueType.EXCHANGE })
  const accounts = (portfolioQueryData?.portfolio?.accounts || []).filter(a => !exchanges[a?.custodian?.operatingVenue?.id])

  return (
    <StyledDiv className={COMPONENT_NAME}>
        <main>
          {user.canViewCustodyCrypto && (
            <div id="wallet" className={cn('sectionWrapper')}>
              <CryptoSection
                accounts={accounts}
                createCustomCustodian={createCustomCustodian}
                createCustomCustodianMutationError={createCustomCustodianMutationError}
                createCustomCustodianMutationProcessing={createCustomCustodianMutationProcessing}
                createCustomCustodianMutationResult={createCustomCustodianMutationResult}
                createInstruction={createInstruction}
                createInstructionMutationError={createInstructionMutationError}
                createInstructionMutationProcessing={createInstructionMutationProcessing}
                createInstructionMutationResult={createInstructionMutationResult}
                createManualAccount={createManualAccount}
                createManualAccountMutationError={createManualAccountMutationError}
                createManualAccountMutationProcessing={createManualAccountMutationProcessing}
                createManualAccountMutationResult={createManualAccountMutationResult}
                currenciesQueryData={currenciesQueryData}
                currenciesQueryError={currenciesQueryError}
                currenciesQueryLoading={currenciesQueryLoading}
                currentPricesQueryData={currentPricesQueryData}
                currentPricesQueryError={currentPricesQueryError}
                currentPricesQueryLoading={currentPricesQueryLoading}
                customCustodians={customCustodians[CRYPTO]}
                deleteInstruction={deleteInstruction}
                deleteInstructionMutationError={deleteInstructionMutationError}
                deleteInstructionMutationProcessing={deleteInstructionMutationProcessing}
                deleteInstructionMutationResult={deleteInstructionMutationResult}
                isUserBuySide={user?.org?.type === OrgType.CLIENT_BUY_SIDE}
                orgQueryData={orgQueryData}
                orgQueryError={orgQueryError}
                orgQueryLoading={orgQueryLoading}
                portfolioQueryData={portfolioQueryData}
                portfolioQueryError={portfolioQueryError}
                portfolioQueryLoading={portfolioQueryLoading}
                updateAccount={updateAccount}
                updateAccountMutationError={updateAccountMutationError}
                updateAccountMutationProcessing={updateAccountMutationProcessing}
                updateAccountMutationResult={updateAccountMutationResult}
                updateCustomCustodian={updateCustomCustodian}
                updateCustomCustodianMutationError={updateCustomCustodianMutationError}
                updateCustomCustodianMutationProcessing={updateCustomCustodianMutationProcessing}
                updateCustomCustodianMutationResult={updateCustomCustodianMutationResult}
                updateInstruction={updateInstruction}
                updateInstructionMutationError={updateInstructionMutationError}
                updateInstructionMutationProcessing={updateInstructionMutationProcessing}
                updateInstructionMutationResult={updateInstructionMutationResult}
              />
            </div>
          )}
          {user.canViewCustodyFiat && (
            <div id="account" className={cn('sectionWrapper')}>
              <FiatSection
                accounts={accounts}
                createCustomCustodian={createCustomCustodian}
                createCustomCustodianMutationError={createCustomCustodianMutationError}
                createCustomCustodianMutationProcessing={createCustomCustodianMutationProcessing}
                createCustomCustodianMutationResult={createCustomCustodianMutationResult}
                createInstruction={createInstruction}
                createInstructionMutationError={createInstructionMutationError}
                createInstructionMutationProcessing={createInstructionMutationProcessing}
                createInstructionMutationResult={createInstructionMutationResult}
                createManualAccount={createManualAccount}
                createManualAccountMutationError={createManualAccountMutationError}
                createManualAccountMutationProcessing={createManualAccountMutationProcessing}
                createManualAccountMutationResult={createManualAccountMutationResult}
                currenciesQueryData={currenciesQueryData}
                currenciesQueryError={currenciesQueryError}
                currenciesQueryLoading={currenciesQueryLoading}
                customCustodians={customCustodians[FIAT]}
                deleteInstruction={deleteInstruction}
                deleteInstructionMutationError={deleteInstructionMutationError}
                deleteInstructionMutationProcessing={deleteInstructionMutationProcessing}
                deleteInstructionMutationResult={deleteInstructionMutationResult}
                orgQueryData={orgQueryData}
                orgQueryError={orgQueryError}
                orgQueryLoading={orgQueryLoading}
                portfolioQueryData={portfolioQueryData}
                portfolioQueryError={portfolioQueryError}
                portfolioQueryLoading={portfolioQueryLoading}
                updateAccount={updateAccount}
                updateAccountMutationError={updateAccountMutationError}
                updateAccountMutationProcessing={updateAccountMutationProcessing}
                updateAccountMutationResult={updateAccountMutationResult}
                updateCustomCustodian={updateCustomCustodian}
                updateCustomCustodianMutationError={updateCustomCustodianMutationError}
                updateCustomCustodianMutationProcessing={updateCustomCustodianMutationProcessing}
                updateCustomCustodianMutationResult={updateCustomCustodianMutationResult}
                updateInstruction={updateInstruction}
                updateInstructionMutationError={updateInstructionMutationError}
                updateInstructionMutationProcessing={updateInstructionMutationProcessing}
                updateInstructionMutationResult={updateInstructionMutationResult}
                user={user}
              />
            </div>
          )}
        </main>
    </StyledDiv>
  )
}

CustodyPage.propTypes = {
  createCustomCustodian: func,
  createCustomCustodianMutationError: object,
  createCustomCustodianMutationProcessing: bool,
  createCustomCustodianMutationResult: object,
  createInstruction: func,
  createInstructionMutationError: object,
  createInstructionMutationProcessing: bool,
  createInstructionMutationResult: object,
  createManualAccount: func,
  createManualAccountMutationError: object,
  createManualAccountMutationProcessing: bool,
  createManualAccountMutationResult: object,
  currenciesQueryData: object,
  currenciesQueryError: object,
  currenciesQueryLoading: bool,
  currentPricesQueryData: object,
  currentPricesQueryError: object,
  currentPricesQueryLoading: bool,
  customCustodiansQuery: queryShape(object, false).isRequired,
  deleteInstruction: func,
  deleteInstructionMutationError: object,
  deleteInstructionMutationProcessing: bool,
  deleteInstructionMutationResult: object,
  orgQueryData: object,
  orgQueryError: object,
  orgQueryLoading: bool,
  portfolioQueryData: object,
  portfolioQueryError: object,
  portfolioQueryLoading: bool,
  updateAccount: func,
  updateAccountMutationError: object,
  updateAccountMutationProcessing: bool,
  updateAccountMutationResult: object,
  updateCustomCustodian: func,
  updateCustomCustodianMutationError: object,
  updateCustomCustodianMutationProcessing: bool,
  updateCustomCustodianMutationResult: object,
  updateInstruction: func,
  updateInstructionMutationError: object,
  updateInstructionMutationProcessing: bool,
  updateInstructionMutationResult: object,
  user: shape({ org: shape({ type: string.isRequired })}),
}

export default CustodyPage
