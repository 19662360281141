const notificationSettingsCopy = {
  notificationConditions_orderCancelled: 'Order is Cancelled',
  notificationConditions_orderFilled: 'Order is Completed',
  notificationConditions_orderPlaced: 'Order is Placed',
  notificationDeliveryMethods_email: 'Email',
  sectionTitle_notificationDeliveryMethods: 'Notify by',
  sectionTitle_notificationConditions: 'Notify when',
  warningMessageNoSettings: 'You do not have any saved notification settings.',
  notificationConditions_venueExcludedFromAlgo: 'Venue is Temporarily Excluded from Algo',
  notificationConditions_venueRemovedFromAlgo: 'Venue is Removed from Algo',
  notificationConditions_algoPaused: 'Algo is Paused',
  notificationDeliveryMethods_desktop: 'Desktop notification',

};

export default notificationSettingsCopy;
