import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import CreditsPageMainSection from './CreditsPageMainSection';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');

const COMPONENT_NAME = 'CreditsPage';

const StyledDiv = styled('div')`
  height: 100%;
  overflow: hidden;

  .${COMPONENT_NAME}-sectionWrapper {
    padding-top: 20px;
  }

  .${COMPONENT_NAME}-sectionWrapper:last-child {
    margin-bottom: 60px;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class CreditsPage extends PureComponent {
  static propTypes = {};

  static defaultProps = {};

  render() {
    return (
      <StyledDiv className={COMPONENT_NAME}>
          <main>
            <div id="credits" className={cn('sectionWrapper')}>
              <CreditsPageMainSection />
            </div>
          </main>
      </StyledDiv>
    );
  }
}
