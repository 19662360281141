import gql from 'graphql-tag';

export default gql`
  query getIntegrations($portfolioId: ID!) {
    integrations: getIntegrations(portfolioId: $portfolioId) {
      id
      credentialsPreview
      credentialsForViewPreview
      provider {
        id
      }
      settings {
        enableFutures
      }
      status
      type
      timeCreated
      timeLastUpdated
    }
  }
`;
