import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
// import getCurrenciesKeyedById from './getCurrenciesKeyedById';
import getInstrumentsKeyedById from './getInstrumentsKeyedById';
import { createSelector } from 'reselect';

export function populatePaginatedOrders(
  orders,
  instruments,
  // currencies
) {
  if (
    !Array.isArray(orders) ||
    orders.length === 0 ||
    !Array.isArray(instruments) ||
    instruments.length === 0
    // !Array.isArray(currencies) ||
    // currencies.length === 0
  ) {
    return [];
  }

  // const currenciesKeyedById = getCurrenciesKeyedById(currencies);
  const instrumentsKeyedById = getInstrumentsKeyedById(instruments);

  return orders.map(order => {
    // const feeCurrency = currenciesKeyedById[get(order, 'feeCurrency.id')];
    const feeCurrency = get(order, 'feeCurrency');
    const instrument = instrumentsKeyedById[get(order, 'instrument.id')];
    const specifiedCurrency = get(order, 'specifiedCurrency');
    // const specifiedCurrency =
    //   currenciesKeyedById[get(order, 'specifiedCurrency.id')];

    return {
      ...order,
      feeCurrency,
      instrument,
      specifiedCurrency,
    };
  });
}

export default createSelector(
  // (orders, instruments, currencies) => orders,
  // (orders, instruments, currencies) => instruments,
  // (orders, instruments, currencies) => currencies,
  (orders, instruments) => orders,
  (orders, instruments) => instruments,
  populatePaginatedOrders
);
