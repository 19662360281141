import {
  CategoricalFilter,
  CondensedFilterOptions,
  DistinctValueQData,
  FilterMap,
  IPaginationVariables,
  IPaginationVariablesWithOrderId,
  Order,
  SortParams,
  InitialFilters,
  PaginatedOrderQData,
  PaginatedFillQData,
} from '../../../types';
import { ColorProperty } from 'csstype';
import { FetchMoreOptions } from 'apollo-client';
import User from '../../../utils/AuthUtils';
import OrderAlgorithmStrategy from '@omniex/poms-core/lib/enums/OrderAlgorithmStrategy';
import OrderFeature from '@omniex/poms-core/lib/enums/OrderFeature';
import OrderExpiryType from '@omniex/poms-core/lib/enums/OrderExpiryType';
import OrderSide from '@omniex/poms-core/lib/enums/OrderSide';
import OrderStatus from '@omniex/poms-core/lib/enums/OrderStatus';
import OrderType from '@omniex/poms-core/lib/enums/OrderType';
import orderCopyText from '../OrderBlotterTable.copyText';
import { getExecutionTypeOptions } from '../../../utils/FilterUtils';
import { mergeUpdate } from '../../../utils/QueryUtils';

const TERMINAL_STATUSES = [
  OrderStatus.CANCELED,
  OrderStatus.FILLED,
  OrderStatus.REJECTED,
];

const ACTIVE_STATUSES = [
  OrderStatus.ACTIVE,
  OrderStatus.CANCELLATION_REQUESTED,
  OrderStatus.PARTIALLY_FILLED,
  OrderStatus.PENDING_CANCEL,
  OrderStatus.SENT,
];

const CANCELLABLE_STATUSES = [
  OrderStatus.ACTIVE,
  OrderStatus.PARTIALLY_FILLED,
  OrderStatus.SENT,
];

const HAPPY_STATUSES = [OrderStatus.FILLED];

const UNHAPPY_STATUSES = [
  OrderStatus.CANCELLATION_REQUESTED,
  OrderStatus.PENDING_CANCEL,
  OrderStatus.CANCELED,
  OrderStatus.REJECTED,
];

export const isOrderTerminal = <T extends { status: OrderStatus }>(o: T) => TERMINAL_STATUSES.includes(o.status);
export const isOrderActive = <T extends { status: OrderStatus }>(o: T) => ACTIVE_STATUSES.includes(o.status);
export const isOrderCancellable = <T extends { status: OrderStatus }>(o: T) => CANCELLABLE_STATUSES.includes(o.status);
export const isOrderHappy = <T extends { status: OrderStatus }>(o: T) => HAPPY_STATUSES.includes(o.status);
export const isOrderUnhappy = <T extends { status: OrderStatus }>(o: T) => UNHAPPY_STATUSES.includes(o.status);


const EXCLUDED_FEATURES = [OrderFeature.ALGO, OrderFeature.API];

const expiryType = (Object.values(OrderExpiryType)).map((value) => ({ value }));
const executionType = (Object.values(OrderFeature)).reduce((arr: Array<{value: string}>, value) => {
  if (!EXCLUDED_FEATURES.includes(value)) arr.push({ value });
  return arr;
}, []);
const side = (Object.values(OrderSide) as Array<string>).map((value) => ({ value }));
const status = (Object.values(OrderStatus) as Array<string>).map((value) => ({ name: value.replaceAll('_', ' '), value }));
const type = (Object.values(OrderType) as Array<string>).map((value) => {
  if (value === OrderType.PREVIOUSLY_QUOTED) return { name: orderCopyText.statusQuoted, value };
  return { name: value.replaceAll('_', ' '), value };
});
export const ROW_HEIGHT = 30;
export const TABLE_HEADER_HEIGHT = 40;
export const PAGE_SIZE = 18;
export const DRILL_IN_PAGE_SIZE = 10;
export const BODY_HEIGHT = PAGE_SIZE * ROW_HEIGHT;
export const DRILL_IN_BODY_HEIGHT = DRILL_IN_PAGE_SIZE * ROW_HEIGHT;
export const TABLE_HEIGHT = BODY_HEIGHT + TABLE_HEADER_HEIGHT;
export const DRILL_IN_HEIGHT = DRILL_IN_BODY_HEIGHT + TABLE_HEADER_HEIGHT;
export const TOOLBAR_HEIGHT = 48;
export const BLOTTER_HEIGHT = TABLE_HEIGHT + TOOLBAR_HEIGHT;
export const MAX_WIDTH = 2400;

export const LIMIT = 50;
export const DEFAULT_ORDERED_BY = 'timeLastUpdated';
export const DEFAULT_ORDER_DIRECTION = 'DESC';
export const DEFAULT_FILL_ORDERED_BY = 'timeCreated';
export const DEFAULT_SORT_PARAMS: SortParams = {
  orderDirection: DEFAULT_ORDER_DIRECTION,
  orderParameter: DEFAULT_ORDERED_BY,
};

export const DEFAULT_FILL_SORT_PARAMS: SortParams = {
  orderDirection: DEFAULT_ORDER_DIRECTION,
  orderParameter: DEFAULT_FILL_ORDERED_BY,
};

export const DEFAULT_PAGINATION_VARS: IPaginationVariables = {
  params: {
    limit: LIMIT,
    offset: 0,
    ...DEFAULT_SORT_PARAMS
  },
  filters: {},
};

export const DEFAULT_FILL_PAGINATION_VARS: IPaginationVariables = {
  params: {
    limit: LIMIT,
    offset: 0,
    ...DEFAULT_FILL_SORT_PARAMS,
  },
  filters: {},
};

export const ORDER_FILTER_OPTIONS: InitialFilters = {
  displayQuantity: {
    type: 'numberRange',
  },
  expiryType: {
    type: 'category',
    options: [...expiryType],
  },
  limitPrice: {
    type: 'numberRange',
  },
  participationRate: {
    type: 'numberRange',
  },
  side: {
    type: 'exclusiveCategory',
    options: [...side],
  },
  specifiedQuantity: {
    type: 'numberRange',
  },
  stopPrice: {
    type: 'numberRange',
  },
  status: {
    type: 'category',
    options: [...status],
  },
  type: {
    type: 'category',
    options: [...type],
  },
};

export const FILLS_BY_ORDER_ID_FILTER_OPTIONS: InitialFilters = {
  feeCost: {
    type: 'numberRange',
  },
  price: {
    type: 'numberRange',
  },
  quantity: {
    type: 'numberRange',
  },
};

export const FILL_FILTER_OPTIONS: InitialFilters = {
  ...FILLS_BY_ORDER_ID_FILTER_OPTIONS,
  side: {
    type: 'exclusiveCategory',
    options: [...side],
  },
};

export enum BlotterTab {
  Orders,
  Fills
}

/**
 * This function is called if there was an unhandled union case. Purpose is to generate compilation error for missing cases.
 *
 * @export
 * @param {never} x
 * @return {*}  {never}
 */
export function assertNever(x: never): never {
  throw new Error('Unexpected object: ' + x);
}

/**
 * Takes a Duration and formats it to be human-readable with some level of precision.
 *
 * @export
 * @param {moment.Duration} dur
 * @param {number} [precision=2]
 * @return {string}
 */
export function formatDuration (dur: moment.Duration, precision: number = 2) {
  let formattedDuration = '';
  const times: Array<[number, string]> = [
    [dur.years(), 'year'],
    [dur.months(), 'month'],
    [dur.days(), 'day'],
    [dur.hours(), 'hour'],
    [dur.minutes(), 'minute'],
    [dur.seconds(), 'second']
  ];

  let counter = 0;

  for (const [v, label] of times) {
    if (v && counter < precision) {
      if (formattedDuration.length) formattedDuration += ', ';
      formattedDuration += `${v} ${label.concat(v > 1 ? 's' : '')}`;
      counter++;
    }
  }

  return formattedDuration;
}

/**
 * Helper fn to take InitialFilters and parse them into the initial FilterMap state.
 *
 * @export
 * @param {InitialFilters} filters
 * @return {*}
 */
export function parseInitialFilters (filters: InitialFilters) {
  return Object.keys(filters).reduce((filterMap: FilterMap, k) => {
    const filter = filters[k];
    switch (filter.type) {
      case 'category': {
        filterMap[k] = {
          type: filter.type,
          options: [...filter.options].sort((a, b) => a.name && b.name ? a.name.localeCompare(b.name) : a.value.localeCompare(b.value)),
          values: [],
        };
        return filterMap;
      }
      case 'exclusiveCategory':
        filterMap[k] = {
          type: filter.type,
          options: [...filter.options].sort((a, b) => a.name && b.name ? a.name.localeCompare(b.name) : a.value.localeCompare(b.value)),
          value: null,
        }
        return filterMap;
      case 'numberRange':
        filterMap[k] = {
          type: filter.type,
          sign: '=',
          value: null,
        }
        return filterMap;
      default:
        return assertNever(filter);
    }
  }, {});
}

/**
 * Parses a FilterMap into a format that can be passed into the GQL query.
 * Includes custom logic for managing merged columns and ignoring non-active filters.
 *
 * @export
 * @param {FilterMap} filterMap
 * @return {*}  {CondensedFilterOptions}
 */
export function condenseFilterOptions(filterMap: FilterMap) {
  return Object.keys(filterMap).reduce((condensedOptions: CondensedFilterOptions, c) => {
    const filter = filterMap[c];
    switch (filter.type) {
      case 'category':
        if (filter.values.length && filter.values.length !== filter.options.length) {
          if (c === 'executionType') {
            const features: Array<string> = [];
            const algorithmStrategies: Array<string> = [];
            for (const option of filter.values) {
              if (executionType.some(({ value }) => value === option)) features.push(option);
              else {
                if (option === OrderAlgorithmStrategy.VWAP) algorithmStrategies.push(OrderAlgorithmStrategy.SVWAP);
                algorithmStrategies.push(option);
              }
            }
            if (features.length) condensedOptions.feature = features;
            if (algorithmStrategies.length) condensedOptions.algorithmStrategy = algorithmStrategies;
          } else {
            condensedOptions[c] = filter.values;
          }
        }
        return condensedOptions;
      case 'exclusiveCategory':
        if (filter.value !== null) {
          condensedOptions[c] = filter.value
        }
        return condensedOptions;
      case 'numberRange':
        if (filter.value !== null) {
          condensedOptions[c] = {
            value: c === 'participationRate' ? filter.value / 100 : filter.value,
            sign: filter.sign === String.fromCharCode(0x2260) ? '!=' : filter.sign
          };
        }
        return condensedOptions;
      default:
        return assertNever(filter);
    }
  }, {});
}

/**
 * Checks whether any filters are currently being applied.
 *
 * @export
 * @param {FilterMap} filters
 * @return {boolean} isFilterActive
 */
export function checkFiltersActive(filters: FilterMap) {
  for (const filter of Object.values(filters)) {
    if (filter.type === 'category') {
      if (filter.values.length) return true;
    } else if (filter.type === 'exclusiveCategory' || filter.type === 'numberRange') {
      if (filter.value !== null) return true;
    } else {
      return assertNever(filter);
    }
  }
  return false;
};


/**
 * Prepares the variables to pass to a QueryResult's 'refetch' fn.
 *
 * @export
 * @param {number} currentLength
 * @param {SortParams} sortParams
 * @param {CondensedFilterOptions} filters
 * @param {string | undefined} portfolioId
 * @return {*}  {IPaginationVariables}
 */
export function getRefetchPaginationVars(
  currentLength: number,
  sortParams: SortParams,
  filters: CondensedFilterOptions,
  portfolioId?: string,
): IPaginationVariables {
  return {
    params: {
      limit: Math.max(currentLength, LIMIT),
      offset: 0,
      orderDirection: sortParams.orderDirection,
      orderParameter: sortParams.orderParameter,
    },
    filters,
    portfolioId,
  };
};

/**
 * Prepares the variables to pass to a QueryResult's 'refetch' fn where orderId is required.
 *
 * @export
 * @param {string} orderId
 * @param {number} currentLength
 * @param {SortParams} sortParams
 * @param {CondensedFilterOptions} filters
 * @return {*}  {IPaginationVariablesWithOrderId}
 */
export function getRefetchPaginationVarsWithOrderID(
  orderId: string,
  currentLength: number,
  sortParams: SortParams,
  filters: CondensedFilterOptions,
  portfolioId?: string,
): IPaginationVariablesWithOrderId {
  const vars: IPaginationVariablesWithOrderId = getRefetchPaginationVars(currentLength, sortParams, filters, portfolioId) as IPaginationVariablesWithOrderId;
  vars.orderId = orderId;
  return vars;
};

/**
 * Gets the corresponding color for an order status.
 *
 * @export
 * @param {OrderStatus} status
 * @return {string}  {ColorProperty}
 */
export function getStatusColor (status: OrderStatus): ColorProperty {
  if (UNHAPPY_STATUSES.includes(status)) return 'red';
  if (HAPPY_STATUSES.includes(status)) return 'green';
  return 'inherit';
};

/**
 * Parses a distinct values object into Filter objects.
 *
 * @export
 * @param {DistinctValueQData['distinctValues']} values
 * @return {*}  {{
 *   executionType: CategoricalFilter,
 *   instrumentId: CategoricalFilter,
 *   traderId: CategoricalFilter,
 *   venueId: CategoricalFilter
 * }}
 */
export function getFiltersFromDistinctValues(
  values: DistinctValueQData['distinctValues']
): {
  executionType: CategoricalFilter,
  instrumentId: CategoricalFilter,
  traderId: CategoricalFilter,
  venueId: CategoricalFilter
} {
  return {
    executionType: {
      type: 'category',
      options: getExecutionTypeOptions(values).sort((a, b) => a.value.localeCompare(b.value)),
      values: [],
    },
    instrumentId: {
      type: 'category',
      options: values.instruments.map(({ id, identifier, displayName }) => ({
        name: identifier ?? displayName,
        value: id,
      })).sort((a, b) => a.name.localeCompare(b.name)),
      values: [],
    },
    traderId: {
      type: 'category',
      options: values.traders.map(({ id, username }) => ({ name: username, value: id })).sort((a, b) => a.name.localeCompare(b.name)),
      values: [],
    },
    venueId: {
      type: 'category',
      options: values.venues.map(({ name, id }) => ({ name, value: id })).sort((a, b) => a.name.localeCompare(b.name)),
      values: [],
    },
  };
}


/**
 * Checks whether a user has the ability to cancel an order, and the order is in a cancelable state.
 *
 * @export
 * @param {Order} order
 * @param {User} user
 * @return {*}
 */
export function checkCanUserCancelOrder (order: Order, user: User) {
  return (
    user.canCancelOrder && CANCELLABLE_STATUSES.includes(order.status) &&
    order.expiryType !== OrderExpiryType.IOC && order.source !== 'EXTERNAL'
  );
}

/**
 * Gets a venue name from an order with edge cases.
 *
 * @export
 * @param {(Order)} order
 * @return {string} string
 */
export function getVenueName(order: Order) {
  if (order.venue?.name) return order.venue.name;
  if (order.venueNames?.length) return order.venueNames.length > 1 ? 'MULTIPLE' : order.venueNames[0];
  if (order.venueNamesSelected?.length) return order.venueNamesSelected.length > 1 ? 'MULTIPLE' : order.venueNamesSelected[0];
  return '--';
}

/**
 * Removes trailing zeroes from a number string.
 *
 * @export
 * @param {string} numberStr
 * @return {string} numberStr
 */
export const removeTrailingZeroes = (numberStr: string) => {
  if (!numberStr.includes('.')) return numberStr;
  let i = numberStr.length - 1;
  while (i >= 0 && numberStr[i] !== '.') {
    if (numberStr[i] !== '0') {
      i++;
      break;
    }
    i--;
  }
  return numberStr.slice(0, i);
}

/**
 * Removes trailing zeroes from a display quantity, e.g. '10.00000000 BTC'.
 *
 * @export
 * @param {string} dq
 * @returns {string} dq
 */
export const removeTrailingZeroesFromDisplayQuantity = (dq: string) => {
  const indexOfFirstSpace = dq.indexOf(' ');
  const numberStr = removeTrailingZeroes(dq.slice(0, indexOfFirstSpace));
  return numberStr + dq.slice(indexOfFirstSpace);
}

/**
 * Updater fn for order refetches.
 *
 * @export
 * @see https://github.com/apollographql/apollo-client/blob/master/src/core/ObservableQuery.ts#L455
 */
export const orderUpdateQuery: FetchMoreOptions<PaginatedOrderQData, IPaginationVariables>['updateQuery'] = (previousResult, { fetchMoreResult }) =>
  fetchMoreResult ? {
    orderData: {
      count: fetchMoreResult.orderData.count,
      orders: mergeUpdate(previousResult.orderData.orders, fetchMoreResult.orderData.orders),
      __typename: fetchMoreResult.orderData.__typename,
    }
  } : previousResult;

/**
 * Updater fn for order refetches.
 *
 * @export
 * @see https://github.com/apollographql/apollo-client/blob/master/src/core/ObservableQuery.ts#L455
 */
export const fillUpdateQuery: FetchMoreOptions<PaginatedFillQData, IPaginationVariables>['updateQuery'] = (previousResult, { fetchMoreResult }) =>
  fetchMoreResult ? {
    fillData: {
      count: fetchMoreResult.fillData.count,
      fills: mergeUpdate(previousResult.fillData.fills, fetchMoreResult.fillData.fills),
      __typename: fetchMoreResult.fillData.__typename,
    }
  } : previousResult;
