const customCustodianFormCopy = {
  bankAddressPlaceholder: 'Bank Address',
  cancelButtonLabel: 'Cancel',
  confirmationMessageContent: 'Your new custodian will appear in the table to the left.',
  confirmationMessageHeader: 'Do you want to proceed?',
  descriptionPlaceholder: 'Description',
  freeTextWarning: 'Warning: for your security, avoid providing personally identifying information.',
  namePlaceholder: 'Name',
  proceedButtonLabel: 'Proceed',
  saveButtonLabel: 'Save',
  swiftCodePlaceholder: "Swift Code (e.g. AAAABBCC123)",
};

export default customCustodianFormCopy;
