import { format as formatNumber } from '@omniex/onx-common-js/lib/utils/NumberUtils';
import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import { getNumberFormat, getPriceFormat, } from './DisplayUtils';
import { isNil } from '@omniex/onx-common-js/lib/utils/LangUtils';
import OrderExpiryType from '@omniex/onx-poms-entity-helpers/lib/enums/OrderExpiryType';
import OrderSource from '@omniex/onx-poms-entity-helpers/lib/enums/OrderSource';
import OrderType from '@omniex/onx-poms-entity-helpers/lib/enums/OrderType';
import InstrumentType from '@omniex/onx-poms-entity-helpers/lib/enums/InstrumentType';
import AssetType from '@omniex/poms-core/lib/enums/AssetType';

export const getAvgFillPrice = order => {
  const avgFillprice = order.source === OrderSource.INTERNAL
    ? order.calculatedAverageFillPrice
    : order.averageFillPrice;

  return isNil(avgFillprice)
    ? '--'
    : `${formatNumber(avgFillprice, getPriceFormat(avgFillprice))} ${getAssetDisplayText(get(order, 'instrument.termAsset'))}`;
}

export const getFees = order => {
  const feeCost = order.source === OrderSource.INTERNAL
    ? get(order, 'calculatedTotalFees')
    : get(order, 'feeCost');

  return feeCost
    ? `${formatNumber(feeCost, getPriceFormat(feeCost))} ${getAssetDisplayText(get(order, 'feeCurrency'))}`
    : '--';
}

export const getOrderType = order => {
  if (order.type === OrderType.PREVIOUSLY_QUOTED) {
    return 'QUOTED'
  } else if (order.feature === 'ALGO' && order.type === OrderType.STOP_LOSS) {
    return 'STOP MARKET'
  } else {
    return order.type.replaceAll('_', ' ')
  }
}

export const getInstrumentType = ({ instrument } = {}) => {
  if (get(instrument, 'type') === InstrumentType.FUTURE && get(instrument, 'baseAsset.expiry') === null) return 'PSWAP';
  else if (get(instrument, 'type') === InstrumentType.FUTURE) return 'FUTURE'
  else return 'SPOT';
}

export const getOrderTypesByVenueId = venueId => {
  switch (parseInt(venueId, 10)) {
    case 1: //bittrex
      return [[OrderType.LIMIT, OrderExpiryType.GTC]];
    case 2: //poloniex
      return [
        [OrderType.LIMIT, OrderExpiryType.IOC],
        [OrderType.LIMIT, OrderExpiryType.GTC]
      ];
    case 3: //binance
      return [
        [OrderType.LIMIT, OrderExpiryType.IOC],
        [OrderType.LIMIT, OrderExpiryType.GTC],
        [OrderType.MARKET, OrderExpiryType.IOC]
        // [OrderType.STOP_LIMIT, OrderExpiryType.GTC],
        // [OrderType.STOP_LOSS, OrderExpiryType.GTC]
      ];
    case 4: //bitfinex
      return [
        [OrderType.LIMIT, OrderExpiryType.GTC],
        [OrderType.MARKET, OrderExpiryType.IOC],
        [OrderType.STOP_LIMIT, OrderExpiryType.GTC],
        [OrderType.STOP_LOSS, OrderExpiryType.GTC]
      ];
    case 5: //bitstamp
      return [
        [OrderType.LIMIT, OrderExpiryType.IOC],
        [OrderType.LIMIT, OrderExpiryType.GTC],
        [OrderType.MARKET, OrderExpiryType.IOC]
      ];
    case 6: //gdax
      return [
        [OrderType.LIMIT, OrderExpiryType.IOC],
        [OrderType.LIMIT, OrderExpiryType.GTC],
        [OrderType.MARKET, OrderExpiryType.IOC],
        [OrderType.STOP_LIMIT, OrderExpiryType.GTC]
        // [OrderType.STOP_LOSS, OrderExpiryType.GTC]
      ];
    case 7: //gemini
      return [
        [OrderType.LIMIT, OrderExpiryType.IOC],
        [OrderType.LIMIT, OrderExpiryType.GTC]
      ];
    case 8: //itbit
      return [[OrderType.LIMIT, OrderExpiryType.GTC]];
    case 9: //kraken
      return [
        [OrderType.LIMIT, OrderExpiryType.GTC],
        [OrderType.MARKET, OrderExpiryType.IOC],
        // [OrderType.STOP_LIMIT, OrderExpiryType.GTC],
        [OrderType.STOP_LOSS, OrderExpiryType.GTC]
      ];
    case 10: //bitmex
      return [
        [OrderType.LIMIT, OrderExpiryType.GTC],
        [OrderType.STOP_LIMIT, OrderExpiryType.GTC],
        [OrderType.STOP_LOSS, OrderExpiryType.GTC],
        [OrderType.MARKET, OrderExpiryType.IOC]
      ];
    case 11: //lmax
      return [
        [OrderType.LIMIT, OrderExpiryType.IOC],
        [OrderType.LIMIT, OrderExpiryType.GTC],
        [OrderType.MARKET, OrderExpiryType.IOC],
        [OrderType.STOP_LIMIT, OrderExpiryType.GTC],
        [OrderType.STOP_LOSS, OrderExpiryType.GTC]
      ];
    case 32: //bitflyer
      return [
        [OrderType.LIMIT, OrderExpiryType.IOC],
        [OrderType.LIMIT, OrderExpiryType.GTC],
        [OrderType.MARKET, OrderExpiryType.IOC]
      ];
    case 48: //okex
      return [
        [OrderType.LIMIT, OrderExpiryType.GTC],
        [OrderType.LIMIT, OrderExpiryType.IOC],
        // [OrderType.LIMIT, OrderExpiryType.FOK],
        // [OrderType.POST_ONLY, OrderExpiryType.GTC],
        [OrderType.MARKET, OrderExpiryType.IOC],
        // [OrderType.STOP_LOSS, OrderExpiryType.GTC],
      ];
    case 49: //huobi
      return [
        [OrderType.LIMIT, OrderExpiryType.GTC]
        // [OrderType.MARKET, OrderExpiryType.IOC]
      ];
    case 56: //liquid
      return [
        [OrderType.LIMIT, OrderExpiryType.GTC],
        [OrderType.MARKET, OrderExpiryType.IOC]
      ];
    case 1022: //seedcx
      return [
        [OrderType.LIMIT, OrderExpiryType.GTC],
        [OrderType.MARKET, OrderExpiryType.IOC]
      ];
    case 1023: //okcoin
      return [
        [OrderType.LIMIT, OrderExpiryType.IOC],
        [OrderType.LIMIT, OrderExpiryType.GTC]
        // [OrderType.MARKET, OrderExpiryType.IOC]
      ];
    case 1024: //binanceus
      return [
        [OrderType.LIMIT, OrderExpiryType.IOC],
        [OrderType.LIMIT, OrderExpiryType.GTC],
        [OrderType.MARKET, OrderExpiryType.IOC]
        // [OrderType.STOP_LIMIT, OrderExpiryType.GTC],
        // [OrderType.STOP_LOSS, OrderExpiryType.GTC]
      ];
    case 1025: //deribit
      return [
        [OrderType.LIMIT, OrderExpiryType.IOC],
        [OrderType.LIMIT, OrderExpiryType.GTC],
        [OrderType.MARKET, OrderExpiryType.IOC],
      ];
    case 1031: //huobidm
      return [
        [OrderType.LIMIT, OrderExpiryType.IOC],
        // [OrderType.LIMIT, OrderExpiryType.FOK],
        [OrderType.LIMIT, OrderExpiryType.GTC],
      ];
    case 1036: //ftx
      return [
        [OrderType.LIMIT, OrderExpiryType.GTC],
        [OrderType.LIMIT, OrderExpiryType.IOC],
      ];
    case 1038: //bequant
      return [
        [OrderType.LIMIT, OrderExpiryType.FOK],
        [OrderType.LIMIT, OrderExpiryType.GTC],
        [OrderType.LIMIT, OrderExpiryType.IOC],
        [OrderType.MARKET, OrderExpiryType.IOC],
        [OrderType.STOP_LIMIT, OrderExpiryType.GTC],
        [OrderType.STOP_LOSS, OrderExpiryType.GTC],
      ];
      case 1039: //hitbtc
      return [
        [OrderType.LIMIT, OrderExpiryType.FOK],
        [OrderType.LIMIT, OrderExpiryType.GTC],
        [OrderType.LIMIT, OrderExpiryType.IOC],
        [OrderType.MARKET, OrderExpiryType.IOC],
        [OrderType.STOP_LIMIT, OrderExpiryType.GTC],
        [OrderType.STOP_LOSS, OrderExpiryType.GTC],
      ];
      case 1044: //crosstower
      return [
        [OrderType.LIMIT, OrderExpiryType.FOK],
        [OrderType.LIMIT, OrderExpiryType.GTC],
        [OrderType.LIMIT, OrderExpiryType.IOC],
        [OrderType.MARKET, OrderExpiryType.IOC],
        [OrderType.STOP_LIMIT, OrderExpiryType.GTC],
        [OrderType.STOP_LOSS, OrderExpiryType.GTC],
      ];
      case 1045: //bybit
      return [
        [OrderType.LIMIT, OrderExpiryType.FOK],
        [OrderType.LIMIT, OrderExpiryType.GTC],
        [OrderType.LIMIT, OrderExpiryType.IOC],
        [OrderType.MARKET, OrderExpiryType.IOC],
      ];
      case 1058: //kucoin
      return [
        [OrderType.LIMIT, OrderExpiryType.GTC],
        [OrderType.LIMIT, OrderExpiryType.IOC],
        [OrderType.MARKET, OrderExpiryType.IOC],
      ];
    default:
      return [];
  }
}

export const getQuantityFilled = (order = {}) => {
  const { source, specifiedCurrency, calculatedQuantityFilled, quantityFilled } = order;
  return formatQuantity(specifiedCurrency, source === OrderSource.INTERNAL ? calculatedQuantityFilled : quantityFilled);
}

export const getQuantityOrdered = (order = {}) => {
  const { specifiedCurrency, specifiedQuantity } = order;
  return formatQuantity(specifiedCurrency, specifiedQuantity);
}

export const getQuantityOrderedInstrument = (order = {}) => {
  const { specifiedCurrency, specifiedQuantity } = order;
  const instrument = get(order, ['instrument', 'displayName']) || '';
  const currency = get(specifiedCurrency, 'type') || '';

  return `${formatNumber(specifiedQuantity, getNumberFormat(currency))} ${instrument}`;
}

export const formatQuantity = (asset, quantity) => {
  const type = get(asset, 'type', '')
  const symbol = get(asset, 'symbol', '');
  return `${formatNumber(quantity, getNumberFormat(type))} ${type === AssetType.FUTURE ? 'cont' : symbol}`;
}

const orderUtils = {
  formatQuantity,
  getAvgFillPrice,
  getInstrumentType,
  getFees,
  getOrderTypesByVenueId,
  getQuantityFilled,
  getQuantityOrdered,
  getQuantityOrderedInstrument,
};

export default orderUtils;
