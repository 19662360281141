import { createSelector } from 'reselect';

import { Decimal } from 'decimal.js';
import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import {
  groupBy,
  keyBy
} from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import OrderSide from '@omniex/onx-poms-entity-helpers/lib/enums/OrderSide';


export function getSelectedVenueBalances(instrument, accounts, side, venueIds, venueOptions) {
  const balances = groupBy(accounts, a => get(a, 'custodian.operatingVenue.id', null));
  const venueBalances = {};
  const currencyId = side === OrderSide.BUY
    ? get(instrument, 'termAsset.id', '')
    : get(instrument, 'baseAsset.id', '');

  venueIds.forEach(venue => {
    const venueBalancesByCurrency = keyBy(balances[venue], function(account) {
      return account.currency.id;
    });
    const balance = venueBalancesByCurrency[currencyId] || {};
    const freeBalance = balance.balanceAtCustodianAsReportedFree
      || balance.temporaryCalculatedBalance || 0;
    venueBalances[venueOptions[venue].symbol] = new Decimal(freeBalance);
  });
  return venueBalances;
}

export default createSelector(
(instrument, accounts, venueIds, venueOptions) => instrument,
(instrument, accounts, venueIds, venueOptions) => accounts,
(instrument, accounts, venueIds, venueOptions) => venueIds,
(instrument, accounts, venueIds, venueOptions) => venueOptions,
getSelectedVenueBalances
);
