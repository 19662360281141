import gql from 'graphql-tag';

export default gql`
  query getPortfolios {
    portfolios: getPortfolios {
      id
      active
      name
      isDefault
      integrations {
        id
        credentialsPreview
        credentialsForViewPreview
        provider {
          id
        }
        status
        type
        timeCreated
        timeLastUpdated
      }
      users {
        id
        name
        username
      }
    }
  }
`;
