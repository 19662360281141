import gql from 'graphql-tag';

export default gql`
  mutation fillOrder($id: ID!) {
    order: fillOrder(id: $id) {
      id
      fixVenueOrderID
    }
  }
`;
