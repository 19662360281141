import { ApolloProvider, Mutation, Query } from 'react-apollo';
import { object } from 'prop-types';
import React, { Component } from 'react';

import { ERROR_PERMISSION_DENIED } from '@omniex/onx-common-ui/lib/errors';
import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import activateIntegration from '../apollo/graphql/activateIntegration';
import blockRequestedIntegration from '../apollo/graphql/blockRequestedIntegration';
import deleteIntegration from '../apollo/graphql/deleteIntegration';
import ErrorPage from '@omniex/onx-common-ui/lib/pages/ErrorPage';
import getAllClientAccountsList from '../apollo/graphql/getAllClientAccountsList';
import getRequestedIntegrations from '../apollo/graphql/getRequestedIntegrations';
import getVerifiedIntegrations from '../apollo/graphql/getVerifiedIntegrations';
import IntegrationType from '@omniex/poms-core/lib/enums/IntegrationType';
import RequestsPage from '../ui/pages/RequestsPage';

const SYSTEM_ORG_ID = '1';

export default class RequestsPageContainer extends Component {
  static propTypes = {
    apiClient: object,
    user: object,
  };

  static defaultProps = {};

  render() {
    if (
      !this.props.user.canViewRequests ||
      get(this.props, 'user.org.id') !== SYSTEM_ORG_ID
    ) {
      return <ErrorPage type={ERROR_PERMISSION_DENIED} />;
    }

    return (
      <ApolloProvider client={this.props.apiClient}>
        <Query
          query={getAllClientAccountsList}
          variables={{ type: IntegrationType.CUSTODIAN_SILVERGATE }}>
          {({
            data: clientAccountsListQueryData,
            error: clientAccountsListQueryError,
            loading: clientAccountsListQueryLoading
          }) => (
            <Query query={getRequestedIntegrations}>
              {({
                data: requestedIntegrationsQueryData,
                error: requestedIntegrationsQueryError,
                loading: requestedIntegrationsQueryLoading
              }) => (
                <Query query={getVerifiedIntegrations}>
                  {({
                    data: verifiedIntegrationsQueryData,
                    error: verifiedIntegrationsQueryError,
                    loading: verifiedIntegrationsQueryLoading
                  }) => (
                    <Mutation
                      mutation={activateIntegration}
                      refetchQueries={[
                        { query: getRequestedIntegrations },
                        { query: getVerifiedIntegrations }
                      ]}>
                      {(
                        activateIntegration,
                        {
                          data: activateIntegrationMutationResult,
                          error: activateIntegrationMutationError,
                          loading: activateIntegrationMutationProcessing
                        }
                      ) => (
                        <Mutation
                          mutation={blockRequestedIntegration}
                          refetchQueries={[
                            { query: getRequestedIntegrations },
                            { query: getVerifiedIntegrations }
                          ]}>
                          {(
                            blockRequestedIntegration,
                            {
                              data: blockRequestedIntegrationMutationResult,
                              error: blockRequestedIntegrationMutationError,
                              loading: blockRequestedIntegrationMutationProcessing
                            }
                          ) => (
                            <Mutation
                              mutation={deleteIntegration}
                              refetchQueries={[
                                { query: getRequestedIntegrations },
                                { query: getVerifiedIntegrations }
                              ]}>
                              {(
                                deleteIntegration,
                                {
                                  data: deleteIntegrationMutationResult,
                                  error: deleteIntegrationMutationError,
                                  loading: deleteIntegrationMutationProcessing
                                }
                              ) => {

                                if (this.props.user.canReadOnly) {
                                  activateIntegration = noop
                                  blockRequestedIntegration = noop
                                  deleteIntegration = noop
                                }

                                return <RequestsPage
                                  activateIntegration={async parameters => {
                                    try {
                                      await activateIntegration({
                                        variables: parameters
                                      });
                                    } catch (error) {}
                                  }}
                                  activateIntegrationMutationError={
                                    activateIntegrationMutationError
                                  }
                                  activateIntegrationMutationProcessing={
                                    activateIntegrationMutationProcessing
                                  }
                                  activateIntegrationMutationResult={
                                    activateIntegrationMutationResult
                                  }
                                  blockRequestedIntegration={async parameters => {
                                    try {
                                      await blockRequestedIntegration({
                                        variables: parameters
                                      });
                                    } catch (error) {}
                                  }}
                                  blockRequestedIntegrationMutationError={
                                    blockRequestedIntegrationMutationError
                                  }
                                  blockRequestedIntegrationMutationProcessing={
                                    blockRequestedIntegrationMutationProcessing
                                  }
                                  blockRequestedIntegrationMutationResult={
                                    blockRequestedIntegrationMutationResult
                                  }
                                  clientAccountsListQueryData={
                                    clientAccountsListQueryData
                                  }
                                  clientAccountsListQueryError={
                                    clientAccountsListQueryError
                                  }
                                  clientAccountsListQueryLoading={
                                    clientAccountsListQueryLoading
                                  }
                                  deleteIntegration={async parameters => {
                                    try {
                                      await deleteIntegration({
                                        variables: parameters
                                      });
                                    } catch (error) {}
                                  }}
                                  deleteIntegrationMutationError={
                                    deleteIntegrationMutationError
                                  }
                                  deleteIntegrationMutationProcessing={
                                    deleteIntegrationMutationProcessing
                                  }
                                  deleteIntegrationMutationResult={
                                    deleteIntegrationMutationResult
                                  }
                                  requestedIntegrationsQueryData={
                                    requestedIntegrationsQueryData
                                  }
                                  requestedIntegrationsQueryError={
                                    requestedIntegrationsQueryError
                                  }
                                  requestedIntegrationsQueryLoading={
                                    requestedIntegrationsQueryLoading
                                  }
                                  verifiedIntegrationsQueryData={
                                    verifiedIntegrationsQueryData
                                  }
                                  verifiedIntegrationsQueryError={
                                    verifiedIntegrationsQueryError
                                  }
                                  verifiedIntegrationsQueryLoading={
                                    verifiedIntegrationsQueryLoading
                                  }
                                />
                              }}
                            </Mutation>
                          )}
                        </Mutation>
                      )}
                    </Mutation>
                  )}
                </Query>
              )}
            </Query>
          )}
        </Query>
      </ApolloProvider>
    );
  }
}
