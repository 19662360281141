import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { Button } from 'semantic-ui-react';
import React, { Fragment, PureComponent } from 'react';
import styled from 'react-emotion';

import { formatNum } from '../../utils/SettlementUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import copyText from './InitiateSettlementForm.copyText';
import FormSelect from '@omniex/onx-common-ui/lib/semantic/react/FormSelect';
import getAccountOptions from '../../selectors/getAccountOptions';
import getInstructionOptions from '../../selectors/getInstructionOptions';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import Table from '@omniex/onx-common-ui/lib/semantic/react/Table';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/table.css');
require('@omniex/onx-common-ui/lib/semantic/css/button.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');

const COMPONENT_NAME = 'InitiateSettlementForm';

const StyledForm = styled('form')`
  .${COMPONENT_NAME}-currency {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .${COMPONENT_NAME}-buttonWrapper {
    padding-top: 20px;
    text-align: right;
  }

  .${COMPONENT_NAME}-quantity, .${COMPONENT_NAME}-quantityHeader {
    padding-right: 0.25em !important;
    text-align: right !important;
  }

  .${COMPONENT_NAME}-secondConfirmationMessage {
    font-size: 1.3em;
  }

  .${COMPONENT_NAME}-tableWrapper {
    display: inline-block;
  }

  .${COMPONENT_NAME}-dropdown {
    width: 200px !important;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class InitiateSettlementForm extends PureComponent {
  static propTypes = {
    fromAccounts: arrayOf(
      shape({
        id: string.isRequired,
        name: string.isRequired,
        instructions: arrayOf(
          shape({
            id: string.isRequired,
            description: string,
            freeForm: string,
            walletAddress: string
          })
        )
      })
    ),
    netFlows: arrayOf(
      shape({
        currencyId: string.isRequired,
        currencySymbol: string.isRequired,
        currencyType: string.isRequired,
        quantity: number.isRequired
      })
    ),
    netted: bool,
    toAccounts: arrayOf(
      shape({
        id: string.isRequired,
        name: string.isRequired,
        instructions: arrayOf(
          shape({
            id: string.isRequired,
            description: string,
            freeForm: string,
            walletAddress: string
          })
        )
      })
    ),
    showSecondConfirmation: bool,
    onCancel: func,
    onFirstConfirmation: func,
    onSecondConfirmation: func
  };

  static defaultProps = {
    netFlows: [],
    onCancel: noop,
    onFirstConfirmation: noop,
    onSecondConfirmation: noop
  };

  state = {
    offline: false,
  };

  render() {
    const { offline } = this.state;
    const { netFlows, netted, showSecondConfirmation } = this.props;
    return (
      <StyledForm className={COMPONENT_NAME}>
        <div className={cn('tableWrapper')}>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.Cell fontWeight="bold" border="none">
                  {copyText.tableHeaderDirection}
                </Table.Cell>
                <Table.Cell
                  className={cn('quantityHeader')}
                  fontWeight="bold"
                  border="none">
                  {copyText.tableHeaderQuantity}
                </Table.Cell>
                <Table.Cell />
                <Table.Cell fontWeight="bold" border="none">
                  {copyText.tableHeaderWallet}
                </Table.Cell>
                <Table.Cell fontWeight="bold" border="none">
                  {copyText.tableHeaderAddress}
                </Table.Cell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {netFlows.map(this._renderFlowTableRow, this)}
            </Table.Body>
          </Table>
        </div>
        {showSecondConfirmation ? (
          <Fragment>
            <Message
              content={offline ? copyText.confirmationContentOffline : copyText.confirmationContent}
              header={copyText.confirmationHeader}
              warning
            />
            <div className={cn('buttonWrapper')}>
              <Button
                color="orange"
                size="tiny"
                onClick={this._handleClickSecondConfirmationButton}>
                {copyText.initiateSettlementButtonLabel}
              </Button>
              <Button size="tiny" onClick={this._handleClickCancelButton}>
                {copyText.cancelButtonLabel}
              </Button>
            </div>
          </Fragment>
        ) : (
          <div className={cn('buttonWrapper')}>
            <Button
              color="blue"
              disabled={!this._canAccept()}
              size="tiny"
              onClick={this._handleClickFirstConfirmationButton}>
              {netted ? copyText.netSettleButtonLabel : copyText.settleButtonLabel}
            </Button>
            <Button
              name={'offline'}
              color="blue"
              disabled={!netFlows.length}
              size="tiny"
              onClick={this._handleClickFirstConfirmationButton}>
              {copyText.settledOffline}
            </Button>
            <Button size="tiny" onClick={this._handleClickCancelButton}>
              {copyText.cancelButtonLabel}
            </Button>
          </div>
        )}
      </StyledForm>
    );
  }

  _renderFlowTableRow(netFlow) {
    const { currencyId, currencySymbol, currencyType, quantity } = netFlow;

    // NOTE: Treat 0 quantity situation as incoming for now
    const isReceiving = quantity >= 0;

    return (
      <Table.Row key={currencyId}>
        <Table.Cell>
          {isReceiving ? copyText.incomingLabel : copyText.outgoingLabel}
        </Table.Cell>
        <Table.Cell className={cn('quantity')}>
          {formatNum(Math.abs(quantity), currencyType)}
        </Table.Cell>
        <Table.Cell className={cn('currency')}>{currencySymbol}</Table.Cell>
        <Table.Cell>
          <FormSelect
            className={cn('dropdown')}
            name={`${currencyId}-accountId`}
            isDisabled={this.props.showSecondConfirmation}
            options={getAccountOptions(
              isReceiving ? this.props.toAccounts : this.props.fromAccounts,
              currencyId
            )}
            placeholder={
              isReceiving
                ? copyText.inputPlaceholder_selectToWallet
                : copyText.inputPlaceholder_selectFromWallet
            }
            onChange={this._handleChangeField.bind(this, currencyId)}
          />
        </Table.Cell>
        <Table.Cell>
          <FormSelect
            className={cn('dropdown')}
            name={`${currencyId}-instructionId`}
            isDisabled={
              !this.state[`${currencyId}-accountId`] ||
              this.props.showSecondConfirmation
            }
            options={getInstructionOptions(
              isReceiving ? this.props.toAccounts : this.props.fromAccounts,
              this.state[`${currencyId}-accountId`]
            )}
            placeholder={
              isReceiving
                ? copyText.inputPlaceholder_selectToAddress
                : copyText.inputPlaceholder_selectFromAddress
            }
            onChange={this._handleChangeField.bind(this, currencyId)}
          />
        </Table.Cell>
      </Table.Row>
    );
  }

  _handleChangeField = (currencyId, field, event) => {
    let changes = { [event.name]: field.value };

    if (changes[`${currencyId}-accountId`]) {
      changes[`${currencyId}-instructionId`] = '';
    }

    this.setState(changes);
  };

  _handleClickCancelButton = event => {
    event.preventDefault();
    this.setState({ offline: false });
    this.props.onCancel();
  };

  _handleClickFirstConfirmationButton = (event, data) => {
    event.preventDefault();
    if (data.name === 'offline') this.setState({ [data.name]: true });
    this.props.onFirstConfirmation();
  };

  _handleClickSecondConfirmationButton = event => {
    event.preventDefault();
    const allocationInputs = this.state.offline
      ? []
      : this.props.netFlows.map(netFlow =>
        netFlow.quantity >= 0
          ? {
              currencyId: netFlow.currencyId,
              toInstructionId: this.state[`${netFlow.currencyId}-instructionId`],
              quantity: netFlow.quantity
            }
          : {
              currencyId: netFlow.currencyId,
              fromInstructionId: this.state[`${netFlow.currencyId}-instructionId`],
              quantity: Math.abs(netFlow.quantity)
            }
    );

    this.props.onSecondConfirmation(allocationInputs);
  };

  _canAccept() {
    return (
      this.props.netFlows.length ===
      Object.keys(this.state).filter(
        key => key.endsWith('-instructionId') && this.state[key]
      ).length
    );
  }
}
