import gql from 'graphql-tag';

export default gql`
  mutation updateIntegrationCredentials(
    $id: ID!
    $isForView: Boolean
    $fixClientId: String
    $fixCompId: String
    $key: String
    $password: String
    $secret: String
  ) {
    integration: updateIntegrationCredentials(
      id: $id
      isForView: $isForView
      fixClientId: $fixClientId
      fixCompId: $fixCompId
      key: $key
      password: $password
      secret: $secret
    ) {
      id
      type
    }
  }
`;
