import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';

import {
  ERROR_NETWORK_CONNECTIVITY,
  ERROR_UNAUTHENTICATED,
  ERROR_UNEXPECTED
} from '@omniex/onx-common-ui/lib/errors';

export default function normalizeError(error) {
  if (!error) return;
  const { graphQLErrors, networkError } = error;
  if (get(networkError, 'statusCode') === 401) return ERROR_UNAUTHENTICATED;
  if (networkError) return ERROR_NETWORK_CONNECTIVITY;
  if (get(graphQLErrors, '[0].message', '').startsWith('ERROR_')) {
    return graphQLErrors[0].message;
  }
  return ERROR_UNEXPECTED;
}
