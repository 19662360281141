import gql from 'graphql-tag';

export default gql`
  query getSettlements {
    settlements: getSettlements {
      id
      allocations {
        id
        currency {
          id
        }
        fixFromCompID
        fixToCompID
        fromAccount {
          id
          custodian {
            name
          }
          customCustodian {
            bankAddress
            description
            name
            swiftCode
          }
        }
        fromInstruction {
          id
          freeForm
          iban
          walletAddress
        }
        quantity
        status
        toAccount {
          id
          custodian {
            name
          }
          customCustodian {
            bankAddress
            description
            name
            swiftCode
          }
        }
        toInstruction {
          id
          freeForm
          iban
          walletAddress
        }
      }
      fills {
        id
        fixExecID
        order {
          id
          feature
          fixClOrdID
          fixOrderID
          fixQuoteID
          fixVenueOrderID
          instrument {
            id
          }
          side
          type
        }
        price
        quantity
        specifiedCurrency {
          id
        }
        timeCreated
        venue {
          id
          name
          symbol
        }
      }
      fixAllocID
      fixInitiatorCompID
      fixRespondentCompID
      initiatorOrg {
        id
        name
      }
      reasonRejected
      respondentOrg {
        id
        name
      }
      status
      timeCreated
      timeLastUpdated
    }
  }
`;
