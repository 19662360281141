import * as React from 'react';
import { useCallback, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { Grid, Loader, Menu, Segment } from 'semantic-ui-react';
import { makeStyles } from '@material-ui/core';
import VpnKeyIcon from '@material-ui/icons/VpnKey'

// @ts-ignore
import { colors } from '@omniex/onx-common-ui/lib/styles';
// @ts-ignore
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';

import {
  CreateApiKeyData,
  DisableApiKeyData,
  EdgeApiKey,
  EdgeApiKeyData,
  FormApiKey,
  Maybe,
} from '../../../types';
import ApiKeyPopup from './ApiKeyPopup';
import ApiKeyTable from './ApiKeyTable';
import copyText from './copyText';
import createApiKeyMutation from '../../../apollo/graphql/createApiKey';
import CreateApiKeyForm from './CreateApiKeyForm';
import disableApiKeyMutation from '../../../apollo/graphql/disableApiKey';
import DisableApiKeyPopup from './DisableApiKeyPopup';
import getApiKeys from '../../../apollo/graphql/getApiKeys';


const defaultApiKey = { key: '', label: '', secret: '' };
const defaultApiKeyToDisable = { id: '', label: '' };

const insertKeyName = (name: string, message: string) => message.replace('%name%', name);

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    borderTopColor: colors.errorBorderColor,
  },
  grid: {
    minHeight: '100px',
  },
  keyIcon: {
    fontSize: '1.2rem',
    marginRight: theme.spacing(0.5),
  },
  loader: {
    color: colors.grey,
  },
  root: {
    minWidth: theme.spacing(0),
  },
  segment: {
    padding: theme.spacing(0),
  },
  successMessage: {
    borderTopColor: colors.successBorderColor,
  }
}));

const ApiKeySection: React.FC<{}> = () => {
  // Styles
  const classes = useStyles();

  // Refs
  const sectionRef = useRef<HTMLDivElement>(null);

  // State
  const [apiKeyToDisable, setApiKeyToDisable] = useState<{ id: string, label: string }>(defaultApiKeyToDisable);
  const [createdApiKey, setCreatedApiKey] = useState<FormApiKey>(defaultApiKey);
  const [errorMsg, setErrorMsg] = useState<Maybe<string>>(null);
  const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
  const [showDisablePopup, setShowDisablePopup] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [successMsg, setSuccessMsg] = useState<Maybe<string>>(null);

  // Queries
  const [createApiKey, ] = useMutation<CreateApiKeyData, { label: string }>(createApiKeyMutation);
  const [disableApiKey, ] = useMutation<DisableApiKeyData, { id: string }>(disableApiKeyMutation);
  const qApiKeys = useQuery<EdgeApiKeyData, string>(getApiKeys, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  // Derived Data
  const loading = qApiKeys.loading || !qApiKeys.data;

  // Callbacks
  const clearMessages = useCallback(() => {
    setErrorMsg(null);
    setSuccessMsg(null);
  }, []);

  const handleClickDisable = useCallback(({ id, name }: EdgeApiKey) => {
    setApiKeyToDisable({ id, label: name });
    setShowDisablePopup(true);
  }, []);

  const handleCreatePopupState = useCallback((openState: boolean) => () => {
    setShowCreatePopup(openState);
    if (!openState) setCreatedApiKey(defaultApiKey);
  }, []);

  const handleDisablePopupState = useCallback((openState: boolean) => () => {
    setShowDisablePopup(openState);
    if (!openState) setApiKeyToDisable(defaultApiKeyToDisable);
  }, []);

  const handleCreateApiKey = useCallback(async (label: string) => {
    try {
      clearMessages();
      const response = await createApiKey({ variables: { label } });
      const { data } = response;

      if (!(data?.apiKey?.key && data?.apiKey?.secret)) setErrorMsg(copyText.errorCreateUnexpected);
      else {
        setCreatedApiKey({ ...data.apiKey, label });
        setSuccessMsg(insertKeyName(label, copyText.apiKeyCreated));
        setShowForm(false);
        handleCreatePopupState(true)();
        qApiKeys.refetch();
      };
    } catch (e) {
      console.error(e);
      setErrorMsg(copyText.errorCreateUnexpected);
    };
  }, [createApiKey, clearMessages, handleCreatePopupState, qApiKeys]);

  const handleDisableApiKey = useCallback(async () => {
    try {
      clearMessages();
      const response = await disableApiKey({ variables: { id: apiKeyToDisable.id } });
      const { data } = response;

      if (!data?.disableApiKey) setErrorMsg(copyText.errorDisableUnexpected);
      else {
        setSuccessMsg(insertKeyName(apiKeyToDisable.label, copyText.apiKeyDisabled));
        handleDisablePopupState(false)();
        qApiKeys.refetch();
      };
    } catch (e) {
      console.error(e);
      setErrorMsg(copyText.errorCreateUnexpected);
    };
  }, [apiKeyToDisable, disableApiKey, clearMessages, handleDisablePopupState, qApiKeys]);

  return (
    <div ref={sectionRef} className={classes.root}>
      <Menu attached="top">
        <Menu.Item
          content={
            <div>
              <VpnKeyIcon className={classes.keyIcon} />
              {copyText.sectionTitle}
            </div>
          }
          header
        />
        <Menu.Menu position="right">
          <Menu.Item
            content={copyText.rightMenuItemLabel}
            disabled={loading}
            icon="add"
            onClick={() => setShowForm(true)}
          />
        </Menu.Menu>
      </Menu>
      {errorMsg && (
        <Message
          attached
          className={classes.errorMessage}
          closeOnClick
          content={errorMsg}
          error
          icon="warning sign"
        />
      )}
      {successMsg && (
        <Message
          attached
          className={classes.successMessage}
          closeOnClick
          content={successMsg}
          icon="check circle outline"
          success
        />
      )}
      <Segment className={classes.segment} attached="bottom">
        <Grid className={classes.grid} celled="internally">
          {loading ? (
            <Grid.Row columns={1}>
              <Grid.Column width={16}>
                <Loader
                  active
                  className={classes.loader}
                  content={copyText.loadingMessage}
                  size="medium"
                />
              </Grid.Column>
            </Grid.Row>
          ) : (
            <Grid.Row columns={showForm ? 2 : 1}>
              <Grid.Column
                width={showForm ? 12 : 16}>
                <ApiKeyTable
                  apiKeys={qApiKeys.data?.apiKeys}
                  onClickDisable={handleClickDisable}
                />
              </Grid.Column>
              {showForm && (
                <Grid.Column width={4}>
                  <CreateApiKeyForm
                    createApiKey={handleCreateApiKey}
                    onCancel={() => setShowForm(false)}
                  />
                </Grid.Column>
              )}
            </Grid.Row>
          )}
        </Grid>
      </Segment>
      <ApiKeyPopup
        apiKey={createdApiKey}
        closePopup={handleCreatePopupState(false)}
        showPopup={showCreatePopup}
      />
      <DisableApiKeyPopup
        closePopup={handleDisablePopupState(false)}
        disableApiKey={handleDisableApiKey}
        label={apiKeyToDisable.label}
        showPopup={showDisablePopup}
      />
    </div>
  );
};

export default ApiKeySection;
