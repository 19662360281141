import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import clsx from 'clsx';
import { Icon } from 'semantic-ui-react';
import InputAdornment from '@material-ui/core/InputAdornment'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover'
import Select from '@material-ui/core/Select';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip';
import { assertNever } from '../Blotter/shared';
import { Sign, CategoricalFilter, NumericalFilter, ExclusiveCategoryFilter, Filter } from '../../../types';
import { debounce } from 'lodash';
import { HeaderProps, MultiGridColumnData, MultiGridHandleSortParams } from '.';

const containEvent = (fn: (e: React.SyntheticEvent) => void) => (e: React.SyntheticEvent) => {
  e.stopPropagation();
  fn(e);
}

const SIGNS = ['=', String.fromCharCode(0x2260), '>', '>=', '<', '<=']

type BaseHeaderProps = Pick<HeaderProps, 'sortBy' | 'sortDirection' | 'columnIndex' | 'headerClassName'> & {
  classes: {
    controls: string
    filter: string
    flexContainer: string
    adornedStartSign: string
    header: string
    menuItemLabel: string
  }
  columns: Array<MultiGridColumnData<any>>
  onSort?: (params: MultiGridHandleSortParams) => void
  style?: React.CSSProperties
}

type HeaderCellProps = BaseHeaderProps & {
  filter?: Filter
  setFilter?: (f: Filter) => void
}

type CategoryFilterMenuProps = {
  classes: HeaderCellProps['classes']
  filter: CategoricalFilter
  setFilter: (f: CategoricalFilter) => void
}

type ExclusiveCategoryFilterMenuProps = {
  classes: HeaderCellProps['classes']
  filter: ExclusiveCategoryFilter
  setFilter: (f: ExclusiveCategoryFilter) => void
}

type NumberFilterMenuProps = {
  classes: HeaderCellProps['classes']
  filter: NumericalFilter
  setFilter: (f: NumericalFilter) => void
}

const BaseHeader: React.FC<BaseHeaderProps> = ({
  children,
  headerClassName,
  columnIndex,
  onSort,
  sortBy,
  sortDirection,
  columns,
  classes,
  style,
}) => {
  const { clickable, dataKey, label } = columns[columnIndex];
  const isSortActive = dataKey === sortBy;
  const sd = isSortActive ? sortDirection === 'ASC' ? 'asc' : 'desc' : false

  return (
    <TableCell
      component='div'
      className={clsx(classes.header, classes.flexContainer, headerClassName)}
      variant='head'
      align='left'
      sortDirection={sd}
      scope='col'
      style={style}
    >
      {clickable && onSort ? (
        <TableSortLabel
          active={isSortActive}
          direction={sd || 'desc'}
          onClick={(event) => onSort({ event, columnIndex, dataKey })}
          title='Click to Sort'
        >
          {label}
        </TableSortLabel>
      ) : (
        <div>{label}</div>
      )}
      {children}
    </TableCell>
  );
};

type FilterTooltipProps = {
  classes: HeaderCellProps['classes']
  isFilterActive: boolean
  onClick: (e: any) => void
}

const FilterTooltip: React.FC<FilterTooltipProps> = ({
  classes,
  isFilterActive,
  onClick,
}) => (
  <Tooltip title='Click to Filter'>
    <div className={classes.controls} onClick={onClick}>
      <Icon
        className={classes.filter}
        color={isFilterActive ? 'blue' : 'grey'}
        name='filter'
        size='small'
      />
    </div>
  </Tooltip>
);

const CategoryFilterMenu: React.FC<CategoryFilterMenuProps> = ({
  classes,
  filter,
  setFilter,
}) => {
  const [filterAnchor, setFiltersAnchor] = useState<HTMLElement | null>(null);
  const [localValues, setLocalValues] = useState<Array<string>>([...filter.values]);
  const [hasChanged, setHasChanged] = useState(false);
  const debounced = useRef(debounce((newValues: Array<string>) => {
    setFilter({
      type: filter.type,
      options: filter.options,
      values: newValues
    });
  }, 500));

  useEffect(() => {
    if (!hasChanged) {
      if (localValues.join() !== filter.values.join()) setLocalValues([...filter.values]);
      return;
    }
    if (localValues.join() !== filter.values.join()) debounced.current(localValues);
  }, [filter.values, hasChanged, localValues]);

  const setValuesAndMarkChanged = (newValues: Array<string>) => {
    setHasChanged(true);
    setLocalValues(newValues);
  }

  const openFilters = containEvent((e) => {
    setHasChanged(false);
    setLocalValues([...filter.values]);
    setFiltersAnchor(e.currentTarget as HTMLElement)
  });
  const closeFilters = containEvent(() => {
    setHasChanged(false);
    setFiltersAnchor(null)
  });
  const isFilterActive = !!localValues.length;
  const allSelected = localValues.length === filter.options.length;

  const handleClickAll = containEvent(() => {
    if (allSelected) setValuesAndMarkChanged([]);
    else setValuesAndMarkChanged(filter.options.map(({ value }) => value));
  });

  return (
    <>
      <FilterTooltip
        classes={classes}
        isFilterActive={isFilterActive}
        onClick={openFilters}
      />
      <Menu
        anchorEl={filterAnchor}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        elevation={1}
        onClose={closeFilters}
        open={!!filterAnchor}
        PaperProps={{ style: { maxHeight: '500px' } }}
        variant='menu'
        getContentAnchorEl={null}
      >
        {filter.options.length > 2 && (
          <MenuItem onClick={handleClickAll}>
            {allSelected ? <CheckBoxIcon /> : <CheckBoxOutlineIcon />}
            <em className={classes.menuItemLabel}>Select All</em>
          </MenuItem>
        )}
        {filter.options.map(o => {
          const isOptionSelected = localValues.includes(o.value);
          return (
            <MenuItem
              key={o.value}
              onClick={containEvent(() => {
                const newValues = isOptionSelected ? localValues.filter(v => v !== o.value) : [...localValues, o.value];
                setValuesAndMarkChanged(newValues);
              })}
            >
              {isOptionSelected ? <CheckBoxIcon /> : <CheckBoxOutlineIcon />}
              <span className={classes.menuItemLabel}>{o.name ?? o.value}</span>
            </MenuItem>
          )
        })}
      </Menu>
    </>
  );
};

const ExclusiveCategoryFilterMenu: React.FC<ExclusiveCategoryFilterMenuProps> = ({
  classes,
  filter,
  setFilter,
}) => {
  const [localValue, setLocalValue] = useState<string | null>(filter.value);
  const [hasChanged, setHasChanged] = useState(false);
  const [filtersAnchor, setFiltersAnchor] = useState<HTMLElement | null>(null);
  const debounced = useRef(debounce((newValue: string | null) => {
    setFilter({
      type: filter.type,
      options: filter.options,
      value: newValue
    });
  }, 250));

  useEffect(() => {
    if (hasChanged && localValue !== filter.value) {
      debounced.current(localValue);
      setHasChanged(false);
    }
  }, [filter.value, hasChanged, localValue]);

  const openFilters = containEvent((e) => {
    setHasChanged(false);
    setLocalValue(filter.value);
    setFiltersAnchor(e.currentTarget as HTMLElement)
  });

  const closeFilters = containEvent(() => {
    setFiltersAnchor(null);
  });

  const handleClickOption = (newValue: string | null) => {
    setHasChanged(true);
    setLocalValue(newValue);
    setFiltersAnchor(null);
  }

  const isFilterActive = filter.value !== null;

  return (
    <>
      <FilterTooltip
        classes={classes}
        isFilterActive={isFilterActive}
        onClick={openFilters}
      />
      <Menu
        anchorEl={filtersAnchor}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        elevation={1}
        onClose={closeFilters}
        open={!!filtersAnchor}
        PaperProps={{ style: { maxHeight: '500px' } }}
      >
        <MenuItem onClick={containEvent(() => handleClickOption(null))}>
          <em className={classes.menuItemLabel}>{filter.options.length > 2 ? 'Any' : 'Either'}</em>
        </MenuItem>
        {filter.options.map(o => {
          const isOptionSelected = localValue === o.value;
          return (
            <MenuItem
              key={o.value}
              selected={isOptionSelected}
              onClick={containEvent(() => handleClickOption(o.value))}
            >
              <span className={classes.menuItemLabel}>{o.name ?? o.value}</span>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

const getInitValue = (value: number | null): string => {
  return value !== null ? `${value}` : '';
};

const NumberFilterMenu: React.FC<NumberFilterMenuProps> = ({
  filter,
  classes,
  setFilter,
}) => {
  const [filtersAnchor, setFiltersAnchor] = useState<HTMLElement | null>(null);
  const [localSign, setLocalSign] = useState<Sign>(filter.sign);
  const [localValue, setLocalValue] = useState<string>(getInitValue(filter.value));
  const [hasChanged, setHasChanged] = useState(false);
  const debounced = useRef(debounce((newSign: Sign, newValue: string) => {
    const num = Number(newValue);
    setFilter({
      type: filter.type,
      sign: newSign,
      value: newValue === '' || isNaN(num) ? null : num,
    });
  }, 1000));

  useEffect(() => {
    if (!hasChanged) {
      setLocalSign(filter.sign);
      setLocalValue(getInitValue(filter.value));
      return;
    }
    const num = Number(localValue);
    if ((isNaN(num) && filter.value === null) || (localSign === filter.sign && num === filter.value)) return;
    debounced.current(localSign, localValue);
  }, [filter.sign, filter.value, hasChanged, localSign, localValue]);

  const openFilters = containEvent((e) => {
    setLocalSign(filter.sign);
    setLocalValue(getInitValue(filter.value));
    setHasChanged(false);
    setFiltersAnchor(e.currentTarget as HTMLElement)
  });
  const closeFilters = containEvent(() => {
    setHasChanged(false);
    setFiltersAnchor(null)
  });

  const isFilterActive = localValue !== '';

  return (
    <>
      <FilterTooltip
        classes={classes}
        isFilterActive={isFilterActive}
        onClick={openFilters}
      />
      <Popover
        anchorEl={filtersAnchor}
        open={!!filtersAnchor}
        onClose={closeFilters}
      >
        <TextField
          value={localValue}
          placeholder='Value'
          onClick={containEvent(() => {})}
          onChange={(e) => {
            setHasChanged(true);
            setLocalValue(e.target.value)
          }}
          variant='outlined'
          InputProps={{
            classes: {
              adornedStart: classes.adornedStartSign
            },
            margin: 'dense',
            startAdornment: (
              <InputAdornment position='start'>
                <Select
                  margin='dense'
                  value={localSign}
                  onChange={(e) => {
                    setHasChanged(true);
                    setLocalSign(e.target.value as Sign)}
                  }
                  variant='outlined'
                >
                  {SIGNS.map(sign => <MenuItem key={sign} value={sign}>{sign}</MenuItem>)}
                </Select>
              </InputAdornment>
            )
          }}
        />
      </Popover>
    </>
  );
};

export const HeaderCell: React.FC<HeaderCellProps> = ({
  filter,
  headerClassName,
  columnIndex,
  onSort,
  sortBy,
  sortDirection,
  columns,
  classes,
  setFilter,
  style,
}) => {
  if (!filter || !setFilter) return (
    <BaseHeader
      classes={classes}
      columnIndex={columnIndex}
      columns={columns}
      headerClassName={headerClassName}
      onSort={onSort}
      sortBy={sortBy}
      sortDirection={sortDirection}
      style={style}
    />
  );

  return (
    <BaseHeader
      classes={classes}
      columnIndex={columnIndex}
      columns={columns}
      headerClassName={headerClassName}
      onSort={onSort}
      sortBy={sortBy}
      sortDirection={sortDirection}
      style={style}
    >
      {filter.type === 'category' ? (
        <CategoryFilterMenu
          classes={classes}
          filter={filter}
          setFilter={setFilter}
        />
      ) : filter.type === 'exclusiveCategory' ? (
        <ExclusiveCategoryFilterMenu
          classes={classes}
          filter={filter}
          setFilter={setFilter}
        />
      ) : filter.type === 'numberRange' ? (
        <NumberFilterMenu
          classes={classes}
          filter={filter}
          setFilter={setFilter}
        />
      ) : assertNever(filter)}
    </BaseHeader>
  );
};

export default HeaderCell;
