import gql from 'graphql-tag';

export default gql`
  query getAccountsWithBalances(
    $portfolioId: ID
    $venueIds: [ID!]
    $assetIds: [ID!]
  ) {
    accounts: getAccountsWithBalances(
      portfolioId: $portfolioId
      venueIds: $venueIds
      assetIds: $assetIds
    ) {
      id
      balanceAtCustodianAsReportedFree
      balanceAtCustodianAsReportedTotal
      currency {
        id
        name
        symbol
        type
        expiry
      }
      custodian {
        id
        operatingVenue {
          id
        }
        name
      }
      customCustodian {
        id
        name
      }
      error
      hasCustomAsset
      name
      position {
        baseAssetId
        baseAssetName
        baseCurrencySymbol
        baseValue
        entryPrice
        leverage
        liquidationPrice
        liquidationValue
        margin
        marginAssetId
        marginAssetSymbol
        markPrice
        notionalValue
        unrealizedPnL
        unrealizedRoePcnt
        quantity
      }
      temporaryCalculatedBalance
      type
    }
  }
`;
