const confirmAllocationsTableCopyText = {
  accountNumberLabel: 'Account Number:',
  buttonLabelAcceptFunds: 'Funds Sent',
  buttonLabelCompleted: 'Completed',
  buttonLabelFundsReceived: 'Funds Received',
  ibanLabel: 'IBAN:',
  incomingLabel: 'Incoming',
  noDataLabel: '-- --',
  outgoingLabel: 'Outgoing',
  permissionDenied: 'Action required by a user with Settlement Officer role',
  settledOffline: 'Settled Offline',
  swiftLabel: 'SWIFT:',
  tableHeaderAddress: 'Address/Account',
  tableHeaderCounterpartyDetails: 'Counterparty Details',
  tableHeaderCurrency: 'Currency',
  tableHeaderDirection: 'Direction',
  tableHeaderQuantity: 'Quantity',
  tableHeaderStatus: 'Confirmation',
  tableHeaderTimestamp: 'Time Executed',
  tableHeaderWallet: 'Wallet/Institution',
};

export default confirmAllocationsTableCopyText;
