import * as React from 'react';
import { useState } from 'react';
import { makeStyles } from '@omniex/poms-ui';
import { List, Collapse } from '@material-ui/core';
import { OTCListItem, ListHeader } from './OTCListItem';
import { Dictionary, Order } from '../../../types';
import { OTCOrder, HydratedOTCTemplateData, HydratedOTCTileData } from '../../../types/otc';
import OTCCopyText from './OTC.copy';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 292,
  },
  list: {
    width: '100%',
    padding: 0,
  }
}));

export type OTCListProps = {
  copyTile: (index: number) => void;
  deleteTile: (index: number) => void;
  loadTile: (index: number) => void;
  resetTile: (index: number) => void;
  createTemplate: (index: number) => void;
  deleteTemplate: (index: number) => void;
  keyedOrders: Dictionary<Order | OTCOrder>;
  templates: HydratedOTCTemplateData[];
  tiles: HydratedOTCTileData[];
}

export function OTCList({
  copyTile,
  deleteTile,
  loadTile,
  resetTile,
  createTemplate,
  deleteTemplate,
  keyedOrders,
  templates,
  tiles,
}: OTCListProps) {
  const cls = useStyles();
  const [isSavedOpen, setIsSavedOpen] = useState(true);
  const [isActiveOpen, setIsActiveOpen] = useState(true);

  return (
    <div className={cls.root}>
      <List className={cls.list}>
        <ListHeader isOpen={isSavedOpen} label={OTCCopyText.templateLabel} onClick={() => setIsSavedOpen(p => !p)} />
        <Collapse in={isSavedOpen} timeout="auto" unmountOnExit>
          <List disablePadding>
            {templates.map((template, i) => (
              <OTCListItem
                key={template.id}
                onAdd={loadTile}
                onDelete={deleteTemplate}
                index={i}
                hydratedData={template}
                variant="template"
              />
            ))}
          </List>
        </Collapse>
        <ListHeader isOpen={false || isActiveOpen} label={OTCCopyText.tileLabel} onClick={() => setIsActiveOpen(p => !p)} />
        <Collapse in={false || isActiveOpen} timeout="auto" unmountOnExit>
          <List disablePadding>
            {tiles.map((tile, i) => (
              <OTCListItem
                key={tile.id}
                onAdd={copyTile}
                onDelete={deleteTile}
                onDismiss={resetTile}
                onSave={createTemplate}
                index={i}
                hydratedData={tile}
                order={keyedOrders[tile.id]}
                variant="tile"
              />
            ))}
          </List>
        </Collapse>
      </List>
    </div>
  )
}

export default OTCList;
