import gql from 'graphql-tag';

export default gql`
  mutation updateIntegrationSettings(
    $id: ID!
    $settings: IntegrationSettingsInput!
  ) {
    integrationSettings: updateIntegrationSettings(
      id: $id
      settings: $settings
    ) {
      id
    }
  }
`;
