import { shape, string } from 'prop-types';
import { Grid, Label, Loader, Segment } from 'semantic-ui-react';
import { arrayOfShape, queryShape } from '../../utils/PropTypeUtils';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { get, has } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { sortBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './AdminPageVenueRelationshipsSection.copyText';
import ExchangeVenueRelationshipsTable from '../components/ExchangeVenueRelationshipsTable';
import Menu from '@omniex/onx-common-ui/lib/semantic/react/Menu';
import OrgVenueRelationshipType from '@omniex/onx-poms-entity-helpers/lib/enums/OrgVenueRelationshipType';
import OtcVenueRelationshipsTable from '../components/OtcVenueRelationshipsTable';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/label.css');
require('@omniex/onx-common-ui/lib/semantic/css/loader.css');
require('@omniex/onx-common-ui/lib/semantic/css/segment.css');
require('@omniex/onx-common-ui/lib/semantic/css/grid.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');

const COMPONENT_NAME = 'AdminPageVenueRelationshipsSection';

const StyledSection = styled('section')`
  .${COMPONENT_NAME}-segment {
    padding: 0;
  }

  .${COMPONENT_NAME}-item {
    justify-content: center;
    width: 180px;
  }

  .${COMPONENT_NAME}-grid {
    min-height: 100px;
  }

  .${COMPONENT_NAME}-loader {
    color: ${colors.grey};
  }

  .${COMPONENT_NAME}-venueLabel {
    font-size: 10px;
    padding: 4px;
    text-align: center;
  }

  /* TODO: temporary hack. This should be supported by ui/kit/Table itself */

  /* prettier-ignore */
  tr {
    td, th {
      &:first-child {
        width: 250px !important;
      }
    }
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class AdminPageVenueRelationshipsSection extends PureComponent {
  static propTypes = {
    orgQuery: queryShape({
      org: shape({
        relationships: arrayOfShape({
          id: string.isRequired,
          venue: shape({
            id: string.isRequired,
            type: string.isRequired
          }).isRequired
        }).isRequired
      })
    }),
  };

  state = {
    relationshipId: null,
    selectedTab: OrgVenueRelationshipType.OTC
  };

  render() {
    const relationships = sortBy(
      get(this.props, 'orgQuery.data.org.relationships', []),
      [relationship => get(relationship, 'venue.name', '').toLowerCase(), 'status']
    );

    const [exchangeRelationships, otcRelationships] = relationships.reduce(
      ([exchangeRelationships, otcRelationships], relationship) =>
        relationship.venue.type === OrgVenueRelationshipType.EXCHANGE
          ? [[...exchangeRelationships, relationship], otcRelationships]
          : [exchangeRelationships, [...otcRelationships, relationship]],
      [[], []]
    );

    const selectedRelationships =
      this.state.selectedTab === OrgVenueRelationshipType.EXCHANGE
        ? exchangeRelationships
        : otcRelationships;

    const loading = get(this.props, 'orgQuery.loading') || !has(this.props, 'orgQuery.data.org');

    return (
      <StyledSection className={COMPONENT_NAME}>
        <Menu attached="top">
          <Menu.Item header icon="exchange" content={copyText.sectionTitle} />
          <Menu.Tab
            className={cn('item')}
            active={this.state.selectedTab === OrgVenueRelationshipType.OTC}
            onClick={this._handleClickTab.bind(this, OrgVenueRelationshipType.OTC)}>
            {copyText.menuItemLabelOtc}
            <Label className={cn('venueLabel')}>
              {otcRelationships.length}
            </Label>
          </Menu.Tab>
          <Menu.Tab
            className={cn('item')}
            active={this.state.selectedTab === OrgVenueRelationshipType.EXCHANGE}
            onClick={this._handleClickTab.bind(this, OrgVenueRelationshipType.EXCHANGE)}>
            {copyText.menuItemLabelExchanges}
            <Label className={cn('venueLabel')}>
              {exchangeRelationships.length}
            </Label>
          </Menu.Tab>
        </Menu>
        <Segment attached="bottom" className={cn('segment')}>
          <Grid className={cn('grid')} celled="internally">
            {loading ? (
              <Grid.Row columns={1}>
                <Grid.Column width={16}>
                  <Loader
                    className={cn('loader')}
                    active
                    content={copyText.loadingMessage}
                    size="medium"
                  />
                </Grid.Column>
              </Grid.Row>
            ) : (
              <Grid.Row columns={this.state.relationshipId ? 2 : 1}>
                <Grid.Column width={this.state.relationshipId ? 12 : 16}>
                  {this._renderTabContent(selectedRelationships)}
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Segment>
      </StyledSection>
    );
  }

  _renderTabContent(selectedRelationships) {
    switch (this.state.selectedTab) {
      case OrgVenueRelationshipType.OTC:
        return (<OtcVenueRelationshipsTable relationships={selectedRelationships} />);
      case OrgVenueRelationshipType.EXCHANGE:
        return (
          <ExchangeVenueRelationshipsTable
            relationships={selectedRelationships}
            selectedRelationshipId={this.state.relationshipId}
            onSelect={this._handleSelectedExchangeVenueRelationship}
          />
        );
      default:
        return null;
    }
  }

  _handleCancelSaveApiKeyForm = () => {
    this.setState({ relationshipId: null });
  };

  _handleClickTab = type => {
    if (type === this.state.selectedTab) return;
    this.setState({
      relationshipId: null,
      selectedTab: type
    });
  };

  _handleSelectedExchangeVenueRelationship = relationshipId => {
    this.state.relationshipId
      ? this.setState({ relationshipId: null })
      : this.setState({ relationshipId });
  };
}
