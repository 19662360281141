import * as React from 'react';
import { useEnhancedTrigger } from '../../../hooks';
import { Dictionary, QuoteRequest, OTCOrder, PlaceOrderForQuoteMVars, PlaceOrderForQuoteMData, PaginatedOrderQData, IPaginationVariables, Order, HydratedOTCTileData, OTCOrderType } from '../../../../types';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import placeOrderForQuoteMutation from '../../../../apollo/graphql/buy-side/placeOrderForQuote';
import { useMutation, useQuery } from '@apollo/react-hooks';
import getPaginatedOrders from '../../../../apollo/graphql/buy-side/getPaginatedOrders';
import { DEFAULT_PAGINATION_VARS, isOrderActive } from '../../Blotter/shared';
import { filterDict, OTC_ORDER_TYPES } from '..';
import QuoteRequestSide from '@omniex/poms-core/lib/enums/QuoteRequestSide';

const getPlaceOrderParams = (
  tile: HydratedOTCTileData,
  keyedQRs: Dictionary<QuoteRequest>,
  portfolioId?: string,
) => {
  if (!portfolioId) {
    console.warn('Attempting to place an OTC order w/o a portfolio ID.');
    return;
  }

  const { instrumentId, orderType, quantity, side, venueIds, limitPrice, stopPrice } = tile;

  if (!instrumentId || !orderType || !quantity || !side || !venueIds) {
    console.warn('Attempting to place an OTC order w/o required fields filled.');
    return;
  }

  const qrs = tile.keys.reduce((qrs: QuoteRequest[], key) => {
    const qr = keyedQRs[key];
    if (qr?.status === 'ACTIVE') qrs.push(qr);
    return qrs;
  }, []);

  if (!qrs || !qrs.length) {
    console.warn('Attempting to place an OTC order w/o any active quote requests.');
    return;
  }

  const orderTypeInfo = OTC_ORDER_TYPES.find(o => o.name === orderType);

  if (!orderTypeInfo) {
    console.warn(`Attempting to place an OTC order with an invalid order type: ${orderType}`);
    return;
  }

  const params: PlaceOrderForQuoteMVars = {
    fixQuoteReqIDs: [],
    side,
    orderType: orderTypeInfo.orderType,
    expiryType: orderTypeInfo.expiryType,
    portfolioId,
  }

  if (orderType === OTCOrderType.AS_QUOTED) {
    // get the best quote
    const bestQR = qrs.reduce((best, qr) => (
        (side === QuoteRequestSide.BUY && qr.quote && (!best.quote || qr.quote.offerPrice < best.quote.offerPrice)) ||
        (side === QuoteRequestSide.SELL && qr.quote && (!best.quote || qr.quote.bidPrice > best.quote.bidPrice))
      ) ? qr : best
    );

    if (!bestQR) {
      console.warn('Attempting to place an OTC As Quoted order w/o a quote requests');
      return;
    }
    const { quote } = bestQR;
    if (!quote) {
      console.warn('Attempting to place an OTC As Quoted order w/o a quote');
      return;
    }

    params.fixQuoteID = quote.fixQuoteID;
    params.fixQuoteReqIDs = [bestQR.key];
    params.limitPrice = quote[side === QuoteRequestSide.BUY ? 'offerPrice' : 'bidPrice'];
  } else {
     // rest use every ReqID
    params.fixQuoteReqIDs = qrs.map(({ key }) => key);

    if (orderTypeInfo.hasLimitPrice) params.limitPrice = Number(limitPrice);
    if (orderTypeInfo.hasStopPrice) params.stopPrice = Number(stopPrice);
  }

  return params;
}

export function useOTCOrders(
  keyedQRs: Dictionary<QuoteRequest>,
  refreshDataNonce?: string,
  portfolioId?: string,
  apiClient?: ApolloClient<NormalizedCacheObject>,
  fixClient?: ApolloClient<NormalizedCacheObject>,
) {
  const [errorSet, setErrorSet] = React.useState(new Set<string>());
  const [loadingSet, setLoadingSet] = React.useState(new Set<string>());
  const [keyedOTCOrders, setKeyedOTCOrders] = React.useState<Dictionary<OTCOrder>>({});

  const otcOrders = Object.values(keyedOTCOrders);

  const skip = !otcOrders.length;

  const variables = React.useMemo(() => ({
    ...DEFAULT_PAGINATION_VARS,
    portfolioId,
    filters: {
      fixClOrdID: otcOrders.map(o => o.fixClOrdID),
    },
  }), [otcOrders, portfolioId]);

  const qOrders = useQuery<PaginatedOrderQData, IPaginationVariables>(getPaginatedOrders, { client: apiClient, skip, variables });

  const [orders, someOrdersActive] = React.useMemo(
    () => Object.keys(keyedOTCOrders).reduce<[Dictionary<Order | OTCOrder>, boolean]>(([orders, active], tileId) => {
      const paginatedOrders = qOrders.data?.orderData.orders ?? []
      const order = paginatedOrders.find(o => o.id === keyedOTCOrders[tileId].id) ?? keyedOTCOrders[tileId];
      if (order) {
        orders[tileId] = order;
        active = active || isOrderActive(order);
      }
      return [orders, active];
  }, [{}, false]), [keyedOTCOrders, qOrders.data])

  const [placeOrderForQuote] = useMutation<PlaceOrderForQuoteMData, PlaceOrderForQuoteMVars>(placeOrderForQuoteMutation, { client: fixClient });

  const removeOrder = React.useCallback((id: string) => {
    setKeyedOTCOrders(prev => filterDict(prev, (_, k) => k !== id));
    setErrorSet(p => {
      const next = new Set(p);
      next.delete(id);
      return next;
    });
  }, []);

  const placeOrder = React.useCallback(async (
    tileId: string,
    tile: HydratedOTCTileData,
  ) => {
    const variables = getPlaceOrderParams(tile, keyedQRs, portfolioId);
    if (!variables) return;

    try {
      setLoadingSet(p => (new Set(p)).add(tileId));
      const { data, errors } = await placeOrderForQuote({ variables });
      if (data) setKeyedOTCOrders(prev => ({ ...prev, [tileId]: data.order }));
      if (errors) console.warn(errors);
    } catch (e) {
      console.error('Failed to place order for quote.');
      console.error(e);
      setErrorSet(p => (new Set(p)).add(tileId));
    } finally {
      setLoadingSet(p => {
        const next = new Set(p);
        next.delete(tileId);
        return next;
      })
    }
  }, [keyedQRs, placeOrderForQuote, portfolioId]);

  useEnhancedTrigger(refreshDataNonce, qOrders.refetch, someOrdersActive, 1000);

  return [orders, placeOrder, removeOrder, loadingSet, errorSet] as const;
}
