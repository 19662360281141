import gql from 'graphql-tag';

export default gql`
  query getOrgRelationships($portfolioId: ID) {
    org: getOrg(portfolioId: $portfolioId) {
      id
      enableCustomOrderId
      enablePTWAPSE
      externalMarketDataSource
      fixSenderCompID
      homeCurrency {
        id
        symbol
      }
      name
      portfolios {
        id
        name
      }
      relationships {
        id
        org {
          id
          name
        }
        status
        venue {
          id
          name
          integrationType
          instrumentConfigurations {
            id
            instrument {
              id
              identifier
              contractPriceMultiplier
              contractSize
              contractSizeAsset {
                id
                symbol
                name
                type
              }
              displayName
              symbol
              type
              mdInstrument {
                id
              }
              termAsset {
                id
                symbol
                name
                type
              }
              baseAsset {
                id
                symbol
                name
                type
                expiry
              }
              marginAsset {
                id
                symbol
                expiry
                name
                type
              }
            }
            minSize
            maxSize
            minTermSize
            minSizeIncrement
            minPrice
            maxPrice
            minTick
          }
          shortName
          supportsEsp
          supportsRfq
          symbol
          type
        }
      }
      takerApiBaseUrl
      type
      tz
    }
  }
`;
