import moment from '../ext/moment';

export const TZ_BRUSSELS = 'Europe/Brussels';
export const TZ_CALCUTTA = 'Asia/Calcutta';
export const TZ_CHICAGO = 'America/Chicago';
export const TZ_HONG_KONG = 'Asia/Hong_Kong';
export const TZ_JOHANNESBURG = 'Africa/Johannesburg';
export const TZ_LONDON = 'Europe/London';
export const TZ_LOS_ANGELES = 'America/Los_Angeles';
export const TZ_NEW_YORK = 'America/New_York';
export const TZ_SAO_PAULO = 'America/Sao_Paulo';
export const TZ_SEOUL = 'Asia/Seoul';
export const TZ_SHANGHAI = 'Asia/Shanghai';
export const TZ_SYDNEY = 'Australia/Sydney';
export const TZ_TAIPEI = 'Asia/Taipei';
export const TZ_TOKYO = 'Asia/Tokyo';
export const TZ_UTC = 'UTC';

const timeZones = [
  { name: TZ_UTC, code: moment.tz(TZ_UTC).zoneAbbr() },
  { name: TZ_CHICAGO, code: moment.tz(TZ_CHICAGO).zoneAbbr() },
  { name: TZ_LOS_ANGELES, code: moment.tz(TZ_LOS_ANGELES).zoneAbbr() },
  { name: TZ_NEW_YORK, code: moment.tz(TZ_NEW_YORK).zoneAbbr() },
  { name: TZ_JOHANNESBURG, code: moment.tz(TZ_JOHANNESBURG).zoneAbbr() },
  { name: TZ_SAO_PAULO, code: 'BRT' },
  { name: TZ_CALCUTTA, code: moment.tz(TZ_CALCUTTA).zoneAbbr() },
  { name: TZ_HONG_KONG, code: moment.tz(TZ_HONG_KONG).zoneAbbr() },
  { name: TZ_SEOUL, code: moment.tz(TZ_SEOUL).zoneAbbr() },
  { name: TZ_SHANGHAI, code: moment.tz(TZ_SHANGHAI).zoneAbbr() },
  { name: TZ_TAIPEI, code: moment.tz(TZ_TAIPEI).zoneAbbr() },
  { name: TZ_TOKYO, code: moment.tz(TZ_TOKYO).zoneAbbr() },
  { name: TZ_SYDNEY, code: moment.tz(TZ_SYDNEY).zoneAbbr() },
  { name: TZ_BRUSSELS, code: moment.tz(TZ_BRUSSELS).zoneAbbr() },
  { name: TZ_LONDON, code: moment.tz(TZ_LONDON).zoneAbbr() }
];

export default timeZones;
