import { arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import { CSVLink } from 'react-csv';
import { Form, Loader, Input, Menu, Segment } from 'semantic-ui-react';
import React, { Fragment, PureComponent } from 'react';
import styled from 'react-emotion';

import { format as formatDate } from '@omniex/onx-common-js/lib/utils/DateTimeUtils';
import {
  generateCurrencyKey,
  getCustodianName,
  getTotalAccountBalance
} from '../../utils/AccountUtils';
import { get, has } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import {
  shouldDisplayPosition,
  getLiquidationValue,
  getMargin,
  getPositions,
  getUnrealizedPnL,
  getValue
} from '../../utils/PositionUtils';
import {
  keyBy,
  sortBy
} from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import { isEmpty, isError, isNil } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { unique } from '@omniex/onx-common-js/lib/utils/ArrayUtils';
import AccountActivityTable from '../components/AccountActivityTable';
import AccountActivityTransactionType from '@omniex/onx-poms-entity-helpers/lib/enums/AccountActivityTransactionType';
import AssetType from '@omniex/poms-core/lib/enums/AssetType';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './PortfolioPagePositionsSection.copyText';
import CsvDataType from '@omniex/onx-poms-entity-helpers/lib/enums/CsvDataType';
import generateCurrentPriceLookupTable from '../../selectors/generateCurrentPriceLookupTable';
import ManualEntryForm from '../components/ManualEntryForm';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import PortfolioPositionsCustodianTable from '../components/PortfolioPositionsCustodianTable';
import PortfolioPositionsTable from '../components/PortfolioPositionsTable';
import { getFilteredVenues } from '../../selectors/blotterSelectors';

const ERROR_MESSAGE_KEY_CREATE_ACCOUNT_TRANSACTION_FAILED =
  'errorMessageCreateAccountTransactionFailed';
const ERROR_MESSAGE_KEY_DELETE_ACCOUNT_TRANSACTION_FAILED =
  'errorMessageDeleteAccountTransactionFailed';
const ERROR_MESSAGE_KEY_PAGINATED = 'errorMessagePaginated';
const ERROR_MESSAGE_KEY_CREATE_POSITION = 'errorMessageCreatePositionFailed';
const ERROR_MESSAGE_KEY_DUPLICATE_SYMBOL_OMNIEX =
  'errorMessageDuplicateSymbolOmniex';
const ERROR_MESSAGE_KEY_DUPLICATE_SYMBOL_ORG = 'errorMessageDuplicateSymbolOrg';
const ERROR_MESSAGE_KEY_UPDATE_POSITION = 'errorMessageUpdatePositionFailed';
const SUCCESS_MESSAGE_KEY_ACCOUNT_TRANSACTION_CREATED =
  'successMessageCreateAccountTransaction';
const SUCCESS_MESSAGE_KEY_ACCOUNT_TRANSACTION_DELETED =
  'successMessageDeleteAccountTransaction';
const SUCCESS_MESSAGE_KEY_POSITION_CREATED = 'successMessagePositionCreated';
const SUCCESS_MESSAGE_KEY_POSITION_UPDATED = 'successMassagePositionUpdated';

const DEFAULT_ROWS_PER_PAGE = 10;

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/input.css');
require('@omniex/onx-common-ui/lib/semantic/css/loader.css');
require('@omniex/onx-common-ui/lib/semantic/css/segment.css');
require('@omniex/onx-common-ui/lib/semantic/css/form.css');
require('@omniex/onx-common-ui/lib/semantic/css/grid.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');

const COMPONENT_NAME = 'PortfolioPagePositionsSection';

const StyledSection = styled('section')`
  /* min-width: 0; TODO: determine suitable min-width */

  .${COMPONENT_NAME}-loader {
    color: ${colors.grey};
  }

  .${COMPONENT_NAME}-segment {
    display: flex;
    min-height: 400px;
    padding: 0 !important;
  }

  .${COMPONENT_NAME}-successMessage + .${COMPONENT_NAME}-segment {
    border-top-color: ${colors.successBorderColor};
  }

  .${COMPONENT_NAME}-errorMessage + .${COMPONENT_NAME}-segment {
    border-top-color: ${colors.errorBorderColor};
  }

  .${COMPONENT_NAME}-loaderWrapper {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
  }

  .${COMPONENT_NAME}-loaderWrapperExpanded {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    min-height: 300px;
  }

  .${COMPONENT_NAME}-paginationSection {
    border-top: 1px solid ${colors.internalBorderColor};
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 14px;
  }

  .${COMPONENT_NAME}-paginationWrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  .${COMPONENT_NAME}-pagination {
    box-shadow: none;
    height: 34px;
    margin-left: 14px !important;
  }

  .${COMPONENT_NAME}-actionButtons {
    font-size: 0.8em;
    padding: 6px 12px;
  }

  .${COMPONENT_NAME}-formField {
    padding-top: none;
  }

  .${COMPONENT_NAME}-pageJumpInput {
    font-size: 0.8em;

    input {
      text-align: right;
      width: 70px !important;
    }
  }

  .${COMPONENT_NAME}-paginationLabel {
    background-color: ${colors.white} !important;
    border: 1px solid ${colors.borderColor};
    border-bottom-right-radius: 2px !important;
    border-top-right-radius: 2px !important;
    font-size: 0.8em;
    min-width: unset !important;
    text-align: center;
    width: 70px !important;
  }

  .${COMPONENT_NAME}-subsegment {
    padding: 14px;

    :first-child {
      flex: 1;
      overflow: auto;
    }

    :nth-child(2) {
      border-left: 1px solid ${colors.internalBorderColor};
      max-width: 308px !important;
    }
  }

  .${COMPONENT_NAME}-positionsTableWrapper {
    overflow-x: auto;
  }

  .${COMPONENT_NAME}-csvLink {
    &.clickable {
      color: ${colors.black};
    }

    &.disabled {
      color: ${colors.solidBorderColor};
    }
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class PortfolioPagePositionsSection extends PureComponent {
  static propTypes = {
    accountsWithBalancesQueryData: shape({
      accounts: arrayOf(
        shape({
          id: string.isRequired,
          balanceAtCustodianAsReportedTotal: number,
          currency: shape({
            id: string.isRequired
          }).isRequired,
          temporaryCalculatedBalance: number
        }).isRequired
      )
    }),
    accountsWithBalancesQueryLoading: bool,
    clientAssetsQueryData: object,
    clientAssetsQueryError: object,
    clientAssetsQueryLoading: bool,
    createAccountBuy: func,
    createAccountBuyMutationError: object,
    createAccountBuyMutationProcessing: bool,
    createAccountBuyMutationResult: shape({
      accountActivity: arrayOf(shape({ id: string.isRequired }))
    }),
    createAccountDeposit: func,
    createAccountDepositMutationError: object,
    createAccountDepositMutationProcessing: bool,
    createAccountDepositMutationResult: shape({
      accountActivity: shape({ id: string.isRequired })
    }),
    createAccountSell: func,
    createAccountSellMutationError: object,
    createAccountSellMutationProcessing: bool,
    createAccountSellMutationResult: shape({
      accountActivity: arrayOf(shape({ id: string.isRequired }))
    }),
    createAccountWithdrawal: func,
    createAccountWithdrawalMutationError: object,
    createAccountWithdrawalMutationProcessing: bool,
    createAccountWithdrawalMutationResult: shape({
      accountActivity: shape({ id: string.isRequired })
    }),
    createPosition: func,
    createPositionMutationError: object,
    createPositionMutationProcessing: bool,
    createPositionMutationResult: object,
    currenciesQueryData: shape({
      currencies: arrayOf(
        shape({
          id: string.isRequired,
          type: string.isRequired, // TODO: use enum constants
          symbol: string.isRequired,
          name: string.isRequired
        })
      )
    }),
    currenciesQueryError: object,
    currenciesQueryLoading: bool,
    currentPricesQueryData: shape({
      currentPrices: arrayOf(
        shape({
          baseCurrency: shape({
            id: string.isRequired
          }).isRequired,
          price: number
        })
      )
    }),
    currentPricesQueryError: object,
    currentPricesQueryLoading: bool,
    deleteAccountActivity: func,
    deleteAccountActivityMutationError: object,
    deleteAccountActivityMutationProcessing: bool,
    deleteAccountActivityMutationResult: shape({
      accountActivity: shape({ id: string.isRequired })
    }),
    fetchAccountsWithBalances: func,
    fetchPaginatedAccountActivities: func,
    integrationsQueryData: object,
    integrationsQueryError: object,
    integrationsQueryLoading: bool,
    loading: bool,
    orgQueryData: shape({
      org: shape({
        homeCurrency: shape({
          id: string.isRequired,
          symbol: string
        }).isRequired
      })
    }),
    orgQueryError: object,
    orgQueryLoading: bool,
    paginatedAccountActivitiesQueryData: object,
    paginatedAccountActivitiesQueryLoading: bool,
    paginatedAccountActivitiesQueryError: object,
    updatePosition: func,
    updatePositionMutationError: object,
    updatePositionMutationProcessing: bool,
    updatePositionMutationResult: object,
    user: shape({
      org: shape({
        type: string.isRequired
      })
    }),
  };

  static defaultProps = {
    createAccountBuy: noop,
    createAccountDeposit: noop,
    createAccountSell: noop,
    createAccountWithdrawal: noop,
    createPosition: noop,
    deleteAccountActivity: noop,
    fetchAccountsWithBalances: noop,
    fetchCurrencies: noop,
    fetchCurrentPrices: noop,
    fetchPaginatedAccountActivities: noop,
    updatePosition: noop
  };

  state = {};

  static getDerivedStateFromProps(nextProps, prevState) {
    const stateChanges = {};

    if (isEmpty(prevState)) {
      return getInitialState(nextProps);
    }

    for (const key of [
      'createAccountBuyMutationProcessing',
      'createAccountDepositMutationProcessing',
      'createAccountSellMutationProcessing',
      'createAccountWithdrawalMutationProcessing',
      'createPositionMutationProcessing',
      'deleteAccountActivityMutationProcessing',
      'paginatedAccountActivitiesQueryLoading',
      'updatePositionMutationProcessing'
    ]) {
      if (has(nextProps, key) && get(prevState, key) !== get(nextProps, key)) {
        stateChanges[key] = nextProps[key];
      }
    }

    if (
      (stateChanges.createAccountBuyMutationProcessing === false &&
        isError(nextProps.createAccountBuyMutationError)) ||
      (stateChanges.createAccountSellMutationProcessing === false &&
        isError(nextProps.createAccountSellMutationError)) ||
      (stateChanges.createAccountDepositMutationProcessing === false &&
        isError(nextProps.createAccountDepositMutationError)) ||
      (stateChanges.createAccountWithdrawalMutationProcessing === false &&
        isError(nextProps.createAccountWithdrawalMutationError))
    ) {
      stateChanges.showManualEntry = true;
      stateChanges.errorMessageKey = ERROR_MESSAGE_KEY_CREATE_ACCOUNT_TRANSACTION_FAILED;
    }

    if (
      (stateChanges.createAccountBuyMutationProcessing === false &&
        !isError(nextProps.createAccountBuyMutationError) &&
        get(nextProps, 'createAccountBuyMutationResult.accountActivity')) ||
      (stateChanges.createAccountSellMutationProcessing === false &&
        !isError(nextProps.createAccountSellMutationError) &&
        get(nextProps, 'createAccountSellMutationResult.accountActivity')) ||
      (stateChanges.createAccountDepositMutationProcessing === false &&
        !isError(nextProps.createAccountDepositMutationError) &&
        get(nextProps, 'createAccountDepositMutationResult.accountActivity')) ||
      (stateChanges.createAccountWithdrawalMutationProcessing === false &&
        !isError(nextProps.createAccountWithdrawalMutationError) &&
        get(nextProps, 'createAccountWithdrawalMutationResult.accountActivity'))
    ) {
      stateChanges.showManualEntry = false;
      stateChanges.successMessageKey = SUCCESS_MESSAGE_KEY_ACCOUNT_TRANSACTION_CREATED;
    }

    if (
      stateChanges.deleteAccountActivityMutationProcessing === false &&
      isError(nextProps.deleteAccountActivityMutationError)
    ) {
      stateChanges.showManualEntry = true;
      stateChanges.errorMessageKey = ERROR_MESSAGE_KEY_DELETE_ACCOUNT_TRANSACTION_FAILED;
    }

    if (
      stateChanges.deleteAccountActivityMutationProcessing === false &&
      !isError(nextProps.deleteAccountActivityMutationError) &&
      get(nextProps, 'deleteAccountActivityMutationResult.accountActivity')
    ) {
      stateChanges.showManualEntry = false;
      stateChanges.successMessageKey = SUCCESS_MESSAGE_KEY_ACCOUNT_TRANSACTION_DELETED;
    }

    if (
      stateChanges.paginatedAccountActivitiesQueryLoading === false &&
      isError(nextProps.paginatedAccountActivitiesQueryError)
    ) {
      stateChanges.errorMessageKey = ERROR_MESSAGE_KEY_PAGINATED;
    }

    if (
      stateChanges.createPositionMutationProcessing === false &&
      !isError(nextProps.createPositionMutationError) &&
      get(nextProps, 'createPositionMutationResult.position')
    ) {
      stateChanges.showManualEntry = false;
      stateChanges.errorMessageKey = undefined;
      stateChanges.successMessageKey = SUCCESS_MESSAGE_KEY_POSITION_CREATED;
      stateChanges.openAccountId = null;
    }

    if (
      stateChanges.createPositionMutationProcessing === false &&
      isError(nextProps.createPositionMutationError)
    ) {
      let errorMessageKey = get(
        nextProps.createPositionMutationError,
        'message',
        ERROR_MESSAGE_KEY_CREATE_POSITION
      );

      const matchingErrorMessage = errorMessageKey.match(/\[.+\]/);

      if (matchingErrorMessage) {
        errorMessageKey = matchingErrorMessage[0].replace(/\[|\]/g, '');
      } else {
        errorMessageKey = ERROR_MESSAGE_KEY_CREATE_POSITION;
      }

      stateChanges.errorMessageKey = errorMessageKey;
      stateChanges.errorMessageCustomText =
        nextProps.createPositionMutationError.message;
    }

    if (
      stateChanges.updatePositionMutationProcessing === false &&
      !isError(nextProps.updatePositionMutationError) &&
      get(nextProps, 'createPositionMutationResult.position')
    ) {
      stateChanges.showManualEntry = false;
      stateChanges.errorMessageKey = undefined;
      stateChanges.successMessageKey = SUCCESS_MESSAGE_KEY_POSITION_CREATED;
      stateChanges.openAccountId = null;
    }

    if (
      stateChanges.updatePositionMutationProcessing === false &&
      isError(nextProps.updatePositionMutationError)
    ) {
      stateChanges.errorMessageKey = ERROR_MESSAGE_KEY_UPDATE_POSITION;
    }

    if (
      stateChanges.updatePositionMutationProcessing === false &&
      !isError(nextProps.updatePositionMutationError) &&
      get(nextProps, 'updatePositionMutationResult.position')
    ) {
      stateChanges.showManualEntry = false;
      stateChanges.errorMessageKey = undefined;
      stateChanges.successMessageKey = SUCCESS_MESSAGE_KEY_POSITION_UPDATED;
    }

    return stateChanges;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.state.openAccountId &&
        (prevProps.createAccountBuyMutationProcessing === true &&
          this.state.createAccountBuyMutationProcessing === false)) ||
      (prevProps.createAccountSellMutationProcessing === true &&
        this.state.createAccountSellMutationProcessing === false) ||
      (prevProps.createAccountDepositMutationProcessing === true &&
        this.state.createAccountDepositMutationProcessing === false) ||
      (prevProps.createAccountWithdrawalMutationProcessing === true &&
        this.state.createAccountWithdrawalMutationProcessing === false) ||
      (prevProps.deleteAccountActivityMutationProcessing === true &&
        this.state.deleteAccountActivityMutationProcessing === false)
    ) {
      this.props.fetchAccountsWithBalances();

      if (prevState.openAccountId) {
        this.props.fetchPaginatedAccountActivities({
          accountId: prevState.openAccountId,
          orderParameter: 'timeExecuted',
          orderDirection: 'desc',
          limit: prevState.pagination.limit,
          offset:
            (prevState.pagination.currentPage - 1) * prevState.pagination.limit
        });
      }
    }
  }

  _getAccounts(accounts, currencies) {
    if (!Array.isArray(accounts) || accounts.length === 0) return [];

    const currenciesKeyedById =
      !Array.isArray(currencies) || currencies.length === 0
        ? {}
        : keyBy(currencies, 'id');

    return accounts.map(account => ({
      ...account,
      currency:
        currenciesKeyedById[generateCurrencyKey(account)] || account.currency
    }));
  }

  render() {
    const accounts = get(this.props, 'accountsWithBalancesQueryData.accounts');
    const currencies = get(this.props, 'currenciesQueryData.currencies');
    const homeCurrency = get(this.props, 'orgQueryData.org.homeCurrency');

    const errorVenues = unique((accounts || []).reduce((vs, a) => (a.error && vs.push(get(a, 'custodian.name')), vs), []).filter(_ => _)); // eslint-disable-line no-sequences
    const venueErrorMessage = copyText.unableToFetchBalance(errorVenues);

    const populatedAccounts = this._getAccounts((accounts || []).filter(a => isNil(a.error)), currencies);
    const aggregatePositions = getPositions(populatedAccounts);

    const currentPriceLookupTable = generateCurrentPriceLookupTable(
      get(this.props, 'currentPricesQueryData.currentPrices'),
      get(this.props, 'orgQueryData.org.homeCurrency.id')
    );

    const venues = getFilteredVenues({ relationships: this.props.orgQueryData?.org?.relationships })

    let errorMessage;

    if (
      [
        ERROR_MESSAGE_KEY_DUPLICATE_SYMBOL_OMNIEX,
        ERROR_MESSAGE_KEY_DUPLICATE_SYMBOL_ORG
      ].includes(this.state.errorMessageKey)
    )
      errorMessage = `${
        copyText[this.state.errorMessageKey]
      } ${this.state.errorMessageCustomText.match(/\(.+\)/)}.`;
    else {
      errorMessage = copyText[this.state.errorMessageKey];
    }

    return (
      <StyledSection className={COMPONENT_NAME}>
        <Menu attached="top">
          <Menu.Item header icon="table" content={copyText.sectionTitle} />
          <Menu.Menu position="right">
            {this.props.user.canDownloadPortfolioCSV && (
              <Menu.Item
                disabled={this.props.loading}
                content={
                  <CSVLink
                    data={getCsvData(populatedAccounts, currentPriceLookupTable)}
                    filename={`${CsvDataType.ACCOUNT} ${formatDate(
                      Date.now(),
                      null,
                      'YYYYMMDDHHmmss'
                    )}.csv`.replace(/\s/g, '-')}
                    headers={getCsvHeaders(homeCurrency)}>
                    <span
                      className={cn(
                        `csvLink ${this.props.loading ? 'disabled' : 'clickable'}`
                      )}>
                      {copyText.csvDownloadTabLabel}
                    </span>
                  </CSVLink>
                }
                icon="download"
              />
            )}
            {this.props.user.canViewManualEntryForm && (
              <Menu.Item
                active={this.state.showManualEntry}
                disabled={this.props.loading}
                content={copyText.openManualEntryTabLabel}
                icon="keyboard"
                onClick={this._handleClickManualEntry}
              />
            )}
          </Menu.Menu>
        </Menu>
        {false && !this.props.loading && venueErrorMessage && (
          <Message
            className={cn('errorMessage')}
            attached
            closeOnClick
            content={venueErrorMessage}
            icon="warning sign"
            error
          />
        )}
        {this.state.successMessageKey && (
          <Message
            className={cn('successMessage')}
            attached
            closeOnClick
            content={copyText[this.state.successMessageKey]}
            icon="check circle outline"
            success
          />
        )}
        {this.state.errorMessageKey && (
          <Message
            className={cn('errorMessage')}
            attached
            closeOnClick
            content={errorMessage}
            icon="warning sign"
            error
          />
        )}
        <Segment className={cn('segment')} attached="bottom">
          {(this.props.loading && !this.props.fetchBalancesMutationProcessing) ? (
            <div className={cn('loaderWrapper')}>
              <Loader
                className={cn('loader')}
                active
                content={copyText.loadingMessage}
                size="medium"
              />
            </div>
          ) : (
            <Fragment>
              <div className={cn('subsegment')}>
                <div className={cn('positionsTableWrapper')}>
                  <PortfolioPositionsTable
                    currentPriceLookupTable={currentPriceLookupTable}
                    homeCurrency={get(
                      this.props,
                      'orgQueryData.org.homeCurrency'
                    )}
                    positions={aggregatePositions}
                    renderExpandedContent={this._renderCustodianTable.bind(
                      this
                    )}
                  />
                </div>
              </div>
              {this.state.showManualEntry && (
                <div className={cn('subsegment')}>
                  <ManualEntryForm
                    accounts={populatedAccounts}
                    clientAssetsQueryData={this.props.clientAssetsQueryData}
                    homeCurrency={get(
                      this.props,
                      'orgQueryData.org.homeCurrency'
                    )}
                    integrationsQueryData={this.props.integrationsQueryData}
                    processing={
                      this.props.createAccountBuyMutationProcessing ||
                      this.props.createAccountDepositMutationProcessing ||
                      this.props.createAccountSellMutationProcessing ||
                      this.props.createAccountWithdrawalMutationProcessing ||
                      this.props.createPositionMutationProcessing ||
                      this.props.clientAssetsQueryLoading ||
                      this.props.integrationsQueryLoading
                    }
                    size="small"
                    venues={venues}
                    onCancel={this._handleClickManualEntry.bind(this)}
                    onSaveActivity={this._handleSaveManualActivity}
                    onSavePosition={this._handleSaveManualPosition}
                  />
                </div>
              )}
            </Fragment>
          )}
        </Segment>
      </StyledSection>
    );
  }

  _renderCustodianTable(currency, currentPrice) {
    const activities = get(
      this.props,
      'paginatedAccountActivitiesQueryData.accountActivities.accountActivities'
    ) || [];

    const accounts = this._getAccounts(
      get(this.props, 'accountsWithBalancesQueryData.accounts'),
      get(this.props, 'currenciesQueryData.currencies')
    );

    const filteredAccounts = accounts.filter(
      account => (
        account.currency.id === currency.id &&
        shouldDisplayPosition(account, getTotalAccountBalance(account))
      )
    );

    const currentPriceLookupTable = generateCurrentPriceLookupTable(
      get(this.props, 'currentPricesQueryData.currentPrices'),
      get(this.props, 'orgQueryData.org.homeCurrency.id')
    );
    return (
      <PortfolioPositionsCustodianTable
        accounts={filteredAccounts}
        activities={activities}
        activitiesLoading={get(
          this.props,
          'paginatedAccountActivitiesQueryLoading',
          []
        )}
        currency={currency}
        currentPrice={currentPrice}
        currentPriceLookupTable={currentPriceLookupTable}
        forFutures={currency.type === AssetType.FUTURE}
        homeCurrency={get(this.props, 'orgQueryData.org.homeCurrency')}
        openAccountId={this.state.openAccountId}
        handleExpandedRow={this._handleClickAccountRow}
        renderExpandedContent={this._renderAccountActivityTable.bind(this)}
      />
    );
  }

  _renderAccountActivityTable(
    activities,
    activitiesLoading,
    account,
    currentPrice,
    currency,
    pageRange,
    numberOfPages
  ) {
    //TODO: Selector
    //Filter is to prevent flicker while cache changes
    activities = activities
      .filter(activity => activity.account.id === account.id)
      .map(activity => {
        activity.account = account;
        return activity;
      });

    const homeCurrency = get(this.props, 'orgQueryData.org.homeCurrency');

    return (
      <Fragment>
        {this.state.errorMessageKey && (
          <Message
            className={cn('errorMessage')}
            attached
            closeOnClick
            content={copyText[this.state.errorMessageKey]}
            icon="warning sign"
            error
          />
        )}
        <AccountActivityTable
          account={account}
          activities={activities}
          activitiesLoading={activitiesLoading}
          currentPrice={currentPrice}
          homeCurrency={homeCurrency}
          isFiat={currency.type === AssetType.FIAT}
          loading={this.props.loading}
          updatePositionMutationError={this.props.updatePositionMutationError}
          updatePositionMutationProcessing={
            this.props.updatePositionMutationProcessing
          }
          updatePositionMutationResult={this.props.updatePositionMutationResult}
          user={this.props.user}
          onDelete={this._handleDeleteAccountActivity}
          onSave={this._handleUpdatePosition}
        />
        {this._renderPaginationControls(pageRange, numberOfPages)}
      </Fragment>
    );
  }

  _renderPaginationControls() {
    const accountActivitiesCount = get(
      this.props,
      'paginatedAccountActivitiesQueryData.accountActivities.count',
      0
    );

    const numberOfPages = Math.ceil(
      accountActivitiesCount / this.state.pagination.limit
    );

    let startPage;

    if (numberOfPages <= 3 || this.state.pagination.currentPage === 1) {
      startPage = 1;
    } else if (this.state.pagination.currentPage === numberOfPages) {
      startPage = this.state.pagination.currentPage - 2;
    } else {
      startPage = this.state.pagination.currentPage - 1;
    }

    const pageRange = Array.from(
      new Array(Math.min(3, numberOfPages)),
      (v, i) => i + startPage
    );

    return (
      <div className={cn('paginationSection')}>
        <div className={cn('paginationWrapper')}>
          <Form
            autoComplete="off"
            onSubmit={e =>
              this._handleClickPageNumber(e, {
                value: this.state.userEnteredPageNumber
              })
            }>
            <Form.Field className={cn('formField')}>
              <Input
                className={cn('pageJumpInput')}
                label={
                  <p className={cn('paginationLabel')}> of {numberOfPages}</p>
                }
                labelPosition="right"
                placeholder={copyText.inputPlaceholder_pageNumber}
                value={this.state.userEnteredPageNumber}
                onChange={this._handleChangeUserEnteredPageNumber.bind(
                  this,
                  numberOfPages
                )}
              />
            </Form.Field>
          </Form>
          <Menu
            className={cn('pagination')}
            borderless
            floated="right"
            pagination
            size="mini">
            <Menu.Item
              icon="angle double left"
              value={1}
              onClick={this._handleClickPageNumber}
            />
            <Menu.Item
              icon="angle left"
              value="LEFT"
              onClick={this._handleClickPageDirection.bind(this, numberOfPages)}
            />
            {pageRange.map(pageIndex => (
              <Menu.Item
                active={pageIndex === this.state.pagination.currentPage}
                as="a"
                content={`${pageIndex}`}
                key={pageIndex}
                value={pageIndex}
                onClick={this._handleClickPageNumber}
              />
            ))}
            <Menu.Item
              icon="angle right"
              value="RIGHT"
              onClick={this._handleClickPageDirection.bind(this, numberOfPages)}
            />
            <Menu.Item
              icon="angle double right"
              value={numberOfPages}
              onClick={this._handleClickPageNumber}
            />
          </Menu>
        </div>
      </div>
    );
  }

  _handleClickManualEntry = () => {
    this.setState(currentState => ({
      showManualEntry: !currentState.showManualEntry,
      successMessageKey: undefined
    }));
  };

  _handleDeleteAccountActivity = id => {
    this.setState({
      errorMessageKey: undefined,
      successMessageKey: undefined
    });

    this.props.deleteAccountActivity({ id });
  };

  _handleSaveManualPosition = async formInputs => {
    const {
      accountId,
      assetId,
      assetName,
      assetSymbol,
      custodianId,
      positionQuantity,
      price
    } = formInputs;

    await this.props.createPosition({
      accountId,
      assetId,
      assetName,
      assetSymbol,
      custodianId,
      positionQuantity,
      price
    });
  };

  _handleSaveManualActivity = formInputs => {
    this.setState({
      errorMessageKey: undefined,
      successMessageKey: undefined
    });

    let {
      fromAccountId,
      toAccountId,
      adjustedCostBasis,
      datestamp,
      executionPrice,
      fees,
      quantity,
      transactionType,
      venueId
    } = formInputs;

    const basicParameters = {
      accountId:
        transactionType === AccountActivityTransactionType.DEPOSIT
          ? toAccountId
          : fromAccountId,
      adjustedCostBasis,
      quantity,
      timeExecuted: datestamp,
      transactionType
    };

    const tradeParameters = {
      fromAccountId,
      toAccountId,
      executionPrice,
      fees,
      quantity,
      timeExecuted: datestamp,
      venueId
    };

    switch (transactionType) {
      case AccountActivityTransactionType.DEPOSIT:
        this.props.createAccountDeposit(basicParameters);
        break;
      case AccountActivityTransactionType.BUY:
        this.props.createAccountBuy(tradeParameters);
        break;
      case AccountActivityTransactionType.SELL:
        this.props.createAccountSell(tradeParameters);
        break;
      case AccountActivityTransactionType.WITHDRAWAL:
        this.props.createAccountWithdrawal(basicParameters);
        break;
      default:
        return;
    }
  };

  _handleUpdatePosition = async formInputs => {
    const { accountId, assetId, positionQuantity, price } = formInputs;

    await this.props.updatePosition({
      accountId,
      assetId,
      positionQuantity,
      price
    });

    await this.setState({ openAccountId: accountId });
  };

  _handleClickAccountRow = accountId => {
    if (this.state.openAccountId !== accountId) {
      const parameters = {
        accountId,
        orderParameter: 'timeExecuted',
        orderDirection: 'desc',
        limit: DEFAULT_ROWS_PER_PAGE,
        offset: 0
      };

      this.props.fetchPaginatedAccountActivities(parameters);

      this.setState({
        errorMessageKey: undefined,
        openAccountId: accountId
      });
    } else {
      this.setState({
        openAccountId: undefined,
        pagination: {
          limit: DEFAULT_ROWS_PER_PAGE,
          currentPage: 1
        }
      });
    }
  };

  _handleClickPageNumber = (event, { value }) => {
    if (value <= 0) return;

    this.props.fetchPaginatedAccountActivities({
      accountId: this.state.openAccountId,
      orderParameter: 'timeExecuted',
      orderDirection: 'desc',
      limit: this.state.pagination.limit,
      offset: (value - 1) * this.state.pagination.limit
    });

    this.setState(currentState => ({
      errorMessageKey: undefined,
      userEnteredPageNumber: '',
      pagination: {
        ...currentState.pagination,
        currentPage: value
      }
    }));
  };

  _handleClickPageDirection = (numberOfPages, event, { value }) => {
    let change = 0;
    if (value === 'LEFT' && this.state.pagination.currentPage > 1) {
      change = -1;
    }

    if (
      value === 'RIGHT' &&
      this.state.pagination.currentPage < numberOfPages
    ) {
      change = 1;
    }

    this.props.fetchPaginatedAccountActivities({
      accountId: this.state.openAccountId,
      orderParameter: 'timeExecuted',
      orderDirection: 'desc',
      limit: this.state.pagination.limit,
      offset:
        (this.state.pagination.currentPage - 1 + change) *
        this.state.pagination.limit
    });

    if (change !== 0) {
      this.setState(currentState => ({
        errorMessageKey: undefined,
        userEnteredPageNumber: '',
        pagination: {
          ...currentState.pagination,
          currentPage: currentState.pagination.currentPage + change
        }
      }));
    }
  };

  _handleChangeUserEnteredPageNumber = (numberOfPages, event, field) => {
    let value = field.value.replace(/[^\d.]/g, '');
    value = !isEmpty(value) ? parseInt(value) : '';

    if (value > numberOfPages) return;

    this.setState({
      errorMessageKey: undefined,
      userEnteredPageNumber: value
    });
  };
}

const getInitialState = _ => ({
  errorMessageKey: undefined,
  openAccountId: undefined,
  pagination: {
    currentPage: 1,
    limit: DEFAULT_ROWS_PER_PAGE,
  },
  successMessageKey: undefined,
  userEnteredPageNumber: '',
});


const getCsvData = (accounts, currentPriceLookupTable) => sortBy(accounts
  .reduce((accum, account) => {
    const asset = account.currency || {};
    const currentPrice = currentPriceLookupTable[asset.id];
    const isFuture = asset.type === AssetType.FUTURE;

    const qty = getTotalAccountBalance(account)

    if (!shouldDisplayPosition(account, qty)) return accum;

    const sharedFields = {
      currencySymbol: getAssetDisplayText(asset),
      assetType: isFuture && isNil(asset.expiry)
        ? 'PSWAP'
        : asset.type,
      custodian: getCustodianName(account),
      quantity: qty,
      currentPrice: currentPrice || copyText.notAvailable,
      marketValue: isFuture
        ? null                  // 2021/07/27 product has decided that market value is only for spot
        : currentPrice * qty    // prefer Decimal.mul but currentPrice can be undefined, this needs to be investigated
    };

    const futureFields = isFuture && !isNil(account.position)
      ? {
          value: getValue(account),
          entryPrice: account.position.entryPrice,
          markPrice: account.position.markPrice,
          liquidationPrice: account.position.liquidationPrice,
          margin: getMargin(account),
          unrealizedPnL: getUnrealizedPnL(account),
          liquidationValue: getLiquidationValue(account, currentPriceLookupTable),
        }
      : {};

    accum.push({ ...sharedFields, ...futureFields });
    return accum;
  }, []),
  ['currencySymbol', 'custodian']
);

const getCsvHeaders = homeCurrency => [
  'currencySymbol',
  'assetType',
  'custodian',
  'quantity',
  'currentPrice',
  'marketValue',
  'value',
  'entryPrice',
  'markPrice',
  'liquidationPrice',
  'margin',
  'unrealizedPnL',
  'liquidationValue',
].map(key => {
  let label = copyText[`csvHeader_${key}`];
  if (['currentPrice', 'marketValue', 'liquidationValue'].includes(key) && homeCurrency?.symbol){
    label = label.concat(` (${homeCurrency.symbol})`)
  }
  else if (['entryPrice', 'markPrice', 'liquidationPrice'].includes(key)){
    label = label.concat(` (USD)`)
  }
  return { key, label };
});
