import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { Button } from 'semantic-ui-react';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import { isBlank } from '@omniex/onx-common-js/lib/utils/StringUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import copyText from './UnclaimedQuoteRequestsTable.copyText';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import Table, {
  CELL_FORMAT_NUMBER,
  CELL_FORMAT_TIMESTAMP_UTC
} from '@omniex/onx-common-ui/lib/semantic/react/Table';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/button.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');
require('@omniex/onx-common-ui/lib/semantic/css/table.css');

const COMPONENT_NAME = 'UnclaimedQuoteRequestsTable';

const StyledTable = styled(Table)`
  .${COMPONENT_NAME}-currency {
    padding-left: 0 !important;
  }

  .${COMPONENT_NAME}-quantity {
    padding-right: 0.5em !important;
  }

  .${COMPONENT_NAME}-cancelButton {
    margin-left: 5px !important;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class UnclaimedQuoteRequestsTable extends PureComponent {
  static propTypes = {
    requests: arrayOf(
      shape({
        id: string.isRequired,
        fixClientID: string.isRequired,
        instrument: shape({
          id: string.isRequired,
          displayName: string.isRequired
        }).isRequired,
        specifiedCurrency: shape({
          id: string.isRequired,
          symbol: string.isRequired
        }).isRequired,
        side: string,
        specifiedQuantity: number.isRequired,
        timeCreated: string.isRequired
      })
    ),
    processing: bool,
    onClaim: func
  };

  static defaultProps = {
    onClaim: noop
  };

  render() {
    if (get(this.props, 'requests.length', 0) === 0) {
      return this._renderWarningMessage();
    }

    return (
      <StyledTable className={COMPONENT_NAME}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{copyText.tableHeaderTimestamp}</Table.HeaderCell>
            <Table.HeaderCell>{copyText.tableHeaderClient}</Table.HeaderCell>
            <Table.HeaderCell>
              {copyText.tableHeaderInstrument}
            </Table.HeaderCell>
            <Table.HeaderCell>{copyText.tableHeaderSide}</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              {copyText.tableHeaderQuantity}
            </Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.requests.map(request => (
            <Table.Row className={cn('row')} key={request.id}>
              <Table.Cell formatType={CELL_FORMAT_TIMESTAMP_UTC}>
                {request.timeCreated}
              </Table.Cell>
              <Table.Cell>{request.fixClientID}</Table.Cell>
              <Table.Cell>{request.instrument.displayName}</Table.Cell>
              <Table.Cell>
                {isBlank(request.side) ? copyText.label2Way : request.side}
              </Table.Cell>
              <Table.Cell
                className={cn('quantity')}
                formatType={CELL_FORMAT_NUMBER}
                format="0,0.00000000">
                {request.specifiedQuantity}
              </Table.Cell>
              <Table.Cell className={cn('currency')}>
                {getAssetDisplayText(request.specifiedCurrency)}
              </Table.Cell>
              <Table.Cell textAlign="right">
                <Button
                  color="orange"
                  disabled={this.props.processing}
                  size="mini"
                  onClick={this._handleClickClaimButton.bind(this, request.id)}>
                  {copyText.claimButtonLabel}
                </Button>
                <Button
                  className={cn('cancelButton')}
                  color="orange"
                  disabled={this.props.processing}
                  size="mini"
                  onClick={this._handleClickCancelButton.bind(
                    this,
                    request.id
                  )}>
                  {copyText.cancelButtonLabel}
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </StyledTable>
    );
  }

  _renderWarningMessage() {
    return (
      <div className={COMPONENT_NAME}>
        <Message content={copyText.warningMessage} warning />
      </div>
    );
  }

  _handleClickCancelButton(requestId) {
    this.props.onCancel(requestId);
  }

  _handleClickClaimButton(requestId) {
    this.props.onClaim(requestId);
  }
}
