import gql from 'graphql-tag';

export default gql`
  query getCsvData(
    $orderParameter: String!
    $orderDirection: String!
    $dataType: String
    $endDate: String
    $startDate: String
  ) {
    data: getCsvData(
      orderParameter: $orderParameter
      orderDirection: $orderDirection
      dataType: $dataType
      endDate: $endDate
      startDate: $startDate
    ) {
      settlements {
        id
        allocations {
          id
          currency {
            id
          }
          fixToCompID
          fromAccount {
            id
            name
          }
          fromInstruction {
            id
            freeForm
            iban
            walletAddress
          }
          quantity
          toAccount {
            id
            name
          }
          toInstruction {
            id
            freeForm
            iban
            walletAddress
          }
        }
        fixAllocID
        initiatorOrg {
          id
          name
        }
        respondentOrg {
          id
          name
        }
        status
        timeCreated
      }
    }
  }
`;
