const accountsTableCopyText = {
  popupUpdateWarningHeader: 'Are you sure you want to proceed?',
  popupUpdateWarningMessage:
    'Are you sure you want to update this instruction?',
  proceedButtonLabel: 'Proceed',
  tableHeaderAccountName: 'Account Name',
  tableHeaderWallet: 'Wallet',
  tableHeaderCurrency: 'Currency',
  tableHeaderRename: 'Rename',
  tableHeaderWalletAddress: 'Wallet Address',
  tableHeaderWalletName: 'Wallet Name',
  warningMessage: 'There are no wallets to display at this time.'
};

export default accountsTableCopyText;
