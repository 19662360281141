import gql from 'graphql-tag';

export default gql`
  query getUnsettledOtcFills {
    unsettledOtcFills: getUnsettledOtcFills {
      id
      fixExecID
      order {
        id
        feature
        fixClOrdID
        fixOrderID
        fixQuoteID
        fixVenueOrderID
        instrument {
          id
        }
        side
        type
      }
      org {
        id
        name
      }
      price
      quantity
      specifiedCurrency {
        id
      }
      timeCreated
      venue {
        id
        name
        org {
          id
          name
        }
      }
    }
  }
`;
