/* eslint no-sequences: 0 */
import React, { PureComponent } from 'react'
import clsx from 'clsx'

import MuiTable from '@material-ui/core/Table'
import MuiTableBody from '@material-ui/core/TableBody'
import MuiTableCell from '@material-ui/core/TableCell'
import MuiTableHead from '@material-ui/core/TableHead'
import MuiTableRow from '@material-ui/core/TableRow'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

import { clamp } from '../../../utils/MathUtils';
import { align, collapsible, filterable, fmtColumnLabel, fmtColumnValue } from './utils'
import { useTableClasses, useTableCellStyles } from './styles'
import ColumnHeader from './ColumnHeader'

import { noop } from '@omniex/poms-core/lib/utils/FunctionUtils';

/**
 * Table v1 - basic <table>-based implementation
 */
const TableHeader = ({ cols, headerColProps: { filters, setFilter, ...rest } }) => {
  const cls = useTableClasses()
  return (
    <thead className={cls.header}>
      <tr className={cls.headerRow}>
        {cols.map((c, i) => <ColumnHeader key={i} label={fmtColumnLabel(c)} column={c} filterable={filterable(c)} setFilter={setFilter(c)} filter={filters[c]} {...rest} />)}
      </tr>
    </thead>
  )
}

const TRow = ({ cols, row, path }) => {
  const cls = useTableClasses()
  return (
    <tr className={cls.row}>
      {cols.map((c, i) => <td key={i} className={clsx(cls.cell, cls[align(c)])}>{fmtColumnValue(c, row[c], row, path)}</td>)}
    </tr>
  )
}

const TableBody = ({ cols, rows, path }) => Array.isArray(rows)
  ? rows.map((row, i) => <TRow key={i} cols={cols} row={row} path={path} />)
  : rows && typeof rows === 'object' && Object.keys(rows).map((key, i) => <TableBody key={i} cols={cols} rows={rows[key]} path={path} />)

const Table = ({ cols, rows, path, headerColProps }) => {
  const cls = useTableClasses()
  return (
    <div className={cls.root}>
      <table className={cls.table}>
        <TableHeader cols={cols} headerColProps={headerColProps} />
        <tbody className={cls.body}>
          <TableBody cols={cols} rows={rows} path={path} />
        </tbody>
      </table>
    </div>
  )
}


/**
 * Table v2 - column-based implementation using only <div>s
 */
class Cell extends PureComponent {
  render() {
    const { value } = this.props
    const cls = useTableClasses()
    return <div className={cls.cell}>{value}</div>
  }
}

const TableColumn = ({ index, column, rows, path, headerColProps }) => {
  const cls = useTableClasses()
  return (
    <div className={cls.column}>
      <div>{fmtColumnLabel(column)}</div>
      {rows.map((r, i) => <div key={i} className={cls.cell2}>{fmtColumnValue(column, r[column], r, path)}</div>)}
      {false && rows.map((r, i) => <Cell key={i} index={i} columnIndex={index} column={column} value={fmtColumnValue(column, r[column], r, path)} />)}
    </div>
  )
}

export const Table2 = ({ cols, rows, path, headerColProps }) => {
  const cls = useTableClasses()
  return (
    <div className={cls.table2}>
      {cols.map((c, i) => <TableColumn key={i} index={i} column={c} rows={rows} path={path} headerColProps={headerColProps} />)}
    </div>
  )
}

const toggleCollapseRow = (chain, collapsedRows, setCollapsedRows) => {
  collapsedRows.includes(chain) ? setCollapsedRows(collapsedRows.filter(r => r !== chain)) : setCollapsedRows([...collapsedRows, chain])
}

const TableCell = ({ c, r, path, cls, collapsibleRow, collapsedRows, chainEnd }) => {
  const collapseArrow = collapsibleRow && chainEnd && r[c] === chainEnd
  const { cell } = useTableCellStyles()
  return (
    <MuiTableCell className={cell} align={collapseArrow ? 'left' : align(c)}>
      {collapseArrow &&
        (collapsedRows.includes(r.__chain)
          ? <ArrowRightIcon className={cls.collapseArrow} />
          : <ArrowDropDownIcon className={cls.collapseArrow} />)
      }
      {fmtColumnValue(c, r[c], r, path)}
    </MuiTableCell>
  )
}

const TableRow = ({ r, cols, path, showData, cls, collapsedRows, setCollapsedRows }) => {
  const maxDepth = path.length + (path.length && !showData ? 0 : 1)
  const depth = r => clamp(maxDepth - r.__depth, 0, 9) | 0

  const chainLinks = r.__chain && r.__chain.split(":")
  const chainEnd = chainLinks && chainLinks[chainLinks.length - 1]

  const collapsibleRow = collapsible(r, path, showData)
  const rowStyle = collapsibleRow ? cls.collapsibleRow : cls.row

  return (
    <MuiTableRow hover className={clsx(rowStyle, cls[`d${depth(r)}`])} onClick={() => collapsibleRow ? toggleCollapseRow(r.__chain, collapsedRows, setCollapsedRows) : noop}>
      {cols.map((c, ic) =>
        <TableCell
          key={ic}
          c={c}
          r={r}
          path={path}
          cls={cls}
          collapsibleRow={collapsibleRow}
          collapsedRows={collapsedRows}
          chainEnd={chainEnd} />
      )}
    </MuiTableRow>
  )
}

/**
 * Table v3 - sexy af MUI implementation
 */
export const Table3 = ({ cols, rows, path = [], headerColProps: { filters, filterOptions, setFilter, ...rest }, showData, collapsedRows, setCollapsedRows }) => {
  const cls = useTableClasses()
  return (
    <div className={cls.table3}>
      <MuiTable size="small" stickyHeader>
        <MuiTableHead>
          <MuiTableRow>
            {cols.map((c, i) =>
              <MuiTableCell key={i}>
                <ColumnHeader
                  column={c}
                  label={fmtColumnLabel(c)}
                  filter={filters[c]}
                  filterable={filterable(c)}
                  filterOptions={filterOptions[c]}
                  setFilter={setFilter(c)}
                  {...rest} />
              </MuiTableCell>
            )}
          </MuiTableRow>
        </MuiTableHead>
        <MuiTableBody>
          {rows.map((r, ir) =>
            <TableRow
              key={ir}
              r={r}
              cols={cols}
              path={path}
              showData={showData}
              cls={cls}
              collapsedRows={collapsedRows}
              setCollapsedRows={setCollapsedRows} />
          )}
        </MuiTableBody>
      </MuiTable>
    </div>
  )
}


export default Table
