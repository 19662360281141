import { bool, func, object, shape, string } from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import styled from 'react-emotion';

import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import PortfolioPageBreakdownSection from './PortfolioPageBreakdownSection';
// import PortfolioPagePerformanceSection from './PortfolioPagePerformanceSection';
import PortfolioPagePositionsSection from './PortfolioPagePositionsSection';
// import PortfolioPageValuationSection from './PortfolioPageValuationSection';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');
require('@omniex/onx-common-ui/lib/semantic/css/popup.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const COMPONENT_NAME = 'PortfolioPage';

const StyledDiv = styled('div')`
  height: 100%;
  overflow: hidden;

  .${COMPONENT_NAME}-sectionWrapper {
    padding-top: 20px;

    :last-child {
      margin-bottom: 60px;
    }
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class PortfolioPage extends PureComponent {
  static propTypes = {
    accountsWithBalancesQueryData: object,
    accountsWithBalancesQueryError: object,
    accountsWithBalancesQueryLoading: bool,
    clientAssetsQueryData: object,
    clientAssetsQueryError: object,
    clientAssetsQueryLoading: bool,
    createAccountBuy: func,
    createAccountBuyMutationError: object,
    createAccountBuyMutationProcessing: bool,
    createAccountBuyMutationResult: object,
    createAccountDeposit: func,
    createAccountDepositMutationError: object,
    createAccountDepositMutationProcessing: bool,
    createAccountDepositMutationResult: object,
    createAccountSell: func,
    createAccountSellMutationError: object,
    createAccountSellMutationProcessing: bool,
    createAccountSellMutationResult: object,
    createAccountWithdrawal: func,
    createAccountWithdrawalMutationError: object,
    createAccountWithdrawalMutationProcessing: bool,
    createAccountWithdrawalMutationResult: object,
    createPosition: func,
    createPositionMutationError: object,
    createPositionMutationProcessing: bool,
    createPositionMutationResult: object,
    currenciesQueryData: object,
    currenciesQueryError: object,
    currenciesQueryLoading: bool,
    currentPricesQueryData: object,
    currentPricesQueryError: object,
    currentPricesQueryLoading: bool,
    deleteAccountActivity: func,
    deleteAccountActivityMutationError: object,
    deleteAccountActivityMutationProcessing: bool,
    deleteAccountActivityMutationResult: object,
    fetchBalances: func,
    fetchBalancesMutationData: object,
    fetchBalancesMutationError: object,
    fetchBalancesMutationProcessing: bool,
    integrationsQueryData: object,
    integrationsQueryError: object,
    integrationsQueryLoading: bool,
    loading: bool,
    orgQueryData: object,
    orgQueryError: object,
    orgQueryLoading: bool,
    paginatedAccountActivitiesQueryData: object,
    paginatedAccountActivitiesQueryLoading: bool,
    paginatedAccountActivitiesQueryError: object,
    updatePosition: func,
    updatePositionMutationError: object,
    updatePositionMutationProcessing: bool,
    updatePositionMutationResult: object,
    user: shape({
      org: shape({
        type: string.isRequired
      })
    }),
    fetchAccountsWithBalances: func,
    fetchPaginatedAccountActivities: func,
  };

  static defaultProps = {
    createAccountBuy: noop,
    createAccountDeposit: noop,
    createAccountSell: noop,
    createAccountWithdrawal: noop,
    createPosition: noop,
    deleteAccountActivity: noop,
    fetchAccountsWithBalances: noop,
    fetchBalances: noop,
    fetchPaginatedAccountActivities: noop,
    updatePosition: noop,
  };

  render() {
    return (
      <StyledDiv className={COMPONENT_NAME}>
          <main>
            <Fragment>
              <div id="breakdown" className={cn('sectionWrapper')}>
                <PortfolioPageBreakdownSection
                  accountsWithBalancesQueryData={this.props.accountsWithBalancesQueryData}
                  accountsWithBalancesQueryLoading={this.props.accountsWithBalancesQueryLoading}
                  currenciesQueryData={this.props.currenciesQueryData}
                  currenciesQueryError={this.props.currenciesQueryError}
                  currenciesQueryLoading={this.props.currenciesQueryLoading}
                  currentPricesQueryData={this.props.currentPricesQueryData}
                  currentPricesQueryError={this.props.currentPricesQueryError}
                  currentPricesQueryLoading={this.props.currentPricesQueryLoading}
                  fetchBalances={this.props.fetchBalances}
                  fetchBalancesMutationData={this.props.fetchBalancesMutationData}
                  fetchBalancesMutationError={this.props.fetchBalancesMutationError}
                  fetchBalancesMutationProcessing={this.props.fetchBalancesMutationProcessing}
                  loading={this.props.loading}
                  orgQueryData={this.props.orgQueryData}
                  orgQueryError={this.props.orgQueryError}
                  orgQueryLoading={this.props.orgQueryLoading}
                  user={this.props.user}
                />
              </div>
              <div className={cn('sectionWrapper')}>
                <PortfolioPagePositionsSection
                  accountsWithBalancesQueryData={this.props.accountsWithBalancesQueryData}
                  accountsWithBalancesQueryLoading={this.props.accountsWithBalancesQueryLoading}
                  clientAssetsQueryData={this.props.clientAssetsQueryData}
                  clientAssetsQueryError={this.props.clientAssetsQueryError}
                  clientAssetsQueryLoading={this.props.clientAssetsQueryLoading}
                  createAccountBuy={this.props.createAccountBuy}
                  createAccountBuyMutationError={this.props.createAccountBuyMutationError}
                  createAccountBuyMutationProcessing={this.props.createAccountBuyMutationProcessing}
                  createAccountBuyMutationResult={this.props.createAccountBuyMutationResult}
                  createAccountDeposit={this.props.createAccountDeposit}
                  createAccountDepositMutationError={this.props.createAccountDepositMutationError}
                  createAccountDepositMutationProcessing={this.props.createAccountDepositMutationProcessing}
                  createAccountDepositMutationResult={this.props.createAccountDepositMutationResult}
                  createAccountWithdrawal={this.props.createAccountWithdrawal}
                  createAccountWithdrawalMutationError={this.props.createAccountWithdrawalMutationError}
                  createAccountWithdrawalMutationProcessing={this.props.createAccountWithdrawalMutationProcessing}
                  createAccountWithdrawalMutationResult={this.props.createAccountWithdrawalMutationResult}
                  createPosition={this.props.createPosition}
                  createPositionMutationError={this.props.createPositionMutationError}
                  createPositionMutationProcessing={this.props.createPositionMutationProcessing}
                  createPositionMutationResult={this.props.createPositionMutationResult}
                  createAccountSell={this.props.createAccountSell}
                  createAccountSellMutationError={this.props.createAccountSellMutationError}
                  createAccountSellMutationProcessing={this.props.createAccountSellMutationProcessing}
                  createAccountSellMutationResult={this.props.createAccountSellMutationResult}
                  currenciesQueryData={this.props.currenciesQueryData}
                  currenciesQueryError={this.props.currenciesQueryError}
                  currenciesQueryLoading={this.props.currenciesQueryLoading}
                  currentPricesQueryData={this.props.currentPricesQueryData}
                  currentPricesQueryError={this.props.currentPricesQueryError}
                  currentPricesQueryLoading={this.props.currentPricesQueryLoading}
                  fetchAccountsWithBalances={this.props.fetchAccountsWithBalances}
                  fetchBalancesMutationProcessing={this.props.fetchBalancesMutationProcessing}
                  fetchPaginatedAccountActivities={this.props.fetchPaginatedAccountActivities}
                  integrationsQueryData={this.props.integrationsQueryData}
                  integrationsQueryError={this.props.integrationsQueryError}
                  integrationsQueryLoading={this.props.integrationsQueryLoading}
                  deleteAccountActivity={this.props.deleteAccountActivity}
                  deleteAccountActivityMutationError={this.props.deleteAccountActivityMutationError}
                  deleteAccountActivityMutationProcessing={this.props.deleteAccountActivityMutationProcessing}
                  deleteAccountActivityMutationResult={this.props.deleteAccountActivityMutationResult}
                  loading={this.props.loading}
                  orgQueryData={this.props.orgQueryData}
                  orgQueryError={this.props.orgQueryError}
                  orgQueryLoading={this.props.orgQueryLoading}
                  paginatedAccountActivitiesQueryData={this.props.paginatedAccountActivitiesQueryData}
                  paginatedAccountActivitiesQueryLoading={this.props.paginatedAccountActivitiesQueryLoading}
                  paginatedAccountActivitiesQueryError={this.props.paginatedAccountActivitiesQueryError}
                  updatePosition={this.props.updatePosition}
                  updatePositionMutationError={this.props.updatePositionMutationError}
                  updatePositionMutationProcessing={this.props.updatePositionMutationProcessing}
                  updatePositionMutationResult={this.props.updatePositionMutationResult}
                  user={this.props.user}
                />
              </div>
              {/* <div className={cn('sectionWrapper')}>
                <PortfolioPageValuationSection />
              </div>
              <div className={cn('sectionWrapper')}>
                <PortfolioPagePerformanceSection />
              </div> */}
            </Fragment>
          </main>
      </StyledDiv>
    );
  }

  _handleVerticalScroll(yPosition) {
    if (!Number.isInteger(yPosition)) return;

    window.scrollTo(0, yPosition);
  }
}
