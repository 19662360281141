import { useQuery, useLazyQuery, useMutation } from 'react-apollo';
import React from 'react';

import { cleanQuery } from '../utils/PropTypeUtils';
import { ERROR_PERMISSION_DENIED } from '@omniex/onx-common-ui/lib/errors';
import { safeExecAsync } from '../utils/FunctionUtils';

import AdminPage from '../ui/pages/AdminPage';
import createAccountsMutation from '../apollo/graphql/createAccounts';
import createPortfolioMutation from '../apollo/graphql/createPortfolio';
import createRequestedIntegrationMutation from '../apollo/graphql/createRequestedIntegration';
import ErrorPage from '@omniex/onx-common-ui/lib/pages/ErrorPage';
import getAccountsQuery from '../apollo/graphql/buy-side/getAccounts';
import getCurrenciesQuery from '../apollo/graphql/getCurrencies';
import getExchangeAccountsQuery from '../apollo/graphql/buy-side/getExchangeAccounts';
import getIntegrationsQuery from '../apollo/graphql/getIntegrations';
import getOrgRelationshipsQuery from '../apollo/graphql/getOrgRelationships';
import getPortfoliosQuery from '../apollo/graphql/getPortfolios';
import saveIntegrationMutation from '../apollo/graphql/saveIntegration';
import updateIntegrationCredentialsMutation from '../apollo/graphql/updateIntegrationCredentials';
import updateIntegrationSettingsMutation from '../apollo/graphql/updateIntegrationSettings';
import updateOrgMutation from '../apollo/graphql/updateOrg';
import updatePortfolioMutation from '../apollo/graphql/updatePortfolio';

const AdminPageWithAuth = ({apiClient, user, portfolio = {} }) => {
  const useApiQuery = (q, o) => useQuery(q, { client: apiClient, ...o });
  const useLazyApiQuery = (q, o) => useLazyQuery(q, { client: apiClient, ...o });
  const useApiMutation = (m, o) => useMutation(m, { client: apiClient, ...o });

  const orgId = user?.org?.id;
  const portfolioId = portfolio?.id;
  const refetchQueries = [
    {query: getIntegrationsQuery, variables: {portfolioId}},
    {query: getOrgRelationshipsQuery, variables: {portfolioId}},
    {query: getPortfoliosQuery},
  ];

  const qOrg = useApiQuery(getOrgRelationshipsQuery, {skip: !orgId, variables: {portfolioId}, fetchPolicy: 'network-only'});
  const qCurrencies = useApiQuery(getCurrenciesQuery);
  const [getExchangeAccounts, qExchangeAccounts] = useLazyApiQuery(getExchangeAccountsQuery, {fetchPolicy: 'network-only'});
  const [getAccounts, qAccounts] = useLazyApiQuery(getAccountsQuery, {fetchPolicy: 'network-only'});
  const qIntegrations = useApiQuery(getIntegrationsQuery, {skip: !portfolioId, variables: {portfolioId}});
  const qPortfolios = useApiQuery(getPortfoliosQuery);

  const [createRequestedIntegration, mCreateRequestedIntegration] = useApiMutation(createRequestedIntegrationMutation);
  const [saveIntegration, mSaveIntegration] = useApiMutation(saveIntegrationMutation);
  const [updateOrg, mUpdateOrg] = useApiMutation(updateOrgMutation);
  const [createAccounts, mCreateAccounts] = useApiMutation(createAccountsMutation);
  const [updateCredentials, mUpdateCredentials] = useApiMutation(updateIntegrationCredentialsMutation);
  const [updateIntegrationSettings, mUpdateIntegrationSettings] = useApiMutation(updateIntegrationSettingsMutation);
  const [createPortfolio, mCreatePortfolio] = useApiMutation(createPortfolioMutation);
  const [updatePortfolio, mUpdatePortfolio] = useApiMutation(updatePortfolioMutation);

  return (
    <AdminPage
      accountsQuery={                         cleanQuery(qAccounts)}
      createAccounts={                        variables => safeExecAsync(createAccounts, {variables: {portfolioId, ...variables}})}
      createAccountsMutation={                cleanQuery(mCreateAccounts)}
      createPortfolio={                       variables => safeExecAsync(createPortfolio, {variables, refetchQueries})}
      mCreatePortfolio={                      cleanQuery(mCreatePortfolio)}
      createRequestedIntegration={            variables => safeExecAsync(createRequestedIntegration, {variables: { portfolioId, ...variables }, refetchQueries})}
      createRequestedIntegrationMutation={    cleanQuery(mCreateRequestedIntegration)}
      currenciesQuery={                       cleanQuery(qCurrencies)}
      exchangeAccountsQuery={                 cleanQuery(qExchangeAccounts)}
      fetchAccounts={                         variables => safeExecAsync(getAccounts, {variables: {portfolioId, ...variables}})}
      fetchExchangeAccounts={                 variables => safeExecAsync(getExchangeAccounts, {variables: {portfolioId, ...variables}})}
      integrationsQuery={                     cleanQuery(qIntegrations)}
      orgQuery={                              cleanQuery(qOrg)}
      qPortfolios={                           cleanQuery(qPortfolios)}
      saveIntegration={                       variables => safeExecAsync(saveIntegration, {variables: {portfolioId, ...variables}, refetchQueries})}
      saveIntegrationMutation={               cleanQuery(mSaveIntegration)}
      updateIntegrationCredentials={          variables => safeExecAsync(updateCredentials, {variables, refetchQueries})}
      updateIntegrationCredentialsMutation={  cleanQuery(mUpdateCredentials)}
      updateIntegrationSettings={             variables => safeExecAsync(updateIntegrationSettings, {variables, refetchQueries})}
      updateIntegrationSettingsMutation={     cleanQuery(mUpdateIntegrationSettings)}
      updateOrg={                             variables => safeExecAsync(updateOrg, {variables, refetchQueries})}
      updateOrgMutation={                     cleanQuery(mUpdateOrg)}
      updatePortfolio={                       variables => safeExecAsync(updatePortfolio, {variables, refetchQueries})}
      mUpdatePortfolio={                      cleanQuery(mUpdatePortfolio)}
      user={                                  user}
      portfolio={                             portfolio}
    />
  );
}

const AdminPageContainer = ({user, ...props}) => user?.canViewAdmin
  ? <AdminPageWithAuth user={user} {...props} /> 
  : <ErrorPage type={ERROR_PERMISSION_DENIED} />

export default AdminPageContainer;
