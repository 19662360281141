import * as React from 'react';
import { InputBase } from '@material-ui/core';
import { makeStyles, fonts, BaseProps, ClassNameMap } from '@omniex/poms-ui';
import { Palette } from '@omniex/poms-ui/lib/types';
import clsx from 'clsx';
import { Size, sizeStyles } from './shared';

export type SimpleInputBaseClassKey = 'root' | 'input' | 'hasValue' | Size;

export type SimpleInputBaseProps = Pick<BaseProps<HTMLInputElement, SimpleInputBaseClassKey>, 'className' | 'classes'> & {
  icon?: JSX.Element;
  // label: string;
  size: keyof typeof fonts['displayFonts'];
  value?: string;
  iconColor?: keyof Palette;
  iconHoverColor?: keyof Palette;
}

const hasValue = (v: unknown) => {
  return v !== undefined && v !== '' && (Array.isArray(v) ? !!v.length : true);
}

type SimpleInputBaseStyleProps = Pick<SimpleInputBaseProps, 'size' | 'value'>;

type UseStyles = (props?: any) => ClassNameMap<SimpleInputBaseClassKey>
const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: 'flex',
    // ...fonts.displayFonts[size],
    // height: fonts.displayFonts[size]?.lineHeight,
    width: 'fit-content',
    maxWidth: '100%',
  },
  input: {
    color: palette.level2.main,
    // fontStyle: hasValue(value) ? 'normal' : 'italic',
    fontStyle: 'italic',
    '&:hover': {
      color: palette.level1.main,
    },
    // ...fonts.displayFonts[size],
    // height: fonts.displayFonts[size]?.lineHeight,
    minHeight: 'unset',
    width: 'auto',
    // '&&': {
    //   paddingRight: fonts.displayFonts[size]?.lineHeight,
    // }
  },
  hasValue: {
    fontStyle: 'normal',
  },
  ...sizeStyles as object,
}), { name: 'simple-inputbase' }) as UseStyles;

export const SimpleInputBase = React.forwardRef<HTMLDivElement, SimpleInputBaseProps>(function SimpleImputBase({
  classes,
  size,
  ...props
}, ref) {
  // const cls = useStyles({ size, value: props.value, });
  const cls = useStyles();
  return (
    <InputBase {...props} ref={ref} classes={{
      ...classes,
      root: clsx(cls.root, cls[size], classes?.root),
      input: clsx(cls.input, cls[size], hasValue(props.value) && cls.hasValue, classes?.input),
    }} />
  );
})

export default SimpleInputBase;
