import createSelector from 're-reselect';

import { orderBy } from '@omniex/poms-core/lib/utils/CollectionUtils';

export function getVenueOptions(venues, instrumentDisplayName) {
  if (!Array.isArray(venues) || venues.length === 0) return [];

  return orderBy(
    venues.reduce((venues, v) => {
      const instrumentConfiguration = instrumentDisplayName ? v.instrumentConfigurations.find(c => c?.instrument?.displayName === instrumentDisplayName) : undefined;
      if (
        !instrumentDisplayName ||
        instrumentConfiguration
      ) venues.push({
        label: v.name, 
        value: v.id, 
        baseAsset: instrumentConfiguration?.instrument?.baseAsset, 
        termAsset: instrumentConfiguration?.instrument?.termAsset,
        marginAsset: instrumentConfiguration?.instrument?.marginAsset,
      });
      return venues;
    }, []),
    v => v.label.toLowerCase()
  );
}

export default createSelector(
  (venues, instrumentDisplayName) => venues,
  (venues, instrumentDisplayName) => instrumentDisplayName,
  getVenueOptions
)((venues, instrumentDisplayName) => instrumentDisplayName || '');
