import gql from 'graphql-tag';

export default gql`
  mutation disableApiKey($id: ID!) {
    disableApiKey(id: $id) {
      active
      id
      key
      name
      org {
        id
      }
      timeCreated
      timeLastUpdated
    }
  }
`;
