import { shape, string } from 'prop-types';
import { Icon } from 'semantic-ui-react';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { HOME_CURRENCY, MARKET_DATA_SOURCE } from '../../constants/orgSettings';
import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './OrgSettingsComponent.copyText';

const COMPONENT_NAME = 'OrgSettingsComponent';

const StyledTable = styled('table')`
  min-width: 250px;

  .${COMPONENT_NAME}-entry {
    height: 40px;

    td:first-child {
      font-size: 12px;
      min-width: 150px;
      text-align: right;
    }

    div:last-child {
      text-align: right;
      margin-bottom: 10px;
      margin-left: 10px;
    }

    :last-child {
      div:last-child {
        margin-bottom: 0;
      }
    }
  }

  .${COMPONENT_NAME}-edit {
    color: ${colors.blue};
  }

  .${COMPONENT_NAME}-entry.selected {
    color: ${colors.selectedTextColor} !important;

    > td > i.${COMPONENT_NAME}-edit {
      color: ${colors.selectedTextColor} !important;
    }
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class OrgSettingsComponent extends PureComponent {
  static propTypes = {
    org: shape({
      id: string.isRequired,
      externalMarketDataSource: string.isRequired,
      homeCurrency: shape({
        id: string.isRequired,
        symbol: string.isRequired
      }),
      tz: string.isRequired
    })
  };

  render() {
    const marketDataSource = get(this.props, 'org.externalMarketDataSource') || ''
      .toLowerCase()
      .replace(/_\w/g, m => m[1].toUpperCase())
      .replace(/^[a-z]/, m => m.toUpperCase());

    const timeZone = get(this.props, 'org.tz').replace(/_/g, ' ');

    const { editingField, onClick } = this.props;

    return (
      <StyledTable>
        <tbody>
          <tr className={cn('entry')}>
            <td>{copyText.orgNameLabel}:</td>
            <td>{get(this.props, 'org.name')}</td>
          </tr>
          <tr className={cn(`entry${editingField === HOME_CURRENCY ? ' selected' : ''}`)}>
            <td>{copyText.homeCurrencyLabel}:</td>
            <td>{get(this.props, 'org.homeCurrency.symbol')}</td>
            <td><Icon name="edit" className={cn('edit')} onClick={() => onClick(HOME_CURRENCY)}/></td>
          </tr>
          <tr className={cn(`entry${editingField === MARKET_DATA_SOURCE ? ' selected' : ''}`)}>
            <td>{copyText.marketDataSourceLabel}:</td>
            <td>{marketDataSource}</td>
            <td><Icon name="edit" className={cn('edit')} onClick={() => onClick(MARKET_DATA_SOURCE)}/></td>
          </tr>
          <tr className={cn('entry')}>
            <td>{copyText.timeZoneLabel}:</td>
            <td>{timeZone}</td>
          </tr>
        </tbody>
      </StyledTable>
    );
  }
}
