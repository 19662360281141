import { createSelector } from 'reselect';

import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { keyBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import getCurrenciesKeyedById from './getCurrenciesKeyedById';

export function populateAllocations(settlements, currencies, accounts) {
  if (
    !Array.isArray(settlements) ||
    settlements.length === 0 ||
    !Array.isArray(currencies) ||
    currencies.length === 0 ||
    !Array.isArray(accounts) ||
    accounts.length === 0
  )
    return [];

  const accountsKeyedById = keyBy(accounts, 'id');
  const currenciesKeyedById = getCurrenciesKeyedById(currencies);

  return settlements.map(settlement => {
    return {
      ...settlement,
      allocations: settlement.allocations.map(allocation => ({
        ...allocation,
        currency:
          currenciesKeyedById[allocation.currency.id] || allocation.currency,
        fromAccount:
          accountsKeyedById[get(allocation, 'fromAccount.id')] ||
          allocation.fromAccount,
        toAccount:
          accountsKeyedById[get(allocation, 'toAccount.id')] ||
          allocation.toAccount
      }))
    };
  });
}

export default createSelector(
  (settlements, currencies, accounts) => settlements,
  (settlements, currencies, accounts) => currencies,
  (settlements, currencies, accounts) => accounts,
  populateAllocations
);
