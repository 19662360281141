import { createSelector } from 'reselect';
import { Decimal } from 'decimal.js';

import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { sortBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';

export function calculateMarketPrice(entriesGroupedByType) { //type might always be using BID -> add ASK
  const bidEntries = sortBy(get(entriesGroupedByType, 'BID', []), entry => -entry.price);
  const offerEntries = sortBy(get(entriesGroupedByType, 'OFFER', []), 'price');

  const highestBid =  new Decimal(get(bidEntries, '[0].price', 0));
  const lowestOffer = new Decimal(get(offerEntries, '[0].price', 0));

  const midpoint = highestBid.add(lowestOffer).dividedBy(new Decimal(2)).toNumber();

  return  midpoint || 0;
}

export default createSelector(
  entriesGroupedByType => entriesGroupedByType,
  entriesGroupedByType => calculateMarketPrice(entriesGroupedByType)
);
