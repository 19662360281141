const dashboardPagePortfolioBreakdownSectionCopy = {
  error: 'An error occurred while trying to load the portfolio breakdown. If this error continues to occur, please contact our support team.',
  errorMessage: 'Portfolio breakdown cannot be generated at this time.',
  loadingMessage: 'Loading....',
  portfolioMarketValuePrefix: 'Current',
  sectionTitle: 'Portfolio Breakdown',
  warningMessageNoPositions:
    'There are no positions associated with your portfolio at this time.'
};

export default dashboardPagePortfolioBreakdownSectionCopy;
