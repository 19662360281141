import * as React from 'react';
import { createTheme, ThemeProvider } from '@omniex/poms-ui/lib/themes';
import OTCSection from './OTCSection';
import { Org, BaseUser, Dictionary, PopulatedInstrument, Venue } from '../../../types';
import { ApolloClient } from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';

const theme = createTheme({
  overrides: {
    colors: {
      buy: '#1071BC',
      alert1: '#D92B2F',
      sell: '#ED7D50',
      affirm: '#43AE53',
    },
    fonts: {
      fontFamily: [
        'Lato',
        '"Helvetica Neue"',
        'sans-serif',
      ].join(',') as unknown as undefined,
    },
  },
}, {
  palette: {
    divider: '#E8E9E9',
  },
  overrides: {
    MuiScopedCssBaseline: {
      root: {
        backgroundColor: 'inherit',
      },
    },
  },
});

type OTCContainerProps = {
  apiClient?: ApolloClient<NormalizedCacheObject>
  buttonContainerRef: React.RefObject<React.ReactInstance>
  fixSenderCompID: Org['fixSenderCompID']
  instruments: PopulatedInstrument[]
  keyedInstruments: Dictionary<PopulatedInstrument>
  keyedVenues: Dictionary<Venue>
  portfolioId?: string
  refreshDataNonce?: string
  setInspectedOrder: (fixClOrdID?: string) => void
  takerApiClient?: ApolloClient<NormalizedCacheObject>
  user: BaseUser
  venues: Venue[]
}

const OTCContainer = ({
  apiClient,
  buttonContainerRef,
  fixSenderCompID,
  instruments,
  keyedInstruments,
  keyedVenues,
  portfolioId,
  refreshDataNonce,
  setInspectedOrder,
  takerApiClient,
  user,
  venues,
}: OTCContainerProps) => (
  <ThemeProvider theme={theme} scoped>
    <OTCSection
      apiClient={apiClient}
      buttonContainerRef={buttonContainerRef}
      fixSenderCompID={fixSenderCompID}
      instruments={instruments}
      keyedInstruments={keyedInstruments}
      keyedVenues={keyedVenues}
      portfolioId={portfolioId}
      refreshDataNonce={refreshDataNonce}
      setInspectedOrder={setInspectedOrder}
      takerApiClient={takerApiClient}
      user={user}
      venues={venues}
    />
  </ThemeProvider>
)

export default React.memo(OTCContainer);

