import { bool, func, number, object, shape, string } from 'prop-types';
import { Button, Form, Input } from 'semantic-ui-react';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { get, has } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import {
  isEmpty,
  isError,
  isNil
} from '@omniex/onx-common-js/lib/utils/LangUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './UpdatePositionForm.copyText';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/button.css');
require('@omniex/onx-common-ui/lib/semantic/css/form.css');
require('@omniex/onx-common-ui/lib/semantic/css/input.css');
require('@omniex/onx-common-ui/lib/semantic/css/label.css');

const COMPONENT_NAME = 'UpdatePositionForm';
const CUSTOM_ASSET_PREFIX = 'CUSTOM-';

// prettier-ignore
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: row-reverse;
  height: 90px;
  min-width: 280px;
  width: 100%;

  .${COMPONENT_NAME}-formWrapper {
    .field > label {
        height: 20px;
      }
  }

  .${COMPONENT_NAME}-saveButtonWrapper, .${COMPONENT_NAME}-cancelButtonWrapper {
    padding-top: 23px;
  }

  .${COMPONENT_NAME}-saveButtonWrapper {
    text-align: right;
  }

  .${COMPONENT_NAME}-saveButton, .disabled.button.${COMPONENT_NAME}-saveButton, ${COMPONENT_NAME}-saveButton:hover {
    margin: 0;
    background-color: ${colors.actionButtonBackgroundColor} !important;
    color: ${colors.actionButtonTextColor} !important;
  }

  .${COMPONENT_NAME}-cancelButton, .${COMPONENT_NAME}-cancelButton:hover {
    margin: 0;
    background-color: ${colors.cancelButtonBackgroundColor} !important;
    color: ${colors.cancelButtonTextColor} !important;
  }

  .disabled.input.${COMPONENT_NAME}-positionQuantity, .disabled.input.${COMPONENT_NAME}-price {
    opacity: 1 !important;

    > .label {
      opacity: 0.45;
      border: 1px solid ${colors.solidBorderColor};
    }
  }

  .${COMPONENT_NAME}-positionQuantity, .${COMPONENT_NAME}-price {
    > input {
      border: 1px solid ${colors.solidBorderColor};
    }

    > .label {
      opacity: 1;
      border: 1px solid ${colors.solidBorderColor};
    }
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class UpdatePositionForm extends PureComponent {
  static propTypes = {
    account: shape({
      id: string.isRequired,
      balanceAtCustodianAsReportedTotal: number.isRequired
    }),
    currentPrice: number,
    homeCurrency: shape({
      id: string.isRequired,
      symbol: string.isRequired
    }),
    loading: bool,
    updatePositionMutationError: object,
    updatePositionMutationProcessing: bool,
    updatePositionMutationResult: object,
    onSave: func
  };

  static defaultProps = { onSave: noop };

  state = getInitialState(this.props);

  static getDerivedStateFromProps(nextProps, prevState) {
    const stateChanges = {};

    if (isEmpty(prevState)) {
      return getInitialState(nextProps);
    }

    if (
      nextProps.updatePositionMutationProcessing === false &&
      !isError(nextProps.updatePositionMutationError) &&
      has(nextProps.updatePositionMutationResult, 'position')
    ) {
      const position = get(nextProps, 'updatePositionMutationResult.position');

      stateChanges.originalPrice = position.price;
      stateChanges.originalPositionQuantity = position.quantity;
    }

    return stateChanges;
  }

  render() {
    return (
      <StyledForm
        className={COMPONENT_NAME}
        autoComplete="off"
        size={this.props.size}>
        <Form.Group className={cn('formWrapper')}>
          <Form.Field>
            <label>{copyText.inputPlaceholder_positionQuantity}</label>
            <Input
              className={cn('positionQuantity')}
              name="positionQuantity"
              value={this.state.positionQuantity}
              onChange={this._handleChangeField}
            />
          </Form.Field>

          <Form.Field>
            <label>{copyText.inputPlaceholder_price}</label>
            <Input
              className={cn('price')}
              name="price"
              label={getAssetDisplayText(get(this.props, 'homeCurrency'))}
              labelPosition="right"
              value={this.state.price}
              onChange={this._handleChangeField}
            />
          </Form.Field>

          <Form.Field className={cn('saveButtonWrapper')}>
            <Button
              className={cn('saveButton')}
              disabled={
                !this._canSavePosition() ||
                this.props.loading ||
                this.props.processing
              }
              fluid
              loading={this.props.updatePositionMutationProcessing}
              onClick={this._handleSubmitPosition}>
              {copyText.saveButtonLabel}
            </Button>
          </Form.Field>

          <Form.Field className={cn('cancelButtonWrapper')}>
            <Button
              className={cn('cancelButton')}
              disabled={
                !this._canCancel() ||
                this.props.loading ||
                this.props.processing
              }
              fluid
              loading={this.props.updatePositionMutationProcessing}
              onClick={this._handleCancel}>
              {copyText.cancelButtonLabel}
            </Button>
          </Form.Field>
        </Form.Group>
      </StyledForm>
    );
  }

  _canCancel() {
    const {
      originalPositionQuantity,
      originalPrice,
      positionQuantity,
      price
    } = this.state;

    let result = true;

    if (
      originalPositionQuantity === parseFloat(positionQuantity) &&
      originalPrice === parseFloat(price)
    )
      result = false;

    return result;
  }

  _canSavePosition() {
    const {
      originalPositionQuantity,
      originalPrice,
      positionQuantity,
      price
    } = this.state;

    let result = true;

    if (isNil(positionQuantity) || isNil(price)) result = false;

    if (
      originalPositionQuantity === parseFloat(positionQuantity) &&
      originalPrice === parseFloat(price)
    )
      result = false;

    return result;
  }

  _handleCancel = event => {
    event.preventDefault();

    this.setState({
      positionQuantity: this.state.originalPositionQuantity,
      price: this.state.originalPrice
    });
  };

  _handleChangeField = (input, event) => {
    let { value } = input;
    const { name } = event;

    if (has(event, 'value')) {
      value = event.value;
    }

    if (['positionQuantity', 'price'].includes(name)) {
      value = value.replace(/[^\d.]/g, '');
    }

    if (
      !/^\d*\.?\d{0,8}$/.test(value) &&
      ['positionQuantity', 'price'].includes(name)
    ) {
      return;
    }

    let changes = { [name]: value };

    this.setState(changes);
  };

  _handleSubmitPosition = event => {
    event.preventDefault();

    const { account, positionQuantity, price } = this.state;

    const assetIdParsed = String(account.currency.id).replace(
      CUSTOM_ASSET_PREFIX,
      ''
    );

    this.props.onSave({
      accountId: account.id,
      assetId: assetIdParsed,
      positionQuantity,
      price
    });
  };
}

function getInitialState(props) {
  return {
    account: get(props, 'account'),
    originalPositionQuantity: get(
      props,
      'account.balanceAtCustodianAsReportedTotal',
      0
    ),
    originalPrice: get(props, 'currentPrice', 0),
    positionQuantity: get(props, 'account.balanceAtCustodianAsReportedTotal'),
    price: get(props, 'currentPrice')
  };
}
