import gql from 'graphql-tag';

export default gql`
  mutation updateOrg(
    $externalMarketDataSource: String
    $homeCurrencySymbol: String
  ) {
    org: updateOrg(
      externalMarketDataSource: $externalMarketDataSource
      homeCurrencySymbol: $homeCurrencySymbol
    ) {
      id
      externalMarketDataSource
      homeCurrency {
        id
        symbol
      }
    }
  }
`;
