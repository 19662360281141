import { bool, func, object } from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import RequestsPageIntegrationsSection from './RequestsPageIntegrationsSection';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');
require('@omniex/onx-common-ui/lib/semantic/css/popup.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const COMPONENT_NAME = 'RequestsPage';

const StyledDiv = styled('div')`
  height: 100%;
  overflow: hidden;

  .${COMPONENT_NAME}-sectionWrapper {
    padding-top: 20px;

    :last-child {
      margin-bottom: 60px;
    }
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class RequestsPage extends PureComponent {
  static propTypes = {
    activateIntegration: func,
    activateIntegrationMutationError: object,
    activateIntegrationMutationProcessing: bool,
    activateIntegrationMutationResult: object,
    blockRequestedIntegration: func,
    blockRequestedIntegrationMutationError: object,
    blockRequestedIntegrationMutationProcessing: bool,
    blockRequestedIntegrationMutationResult: object,
    clientAccountsListQueryData: object,
    clientAccountsListQueryError: object,
    clientAccountsListQueryLoading: bool,
    deleteIntegration: func,
    deleteIntegrationMutationError: object,
    deleteIntegrationMutationProcessing: bool,
    deleteIntegrationMutationResult: object,
    requestedIntegrationsQueryData: object,
    requestedIntegrationsQueryError: object,
    requestedIntegrationsQueryLoading: bool,
    verifiedIntegrationsQueryData: object,
    verifiedIntegrationsQueryError: object,
    verifiedIntegrationsQueryLoading: bool,
  };

  static defaultProps = {
    activateIntegration: noop,
    blockRequestedIntegration: noop,
    deleteIntegration: noop,
  };

  render() {
    return (
      <StyledDiv className={COMPONENT_NAME}>
          <main>
            <div className={cn('sectionWrapper')}>
              <RequestsPageIntegrationsSection
                activateIntegration={this.props.activateIntegration}
                activateIntegrationMutationError={
                  this.props.activateIntegrationMutationError
                }
                activateIntegrationMutationProcessing={
                  this.props.activateIntegrationMutationProcessing
                }
                activateIntegrationMutationResult={
                  this.props.activateIntegrationMutationResult
                }
                blockRequestedIntegration={this.props.blockRequestedIntegration}
                blockRequestedIntegrationMutationError={
                  this.props.blockRequestedIntegrationMutationError
                }
                blockRequestedIntegrationMutationProcessing={
                  this.props.blockRequestedIntegrationMutationProcessing
                }
                blockRequestedIntegrationMutationResult={
                  this.props.blockRequestedIntegrationMutationResult
                }
                clientAccountsListQueryData={
                  this.props.clientAccountsListQueryData
                }
                clientAccountsListQueryError={
                  this.props.clientAccountsListQueryError
                }
                clientAccountsListQueryLoading={
                  this.props.clientAccountsListQueryLoading
                }
                deleteIntegration={this.props.deleteIntegration}
                deleteIntegrationMutationError={
                  this.props.deleteIntegrationMutationError
                }
                deleteIntegrationMutationProcessing={
                  this.props.deleteIntegrationMutationProcessing
                }
                deleteIntegrationMutationResult={
                  this.props.deleteIntegrationMutationResult
                }
                requestedIntegrationsQueryData={
                  this.props.requestedIntegrationsQueryData
                }
                requestedIntegrationsQueryError={
                  this.props.requestedIntegrationsQueryError
                }
                requestedIntegrationsQueryLoading={
                  this.props.requestedIntegrationsQueryLoading
                }
                verifiedIntegrationsQueryData={
                  this.props.verifiedIntegrationsQueryData
                }
                verifiedIntegrationsQueryError={
                  this.props.verifiedIntegrationsQueryError
                }
                verifiedIntegrationsQueryLoading={
                  this.props.verifiedIntegrationsQueryLoading
                }
              />
            </div>
          </main>
      </StyledDiv>
    );
  }
}
