const portfolioPositionsCustodianTableCopy = {
  selfCustodyCustodian: 'Self Custody',
  tableHeaderCurrentValue: 'Market Value',
  tableHeaderCustodian: 'Custodian',
  tableHeaderEntryPrice: 'Entry Price',
  tableHeaderInstrument: 'Instrument',
  tableHeaderLiquidationPrice: 'Liquidation Price',
  tableHeaderLiquidationValue: 'Liquidation Value',
  tableHeaderMargin: 'Margin',
  tableHeaderMarkPrice: 'Mark Price',
  tableHeaderPrice: 'Price',
  tableHeaderQuantity: 'Quantity',
  tableHeaderUnrealizedPnL: 'Unrealized PnL (ROE%)',
  tableHeaderValue: 'Value',
};

export default portfolioPositionsCustodianTableCopy;
