import { Accordion, Icon, Label } from 'semantic-ui-react';
import { arrayOf, func, object, shape, string } from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import styled from 'react-emotion';

import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { isEmpty } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import AllocationStatus from '@omniex/onx-poms-entity-helpers/lib/enums/AllocationStatus';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import ConfirmAllocationsTable from '../components/ConfirmAllocationsTable';
import ConfirmSettlementsBlotterTable from '../components/ConfirmSettlementsBlotterTable';
import copyText from './ConfirmSettlementsTable.copyText';
import { getNetSettlements } from '../../utils/SettlementUtils';
import getSettlementsGroupedByCounterParty from '../../selectors/getSettlementsGroupedByCounterParty';
import NetFlow from '../components/NetFlow';
import populateUnsettledFills from '../../selectors/populateUnsettledFills';
import SettlementStatus from '@omniex/onx-poms-entity-helpers/lib/enums/SettlementStatus';
import Table from '@omniex/onx-common-ui/lib/semantic/react/Table';

const COMPONENT_NAME = 'ConfirmSettlementsTable';

const ACTIVE_SETTLEMENTS = 'active';
const COMPLETED_SETTLEMENTS = 'completed';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/label.css');
require('@omniex/onx-common-ui/lib/semantic/css/table.css');
require('@omniex/onx-common-ui/lib/semantic/css/accordion.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const StyledTable = styled(Table)`
  .${COMPONENT_NAME}-accordionTitle {
    background-color: ${colors.transparentBlack};
    border-top: 1px solid ${colors.borderColor};
    padding-left: 14px !important;
    display: flex;

    :first-child {
      border-top: 0;
    }
  }

  .${COMPONENT_NAME}-accordionContent {
    border-top: 1px solid ${colors.borderColor};
    padding-left: 28px !important;
    padding-right: 28px !important;
  }

  .${COMPONENT_NAME}-countLabel {
    background-color: #aaa;
    color: white;
    line-height: 0.55em !important;
    margin-left: 1em;
    min-height: 0 !important;
    min-width: 0 !important;
    padding: 0.2em 0.5em !important;
    vertical-align: middle;
  }

  .${COMPONENT_NAME}-titleLabel {
    min-width: 300px !important;
  }

  .${COMPONENT_NAME}-headerCell {
    background-color: #e6e6e6 !important;
    border-bottom: 0.5px solid ${colors.internalBorderColor};
    border-top: 1px solid ${colors.internalBorderColor};
    height: 35px;
    padding-left: 7px !important;
  }

  .${COMPONENT_NAME}-counterPartyWrapper {
    padding: 0 !important;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class ConfirmSettlementsTable extends PureComponent {
  static propTypes = {
    currencies: arrayOf(object),
    fixSenderCompID: string,
    instruments: arrayOf(object),
    org: shape({
      id: string.isRequired,
      fixSenderCompID: string.isRequired
    }),
    settlements: arrayOf(
      shape({
        allocations: arrayOf(
          shape({
            fixFromCompID: string.isRequired,
            status: string.isRequired
          }).isRequired
        ),
        status: string.isRequired
      })
    ),
    user: object.isRequired,
    onAdvance: func
  };

  static defaultProps = { onAdvance: noop };

  state = {};

  static getDerivedStateFromProps(nextProps, prevState) {
    if (isEmpty(prevState)) return getInitialState(nextProps);

    let stateChanges = {};

    return stateChanges;
  }

  render() {
    let [completedSettlements, activeSettlements] = (this.props.settlements || []).reduce(
      ([completedSettlements, activeSettlements], settlement) => {
        const completed =
          settlement.status === SettlementStatus.COMPLETED ||
          settlement.allocations.every(allocation => {
            if (allocation.status === AllocationStatus.NOT_STARTED) {
              return false;
            }

            return (
              allocation.status === AllocationStatus.CLEARED ||
              (allocation.fixFromCompID === this.props.org.fixSenderCompID &&
                allocation.status === AllocationStatus.INITIATED)
            );
          });

        return completed
          ? [[...completedSettlements, settlement], activeSettlements]
          : [completedSettlements, [...activeSettlements, settlement]];
      },
      [[], []]
    );

    const activeSettlementsCounterPartyMap = getSettlementsGroupedByCounterParty(
      activeSettlements,
      get(this.props, 'org.id')
    );

    const completedSettlementsCounterPartyMap = getSettlementsGroupedByCounterParty(
      completedSettlements,
      get(this.props, 'org.id')
    );

    return (
      <StyledTable>
        <Table.Body>
          <Table.Row
            onClick={this._handleClickExpandRow.bind(this, ACTIVE_SETTLEMENTS)}>
            <Table.Cell className={cn('headerCell')}>
              <Icon
                name={`triangle ${
                  this.state.expandedRows.includes(ACTIVE_SETTLEMENTS)
                    ? 'down'
                    : 'right'
                }`}
              />
              <span>{copyText.tableHeaderActiveSettlements}</span>
              <Label size="small" circular className={cn('countLabel')}>
                {activeSettlements.length}
              </Label>
            </Table.Cell>
          </Table.Row>
          {this.state.expandedRows.includes(ACTIVE_SETTLEMENTS) ? (
            <Table.Row>
              <Table.Cell className={cn('counterPartyWrapper')}>
                <Accordion fluid>
                  {this._renderAccordionContent(
                    ACTIVE_SETTLEMENTS,
                    activeSettlementsCounterPartyMap
                  )}
                </Accordion>
              </Table.Cell>
            </Table.Row>
          ) : null}
          <Table.Row
            onClick={this._handleClickExpandRow.bind(
              this,
              COMPLETED_SETTLEMENTS
            )}>
            <Table.Cell className={cn('headerCell')}>
              <Icon
                name={`triangle ${
                  this.state.expandedRows.includes(COMPLETED_SETTLEMENTS)
                    ? 'down'
                    : 'right'
                }`}
              />
              <span>{copyText.tableHeaderCompletedSettlements}</span>
              <Label size="small" circular className={cn('countLabel')}>
                {completedSettlements.length}
              </Label>
            </Table.Cell>
          </Table.Row>
          {this.state.expandedRows.includes(COMPLETED_SETTLEMENTS) ? (
            <Table.Row>
              <Table.Cell className={cn('counterPartyWrapper')}>
                <Accordion fluid>
                  {this._renderAccordionContent(
                    COMPLETED_SETTLEMENTS,
                    completedSettlementsCounterPartyMap
                  )}
                </Accordion>
              </Table.Cell>
            </Table.Row>
          ) : null}
        </Table.Body>
      </StyledTable>
    );
  }

  _renderAccordionContent(
    internalSettlementStatus,
    settlementsGroupedByCounterParty
  ) {
    return Object.keys(settlementsGroupedByCounterParty).map(counterPartyId => {
      const selectedActiveCounterParty = !this.state.collapsedActiveCounterPartyIds.includes(
        counterPartyId
      );
      const selectedCompletedCounterParty = !this.state.collapsedCompletedCounterPartyIds.includes(
        counterPartyId
      );
      const settlements = settlementsGroupedByCounterParty[counterPartyId];

      const {currencies, fixSenderCompID} = this.props;
      const net = getNetSettlements(settlements, fixSenderCompID, currencies);

      return (
        <Fragment key={counterPartyId}>
          <Accordion.Title
            className={cn('accordionTitle')}
            active={
              internalSettlementStatus === ACTIVE_SETTLEMENTS
                ? selectedActiveCounterParty
                : selectedCompletedCounterParty
            }
            index={counterPartyId}
            onClick={
              internalSettlementStatus === ACTIVE_SETTLEMENTS
                ? this._handleToggleActiveCounterParties
                : this._handleToggleCompletedCounterParties
            }>
            <Icon
              name={`triangle ${
                internalSettlementStatus === ACTIVE_SETTLEMENTS
                  ? selectedActiveCounterParty
                    ? 'down'
                    : 'right'
                  : selectedCompletedCounterParty
                  ? 'down'
                  : 'right'
              }`}
            />
            <div className={cn('titleLabel')}>
              <span>
                {get(this.props, 'org.id') ===
                get(settlements, '[0].initiatorOrg.id')
                  ? settlements[0].respondentOrg.name
                  : settlements[0].initiatorOrg.name}
              </span>
              <Label size="small" circular className={cn('countLabel')}>
                {settlements.length}
              </Label>
          </div>
          <NetFlow net={net} />
          </Accordion.Title>
          <Accordion.Content
            className={cn('accordionContent')}
            active={
              internalSettlementStatus === ACTIVE_SETTLEMENTS
                ? selectedActiveCounterParty
                : selectedCompletedCounterParty
            }>
            <ConfirmSettlementsBlotterTable
              renderExpandedContent={this._renderConfirmAllocationsTable}
              fixSenderCompID={get(this.props, 'org.fixSenderCompID')}
              settlements={settlements}
            />
          </Accordion.Content>
        </Fragment>
      );
    });
  }

  _renderConfirmAllocationsTable = settlement => {
    settlement.fills = populateUnsettledFills(
      settlement.fills,
      this.props.currencies,
      this.props.instruments
    );

    return (
      <ConfirmAllocationsTable
        fixSenderCompID={get(this.props, 'org.fixSenderCompID')}
        org={get(this.props, 'org')}
        settlement={settlement}
        user={this.props.user}
        onAdvance={this.props.onAdvance}
      />
    );
  };

  _handleToggleActiveCounterParties = (event, titleProps) => {
    const counterPartyId = titleProps.index;

    this.setState(currentState => ({
      collapsedActiveCounterPartyIds: currentState.collapsedActiveCounterPartyIds.includes(
        counterPartyId
      )
        ? currentState.collapsedActiveCounterPartyIds.filter(
            n => counterPartyId !== n
          )
        : [...currentState.collapsedActiveCounterPartyIds, counterPartyId]
    }));
  };

  _handleToggleCompletedCounterParties = (event, titleProps) => {
    const counterPartyId = titleProps.index;

    this.setState(currentState => ({
      collapsedCompletedCounterPartyIds: currentState.collapsedCompletedCounterPartyIds.includes(
        counterPartyId
      )
        ? currentState.collapsedCompletedCounterPartyIds.filter(
            n => counterPartyId !== n
          )
        : [...currentState.collapsedCompletedCounterPartyIds, counterPartyId]
    }));
  };

  _handleClickExpandRow = rowId => {
    this.setState(currentState => ({
      expandedRows: currentState.expandedRows.includes(rowId)
        ? currentState.expandedRows.filter(row => row !== rowId)
        : [...currentState.expandedRows, rowId]
    }));
  };
}

function getInitialState(props) {
  let [completedSettlements, activeSettlements] = get(
    props,
    'settlements',
    []
  ).reduce(
    ([completedSettlements, activeSettlements], settlement) => {
      const completed =
        settlement.status === SettlementStatus.COMPLETED ||
        settlement.allocations.every(allocation => {
          if (allocation.status === AllocationStatus.NOT_STARTED) {
            return false;
          }

          if (
            allocation.status === AllocationStatus.CLEARED ||
            (allocation.fixFromCompID === props.org.fixSenderCompID &&
              allocation.status === AllocationStatus.INITIATED)
          ) {
            return true;
          }
          return false;
        });

      return completed
        ? [[...completedSettlements, settlement], activeSettlements]
        : [completedSettlements, [...activeSettlements, settlement]];
    },
    [[], []]
  );

  const activeSettlementsCounterPartyMap = getSettlementsGroupedByCounterParty(
    activeSettlements,
    get(props, 'org.id')
  );

  const completedSettlementsCounterPartyMap = getSettlementsGroupedByCounterParty(
    completedSettlements,
    get(props, 'org.id')
  );

  return {
    expandedRows: [ACTIVE_SETTLEMENTS],
    collapsedActiveCounterPartyIds:
      Object.keys(activeSettlementsCounterPartyMap).length === 1
        ? []
        : Object.keys(activeSettlementsCounterPartyMap).map(
            counterPartyId => counterPartyId
          ),
    collapsedCompletedCounterPartyIds:
      Object.keys(completedSettlementsCounterPartyMap).length === 1
        ? []
        : Object.keys(completedSettlementsCounterPartyMap).map(
            counterPartyId => counterPartyId
          )
  };
}
