import { useCallback, useMemo, useState } from 'react'

export type SortOrder = 'ASC' | 'DESC'

type SortHook = {
  selection: {
    id: string
    direction: SortOrder
  }
  queryArgs: {
    id: string
    direction: SortOrder
  }
  handleSort: (thisId: string, thisDirection?: SortOrder) => void
}

export function useSort(defaultId: string, defaultDirection: SortOrder = 'DESC'): SortHook {

  const [direction, setDirection] = useState<SortOrder>(defaultDirection)
  const [id, setId] = useState<string>(defaultId)

  const handleSort = useCallback((thisId: string, thisDirection: SortOrder = 'DESC') => {
    if (thisId === id) {
      const nextDir = direction === 'DESC' ? 'ASC' : 'DESC'
      setDirection(nextDir)
    } else {
      setId(thisId)
      setDirection(thisDirection)
    }
  }, [direction, id, setDirection, setId])

  const queryArgs = useMemo(() => ({ direction, id }), [direction, id])

  return { selection: { id, direction }, queryArgs, handleSort }
}