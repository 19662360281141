import OrgVenueRelationshipStatus from '@omniex/onx-poms-entity-helpers/lib/enums/OrgVenueRelationshipStatus';

const otcVenueRelationshipsTableCopy = {
  [`statusLabel_${OrgVenueRelationshipStatus.CONFIRMED}`]: 'Verified',
  [`statusLabel_${OrgVenueRelationshipStatus.PENDING}`]: 'Pending Verification',
  tableHeaderStatus: 'Status',
  tableHeaderName: 'Name',
  warningMessage:
    'There are no OTC venues associated with your organization at this time.'
};

export default otcVenueRelationshipsTableCopy;
