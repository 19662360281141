import * as React from 'react';
import { ROW_HEIGHT, TABLE_HEADER_HEIGHT } from './shared';
import { getOrderColumns } from './multigridColumns';
import { Order, SortParams, Filter, FilterMap } from '../../../types';
import InfiniteMultiGrid from '../multigrid/InfiniteMultiGrid';
import { MultiGridHandleSortParams } from '../multigrid';
import { MultiGrid } from 'react-virtualized';
import User from '../../../utils/AuthUtils';

type OrderBlotterTableProps = {
  cancelContainer: React.RefObject<HTMLDivElement>
  checkIsOrderCancellable: (order: Order) => boolean
  fetchNextPage: (indices: { startIndex: number, stopIndex: number }) => Promise<void>
  filters: FilterMap
  gridRef: React.RefObject<MultiGrid>
  handleFocusOrder: (rowData: Order) => void
  handleSort?: (params: MultiGridHandleSortParams) => void
  hasOrgEnabledCustomId: boolean
  height: number
  orders: Array<Order>
  ordersCount: number
  ordersQueryLoading: boolean
  requestCancel: (id: string) => Promise<void>
  setFilter: (c: string) => (f: Filter) => void
  sortParams: SortParams
  hasOrdersFromMultiPortfolios: boolean
  user: User
}

const OrderBlotterTable: React.FC<OrderBlotterTableProps> = ({
  cancelContainer,
  checkIsOrderCancellable,
  fetchNextPage,
  filters,
  gridRef,
  handleFocusOrder,
  handleSort,
  hasOrgEnabledCustomId,
  height,
  orders,
  ordersCount,
  ordersQueryLoading,
  requestCancel,
  setFilter,
  sortParams,
  hasOrdersFromMultiPortfolios,
  user
}) => {
  const columns = React.useMemo(() =>
    getOrderColumns(
      cancelContainer,
      checkIsOrderCancellable,
      (order) => requestCancel(order.id),
      handleFocusOrder,
      hasOrgEnabledCustomId,
      hasOrdersFromMultiPortfolios,
      user
    ),
    [cancelContainer, checkIsOrderCancellable, handleFocusOrder, hasOrgEnabledCustomId, requestCancel, hasOrdersFromMultiPortfolios, user]
  );

  return (
    <InfiniteMultiGrid
      columns={columns}
      data={orders}
      fixedColumnCount={1}
      fixedRowCount={1}
      headerHeight={TABLE_HEADER_HEIGHT}
      height={height}
      filters={filters}
      setFilter={setFilter}
      isNextPageLoading={ordersQueryLoading}
      loadNextPage={fetchNextPage}
      onSort={handleSort}
      onCellDoubleClick={({ rowIndex }) => handleFocusOrder(orders[rowIndex])}
      remoteRowCount={ordersCount}
      ref={gridRef}
      rowHeight={ROW_HEIGHT}
      sortBy={sortParams.orderParameter}
      sortDirection={sortParams.orderDirection}
    />
  )
};

export default React.memo(OrderBlotterTable);
