import { bool, func, object } from 'prop-types';
import { CSVLink } from 'react-csv';
import { Icon, Loader, Menu, Segment } from 'semantic-ui-react';
import { Input } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from '../../ext/moment'; // NOTE: required by react-datepicker
import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css'
import styled from 'react-emotion';

import { get, has } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getLocalDate } from '../../utils/TimeUtils.js';
import { isEmpty } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './DashboardPageCorrelationMatrixSection.copyText';
import CorrelationMatrixComponent from '../components/CorrelationMatrixComponent';
import Dropdown from '@omniex/onx-common-ui/lib/react-select/Dropdown';
import ErrorBoundary from '../components/ErrorBoundary';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/segment.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const COMPONENT_NAME = 'DashboardPageCorrelationMatrixSection';

const DATE_INTERVAL_90_DAYS = 90;
const DATE_INTERVAL_180_DAYS = 180;
const DATETIME_PICKER_FORMAT = 'yyyy-MM-dd';
const DATETIME_PICKER_MIN_DATE = '2017-01-01';

const rangeOptions = [
  {
    value: DATE_INTERVAL_90_DAYS,
    label: '90 Days'
  },
  {
    value: DATE_INTERVAL_180_DAYS,
    label: '180 Days'
  }
];

// NOTE: temporarily changing default to 180 days to alleviate an issue with data
// for traditional indices (VIX, etc) failing to load when the interval is 90 days
const DEFAULT_RANGE = DATE_INTERVAL_180_DAYS;
const DEFAULT_RANGE_OPTION = rangeOptions[1];

// prettier-ignore
const StyledSection = styled('section')`
  .${COMPONENT_NAME}-menuItem {
    flex: 0 1 auto !important;
    flex-wrap: wrap !important;
    min-width: 250px;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  .${COMPONENT_NAME}-csvLink {
    color: ${colors.black};
  }

  .${COMPONENT_NAME}-selection {
    margin: 0 10px;
  }

  .${COMPONENT_NAME}-selectionDate {
    input {
      font-size: 0.8em !important;
      margin: 0 10px !important;
    }
  }

  .${COMPONENT_NAME}-rangeInput {
    margin: 0 10px !important;
    width: 120px;
  }

  .range__control {
    height: 35px;
    min-height: 35px;
  }

  .${COMPONENT_NAME}-componentWrapper {
    height: 351px;

    &.withScroll {
      overflow: auto;
    }
  }

  .ui.left.icon.input {
    width: 140px;
  }

  .react-datepicker__input-container {
    width: 100%;

    .input {
      height: 35px;
    }
  }

  .react-datepicker__time-list {
    padding: 0;
  }

  .react-datepicker__day-names, .react-datepicker__header, .react-datepicker__time-container, .react-datepicker__triangle, .react-datepicker__year-dropdown, .react-datepicker {
    border-color: ${colors.borderColor};
  }

  .react-datepicker__header {
    background-color: ${colors.transparentBlack};
  }

  .react-datepicker__close-icon::after, .react-datepicker__day--selected, .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: ${colors.blue};
  }

  .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before {
    border-bottom-color: ${colors.borderColor};
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class DashboardPageCorrelationMatrixSection extends Component {
  static propTypes = {
    currencyStatisticsQueryData: object,
    currencyStatisticsQueryLoading: bool,
    fetchCurrencyStatistics: func,
    recentInputHistoryQueryData: object,
    updateRecentInputHistory: func
  };

  static defaultProps = {
    fetchCurrencyStatistics: noop,
    updateRecentInputHistory: noop
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (isEmpty(prevState)) return getInitialState(nextProps);

    const stateChanges = {};

    if (
      has(nextProps, 'recentInputHistoryQueryData.inputHistory') &&
      !prevState.hasInputChanged
    ) {
      const { dateClosed } = populateRecentInputHistory(nextProps);

      stateChanges.dateClosed = dateClosed;
    }

    return stateChanges;
  }

  state = {};

  render() {
    const loading = this.props.currencyStatisticsQueryLoading;

    return (
      <StyledSection className={COMPONENT_NAME}>
        <Menu attached="top">
          <Menu.Item
            content={copyText.sectionTitle}
            header
            icon="grid layout"
          />
          <Menu.Item className={cn('menuItem')}>
            <DatePicker
              customInput={
                <Input
                  name="datestamp"
                  icon="calendar"
                  iconPosition="left"
                  required
                />
              }
              dateFormat={DATETIME_PICKER_FORMAT}
              disabledKeyboardNavigation
              disabled={this.props.currencyStatisticsQueryLoading}
              maxDate={new Date()}
              minDate={new Date(DATETIME_PICKER_MIN_DATE)}
              placeholderText={copyText.inputPlaceholder_datestamp}
              selected={
                this.state.dateClosed
                  ? getLocalDate(this.state.dateClosed)
                  : undefined
              }
              showYearDropdown
              onChange={this._handleChangeDatestamp}
            />
            <Dropdown
              className={cn('rangeInput')}
              classNamePrefix="range"
              name="range"
              isDisabled={this.props.currencyStatisticsQueryLoading}
              isSearchable={false}
              options={rangeOptions}
              placeholder={copyText.inputPlaceholder_range}
              value={this.state.rangeOption}
              onChange={this._handleChangeField}
            />
          </Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item
              content={
                <CSVLink
                  className={cn('csvLink')}
                  data={getCsvData(
                    get(
                      this.props,
                      'currencyStatisticsQueryData.currencyStatistics',
                      []
                    )
                  )}
                  filename={getCsvFileName(
                    this.state.dateClosed,
                    this.state.range
                  )}
                  headers={getCsvHeaders()}>
                  <Icon name="download" />
                </CSVLink>
              }
            />
          </Menu.Menu>
        </Menu>
        <Segment attached="bottom">
          <div className={cn('componentWrapper withScroll')}>
            {loading ? (
              <Loader active content={copyText.loadingMessage} size="medium" />
            ) : (
              <ErrorBoundary message={copyText.error}>
                <CorrelationMatrixComponent currencyStatisticsQueryData={this.props.currencyStatisticsQueryData} />
              </ErrorBoundary>
            )}
          </div>
        </Segment>
      </StyledSection>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.dateClosed !== this.state.dateClosed) {
      this.props.fetchCurrencyStatistics({
        window: this.state.range,
        dateClosed: this.state.dateClosed
      });
    }
  }

  _handleClickConfigIcon = () => {
    this.setState(currentState => ({
      showConfig: !currentState.showConfig
    }));
  };

  _handleChangeDatestamp = dateClosed => {
    dateClosed = moment(dateClosed).format('YYYY-MM-DD');

    this.props.updateRecentInputHistory({
      inputs: [
        {
          key: `${COMPONENT_NAME}_dateClosed`,
          value: dateClosed
        }
      ]
    });

    this.setState({
      dateClosed,
      hasInputChanged: true
    });
  };

  _handleChangeField = (field, event) => {
    let { value } = field;
    const { name } = event;

    let changes = { [name]: value };

    if (changes.range) {
      changes = {
        ...changes,
        rangeOption: field
      };
    }

    this.setState(changes, () => {
      this.props.fetchCurrencyStatistics({
        window: this.state.range,
        dateClosed: this.state.dateClosed
      });
    });
  };
}

function getInitialState(props) {
  return { range: DEFAULT_RANGE, rangeOption: DEFAULT_RANGE_OPTION };
}

function getCsvData(data) {
  if (!data) return [];

  return data.map(datum => ({
    baseSymbol: datum.baseSymbol,
    correlation: datum.correlation,
    covariance: datum.covariance,
    dateClosed: datum.timeClosed.substring(0, 10),
    termSymbol: datum.termSymbol
  }));
}

function getCsvFileName(dateClosed, dateWindow) {
  if (!dateClosed) return null;

  return `CorrelationMatrix_${moment(dateClosed).format(
    'YYYYMMDD'
  )}_${dateWindow}_days.csv`.replace(/\s/g, '-');
}

function getCsvHeaders() {
  return [
    'baseSymbol',
    'correlation',
    'covariance',
    'dateClosed',
    'termSymbol'
  ].map(key => ({
    key,
    label: copyText[`csvHeader_${key}`]
  }));
}

function populateRecentInputHistory(props) {
  return {
    dateClosed:
      moment().format('YYYY-MM-DD') ||
      get(
        props,
        `recentInputHistoryQueryData.inputHistory.${COMPONENT_NAME}_dateClosed[0]`
      )
  };
}
