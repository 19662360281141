import gql from 'graphql-tag';

const getOrderNotificationsSince = gql`
  query getOrderNotificationsSince($time: String!, $limit: Int, $offset: Int) {
    getOrderNotificationsSince(time: $time, limit: $limit, offset: $offset) {
      count
      notifications {
        id
        reason
        text
        timeCreated
        venue {
          id
          name
        }
        order {
          id
          algorithmStrategy
          feature
          fixClOrdID
          fixQuoteID
          instrument {
            id
            displayName
          }
          side
          specifiedQuantity
        }
      }
    }
  }
`;

export default getOrderNotificationsSince;
