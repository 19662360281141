import { format as formatNumber } from '@omniex/onx-common-js/lib/utils/NumberUtils';
import { get, has } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { isEmpty, isNil } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { pluralize } from '@omniex/onx-common-js/lib/utils/StringUtils';
import AssetType from '@omniex/poms-core/lib/enums/AssetType';
import moment from 'moment-timezone';
import { clamp } from './MathUtils';

export const FMT_STR_CRYPTO = '0,0.00000000';
export const FMT_STR_FIAT = '0,0.00';
export const FMT_STR_FUTURE = '0,0.0000';

export function displayInHomeCurrency(v, symbol) {
  if (['EUR', 'JPY', 'USD'].includes(symbol)) return `${getFiatSymbol(symbol)}${v}`
  else if (symbol === 'CHF')                  return `CHF ${v}`
  else                                        return (symbol ? `${v} ${symbol}` : `${v}`)
};

export function getCounterCurrency(order) {
  if (!has(order, 'instrument') || !has(order, 'specifiedCurrency.id')) return {};

  return [
    get(order, 'instrument.baseAsset'),
    get(order, 'instrument.termAsset'),
  ].find(currency => currency.id !== get(order, 'specifiedCurrency.id'));
}

export function getDateFormat(ts) {
  let m = moment(ts, moment.ISO_8601);
  return m.tz(moment.tz.guess()).format('MM/DD/YYYY, HH:mm:ss zz');
}

export function getDateFormatSimple(ts, showYearIfPast = false) {
  let m = moment(ts, moment.ISO_8601);
  if (!showYearIfPast || m.year() >= moment().year()) return m.tz(moment.tz.guess()).format('MMM D, HH:mm:ss');
  return m.tz(moment.tz.guess()).format('ll');
}

export const getDHMString = (duration, unit) => {
  const d = moment.duration(duration, unit);
  return [
    d.days() && pluralize('day', d.days(), true),
    d.hours() && pluralize('hour', d.hours(), true),
    d.minutes() && pluralize('minute', d.minutes(), true),
  ].filter(s => s).join(', ');
}

export function getDisplayFormattedNum(num, min = 0) { //min is the minimum number of trailing digits
  if (isNil(num) || isNaN(num)) return '';

  const re = /^-?[0-9]+\.?(?<frac>[0-9]*)e(?<exp>[-+]?[0-9]+)$/;
  const f = n => n.toExponential().match(re).groups;
  const countDigits = (n, frac, exp) => ({ frac, exp } = f(n), Math.max(min, frac.length - exp)); // eslint-disable-line no-sequences
  const format = `0,0.${'0'.repeat(countDigits(num))}`;

  return formatNumber(num, format);
}

export const getFiatSymbol = (s) => ({
  EUR: '€',
  JPY: '¥',
  USD: '$'
}[s] || s)

export function getLastNChars(str, n) {
  if (str.length <= n) return str;
  return '...'.concat(str.substring(str.length - n));
}

export function getNumberFormat(assetType) {
  switch (assetType) {
    case AssetType.CRYPTO: return FMT_STR_CRYPTO;
    case AssetType.FIAT: return FMT_STR_FIAT;
    case AssetType.FUTURE: return FMT_STR_FUTURE;
    default: return FMT_STR_CRYPTO;
  }
}

/**
 * the function below is too delicious to be removed completely, so it
 * is preserved here as a comment to allow the whole of posterity to
 * banquet upon it as i have
 */

// export function getPriceFormat(price) {
//   if (!price) return '0.00';

//   const exponent = parseInt(
//     price
//       .toExponential()
//       .toString()
//       .slice(-2),
//     10
//   );

//   if (exponent > 1) return '0,0.00';

//   let format = '0.00000000000';

//   for (let i = -7; i < exponent; i++) {
//     format = format.substring(0, format.length - 1);
//   }

//   return format;
// }

const pow = n => Math.floor(Math.log10(Math.abs(n))) // returns the number 10 is raised to when n is written as an exponential, e.g. pow(123) => 2
export const getPriceFormat = p => !p || p >= 100 ? '0,0.00' : `0.00${'0'.repeat(clamp(2-pow(p), 1, 9))}`

export function getReasonCanceledUrl(baseUrl, reasonCanceled) {
  if (isNil(reasonCanceled) || isEmpty(reasonCanceled)) return baseUrl;
  let anchor = reasonCanceled.toLowerCase();
  let newUrl = baseUrl.concat(anchor);
  return newUrl;
}

export function getTimeOnly(ts) {
  let m = moment(ts, moment.ISO_8601);
  return m.tz(moment.tz.guess()).format('HH:mm:ss zz');
}

export const getYMDHMString = (duration, unit) => {
  const d = moment.duration(duration, unit);
  return [
    d.years() && pluralize('year', d.years(), true),
    d.months() && pluralize('month', d.months(), true),
    d.days() && pluralize('day', d.days(), true),
    d.hours() && pluralize('hour', d.hours(), true),
    d.minutes() && pluralize('minute', d.minutes(), true),
  ].filter(s => s).join(', ');
}

export const formatPrice = p => formatNumber(p, getPriceFormat(p));

export const truncateTrailingZeroes = (number) => {
  if (Number.isNaN(number)) {
    return number; 
  } else {
    // 1.234000 -> 1.234
    return Number(number).toString();
  }
}

export const formatNumberWithoutTrailingZeroes = (number, format, minSignificantDecimals) => {
  const formattedNumber = formatNumber(number, format);
  const decimalPointIndex = formattedNumber.lastIndexOf('.');
  let trailingZeroCount = 0; 
  if (decimalPointIndex > -1) {
    for (let i = formattedNumber.length - 1; i > decimalPointIndex + minSignificantDecimals; i--) {
      const char = formattedNumber[i];
      if (char === '0') {
        trailingZeroCount++;
      } else if (char === '.') {
        trailingZeroCount++;
        break;
      } else {
        break;
      }
    }; 
  }

  return formattedNumber.slice(0, formattedNumber.length - trailingZeroCount)
}

const displayUtils = {
  displayInHomeCurrency,
  getCounterCurrency,
  getDateFormat,
  getDateFormatSimple,
  getDHMString,
  getDisplayFormattedNum,
  getLastNChars,
  getNumberFormat,
  getPriceFormat,
  getReasonCanceledUrl,
  getTimeOnly,
  getYMDHMString,
  formatPrice,
  truncateTrailingZeroes,
  formatNumberWithoutTrailingZeroes,
};

export default displayUtils;
