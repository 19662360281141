import * as React from 'react';
import { Select, SelectProps, MenuItem } from '@material-ui/core';
import { MenuDownIcon } from '@omniex/poms-ui/lib/components/Icon';
import SimpleInputBase from './SimpleInputBase';
import { makeStyles, fonts, BaseProps } from '@omniex/poms-ui';
import clsx from 'clsx';
import { IdentifiableWithName } from '../../../types';

export type SimpleSelectorClassKey = 'root' | 'select' | 'icon' | 'input';
export type SimpleSelectorProps<T extends unknown = string> = SelectProps & Omit<BaseProps<HTMLInputElement, SimpleSelectorClassKey>, 'onChange'> & {
  emptyLabel: string;
  multiple?: boolean;
  onChange: (newValue: T) => void;
  onOpen: () => void;
  options?: Array<IdentifiableWithName>;
  size: keyof typeof fonts['displayFonts'];
  value: T;
}

const useStyles = makeStyles(({ palette }) => ({
  root: {
    minWidth: 100,
  },
  select: {
    '&&': {
      padding: 0,
    },
  },
  icon: {
    ...fonts.displayFonts.small,
    color: palette.level4.main,
    fontSize: 24,
    '&:hover': {
      color: palette.level1.main,
    },
  },
  input: {},
}), { name: 'simple-selector' });

export function SimpleSelector<T extends unknown = string>({ classes, children, emptyLabel, options = [], onChange, size, multiple, value, renderValue, ...props }: SimpleSelectorProps<T>) {
  const cls = useStyles();

  return (
    <Select
      classes={{
        root: clsx(cls.root, classes?.root),
        select: clsx(cls.select, classes?.select),
        icon: clsx(cls.icon, classes?.icon),
      }}
      value={value}
      IconComponent={iconProps => <MenuDownIcon className={clsx(cls.icon, classes?.icon)} size={size} {...iconProps} />}
      onChange={(e) => onChange(e.target.value as T)}
      displayEmpty
      multiple={multiple}
      renderValue={renderValue || ((v) => {
        if (typeof v === 'number') return options[v];
        if (typeof v === 'string') return v || emptyLabel;
        if (Array.isArray(v) && v.length) return v.map(i => options[i]).join(', ');
        return emptyLabel;
      })}
      input={<SimpleInputBase className={clsx(cls.input, classes?.input)} size={size} />}
      {...props}
    >
      <MenuItem value=''>
        <em>{emptyLabel}</em>
      </MenuItem>
      {children || options.map((o,i) => (
        <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>
      ))}
    </Select>
  )
}

export default SimpleSelector;
