import gql from 'graphql-tag';

export default gql `
query getDuration($instrumentId: ID!, $venueIds: [ID]!, $pov: Float!, $totalVolume: Float!, $weeks: Int!, $seasonality: String!) {
  getDuration(
          instrumentId: $instrumentId
          venueIds: $venueIds
          pov: $pov
          totalVolume: $totalVolume
          weeks: $weeks
          seasonality: $seasonality
        )
  }
`;
