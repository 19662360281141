import gql from 'graphql-tag';

export default gql`
  mutation updateAuthenticatedUserPassword(
    $currentPassword: String!
    $newPassword: String!
  ) {
    user: updateAuthenticatedUserPassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      id
      username
    }
  }
`;
