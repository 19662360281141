const algoExecutionFormCopyText = {
  cancelButtonLabel: 'Cancel',
  contractsLabel: 'contract',
  durationPastExpiration: 'Duration Past Expiration',
  durationUnitOptionDays: 'Day(s)',
  durationUnitOptionHours: 'Hour(s)',
  durationUnitOptionMinutes: 'Minute(s)',
  executeSorIfUnfilledLabel: 'Execute SOR if unfilled at expiration',
  futuresDurationError: 'The specified duration is greater than the life of the contract. Please set the duration to a value at or below %maxDuration% %durationUnit%.',
  includeFeesLabel: 'Include Fees',
  inputPlaceholder_algorithmDuration: 'Duration',
  inputPlaceholder_algorithmStrategy: 'Strategy',
  inputPlaceholder_customOrderId: 'Client Order ID (Optional)',
  inputPlaceholder_displayQuantity: 'Display Quantity',
  inputPlaceholder_instrumentDisplayName: 'Instrument',
  inputPlaceholder_limitPrice: 'Limit Price',
  inputPlaceholder_limitPriceOptional: 'Limit Price (Optional)',
  inputPlaceholder_stopLossPrice: 'Stop Price',
  inputPlaceholder_orderType: 'Order Type',
  inputPlaceholder_quantity: 'Quantity',
  inputPlaceholder_venueIds: 'Venues',
  orderSideBuy: 'BUY',
  orderSideSell: 'SELL',
  orderSuccessButtonLabel: 'Order Placed',
  overrideTwapIntervalsLabel: 'Override # intervals to',
  overridePTwapIntervalsLabel: 'Override # posting intervals to',
  overrideTwapIntervalsTooltip: 'Unless overridden, the number of intervals is determined dynamically based on market depth relative to order quantity.',
  participationRatePopup: 'The POV algo will target the specified participation rate throughout the duration of the algo. The duration of the algo is unbounded and based on the venue(s), instrument and quantity specified relative to the projected trading volume. Use this algo if you do not require a specified end time and you are prioritizing minimizing slippage over expediency.',
  proceedButtonLabel: 'Execute Algo',
  quantityUnitFutures: 'Cont.',
  quantityError: 'Your order to sell %quantity% %unit% of %instrument% exceeds the amount you currently hold. Please decrease the quantity to a value less than or equal to %basePositionQuantity% contracts.',
  quantityExceedsHoldings: 'Quantity Exceeds Holdings',
  sorLimitGtcWarning: 'Based upon the specified limit price relative to the market, your order is not expected to fully fill immediately. The remainder of the order will rest until the limit price is satisfied or the order is cancelled.',
  sorLimitGtcWarningHeader: 'Resting - Limit Price',
  sorStopGtcWarning: 'Based upon the specified stop market price relative to the market, your order is not expected to fill immediately. The order will rest until the stop market price is satisfied or the order is cancelled.',
  sorStopGtcWarningHeader: 'Resting - Stop Price',
  limitPriceWarning: 'Please specify a limit price which is %direction% your stop price.',
  limitPriceWarningHeader: 'Limit Price Too %relativeValue%',
  submitButtonLabel: 'Execute Algo',
  twapIntervalsFrequencyError: 'The maximum number of intervals is 1 every 10 seconds. For your selected duration of %algoDuration%, please choose a number of intervals less than or equal to %maxIntervals%.',
  twapIntervalsMinimumError: 'The minimum number of intervals is 10. Please choose a number greater than or equal to 10.',
  twapIntervalsDurationError: 'The maximum number of intervals at your selected duration of %algoDuration% is 10. Please increase the duration or reduce your intervals to 10.',
  twapTimeMinimumError: 'The minimum duration is 2 minutes. Please choose a duration greater than or equal to 2 minutes.',
};

export default algoExecutionFormCopyText;
