import { arrayOf, shape, string, number } from 'prop-types';
import React, { PureComponent } from 'react';

import { createSelector } from 'reselect';
import { format as formatNumber } from '@omniex/onx-common-js/lib/utils/NumberUtils';
import {
  formatUtc as formatDate,
  toEpochMilliseconds
} from '@omniex/onx-common-js/lib/utils/DateTimeUtils';
import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import { HighstockAdapter } from '../../ext/react-highcharts';
import { keyBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './MarketHistoryChart.copyText';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';

export const CHART_TYPE_CANDLESTICK = 'CANDLESTICK';
export const CHART_TYPE_LINE = 'LINE';
export const CHART_TYPE_OHLC = 'OHLC';
export const RANGE_1WEEK = 0;
export const RANGE_2WEEKS = 1;
export const RANGE_1MONTH = 2;
export const RANGE_3MONTHS = 3;
export const RANGE_6MONTHS = 4;
export const RANGE_1YEAR = 5;
export const RANGE_ALL = 6;

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/message.css');

const DEFAULT_CHART_TYPE = CHART_TYPE_CANDLESTICK;

const COMPONENT_NAME = 'MarketHistoryChart';

export default class MarketHistoryChart extends PureComponent {
  static propTypes = {
    bars: arrayOf(
      shape({
        timeClosed: string.isRequired,
        closingPrice: number.isRequired,
        highestPrice: number.isRequired,
        lowestPrice: number.isRequired,
        openingPrice: number.isRequired,
        volume: number.isRequired
      })
    ),
    chartType: string,
    height: number,
    instrument: shape({
      id: string.isRequired,
      baseAsset: shape({
        id: string.isRequired,
        symbol: string.isRequired
      }),
      termAsset: shape({
        id: string.isRequired,
        symbol: string.isRequired
      })
    }),
    range: number
  };

  static defaultProps = {
    chartType: DEFAULT_CHART_TYPE,
    range: RANGE_3MONTHS
  };

  _getChartConfig = createSelector(
    (bars, chartType, height, instrument, range) => bars,
    (bars, chartType, height, instrument, range) => chartType,
    (bars, chartType, height, instrument, range) => height,
    (bars, chartType, height, instrument, range) => instrument,
    (bars, chartType, height, instrument, range) => range,
    getChartConfig
  );

  render() {
    if (get(this.props, 'bars.length') === 0) {
      return this._renderWarningMessage();
    }

    return (
      <HighstockAdapter
        domProps={{ className: 'MarketHistoryChart' }}
        config={this._getChartConfig(
          this.props.bars,
          this.props.chartType,
          this.props.height,
          this.props.instrument,
          this.props.range
        )}
        isPureConfig={true}
      />
    );
  }

  _renderWarningMessage() {
    return (
      <div className={COMPONENT_NAME}>
        <Message content={copyText.warningMessage} warning />
      </div>
    );
  }
}

function getAxisFormat(range) {
  if (range < 0.000001) return '0.00000000';
  if (range < 0.00001) return '0.0000000';
  if (range < 0.0001) return '0.000000';
  if (range < 0.001) return '0.00000';
  if (range < 0.01) return '0.0000';
  if (range < 0.1) return '0.000';
  if (range < 1) return '0.00';
  if (range < 10) return '0.0';
  return '0,0';
}

function getChartConfig(bars = [], chartType, height, instrument, range) {
  const maxClosingPrice = Math.max(...bars.map(bar => bar.closingPrice));

  const yAxisFormat = getAxisFormat(maxClosingPrice);

  const barsKeyedByTimeClosed = keyBy(
    bars.map(bar => ({
      ...bar,
      timeClosed: toEpochMilliseconds(bar.timeClosed)
    })),
    'timeClosed'
  );

  return {
    chart: {
      height,
      spacing: [10, 0, 0, 0]
    },
    rangeSelector: {
      selected: range,
      buttonTheme: { style: { display: 'none' } },
      inputEnabled: false,
      labelStyle: { display: 'none' },
      buttons: [
        {
          type: 'week',
          count: 1
        },
        {
          type: 'week',
          count: 2
        },
        {
          type: 'month',
          count: 1
        },
        {
          type: 'month',
          count: 3
        },
        {
          type: 'month',
          count: 6
        },
        {
          type: 'year',
          count: 1
        },
        {
          type: 'all'
        }
      ]
    },
    navigator: {
      enabled: false,
      yAxis: {
        resize: {
          enabled: false
        }
      }
    },
    scrollbar: {
      enabled: false
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        second: '%Y-%m-%d %H:%M:%S',
        minute: '%Y-%m-%d %H:%M',
        hour: '%Y-%m-%d %H:%M',
        day: '%Y-%m-%d',
        week: '%Y-%m-%d',
        month: '%Y-%m',
        year: '%Y'
      },
      minRange: 24 * 60 * 60 * 1000,
      // tickInterval: 24 * 60 * 60 * 1000,
      labels: {
        rotation: -45,
        style: {
          fontSize: '.8em'
        }
      }
    },
    yAxis: [
      {
        opposite: false,
        title: {
          style: { fontSize: '0.85em' },
          text: `${copyText.yAxisTitleTextPrice} (${getAssetDisplayText(
            get(instrument, 'termAsset')
          )})`
        },
        height: '70%',
        labels: {
          x: -15,
          formatter: function() {
            return formatNumber(this.value, yAxisFormat);
          }
        }
      },
      {
        opposite: false,
        title: {
          style: { fontSize: '0.85em' },
          text: `${copyText.yAxisTitleTextVolume} (${getAssetDisplayText(
            get(instrument, 'baseAsset')
          )})`
        },
        top: '75%',
        height: '25%',
        labels: {
          x: 50,
          formatter: function() {
            return formatNumber(this.value, '0a');
          }
        }
      }
    ],
    tooltip: {
      useHTML: true,
      shared: true,
      borderColor: colors.black,
      formatter: function() {
        return renderTooltipContent({
          barsKeyedByTimeClosed,
          instrument,
          points: this.points,
          x: this.x
        });
      }
    },
    plotOptions: {
      candlestick:
        chartType === CHART_TYPE_CANDLESTICK
          ? {
              color: colors.red,
              lineColor: colors.red,
              upColor: colors.green,
              upLineColor: colors.green
            }
          : {},
      ohlc:
        chartType === CHART_TYPE_OHLC
          ? {
              color: colors.red,
              lineColor: colors.red,
              upColor: colors.green,
              upLineColor: colors.green
            }
          : {},
      line:
        chartType === CHART_TYPE_LINE
          ? {
              color: colors.black
            }
          : {}
    },
    series: [
      {
        type: chartType.toLowerCase(),
        name: 'Price',
        id: 'price',
        yAxis: 0,
        zIndex: 1,
        data: bars.map(bar => [
          toEpochMilliseconds(bar.timeClosed),
          bar.openingPrice,
          bar.highestPrice,
          bar.lowestPrice,
          bar.closingPrice
        ])
      },
      {
        linkedTo: ':previous',
        type: 'area',
        name: 'Volume',
        id: 'volume',
        color: colors.blue,
        yAxis: 1,
        zIndex: 2,
        data: bars.map(bar => [toEpochMilliseconds(bar.timeClosed), bar.volume])
      }
    ]
  };
}

function renderTooltipContent(parameters) {
  const { barsKeyedByTimeClosed, instrument, points, x } = parameters;

  const { openingPrice, highestPrice, lowestPrice, closingPrice } = get(
    barsKeyedByTimeClosed,
    [x],
    {}
  );

  const priceChange = closingPrice - openingPrice;
  const priceChangePercentage = priceChange / openingPrice;
  const priceChangeColor =
    priceChangePercentage < -0.0004
      ? colors.red
      : priceChangePercentage > 0.0004
      ? colors.green
      : colors.grey;
  const volume = get(points, '[1].y', '');
  const volumeColor = get(points, '[1].color', '');
  const content = `
<table>
<tr>
  <th colspan="2" style="text-align: center; font-weight: bold; padding-bottom: 4px;">
    ${formatDate(x, null, 'MMM D, YYYY')}
  </th>
</tr>
<tr>
  <td style="color: ${colors.grey}; font-weight: bold;">${
    copyText.chartTooltipLabelChange
  }</td>
  <td style="color: ${priceChangeColor}; text-align: right; font-weight: bold;">${formatNumber(
    priceChangePercentage,
    '+0.0%'
  )}</td>
</tr>
<tr>
  <td style="color: ${colors.grey}; font-weight: bold;">${
    copyText.chartTooltipLabelOpen
  }</td>
  <td style="text-align: right;">${formatNumber(
    openingPrice,
    '0,0.00000000'
  )} ${getAssetDisplayText(get(instrument, 'termAsset'))}</td>
</tr>
<tr>
  <td style="color: ${colors.grey}; font-weight: bold;">${
    copyText.chartTooltipLabelHigh
  }</td>
  <td style="text-align: right;">${formatNumber(
    highestPrice,
    '0,0.00000000'
  )} ${getAssetDisplayText(get(instrument, 'termAsset'))}</td>
</tr>
<tr>
  <td style="color: ${colors.grey}; font-weight: bold;">${
    copyText.chartTooltipLabelLow
  }</td>
  <td style="text-align: right;">${formatNumber(
    lowestPrice,
    '0,0.00000000'
  )} ${getAssetDisplayText(get(instrument, 'termAsset'))}</td>
</tr>
<tr>
  <td style="color: ${colors.grey}; font-weight: bold;">${
    copyText.chartTooltipLabelClose
  }</td>
  <td style="text-align: right;">${formatNumber(
    closingPrice,
    '0,0.00000000'
  )} ${getAssetDisplayText(get(instrument, 'termAsset'))}</td>
</tr>
<tr>
  <td style="color: ${volumeColor}; font-weight: bold; padding-top: 10px;">${
    copyText.chartTooltipLabelVolume
  }</td>
  <td style="text-align: right; padding-top: 10px;">${formatNumber(
    volume,
    '0,0'
  )} ${getAssetDisplayText(get(instrument, 'baseAsset'))}</td>
</tr>
</table>
`.replace(/>[\s\n]+</g, '><');
  return content;
}
