import { func, object } from 'prop-types';
import { Grid, Loader, Menu, Segment } from 'semantic-ui-react';
import { queryShape } from '../../utils/PropTypeUtils';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { get, has } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import AssetType from '@omniex/poms-core/lib/enums/AssetType';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './AdminPageOrgSettingsSection.copyText';
import EditOrgSettingsForm from '../components/EditOrgSettingsForm';
import ExternalMarketDataSource from '@omniex/onx-poms-entity-helpers/lib/enums/ExternalMarketDataSource';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import OrgSettingsComponent from '../components/OrgSettingsComponent';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/header.css');
require('@omniex/onx-common-ui/lib/semantic/css/loader.css');
require('@omniex/onx-common-ui/lib/semantic/css/grid.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');
require('@omniex/onx-common-ui/lib/semantic/css/segment.css');

const ERROR_MESSAGE_KEY_UNEXPECTED = 'errorMessageUnexpected';
const ERROR_MESSAGE_KEY_UPDATE_ORG_FAILED = 'errorMessageUpdateOrgFailed';
const SUCCESS_MESSAGE_KEY_ORG_UPDATED = 'successMessageOrgUpdated';

const COMPONENT_NAME = 'AdminPageOrgSettingsSection';

const StyledSection = styled('section')`
  height: 100% !important;

  .${COMPONENT_NAME}-segment {
    padding: 0;
  }

  .${COMPONENT_NAME}-grid {
    min-height: 190px;
  }

  .${COMPONENT_NAME}-errorMessage + .${COMPONENT_NAME}-segment {
    border-top-color: ${colors.errorBorderColor};
  }

  .${COMPONENT_NAME}-successMessage + .${COMPONENT_NAME}-segment {
    border-top-color: ${colors.successBorderColor};
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class AdminPageOrgSettingsSection extends PureComponent {
  static propTypes = {
    currenciesQuery: queryShape(object, false),
    orgQuery: queryShape({ org: object }),
    updateOrg: func,
    updateOrgMutation: queryShape(object, false),
  };

  _isMounted = true;

  state = {
    editingField: '',
  };

  render() {
    const loading = get(this.props, 'orgQuery.loading') || !has(this.props, 'orgQuery.data.org')|| get(this.props, 'updateOrgMutation.loading');

    const homeCurrencies = (get(this.props, 'currenciesQuery.data.currencies') || []).filter(c => c.type === AssetType.FIAT);
    const org = get(this.props, 'orgQuery.data.org');
    return (
      <StyledSection className={COMPONENT_NAME}>
        <Menu attached="top">
          <Menu.Item
            content={copyText.sectionTitle}
            header
            icon="info circle"
          />
        </Menu>
        {this._renderMessage()}
        <Segment className={cn('segment')} attached="bottom">
          <Grid className={cn('grid')} celled="internally">
            {loading ? (
              <Grid.Row columns={1}>
                <Grid.Column with={16}>
                  <Loader className={cn('loader')} active size="medium" />
                </Grid.Column>
              </Grid.Row>
            ) : (
              <Grid.Row columns={this.state.editingField ? 2 : 1}>
                <Grid.Column
                  width={this.state.editingField ? 12 : 16}>
                  <OrgSettingsComponent editingField={this.state.editingField} org={org} onClick={this._handleToggleEditOrgSettingsForm}/>
                </Grid.Column>
                {this.state.editingField && (
                  <Grid.Column width={4}>
                    <EditOrgSettingsForm
                      editingField={this.state.editingField}
                      homeCurrencies={homeCurrencies}
                      org={org}
                      processing={this.props.updateOrgMutation.loading}
                      onCancel={this._handleToggleEditOrgSettingsForm}
                      onUpdate={this._handleUpdateOrgSettings}
                    />
                  </Grid.Column>
                )}
              </Grid.Row>
            )}
          </Grid>
        </Segment>
      </StyledSection>
    );
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  _setState = (state, cb) => this._isMounted && this.setState(state, cb) // for updating state in async callbacks

  _renderMessage() {
    if (this.state.errorMessageKey) {
      return (
        <Message
          className={cn('errorMessage')}
          attached
          closeOnClick
          content={copyText[this.state.errorMessageKey]}
          error
          icon="warning sign"
        />
      );
    }

    if (this.state.successMessageKey) {
      return (
        <Message
          className={cn('successMessage')}
          attached
          closeOnClick
          content={copyText[this.state.successMessageKey]}
          icon="check circle outline"
          success
        />
      );
    }

    return null;
  }

  _handleToggleEditOrgSettingsForm = editingField => {
    this.setState(currentState => ({
      editingField: editingField === currentState.editingField ? null : editingField,
      errorMessageKey: undefined,
      successMessageKey: undefined
    }));
  };

  _handleUpdateOrgSettings = async input => {
    const { externalMarketDataSource } = input;
    if (externalMarketDataSource && !Object.values(ExternalMarketDataSource).includes(externalMarketDataSource)) {
      this._setState({ errorMessageKey: ERROR_MESSAGE_KEY_UNEXPECTED });
      return;
    }

    try {
      const { data } = await this.props.updateOrg(input);
      if (!data || !has(data, 'org')) return this._setState({ errorMessageKey: ERROR_MESSAGE_KEY_UPDATE_ORG_FAILED });
      this._setState({ editingField: null, successMessageKey: SUCCESS_MESSAGE_KEY_ORG_UPDATED });
    } catch (error) {
      this._setState({ errorMessageKey: ERROR_MESSAGE_KEY_UPDATE_ORG_FAILED });
    }

  };
}
