import { arrayOf, func, shape, string } from 'prop-types';
import { Label } from 'semantic-ui-react';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { opaque } from '@omniex/onx-common-ui/lib/colors/utils';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './ExchangeVenueRelationshipsTable.copyText';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import OrgVenueRelationshipStatus from '@omniex/onx-poms-entity-helpers/lib/enums/OrgVenueRelationshipStatus';
import Table from '@omniex/onx-common-ui/lib/semantic/react/Table';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/message.css');
require('@omniex/onx-common-ui/lib/semantic/css/table.css');

const COMPONENT_NAME = 'ExchangeVenueRelationshipsTable';

const StyledTable = styled(Table)`
  .${COMPONENT_NAME}-actionLink {
    background-color: white !important;
    color: ${colors.blue};
    padding: 0 !important;

    :hover {
      color: ${opaque(colors.blue, 0.8)} !important;
    }
  }

  .${COMPONENT_NAME}-statusLabel {
    border-radius: 0.2em;
    font-size: 0.85em;
    text-align: center;
    width: 80px !important;

    &.pending {
      background: ${colors.white};
      border: 1px solid ${colors.negativeTextColor};
      color: ${colors.negativeTextColor};
    }

    &.verified {
      background: ${colors.white};
      border: 1px solid ${colors.positiveTextColor};
      color: ${colors.positiveTextColor};
    }
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class ExchangeVenueRelationshipsTable extends PureComponent {
  static propTypes = {
    relationships: arrayOf(
      shape({
        id: string.isRequired,
        status: string.isRequired,
        venue: shape({
          id: string.isRequired,
          name: string.isRequired
        }).isRequired
      })
    ),
    onSelect: func
  };

  static defaultProps = {
    relationships: [],
    onSelect: noop
  };

  render() {
    return this.props.relationships.length > 0 ? (
      <StyledTable className={COMPONENT_NAME}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{copyText.tableHeaderName}</Table.HeaderCell>
            <Table.HeaderCell>{copyText.tableHeaderStatus}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.relationships.map(relationship =>
            this._renderTableRow(relationship)
          )}
        </Table.Body>
      </StyledTable>
    ) : (
      this._renderWarningMessage()
    );
  }

  _renderTableRow(relationship) {
    return (
      <Table.Row
        key={relationship.id}
        disabled={
          this.props.selectedRelationshipId &&
          this.props.selectedRelationshipId !== relationship.id
        }>
        <Table.Cell>{relationship.venue.name}</Table.Cell>
        <Table.Cell>
          <Label
            className={cn(
              `statusLabel ${
                relationship.status === OrgVenueRelationshipStatus.PENDING
                  ? 'pending'
                  : 'verified'
              }`
            )}>
            {copyText[`statusLabel_${relationship.status}`]}
          </Label>
        </Table.Cell>
      </Table.Row>
    );
  }

  _handleClickEditButton = (relationshipId, event) => {
    event.preventDefault();
    this.props.onSelect(relationshipId);
  };

  _renderWarningMessage() {
    return (
      <div className={COMPONENT_NAME}>
        <Message content={copyText.warningMessage} warning />
      </div>
    );
  }
}
