import { init as initApm } from '@elastic/apm-rum'

const config = {
  environment: process.env.REACT_APP_APM_RUM_ENV || 'development',
  serviceName: 'poms-edge',
  serverUrl: process.env.REACT_APP_APM_RUM_URL || 'http://localhost:8200',
  serviceVersion: '',
  active: process.env.REACT_APP_ENABLE_APM_RUM === 'true',
  disableInstrumentations: ['fetch', 'xmlhttprequest'],
};

export default initApm(config);