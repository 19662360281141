import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { groupBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';

export const getExistingCurrencies = accounts => groupBy(accounts.reduce((accum, account) => {
  const custodian = get(account, 'custodian') || get(account, 'customCustodian');
  if (!custodian || !custodian.id) return accum;
  return accum.concat({
    custodian: `${custodian.id}_${custodian.__typename}`,
    currencyId: account.currency.id,
  });
}, []), 'custodian');

export const partitionAccounts = accounts => accounts.reduce((accum, account) => {
  const custom = !account.custodian && account.customCustodian;
  const custodian = custom ? account.customCustodian : account.custodian;
  if (!custodian) return accum;
  const accounts = custom ? accum.accountsByCustomCustodianId : accum.accountsByCustodianId;
  if (accounts[custodian.id]) {
    accounts[custodian.id].push(account);
  } else {
    accounts[custodian.id] = [account];
    if (!custom) {
      accum.custodians.push(custodian);
    }
  }
  return accum;
}, {accountsByCustodianId: {}, accountsByCustomCustodianId: {}, custodians: []});

const custodyUtils = {
  partitionAccounts,
};

export default custodyUtils;
