import { bool, object } from 'prop-types';
import { Icon, Header, Loader, Segment } from 'semantic-ui-react';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { has } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import MarketOverviewTable from '../components/MarketOverviewTable';
import copyText from './DashboardPageMarketOverviewSection.copyText';
import ErrorBoundary from '../components/ErrorBoundary';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/header.css');
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/loader.css');
require('@omniex/onx-common-ui/lib/semantic/css/segment.css');

const COMPONENT_NAME = 'DashboardPageMarketOverviewSection';

const StyledSection = styled('section')`
  .${COMPONENT_NAME}-icon {
    font-size: 1em !important;
    padding: 2px !important;
  }

  .${COMPONENT_NAME}-componentWrapper {
    height: 351px;

    &.withScroll {
      overflow: auto;
    }
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class DashboardPageMarketOverviewSection extends PureComponent {
  static propTypes = {
    orgQueryData: object,
    topListQueryData: object,
    topListQueryError: object,
    topListQueryLoading: bool
  };

  render() {
    const loading = this.props.topListQueryLoading || !has(this.props, 'topListQueryData.topList');

    return (
      <StyledSection>
        <Header attached="top">
          <Icon name="list layout" className={cn('icon')} fitted />
          <Header.Content>{copyText.sectionTitle}</Header.Content>
        </Header>
        <Segment attached="bottom">
          <div className={cn('componentWrapper withScroll')}>
            {loading ? (
              <Loader className={cn('loader')} active content={copyText.loadingMessage} size="medium" />
            ) : (
              <ErrorBoundary message={copyText.error}>
                <MarketOverviewTable orgQueryData={this.props.orgQueryData} topListQueryData={this.props.topListQueryData} />
              </ErrorBoundary>
            )}
          </div>
        </Segment>
      </StyledSection>
    );
  }
}
