import gql from 'graphql-tag';

export default gql`
  mutation createManualAccount(
    $currencyId: ID!
    $custodianId: ID
    $customCustodianId: ID
    $description: String
    $freeForm: String
    $name: String
    $portfolioId: ID
    $venueId: ID
    $walletAddress: String
  ) {
    account: createManualAccount(
      currencyId: $currencyId
      custodianId: $custodianId
      customCustodianId: $customCustodianId
      description: $description
      freeForm: $freeForm
      name: $name
      portfolioId: $portfolioId
      venueId: $venueId
      walletAddress: $walletAddress
    ) {
      id
    }
  }
`;
