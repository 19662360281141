const sendQuoteFormCopy = {
  bidLabel: 'Bid',
  errorMessage:
    'Sorry, an unexpected error has occurred. You cannot send a quote at this time.',
  inputPlaceholder_bidPrice: 'Bid Price',
  inputPlaceholder_offerPrice: 'Offer Price',
  offerLabel: 'Offer',
  submitButtonLabel: 'Send Quote'
};

export default sendQuoteFormCopy;
