import * as React from 'react';
import { QuoteRequest, ConfigureQuoteFeedQData, ConfigureQuoteFeedQVars, QuoteFeedSVars, QuoteFeedInput, HydratedOTCTileData } from '../../../../types';
import { Dictionary, has } from 'lodash';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { useMutation, useSubscription } from '@apollo/react-hooks';
import refreshQuoteFeedSubscription from '../../../../apollo/graphql/buy-side/refreshQuoteFeed';
import configureQuoteFeedMutation from '../../../../apollo/graphql/buy-side/configureQuoteFeed';
import { useDebouncedTrigger } from '../../../hooks';

const WAIT = 0.5 * 1000;

const getQuoteFeedInputs = (tiles: HydratedOTCTileData[]): [QuoteFeedInput[], string] => {
  const keyedQuoteFeed: Dictionary<QuoteFeedInput> = {};
  const inputs: QuoteFeedInput[] = [];
  let nonce = '';

  for (const { instrument, instrumentId, quantity, venues } of tiles) {
    if (instrument && instrumentId && quantity && venues && Number(quantity) > 0) {
      venues.forEach(venue => {
        const key = [quantity, instrument.symbol, venue.name].join('');
        if (!keyedQuoteFeed[key]) keyedQuoteFeed[key] = { instrumentId, quantity: Number(quantity), venueId: venue.id };
      });
    }
  }

  for (const [key, input] of Object.entries(keyedQuoteFeed)) {
    nonce += key; // need better name than 'nonce'
    inputs.push(input);
  }

  return [inputs, nonce || 'EMPTY'];
}

export function useQuoteFeed(tiles: HydratedOTCTileData[], client?: ApolloClient<NormalizedCacheObject>) {
  const [state, setStateInternal] = React.useState<Dictionary<QuoteRequest>>({});
  const isMounted = React.useRef(true);
  const setState: typeof setStateInternal = (newStateOrCB) => {
    if (!isMounted.current) return;
    setStateInternal(newStateOrCB);
  }

  const [quoteFeedInputs, quoteFeedInputsNonce] = React.useMemo(() => getQuoteFeedInputs(tiles), [tiles]);

  const [configureQuoteFeed] = useMutation<ConfigureQuoteFeedQData, ConfigureQuoteFeedQVars>(
    configureQuoteFeedMutation,
    {
      client,
      onCompleted({ quoteRequests }) {
        // this is to remove stale QRs + add new QRs
        if (!quoteRequests) return;
        setState((prev) => {
          const next: Dictionary<QuoteRequest> = {};
          for (const qr of quoteRequests) {
            if (has(prev, qr.key)) next[qr.key] = prev[qr.key];
            else next[qr.key] = qr;
          }
          return next;
        });
      },
      onError(error) {
        console.error(error);
      }
    }
  );

  useSubscription<QuoteFeedSVars, {}>(refreshQuoteFeedSubscription, {
    client,
    onSubscriptionData({ subscriptionData: { data } }) {
      if (!data?.quoteRequests) return;

      const keyedQRs: Dictionary<QuoteRequest> = {};

      for (const quoteRequest of data.quoteRequests) {
        const { instrument, key, quantity, quote, venue } = quoteRequest;
        if (!instrument || !key || !quantity || !venue) {
          console.warn('Missing Data', instrument, key, quantity, venue);
          return;
        }

        if (process.env.NODE_ENV === 'development' && quote) {
          quote.metrics.push({ name: 'webapp_rx', time: `${Date.now()}000` });
          console.info(JSON.stringify(quote.metrics));
        }
        keyedQRs[key] = quoteRequest;
      }

      setState(prev => ({  ...prev, ...keyedQRs}));
    },
    skip: !client,
  });

  useDebouncedTrigger(
    quoteFeedInputsNonce,
    configureQuoteFeed,
    WAIT,
    { variables: { quoteFeedInputs } }
  );

  React.useEffect(() => () => {
    isMounted.current = false;
    configureQuoteFeed({ variables: { quoteFeedInputs: [] } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state;
}

export default useQuoteFeed;
