import gql from 'graphql-tag';

export default gql`
  mutation configureMarketDataFeed($orgId: String, $instrumentIds: [ID!]!) {
    marketDataSubscription: configureMarketDataFeed(
      orgId: $orgId
      instrumentIds: $instrumentIds
    ) {
      fixMDReqID
      instrument {
        id
      }
      fixMDStreamID
    }
  }
`;
