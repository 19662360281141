const getVenuesString = errors => {
  if (!errors || !errors.length) return '';
  if (errors.length === 1) return errors[0];
  const [first, ...rest] = errors;
  return `${rest.join(', ')} and ${first}`
}

const portfolioPagePositionsSectionCopy ={
  unableToFetchBalance: names => names && names.length ? `An error occurred while fetching your balance at ${getVenuesString(names)}. Please check that your API keys are still valid. If this error continues to occur, please contact our support team.` : null,
  csvDownloadTabLabel: 'Download CSV',
  csvHeader_assetType: 'Asset Type',
  csvHeader_currencySymbol: 'Currency',
  csvHeader_currentPrice: 'Current Price',
  csvHeader_custodian: 'Custodian',
  csvHeader_entryPrice: 'Entry Price',
  csvHeader_liquidationPrice: 'Liquidation Price',
  csvHeader_liquidationValue: 'Liquidation Value',
  csvHeader_margin: 'Margin',
  csvHeader_marketValue: 'Market Value',
  csvHeader_markPrice: 'Mark Price',
  csvHeader_quantity: 'Quantity',
  csvHeader_unrealizedPnL: 'Unrealized PnL (ROE%)',
  csvHeader_value: 'Value',
  inputPlaceholder_pageNumber: 'Page #',
  errorMessageCreateAccountTransactionFailed:
    'Sorry, an unexpected error occurred while trying to save your transaction. Please try again.',
  errorMessageDeleteAccountTransactionFailed:
    'Sorry, an unexpected error occurred while trying to delete your transaction. Please try again.',
  errorMessageCreatePositionFailed:
    'Sorry, an unexpected error occured while trying to create your position. Please try again.',
  errorMessageDuplicateSymbolOmniex:
    'The symbol you entered is already supported by Omniex',
  errorMessageDuplicateSymbolOrg:
    'The symbol you entered has already been created by a user at your organization',
  errorMessagePaginated: 'Sorry, something went wrong. Please try again.',
  errorMessageUpdatePositionFailed:
    'Sorry, an unexpected error occured while trying to update your position. Please try again.',
  loadingMessage: 'Loading...',
  notAvailable: 'N/A',
  openManualEntryTabLabel: 'Manual Entry',
  sectionTitle: 'Positions',
  successMessageCreateAccountTransaction:
    'Your manual entry transaction was recorded successfully!',
  successMessageDeleteAccountTransaction:
    'Your manual entry transaction was deleted successfully!',
  successMessagePositionCreated: 'Your position was created successfully.',
  successMassagePositionUpdated: 'Your position was updated successfully.'
};

export default portfolioPagePositionsSectionCopy
