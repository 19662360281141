import { arrayOf, number, shape, string } from 'prop-types';
import { createSelector } from 'reselect';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getPriceFormat } from '../../utils/DisplayUtils';
import { isString } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { orderBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import copyText from './MarketOverviewTable.copyText';
import Table, {
  CELL_FORMAT_MONEY,
  CELL_FORMAT_NUMBER,
  CELL_FORMAT_PERCENT_CHANGE
} from '@omniex/onx-common-ui/lib/semantic/react/Table';

const COMPONENT_NAME = 'MarketOverviewTable';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/table.css');

//prettier-ignore
const StyledTable = styled(Table)`
  min-width: 625px;
  padding-right: 10px !important;

  /* NOTE: This is to prevent Semantics sortable styling from overwriting our standard th styling  */
  th {
    background: none !important;
    border-left: none !important;
    border-right: none !important;
    padding-top: 0 !important;
  }

  /* TODO: temporary hack. This should be supported by ui/kit/Table itself */
  tr {
    td, th {
      &:first-child {
        min-width: 60px !important;
      }

      &:last-child {
        min-width: 120px !important;
      }
    }
  }
`;

const COLUMN_PRICE_CHANGE_24HOUR = 'priceChange24Hour';
const COLUMN_CURRENT_PRICE = 'currentPrice';
const COLUMN_MARKET_CAP = 'marketCap';
const COLUMN_NAME = 'currencyName';
const COLUMN_RANK = 'rank';
const COLUMN_CIRCULATING_SUPPLY = 'circulatingSupply';
const COLUMN_VOLUME_24HOUR = 'volume24Hour';

const SORT_ORDER_ASCENDING = 'ascending';
const SORT_ORDER_DESCENDING = 'descending';

export default class MarketOverviewTable extends PureComponent {
  static propTypes = {
    topListQueryData: shape({
      topList: arrayOf(
        shape({
          circulatingSupply: number,
          currencySymbol: string,
          currentPrice: number,
          marketCap: number,
          priceChange24Hour: number,
          rank: number,
          volume24Hour: number
        })
      )
    })
  };

  state = {
    sortedColumnName: COLUMN_RANK,
    sortedOrder: SORT_ORDER_DESCENDING
  };

  render() {
    const tableData = getSortedTableData(
      get(this.props, 'topListQueryData.topList', []),
      this.state.sortedColumnName,
      this.state.sortedOrder
    );

    const homeCurrency = get(this.props, 'orgQueryData.org.homeCurrency') || {};

    return (
      <StyledTable className={COMPONENT_NAME} sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={
                this.state.sortedColumnName === COLUMN_RANK
                  ? this.state.sortedOrder
                  : null
              }
              onClick={this._handleClickHeaderCell.bind(this, COLUMN_RANK)}>
              {copyText.tableHeaderRank}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={
                this.state.sortedColumnName === COLUMN_NAME
                  ? this.state.sortedOrder
                  : null
              }
              onClick={this._handleClickHeaderCell.bind(this, COLUMN_NAME)}>
              {copyText.tableHeaderCrypto}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={
                this.state.sortedColumnName === COLUMN_CURRENT_PRICE
                  ? this.state.sortedOrder
                  : null
              }
              textAlign="right"
              onClick={this._handleClickHeaderCell.bind(
                this,
                COLUMN_CURRENT_PRICE
              )}>
              {copyText.tableHeaderCurrentPrice}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={
                this.state.sortedColumnName === COLUMN_PRICE_CHANGE_24HOUR
                  ? this.state.sortedOrder
                  : null
              }
              textAlign="right"
              onClick={this._handleClickHeaderCell.bind(
                this,
                COLUMN_PRICE_CHANGE_24HOUR
              )}>
              {copyText.tableHeaderChange24H}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={
                this.state.sortedColumnName === COLUMN_VOLUME_24HOUR
                  ? this.state.sortedOrder
                  : null
              }
              textAlign="right"
              onClick={this._handleClickHeaderCell.bind(
                this,
                COLUMN_VOLUME_24HOUR
              )}>
              {copyText.tableHeaderVolume24H}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={
                this.state.sortedColumnName === COLUMN_MARKET_CAP
                  ? this.state.sortedOrder
                  : null
              }
              textAlign="right"
              onClick={this._handleClickHeaderCell.bind(
                this,
                COLUMN_MARKET_CAP
              )}>
              {copyText.tableHeaderMarketCap}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={
                this.state.sortedColumnName === COLUMN_CIRCULATING_SUPPLY
                  ? this.state.sortedOrder
                  : null
              }
              textAlign="right"
              onClick={this._handleClickHeaderCell.bind(
                this,
                COLUMN_CIRCULATING_SUPPLY
              )}>
              {copyText.tableHeaderCirculatingSupply}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {tableData.map(rowData => this._renderTableRow({...rowData, homeCurrency}))}
        </Table.Body>
      </StyledTable>
    );
  }

  _renderTableRow(rowData) {
    return (
      <Table.Row key={rowData.rank}>
        <Table.Cell>{rowData.rank}</Table.Cell>
        <Table.Cell>
          {`${rowData.currencyName}
          (${rowData.currencySymbol})`}
        </Table.Cell>
        <Table.Cell formatType={CELL_FORMAT_MONEY} format={getPriceFormat(rowData.price)} homecurrency={rowData.homeCurrency}>
          {rowData.price}
        </Table.Cell>
        <Table.Cell formatType={CELL_FORMAT_PERCENT_CHANGE}>
          {rowData.priceChange24Hour / 100}
        </Table.Cell>
        <Table.Cell formatType={CELL_FORMAT_MONEY} format="0,0">
          {rowData.volume24Hour}
        </Table.Cell>
        <Table.Cell formatType={CELL_FORMAT_MONEY} format="0,0" homecurrency={rowData.homeCurrency}>
          {rowData.marketCap}
        </Table.Cell>
        <Table.Cell formatType={CELL_FORMAT_NUMBER} format="0,0">
          {rowData.circulatingSupply}
        </Table.Cell>
      </Table.Row>
    );
  }

  _handleClickHeaderCell = columnName => {
    this.setState(currentState =>
      columnName === currentState.sortedColumnName
        ? {
            sortedOrder:
              currentState.sortedOrder === SORT_ORDER_ASCENDING
                ? SORT_ORDER_DESCENDING
                : SORT_ORDER_ASCENDING
          }
        : {
            sortedOrder: SORT_ORDER_DESCENDING,
            sortedColumnName: columnName
          }
    );
  };
}

const getSortedTableData = createSelector(
  (data, column, sortOrder) => data,
  (data, column, sortOrder) => column,
  (data, column, sortOrder) => sortOrder,
  (data, column, sortOrder) => {
    if (!data || !data.length) return [];

    let order;

    if (isString(data[0][column]) || column === COLUMN_RANK) {
      order =
        sortOrder === SORT_ORDER_DESCENDING
          ? SORT_ORDER_ASCENDING
          : SORT_ORDER_DESCENDING;
    } else {
      order =
        sortOrder === SORT_ORDER_DESCENDING
          ? SORT_ORDER_DESCENDING
          : SORT_ORDER_ASCENDING;
    }

    order = order.replace('ending', '');

    return orderBy(
      data,
      datum => {
        let value = datum[column];
        if (isString(value)) {
          value = value.toLowerCase();
        }
        return value;
      },
      order
    );
  }
);
