import gql from 'graphql-tag';

const refreshQuoteFeedSubscription = gql`
  subscription refreshQuoteFeed {
    quoteRequests: refreshQuoteFeed {
      key
      instrument {
        id
        displayName
        type
      }
      quantity
      quote {
        bidPrice
        fixQuoteID
        offerPrice
        metrics {
          name
          time
        }
      }
      reasonRejected
      status
      venue {
        id
        name
        shortName
      }
    }
  }
`;

export default refreshQuoteFeedSubscription;
