import gql from 'graphql-tag';

export default gql`
  query getOrg($portfolioId: ID) {
    org: getOrg(portfolioId: $portfolioId) {
      id
      enableCustomOrderId
      enablePTWAPSE
      externalMarketDataSource
      fixSenderCompID
      homeCurrency {
        id
        symbol
      }
      name
      portfolios {
        id
        name
      }
      takerApiBaseUrl
      type
      tz
    }
  }
`;
