import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';

export const hasMarketDataFor = (id, instrumentsKeyedById, marketData) => {
  const instrument = instrumentsKeyedById[id] || {};
  const mdInstrumentId = get(instrument, 'mdInstrument.id') || get(instrument, 'id');
  return (marketData || []).some(e => get(e, 'instrument.id') === mdInstrumentId);
}

const marketDataUtils = {
  hasMarketDataFor
};

export default marketDataUtils;
