import gql from 'graphql-tag';

export default gql`
  mutation createAccountBuy(
    $toAccountId: ID!
    $fromAccountId: ID!
    $executionPrice: Float!
    $fees: Float!
    $quantity: Float!
    $timeExecuted: String!
    $venueId: ID!
  ) {
    accountActivity: createAccountBuy(
      toAccountId: $toAccountId
      fromAccountId: $fromAccountId
      executionPrice: $executionPrice
      fees: $fees
      quantity: $quantity
      timeExecuted: $timeExecuted
      venueId: $venueId
    ) {
      id
    }
  }
`;
