import { arrayOf, func, number, object, shape, string } from 'prop-types';
import { Button, Form, Input } from 'semantic-ui-react';
import clsx from 'clsx';
import React, { Fragment, PureComponent } from 'react';
import styled from 'react-emotion';

import { formatNum } from '../../utils/SettlementUtils';
import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './AffirmAllocationsTable.copyText';
import FormSelect from '@omniex/onx-common-ui/lib/semantic/react/FormSelect';
import getAccountOptions from '../../selectors/getAccountOptions';
import getInstructionOptions from '../../selectors/getInstructionOptions';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import SettlementFillsTable from './SettlementFillsTable';
import SettlementStatus from '@omniex/onx-poms-entity-helpers/lib/enums/SettlementStatus';
import Table from '@omniex/onx-common-ui/lib/semantic/react/Table';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/table.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const COMPONENT_NAME = 'AffirmAllocationsTable';

const StyledDiv = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 20px;

  .${COMPONENT_NAME}-allocationsSectionWrapper {
    margin-left: 14px;
    max-width: 700px;
  }

  .${COMPONENT_NAME}-formWrapper {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
  }

  .${COMPONENT_NAME}-message {
    max-width: 450px;
  }

  .${COMPONENT_NAME}-actionButtonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      :first-child {
        margin-right: 10px;
      }
    }
  }

  .${COMPONENT_NAME}-currency {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .${COMPONENT_NAME}-acceptButton {
    background-color: ${colors.positiveTextColor} !important;
    color: white !important;
    margin-right: 10px;
  }

  .${COMPONENT_NAME}-dropdown {
    width: 200px !important;
  }

  .${COMPONENT_NAME}-borderlessCell {
    border: none !important;
  }

  .${COMPONENT_NAME}-rightCell {
    text-align: right !important;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class AffirmAllocationsTable extends PureComponent {
  static propTypes = {
    fromAccounts: arrayOf(
      shape({
        id: string.isRequired,
        name: string.isRequired,
        instructions: arrayOf(
          shape({
            id: string.isRequired,
            description: string,
            freeForm: string,
            walletAddress: string
          })
        )
      })
    ),
    org: shape({
      id: string.isRequired,
      fixSenderCompID: string.isRequired,
    }),
    settlement: shape({
      allocations: arrayOf(
        shape({
          id: string.isRequired,
          currency: shape({
            id: string.isRequired,
            symbol: string.isRequired,
            type: string.isRequired
          }),
          fromInstructions: shape({
            id: string.isRequired,
            freeForm: string.isRequired,
            walletAddress: string.isRequired
          }),
          quantity: number.isRequired,
          toInstructions: shape({
            id: string.isRequired,
            freeForm: string.isRequired,
            walletAddress: string.isRequired
          })
        })
      ),
      fills: arrayOf(object),
      fixInitiatorCompID: string.isRequired
    }),
    toAccounts: arrayOf(
      shape({
        id: string.isRequired,
        name: string.isRequired,
        instructions: arrayOf(
          shape({
            id: string.isRequired,
            description: string,
            freeForm: string,
            walletAddress: string
          })
        )
      })
    ),
    onAccept: func,
    onReject: func,
    onRelease: func
  };

  static defaultProps = {
    onAccept: noop,
    onReject: noop,
    onRelease: noop
  };

  state = {
    reasonRejected: '',
    showProceedForm: false,
    showRejectForm: false,
    showReleaseForm: false
  };

  render() {
    return (
      <StyledDiv className={COMPONENT_NAME}>
        <div className={cn('allocationsSectionWrapper')}>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className={clsx(cn('borderlessCell'), cn('rightCell'))}>
                  {copyText.tableHeaderDirection}
                </Table.HeaderCell>
                <Table.HeaderCell className={clsx(cn('borderlessCell'), cn('rightCell'))}>
                  {copyText.tableHeaderQuantity}
                </Table.HeaderCell>
                <Table.HeaderCell className={cn('borderlessCell')} />
                <Table.HeaderCell>
                  {copyText.tableHeaderWallet}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {copyText.tableHeaderAddress}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.settlement.allocations.map(allocation =>
                this._renderAllocation(allocation)
              )}
              <Table.Row>
                <Table.Cell className={clsx(cn('borderlessCell'), cn('rightCell'))} colSpan={5}>
                  <div className={cn('formWrapper')}>
                    {this._renderActionButtons(this.props.settlement)}
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>

        <SettlementFillsTable fills={this.props.settlement.fills} org={get(this.props, 'org')} />
      </StyledDiv>
    );
  }

  _renderAllocation(allocation) {
    const senderCompID = get(this.props, 'org.fixSenderCompID')
    return (
      <Table.Row
        key={allocation.id}
        disabled={
          this.props.settlement.status === SettlementStatus.PENDING_REJECT
        }>
        <Table.Cell className={clsx(cn('borderlessCell'), cn('rightCell'))}>
          {senderCompID === allocation.fixToCompID
            ? copyText.incomingLabel
            : copyText.outgoingLabel}
        </Table.Cell>
        <Table.Cell className={clsx(cn('borderlessCell'), cn('rightCell'))}>
          {formatNum(allocation.quantity, get(allocation, 'currency.type'))}
        </Table.Cell>
        <Table.Cell className={clsx(cn('currency'), cn('borderlessCell'))}>
          {getAssetDisplayText(allocation.currency)}
        </Table.Cell>
        {this._renderAccountInputs(allocation)}
      </Table.Row>
    );
  }

  _renderAccountInputs(allocation) {
    const senderCompID = get(this.props, 'org.fixSenderCompID')
    if (senderCompID === this.props.settlement.fixInitiatorCompID) {
      return (
        <Fragment>
          <Table.Cell className={cn('borderlessCell')}>
            {senderCompID === allocation.fixFromCompID
              ? allocation.fromAccount.name
              : allocation.toAccount.name}
          </Table.Cell>
          <Table.Cell className={cn('borderlessCell')}>
            {senderCompID === allocation.fixFromCompID
              ? get(allocation, 'fromInstruction.freeForm')
                ? get(allocation, 'fromInstruction.freeForm')
                : get(allocation, 'fromInstruction.walletAddress')
              : get(allocation, 'toInstruction.freeForm')
              ? get(allocation, 'toInstruction.freeForm')
              : get(allocation, 'toInstruction.walletAddress')}
          </Table.Cell>
        </Fragment>
      );
    }

    const instructionId = this.state[`${allocation.currency.id}-instructionId`];
    const instructionOptions = getInstructionOptions(
      senderCompID === allocation.fixFromCompID
        ? this.props.fromAccounts
        : this.props.toAccounts,
      this.state[`${allocation.currency.id}-accountId`]
    );
    const instructionValue = instructionOptions.find(i => i.value === instructionId) || null;

    return (
      <Fragment>
        <Table.Cell className={cn('borderlessCell')}>
          <FormSelect
            className={cn('dropdown')}
            name={`${allocation.currency.id}-accountId`}
            isSearchable={false}
            options={getAccountOptions(
              senderCompID === allocation.fixFromCompID
                ? this.props.fromAccounts
                : this.props.toAccounts,
              allocation.currency.id
            )}
            placeholder={copyText.inputPlaceholder_selectFromWallet}
            onChange={(e, d) => this._handleChangeField(e, d, allocation.currency.id)}
          />
        </Table.Cell>
        <Table.Cell className={cn('borderlessCell')}>
          <FormSelect
            className={cn('dropdown')}
            name={`${allocation.currency.id}-instructionId`}
            isDisabled={!this.state[`${allocation.currency.id}-accountId`]}
            isSearchable={false}
            options={instructionOptions}
            placeholder={copyText.inputPlaceholder_selectFromAddress}
            onChange={(e, d) => this._handleChangeField(e, d, allocation.currency.id)}
            value={instructionValue}
          />
        </Table.Cell>
      </Fragment>
    );
  }

  _renderActionButtons(settlement) {
    // TODO: Uncomment when cancellation feature is ready to be implemented.

    //   (
    //   <Button
    //     color="orange"
    //     size="mini"
    //     onClick={this._handleClickRequestSettlementCancellation.bind(
    //       this,
    //       settlement.id
    //     )}>
    //     {copyText.cancelButtonLabel}
    //   </Button>
    // )
    const senderCompID = get(this.props, 'org.fixSenderCompID');

    if (
      (senderCompID === settlement.fixInitiatorCompID &&
        settlement.status !== SettlementStatus.PENDING_REJECT) ||
      (senderCompID === settlement.fixRespondentCompID &&
        settlement.status === SettlementStatus.PENDING_REJECT)
    )
      return null;

    if (
      senderCompID === settlement.fixInitiatorCompID &&
      settlement.status === SettlementStatus.PENDING_REJECT &&
      !this.state.showReleaseForm
    ) {
      return (
        <Fragment>
          <Message
            className={cn('message')}
            header={copyText.reasonRejectedMessageHeader}
            content={settlement.reasonRejected}
            warning
          />
          <Form>
            <Form.Field>
              <Button
                color="blue"
                size="tiny"
                onClick={this._handleClickReleaseFillsButton}>
                {copyText.releaseFillsButtonLabel}
              </Button>
            </Form.Field>
          </Form>
        </Fragment>
      );
    }

    if (this.state.showProceedForm) {
      return (
        <Fragment>
          <Message
            content={copyText.acceptMessageContent}
            header={copyText.confirmationMessageHeader}
            warning
          />
          <Form onSubmit={this._handleSubmitAccept.bind(this, settlement.id)}>
            <div className={cn('actionButtonsWrapper')}>
              <Form.Field>
                <Button color="orange" size="tiny" type="submit">
                  {copyText.proceedButtonLabel}
                </Button>
              </Form.Field>
              <Form.Field>
                <Button
                  size="tiny"
                  type="reset"
                  onClick={this._handleClickCancelAcceptButton}>
                  {copyText.cancelButtonLabel}
                </Button>
              </Form.Field>
            </div>
          </Form>
        </Fragment>
      );
    }

    if (this.state.showRejectForm) {
      return (
        <div>
          <Message content={copyText.rejectionMessageContent} warning />
          <Form onSubmit={this._handleSubmitReject.bind(this, settlement.id)}>
            <Form.Field>
              <Input
                name="reasonRejected"
                placeholder={copyText.inputPlaceholder_reasonRejected}
                required
                value={this.state.reasonRejected}
                onChange={this._handleChangeField}
              />
            </Form.Field>
            <div className={cn('actionButtonsWrapper')}>
              <Form.Field>
                <Button color="orange" size="tiny" type="submit">
                  {copyText.submitRejectButtonLabel}
                </Button>
              </Form.Field>
              <Form.Field>
                <Button
                  size="tiny"
                  type="reset"
                  onClick={this._handleClickCancelRejectButton}>
                  {copyText.cancelRejectButtonLabel}
                </Button>
              </Form.Field>
            </div>
          </Form>
        </div>
      );
    }

    if (this.state.showReleaseForm) {
      return (
        <Fragment>
          <Message
            content={copyText.releaseMessageContent}
            header={copyText.confirmationMessageHeader}
            warning
          />
          <Form onSubmit={this._handleSubmitRelease.bind(this, settlement.id)}>
            <div className={cn('actionButtonsWrapper')}>
              <Form.Field>
                <Button color="orange" size="tiny" type="submit">
                  {copyText.releaseFillsButtonLabel}
                </Button>
              </Form.Field>
              <Form.Field>
                <Button
                  size="tiny"
                  type="reset"
                  onClick={this._handleClickCancelReleaseButton}>
                  {copyText.cancelButtonLabel}
                </Button>
              </Form.Field>
            </div>
          </Form>
        </Fragment>
      );
    }

    return (
      <div className={cn('actionButtonsWrapper')}>
        <Button
          className={cn('acceptButton')}
          disabled={!this._canAccept()}
          size="mini"
          onClick={this._handleClickAcceptSettlementButton}>
          {copyText.acceptButtonLabel}
        </Button>
        <Button
          negative
          size="mini"
          onClick={this._handleClickRejectSettlementButton}>
          {copyText.rejectButtonLabel}
        </Button>
      </div>
    );
  }

  _canAccept() {
    return (
      this.props.settlement.allocations.length ===
      Object.keys(this.state).filter(
        key => key.endsWith('-instructionId') && this.state[key] !== ''
      ).length
    );
  }

  _handleChangeField = (event, data, currencyId) => {
    let changes = { [data.name]: data.value || event.value };

    if (currencyId && changes[`${currencyId}-accountId`]) {
      changes[`${currencyId}-instructionId`] = '';
    }

    this.setState(changes);
  };

  _handleClickAcceptSettlementButton = event => {
    event.preventDefault();

    this.setState({ showProceedForm: true });
  };

  _handleClickCancelAcceptButton = event => {
    event.preventDefault();

    this.setState({ showProceedForm: false });
  };

  _handleClickCancelRejectButton = event => {
    event.preventDefault();

    this.setState({ showRejectForm: false });
  };

  _handleClickCancelReleaseButton = event => {
    event.preventDefault();

    this.setState({ showReleaseForm: false });
  };

  _handleClickRejectSettlementButton = event => {
    event.preventDefault();

    this.setState({ showRejectForm: true });
  };

  _handleClickReleaseFillsButton = event => {
    event.preventDefault();

    this.setState({ showReleaseForm: true });
  };

  _handleSubmitAccept = (settlementId, event) => {
    event.preventDefault();

    this.props.onAccept({
      id: settlementId,
      instructionIds: Object.keys(this.state)
        .filter(key => key.endsWith('-instructionId'))
        .map(key => this.state[key])
    });
  };

  _handleSubmitReject = (settlementId, event) => {
    event.preventDefault();
    this.props.onReject({
      id: settlementId,
      reasonRejected: this.state.reasonRejected
    });
  };

  _handleSubmitRelease = (settlementId, event) => {
    event.preventDefault();

    this.props.onRelease({ id: settlementId });
  };
}
