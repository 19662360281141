import gql from 'graphql-tag';

export default gql`
  query getAccounts($custodianId: ID, $portfolioId: ID) {
    accounts: getAccounts(custodianId: $custodianId, portfolioId: $portfolioId) {
      currency {
        id
      }
      custodian {
        id
      }
    }
  }
`;
