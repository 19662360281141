import { createSelector } from 'reselect';

import { keyBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';

export function getCurrenciesKeyedById(currencies) {
  if (!Array.isArray(currencies) || currencies.length === 0) return {};

  return keyBy(currencies, 'id');
}

export default createSelector(currencies => currencies, getCurrenciesKeyedById);
