const settlementFillsTableCopy = {
  accordionTitleShowFills: 'Show Fills',
  notAvailable: 'N/A',
  popupLabelEMSOrderID: 'EMS Order ID',
  popupLabelExecID: 'Exec ID',
  popupLabelFixQuoteID: 'Quote ID',
  tableHeaderClientOrderID: 'Client Order ID',
  tableHeaderExecType: 'Execution Type',
  tableHeaderFillPrice: 'Fill Price',
  tableHeaderFixOrderID: 'OrderID',
  tableHeaderInstrument: 'Instrument',
  tableHeaderQuantity: 'Quantity',
  tableHeaderSide: 'Side',
  tableHeaderTimeExecuted: 'Time Executed',
  tableHeaderVenueOrderID: 'Venue Order ID',
};

export default settlementFillsTableCopy;
