const affirmSettlementsBlotterTableCopyText = {
  actionRequiredLabel: 'Action Required',
  pendingLabel: 'Pending',
  popupLabelFixAllocID: 'Allocation ID',
  rejectedLabel: 'Rejected',
  tableHeaderFixAllocID: 'Settlement ID',
  tableHeaderIncoming: 'Incoming',
  tableHeaderOutgoing: 'Outoing',
  tableHeaderStatus: 'Status',
  tableHeaderTimestamp: 'Timestamp',
  utcLabel: 'UTC',
  warningMessageNoAffirmSettlements:
    'There are no initiated settlements associated with your account at this time.'
};

export default affirmSettlementsBlotterTableCopyText;
