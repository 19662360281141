import React, { PureComponent } from 'react';
import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import moment from '../../ext/moment';
import MomentUtils from '@date-io/moment'
import { CSVLink } from 'react-csv';
import { format as formatDate } from '@omniex/poms-core/lib/utils/DateTimeUtils';
import { format as formatNumber } from '@omniex/poms-core/lib/utils/NumberUtils';
import { get } from '@omniex/poms-core/lib/utils/ObjectUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import { getExecutionType } from '@omniex/poms-core/lib/utils/OrderUtils';
import { formatPrice } from '../../utils/DisplayUtils';
import { formatQuantity, getQuantityFilled, getQuantityOrdered, getAvgFillPrice, getFees } from '../../utils/OrderUtils';
import { convertDateToLocalTime } from '../../utils/TimeUtils.js';
import { isEmpty } from '@omniex/poms-core/lib/utils/LangUtils';
import { noop } from '@omniex/poms-core/lib/utils/FunctionUtils';
import { orderBy } from '@omniex/poms-core/lib/utils/CollectionUtils';
import copyText from './CsvDownloadForm.copyText';
import CsvDataType from '@omniex/poms-core/lib/enums/CsvDataType';
import OrderSource from '@omniex/poms-core/lib/enums/OrderSource';
import { formatDuration, getVenueName, isOrderActive } from './Blotter/shared';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/button.css');
require('@omniex/onx-common-ui/lib/semantic/css/form.css');
require('@omniex/onx-common-ui/lib/semantic/css/input.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');


const DATETIME_PICKER_FORMAT = 'L';
const DATETIME_PICKER_MIN_DATE = '2018-01-01';

const COMPONENT_NAME = 'CsvDownloadForm';

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class CsvDownloadForm extends PureComponent {
  static propTypes = {
    data: arrayOf(
      shape({
        fills: arrayOf(object),
        orders: arrayOf(object),
        settlements: arrayOf(object)
      })
    ),
    dataType: string,
    org: shape({
      enableCustomOrderId: bool,
      fixSenderCompID: string.isRequired,
      id: string,
    }),
    processing: bool,
    onDownload: func
  };
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
    }
  }

  static defaultProps = { onDownload: noop };


  csvLink = React.createRef();

  static getDerivedStateFromProps(nextProps, prevState) {
    const stateChanges = {};

    if (prevState.processing !== nextProps.processing) {
      stateChanges.processing = nextProps.processing;
    }

    if (
      prevState.processing === true &&
      nextProps.processing === false &&
      isEmpty(nextProps.data)
    ) {
      stateChanges.startDate = null;
      stateChanges.endDate = null;
    }

    return stateChanges;
  }

  render() {
    return (
      <Box display='flex' alignItems='center'>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            id='start-date-picker'
            variant='inline'
            format={DATETIME_PICKER_FORMAT}
            minDate={DATETIME_PICKER_MIN_DATE}
            autoOk
            disableToolbar
            disableFuture
            placeholder={copyText.inputPlaceholder_startDate}
            value={this.state.startDate}
            onChange={this._handleChangeStartDate}
            inputVariant='outlined'
            size='small'
          />
          <DatePicker
            id='end-date-picker'
            variant='inline'
            format={DATETIME_PICKER_FORMAT}
            minDate={DATETIME_PICKER_MIN_DATE}
            autoOk
            disableToolbar
            placeholder={copyText.inputPlaceholder_endDate}
            value={this.state.endDate}
            onChange={this._handleChangeEndDate}
            inputVariant='outlined'
            size='small'
            style={{ marginLeft: 5 }}
          />
        </MuiPickersUtilsProvider>
        <Button
          className={cn('downloadButton')}
          variant='contained'
          disabled={
            !this.state.startDate ||
            !this.state.endDate ||
            this.props.processing
          }
          size='small'
          onClick={this._handleClick}
          style={{ backgroundColor: '#266dbe', color: 'white', marginLeft: 10, marginRight: 5 }}
        >
          {copyText.downloadButtonLabel}
        </Button>
        <CSVLink
          className={cn('csvLink')}
          data={getCsvData(
            this.props.dataType,
            this.props.data,
            this.props.org
          )}
          filename={getCsvFilename(this.props.dataType)}
          headers={this._getCsvHeaders()}
          ref={this.csvLink}
        />
      </Box>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.processing === true &&
      this.props.processing === false &&
      this.state.endDate &&
      this.state.startDate
    ) {
      this.csvLink.current.link.click();
      this.setState({ endDate: null, startDate: null });
    }
  }

  _getCsvHeaders = _ => {
    if (this.props.dataType === CsvDataType.FILL) {
      return [
        'fillTimeCreated',
        'fixClOrdID',
        'fixExecID',
        'fixOrderID',
        'fixVenueOrderID',
        'executionType',
        'venueName',
        'instrument',
        'side',
        'fillQuantity',
        'fillPrice',
        'feeCost',
      ].map(key => ({
        key,
        label: copyText[`csvHeader_${key}`]
      }));
    }

    if (this.props.dataType === CsvDataType.SETTLEMENT) {
      return [
        'timeCreated',
        'fixAllocID',
        'counterParty',
        'outgoingQuantity',
        'outgoingCurrency',
        'outgoingFromWallet',
        'outgoingFromAddress',
        'outgoingToWallet',
        'outgoingToAddress',
        'incomingQuantity',
        'incomingCurrency',
        'incomingFromWallet',
        'incomingFromAddress',
        'incomingToWallet',
        'incomingToAddress',
      ].map(key => ({
        key,
        label: copyText[`csvHeader_${key}`]
      }));
    }

    const orderCols = [
      'timeCreated',
      'timeLastUpdated',
      //Conditionally add customOrderId here
      'fixClOrdID',
      'fixQuoteID',
      'fixOrderID',
      'fixVenueOrderID',
      'traderName',
      'status',
      'executionType',
      'side',
      'instrument',
      'specifiedQuantity',
      'fillQuantity',
      'venueName',
      'averageFillPrice',
      'orderType',
      'expiryType',
      'limitPrice',
      'stopPrice',
      'algorithmDuration',
      'algorithmActualDuration',
      'totalFees',
      'displayQuantity',
      'participationRate',
    ];
    if (this.props.org?.enableCustomOrderId) orderCols.splice(2, 0, 'customOrderId');

    return orderCols.map(key => ({
      key,
      label: copyText[`csvHeader_${key}`]
    }));
  }

  _handleChangeStartDate = startDate => {
    if (startDate) {
      this.setState({
        endDate: this.state.endDate?.isBefore(startDate)
            ? startDate
            : this.state.endDate,
        startDate: startDate
      });
    } else {
      this.setState({ startDate });
    }
  };

  _handleChangeEndDate = endDate => {
    if (endDate) {
      this.setState({
        endDate: this.state.startDate?.isAfter(endDate)
          ? this.state.startDate
          : endDate
      });
    } else {
      this.setState({ endDate });
    }
  };

  _handleClick = event => {
    event.preventDefault();

    const startDate = moment(this.state.startDate)
      .utc()
      .hour(0)
      .toISOString();
    const endDate = moment(this.state.endDate)
      .utc()
      .hour(23)
      .minute(59)
      .second(59)
      .toISOString();

    const vars = {
      orderParameter: 'timeCreated',
      orderDirection: 'desc',
      dataType: this.props.dataType,
      endDate,
      startDate
    };

    this.props.onDownload(vars);
  };
}

function getCsvData(dataType, data, userOrg) {
  if (dataType === CsvDataType.FILL) {
    return data.map(fill => {
      const output = {
        fillTimeCreated: convertDateToLocalTime(fill.timeCreated),
        fixClOrdID: fill.order.fixClOrdID,
        fixOrderID: fill.order.fixOrderID,
        fixVenueOrderID: fill.order.fixVenueOrderID,
        fixExecID: fill.fixExecID,
        executionType: getExecutionType(fill.order),
        venueName: fill.venue.name,
        instrument: fill.order.instrument.displayName,
        side: fill.order.side,
        fillQuantity: formatQuantity(fill.specifiedCurrency, fill.quantity),
        fillPrice: `${formatPrice(fill.price)} ${fill.order.instrument.termAsset?.symbol}`,
        feeCost: fill.feeCost
          ? `${formatPrice(fill.feeCost)} ${fill.feeCurrency?.symbol || ''}`
          : copyText.noData,
      };
      return output;
    });
  }

  if (dataType === CsvDataType.SETTLEMENT) {
    // TODO: Put this in a selector
    return orderBy(
      data.reduce((rows, settlement) => {
        const counterParty = [settlement.respondentOrg, settlement.initiatorOrg].find(org => userOrg.id !== org.id).name;

        let row = {
          timeCreated: convertDateToLocalTime(settlement.timeCreated),
          fixAllocID: settlement.fixAllocID,
          counterParty,
        };

        settlement.allocations.forEach(a => {
          const isIncoming = userOrg.fixSenderCompID === a.fixToCompID;
          const fromInstruction = a.fromInstruction || {};
          const toInstruction = a.toInstruction || {};
          const alloc = isIncoming
            ? {
              incomingQuantity: a.quantity,
              incomingCurrency: getAssetDisplayText(get(a, 'currency')) || copyText.noData,
              incomingFromWallet: get(a, 'fromAccount.name') || copyText.noData,
              incomingFromAddress: fromInstruction.iban || fromInstruction.freeForm || fromInstruction.walletAddress || copyText.noData,
              incomingToWallet: get(a, 'toAccount.name') || copyText.noData,
              incomingToAddress: toInstruction.iban || toInstruction.freeForm || toInstruction.walletAddress || copyText.noData,
            } : {
              outgoingQuantity: a.quantity,
              outgoingCurrency: getAssetDisplayText(get(a, 'currency')) || copyText.noData,
              outgoingFromWallet: get(a, 'fromAccount.name') || copyText.noData,
              outgoingFromAddress: fromInstruction.iban || fromInstruction.freeForm || fromInstruction.walletAddress || copyText.noData,
              outgoingToWallet: get(a, 'toAccount.name') || copyText.noData,
              outgoingToAddress: toInstruction.iban || toInstruction.freeForm || toInstruction.walletAddress || copyText.noData,
            };
          row = {...row, ...alloc};
        });
        rows.push(row);
        return rows;
      }, []),
      ['timeCreated'],
      ['desc'],
    );
  }

  return data.map(order => ({
    timeCreated: convertDateToLocalTime(order.timeCreated),
    timeLastUpdated: convertDateToLocalTime(order.timeLastUpdated ?? order.timeCreated),
    customOrderId: order.customOrderId ?? copyText.noData,
    fixOrderID:
      order.source === OrderSource.EXTERNAL
        ? `Order placed on ${get(order, 'venue.name')}`
        : order.fixOrderID,
    fixQuoteID: order.fixQuoteID ? order.fixQuoteID : copyText.noData,
    fixClOrdID:
      order.source === OrderSource.EXTERNAL
        ? `Order placed on ${get(order, 'venue.name')}`
        : order.fixClOrdID,
    fixVenueOrderID: order.feature !== 'ALGO' && order.fixVenueOrderID
      ? order.fixVenueOrderID
      : copyText.noData,
    traderName: order.trader?.username || copyText.noData,
    status: order.status,
    executionType: getExecutionType(order),
    side: order.side,
    instrument: order.instrument.displayName,
    specifiedQuantity: getQuantityOrdered(order),
    fillQuantity: getQuantityFilled(order),
    venueName: getVenueName(order),
    averageFillPrice: getAvgFillPrice(order),
    orderType: order.type,
    expiryType: order.expiryType,
    limitPrice: order.limitPrice ?? copyText.noData,
    stopPrice: order.stopPrice ?? copyText.noData,
    algorithmDuration: order.algorithmDuration ? formatDuration(moment.duration(order.algorithmDuration, 'seconds')) : copyText.noData,
    algorithmActualDuration: !isOrderActive(order) && order.timeLastUpdated
      ? copyText.noData
      : formatDuration(moment.duration((
          isOrderActive(order) ? moment() : moment(order.timeLastUpdated)
        ).diff(moment(order.timeCreated)))) || copyText.noData,
    totalFees: getFees(order),
    displayQuantity: order.displayQuantity ? formatQuantity(order.specifiedCurrency, order.displayQuantity) : copyText.noData,
    participationRate: order.participationRate
      ? formatNumber(
          `${order.participationRate * 100}`,
          '0,0.00'
        ) + ' %'
      : copyText.noData,
  }));
}

function getCsvFilename(dataType) {
  return `${dataType} ${formatDate(
    Date.now(),
    null,
    'YYYYMMDDHHmmss'
  )}.csv`.replace(/\s/g, '-');
}
