import React, { useState, useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import styled from 'react-emotion';

import { getMaintenanceStartDate, getTimeToMaintenanceWarning } from '../../utils/TimeUtils';
import { layout } from '@omniex/onx-common-ui/lib/styles';
import { noop } from '@omniex/poms-core/lib/utils/FunctionUtils';
import PageLayoutHeaderMenu from './PageLayoutHeaderMenu';
import text from './PageLayout.copyText';
import TokenExpirationProvider from '../../providers/TokenExpirationProvider';


const COMPONENT_NAME = 'PageLayout';

const maintenanceDate = getMaintenanceStartDate().getTime();

// prettier-ignore
const StyledDiv = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .${COMPONENT_NAME}-body {
    background-color: inherit;
    display: flex;
    flex: 1;
    overflow: hidden;
  }

  .${COMPONENT_NAME}-mainContentWrapper {
    flex: 1;
    overflow: auto;
    padding: ${layout.menuBarHeight}px 20px 0 20px;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export const PageLayout = ({
  apiClient,
  children,
  fixApiClient,
  height,
  loading,
  user,
  newNotifications = [],
  onChangePath = noop,
  onLogout = noop,
  portfolio = {},
  setNewNotifications = noop,
  setNotificationFixClOrdID = noop,
  setPortfolio = noop,
}) => {
  const [showMaintenanceWarning, setShowMaintenanceWarning] = useState(false);

  useEffect(_ => {
    const time = getTimeToMaintenanceWarning();
    const timer = setTimeout(_ => setShowMaintenanceWarning(true), time);
    return _ => clearTimeout(timer);
  }, []);

  return (
    <StyledDiv className={COMPONENT_NAME} user={user} menuBarHeight={layout.menuBarHeight}>
      <div className={cn('headerMenuWrapper')}>
        <PageLayoutHeaderMenu
          apiClient={apiClient}
          fixApiClient={fixApiClient}
          height={height}
          maintenanceDate={maintenanceDate}
          showMaintenanceWarning={showMaintenanceWarning}
          user={user}
          newNotifications={newNotifications}
          onChangePath={onChangePath}
          onLogout={onLogout}
          portfolio={portfolio}
          setNewNotifications={setNewNotifications}
          setNotificationFixClOrdID={setNotificationFixClOrdID}
          setPortfolio={setPortfolio}
        />
      </div>
      {loading ? (
        <Loader active content={text.loading} size="medium" />
      ) : (
        <div className={cn('body')}>
          <div className={cn('mainContentWrapper')}>{children}</div>
        </div>
      )}
      <TokenExpirationProvider maintenanceDate={maintenanceDate} onLogout={onLogout} />
    </StyledDiv>
  );
}

export default PageLayout
