import { number, shape, string } from 'prop-types';
import React, { PureComponent } from 'react';

import { arrayOfShape } from '../../utils/PropTypeUtils';
import { FMT_STR_FIAT, FMT_STR_CRYPTO } from '../../utils/DisplayUtils';
import { format } from '@omniex/onx-common-js/lib/utils/NumberUtils';
import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import { groupBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import { isEmpty } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './MarketDepthChart.copyText';
import AssetType from '@omniex/poms-core/lib/enums/AssetType';
import HighchartsAdapter from '../../ext/react-highcharts';
import MarketDataEntryType from '@omniex/onx-poms-entity-helpers/lib/enums/MarketDataEntryType';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/message.css');

const COMPONENT_NAME = 'MarketDepthChart';


const WarningMessage = _ => (
  <div className={COMPONENT_NAME}>
    <Message content={copyText.warningMessage} warning />
  </div>
);


const numType = number.isRequired;
const strType = string.isRequired;
const currencyShape = shape({ id: strType, symbol: strType, type: strType });

export default class MarketDepthChart extends PureComponent {
  static propTypes = {
    height: number,
    entries: arrayOfShape({
      id: strType,
      fixMDStreamID: strType,
      price: numType,
      type: strType,
      quantity: numType,
      venueSymbol: strType,
    }),
    instrument: shape({
      id: strType,
      symbol: strType,
      baseAsset: currencyShape,
      termAsset: currencyShape,
    }),
  }

  static defaultProps = {}

  render() {
    return get(this.props, 'entries.length', 0) === 0
      ? <WarningMessage />
      : <HighchartsAdapter domProps={{ className: COMPONENT_NAME }} config={this._getChartConfig()} />;
  }

  _getChartConfig = _ => {
    const { props } = this;
    const { height, entries } = props;

    const isFiatBase = get(props, 'instrument.baseAsset.type') === AssetType.FIAT;
    const isFiatTerm = get(props, 'instrument.termAsset.type') === AssetType.FIAT;

    let {
      [MarketDataEntryType.BID]: bids,
      [MarketDataEntryType.OFFER]: offers,
    } = groupBy(entries || [], 'type');

    bids = groupBy(bids, 'price');
    offers = groupBy(offers, 'price');

    const bidsData = _getSeriesData(bids, MarketDataEntryType.BID);
    const offersData = _getSeriesData(offers, MarketDataEntryType.OFFER);

    const numBids = bidsData.length, numOffers = offersData.length;
    const firstBid = bidsData[0] || {}, firstOffer = offersData[0] || {};
    const lastBid = bidsData[numBids - 1] || {}, lastOffer = offersData[numOffers - 1] || {};

    const midPoint = (lastBid.x + firstOffer.x) / 2 || 0;
    const middle = (firstBid.x + lastOffer.x) / 2 || 0;

    const xAxisRange = Math.abs(firstBid.x - lastOffer.x) || 0;
    const xAxisFormat = _getAxisFormat(xAxisRange);

    const yAxisMax = Math.max(lastBid.y, lastOffer.y) || 0;
    const yAxisFormat = _getAxisFormat(yAxisMax);

    return {
      chart: {
        type: 'area',
        height,
        spacing: [10, 0, 0, 0],
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        area: {
          marker: {
            enabled: false,
            symbol: 'circle',
          },
        },
        series: {
          animation: false,
        },
      },
      series: [
        {
          name: copyText.chartTooltipTitleBid,
          color: colors.blue,
          data: bidsData,
          step: true,
          entryType: MarketDataEntryType.BID,
        },
        {
          name: copyText.chartTooltipTitleOffer,
          color: colors.orange,
          data: offersData,
          step: true,
          entryType: MarketDataEntryType.OFFER,
        },
      ],
      tooltip: {
        formatter: function() {return _renderTooltipContent(this, props, bids, offers, isFiatBase, isFiatTerm)}, // NOTE: can't use arrow functions in formatters
        shared: true,
        useHTML: true,
      },
      xAxis: {
        allowDecimals: true,
        labels: {
          rotation: -45,
          formatter: function() {return format(this.value, xAxisFormat)},
        },
        plotLines: [
          {
            color: colors.borderColor,
            dashStyle: 'dash',
            value: midPoint,
            width: 1,
            label: {
              textAlign: midPoint > middle ? 'right' : 'left',
              x: midPoint > middle ? -5 : 5,
              rotation: 0,
              y: 30,
              style: { color: colors.grey },
              text: format(midPoint, isFiatTerm ? '0.00' : '0.00000000'),
              verticalAlign: 'top',
            },
          },
        ],
        title: {
          align: 'high',
          style: { fontSize: '0.85em' },
          text: `${copyText.chartTitlePrice} (${getAssetDisplayText(get(props, 'instrument.termAsset'))})`,
        },
      },
      yAxis: {
        labels: {
          formatter: function() {return format(this.value, yAxisFormat)},
        },
        title: {
          style: { fontSize: '0.85em' },
          text: `${copyText.chartTitleVolume} (${getAssetDisplayText(get(props, 'instrument.baseAsset'))})`,
        },
      },
    }
  }
}

const _renderTooltipContent = (data, props, bids, offers, isFiatBase, isFiatTerm) => {
  const { color, name } = get(data, 'points[0].series', {});

  const baseSymbol = getAssetDisplayText(get(props, 'instrument.baseAsset'));
  const termSymbol = getAssetDisplayText(get(props, 'instrument.termAsset'));

  const price = `${format(data.x, isFiatTerm ? FMT_STR_FIAT : FMT_STR_CRYPTO)} ${termSymbol}`;
  const volume = `${format(data.y, isFiatBase ? FMT_STR_FIAT : FMT_STR_CRYPTO)} ${baseSymbol}`;
  const depth = `${format(get(data, 'points[0].point.volume', 0), isFiatTerm ? FMT_STR_FIAT : FMT_STR_CRYPTO)} ${termSymbol}`;
  const entryType = get(data, 'points[0].point.type', '');
  const side = entryType === MarketDataEntryType.BID ? bids : offers;

  const venueSymbol = get(side[data.x], 'length', 0) > 1 ? 'Multiple' : get(side[data.x], '[0].venueSymbol', '');
  const venue = venueSymbol === 'Multiple' ? venueSymbol : get(props.venueMap, `${venueSymbol}.name`, 'N/A');

  const tr = (label, value, style) => `<tr>
        <td style="color: ${colors.grey}; font-weight: bold;">${label}</td>
        <td style="text-align: right; font-weight: bold; ${style || ''}">${value}</td>
      </tr>`;

  return `
    <table>
      <tr>
        <th colspan="2" style="color: ${color}; text-align: center; font-weight: bold; padding-bottom: 4px">
          ${copyText[`chartTooltipTitle${name}`]}
        </th>
      </tr>
      ${tr(copyText.chartTooltipLabelPrice, price)}
      ${tr(copyText.chartTooltipLabelTotalVolume, volume)}
      ${tr(copyText.chartTooltipLabelDepth, depth)}
      ${tr(copyText.chartTooltipLabelVenue, venue, 'text-transform: uppercase;')}
    </table>
  `;
}

const _getSeriesData = (entries, type) => {
  if (isEmpty(entries)) return [];

  const order = type === MarketDataEntryType.BID ? 1 : -1;
  const prices = Object.keys(entries).map(price => parseFloat(price)).sort((a, b) => order*(b - a));

  const volumes = prices.reduce((volumes, price, i) => {
    const { y: lastY, volume: lastV } = volumes[i - 1] || {};
    const [ thisY, thisV ] = entries[price].reduce(([y, v], e) => [y + e.quantity, v + e.quantity * e.price], [0, 0]);
    return [ ...volumes, { x: parseFloat(price), y: thisY + (lastY || 0), volume: thisV + (lastV || 0), type: type } ];
  }, []);

  if (type === MarketDataEntryType.BID) volumes.reverse();

  return volumes;
}

const _getAxisFormat = r => r >= 10 || r <= 0 ? '0,0' : '0.0' + Array(-Math.floor(Math.log10(r))).fill(0).join('');
