import * as React from 'react';
import { useState } from 'react';
import { Grid, Menu, Segment } from 'semantic-ui-react';
import styled from 'react-emotion';
import copyText from './SettingsPageTradeSection.copyText';
import TradeSettingsDisplay from '../components/TradeSettingsDisplay';
import { BaseUser, TradeSettings } from '../../types';

// @ts-ignore library needs typing
import { colors } from '@omniex/onx-common-ui/lib/styles';
// @ts-ignore library needs typing
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import UpdateTradeSettingsForm from '../components/UpdateTradeSettingsForm';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/segment.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const COMPONENT_NAME = 'SettingsPageOTCSection';

const cn = (elementName: string) => `${COMPONENT_NAME}-${elementName}`;

// this needs to be reused
export const StyledSection = styled('section')`

  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;

  .${COMPONENT_NAME}-segment {
    flex: 1 !important;
    padding-top: 0em !important;
    padding-bottom: 0em !important;
  }

  .${COMPONENT_NAME}-successMessage + .${COMPONENT_NAME}-segment {
    border-top-color: ${colors.successBorderColor};
  }

  .${COMPONENT_NAME}-errorMessage + .${COMPONENT_NAME}-segment {
    border-top-color: ${colors.errorBorderColor};
  }
`;

export type SettingsPageOTCSectionProps = {
  updateTradeSettings: (variables: TradeSettings) => void;
  updateTradeSettingsError?: any;
  updateTradeSettingsProcessing: boolean;
  updateTradeSettingsResult?: any;
  user: BaseUser;
}

export function SettingsPageOTCSection({
  updateTradeSettings,
  updateTradeSettingsError,
  updateTradeSettingsProcessing,
  updateTradeSettingsResult,
  user,
}: SettingsPageOTCSectionProps) {
  const [showForm, setShowForm] = useState(false);

  const closeForm = () => setShowForm(false);

  const toggleForm = () => setShowForm(p => !p);


  const handleSave = (newSettings: TradeSettings) => {
    updateTradeSettings(newSettings);
    closeForm();
  }

  return (
    <StyledSection className={COMPONENT_NAME}>
      {updateTradeSettingsResult && (
        <Message
          className={cn('successMessage')}
          attached
          closeOnClick
          content={copyText.success}
          icon="check circle outline"
          success
        />
      )}
      {updateTradeSettingsError && (
        <Message
          className={cn('errorMessage')}
          attached
          closeOnClick
          content={copyText.error}
          error
          icon="warning sign"
        />
      )}
      <Menu attached="top">
        <Menu.Item content={copyText.title} header icon="sliders" />
        <Menu.Menu position="right">
          <Menu.Item
            disabled={false}
            content={'Update Settings'}
            icon="edit"
            onClick={toggleForm}
          />
        </Menu.Menu>
      </Menu>
      {!!user && (
        <Segment className={cn('segment')} attached="bottom">
          <Grid className={cn('grid')} celled="internally">
            <Grid.Row columns={showForm ? 2 : 1}>
              <Grid.Column width={showForm ? 12 : 16}>
                <TradeSettingsDisplay
                  settings={user.settings?.trade}
                />
              </Grid.Column>
              {showForm && (
                <Grid.Column width={4}>
                  <UpdateTradeSettingsForm
                    handleCancel={closeForm}
                    handleSave={handleSave}
                    processing={updateTradeSettingsProcessing}
                    settings={user.settings?.trade}
                  />
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </Segment>
      )}
    </StyledSection>
  )
}

export default SettingsPageOTCSection;
