import config from '../../config';

const pageLayoutHeaderMenuCopy = {
  admin: 'Admin',
  custody: 'Custody',
  creditsAndAttributions: 'Credits & Attributions',
  dashboard: 'Dashboard',
  logout: 'Logout',
  portfolio: 'Portfolio',
  product: config.product,
  quote: 'Quote',
  report: 'Reports',
  requests: 'Requests',
  settings: 'Settings',
  settlement: 'Settlement',
  support: 'Support',
  termsOfUse: 'Terms Of Use',
  trade: 'Trade',
  userMgmt: 'User Mgmt',
};

export default pageLayoutHeaderMenuCopy;
