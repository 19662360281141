const dashboardPageCorrelationMatrixSectionCopy = {
  csvHeader_baseSymbol: 'Base Symbol',
  csvHeader_correlation: 'Correlation',
  csvHeader_covariance: 'Covariance',
  csvHeader_dateClosed: 'Date Closed',
  csvHeader_termSymbol: 'Term Symbol',
  error: 'An error occurred while trying to load the correlation matrix. If this error continues to occur, please contact our support team.',
  inputPlaceholder_datestamp: 'Datestamp',
  inputPlaceholder_range: 'Range',
  sectionTitle: 'Correlation Matrix'
};

export default dashboardPageCorrelationMatrixSectionCopy;
