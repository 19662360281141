import { arrayOf, bool, func, shape, string } from 'prop-types';
import { Button, Form, Icon, Input } from 'semantic-ui-react';
import React, { Fragment, PureComponent } from 'react';
import styled from 'react-emotion';

import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import copyText from './RequestsIntegrationsTable.copyText';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import Table from '@omniex/onx-common-ui/lib/semantic/react/Table';
import { isEmpty } from '@omniex/onx-common-js/lib/utils/LangUtils';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/button.css');
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');
require('@omniex/onx-common-ui/lib/semantic/css/table.css');
require('@omniex/onx-common-ui/lib/semantic/css/dropdown.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const TAB_LABEL_REQUESTED = 'Requested';
const TAB_LABEL_VERIFIED = 'Verified';

const COMPONENT_NAME = 'RequestsIntegrationsTable';

const StyledTable = styled(Table)`
  .${COMPONENT_NAME}-expandedDiv {
    display: flex;
    justify-content: space-between;
    width: 75%;
  }

  .${COMPONENT_NAME}-divWrapper {
    display: flex;
    flex-direction: column;
    line-height: 2em;
    width: 200px;

    &.accounts {
      text-align: center;
    }

    &.orgs {
      padding-top: 26px;
    }
  }

  .${COMPONENT_NAME}-header {
    font-weight: bold;
  }

  .${COMPONENT_NAME}-orgName {
    padding-left: 7px;
  }

  .${COMPONENT_NAME}-actionButton {
    margin: 5px;
    width: 100px;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class RequestedIntegrationsTable extends PureComponent {
  static propTypes = {
    activateProcessing: bool,
    clientAccounts: arrayOf(
      shape({
        id: string.isRequired,
        accountNumber: string.isRequired,
        companyName: string.isRequired
      })
    ),
    integrations: arrayOf(
      shape({
        id: string.isRequired,
        credentialsPreview: string,
        org: shape({
          id: string.isRequired,
          name: string.isRequired
        }),
        requestedBy: string.isRequired,
        requestedTime: string.isRequired
      })
    ),
    visibleTab: string,
    onActivate: func,
    onBlock: func,
    onDelete: func
  };

  static defaultProps = {
    onActivate: noop,
    onBlock: noop,
    onDelete: noop
  };

  state = { accountsList: [] };

  render() {
    if (get(this.props, 'integrations.length', 0) === 0) {
      return this._renderWarningMessage();
    }

    return (
      <StyledTable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{copyText.tableHeaderOrg}</Table.HeaderCell>
            <Table.HeaderCell>{copyText.tableHeaderUsername}</Table.HeaderCell>
            <Table.HeaderCell>{copyText.tableHeaderProvider}</Table.HeaderCell>
            <Table.HeaderCell>{copyText.tableHeaderType}</Table.HeaderCell>
            <Table.HeaderCell>
              {copyText.tableHeaderRequestDate}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.integrations.map(integration => (
            <Fragment key={integration.id}>
              <Table.Row
                onClick={this._handleClickExpandRow.bind(this, integration.id)}>
                <Table.Cell>
                  <Icon
                    name={`triangle ${
                      this.state.expandedIntegrationId === integration.id
                        ? 'down'
                        : 'right'
                    }`}
                  />
                  {integration.org.name}
                </Table.Cell>
                <Table.Cell>{integration.requestedBy}</Table.Cell>
                <Table.Cell>{copyText.venueSilvergate}</Table.Cell>
                <Table.Cell>{copyText.typeCustodian}</Table.Cell>
                <Table.Cell>{integration.requestedTime}</Table.Cell>
              </Table.Row>
              {this.state.expandedIntegrationId === integration.id ? (
                <Table.Row key={integration.id}>
                  <Table.Cell colSpan={5} padding={20}>
                    <div className={cn('expandedDiv')}>
                      <div className={cn('divWrapper')}>
                        <span className={cn('header')}>
                          {copyText.accountHolderOrg}
                        </span>
                        <span className={cn('orgName')}>
                          {copyText.orgNameOmniex}
                        </span>
                        <span className={cn('orgName')}>
                          {copyText.silvergateAccountNumber}
                        </span>
                      </div>
                      <div className={cn('divWrapper orgs')}>
                        <span>{integration.org.name}</span>
                        {this.props.visibleTab === TAB_LABEL_VERIFIED ? (
                          integration.credentialsPreview
                        ) : (
                          <Input
                            name="accountNumber"
                            placeholder={copyText.inputPlaceholder_accNumber}
                            onChange={this._handleChangeField}
                          />
                        )}
                      </div>
                      <div className={cn('divWrapper accounts')}>
                        <span className={cn('header')}>
                          {copyText.selectedOrgNames}
                        </span>
                        {this.state.accountsList.map(account => (
                          <span key={account.id}>{account.companyName}</span>
                        ))}
                      </div>
                      <div className={cn('divWrapper')}>
                        {this.props.visibleTab === TAB_LABEL_VERIFIED
                          ? this._renderBlockButton(integration.id)
                          : this._renderActivateButton(integration.id)}
                        {this.props.visibleTab === TAB_LABEL_REQUESTED
                          ? this._renderBlockButton(integration.id)
                          : this._renderDeleteButton(integration.id)}
                      </div>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ) : null}
            </Fragment>
          ))}
        </Table.Body>
      </StyledTable>
    );
  }

  _renderWarningMessage() {
    return (
      <div className={COMPONENT_NAME}>
        <Message
          content={copyText[`warningMessage${this.props.visibleTab}`]}
          warning
        />
      </div>
    );
  }

  _canSave() {
    // returns true if the account number is valid and is reflected in the list of accounts Omniex is authorized to query
    return (
      !isEmpty(this.state.accountNumber) && this.state.accountsList.length > 0
    );
  }

  _renderActivateButton(integrationId) {
    return (
      <Form onSubmit={this._handleSubmitActivate.bind(this, integrationId)}>
        <Form.Field>
          <Button
            className={cn('actionButton')}
            color="blue"
            content={copyText.actionButtonActivate}
            disabled={!this._canSave()}
            loading={this.props.activateProcessing}
            size="tiny"
            type="submit"
          />
        </Form.Field>
      </Form>
    );
  }

  _renderBlockButton(integrationId) {
    return (
      <Form onSubmit={this._handleSubmitBlock.bind(this, integrationId)}>
        <Form.Field>
          <Button
            className={cn('actionButton')}
            color="orange"
            content={copyText.actionButtonBlock}
            size="tiny"
            type="submit"
          />
        </Form.Field>
      </Form>
    );
  }

  _renderDeleteButton(integrationId) {
    return (
      <Form onSubmit={this._handleSubmitDelete.bind(this, integrationId)}>
        <Form.Field>
          <Button
            className={cn('actionButton')}
            color="red"
            content={copyText.actionButtonDelete}
            size="tiny"
            type="submit"
          />
        </Form.Field>
      </Form>
    );
  }

  _handleChangeField = (event, field) => {
    const change = { [field.name]: field.value };
    let changes = change;

    if (changes.accountNumber) {
      const accountsList = parseClientAccountList(
        this.props.clientAccounts,
        changes.accountNumber
      );

      // This needs to be modified since it's append only - if the contents of the field are deleted, then the account list state stays the same
      changes = {
        ...changes,
        accountsList
      };
    }

    this.setState(changes);
  };

  _handleSubmitActivate = (integrationId, event) => {
    event.preventDefault();

    this.props.onActivate({ integrationId, key: this.state.accountNumber });
  };

  _handleSubmitBlock = (integrationId, event) => {
    event.preventDefault();

    this.props.onBlock({ integrationId });
  };

  _handleSubmitDelete = (integrationId, event) => {
    event.preventDefault();

    this.props.onDelete({ integrationId });
  };

  _handleClickExpandRow = integrationId => {
    let accountsList = [];

    const integration = this.props.integrations.find(
      integration => integration.id === integrationId
    );

    if (this.props.visibleTab === TAB_LABEL_VERIFIED) {
      accountsList = parseClientAccountList(
        this.props.clientAccounts,
        // the credentialsPreview is the org's account number
        integration.credentialsPreview
      );
    }

    this.setState(currentState => ({
      accountsList:
        currentState.expandedIntegrationId === integrationId
          ? []
          : accountsList,
      expandedIntegrationId:
        currentState.expandedIntegrationId === integrationId
          ? undefined
          : integrationId,
      orgName: ''
    }));
  };
}

function parseClientAccountList(clientAccountList, accountNumber) {
  return clientAccountList.reduce((list, account) => {
    if (accountNumber === account.accountNumber)
      list.push({
        id: account.id,
        number: account.accountNumber,
        companyName: account.companyName
      });
    return list;
  }, []);
}
