import { createSelector } from 'reselect';

import { keyBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';

export function getInstrumentsKeyedById(instruments) {
  if (!Array.isArray(instruments) || instruments.length === 0) return {};

  return keyBy(instruments, 'id');
}

export default createSelector(
  instruments => instruments,
  getInstrumentsKeyedById
);
