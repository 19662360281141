const updateUserRolesFormCopy = {
  cancelButtonLabel: 'Cancel',
  confirmationMessageContent: 'Updating user roles can change what certain users will be able to see and access on the site.',
  confirmationMessageHeader: 'Are you sure you want to proceed?',
  inputPlaceholder_portfolios: 'Portfolios',
  inputPlaceholder_roleTypes: 'Roles',
  inputPlaceholder_userId: 'User',
  proceedButtonLabel: 'Update User',
  submitButtonLabel: 'Update User',
  userRoleTypeOption_CRYPTO_ACCOUNT_CONTROLLER: 'Crypto Account Controller',
  userRoleTypeOption_FIAT_ACCOUNT_CONTROLLER: 'Fiat Account Controller',
  userRoleTypeOption_PORTFOLIO_AUDITOR: 'Portfolio Auditor',
  userRoleTypeOption_PORTFOLIO_MANAGER: 'Portfolio Manager',
  userRoleTypeOption_SETTLEMENT_OFFICER: 'Settlement Officer',
  userRoleTypeOption_TRADER: 'Trader',
};

export default updateUserRolesFormCopy;
