import gql from 'graphql-tag';

export default gql`
  query getApiKeys {
    apiKeys: getApiKeys {
      active
      id
      key
      name
      org {
        id
      }
      timeCreated
      timeLastUpdated
    }
  }
`;
