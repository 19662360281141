const adminPageIntegrationsSectionCopy = {
  CUSTODIAN_SILVERGATE: 'Silvergate Custodian',
  errorMessageCreateAccountsPartial: 'Sorry, at least one of your accounts failed to be created. Please check the corresponding integration and try again.',
  errorMessageCreateAccountsUnexpected: 'Sorry, an unexpected error occurred while trying to create your accounts. Please try again.',
  errorMessageRequestIntegrationFailed: 'Sorry, an unexpected error occurred while trying to request your integration. Please try again.',
  errorMessageSaveIntegrationInvalidCredentials: 'Your API credentials are not valid. Please confirm your entries below and try again.',
  errorMessageSaveIntegrationFailed: 'Sorry, an unexpected error occurred while trying to save your API credentials. Please try again.',
  errorMessageUpdateIntegrationCredentialsFailed: 'Sorry, an unexpected error occurred while trying to request your integration. Please try again.',
  errorMessageUpdateIntegrationCredentialsInvalidCredentials: 'Your API credentials are not valid. Please confirm your entries below and try again.',
  EXCHANGE_BEQUANT: 'BeQuant Exchange',
  EXCHANGE_BINANCE: 'Binance Exchange',
  EXCHANGE_BINANCEUS: 'Binance US Exchange',
  EXCHANGE_BITFINEX: 'Bitfinex Exchange',
  EXCHANGE_BITFLYER: 'Bitflyer Exchange',
  EXCHANGE_BITMEX: 'BitMEX Exchange',
  EXCHANGE_BITSTAMP: 'Bitstamp Exchange',
  EXCHANGE_BITTREX: 'Bittrex Exchange',
  EXCHANGE_BYBIT: 'Bybit Exchange',
  EXCHANGE_CROSSTOWER: 'CrossTower Exchange',
  EXCHANGE_DERIBIT: 'Deribit Exchange',
  EXCHANGE_FTX: 'FTX Exchange',
  EXCHANGE_GDAX: 'Coinbase Pro Exchange',
  EXCHANGE_GEMINI: 'Gemini Exchange',
  EXCHANGE_HITBTC: 'HitBTC Exchange',
  EXCHANGE_HUOBI: 'Huobi Exchange',
  EXCHANGE_HUOBIDM: 'Huobi DM Exchange',
  EXCHANGE_ITBIT: 'itBit Exchange',
  EXCHANGE_KRAKEN: 'Kraken Exchange',
  EXCHANGE_KUCOIN: 'KuCoin Exchange',
  EXCHANGE_LIQUID: 'Liquid Exchange',
  EXCHANGE_LMAX: 'LMAX Exchange',
  EXCHANGE_OKCOIN: 'OKCoin Exchange',
  EXCHANGE_OKEX: 'OKEx Exchange',
  EXCHANGE_POLONIEX: 'Poloniex Exchange',
  EXCHANGE_SEEDCX: 'Seed CX Exchange',
  rightMenuItemLabel: 'Add Integration',
  sectionTitle: 'API Integrations',
  successMessageAccountsCreated: 'Your accounts have been created.',
  successMessageIntegrationCredentialsUpdated: 'Your credentials have been updated',
  successMessageIntegrationRequested: 'Your integration for Silvergate has been requested',
  successMessageIntegrationSaved: 'Your API credentials for the %integrationType% have been saved and verified.'
};

export default adminPageIntegrationsSectionCopy;
