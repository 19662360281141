import gql from 'graphql-tag';

const placeOrderForQuoteMutation = gql`
  mutation placeOrderForQuote(
    $fixQuoteID: String,
    $fixQuoteReqIDs: [String!]!,
    $side: String!,
    $orderType: String!,
    $expiryType: String!,
    $limitPrice: Float,
    $stopPrice: Float,
    $portfolioId: ID!
  ) {
    order: placeOrderForQuote(
      fixQuoteID: $fixQuoteID,
      fixQuoteReqIDs: $fixQuoteReqIDs,
      side: $side,
      orderType: $orderType,
      expiryType: $expiryType,
      limitPrice: $limitPrice,
      stopPrice: $stopPrice,
      portfolioId: $portfolioId,
    ) {
      id
      instrument {
        id
        baseAsset {
          id
          symbol
        }
        displayName
        termAsset {
          id
          symbol
        }
        type
      }
      fixClOrdID
      side
      specifiedQuantity
      status
      type
    }
  }
`;

export default placeOrderForQuoteMutation;
