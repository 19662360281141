import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@omniex/poms-ui/lib/themes';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import FillBlotterTable from './FillBlotterTable';
//@ts-ignore
import { colors } from '@omniex/onx-common-ui/lib/styles';
import { computeDisplayFields, displayNotification } from '../../../utils/OrderNotificationUtils.js';
import { getAvgFillPrice, getFees, getOrderType, getQuantityFilled, getQuantityOrdered, getQuantityOrderedInstrument } from '../../../utils/OrderUtils';
import { getDateFormatSimple } from '../../../utils/DisplayUtils';
import { Icon } from 'semantic-ui-react';
import { mapToRange } from '../../../utils/MathUtils';
import moment from 'moment';
import { Decimal } from 'decimal.js'
import Progress from './Progress';
import {
  FilterMap,
  Filter,
  PaginatedFillQData,
  IPaginationVariablesWithOrderId,
  Order,
  SortParams,
  IPaginationVariables,
  Notification,
  PaginatedOrderQData
} from '../../../types';
import { useLazyQuery, useQuery } from 'react-apollo';
import {
  checkFiltersActive,
  isOrderActive,
  condenseFilterOptions,
  DEFAULT_ORDER_DIRECTION,
  DEFAULT_PAGINATION_VARS,
  DEFAULT_FILL_PAGINATION_VARS,
  DEFAULT_FILL_SORT_PARAMS,
  DRILL_IN_HEIGHT,
  FILLS_BY_ORDER_ID_FILTER_OPTIONS,
  formatDuration,
  parseInitialFilters,
  getRefetchPaginationVarsWithOrderID,
  getStatusColor,
  DRILL_IN_BODY_HEIGHT,
  getVenueName,
  LIMIT,
  fillUpdateQuery,
  removeTrailingZeroesFromDisplayQuantity,
} from './shared';
import getPaginatedFillsByOrderId from '../../../apollo/graphql/buy-side/getPaginatedFillsByOrderId';
import getPaginatedOrders from '../../../apollo/graphql/buy-side/getPaginatedOrders';
import blotterCopyText, { selectedOrderCopyText as label } from './copyText';
import { IndexRange } from 'react-virtualized';
import { populateBlotterOrders } from '../../../selectors/blotterSelectors';
import { throttle } from 'lodash';
import { MultiGridHandleSortParams } from '../multigrid';
import { changed } from '../../../utils/PerformanceUtils';
import User from '../../../utils/AuthUtils';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloClient, NetworkStatus } from 'apollo-client';
import { getExecutionType } from '@omniex/poms-core/lib/utils/OrderUtils';
import ORDER_FEATURE from '@omniex/poms-core/lib/enums/OrderFeature';
import ORDER_STATUS from '@omniex/poms-core/lib/enums/OrderStatus';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxHeight: 1000,
    maxWidth: 1410,
  },
  notificationHeader: { display: 'flex' },
  notificationSection: { color: colors.red },
  notificationTable: {
    maxHeight: '126px',//6 rows x 21px
    maxWidth: '1155px',// Match the fill table width
    overflowY: 'auto',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    width: '100%',
    height: '100%'
  },
  tableContainer: {
    height: DRILL_IN_HEIGHT,
  },
  resetFiltersButton: {
    '& .MuiButton-label': {
      color: 'rgba(0, 0, 0, 0.87) !important'
    },
    '&$disabled': {
      backgroundColor: 'transparent !important',
      '& .MuiButton-label': {
        color: 'rgba(0, 0, 0, 0.26) !important'
      }
    }
  },
  disabled: {},
}));

type BodyItemProps = {
  label: string
  value: string | Array<string>
  visible: boolean
}

const renderDoubleList = (list: Array<string>) => {
  const sorted = [...list].sort((a, b) => a.localeCompare(b));
  const len = Math.ceil(sorted.length / 2);
  const left = new Array(len);
  const right = new Array(sorted.length - len);

  for (let i = 0; i < sorted.length; i++) {
    const label = sorted[i];
    const idx = i < len ? i : i - len;
    const arr = i < len ? left : right;
    arr[idx] = <Typography variant='body1' key={label}>{label}</Typography>;
  }

  return (
    <Grid container justify='flex-start'>
      <Grid item xs={4}>{left}</Grid>
      <Grid item xs={8}>{right}</Grid>
    </Grid>
  );
}

const BodyItem: React.FC<BodyItemProps> = ({ label, value, visible }) => {
  if (!visible) return null;

  return (
    <Grid container>
      <Grid item xs={4}>
        <Typography variant='body1'>{label}</Typography>
      </Grid>
      <Grid item xs={8}>
        {Array.isArray(value) ? (
          <List disablePadding dense>
            {renderDoubleList(value)}
          </List>
        ) : (
          <Typography variant='body1'>{value}</Typography>
        )}
      </Grid>
    </Grid>
)};

const getBodyItems: (
  (o: Order) => { leftBodyItems: Array<BodyItemProps>, rightBodyItems: Array<BodyItemProps>}
) = o => {
  const venue = getVenueName(o);
  const hasVenue = Boolean(venue);
  const hasDuration = Boolean(o.algorithmDuration);
  const hasParticipationRate = Boolean(o.participationRate);
  const hasDisplayQuantity = Boolean(o.displayQuantity);
  const hasLimitPrice = Boolean(o.limitPrice);
  const hasStopPrice = Boolean(o.stopPrice);
  const isActive = isOrderActive(o);
  const timeCreated = moment(o.timeCreated);
  const timeCompleted = isActive || !o.timeLastUpdated ? moment() : moment(o.timeLastUpdated);

  const leftBodyItems = [
    { label: label.orderId,       value: o.fixClOrdID, visible: true },
    { label: label.emsId,         value: o.fixOrderID, visible: true },
    { label: label.created,       value: timeCreated.format('lll'), visible: true },
    { label: label.completed,     value: timeCompleted.format('lll'), visible: !isActive },
    { label: label.reqDuration,   value: formatDuration(moment.duration(o.algorithmDuration, 'seconds')), visible: hasDuration },
    { label: label.actDuration,   value: formatDuration(moment.duration(timeCompleted.diff(timeCreated))), visible: hasDuration },
    { label: label.trader,        value: `${o.trader?.username}`, visible: true },
    { label: label.portfolio,     value: `${o.portfolio?.name}`, visible: true },
    { label: label.venue,         value: o.venueNamesSelected?.length ? o.venueNamesSelected : venue as string, visible: hasVenue },
  ];
  const rightBodyItems = [
    { label: label.instrument,    value: o.instrument.symbol, visible: true },
    { label: label.side,          value: o.side, visible: true },
    { label: label.qtyOrdered,    value: removeTrailingZeroesFromDisplayQuantity(getQuantityOrdered(o)), visible: true },
    { label: label.qtyFilled,     value: removeTrailingZeroesFromDisplayQuantity(getQuantityFilled(o)), visible: true },
    { label: label.execType,      value: getExecutionType(o) || '--', visible: true },
    { label: label.orderType,     value: getOrderType(o), visible: true },
    { label: label.tif,           value: o.expiryType, visible: true },
    { label: label.participation, value: `${o.participationRate}`, visible: hasParticipationRate },
    { label: label.displayQty,    value: `${o.displayQuantity}`, visible: hasDisplayQuantity },
    { label: label.limit,         value: `${o.limitPrice}`, visible: hasLimitPrice },
    { label: label.stop,          value: `${o.stopPrice}`, visible: hasStopPrice },
    { label: label.avgFillPrice,  value: getAvgFillPrice(o), visible: true },
    { label: label.fees,          value: getFees(o), visible: true }
  ];
  return { leftBodyItems, rightBodyItems };
};

type HeaderProps = {
  o: Order
  onClose: () => void
}

const Header: React.FC<HeaderProps> = ({ o, onClose }) => (
  <>
    <Grid container item xs={5}>
      <Grid item xs={12}>
        <Typography variant='h5'>
          {o.side} {getQuantityOrderedInstrument(o)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='subtitle1'>
          Filled {getQuantityFilled(o)} on {getVenueName(o)} @ {getAvgFillPrice(o)}
        </Typography>
      </Grid>
    </Grid>
    <Grid container item xs={5}>
      <Grid item xs={12}>
        <Typography variant='h5' style={{ color: getStatusColor(o.status) }}>
          {o.status}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h5'>
          {o.feature === ORDER_FEATURE.ALGO ?? getExecutionType(o)}
        </Typography>
      </Grid>
    </Grid>
    <Grid container item xs={2} justify='flex-end'>
      <Grid item>
        <IconButton edge='start' color='inherit' size='medium' onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  </>
)

type SelectedOrderContainerProps = {
  apiClient: ApolloClient<NormalizedCacheObject> | undefined
  checkIsOrderCancellable: (order: Order) => boolean
  fixClOrdID: string | undefined
  newNotifications: Array<Notification>
  onClose: () => void
  order: Order | null
  requestCancel: (id: string) => Promise<void>
  updateTrigger: string | undefined
  user: User
}

type SelectedOrderProps = Pick<SelectedOrderContainerProps,
  'apiClient' |
  'checkIsOrderCancellable' |
  'onClose' |
  'order' |
  'requestCancel' |
  'updateTrigger' |
  'user'
  >

const SelectedOrder: React.FC<SelectedOrderProps> = ({
  apiClient,
  checkIsOrderCancellable,
  onClose,
  order,
  requestCancel,
  updateTrigger,
  user,
}) => {
  const lastUpdate = useRef<string>();
  const [sortParams, setSortParams] = useState<SortParams>(DEFAULT_FILL_SORT_PARAMS);
  const [filters, setFilters] = useState<FilterMap>(parseInitialFilters(FILLS_BY_ORDER_ID_FILTER_OPTIONS))
  const [getFills, { called, data, loading, networkStatus, refetch, fetchMore, variables }] = useLazyQuery<PaginatedFillQData, IPaginationVariablesWithOrderId>(
    getPaginatedFillsByOrderId,
    { client: apiClient, fetchPolicy: 'network-only' },
  );
  const classes = useStyles();
  const fills = data?.fillData.fills ?? [];
  const fillCount = data?.fillData.count ?? 0;
  const isFetching = networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.fetchMore || networkStatus === NetworkStatus.refetch;

  const queryFilters = useMemo(() => condenseFilterOptions(filters), [filters]);
  const paginationVars = useMemo(() =>
    order ? getRefetchPaginationVarsWithOrderID(order.id, fills.length, sortParams, queryFilters) : null,
  [fills.length, order, queryFilters, sortParams]);


  const getFreshFills = useCallback(async () => {
    try {
      if (paginationVars && refetch) {
        await refetch(paginationVars);
      }
    } catch (e) {
      console.error(e);
    }
  }, [paginationVars, refetch]);

  const handleSubscriptionUpdate = useCallback(() => getFreshFills().catch(console.error), [getFreshFills]);
  const handleSubscriptionUpdateThrottled = useCallback(throttle(handleSubscriptionUpdate, 1.5 * 1000), [handleSubscriptionUpdate]);

  const setFilter = useCallback((c: string) => (f: Filter) => {
    setFilters(prev => ({ ...prev, [c]: f }));
  }, []);

  const resetFilters = useCallback(() => {
    setFilters(parseInitialFilters(FILLS_BY_ORDER_ID_FILTER_OPTIONS))
  }, []);

  const handleSort = useCallback(({ dataKey }: MultiGridHandleSortParams) => {
    setSortParams(prev => prev.orderParameter === dataKey
      ? {
        orderDirection: prev.orderDirection === 'DESC' ? 'ASC' : 'DESC',
        orderParameter: prev.orderParameter
      }
      : {
        orderDirection: DEFAULT_ORDER_DIRECTION,
        orderParameter: dataKey
      }
    );
  }, []);

  const fetchNextPage = useCallback(async ({ startIndex }: IndexRange) => {
    if (!order) return;
    const variables: IPaginationVariablesWithOrderId = {
      params: {
        limit: LIMIT,
        offset: startIndex,
        orderDirection: sortParams.orderDirection,
        orderParameter: sortParams.orderParameter,
      },
      filters: queryFilters,
      orderId: order?.id,
    };

    try {
      await fetchMore({ variables, updateQuery: fillUpdateQuery });
    } catch (e) {
      console.error(e);
    }
  }, [fetchMore, order, queryFilters, sortParams.orderDirection, sortParams.orderParameter]);

  useEffect(() => {
    if (!order || !getFills || called || loading) return;

    getFills({
      variables: {
        ...DEFAULT_FILL_PAGINATION_VARS,
        orderId: order.id
      }
    });
  }, [called, getFills, loading, order, paginationVars]);

  useEffect(() => {
    if (paginationVars && changed(paginationVars, variables) && !isFetching) getFreshFills();
  }, [getFreshFills, isFetching, paginationVars, variables]);

  useEffect(() => {
    if (lastUpdate.current !== updateTrigger) {
      lastUpdate.current = updateTrigger;
      handleSubscriptionUpdateThrottled();
    }
  }, [handleSubscriptionUpdateThrottled, updateTrigger]);

  if (!order) return null;

  const isActive = isOrderActive(order);
  const isOrderCancellable = checkIsOrderCancellable(order);
  const hasDuration = Boolean(order.algorithmDuration);
  const orderCreated = (new Date(order.timeCreated)).getTime();
  const lastUpdated = order.timeLastUpdated ? (new Date(order.timeLastUpdated)).getTime() : 0;
  const algoRunningTime = Math.max(0, ((isActive ? Date.now() : lastUpdated) - orderCreated));
  const totalFilled = order.calculatedQuantityFilled ?? 0;
  const progress = Math.round(parseFloat(Decimal.mul(totalFilled, 100).div(order.specifiedQuantity).toString()));
  const algoProgress = hasDuration
    ? Math.round(mapToRange(algoRunningTime, 0, 1000 * (order.algorithmDuration as number), 0, 100))
    : 0;

  const sortedNotifications = (order?.notifications || [])
    .sort((a, b) => new Date(b.timeCreated).getTime() - new Date(a.timeCreated).getTime())
    .filter(n => displayNotification(user, n, 'blotter'));

  const { leftBodyItems, rightBodyItems } = getBodyItems(order);

  return (
    <Grid container spacing={0} justify='center'>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Header o={order} onClose={onClose} />
          <Grid item xs={12} md={5}>
            {leftBodyItems.map(item =>
              <BodyItem key={item.label} label={item.label} value={item.value} visible={item.visible} />
            )}
          </Grid>
          <Grid item xs={12} md={5}>
            {rightBodyItems.map(item =>
              <BodyItem key={item.label} label={item.label} value={item.value} visible={item.visible} />
            )}
          </Grid>
          <Grid container item xs={12} md={2} direction='column' justify='space-between' alignItems='center'>
            <Grid item>
              <Progress
                progress={progress}
                size={160}
                textVariant='h4'
                inner={hasDuration}
                innerProgress={algoProgress}
                innerThickness={2}
                innerTextVariant='body1'
              />
            </Grid>
            {isOrderCancellable && (
              <Grid item>
                <Button
                  disabled={[
                    ORDER_STATUS.CANCELLATION_REQUESTED,
                    ORDER_STATUS.PENDING_CANCEL
                  ].includes(order.status)}
                  onClick={() => requestCancel(order.id)}
                  style={{ backgroundColor: 'orange' }}
                  variant='text'
                  size='small'
                >
                  {blotterCopyText.cancelOrder}
                </Button>
              </Grid>
            )}
          </Grid>
          {(sortedNotifications.length > 0) && (
            <Grid container item xs={12} md={12} className={classes.notificationSection} >
              <Grid item sm={12} md={12} className={classes.notificationHeader}>
                <Icon name='warning sign' size='large'/>
                <Typography variant='h6'>Notifications</Typography>
              </Grid>
              <Grid container className={classes.notificationTable}>
              {sortedNotifications.map(n => (
                <>
                  <Grid item sm={2}>
                    <Typography variant='body1'>{getDateFormatSimple(n.timeCreated, true)}</Typography>
                  </Grid>
                  <Grid item key={n.id} sm={10}>
                    <Typography variant='body1'>{computeDisplayFields(order, n)?.orderDetailText}</Typography>
                  </Grid>
                </>
              ))}
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container>
          <Grid item xs={10}>
            <Button classes={{ root: classes.resetFiltersButton, disabled: classes.disabled }} onClick={resetFilters} disabled={!checkFiltersActive(filters)}>
              {blotterCopyText.resetFilters}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.tableContainer}>
        <FillBlotterTable
          fetchNextPage={fetchNextPage}
          fills={fills}
          fillsCount={fillCount}
          fillsQueryLoading={loading}
          filters={filters}
          height={DRILL_IN_BODY_HEIGHT}
          handleSort={handleSort}
          selectedOrderId={order.id}
          setFilter={setFilter}
          sortParams={sortParams}
          key='byOrderId'
        />
      </Grid>
    </Grid>
  )
};

type LoadingProps = {
  loading: boolean
}

const Loading: React.FC<LoadingProps> = ({ loading }) => {
  if(!loading) return null
  else return (
    <Grid container spacing={0} justify='center'>
      <CircularProgress disableShrink />
    </Grid>
  )
};

const SelectedOrderContainer: React.FC<SelectedOrderContainerProps> = ({
  fixClOrdID,
  newNotifications,
  order,
  ...sharedProps
}) => {
  const classes = useStyles();
  const { onClose, apiClient } = sharedProps; //FIX
  const filters = fixClOrdID ? { fixClOrdID: [fixClOrdID] } : undefined;
  const { data, loading } = useQuery<PaginatedOrderQData, IPaginationVariables>(
    getPaginatedOrders, { client: apiClient, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true, variables: { ...DEFAULT_PAGINATION_VARS, filters }, skip: !fixClOrdID }
  );

  const orders = ( fixClOrdID ? data?.orderData.orders : order && [order] ) ?? [];
  const [selectedOrder] = useMemo(() => populateBlotterOrders({ newNotifications, orders }), [newNotifications, orders]);
  const open = !!selectedOrder;

  return (
    <Modal
      disableScrollLock
      onClose={onClose}
      open={open}
      closeAfterTransition
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Fade in={open}>
        <div className={classes.root}>
          <Paper square className={classes.paper}>
            <Loading loading={loading} />
            <SelectedOrder {...sharedProps} order={selectedOrder} />
          </Paper>
        </div>
      </Fade>
    </Modal>
  )
};

export default SelectedOrderContainer;
