const processOrderFormCopy = {
  messageHeader: 'How would you like to proceed?',
  messageContent: 'Choose "Fill" to execute this trade, or reject the order',
  confirmationContent:
    'The client has accepted your quoted %side% price of %price%.',
  fillButtonLabel: 'Fill',
  limitPriceLabel: 'Price',
  rejectButtonLabel: 'Reject',
  sideLabel: 'Side',
  bidTerm: 'bid',
  offerTerm: 'offer'
};

export default processOrderFormCopy;
