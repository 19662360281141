import gql from 'graphql-tag';

export default gql`
  mutation updateRecentInputHistoryByPortfolioId($inputs: UserInputByPortfolio!) {
    inputHistory: updateRecentInputHistoryByPortfolioId(inputs: $inputs) {
      id
      DashboardPageCorrelationMatrixSection_dateClosed
      TradePageBlotterSection_visibleTab
      TradePageExecutionSection_algo_instrumentDisplayName
      TradePageExecutionSection_algo_venueIds
      TradePageExecutionSection_dma_instrumentDisplayName
      TradePageExecutionSection_dma_venueId
      TradePageExecutionSection_includeSorFees
      TradePageExecutionSection_rfq_currencyId
      TradePageExecutionSection_rfq_instrumentDisplayName
      TradePageExecutionSection_rfq_venueIds
      TradePageExecutionSection_termCurrencyReferenceRate
      TradePageExecutionSection_visibleTab
      TradePageMarketDepthSection_instrumentDisplayName
      TradePageMarketDepthSection_venueId
      TradePageMarketHistorySection_chartType
      TradePageMarketHistorySection_feedSymbol
      TradePageMarketHistorySection_instrumentDisplayName
      TradePageMarketHistorySection_range
    }
  }
`;
