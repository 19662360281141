const confirmSettlementsBlotterTableCopyText = {
  popupLabelFixAllocID: 'Allocation ID',
  tableHeaderFixAllocID: 'Settlement ID',
  tableHeaderIncoming: 'Incoming',
  tableHeaderInitiatorOrg: 'Initiator',
  tableHeaderOutgoing: 'Outoing',
  tableHeaderRespondentOrg: 'Respondent',
  tableHeaderTimeExecuted: 'Time Executed',
  warningMessageNoSettlements:
    'There are no settlements associated with your account at this time.'
};

export default confirmSettlementsBlotterTableCopyText;
