import { bool, func, shape, string } from 'prop-types';
import { Button, Checkbox, Form, Popup, Icon } from 'semantic-ui-react';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import copyText from './UpdateNotificationSettingsForm.copyText';

import config from '../../config';
 
// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/button.css');
require('@omniex/onx-common-ui/lib/semantic/css/form.css');

const COMPONENT_NAME = 'UpdateNotificationSettingsForm';

const StyledForm = styled(Form)`
  min-width: 280px;
  margin: auto;

  .${COMPONENT_NAME}-sectionTitle {
    padding-bottom: 0.5em;
  }

  .${COMPONENT_NAME}-checkbox {
    padding-left: 1em;
  }

  .${COMPONENT_NAME}-deliveryMethods {
    display: flex;
    justify-content: space-between;
  }

  .${COMPONENT_NAME}-popupIcon {
    color: #006FBF;
    margin-left: 5px;
  }

  .${COMPONENT_NAME}-popupIconDenied {
    color: #e87722;
    margin-left: 5px;
  }

  .${COMPONENT_NAME}-settingsContainer {
    display: flex;
    padding: 15px;
    justify-content: space-between;
  }

  .ui.button.basic.link, .ui.button.basic.link:hover, .ui.button.basic.link:focus {
    box-shadow: 0 0 !important;
    color: #006FBF !important;
    padding: 3px !important;
    margin: 0 !important;
  }

  .${COMPONENT_NAME}-buttonContainer {
    width: 280px;
    margin: 15px auto;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

const boolOrDefault = (value, defaultValue) => typeof value === 'boolean' ? value : defaultValue

const PopupContent = ({ permissionDenied }) => (
  <div>
    <p>{permissionDenied ? copyText.popupTextDenied : copyText.popupText}</p>
    <span>Refer to our <a href={copyText.popupLink} target='_blank' rel="noopener noreferrer">support article</a> for more information.</span>
  </div>
);

const Field = ({ fieldName, deliveryMethod, disableFormFields, handleChangeField, ...props }) => (
  <Form.Field className={cn('checkbox')}>
    <Checkbox
      name={fieldName}
      checked={props[fieldName]}
      disabled={disableFormFields === true}
      label={copyText[fieldName]}
      onChange={(event, input) => handleChangeField({...input, deliveryMethod })}
    />
  </Form.Field> 
);

const Settings = (props) => (
  <div className={cn('settingFields')}>
    <Field {...props} fieldName={"notificationConditions_orderPlaced"} />
    <Field {...props} fieldName={"notificationConditions_orderCancelled"} />
    <Field {...props} fieldName={"notificationConditions_orderFilled"} />
    <Field {...props} fieldName={"notificationConditions_venueExcludedFromAlgo"} />
    <Field {...props} fieldName={"notificationConditions_venueRemovedFromAlgo"} />
    <Field {...props} fieldName={"notificationConditions_algoPaused"} />
  </div>
);

const getInitialState = (props) => {
  const {
    notificationDeliveryMethods_email,
    notificationDeliveryMethods_desktop,
  } = props;

  return {
    notificationDeliveryMethods_email: {
      notificationConditions_algoPaused: !!notificationDeliveryMethods_email?.notificationConditions_algoPaused,
      notificationConditions_orderCancelled: boolOrDefault(notificationDeliveryMethods_email?.notificationConditions_orderCancelled, true),
      notificationConditions_orderFilled: boolOrDefault(notificationDeliveryMethods_email?.notificationConditions_orderFilled, true),
      notificationConditions_orderPlaced: !!notificationDeliveryMethods_email?.notificationConditions_orderPlaced,
      notificationConditions_venueExcludedFromAlgo: !!notificationDeliveryMethods_email?.notificationConditions_venueExcludedFromAlgo,
      notificationConditions_venueRemovedFromAlgo: !!notificationDeliveryMethods_email?.notificationConditions_venueRemovedFromAlgo,
    },
    notificationDeliveryMethods_desktop: {
      notificationConditions_algoPaused: !!notificationDeliveryMethods_desktop?.notificationConditions_algoPaused,
      notificationConditions_orderCancelled: boolOrDefault(notificationDeliveryMethods_desktop?.notificationConditions_orderCancelled, true),
      notificationConditions_orderFilled: boolOrDefault(notificationDeliveryMethods_desktop?.notificationConditions_orderFilled, true),
      notificationConditions_orderPlaced: !!notificationDeliveryMethods_desktop?.notificationConditions_orderPlaced,
      notificationConditions_venueExcludedFromAlgo: !!notificationDeliveryMethods_desktop?.notificationConditions_venueExcludedFromAlgo,
      notificationConditions_venueRemovedFromAlgo: !!notificationDeliveryMethods_desktop?.notificationConditions_venueRemovedFromAlgo,
    }
  };
}

const didSettingsChange = (a={}, b={}) => {
  if (a === null || b === null) return true;

  let settingsChanged = false; 

  for(const [key, value] of Object.entries(a)){
    if(value !== b?.[key]){
      settingsChanged = true;
      break;
    }
  }

  return settingsChanged;
}
export default class UpdateNotificationSettingsForm extends PureComponent {
  static propTypes = {
    processing: bool,
    userSettings: shape({
      id: string,
      notificationDeliveryMethods_email: shape({
        notificationConditions_orderCancelled: bool,
        notificationConditions_orderFilled: bool,
        notificationConditions_orderPlaced: bool,
        notificationConditions_venueExcludedFromAlgo: bool,
        notificationConditions_venueRemovedFromAlgo: bool,
        notificationConditions_algoPaused: bool,
      }),
      notificationDeliveryMethods_desktop: shape({
        notificationConditions_orderCancelled: bool,
        notificationConditions_orderFilled: bool,
        notificationConditions_orderPlaced: bool,
        notificationConditions_venueExcludedFromAlgo: bool,
        notificationConditions_venueRemovedFromAlgo: bool,
        notificationConditions_algoPaused: bool,
      }),
    }),
    onCancel: func,
    onSave: func
  };

  static defaultProps = { onCancel: noop, onSave: noop };

  state = getInitialState(this.props.userSettings);

  render() {
    const { notificationDeliveryMethods_email, notificationDeliveryMethods_desktop } = this.state;

    const allEmailChecked = Object.values(notificationDeliveryMethods_email).every(field => field === true);
    const someEmailChecked = Object.values(notificationDeliveryMethods_email).some(field => field === true);
    const allDesktopChecked = Object.values(notificationDeliveryMethods_desktop).every(field => field === true);
    const someDesktopChecked = Object.values(notificationDeliveryMethods_desktop).some(field => field === true);
    const desktopNotificationPermissionDenied = window.Notification.permission === 'denied';
    const formFieldsChanged = didSettingsChange(notificationDeliveryMethods_email, this.props.userSettings?.notificationDeliveryMethods_email)
                              || didSettingsChange(notificationDeliveryMethods_desktop, this.props.userSettings?.notificationDeliveryMethods_desktop);

    return (
      <StyledForm className={COMPONENT_NAME}>
        <div className={cn('sectionTitle')}>
          {copyText.notificationDeliveryMethodsTitle}:
        </div>
        <div className={cn('settingsContainer')}>
          <div className={cn('emailSettingsContainer')}>
            <Form.Field>
              <Checkbox
                name="notificationDeliveryMethods_email"
                indeterminate={someEmailChecked && !allEmailChecked}
                checked={someEmailChecked}
                label={copyText.notificationDeliveryMethods_email}
                onChange={this._handleChangeAll}
              />
            </Form.Field>
            <Settings { ...notificationDeliveryMethods_email } deliveryMethod={"notificationDeliveryMethods_email"} handleChangeField={this._handleChangeField}/>
          </div>
          <div className={cn('desktopSettingsContainer')}>
            <Form.Field>
              <Checkbox
                name="notificationDeliveryMethods_desktop"
                indeterminate={someDesktopChecked && !allDesktopChecked}
                checked={someDesktopChecked}
                label={copyText.notificationDeliveryMethods_desktop}
                onChange={this._handleChangeDesktopAll}
                disabled={desktopNotificationPermissionDenied}
              />
              <Popup 
                hoverable 
                content={ <PopupContent permissionDenied={desktopNotificationPermissionDenied} /> } 
                trigger={ desktopNotificationPermissionDenied 
                  ? <Icon className={cn('popupIconDenied')} name='warning sign' /> 
                  : <Icon className={cn('popupIcon')} name='question circle outline' /> 
                } 
              />
              <span>(<Button 
                        className='link' 
                        basic 
                        compact 
                        content={copyText.testNotificationButtonLabel} 
                        onClick={this._onNotificationTestClick} 
                        disabled={!someDesktopChecked || window.Notification.permission !== 'granted'} 
                     />)
              </span>
            </Form.Field>
            <Settings { ...notificationDeliveryMethods_desktop } deliveryMethod={"notificationDeliveryMethods_desktop"} handleChangeField={this._handleChangeDesktopField} disableFormFields={desktopNotificationPermissionDenied} />
          </div>
        </div>
        <div className={cn('buttonContainer')}>
          <Form.Field>
            <Button
              color="blue"
              disabled={this.props.processing || !formFieldsChanged}
              fluid
              icon
              labelPosition="left"
              loading={this.props.processing}
              onClick={this._handleSubmit}>
              <Button.Content>
                <span>{copyText.saveButtonLabel}</span>
              </Button.Content>
            </Button>
          </Form.Field>
        </div>
        <div className={cn('buttonContainer')}>
          <Form.Field>
            <Button
              color="orange"
              disabled={this.props.processing}
              fluid
              icon
              labelPosition="left"
              loading={this.props.processing}
              onClick={this._handleCancel}>
              <Button.Content>
                <span>{copyText.cancelButtonLabel}</span>
              </Button.Content>
            </Button>
          </Form.Field>
        </div>
      </StyledForm>
    );
  }

  _onNotificationTestClick = () => new window.Notification(copyText.testNotificationTitle, {
    body: copyText.testNotificationBody,
    icon: new URL(config.urls.notificationLogo, window.location.origin).toString(),
  });

  _handleCancel = event => {
    event.preventDefault();
    this.props.onCancel();
  };

  _handleChangeField = ({ name, checked, deliveryMethod } ) => {
    this._updateField(deliveryMethod, name, checked);
  };

  _handleChangeAll = (event, input) => {
    const { name, checked } = input;

    this.setState(state => ({
      [name]: Object.keys(state[name]).reduce((changes, key) => {
        changes[key] = checked;
        return changes;
      }, {})
    }));
  };

  _handleChangeDesktopAll = (event, input) => {
    const onPermission = (permission) => {
      this._handleChangeAll(event, { ...input, checked: permission === 'granted' });
      if(permission === 'denied') this.forceUpdate();  
    }

    const { checked } = input;

    if (checked && 'Notification' in window) {

      if (window.Notification.permission === 'granted') {
        this._handleChangeAll(event, { ...input, checked });
      } else {
        this._askNotificationPermission(onPermission);
      }

    } else {
      this._handleChangeAll(event, { ...input, checked: false });
    }


  };

  // https://developer.mozilla.org/en-US/docs/Web/API/Notifications_API/Using_the_Notifications_API#feature-detecting_the_requestpermission_promise
  _checkNotificationPromise = () => {
    try {
      window.Notification.requestPermission().then();
    } catch(e) {
      return false;
    }
    return true;
  }

  _updateField = (deliveryMethod, field, value) => {
    this.setState(state => ({ [deliveryMethod]: { ...state[deliveryMethod], [field]: value }}));
  }

  _askNotificationPermission = (onPermission) => {
    if (this._checkNotificationPromise()) {
      window.Notification.requestPermission().then(onPermission)
    } else {
      window.Notification.requestPermission(onPermission)
    }
  }

  _handleChangeDesktopField = ({ name, checked }) => {
    const onPermission = (permission) =>{
      this._updateField("notificationDeliveryMethods_desktop", name, permission === 'granted');
      // if user blocks the permission request, component will not rerender without this because notificationDeliveryMethods_desktop is already false.
      // forced rerender is required because of test button and popup icon/text
      if(permission === 'denied') this.forceUpdate();  
    };

    if (checked && 'Notification' in window) {

      if (window.Notification.permission === 'granted') {
        this._updateField("notificationDeliveryMethods_desktop", name, true);
      } else {
        this._askNotificationPermission(onPermission);
      }

    } else {
      this._updateField("notificationDeliveryMethods_desktop", name, false);
    }
  }

  _handleSubmit = event => {
    event.preventDefault();
    const { notificationDeliveryMethods_email, notificationDeliveryMethods_desktop } = this.state;
    this.props.onSave({ notificationDeliveryMethods_email, notificationDeliveryMethods_desktop });
    this.setState(
      getInitialState(this.props.userSettings)
    );
  };
}
