import { createSelector } from 'reselect';

import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { isNil } from '@omniex/onx-common-js/lib/utils/LangUtils';
// import getCurrenciesKeyedById from './getCurrenciesKeyedById';
import getInstrumentsKeyedById from './getInstrumentsKeyedById';
import getVenuesKeyedById from './getVenuesKeyedById';

// export function populateOrders(orders, instruments, currencies, venues) {
export function populateOrders(orders, instruments, venues) {
  if (
    !Array.isArray(orders) ||
    orders.length === 0 ||
    !Array.isArray(instruments) ||
    instruments.length === 0 ||
    // !Array.isArray(currencies) ||
    // currencies.length === 0 ||
    !Array.isArray(venues)
  ) {
    return [];
  }

  // const currenciesKeyedById = getCurrenciesKeyedById(currencies);
  const instrumentsKeyedById = getInstrumentsKeyedById(instruments);
  const venuesKeyedById = getVenuesKeyedById(venues);

  return orders.map(order => {
    const instrument = instrumentsKeyedById[get(order, 'instrument.id')];
    // const specifiedCurrency =
    //   currenciesKeyedById[get(order, 'specifiedCurrency.id')];
    const specifiedCurrency = get(order, 'specifiedCurrency');
    const venue = venuesKeyedById[get(order, 'venue.id')];

    let averageFillPrice; // Weighted Average. totalCost divided by filledQuantity.
    let filledQuantity; // Total quantity of all fills associated with an order.

    if (get(order, 'fills.length', 0) > 0) {
      let totalCost; // The sum of the cost of all fills associated with an order. Cost = price * quantity.

      order.fills = order.fills.map(fill => ({
        ...fill,
        venue: isNil(venue) ? {} : venue
      }));

      [totalCost, filledQuantity] = order.fills.reduce(
        ([totalCost, filledQuantity], fill) => {
          totalCost += fill.price * fill.quantity;

          filledQuantity += fill.quantity;

          return [totalCost, filledQuantity];
        },
        [0, 0]
      );

      averageFillPrice = totalCost / filledQuantity;
    }

    return {
      ...order,
      ...(isNil(averageFillPrice) ? {} : { averageFillPrice }),
      ...(isNil(filledQuantity) ? {} : { filledQuantity }),
      instrument,
      specifiedCurrency,
      ...(isNil(venue) ? {} : { venue })
    };
  });
}

export default createSelector(
  // (orders, instruments, currencies, venues) => orders,
  // (orders, instruments, currencies, venues) => instruments,
  // (orders, instruments, currencies, venues) => currencies,
  // (orders, instruments, currencies, venues) => venues,
  (orders, instruments, venues) => orders,
  (orders, instruments, venues) => instruments,
  (orders, instruments, venues) => venues,
  populateOrders
);
