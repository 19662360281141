const quotePageRequestsSectionCopy = {
  errorMessageClaimQuoteRequestFailed:
    'Sorry, we encountered an issue while attempting to claim this quote request for you. Please try again.',
  errorMessageFillOrderFailed:
    'Sorry, we encountered an issue while attempting to fill this order for you. Please try again',
  errorMessageCancelOrderFailed:
    'Sorry, we encountered an issue while attempting to reject this order for you. Please try again',
  errorMessageCancelQuoteRequestFailed:
    'Sorry, we encountered an issue while attempting to reject this quote request for you. Please try again',
  errorMessageSendQuoteFailed:
    'Sorry, we encountered an issue while attempting to send your quote. Please try again',
  loadingMessage: 'Loading...',
  sectionTitle: 'RFQ',
  successMessageOrderFilled:
    'The RFQ order <code>%fixVenueOrderID%</code> was filled. See blotter below for full details.',
  successMessageOrderCanceled:
    'The RFQ order <code>%fixVenueOrderID%</code> was rejected. See blotter below for full details.',
  successMessageQuoteRequestCanceled:
    'The selected quote request was successfully canceled.',
  tabLabelClaimed: 'Claimed',
  tabLabelUnclaimed: 'Unclaimed'
};

export default quotePageRequestsSectionCopy;
