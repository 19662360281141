import { bool, func, object } from 'prop-types';
import React from 'react';
import styled from 'react-emotion';

import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import SettingsPageUserInfoSection from '../pages/SettingsPageUserInfoSection';
import SettingsPageNotificationSettingsSection from '../pages/SettingsPageNotificationSettingsSection';
import SettingsPageTradeSection from './SettingsPageTradeSection';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/grid.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');
require('@omniex/onx-common-ui/lib/semantic/css/popup.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const COMPONENT_NAME = 'SettingsPage';

const StyledDiv = styled('div')`
  height: 100%;
  overflow: hidden;

  .${COMPONENT_NAME}-sectionWrapper {
    width: 100%;
    padding-top: 20px;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

const SettingsPage = ({
  updateAuthenticatedUserPassword = noop,
  updateAuthenticatedUserPasswordMutationError,
  updateAuthenticatedUserPasswordMutationProcessing,
  updateAuthenticatedUserPasswordMutationResult,
  updateNotificationSettings = noop,
  updateNotificationSettingsMutationError,
  updateNotificationSettingsMutationProcessing,
  updateNotificationSettingsMutationResult,
  updateTradeSettings = noop,
  updateTradeSettingsMutationError,
  updateTradeSettingsMutationProcessing,
  updateTradeSettingsMutationResult,
  user,
}) => (
  <StyledDiv className={COMPONENT_NAME}>
      <main>
        <div className={cn('sectionWrapper')}>
          <SettingsPageUserInfoSection
            updateAuthenticatedUserPassword={updateAuthenticatedUserPassword}
            updateAuthenticatedUserPasswordMutationError={updateAuthenticatedUserPasswordMutationError}
            updateAuthenticatedUserPasswordMutationProcessing={updateAuthenticatedUserPasswordMutationProcessing}
            updateAuthenticatedUserPasswordMutationResult={updateAuthenticatedUserPasswordMutationResult}
            user={user}
          />
        </div>
        {user?.canViewTrade && (
          <div className={cn('sectionWrapper')}>
            <SettingsPageNotificationSettingsSection
              updateNotificationSettings={updateNotificationSettings}
              updateNotificationSettingsMutationError={updateNotificationSettingsMutationError}
              updateNotificationSettingsMutationProcessing={updateNotificationSettingsMutationProcessing}
              updateNotificationSettingsMutationResult={updateNotificationSettingsMutationResult}
              user={user}
            />
          </div>
        )}
        {user?.canViewTrade && (
          <div className={cn('sectionWrapper')}>
            <SettingsPageTradeSection
              updateTradeSettings={updateTradeSettings}
              user={user}
              updateTradeSettingsError={updateTradeSettingsMutationError}
              updateTradeSettingsProcessing={updateTradeSettingsMutationProcessing}
              updateTradeSettingsResult={updateTradeSettingsMutationResult}
            />
          </div>
        )}
      </main>
  </StyledDiv>
)

SettingsPage.propTypes = {
  updateAuthenticatedUserPassword: func,
  updateAuthenticatedUserPasswordMutationError: object,
  updateAuthenticatedUserPasswordMutationProcessing: bool,
  updateAuthenticatedUserPasswordMutationResult: object,
  updateNotificationSettings: func,
  updateNotificationSettingsMutationError: object,
  updateNotificationSettingsMutationProcessing: bool,
  updateNotificationSettingsMutationResult: object,
  user: object,
}

export default SettingsPage
