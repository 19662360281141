import gql from 'graphql-tag';

export default gql`
  query getRecentlyCompletedOrders {
    orders: getRecentlyCompletedOrders {
      id
      fills {
        id
        price
        quantity
        specifiedCurrency {
          id
          symbol
        }
        timeCreated
      }
      fixClientID
      fixClOrdID
      fixOrderID
      fixQuoteID
      fixVenueOrderID
      instrument {
        id
      }
      limitPrice
      quote {
        id
        venueTrader {
          id
          username
        }
      }
      side
      specifiedCurrency {
        id
      }
      specifiedQuantity
      status
      timeCreated
      timeLastUpdated
      type
    }
  }
`;
