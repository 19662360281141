const durationFormCopy = {
  afterHeader: 'Algo Ends After Maintenance',
  afterUpper: 'The selected %algoStrategy% algo duration of %algoDuration% overlaps with the Omniex maintenance window, which begins %maintenanceStartTime% %maintenanceStartTimeUTC%and lasts up to %maintenanceDuration%. During that time, no trades can be made, altered, or canceled. Please be aware: Trading may recommence before access to Edge is restored.',
  afterLower: 'Would you prefer to shorten the duration such that it completes when the maintenance window begins (~%hoursToMaintenance%) or keep it open such that the algorithm resumes as soon as permitted?',
  duringHeader: 'Algo Ends During Maintenance',
  duringUpper: 'The selected %algoStrategy% algo duration of %algoDuration% is set to expire during the Omniex maintenance window, which begins %maintenanceStartTime% %maintenanceStartTimeUTC%and may last up to %maintenanceDuration%. During that time, no trades can be made, altered, or canceled.',
  duringLower:'You may choose to shorten the duration such that it completes when the maintenance window begins (~%hoursToMaintenance%).'
};

export default durationFormCopy;
