import { arrayOf, func, number, shape, string } from 'prop-types';
import { Icon, Loader, Menu, Segment } from 'semantic-ui-react';
import React, { Component } from 'react';
import styled from 'react-emotion';

import { arrayOfShape, queryShape } from '../../utils/PropTypeUtils';
import { changed } from '../../utils/PerformanceUtils';
import { get, has } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { hasMarketDataFor } from '../../utils/MarketDataUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { unique } from '@omniex/poms-core/lib/utils/ArrayUtils';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './TradePageMarketDepthSection.copyText';
import Dropdown from '@omniex/onx-common-ui/lib/react-select/Dropdown';
import filterMarketDataEntriesByInstrumentsAndVenues from '../../selectors/filterMarketDataEntriesByInstrumentsAndVenues';
import findTopOfBook from '../../selectors/findTopOfBook';
import getGroupedInstrumentOptions from '../../selectors/getGroupedInstrumentOptions';
import getInstrumentFromNameVenue from '../../selectors/getInstrumentFromNameVenue';
import getVenuesWithInstrument from '../../selectors/getVenuesWithInstrument';
import { getGroupedVenueOptions } from '../../selectors/getGroupedVenueOptions';
import InstrumentType from '@omniex/poms-core/lib/enums/InstrumentType';
import { isEmpty } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { keyBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import MarketDepthChart from '../components/MarketDepthChart';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import MultiSelect from '../components/MultiSelect';
import TopOfBookComponent from '../../ui/components/TopOfBookComponent';
import VenueType from '@omniex/poms-core/lib/enums/VenueType';
import { getFilteredVenues, getSortedInstrumentsFromVenues } from '../../selectors/blotterSelectors';

const ERROR_NO_DATA = 'errorMessageNoMarketDataAvailable';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/input.css');
require('@omniex/onx-common-ui/lib/semantic/css/loader.css');
require('@omniex/onx-common-ui/lib/semantic/css/segment.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const COMPONENT_NAME = 'TradePageMarketDepthSection';

const allOption = {label: copyText.defaultVenueOptionLabel, value: '*'};

const StyledSection = styled('section')`
  display: flex !important;
  flex-direction: column !important;
  min-height: 100%;

  .${COMPONENT_NAME}-selection {
    margin: 5px 10px;

    :first-child {
      width: 200px;
    }

    :last-child {
      width: 200px;
    }
  }

  .${COMPONENT_NAME}-segment {
    flex: 1 !important;
    min-height: 400px;

    &.shorter {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 250px;
    }
  }

  .${COMPONENT_NAME}-segmentTopOfBook {
    padding: 0 !important;
  }

  .${COMPONENT_NAME}-errorMessage + .${COMPONENT_NAME}-segment {
    border-top-color: ${colors.errorBorderColor};
  }

  .${COMPONENT_NAME}-warningMessage + .${COMPONENT_NAME}-segment {
    border-top-color: ${colors.warningBorderColor};
    padding: 14px;
  }

  .${COMPONENT_NAME}-iconWrapper {
    align-items: center;
    display: flex !important;
    flex: 1 !important;
    justify-content: space-around;
    min-height: 371px;
  }

  .${COMPONENT_NAME}-icon {
    color: #e8e8e8 !important;
  }

  .${COMPONENT_NAME}-configSegment {
    height: 77px;
  }

  .${COMPONENT_NAME}-configOverflowWrapper {
    height: 100vh;
    overflow-x: auto;
  }

  .${COMPONENT_NAME}-configWrapper {
    height: auto;
    min-width: 375px;
    display: flex;
    flex-direction: row;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

const isDerivative = (i = {}) => [InstrumentType.FUTURE, InstrumentType.OPTION].includes(i?.type);

const MAX_WAIT_TIME = 5000;


const ConfigSegment = ({ groupedInstrumentOptions, groupedVenueOptions, venueOptions, instrument = {}, instrumentSelection, loading, venueSelection, error, onChange }) => {
  const [VenueInput, options] = isDerivative(instrument) ? [Dropdown, venueOptions] : [MultiSelect, groupedVenueOptions]
  return (
    <Segment attached className={cn('configSegment')}>
      <div className={cn('configOverflowWrapper')}>
        <div className={cn('configWrapper')}>
          <Dropdown
            className={cn('selection')}
            name="instrumentDisplayName"
            isDisabled={loading}
            options={groupedInstrumentOptions}
            placeholder={copyText.inputPlaceholder_instrument}
            value={instrumentSelection}
            onChange={onChange}
          />
          <div className={cn('selection')}>
            <VenueInput
              name="venueId"
              isDisabled={!instrumentSelection || loading || !!error}
              options={options}
              placeholder={copyText.inputPlaceholder_venue}
              value={venueSelection}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
    </Segment>
  )
}

const ErrorMessage = ({ error }) => (
  <Message
    attached
    className={cn('errorMessage')}
    closeOnClick
    content={copyText[error]}
    icon="warning sign"
    error
  />
);

const Content = ({ bestBid, bestOffer, entries = [], instrument, loading, error, venueMap }) => error ? (
  <Segment className={cn('segment')} attached="bottom">
    <div className={cn('iconWrapper')}>
      <Icon className={cn('icon')} name="warning sign" size="huge" />
    </div>
  </Segment>
) : loading ? (
  <Segment className={cn('segment')} attached="bottom">
    <Loader
      className={cn('loader')}
      active
      content={copyText.loadingMessage}
      size="medium"
    />
  </Segment>
) : !instrument ? (
  <Segment className={cn('segment')} attached="bottom">
    <Message
      className={cn('warningMessage')}
      content={copyText.warningMessage}
      warning
    />
  </Segment>
) : entries.length === 0 || !instrument ? (
  <Segment className={cn('segment')} attached="bottom">
    <div className={cn('iconWrapper')}>
      <Icon className={cn('icon')} name="area graph" size="huge" />
    </div>
  </Segment>
) : (
  <>
    <Segment className={cn('segmentTopOfBook')} attached>
      <TopOfBookComponent
        bid={bestBid}
        instrument={instrument}
        offer={bestOffer}
        venueMap={venueMap}
      />
    </Segment>
    <Segment className={cn('segment shorter')} attached="bottom">
      <MarketDepthChart
        entries={entries}
        height={275}
        instrument={instrument}
        venueMap={venueMap}
      />
    </Segment>
  </>
);


const currencyShape = shape({ id: string.isRequired }).isRequired;

export default class TradePageMarketDepthSection extends Component {
  static propTypes = {
    configureMarketDataFeed: func,
    marketDataFeedSubscription: queryShape({
      marketDataEntries: arrayOfShape({
        id: string.isRequired,
        fixMDStreamID: string.isRequired,
        price: number.isRequired,
        type: string.isRequired,
        venueSymbol: string.isRequired,
      })
    }),
    orgQuery: queryShape({
      org: shape({
        homeCurrency: currencyShape,
        relationships: arrayOfShape({
          id: string.isRequired,
          venue: shape({
            id: string.isRequired,
            symbol: string.isRequired,
            type: string.isRequired,
          })
        })
      })
    }),
    recentInputHistoryQuery: queryShape({
      inputHistory: shape({
        TradePageMarketDepthSection_instrumentDisplayName: arrayOf(string),
        tradePageMarketDepthSection_venueId: arrayOf(string),
      })
    }),
    updateRecentInputHistory: func,
  }

  static defaultProps = {
    configureMarketDataFeed: noop,
    updateRecentInputHistory: noop,
  }

  state = { loaded: false, loading: false }

  shouldComponentUpdate(newProps, newState) {
    return changed(this.props, newProps) || changed(this.state, newState);
  }

  render() {
    const { instrumentDisplayName, venueIds, error } = this.state;
    const loading = this._isLoading();

    const { instruments, venues, allVenues, groupedInstrumentOptions, groupedVenueOptions, venueOptions } = this._getOptions();

    let entries = get(this.props, 'marketDataFeedSubscription.data.marketDataEntries') || [];
    entries = filterMarketDataEntriesByInstrumentsAndVenues(entries, instruments, venues);

    const [bestBid, bestOffer] = findTopOfBook(entries);

    const venueMap = keyBy(allVenues, 'symbol');

    const referenceInstrument = instruments[0];

    const configProps = {
      groupedInstrumentOptions,
      groupedVenueOptions,
      instrument: referenceInstrument,
      instrumentSelection: instrumentDisplayName && { label: instrumentDisplayName, value: instrumentDisplayName },
      loading,
      venueOptions,
      venueSelection: (venueIds || []).map(id => venueOptions.find(v => v.value === id)),
      error,
      onChange: this._handleChangeField,
    };

    const contentProps = {
      loading,
      instrument: referenceInstrument,
      entries,
      bestBid,
      bestOffer,
      error,
      venueMap,
    };

    return (
      <StyledSection className={COMPONENT_NAME}>
        <Menu attached="top" borderless>
          <Menu.Item content={copyText.sectionTitle} header icon="area graph" />
        </Menu>
        <ConfigSegment { ...configProps } />
        {!loading && error && <ErrorMessage error={error} />}
        <Content { ...contentProps } />
      </StyledSection>
    );
  }

  componentDidMount() {
    this._subscribe();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this._initialLoading()) {
      const { loaded, loading, error } = this.state;
      const instrument = this._getInstrumentFromNameVenue();
      const instrumentsKeyedById = keyBy([instrument], 'id');
      const entries = get(this.props, 'marketDataFeedSubscription.data.marketDataEntries') || [];

      if (!loaded) {
        let { instrumentDisplayName, venueIds } = this._getInputHistory();

        const { venueOptions, instruments } = this._getOptions(instrumentDisplayName);

        venueIds = isDerivative(instruments?.[0]) ? [venueOptions?.[0]?.value] : venueIds;

        const newState = {
          loaded: true,
          instrumentDisplayName,
          venueIds,
        };

        this.setState(newState, this._subscribe);
      } else if ((error || loading) && this._isSubscribed() && hasMarketDataFor(instrument?.id, instrumentsKeyedById, entries)) {
        clearTimeout(this._timer);
        this.setState({error: undefined, loading: false});
      } else if (!loading) this._subscribe();
    }
  }

  componentWillUnmount() {
    clearTimeout(this._timer);
  }

  _handleChangeField = (selected, event) => {
    const { name } = event;

    const updateInstrument = name === 'instrumentDisplayName';
    const updateVenue = name === 'venueId';

    const newState = {};
    let value = '';
    let inputHistory = [];

    if (updateInstrument) {
      value = selected.value;
      if (this.state[name] === value) return;

      const referenceInstrument = this._getInstruments(value)?.[0];
      const isDerivativeInstrument = isDerivative(referenceInstrument);

      const { venueOptions } = this._getOptions(value, []);

      newState.instrumentDisplayName = value;
      newState.venueIds = isDerivativeInstrument
        ? [venueOptions?.[0]?.value]
        : venueOptions.map(v => v.value);

      inputHistory = [
        { key: `${COMPONENT_NAME}_instrumentDisplayName`, value },
        { key: `${COMPONENT_NAME}_venueId`, value: newState.venueIds }
      ];

    } else if (updateVenue) {
      const referenceInstrument = this._getInstruments()?.[0];
      const isDerivativeInstrument = isDerivative(referenceInstrument);

      newState.venueIds = isDerivativeInstrument ? [selected.value] : selected.map(s => s.value);

      inputHistory = [{ key: `${COMPONENT_NAME}_venueId`, value: newState.venueIds }];
    }

    if (!isEmpty(inputHistory)) this.props.updateRecentInputHistory({ inputs: inputHistory });

    this.setState(newState, _ => updateInstrument && this._subscribe());
  }

  _isSubscribed = _ => {
    const iMDC = get(this.props, 'instruments.MDC') || [];
    return iMDC.includes(this._getInstrumentFromNameVenue().id);
  }

  _subscribe = _ => {
    const { instrumentDisplayName, venueIds } = this.state;

    if (!instrumentDisplayName || isEmpty(venueIds) || this._isSubscribed()) return;

    const instrumentId = this._getInstrumentFromNameVenue()?.id;

    this.setState({error: undefined, loading: true}, _ => {
      this.props.updateInstruments('MDC', [instrumentId]);
      clearTimeout(this._timer);
      this._timer = setTimeout(this._handleError, MAX_WAIT_TIME);
    });
  }

  _handleError = _ => this.setState({error: ERROR_NO_DATA, loading: false});

  _initialLoading = _ => (
    get(this.props, 'orgQuery.loading') ||
    !has(this.props, 'orgQuery.data.org') ||
    get(this.props, 'recentInputHistoryQuery.loading') ||
    !has(this.props, 'recentInputHistoryQuery.data.inputHistory')
  );

  _isLoading = _ => this._initialLoading() || this.state.loading || !this.state.loaded;

  _getInstrumentFromNameVenue = _ => {
    const { instrumentDisplayName, venueIds = [] } = this.state;
    const venues = this._getPopulatedVenues();
    return getInstrumentFromNameVenue(venues, instrumentDisplayName, 'id', venueIds[0]);
  }

  _getInstruments = instrumentDisplayName => {
    instrumentDisplayName = instrumentDisplayName || this.state.instrumentDisplayName;
    const allInstruments = this._getPopulatedInstruments();
    return unique(allInstruments.filter(i => i.displayName === instrumentDisplayName), 'id');
  }

  _getOptions = (instrumentDisplayName, venueIds) => {
    instrumentDisplayName = instrumentDisplayName || this.state.instrumentDisplayName;
    venueIds = venueIds || this.state.venueIds || [];
    const allVenues = this._getPopulatedVenues();
    const allInstruments = this._getPopulatedInstruments();

    let instruments = this._getInstruments(instrumentDisplayName);
    if (isDerivative(instruments[0]) && venueIds.length) instruments = [getInstrumentFromNameVenue(allVenues, instrumentDisplayName, 'id', venueIds[0])];

    const venues = venueIds.includes(allOption.value)
      ? undefined
      : allVenues.filter(venue => venueIds.includes(venue.id));

    const nameToInst = keyBy(allInstruments, 'displayName');
    const recentInstruments = this._inputHistoryFor('instrumentDisplayName', []).reduce((options, n) => {
      if (n && nameToInst[n]) options.push({displayName: n});
      return options;
    }, []);
    const groupedInstrumentOptions = getGroupedInstrumentOptions(allInstruments, recentInstruments);
    const venuesWithInstrument = getVenuesWithInstrument(allVenues, instrumentDisplayName);
    const groupedVenueOptions = getGroupedVenueOptions(venuesWithInstrument);

    const flattener = (list, item) => (list.push(...item.options), list); //eslint-disable-line no-sequences
    const venueOptions = groupedVenueOptions.reduce(flattener, []);

    return { instruments, venues, allVenues, groupedInstrumentOptions, groupedVenueOptions, venueOptions, venuesWithInstrument };
  }

  _getPopulatedVenues = _ => {
    const relationships = this.props.orgQuery?.data?.org?.relationships || [];
    const venues = getFilteredVenues({ relationships }, { venueType: VenueType.EXCHANGE, supportsEsp: true });

    return venues;
  }

  _getPopulatedInstruments = _ => {
    const relationships = this.props.orgQuery?.data?.org?.relationships || [];
    const instruments = getSortedInstrumentsFromVenues({ relationships }, { venueType: VenueType.EXCHANGE, supportsEsp: true });

    return instruments;
  }

  _inputHistoryFor = (str, dflt) => this.props.recentInputHistoryQuery?.data?.inputHistory?.[`${COMPONENT_NAME}_${str}`] || dflt;

  _getInputHistory = _ => ({
    instrumentDisplayName: this._inputHistoryFor('instrumentDisplayName', [])[0] || '',
    venueIds: this._inputHistoryFor('venueId', []),
  });
}
