import * as React from 'react';
import styled from 'react-emotion';
import copyText from './TradeSettings.copyText.js';
import { has } from 'lodash';
// @ts-ignore library needs typing
import { colors } from '@omniex/onx-common-ui/lib/styles';
// @ts-ignore library needs typing
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import { TradeSettings } from '../../types';

const COMPONENT_NAME = 'TradeSettingsDisplay';

const cn = (elementName: string) => `${COMPONENT_NAME}-${elementName}`;

const StyledDiv = styled('div')`
  min-width: 150px;

  .${COMPONENT_NAME}-section {
    display: flex;

    div.${COMPONENT_NAME}-items {
      align-content: flex-start;

      ul {
        padding-left: 0;
        margin-top: 0;
        margin-bottom: 0;
        list-style-type: none;
      }
    }

    div:first-child {
      color: ${colors.grey};
      display: inline-block;
      font-size: 12px;
      min-width: 150px;
      text-align: right;
    }

    div:last-child {
      display: inline-block;
      margin-bottom: 10px;
      margin-left: 10px;
    }

    :last-child {
      div:last-child {
        margin-bottom: 0;
      }
    }

    li > span {
      margin-right: 16px;
    }
  }
`;

export type TradeSettingsDisplayProps = {
  settings?: TradeSettings;
}

export function TradeSettingsDisplay({ settings }: TradeSettingsDisplayProps) {
  return (
    <StyledDiv className={COMPONENT_NAME}>
      {!!settings && has(settings, 'executeOnSingleClickOTC') ? (
        <div className={cn('section')}>
          <div className={cn('items')}>
            <ul>
              <li>
                <span>{copyText.otcClickLabel}:</span>
                <span>{settings.executeOnSingleClickOTC ? copyText.singleClickLabel : copyText.doubleClickLabel}</span>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <Message
          className={cn('warningMessage')}
          attached
          content={copyText.noSettings}
          warning
        />
      )}
    </StyledDiv>
  );
}

export default TradeSettingsDisplay;
