import { arrayOf, object } from 'prop-types';
import { Icon } from 'semantic-ui-react';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import AssetType from '@omniex/poms-core/lib/enums/AssetType';
import { formatNum } from '../../utils/SettlementUtils';
import { orderBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';

const COMPONENT_NAME = 'NetFlow';
const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

const StyledDiv = styled('div')`
  margin-left: 7px;
  display: flex;

  .${COMPONENT_NAME}-flow {
    min-width: 200px !important;
    padding-left: 20px;
  }
`;

const trunc = (q, t) => (t === AssetType.FIAT) ? q.toFixed(2) : q.toPrecision(5);
const format = n => `${formatNum(parseFloat(trunc(Math.abs(n.quantity), n.currencyType)), n.currencyType)} ${n.currencySymbol}`;

export default class NetFlow extends PureComponent {
  static propTypes = {
    net: arrayOf(object),
  }

  render() {
    const { net } = this.props;
    const flows = orderBy(Object.keys(net), ['currencyType', 'currencySymbol'], ['asc', 'asc']).reduce((accum, id) => {
      const n = net[id]
      if (n.quantity > 0) {
        accum.pos.push(format(n))
      } else if (n.quantity < 0) {
        accum.neg.push(format(n))
      }
      return accum
    }, {pos: [], neg:[]});

    return (
      <StyledDiv>
        {renderFlow(flows.pos, "plus", "green")}
        {renderFlow(flows.neg, "minus", "red")}
      </StyledDiv>
    );
  }
}

const renderFlow = (flow, name, color) => (
  <div className={cn('flow')}>
    {flow.length !== 0 &&
      <div>
        <Icon name={name} color={color} />
        <span>{flow.join(", ")}</span>
      </div>
    }
  </div>
);
