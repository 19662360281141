import moment from 'moment-timezone'
import {
  MAINTENANCE_REDIRECT_DAY_EST,
  MAINTENANCE_REDIRECT_HOUR_EST,
  MAINTENANCE_END_DAY_EST,
  MAINTENANCE_END_HOUR_EST,
  MAINTENANCE_TIME_ZONE,
  MAINTENANCE_WARNING_MINUTES_BEFORE_REDIRECT,
  SECONDS_IN_FULL_WEEK,
} from '../constants/maintenance.js'

const calendarFormat = {
  lastWeek: '[last] dddd [at] LT',
  lastDay: '[yesterday at] LT',
  sameDay: '[today at] LT',
  nextDay: '[tomorrow at] LT',
  nextWeek: 'dddd [at] LT',
  sameElse: 'MM/DD/YYYY [at] LT',
}

//Internal Functions

function getCurrentTimePlusDuration(duration, timeUnit) {
  return moment().add(parseInt(duration), timeUnit)
}

function getUserTimeZoneOffset() {
  return moment.tz.guess()
}

function nextWeeklyMaintenanceStart() {
  const startTime = moment()
    .tz(MAINTENANCE_TIME_ZONE)
    .isoWeekday(MAINTENANCE_REDIRECT_DAY_EST)
    .hours(MAINTENANCE_REDIRECT_HOUR_EST)
    .minutes(0)
    .seconds(0)
    .millisecond(0)

  return startTime.isBefore(moment())
    ? startTime.add(7, 'd')
    : startTime
}

//Exported Functions

export function convertDateToLocalTime(date) {
  return moment(date).tz(getUserTimeZoneOffset()).format();
}

export function getMaintenanceCalendarStartTime() {
  return nextWeeklyMaintenanceStart().tz(getUserTimeZoneOffset()).calendar(moment(), calendarFormat)
}

export function getMaintenanceStartDate() {
  return nextWeeklyMaintenanceStart().toDate()
}

export function getMaintenanceStartWeekday() {
  return nextWeeklyMaintenanceStart().tz(getUserTimeZoneOffset()).format('d')
}

export function getTimeToMaintenance(timeUnit) {
  return nextWeeklyMaintenanceStart().diff(moment(), timeUnit, true)
}

export function getTimeToWarnStart(timeUnit) {
  return nextWeeklyMaintenanceStart()
    .hours(MAINTENANCE_REDIRECT_HOUR_EST - 1)
    .minutes(60 - MAINTENANCE_WARNING_MINUTES_BEFORE_REDIRECT)
    .diff(moment(), timeUnit)
}

// returns the number of milliseconds until the maintenance warning should be shown
export function getTimeToMaintenanceWarning() {
  let t = getTimeToWarnStart('seconds')
  // if we're between maintenance start and the end of the week, add an extra week
  // to avoid showing a warning on friday night, saturday, sunday
  if (t < -60 * MAINTENANCE_WARNING_MINUTES_BEFORE_REDIRECT) t += SECONDS_IN_FULL_WEEK
  return Math.max(0, t * 1000)
}

export function getUserTimeZone() {
  return moment().tz(getUserTimeZoneOffset()).format('z')
}

export function getUTCMaintenanceCalendarStartTime() {
  return nextWeeklyMaintenanceStart().utc().calendar(null, calendarFormat)
}

export function getUTCMaintenanceStartTime() {
  return nextWeeklyMaintenanceStart().utc().format('LT')
}

export function getUTCMaintenanceStartWeekday() {
  return nextWeeklyMaintenanceStart().utc().format('d')
}

export function getUTCTimeZone() {
  return moment().utc().format('z')
}

export function isAfterMaintenanceEnd(duration, timeUnit) {
  return getCurrentTimePlusDuration(duration, timeUnit)
    .isAfter(nextWeeklyMaintenanceStart().isoWeekday(MAINTENANCE_END_DAY_EST).hours(MAINTENANCE_END_HOUR_EST))
}

export function isAfterMaintenanceStart(duration, timeUnit) {
  return getCurrentTimePlusDuration(duration, timeUnit).isAfter(nextWeeklyMaintenanceStart())
}

export function isAfterExpiry(duration, timeUnit, expiry) {
  return Boolean(expiry) && getCurrentTimePlusDuration(duration, timeUnit).isAfter(moment(expiry))
}

export function getTimeToExpiry(expiry, timeUnit) {
  return moment(expiry).diff(moment(), timeUnit, true)
}

// Changes ts timezone from UTC to local timezone without affecting absolute timestamp 1/1/2019 00:00:00 UTC -> 1/1/2019 00:00:00 GTC
export function getLocalDate(ts) {
  const d = new Date(ts);
  const offset = d.getTimezoneOffset() * 60000; // convert to msec
  const lts = d.getTime() + offset;
  return new Date(lts);
}

const timeUtils = {
  convertDateToLocalTime,
  getLocalDate,
  getMaintenanceCalendarStartTime,
  getMaintenanceStartDate,
  getMaintenanceStartWeekday,
  getTimeToMaintenance,
  getTimeToWarnStart,
  getTimeToMaintenanceWarning,
  getUserTimeZone,
  getUTCMaintenanceCalendarStartTime,
  getUTCMaintenanceStartTime,
  getUTCMaintenanceStartWeekday,
  getUTCTimeZone,
  isAfterMaintenanceEnd,
  isAfterMaintenanceStart,
};

export default timeUtils;
