import { bool, func } from 'prop-types';
import { Button, Form } from 'semantic-ui-react';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { isEmpty } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import copyText from './ChangePasswordForm.copyText';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/button.css');
require('@omniex/onx-common-ui/lib/semantic/css/form.css');

const COMPONENT_NAME = 'ChangePasswordForm';

const StyledForm = styled(Form)`
  min-width: 280px;
`;

export default class ChangePasswordForm extends PureComponent {
  static propTypes = { processing: bool, onSave: func };

  static defaultProps = { onSave: noop };

  state = getInitialState();

  render() {
    return (
      <StyledForm className={COMPONENT_NAME} onSubmit={this._handleSubmit}>
        <Form.Input
          name="currentPassword"
          placeholder={copyText.inputPlaceholder_currentPassword}
          required
          type="password"
          value={this.state.currentPassword}
          onChange={this._handleChangeField}
        />
        <Form.Input
          name="newPassword"
          placeholder={copyText.inputPlaceholder_newPassword}
          required
          type="password"
          value={this.state.newPassword}
          onChange={this._handleChangeField}
        />
        <Form.Input
          name="confirmPassword"
          placeholder={copyText.inputPlaceholder_confirmPassword}
          required
          type="password"
          value={this.state.confirmPassword}
          onChange={this._handleChangeField}
        />
        <Form.Field>
          <Button
            color="blue"
            disabled={!this._canSave() || this.props.processing}
            fluid
            icon
            labelPosition="left"
            loading={this.props.processing}>
            <Button.Content>
              <span>{copyText.saveButtonLabel}</span>
            </Button.Content>
          </Button>
        </Form.Field>
        <Form.Field>
          <Button
            color="orange"
            disabled={this.props.processing}
            fluid
            icon
            labelPosition="left"
            loading={this.props.processing}
            onClick={this.props.handleClickCancelButton}>
            <Button.Content>
              <span>{copyText.cancelButtonLabel}</span>
            </Button.Content>
          </Button>
        </Form.Field>
      </StyledForm>
    );
  }

  _canSave() {
    return (
      !isEmpty(this.state.currentPassword) &&
      !isEmpty(this.state.newPassword) &&
      !isEmpty(this.state.confirmPassword) &&
      this.state.newPassword === this.state.confirmPassword
    );
  }

  _handleChangeField = (event, input) => {
    let { name, value } = input;
    let changes = { [name]: value };

    this.setState(changes);
  };

  _handleSubmit = event => {
    event.preventDefault();
    this.props.onSave({ ...this.state });
    this.setState(getInitialState());
  };
}

function getInitialState(props) {
  return {
    confirmPassword: '',
    currentPassword: '',
    newPassword: ''
  };
}
