import gql from 'graphql-tag';

export default gql`
  query getAllClientAccountsList($type: String!) {
    clientAccountsList: getAllClientAccountsList(type: $type) {
      id
      accountNumber
      companyName
    }
  }
`;
