import { arrayOf, func, shape, string } from 'prop-types';
import { Button, Form, Input } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import React, { PureComponent, Fragment } from 'react';
import 'react-datepicker/dist/react-datepicker.css'
import styled from 'react-emotion';

import { get, has } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getAssetDisplayText, getAssetSymbolAndName } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import { getLocalDate } from '../../utils/TimeUtils.js';
import { isEmpty, isNil } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { isFloat } from '@omniex/onx-common-js/lib/utils/StringUtils';
import { isValid as isValidIdentifier } from '@omniex/onx-common-js/lib/utils/IdentifierUtils';
import { keyBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { sortBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import AccountActivityTransactionType from '@omniex/onx-poms-entity-helpers/lib/enums/AccountActivityTransactionType';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './ManualEntryForm.copyText';
import Dropdown from '@omniex/onx-common-ui/lib/react-select/Dropdown';
import FormSelect from '@omniex/onx-common-ui/lib/semantic/react/FormSelect';
import timeZones, { TZ_UTC } from '../../config/timeZones';
import VenueType from '@omniex/onx-poms-entity-helpers/lib/enums/VenueType';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/button.css');
require('@omniex/onx-common-ui/lib/semantic/css/form.css');
require('@omniex/onx-common-ui/lib/semantic/css/input.css');
require('@omniex/onx-common-ui/lib/semantic/css/label.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');


const transactionTypeOptions = [
  // NOTE: don't change the order of these
  AccountActivityTransactionType.DEPOSIT,
  AccountActivityTransactionType.WITHDRAWAL,
  AccountActivityTransactionType.BUY,
  AccountActivityTransactionType.SELL
].map(transactionType => ({
  value: transactionType,
  label: copyText[`transactionTypeOption_${transactionType}`]
}));

const tzOptions = timeZones.map(tz => ({
  content: tz.code,
  label: tz.name,
  value: tz.name
}));

const DEFAULT_TZ = TZ_UTC;
const DEFAULT_TZ_OPTION = {
  content: timeZones[0].code,
  label: timeZones[0].name,
  value: timeZones[0].name
};
const DEFAULT_TRANSACTION_TYPE = AccountActivityTransactionType.DEPOSIT;
const DEFAULT_TRANSACTION_TYPE_OPTION = transactionTypeOptions[0];

const DATETIME_PICKER_FORMAT = 'yyyy-MM-dd';
const DATETIME_PICKER_MIN_DATE = '2009-01-01';

const FIELD_FROM = 'FROM';
const FIELD_TO = 'TO';

const SYSTEM_CUSTODIAN_ID = '1';

const COMPONENT_NAME = 'ManualEntryForm';

// Constants for types of manual entries
const POSITION = 'POSITION';
const ACTIVITY = 'ACTIVITY';

const CUSTOM_ASSET_PREFIX = 'CUSTOM-';
const NEW_ACCOUNT_IDENTIFIER = 'NEW';
const NEW_ASSET_IDENTIFIER = 'NEW';
const SELF_CUSTODY_IDENTIFIER = 'SELF';

// prettier-ignore
const StyledForm = styled(Form)`
  min-width: 280px;

  .${COMPONENT_NAME}-tzInput {
    background-color: ${colors.white} !important;
    border: 1px solid ${colors.borderColor};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 4px;
    min-width: unset !important;
    padding: 0 !important;
    width: 160px !important;

    .icon {
      float: right;
    }

    .tz__control {
      border: none;
      height: 33.5px;
      min-height: 33.5px;
    }

    .tz__menu {
      border: 1px solid ${colors.borderColor};
    }

    .tz__single-value {
      font-weight: bold;
    }

    .tz__value-container {
      padding-right: 8px;
    }
  }

  .${COMPONENT_NAME}-toggleEntryType {
    margin-bottom: 20px;

    .ui.buttons {
      width: 100%;

      > button {
        background-color: ${colors.white};
        border: 1px solid ${colors.solidBorderColor};
        color: ${colors.lightBlack};

          :first-child {
            border-left: 1px solid ${colors.solidBorderColor} !important;
          }

          :last-child {
            border-left: none;
          }

          :hover {
            background-color: ${colors.blue};
            color: ${colors.white};
            border-color: ${colors.blue};
          }
      }

      .selected {
        background-color: ${colors.blue} !important;
        color: ${colors.white} !important;
        border-color: ${colors.blue} !important;
      }
    }
  }

  .${COMPONENT_NAME}-saveButtonWrapper {
    text-align: right;
  }

  .${COMPONENT_NAME}-saveButton, .disabled.button.${COMPONENT_NAME}-saveButton, ${COMPONENT_NAME}-saveButton:hover {
    margin: 0;
    background-color: ${colors.actionButtonBackgroundColor} !important;
    color: ${colors.actionButtonTextColor} !important;
  }

  .${COMPONENT_NAME}-cancelButton, .${COMPONENT_NAME}-cancelButton:hover {
    margin: 0;
    background-color: ${colors.cancelButtonBackgroundColor} !important;
    color: ${colors.cancelButtonTextColor};
  }

  .${COMPONENT_NAME}-timeInput {
    height: 36px;
    text-align: right !important;

    input {
      width: 120px !important;
    }
  }

  .disabled.input.${COMPONENT_NAME}-positionQuantity, .disabled.input.${COMPONENT_NAME}-price {
    opacity: 1 !important;

    > .label {
      opacity: 0.45;
      border: 1px solid ${colors.solidBorderColor};
    }
  }

  .${COMPONENT_NAME}-positionQuantity, .${COMPONENT_NAME}-price {
    > input {
      border: 1px solid ${colors.solidBorderColor};
    }

    > .label {
      opacity: 1;
      border: 1px solid ${colors.solidBorderColor};
    }
  }

  .${COMPONENT_NAME}-executionPrice, .${COMPONENT_NAME}-fees, .${COMPONENT_NAME}-quantity, .${COMPONENT_NAME}-termCurrencyReferenceRate {
    > input {
      text-align: right;
    }

    .ui.label {
      color: ${colors.lightBlack} !important;
      min-width: 58px;
      text-align: left;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__time-list {
    padding: 0;
  }

  .react-datepicker__day-names, .react-datepicker__header, .react-datepicker__time-container, .react-datepicker__triangle, .react-datepicker__year-dropdown, .react-datepicker {
    border-color: ${colors.borderColor};
  }

  .react-datepicker__header {
    background-color: ${colors.transparentBlack};
  }

  .react-datepicker__close-icon::after, .react-datepicker__day--selected, .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: ${colors.blue};
  }

  .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before {
    border-bottom-color: ${colors.borderColor};
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class ManualEntryForm extends PureComponent {
  static propTypes = {
    accounts: arrayOf(
      shape({
        id: string.isRequired,
        currency: shape({
          id: string.isRequired,
          symbol: string.isRequired
        }).isRequired,
        name: string.isRequired
      })
    ),
    assets: arrayOf(
      shape({
        id: string.isRequired,
        name: string.isRequired,
        source: string.isRequired,
        symbol: string.isRequired,
        type: string.isRequired
      })
    ),
    homeCurrency: shape({
      id: string.isRequired,
      symbol: string.isRequired
    }),
    size: string,
    venues: arrayOf(
      shape({
        id: string.isRequired,
        name: string.isRequired
      })
    ),
    onSave: func
  };

  static defaultProps = { onSave: noop };

  state = getInitialState();

  render() {
    return (
      <StyledForm
        className={COMPONENT_NAME}
        autoComplete="off"
        size={this.props.size}>
        <div className={cn('toggleEntryType')}>
          <Button.Group>
            <Button
              className={this.state.isEditing === POSITION ? 'selected' : null}
              onClick={this._toggleManualEntryType.bind(this)}
              value={POSITION}>
              Position
            </Button>
            <Button
              className={this.state.isEditing === ACTIVITY ? 'selected' : null}
              onClick={this._toggleManualEntryType.bind(this)}
              value={ACTIVITY}>
              Activity
            </Button>
          </Button.Group>
        </div>
        {this.state.isEditing === ACTIVITY
          ? this._renderActivityForm()
          : this._renderPositionsForm(
              this.state.assetId,
              this.state.custodianId
            )}
      </StyledForm>
    );
  }

  renderBasicTransactionFormInputs(fromAccount, toAccount) {
    const isDeposit =
      this.state.transactionType === AccountActivityTransactionType.DEPOSIT;

    const accountOptions = sortBy(
      getAccounts(this.props.accounts).map(account => ({
        value: account.id,
        label: `${account.name} (${getAssetDisplayText(get(account, 'currency'))})`
      })),
      account => account.label.toLowerCase()
    );

    return (
      <Fragment>
        <FormSelect
          name={isDeposit ? 'toAccountId' : 'fromAccountId'}
          disabled={get(this.props, 'accounts.length', 0) === 0}
          options={accountOptions}
          placeholder={
            isDeposit
              ? copyText.inputPlaceholder_toAccountId
              : copyText.inputPlaceholder_fromAccountId
          }
          value={
            this.state[
              this.state.transactionType ===
              AccountActivityTransactionType.DEPOSIT
                ? 'toAccountOption'
                : 'fromAccountOption'
            ]
          }
          onChange={this._handleChangeField}
        />
        <Form.Field>
          <Input
            className={cn('quantity')}
            name="quantity"
            disabled={(isDeposit && !toAccount) || (!isDeposit && !fromAccount)}
            label={{
              basic: true,
              content: isDeposit
                ? getAssetDisplayText(get(toAccount, 'currency'))
                : getAssetDisplayText(get(fromAccount, 'currency'))
            }}
            labelPosition="right"
            placeholder={copyText.inputPlaceholder_quantity}
            required
            value={this.state.quantity}
            onChange={this._handleChangeField}
          />
        </Form.Field>
      </Fragment>
    );
  }

  _renderTradeTransactionFormInputs(fromAccount, toAccount) {
    const venueOptions = sortBy(
      get(this.props, 'venues', []).map(venue => ({
        value: venue.id,
        label: venue.name
      })),
      'label'
    );

    return (
      <Fragment>
        <FormSelect
          name="venueId"
          options={venueOptions}
          placeholder={copyText.inputPlaceholder_venueId}
          value={this.state.venueOption}
          onChange={this._handleChangeField}
        />
        {this.state.transactionType === AccountActivityTransactionType.SELL ? (
          <FormSelect
            name={'fromAccountId'}
            disabled={
              !this.state.venueId || get(this.props, 'accounts.length', 0) === 0
            }
            options={this._getAccountOptions(
              FIELD_FROM,
              fromAccount,
              toAccount
            )}
            placeholder={copyText.inputPlaceholder_fromAccountId_instrument.replace(
              '%side%',
              copyText.instrumentSideBase
            )}
            value={this.state.fromAccountOption}
            onChange={this._handleChangeField}
          />
        ) : null}
        <FormSelect
          name={'toAccountId'}
          disabled={
            !this.state.venueId ||
            (!this.state.fromAccountId &&
              this.state.transactionType ===
                AccountActivityTransactionType.SELL) ||
            get(this.props, 'accounts.length', 0) === 0
          }
          options={this._getAccountOptions(FIELD_TO, fromAccount, toAccount)}
          placeholder={copyText.inputPlaceholder_toAccountId_instrument.replace(
            '%side%',
            this.state.transactionType === AccountActivityTransactionType.BUY
              ? copyText.instrumentSideBase
              : copyText.instrumentSideTerm
          )}
          value={this.state.toAccountOption}
          onChange={this._handleChangeField}
        />
        {this.state.transactionType === AccountActivityTransactionType.BUY ? (
          <FormSelect
            name="fromAccountId"
            disabled={
              !this.state.venueId ||
              get(this.props, 'accounts.length', 0) === 0 ||
              !this.state.toAccountId
            }
            options={this._getAccountOptions(
              FIELD_FROM,
              fromAccount,
              toAccount
            )}
            placeholder={copyText.inputPlaceholder_fromAccountId_instrument.replace(
              '%side%',
              copyText.instrumentSideTerm
            )}
            value={this.state.fromAccountOption}
            onChange={this._handleChangeField}
          />
        ) : null}
        <Form.Field>
          <Input
            className={cn('quantity')}
            name="quantity"
            disabled={!fromAccount || !toAccount}
            label={{
              basic: true,
              content:
                fromAccount && toAccount
                  ? this.state.transactionType ===
                    AccountActivityTransactionType.SELL
                    ? getAssetDisplayText(get(fromAccount, 'currency'))
                    : getAssetDisplayText(get(toAccount, 'currency'))
                  : ''
            }}
            labelPosition="right"
            placeholder={copyText.inputPlaceholder_quantity}
            required
            value={this.state.quantity}
            onChange={this._handleChangeField}
          />
        </Form.Field>
        <Form.Field>
          <Input
            className={cn('executionPrice')}
            name="executionPrice"
            disabled={!fromAccount || !toAccount}
            label={{
              basic: true,
              content:
                fromAccount && toAccount
                  ? this.state.transactionType ===
                    AccountActivityTransactionType.BUY
                    ? getAssetDisplayText(get(fromAccount, 'currency'))
                    : getAssetDisplayText(get(toAccount, 'currency'))
                  : ''
            }}
            labelPosition="right"
            placeholder={copyText.inputPlaceholder_executionPrice}
            required
            value={this.state.executionPrice}
            onChange={this._handleChangeField}
          />
        </Form.Field>
        <Form.Field>
          <Input
            className={cn('fees')}
            name="fees"
            disabled={!fromAccount || !toAccount}
            label={{
              basic: true,
              content:
                fromAccount && toAccount
                  ? this.state.transactionType ===
                    AccountActivityTransactionType.BUY
                    ? getAssetDisplayText(get(fromAccount, 'currency'))
                    : getAssetDisplayText(get(toAccount, 'currency'))
                  : ''
            }}
            labelPosition="right"
            placeholder={copyText.inputPlaceholder_fees}
            required
            value={this.state.fees}
            onChange={this._handleChangeField}
          />
        </Form.Field>
        <Form.Field>
          <Input
            className={cn('termCurrencyReferenceRate')}
            name="termCurrencyReferenceRate"
            disabled={
              !fromAccount ||
              !toAccount ||
              get(fromAccount, 'currency.id') ===
                get(this.props, 'homeCurrency.id') ||
              get(toAccount, 'currency.id') ===
                get(this.props, 'homeCurrency.id')
            }
            label={{
              basic: true,
              content: getAssetDisplayText(get(this.props, 'homeCurrency'))
            }}
            labelPosition="right"
            placeholder={copyText.inputPlaceholder_termCurrencyReferenceRate}
            required
            value={this.state.termCurrencyReferenceRate}
            onChange={this._handleChangeField}
          />
        </Form.Field>
      </Fragment>
    );
  }

  _renderCreateAssetInputs() {
    return (
      <Fragment>
        <Form.Field>
          <Input
            name={'assetName'}
            disabled={false}
            placeholder={copyText.inputPlaceholder_assetName}
            required
            value={this.state.assetName}
            onChange={this._handleChangeField}
          />
        </Form.Field>
        <Form.Field>
          <Input
            name={'assetSymbol'}
            disabled={false}
            placeholder={copyText.inputPlaceholder_assetSymbol}
            required
            value={this.state.assetSymbol}
            onChange={this._handleChangeField}
          />
        </Form.Field>
      </Fragment>
    );
  }

  _renderPositionsForm(assetId, custodianId) {
    const assetKey = `${CUSTOM_ASSET_PREFIX}${assetId}`;

    const accounts = [
      { value: NEW_ACCOUNT_IDENTIFIER, label: copyText.createNewAccountLabel }
    ].concat(
      this.props.accounts
        .filter(account => {
          return custodianId === SELF_CUSTODY_IDENTIFIER
            ? account.custodian === null && account.currency.id === assetKey
            : account.custodian &&
                account.custodian.id === custodianId &&
                account.currency.id === assetKey;
        })
        .map((account, index) => {
          return { key: index, value: account.id, label: account.name };
        })
    );

    const isAccountFormDisabled =
      this.state.assetId === '' || isNil(this.state.custodianId);

    const assets = get(this.props.clientAssetsQueryData, 'assets', []);

    const assetOptions = [
      { value: NEW_ASSET_IDENTIFIER, label: copyText.createNewAssetLabel }
    ].concat(
      assets.map((asset, index) => ({
        key: index,
        value: get(asset, 'id'),
        label: getAssetSymbolAndName(asset)
      }))
    );

    const integrations = get(
      this.props,
      'integrationsQueryData.integrations',
      []
    );

    const custodians = [
      { value: SELF_CUSTODY_IDENTIFIER, label: 'Self Custody' }
    ].concat(
      integrations.map(integration => {
        return {
          value: integration.provider.id,
          label: copyText[`integrationTypeOption_${integration.type}`]
        };
      })
    );

    return (
      <Fragment>
        <FormSelect
          name="assetId"
          disabled={this.props.accounts.length === 0}
          options={assetOptions}
          placeholder={copyText.inputPlaceholder_asset}
          isSearchable={true}
          value={this.state.assetIdOption}
          onChange={this._handleChangeField}
        />

        {this.state.assetId === NEW_ASSET_IDENTIFIER
          ? this._renderCreateAssetInputs()
          : null}

        <FormSelect
          name="custodianId"
          disabled={this.props.accounts.length === 0}
          options={custodians}
          selection
          placeholder={copyText.inputPlaceholder_custodian}
          value={this.state.custodianIdOption}
          isSearchable={true}
          onChange={this._handleChangeField}
        />

        <FormSelect
          name="accountId"
          disabled={true}
          options={isAccountFormDisabled ? [] : accounts}
          placeholder={copyText.inputPlaceholder_account}
          value={this.state.accountIdOption}
          isSearchable={false}
          onChange={this._handleChangeField}
        />

        <Form.Field>
          <Input
            className={cn('positionQuantity')}
            name="positionQuantity"
            disabled={this.state.accountId === ''}
            placeholder={copyText.inputPlaceholder_positionQuantity}
            value={this.state.positionQuantity}
            onChange={this._handleChangeField}
          />
        </Form.Field>

        <Form.Field>
          <Input
            className={cn('price')}
            name="price"
            label={getAssetDisplayText(get(this.props, 'homeCurrency'))}
            labelPosition="right"
            disabled={this.state.accountId === ''}
            placeholder={copyText.inputPlaceholder_price}
            value={this.state.price}
            onChange={this._handleChangeField}
          />
        </Form.Field>

        <Form.Field className={cn('saveButtonWrapper')}>
          <Button
            className={cn('saveButton')}
            disabled={!this._canSavePosition() || this.props.processing}
            fluid
            loading={this.props.processing}
            onClick={this._handleSubmitPosition}>
            {copyText.saveButtonLabel}
          </Button>
        </Form.Field>

        <Form.Field className={cn('cancelButtonWrapper')}>
          <Button
            className={cn('cancelButton')}
            disabled={this.props.processing}
            fluid
            loading={this.props.processing}
            onClick={this._handleCancel}>
            {copyText.cancelButtonLabel}
          </Button>
        </Form.Field>
      </Fragment>
    );
  }

  _renderActivityForm() {
    const isTrade =
      this.state.transactionType === AccountActivityTransactionType.BUY ||
      this.state.transactionType === AccountActivityTransactionType.SELL;

    const fromAccount = isEmpty(this.state.fromAccountId)
      ? null
      : get(this.props, 'accounts', []).find(
          account => account.id === this.state.fromAccountId
        );

    const toAccount = isEmpty(this.state.toAccountId)
      ? null
      : get(this.props, 'accounts', []).find(
          account => account.id === this.state.toAccountId
        );

    return (
      <Fragment>
        <Form.Field>
          <DatePicker
            customInput={
              <Input
                name="datestamp"
                icon="calendar"
                iconPosition="left"
                required
              />
            }
            dateFormat={DATETIME_PICKER_FORMAT}
            disabledKeyboardNavigation
            isClearable={true}
            maxDate={new Date()}
            minDate={getLocalDate(DATETIME_PICKER_MIN_DATE)}
            placeholderText={copyText.inputPlaceholder_datestamp}
            selected={
              this.state.datestamp ? new Date(this.state.datestamp) : undefined
            }
            showYearDropdown
            onChange={this._handleChangeDatestamp}
          />
        </Form.Field>
        <Form.Group>
          <Form.Field>
            <Input
              className={cn('timeInput')}
              name="time"
              direction="left"
              icon="clock"
              iconPosition="left"
              label={
                <Dropdown
                  className={cn('tzInput')}
                  classNamePrefix="tz"
                  name="tz"
                  isRtl
                  isSearchable={false}
                  options={tzOptions}
                  value={this.state.tzOption}
                  onChange={this._handleChangeField}
                />
              }
              labelPosition="right"
              placeholder={copyText.inputPlaceholder_time}
              value={this.state.time}
              onChange={this._handleChangeField}
            />
          </Form.Field>
        </Form.Group>
        <FormSelect
          name="transactionType"
          disabled={this.props.accounts.length === 0}
          options={transactionTypeOptions}
          isSearchable={false}
          value={this.state.transactionTypeOption}
          onChange={this._handleChangeField}
        />
        {!isTrade
          ? this.renderBasicTransactionFormInputs(fromAccount, toAccount)
          : this._renderTradeTransactionFormInputs(fromAccount, toAccount)}
        <Form.Field className={cn('saveButtonWrapper')}>
          <Button
            className={cn('saveButton')}
            color="orange"
            disabled={
              !this._canSaveManualActivity(isTrade, fromAccount, toAccount) ||
              this.props.processing
            }
            fluid
            loading={this.props.processing}
            onClick={this._handleSubmitManualActivity}>
            {copyText.saveButtonLabel}
          </Button>
        </Form.Field>

        <Form.Field className={cn('cancelButtonWrapper')}>
          <Button
            className={cn('cancelButton')}
            disabled={this.props.processing}
            fluid
            loading={this.props.processing}
            onClick={this._handleCancel}>
            {copyText.cancelButtonLabel}
          </Button>
        </Form.Field>
      </Fragment>
    );
  }

  _getAccountOptions(fieldName, fromAccount, toAccount) {
    if (!this.state.venueId || !fieldName) return [];

    if (
      fieldName === FIELD_FROM &&
      !this.state.toAccountId &&
      this.state.transactionType === AccountActivityTransactionType.BUY
    ) {
      return [];
    }

    if (
      fieldName === FIELD_TO &&
      !this.state.fromAccountId &&
      this.state.transactionType === AccountActivityTransactionType.SELL
    ) {
      return [];
    }

    const venue = get(this.props, 'venues', []).find(
      venue => venue.id === this.state.venueId
    );

    let options = getAccounts(this.props.accounts);

    if (venue.type === VenueType.EXCHANGE) {
      options = options.filter(
        account =>
          isValidIdentifier(get(account, 'custodian.operatingVenue.id')) &&
          get(account, 'custodian.operatingVenue.id') === this.state.venueId
      );
    }

    if (venue.type === VenueType.OTC) {
      options = options.filter(account =>
        isNil(get(account, 'custodian.operatingVenue'))
      );
    }

    let supportedCurrencyIds = [];

    if (
      fieldName === FIELD_FROM &&
      this.state.transactionType === AccountActivityTransactionType.SELL
    ) {
      supportedCurrencyIds = venue.instrumentConfigurations.reduce(
        (currencyIds, configuration) => [
          ...currencyIds,
          get(configuration, 'instrument.baseCurrency.id')
        ],
        []
      );
    }

    if (
      fieldName === FIELD_TO &&
      this.state.transactionType === AccountActivityTransactionType.SELL
    ) {
      supportedCurrencyIds = venue.instrumentConfigurations.reduce(
        (currencyIds, configuration) => {
          if (
            get(configuration, 'instrument.baseCurrency.id') ===
            get(fromAccount, 'currency.id')
          ) {
            return [
              ...currencyIds,
              get(configuration, 'instrument.termCurrency.id')
            ];
          }
          return currencyIds;
        },
        []
      );
    }

    if (
      fieldName === FIELD_TO &&
      this.state.transactionType === AccountActivityTransactionType.BUY
    ) {
      supportedCurrencyIds = venue.instrumentConfigurations.reduce(
        (currencyIds, configuration) => [
          ...currencyIds,
          get(configuration, 'instrument.baseCurrency.id')
        ],

        []
      );
    }

    if (
      fieldName === FIELD_FROM &&
      this.state.transactionType === AccountActivityTransactionType.BUY
    ) {
      supportedCurrencyIds = venue.instrumentConfigurations.reduce(
        (currencyIds, configuration) => {
          if (
            get(configuration, 'instrument.baseCurrency.id') ===
            get(toAccount, 'currency.id')
          ) {
            return [
              ...currencyIds,
              get(configuration, 'instrument.termCurrency.id')
            ];
          }

          return currencyIds;
        },
        []
      );
    }

    return sortBy(
      options
        .filter(account =>
          supportedCurrencyIds.includes(get(account, 'currency.id'))
        )
        .map(account => ({
          value: account.id,
          label: `${account.name} (${getAssetDisplayText(account.currency)})`
        })),
      account => account.label.toLowerCase()
    );
  }

  _canSavePosition() {
    const {
      accountId,
      assetId,
      assetName,
      assetSymbol,
      custodianId,
      positionQuantity,
      price
    } = this.state;

    let result = true;

    if (
      assetId === NEW_ASSET_IDENTIFIER &&
      (isEmpty(assetName) || isEmpty(assetSymbol))
    )
      result = false;

    if (
      isEmpty(accountId) ||
      isEmpty(assetId) ||
      isEmpty(custodianId) ||
      isEmpty(positionQuantity) ||
      isEmpty(price)
    )
      result = false;

    return result;
  }

  _canSaveManualActivity(isTrade, fromAccount, toAccount) {
    // >>>>>>>>>
    // SHARED CHECKS
    // >>>>>>>>>

    if (
      !this.state.datestamp ||
      isEmpty(this.state.transactionType) ||
      !isFloat(this.state.quantity)
    ) {
      return false;
    }

    const quantity = parseFloat(this.state.quantity);

    if (quantity <= 0 || quantity > Number.MAX_SAFE_INTEGER) {
      return false;
    }

    // >>>>>>>>>
    // NON-TRADE CHECKS
    // >>>>>>>>>

    if (
      !isTrade &&
      ((this.state.transactionType === AccountActivityTransactionType.SELL &&
        !this.state.fromAccountId) ||
        (this.state.transactionType === AccountActivityTransactionType.BUY &&
          !this.state.toAccountId))
    ) {
      return false;
    }

    // >>>>>>>>>
    // TRADE CHECKS
    // >>>>>>>>>

    if (isTrade && (!this.state.fromAccountId || !this.state.toAccountId))
      return false;

    if (
      isTrade &&
      (isEmpty(this.state.fromAccountId) ||
        isEmpty(this.state.toAccountId) ||
        !isFloat(this.state.executionPrice) ||
        !isFloat(this.state.fees))
    ) {
      return false;
    }

    if (
      isTrade &&
      (isEmpty(this.state.termCurrencyReferenceRate) &&
        (fromAccount.currency.id !== get(this.props, 'homeCurrency.id') &&
          toAccount.currency.id !== get(this.props, 'homeCurrency.id')))
    ) {
      return false;
    }

    const executionPrice = isTrade
      ? parseFloat(this.state.executionPrice)
      : null;

    const fees = isTrade ? parseFloat(this.state.fees) : null;

    if (isTrade && (executionPrice < 0 || fees < 0)) {
      return false;
    }

    return true;
  }

  _handleCancel = event => {
    event.preventDefault();
    this.props.onCancel();
  };

  _handleChangeDatestamp = datestamp => {
    this.setState({ datestamp });
  };

  _handleChangeField = (input, event) => {
    let { value } = input;
    const { name } = event;

    if (has(event, 'value')) {
      value = event.value;
    }

    // -----------------
    // Field validation
    // -----------------

    if (
      [
        'executionPrice',
        'fees',
        'positionQuantity',
        'price',
        'quantity',
        'termCurrencyReferenceRate'
      ].includes(name)
    ) {
      value = value.replace(/[^\d.]/g, '');
    }

    if (
      !/^\d*\.?\d{0,8}$/.test(value) &&
      [
        'executionPrice',
        'fees',
        'positionQuantity',
        'quantity',
        'termCurrencyReferenceRate'
      ].includes(name)
    ) {
      return;
    }

    if (name === 'assetSymbol' && value.length > 5) {
      return;
    }

    let changes = { [name]: value };

    // --------------
    // Activity logic
    // --------------

    if (changes.tz) {
      changes = {
        ...changes,
        tzOption: input
      };
    }

    if (changes.time) {
      if (value.length >= 1 && !/^[0-2](\d(:([0-5](\d?))?)?)?$/.test(value)) {
        return;
      }

      if (changes.time.length === 2) {
        if (this.state.time.length === 3) {
          changes.time = changes.time.slice(0, -1);
        } else {
          changes.time = changes.time + ':';
        }
      }
    }

    if (changes.transactionType) {
      changes = {
        ...changes,
        transactionTypeOption: input
      };
    }

    if (changes.venueId) {
      changes = {
        ...changes,
        venueOption: input
      };
    }

    if (changes.transactionType || changes.venueId) {
      changes = {
        ...changes,
        fromAccountId: '',
        fromAccountOption: '',
        toAccountId: '',
        toAccountOption: '',
        executionPrice: '',
        fees: '',
        quantity: ''
      };
    }

    if (changes.fromAccountId) {
      changes = {
        ...changes,
        fromAccountOption: input
      };
    }

    if (changes.toAccountId) {
      changes = {
        ...changes,
        toAccountOption: input
      };
    }

    if (changes.fromAccountId || changes.toAccountId) {
      changes = {
        ...changes,
        executionPrice: '',
        fees: '',
        termCurrencyReferenceRate: '',
        quantity: ''
      };
    }

    if (
      changes.toAccountId &&
      this.state.transactionType === AccountActivityTransactionType.BUY
    ) {
      changes = {
        ...changes,
        fromAccountId: '',
        fromAccountOption: ''
      };
    }

    if (
      changes.fromAccountId &&
      this.state.transactionType === AccountActivityTransactionType.SELL
    ) {
      changes = {
        ...changes,
        toAccountId: '',
        toAccountOption: ''
      };
    }

    // ---------------
    // Position logic
    // ---------------

    if (changes.custodianId) {
      changes = {
        ...changes,
        custodianIdOption: input
      };

      if (this.state.accountId) {
        changes = {
          ...changes,
          accountIdOption: '',
          positionQuantity: '',
          price: ''
        };
      }
    }

    if (changes.assetId) {
      changes = {
        ...changes,
        assetIdOption: input,
        accountIdOption: '',
        positionQuantity: '',
        price: ''
      };
    }

    if (changes.assetSymbol) {
      changes = {
        assetSymbol: changes.assetSymbol.toUpperCase()
      };
    }

    if (changes.accountId) {
      const accountsKeyedById = keyBy(this.props.accounts, 'id');

      const assetsKeyedById = keyBy(
        get(this.props.clientAssetsQueryData, 'assets'),
        'id'
      );

      changes = {
        ...changes,
        positionQuantity:
          changes.accountId === NEW_ACCOUNT_IDENTIFIER
            ? ''
            : String(
                get(
                  accountsKeyedById,
                  `${changes.accountId}.balanceAtCustodianAsReportedTotal`
                )
              ),
        price:
          changes.accountId === NEW_ACCOUNT_IDENTIFIER
            ? ''
            : String(get(assetsKeyedById, `${this.state.assetId}.price`)),
        accountIdOption: input
      };
    }

    this.setState(changes);
  };

  _handleSubmitManualActivity = event => {
    event.preventDefault();

    const quantity = isFloat(this.state.quantity)
      ? parseFloat(this.state.quantity)
      : 0;

    const [hour, minutes] = this.state.time.split(':');

    const datestamp = moment(this.state.datestamp)
      .tz(this.state.tz)
      .hour(parseInt(hour, 10))
      .minutes(parseInt(minutes, 10))
      .toISOString();

    this.props.onSaveActivity({
      ...this.state,
      adjustedCostBasis: 0, //NOTE: adjustedCostBasis is a non-nullable field in the poms DB
      datestamp,
      quantity
    });
  };

  _handleSubmitPosition = async event => {
    event.preventDefault();

    const positionQuantity = isFloat(this.state.positionQuantity)
      ? parseFloat(this.state.positionQuantity)
      : 0;

    this.props.onSavePosition({
      ...this.state,
      positionQuantity
    });

    this.props.onCancel();
  };

  _toggleManualEntryType = (event, input) => {
    const { value } = input;

    if (this.state.isEditing === value) return;

    const changes = { isEditing: value };

    this.setState({ ...getInitialState(), ...changes });
  };
}

const getAccounts = accounts => {
  if (!Array.isArray(accounts) || accounts.length === 0) return [];

  return accounts.reduce((accum, account) => {
    if (get(account, 'hasCustomAsset') === true) return accum;
    else {
      const accountToPush =
        get(account, 'custodian.id') === SYSTEM_CUSTODIAN_ID
          ? {
              ...account,
              name: account.name.replace(
                'Default',
                copyText.accountNamePrefixUnsettled
              )
            }
          : account;

      accum.push(accountToPush);

      return accum;
    }
  }, []);
};

function getInitialState() {
  return {
    accountId: '',
    accountIdOption: '',
    assetId: '',
    assetIdOption: '',
    assetName: '',
    assetSymbol: '',
    custodianId: '',
    custodianIdOption: '',
    executionPrice: '',
    fees: '',
    fromAccountId: '',
    isEditing: ACTIVITY,
    positionQuantity: '',
    price: '',
    quantity: '',
    termCurrencyReferenceRate: '',
    time: '',
    tz: DEFAULT_TZ,
    tzOption: DEFAULT_TZ_OPTION,
    toAccountId: '',
    transactionType: DEFAULT_TRANSACTION_TYPE,
    transactionTypeOption: DEFAULT_TRANSACTION_TYPE_OPTION,
    venueId: ''
  };
}
