import { func, bool, object } from 'prop-types';
import { Button, Form, Message, Input } from 'semantic-ui-react';
import React, { Fragment, PureComponent } from 'react';
import styled from 'react-emotion';

import InputWithWarning from './InputWithWarning';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import copyText from './CustomCustodianForm.copyText';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/button.css');
require('@omniex/onx-common-ui/lib/semantic/css/form.css');
require('@omniex/onx-common-ui/lib/semantic/css/input.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const COMPONENT_NAME = 'CustomCustodianForm';

const StyledForm = styled(Form)`
  min-width: 280px;

  .${COMPONENT_NAME}-secondConfirmationMessage {
    font-size: 1.3em;
  }

  .${COMPONENT_NAME}-buttonWrapper {
    text-align: right;
  }

  .${COMPONENT_NAME}-button {
    margin: 0;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class CustomCustodianForm extends PureComponent {
  static propType = {
    crypto: bool,
    custodian: object,
    onCancel: func,
    onChange: func,
    onSave: func,
  };

  static defaultProps = {
    crypto: false,
    custodian: {},
    onCancel: noop,
    onChange: noop,
    onSave: noop,
  };

  state = {
    showSecondConfirmation: false,
  };

  render() {
    const {showSecondConfirmation} = this.state
    const { crypto, custodian } = this.props
    const { bankAddress = "", description = "", name = "", swiftCode ="" } = custodian

    return (
      <StyledForm
        className={COMPONENT_NAME}
        autoComplete="off"
        warning={showSecondConfirmation}>
        <Form.Field>
          <InputWithWarning
            name="name"
            placeholder={copyText.namePlaceholder}
            value={name}
            warning={copyText.freeTextWarning}
            onChange={this.props.onChange}
          />
        </Form.Field>
        {crypto ? (
          <Form.Field>
            <InputWithWarning
              name="description"
              placeholder={copyText.descriptionPlaceholder}
              value={description}
              warning={copyText.freeTextWarning}
              onChange={this.props.onChange}
            />
          </Form.Field>
        ) : (
          <Fragment>
            <Form.Field>
              <InputWithWarning
                name="bankAddress"
                placeholder={copyText.bankAddressPlaceholder}
                value={bankAddress}
                warning={copyText.freeTextWarning}
                onChange={this.props.onChange}
              />
            </Form.Field>
            <Form.Field>
              <Input
                name="swiftCode"
                placeholder={copyText.swiftCodePlaceholder}
                value={swiftCode}
                onChange={this.props.onChange}
              />
            </Form.Field>
          </Fragment>
        )}
        {showSecondConfirmation ? (
          <Fragment>
            <Message
              content={copyText.confirmationMessageContent}
              header={copyText.confirmationMessageHeader}
              warning
            />
            <Form.Field className={cn('buttonWrapper')}>
              <Button
                className={cn('button')}
                color="orange"
                disabled={!this._canSave()}
                fluid
                size="tiny"
                type="submit"
                onClick={this._handleSubmit}>
                {copyText.proceedButtonLabel}
              </Button>
            </Form.Field>
            <Form.Field className={cn('buttonWrapper')}>
              <Button
                className={cn('button')}
                fluid
                size="tiny"
                type="reset"
                onClick={this._handleClickCancelConfirmation}>
                {copyText.cancelButtonLabel}
              </Button>
            </Form.Field>
          </Fragment>
        ) : (
          <Fragment>
            <Message
              content={copyText.confirmationMessageContent}
              header={copyText.confirmationMessageHeader}
              warning
            />
            <Form.Field className={cn('buttonWrapper')}>
              <Button
                className={cn('button')}
                color="orange"
                disabled={!this._canSave()}
                fluid
                size="tiny"
                onClick={this._handleClickFirstConfirmationButton}>
                {copyText.saveButtonLabel}
              </Button>
            </Form.Field>
            <Form.Field className={cn('buttonWrapper')}>
              <Button
                className={cn('button')}
                fluid
                size="tiny"
                type="reset"
                onClick={this._handleClickCancelButton}>
                {copyText.cancelButtonLabel}
              </Button>
            </Form.Field>
          </Fragment>
        )}
      </StyledForm>
    );
  }

  _canSave() {
    const { bankAddress, description, name, swiftCode } = this.props.custodian
    const { crypto } = this.props
    return name && ((crypto && description) || (!crypto && bankAddress && swiftCode));
  }

  _handleClickCancelButton = event => {
    event.preventDefault();
    this.props.onCancel();
  };

  _handleClickFirstConfirmationButton = event => {
    event.preventDefault();
    this.setState({ showSecondConfirmation: true });
  };

  _handleClickCancelConfirmation = event => {
    event.preventDefault();
    this.setState({ showSecondConfirmation: false });
  };

  _handleSubmit = event => {
    event.preventDefault();
    const { bankAddress, description, id, name, swiftCode } = this.props.custodian
    this.props.onSave({bankAddress, description, id, name, swiftCode});
  };
}
