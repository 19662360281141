const settlementPageMainSectionCopy = {
  csvDownloadTabLabel: 'Download CSV',
  // errorMessageAdvanceAllocationOnConfirm:
  //   'Sorry, we encountered an issue while attempting to process this request. Please try again.',
  // errorMessageAcceptSettlement:
  //   'Sorry, we encountered an issue while attempting to accept this settlement. Please try again.',
  // errorMessageInitiateSettlement:
  //   'Sorry, we encountered an issue while attempting to initiate this settlement. Please try again.',
  // errorMessageRejectSettlement:
  //   'Sorry, we encountered an issue while attempting to reject this settlement. Please try again.',
  // errorMessageReleaseFills:
  //   'Sorry, we encountered an issue while attempting to release the fills associated with this settlement. Please try again.',
  errorMessageAdvanceAllocationOnConfirm:
    'Settlement is undergoing improvements and temporarily unavailable. Please contact our support team with any concerns.',
  errorMessageAcceptSettlement:
    'Settlement is undergoing improvements and temporarily unavailable. Please contact our support team with any concerns.',
  errorMessageInitiateSettlement:
    'Settlement is undergoing improvements and temporarily unavailable. Please contact our support team with any concerns.',
  errorMessageRejectSettlement:
    'Settlement is undergoing improvements and temporarily unavailable. Please contact our support team with any concerns.',
  errorMessageReleaseFills:
    'Settlement is undergoing improvements and temporarily unavailable. Please contact our support team with any concerns.',
  loadingMessage: 'Loading...',
  permissionDenied: 'Action required by a user with Settlement Officer role',
  sectionTitle: 'Settlement',
  tabLabelAffirm: 'Affirm',
  tabLabelConfirm: 'Confirm',
  tabLabelInitiate: 'Initiate',
  tabLabelStandingInstructions: 'Standing Instructions'
};

export default settlementPageMainSectionCopy;
