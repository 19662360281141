import { arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import { Grid, Loader, Menu, Segment, Icon } from 'semantic-ui-react';
import React, { Fragment, PureComponent } from 'react';
import styled from 'react-emotion';

import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './PortfolioPageBreakdownSection.copyText';
import generateCurrentPriceLookupTable from '../../selectors/generateCurrentPriceLookupTable';
import getVenuePositionsFromAccountsWithBalances from '../../selectors/getVenuePositionsFromAccountsWithBalances';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import PortfolioBreakdownChart, {
  GROUPING_ATTRIBUTE_CURRENCY,
  GROUPING_ATTRIBUTE_CUSTODIAN
} from '../components/PortfolioBreakdownChart';
import populateAccountsWithCurrency from '../../selectors/populateAccountsWithCurrency';
import PortfolioMarketValueComponent from '../components/PortfolioMarketValueComponent';

const ERROR_MESSAGE_KEY = 'errorMessage';
const ERROR_MESSAGE_KEY_ONLY_CLOSED_AND_NEGATIVE_POSITIONS =
  'errorMessageOnlyClosedAndNegativePositions';
const ERROR_MESSAGE_KEY_ONLY_NEGATIVE_POSITIONS =
  'errorMessageOnlyNegativePositions';
const WARNING_MESSAGE_KEY_NO_OPEN_POSITIONS = 'warningMessageNoOpenPositions';
const WARNING_MESSAGE_KEY_NO_POSITIONS = 'warningMessageNoPositions';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/loader.css');
require('@omniex/onx-common-ui/lib/semantic/css/segment.css');
require('@omniex/onx-common-ui/lib/semantic/css/grid.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');

const COMPONENT_NAME = 'PortfolioPageBreakdownSection';

const STYLE_ERROR = 'error';
const STYLE_WARNING = 'warning';

const StyledSection = styled('section')`
  .${COMPONENT_NAME}-refreshLabel {
    margin-right: 7px;
    margin-bottom: 3px;
  }
  .${COMPONENT_NAME}-refreshContainer {
    display: flex;
    flex-direction: row;
  }
  .${COMPONENT_NAME}-refreshLoader {
    margin-right: 7px !important;
  }

  .${COMPONENT_NAME}-loader {
    color: ${colors.grey};
  }

  .${COMPONENT_NAME}-segment {
    padding: 0;
  }

  .${COMPONENT_NAME}-grid {
    min-height: 535px;
  }

  .${COMPONENT_NAME}-chartWrapper {
    padding-top: 20px !important;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class PortfolioPageBreakdownSection extends PureComponent {
  static propTypes = {
    accountsWithBalancesQueryData: shape({
      accounts: arrayOf(
        shape({
          id: string.isRequired,
          balanceAtCustodianAsReportedTotal: number,
          temporaryCalculatedBalance: number
        }).isRequired
      )
    }),
    accountsWithBalancesQueryLoading: bool,
    currenciesQueryData: shape({
      currencies: arrayOf(
        shape({
          id: string.isRequired,
          type: string.isRequired, // TODO: use enum constants
          symbol: string.isRequired,
          name: string.isRequired
        })
      )
    }),
    currenciesQueryError: object,
    currenciesQueryLoading: bool,
    currentPricesQueryData: shape({
      currentPrices: arrayOf(
        shape({
          baseCurrency: shape({
            id: string.isRequired
          }).isRequired,
          price: number
        })
      )
    }),
    currentPricesQueryError: object,
    currentPricesQueryLoading: bool,
    fetchBalances: func,
    fetchBalancesMutationData: object,
    fetchBalancesMutationError: object,
    fetchBalancesMutationProcessing: bool,
    loading: bool,
    orgQueryData: shape({
      org: shape({
        homeCurrency: shape({
          id: string.isRequired,
          symbol: string
        }).isRequired
      })
    }),
    orgQueryError: object,
    orgQueryLoading: bool,
    user: shape({
      org: shape({
        type: string.isRequired
      })
    }),
  };

  render() {
    const {
      currentPositions,
      currentPriceLookupTable,
      homeCurrency,
      negativePositions,
      positivePositions
    } = this._getSectionData();

    const [refreshIcon, refreshLabel] = this.props.fetchBalancesMutationProcessing ? [
      <Loader className={cn('refreshLoader')} active inline="centered" size="tiny" />,
      <span>{copyText.refreshBalancesProcessingLabel}</span>,
    ] : [
      <Icon name='sync' className={cn('refreshLabel')} />,
      <span>{copyText.refreshBalancesLabel}</span>,
    ];

    return (
      <StyledSection className={COMPONENT_NAME}>
        <Menu attached="top">
          <Menu.Item header icon="pie graph" content={copyText.sectionTitle} />
          {this.props.user.canReconBalances && (
            <Menu.Menu position="right">
              <Menu.Item
                disabled={this.props.loading}
                onClick={this.props.fetchBalances}>
                  <div className={cn('refreshContainer')}>
                    {refreshIcon}
                    {refreshLabel}
                  </div>
              </Menu.Item>
            </Menu.Menu>
          )}
        </Menu>
        <Segment className={cn('segment')} attached="bottom">
          <Grid columns={2} celled="internally" className={cn('grid')}>
            {(this.props.loading && !this.props.fetchBalancesMutationProcessing) ? (
              <Grid.Row columns={1}>
                <Grid.Column width={16}>
                  <Loader
                    className={cn('loader')}
                    active
                    content={copyText.loadingMessage}
                    size="medium"
                  />
                </Grid.Column>
              </Grid.Row>
            ) : positivePositions.length > 0 ? (
              <Fragment>
                <Grid.Row>
                  <Grid.Column className={cn('chartWrapper')}>
                    <PortfolioBreakdownChart
                      homeCurrency={homeCurrency}
                      initialGroupingAttribute={GROUPING_ATTRIBUTE_CURRENCY}
                      margin={[25, 45, 0, 45]}
                      positions={currentPositions}
                      priceLookupTable={currentPriceLookupTable}
                    />
                  </Grid.Column>
                  <Grid.Column className={cn('chartWrapper')}>
                    <PortfolioBreakdownChart
                      homeCurrency={homeCurrency}
                      initialGroupingAttribute={GROUPING_ATTRIBUTE_CUSTODIAN}
                      margin={[25, 5, 0, 5]}
                      positions={currentPositions}
                      priceLookupTable={currentPriceLookupTable}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                  <Grid.Column textAlign="center">
                    <PortfolioMarketValueComponent
                      homeCurrency={homeCurrency}
                      positions={currentPositions}
                      priceLookupTable={currentPriceLookupTable}
                      titlePrefix={copyText.portfolioMarketValuePrefix}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Fragment>
            ) : (
              this._renderMessage(
                currentPositions.length,
                positivePositions.length,
                negativePositions.length
              )
            )}
          </Grid>
        </Segment>
      </StyledSection>
    );
  }

  _getSectionData() {
    const homeCurrency = get(this.props, 'orgQueryData.org.homeCurrency');

    const accounts = populateAccountsWithCurrency(
      get(this.props, 'accountsWithBalancesQueryData.accounts'),
      get(this.props, 'currenciesQueryData.currencies')
    );

    const currentPositions = getVenuePositionsFromAccountsWithBalances(
      accounts
    );

    const [positivePositions, negativePositions] = currentPositions.reduce(
      ([positivePositions, negativePositions], position) => {
        if (position.quantity === 0) {
          return [positivePositions, negativePositions];
        }

        return position.quantity > 0
          ? [[...positivePositions, position], negativePositions]
          : [positivePositions, [...negativePositions, position]];
      },
      [[], []]
    );

    const currentPriceLookupTable = generateCurrentPriceLookupTable(
      get(this.props, 'currentPricesQueryData.currentPrices'),
      get(homeCurrency, 'id')
    );

    return {
      currentPositions,
      currentPriceLookupTable,
      homeCurrency,
      negativePositions,
      positivePositions
    };
  }

  _renderMessage(
    currentPositionsLength,
    positivePositionsLength,
    negativePositionsLength
  ) {
    const { copyTextKey, style } = getMessagePropsFromPositions(
      currentPositionsLength,
      positivePositionsLength,
      negativePositionsLength
    );

    return (
      <Grid.Row columns={1}>
        <Grid.Column width={16}>
          <Message
            content={copyText[copyTextKey]}
            error={style === STYLE_ERROR}
            warning={style === STYLE_WARNING}
          />
        </Grid.Column>
      </Grid.Row>
    );
  }
}

function getMessagePropsFromPositions(
  currentPositionsLength,
  positivePositionsLength,
  negativePositionsLength
) {
  if (currentPositionsLength === 0) {
    //no positions
    return {
      copyTextKey: WARNING_MESSAGE_KEY_NO_POSITIONS,
      style: STYLE_WARNING
    };
  }

  if (
    positivePositionsLength === 0 &&
    currentPositionsLength > 0 &&
    negativePositionsLength === 0
  ) {
    //all zero
    return {
      copyTextKey: WARNING_MESSAGE_KEY_NO_OPEN_POSITIONS,
      style: STYLE_WARNING
    };
  }

  if (
    positivePositionsLength === 0 &&
    currentPositionsLength === negativePositionsLength
  ) {
    //all negative
    return {
      copyTextKey: ERROR_MESSAGE_KEY_ONLY_NEGATIVE_POSITIONS,
      style: STYLE_ERROR
    };
  }

  if (
    positivePositionsLength === 0 &&
    negativePositionsLength !== currentPositionsLength
  ) {
    //mix of negative and zero
    return {
      copyTextKey: ERROR_MESSAGE_KEY_ONLY_CLOSED_AND_NEGATIVE_POSITIONS,
      style: STYLE_ERROR
    };
  }

  //default error
  return {
    copyTextKey: ERROR_MESSAGE_KEY,
    style: STYLE_ERROR
  };
}
