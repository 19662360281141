import gql from 'graphql-tag';

export default gql`
  query getActiveOrders {
    orders: getActiveOrders {
      id
      expiryType
      fixClientID
      fixClOrdID
      fixOrderID
      fixQuoteID
      fixVenueOrderID
      instrument {
        id
      }
      limitPrice
      quote {
        id
      }
      side
      specifiedCurrency {
        id
      }
      specifiedQuantity
      status
      timeCreated
      timeLastUpdated
      type
    }
  }
`;
