import { Icon } from 'semantic-ui-react';
import { bool, func, object } from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './CustodianDetail.copyText';
import { opaque } from '@omniex/onx-common-ui/lib/colors/utils';
import Table from '@omniex/onx-common-ui/lib/semantic/react/Table';

const COMPONENT_NAME = 'AccountDetailTable';

// prettier-ignore
const StyledTable = styled(Table)`
  margin-left: -14px 14px !important;
  max-width: 1000px;
  padding-bottom: 14px;
  padding-left: 14px;

  .${COMPONENT_NAME}-icon {
    color: ${colors.blue} !important;
    font-size: 1em !important;
    padding-left: 10px;

    :hover {
      color: ${opaque(colors.blue, 0.8)} !important;
      cursor: pointer;
    }
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class CustodianDetail extends PureComponent {
  static propTypes = {
    custodian: object,
    handleEdit: func,
    isFiat: bool,
  };

  state = {};

  render() {
      const { custodian, handleEdit, isFiat } = this.props

      return (
        <StyledTable className={COMPONENT_NAME}>
          <Table.Body>
            {isFiat
              ? <Row label={copyText.addressLabel} value={custodian.bankAddress} top handleEdit={handleEdit} custodian={custodian} />
              : <Row label={copyText.descriptionLabel} value={custodian.description} top handleEdit={handleEdit} custodian={custodian} />
            }
            {isFiat ? <Row label={copyText.swiftLabel} value={custodian.swiftCode} /> : <Row label="" value="" />}
          </Table.Body>
        </StyledTable>
      );
    }
}
const Row = ({ label, value, top, handleEdit, custodian }) => (
  <Table.Row>
    <Table.Cell>{label}</Table.Cell>
    <Table.Cell>{value}</Table.Cell>
    <Table.Cell>{top && <EditIcon onClick={handleEdit} custodian={custodian} />}</Table.Cell>
  </Table.Row>
)

const EditIcon = ({ onClick, custodian }) => (
  <>
    {copyText.updateLabel}
    <Icon
      className={cn('icon')}
      name="edit"
      onClick={_ => onClick(custodian)}
    />
  </>
)
