import createSelector from 're-reselect';
import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { isNil } from '@omniex/onx-common-js/lib/utils/LangUtils';

import getInstrumentOptions from './getInstrumentOptions';
import InstrumentType from '@omniex/onx-poms-entity-helpers/lib/enums/InstrumentType';

export function getGroupedInstrumentOptions(instruments, recentInstruments) {
  if (!Array.isArray(instruments) || instruments.length === 0 || !Array.isArray(recentInstruments)) {
    return [];
  }

  const [spotInstruments, pswapInstruments, futureInstruments] = instruments.reduce(
    (acc, i) => {
      const expiry = get(i, 'baseAsset.expiry');
      const currentDate = new Date();
      if (i.type === InstrumentType.FUTURE && isNil(expiry)) acc[1].push(i);
      else if (i.type === InstrumentType.FUTURE && expiry && new Date(expiry) < currentDate) return acc;
      else if (i.type === InstrumentType.FUTURE && expiry && new Date(expiry) >= currentDate) acc[2].push(i);
      else acc[0].push(i);
      return acc;
    },
    [[], [], []]
  );

  return [
    { label: 'Recently Used', options: getInstrumentOptions(recentInstruments) },
    { label: 'Spot', options: getInstrumentOptions(spotInstruments) },
    { label: 'Perpetual Swaps', options: getInstrumentOptions(pswapInstruments) },
    { label: 'Futures', options: getInstrumentOptions(futureInstruments) }
  ];
}

export default createSelector(
  (instruments, recentInstruments) => instruments,
  (instruments, recentInstruments) => recentInstruments,
  getGroupedInstrumentOptions
)((instruments, recentInstruments) => recentInstruments.map(i => i.displayName).join(' '));
  // recentInstruments maps to display name because every instance of use only includes display name
