import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import Box from '@material-ui/core/Box'
import DatePicker from 'react-datepicker';
import { Input, Loader, Menu, Segment } from 'semantic-ui-react';
import { makeStyles, ThemeProvider } from '@omniex/poms-ui/lib/themes';
import theme from '../../themes/light'

import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { keyBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import copyText from './ReportPage.copyText';
import getPortfolioCurrencies from '../../apollo/graphql/getPortfolioCurrencies';
import getInstruments from '../../apollo/graphql/getInstruments';
import getSlippageReport from '../../apollo/graphql/getSlippageReport';
import populateInstrumentsWithCurrencies from '../../selectors/populateInstrumentsWithCurrencies';
import ReportTable from '../components/ReportTable';

import 'react-datepicker/dist/react-datepicker.css';
import '@omniex/onx-common-ui/lib/semantic/css/segment.css';

const DATEPICKER_FORMAT = 'yyyy/MM/dd h:mm aa'

const makeClasses = makeStyles({
  datePickerSection: {
    display: 'flex',
    borderRadius: '0px',
  },
  datePickerWrapper: {
    marginRight: '20px',
    maxWidth: '300px',
    zIndex: 3,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  emptyReportSection: {
    textAlign: 'center',
    justifyContent: 'center',
  },
  formWrapper: {
    width: '100%',
  },
  headerWrapper: {
    paddingTop: '20px',
    display: 'flex',
  },
  menu: {
    margin: '0px !important',
  },
  popup: {
    fontSize: '0.9em',
    padding: '8px 16px',
  },
  reportSection: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '500px',
  },
  reportWrapper: {
    minHeight: '500px',
  },
  styledDiv: {
    height: '100%',
    overflow: 'hidden',
  },
  styledSection: {
    display: 'flex !important',
    flexDirection: 'column !important',
    minHeight: '100%',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
}, { name: 'ReportContainer' });

// TODO it'd be better for the report server itself to populate the symbols rather than just returning ids
// this will save on the amount of data coming back over the wire

const Report = ({ client, startDate, endDate, portfolio = {} }) => {
  const after = Math.floor(startDate.getTime() / 1000);
  const before = Math.floor(endDate.getTime() / 1000);

  const config = { excludeSummary: true };
  const variables = { config, after, before, portfolioId: portfolio?.id };

  const reportQuery = useQuery(getSlippageReport(config), { variables, client });
  const instrumentsQuery = useQuery(getInstruments, { client });
  const currenciesQuery = useQuery(getPortfolioCurrencies, { client, variables: { portfolioId: variables.portfolioId } });

  const cls = makeClasses();

  if (reportQuery.loading || instrumentsQuery.loading) {
    return <Loader className="loader" active content={copyText.reportLoading} size="medium" />;
  }

  const { slippageSummary: summary, tickets } = get(reportQuery, 'data.getSlippageReport', {});

  if (reportQuery.error || instrumentsQuery.error || tickets.length === 0) {
    return <Segment className={cls.emptyReportSection}>{copyText.noTickets}</Segment>;
  }

  const instrumentsData = get(instrumentsQuery, 'data.instruments') || []
  const currenciesData = get(currenciesQuery, 'data.currencies') || []
  const instruments = keyBy(populateInstrumentsWithCurrencies(instrumentsData, currenciesData), 'id')

  return (
    <Segment className={cls.reportSection}>
      <ReportTable summary={summary} tickets={tickets} instruments={instruments} startDate={startDate} endDate={endDate} />
    </Segment>
  );
};


const DatePickerSection = ({ startDate, setStartDate, endDate, setEndDate }) => {
  const cls = makeClasses();
  return (
    <Segment className={cls.datePickerSection}>
      <div className={cls.datePickerWrapper}>
        <Box mr={2} className={cls.label} fontSize="subtitle1.fontSize">{copyText.datepickerStart}</Box>
        <DatePicker
          customInput={
            <Input
              name="startdate"
              icon="calendar"
              iconPosition="left"
              required
            />
          }
          dateFormat={DATEPICKER_FORMAT}
          selected={startDate}
          onChange={date => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          maxDate={new Date()}
          showTimeInput
          timeInputLabel={copyText.timeLabel}
          popperModifiers={{ computeStyle: { gpuAcceleration: false } }}
        />
      </div>
      <div className={cls.datePickerWrapper}>
      <Box mr={2} className={cls.label} fontSize="subtitle1.fontSize">{copyText.datepickerEnd}</Box>
        <DatePicker
          customInput={
            <Input
              name="enddate"
              icon="calendar"
              iconPosition="left"
              required
            />
          }
          dateFormat={DATEPICKER_FORMAT}
          selected={endDate}
          onChange={date => setEndDate(date)}
          selectsEnd
          endDate={endDate}
          minDate={startDate}
          maxDate={new Date()}
          showTimeInput
          timeInputLabel={copyText.timeLabel}
          popperModifiers={{ computeStyle: { gpuAcceleration: false } }}
        />
      </div>
    </Segment>
  );
};

const ReportPageContent = ({ apiClient, portfolio = {} }) => {
  const threeMonthsAgo = new Date()
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3)
  const [startDate, setStartDate] = useState(threeMonthsAgo);
  const [endDate, setEndDate] = useState(new Date());

  const cls = makeClasses();

  return (
    <div className={cls.styledDiv}>
      <main>
        <section className={cls.styledSection}>
          <div className={cls.headerWrapper}>
            <Menu attached="top" borderless className={cls.menu}>
              <Menu.Item content={copyText.title} header icon="file alternate outline" />
            </Menu>
          </div>
          <div className={cls.formWrapper}>
            <DatePickerSection
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          </div>
          <div className={cls.reportWrapper}>
            <Report
              client={apiClient}
              startDate={startDate}
              endDate={endDate}
              portfolio={portfolio}
            />
          </div>
        </section>
      </main>
    </div>
  );
}

const ReportPage = ({ apiClient, portfolio = {} }) => (
  <ThemeProvider theme={theme} noCssBaseline>
    <ReportPageContent apiClient={apiClient} portfolio={portfolio} />
  </ThemeProvider>
)

export default ReportPage;
