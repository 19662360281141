import { createSelector } from 'reselect';
import { Decimal } from 'decimal.js';

import { getSelectedVenueBalances } from './getSelectedVenueBalances';
import InstrumentType from '@omniex/onx-poms-entity-helpers/lib/enums/InstrumentType';
import { isEmpty, isNil } from '@omniex/onx-common-js/lib/utils/LangUtils';
import OrderSide from '@omniex/onx-poms-entity-helpers/lib/enums/OrderSide';
import OrderType from '@omniex/onx-poms-entity-helpers/lib/enums/OrderType';

export const getProjectedPrice = (
  accounts,
  entries,
  gtc,
  instrument,
  limitPrice,
  orderType, 
  qtyToFill,
  side,
  stopPrice,
  venueIds,
  venueOptions
) => {
  const projectedVars = {
    avgPrice: 0,
    partiallyFilled: false,
    limitPriceExceeded: false,
    qtyFilled: 0,
    totalPrice: 0,
    desiredTotalPrice: 0,
    stopPriceExceeded: false,
    warn: false,
    completeEstimate: false,
    insufficientOrderBookEntryBalance: false
  }

  const paramsMissing = [
    isNaN(parseFloat(qtyToFill)),
    !Array.isArray(entries),
    isEmpty(entries),
    isEmpty(venueIds),
    isEmpty(venueOptions),
    !_isValidLimitPrice(limitPrice, orderType),
    !_isValidStopPrice(stopPrice, orderType),
  ].reduce((anyMissing, paramMissing) => anyMissing || paramMissing, false)

  if (paramsMissing) return projectedVars;
  // emulate order book
  if (side === OrderSide.SELL) {
    entries.sort((a,b) => a.price > b.price ? -1 : 1)
  } else { entries.sort((a,b) => a.price < b.price ? -1 : 1) }
  
  qtyToFill = new Decimal(qtyToFill);
  let qtyFilled = new Decimal(0);
  let totalPrice = new Decimal(0);
  let desiredTotalPrice = new Decimal(0);
  let venueBalances = {...getSelectedVenueBalances(instrument, accounts, side, venueIds, venueOptions)};
  for (let i = 0; i < entries.length; i++) {
    if (isNil(entries[i].quantity) || isNil(entries[i].price) || isNil(entries[i].venueSymbol)) continue;
    // Order matters here. Stop price checked before limit so that stop-limit pricing is validated correctly for warning purposes.
    if (_exceedsStopPrice(entries[i], stopPrice, side)) {
      projectedVars.stopPriceExceeded = true;
      break;
    }
    if (_exceedsLimitPrice(entries[i], limitPrice, side) && venueBalances[entries[i]?.venueSymbol]?.gt(0)) {
      projectedVars.limitPriceExceeded = true;
      break;
    }
    // Break if entire quantity has been filled
    if (qtyFilled.gte(qtyToFill)) break;

    // Calculate quantity for this fill
    const qtyRemainingToFill = Decimal.sub(qtyToFill, qtyFilled);
    const price = new Decimal(entries[i].price);
    let qtyByBalance = qtyRemainingToFill;
    let currentBalance = new Decimal(0);

    if (instrument.type !== InstrumentType.FUTURE) {
      currentBalance = venueBalances[entries[i].venueSymbol] || new Decimal(0);
      qtyByBalance = side === OrderSide.SELL ? currentBalance : (price.isZero() ? new Decimal(0) : Decimal.div(currentBalance, price));
      qtyByBalance = Decimal.max(qtyByBalance, 0);
    }

    const qtyForThisFill = Decimal.min(Decimal(entries[i].quantity), qtyRemainingToFill, qtyByBalance);
    if (qtyRemainingToFill.gt(qtyByBalance)) projectedVars.insufficientOrderBookEntryBalance = true;
    const totalPriceForThisFill = Decimal.mul(qtyForThisFill, price);

    if (instrument.type !== InstrumentType.FUTURE) {
      venueBalances[entries[i].venueSymbol] = Decimal.sub(currentBalance, side === OrderSide.SELL ? qtyForThisFill : totalPriceForThisFill);
    }

    qtyFilled = Decimal.add(qtyFilled, qtyForThisFill);
    totalPrice = Decimal.add(totalPrice, totalPriceForThisFill);
    desiredTotalPrice = Decimal.add(desiredTotalPrice, totalPriceForThisFill);

    // Won't warn that algorithm crossed order book if insufficient funds in all venues
    if (i === entries.length - 1 && qtyToFill.gt(qtyFilled)) {
      projectedVars.warn = Object.values(venueBalances || {}).reduce((anythingLeft, b) => anythingLeft || b.gt(0), false);
    }
  }

  
  const qtyRemainingToFill = Decimal.sub(qtyToFill, qtyFilled);
  if (qtyRemainingToFill.gt(0)) {
    projectedVars.partiallyFilled = true;
    const fillRemainderWithLimit = gtc && (orderType === OrderType.LIMIT || (orderType === OrderType.STOP_LIMIT && !projectedVars.stopPriceExceeded));
    const fillRemainderWithStop = gtc && projectedVars.stopPriceExceeded;
    if (fillRemainderWithLimit || fillRemainderWithStop) {
      const remainderPrice = new Decimal(parseFloat(fillRemainderWithLimit ? limitPrice : stopPrice) || 0);
      const [remainderQtyFilled, remainderTotalPrice, remainderDesiredTotalPrice] = _fillRemainderWithPrice(remainderPrice, qtyRemainingToFill, venueBalances, side, instrument.type === InstrumentType.FUTURE);
      qtyFilled = Decimal.add(qtyFilled, remainderQtyFilled);
      totalPrice = Decimal.add(totalPrice, remainderTotalPrice);
      desiredTotalPrice = Decimal.add(desiredTotalPrice, remainderDesiredTotalPrice);
      projectedVars.completeEstimate = true;
    }
  } else {
    projectedVars.completeEstimate = true;
  }

  projectedVars.qtyFilled = qtyFilled.toNumber();
  projectedVars.totalPrice = totalPrice.toNumber();
  projectedVars.desiredTotalPrice = desiredTotalPrice.toNumber();

  if (!qtyFilled.isZero()) projectedVars.avgPrice = Decimal.div(totalPrice, qtyFilled).toNumber();

  return projectedVars;
}

const _fillRemainderWithPrice = (price, remainderQty, venueBalances, side, isFutures) => {
  let qtyByBalance = remainderQty;
  if (!isFutures) {
    const totalBalance = Object.values(venueBalances).reduce((vb1, vb2) => (Decimal.add(vb1, vb2)));
    qtyByBalance = side === OrderSide.SELL
      ? totalBalance
      : price.isZero()
        ? new Decimal(0)
        : Decimal.div(totalBalance, price);
    qtyByBalance = Decimal.max(qtyByBalance, 0);
  }
  const qtyForThisFill = Decimal.min(remainderQty, qtyByBalance);
  const totalPriceForThisFill = Decimal.mul(qtyForThisFill, price);
  const desiredTotalPriceForThisFill = Decimal.mul(remainderQty, price);
  return [qtyForThisFill, totalPriceForThisFill, desiredTotalPriceForThisFill];
}

const _exceedsLimitPrice = ({price} = {}, lp, side) => !isEmpty(lp) && (side === OrderSide.BUY ? price > lp : price < lp)
const _exceedsStopPrice = ({price} = {}, sp, side) => !isEmpty(sp) && (side === OrderSide.BUY ? price < sp : price > sp)

const _isValidLimitPrice = (limitPrice, orderType = '') => orderType !== OrderType.LIMIT && orderType !== OrderType.STOP_LIMIT
  ? isEmpty(limitPrice)
  : !isNaN(parseFloat(limitPrice))
const _isValidStopPrice = (stopPrice, orderType = '') => orderType !== OrderType.STOP_LOSS && orderType !== OrderType.STOP_LIMIT
  ? isEmpty(stopPrice)
  : !isNaN(parseFloat(stopPrice))

export default createSelector(
  entry => entry.accounts,
  entry => entry.entries,
  entry => entry.gtc,
  entry => entry.instrument,
  entry => entry.limitPrice,
  entry => entry.orderType,
  entry => entry.qtyToFill,
  entry => entry.side,
  entry => entry.stopPrice,
  entry => entry.venueIds,
  entry => entry.venueOptions,
  getProjectedPrice
);
