/**
 * Simple time conversions.
 *
 * Using one argument:
 *
 *   convertTime(30).from(SECONDS).to(MINUTES) // 0.5
 *   convertTime(30).to(MINUTES).from(SECONDS) // 0.5
 *   convertTime(30).in(SECONDS).to(MINUTES)   // 0.5
 *   convertTime(30).toFrom(MINUTES, SECONDS)  // 0.5
 *   convertTime(30).fromTo(SECONDS, MINUTES)  // 0.5
 *
 * Using two arguments:
 *
 *   convertTime(30, SECONDS).to(MINUTES)      // 0.5
 *   convertTime(30, MINUTES).from(SECONDS)    // 0.5
 *   convertTime(30, SECONDS).in(MINUTES)      // 0.5
 *
 * Using three arguments:
 *
 *   convertTime(30, SECONDS, MINUTES)         // 0.5
 */

export const NANOSECONDS = 'NANOSECONDS'
export const MICROSECONDS = 'MICROSECONDS'
export const MILLISECONDS = 'MILLISECONDS'
export const SECONDS = 'SECONDS'
export const MINUTES = 'MINUTES'
export const HOURS = 'HOURS'
export const DAYS = 'DAYS'
export const WEEKS = 'WEEKS'
export const YEARS = 'YEARS'

export const UNIT = {
  NANOSECONDS,
  MICROSECONDS,
  MILLISECONDS,
  SECONDS,
  MINUTES,
  HOURS,
  DAYS,
  WEEKS,
  YEARS,
}

const TO_S = {
  [NANOSECONDS]: 1e-9,
  [MICROSECONDS]: 1e-6,
  [MILLISECONDS]: 1e-3,
  [SECONDS]: 1,
  [MINUTES]: 60,
  [HOURS]: 3600,
  [DAYS]: 86400,
  [WEEKS]: 604800,
  [YEARS]: 31536000,
}

const _convertTime3 = (t, o, n) => t*TO_S[o]/TO_S[n]
const _convertTime2 = (t, o) => n => _convertTime3(t, o, n)
const _convertTime2f = (t, n) => o => _convertTime3(t, o, n)
const _convertTime1 = t => (o, n) => _convertTime3(t, o, n)
const _convertTime1f = t => (n, o) => _convertTime3(t, o, n)
const _convertTimeTo = t => o => ({to: _convertTime2(t, o)})
const _convertTimeFrom = t => n => ({from: _convertTime2f(t, n)})

export const convertTime = (time, oldUnits, newUnits) => newUnits !== undefined
  ? _convertTime3(time, oldUnits, newUnits)
  : oldUnits !== undefined
  ? ({
      to: _convertTime2(time, oldUnits),
      in: _convertTime2(time, oldUnits),
      from: _convertTime2f(time, oldUnits),
    })
  : ({
      in: _convertTimeTo(time),
      from: _convertTimeTo(time),
      to: _convertTimeFrom(time),
      fromTo: _convertTime1(time),
      toFrom: _convertTime1f(time),
    })
