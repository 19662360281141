const editOrgSettingsFormCopy = {
  cancelButtonLabel: 'Cancel',
  inputPlaceholder_homeCurrency: 'Home Currency',
  inputPlaceholder_marketDataSource: 'Market Data Source',
  marketDataSourceCoinMarketCap: 'CoinMarketCap',
  marketDataSourceCryptoCompare: 'CryptoCompare',
  submitButtonLabel: 'Update'
};

export default editOrgSettingsFormCopy;
