const marketDepthChartCopy = {
  chartTitlePrice: 'Price',
  chartTitleVolume: 'Volume',
  chartTooltipLabelDepth: 'Depth',
  chartTooltipLabelPrice: 'Price',
  chartTooltipLabelVenue: 'Venue',
  chartTooltipLabelTotalVolume: 'Total Volume',
  chartTooltipTitleBid: 'Bid',
  chartTooltipTitleOffer: 'Offer',
  warningMessage: "We're unable to render a market depth chart at this time."
};

export default marketDepthChartCopy;
