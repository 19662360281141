import { useCallback, useMemo, useState } from 'react'

import { FilterOption } from '@omniex/poms-ui/lib/types'

type ExclusiveCategoryState = string | null

export type ExclusiveCategoryQueryArgs = ExclusiveCategoryState

type ExclusiveCategoryOptions = Array<FilterOption>

type ExclusiveCategoryFilterHookArguments = {
  defaultState?: ExclusiveCategoryState
  options: ExclusiveCategoryOptions
}

export type ExclusiveCategoryFilterHook = {
  filterData: {
    options: ExclusiveCategoryOptions
    value: ExclusiveCategoryState
    type: 'exclusiveCategory'
  }
  queryArgs: ExclusiveCategoryState
  handleFilter: (input: ExclusiveCategoryState) => void
}

export function useExclusiveCategoryFilter({ defaultState, options }: ExclusiveCategoryFilterHookArguments): ExclusiveCategoryFilterHook {

  const [value, setValue] = useState<ExclusiveCategoryState>(defaultState || null)

  const handleFilter = useCallback((input: ExclusiveCategoryState) => setValue(input), [setValue])

  const queryArgs = useMemo(() => value, [value])

  return {
    filterData: {
      options,
      value,
      type: 'exclusiveCategory'
    },
    queryArgs,
    handleFilter
  }
}
