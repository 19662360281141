import gql from 'graphql-tag';

export default gql`
  mutation updatePosition(
    $accountId: ID!
    $assetId: ID!
    $positionQuantity: Float!
    $price: Float!
  ) {
    position: updatePosition(
      accountId: $accountId
      assetId: $assetId
      positionQuantity: $positionQuantity
      price: $price
    ) {
      accountId
      activityId
      price
      quantity
    }
  }
`;
