import gql from 'graphql-tag';

export default gql`
  mutation updateNotificationSettings($inputs: UpdateNotificationSettingsInput!) {
    userSettings: updateNotificationSettings(inputs: $inputs) {
      id
      notificationDeliveryMethods_email {
        notificationConditions_orderCancelled
        notificationConditions_orderFilled
        notificationConditions_orderPlaced
        notificationConditions_venueExcludedFromAlgo
        notificationConditions_venueRemovedFromAlgo
        notificationConditions_algoPaused
      }
      notificationDeliveryMethods_desktop {
        notificationConditions_orderCancelled
        notificationConditions_orderFilled
        notificationConditions_orderPlaced
        notificationConditions_venueExcludedFromAlgo
        notificationConditions_venueRemovedFromAlgo
        notificationConditions_algoPaused
      }
    }
  }
`;
