import { createSelector } from 'reselect';

import { isValid as isValidIdentifier } from '@omniex/onx-common-js/lib/utils/IdentifierUtils';

export function getInstructionOptions(accounts, accountId) {
  if (
    !Array.isArray(accounts) ||
    accounts.length === 0 ||
    !isValidIdentifier(accountId)
  )
    return [];

  return (
    (accounts.find(selectedAccount => selectedAccount.id === accountId) || [])
      .instructions || []
  ).map(instruction => ({
    value: instruction.id,

    // NOTE: This text field will have to be adjusted based upon the freeform field
    label: `${instruction.description}...${
      instruction.walletAddress
        ? instruction.walletAddress.slice(-4)
        : instruction.freeForm.slice(-4)
    }`
  }));
}

export default createSelector(
  (accounts, accountId) => accounts,
  (accounts, accountId) => accountId,
  getInstructionOptions
);
