import gql from 'graphql-tag';

export default gql`
  query getAuthenticatedUser {
    authenticatedUser: getAuthenticatedUser {
      id
      name
      emailAddress
      timeCreated
      org {
        id
        enableCustomOrderId
        enablePTWAPSE
        externalMarketDataSource
        fixSenderCompID
        homeCurrency {
          id
          symbol
        }
        makerApiBaseUrl
        name
        portfolios {
          id
          name
          isDefault
        }
        takerApiBaseUrl
        type
        tz
      }
      roles {
        id
        type
      }
      settings {
        notificationDeliveryMethods_email {
          notificationConditions_orderCancelled
          notificationConditions_orderFilled
          notificationConditions_orderPlaced
          notificationConditions_venueExcludedFromAlgo
          notificationConditions_venueRemovedFromAlgo
          notificationConditions_algoPaused
        }
        notificationDeliveryMethods_desktop {
          notificationConditions_orderCancelled
          notificationConditions_orderFilled
          notificationConditions_orderPlaced
          notificationConditions_venueExcludedFromAlgo
          notificationConditions_venueRemovedFromAlgo
          notificationConditions_algoPaused
        }
        trade {
          executeOnSingleClickOTC
        }
      }
      appState {
        lastNotificationCheckTime
        otc {
          templates {
            id
            instrumentId
            orderType
            quantity
            venueIds
          }
          tiles {
            id
            instrumentId
            orderType
            quantity
            venueIds
            limitPrice
            stopPrice
          }
        }
      }
      username
    }
    inputHistory: getRecentInputHistory {
      id
      PageLayoutHeaderMenu_portfolio
    }
  }
`;
