import gql from 'graphql-tag';

export default gql`
  query getVerifiedIntegrations {
    integrations: getVerifiedIntegrations {
      id
      credentialsPreview
      org {
        id
        name
      }
      requestedBy
      requestedTime
      type
    }
  }
`;
