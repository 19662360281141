import createSelector from 're-reselect';

import { isValid as isValidIdentifier } from '@omniex/onx-common-js/lib/utils/IdentifierUtils';
import { sortBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';

export function filterTradeHistoryByInstrumentId(tradeHistory, instrumentId) {
  if (
    !Array.isArray(tradeHistory) ||
    tradeHistory.length === 0 ||
    !isValidIdentifier(instrumentId)
  )
    return [];

  return sortBy(
    tradeHistory.filter(bar => bar.instrument.id === instrumentId),
    'timeClosed'
  );
}

export default createSelector(
  (tradeHistory, instrumentId) => tradeHistory,
  (tradeHistory, instrumentId) => instrumentId,
  filterTradeHistoryByInstrumentId
)((tradeHistory, instrumentId) => instrumentId);
