import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import { getDisplayFormattedNum } from './DisplayUtils';
import getCurrenciesKeyedById from '../selectors/getCurrenciesKeyedById';
import AssetType from '@omniex/poms-core/lib/enums/AssetType';
import OrderSide from '@omniex/onx-poms-entity-helpers/lib/enums/OrderSide';

const { FIAT } = AssetType;
const { BUY } = OrderSide;

export const formatNum = (quantity, currencyType) => currencyType === FIAT
  ? getDisplayFormattedNum(parseFloat(quantity.toFixed(2)), 2)
  : getDisplayFormattedNum(quantity, 0);

const isOrderIncoming = (isUserBuySide, isBuyOrder, isBaseCurrency) => {
  const base = isBaseCurrency ? 1 : -1;
  const flow = isBuyOrder ? base : -base;
  return isUserBuySide ? flow : -flow;
}
const netFill = (f, isBuy, isBase) => isOrderIncoming(isBuy, f.order.side === BUY, isBase) * f.quantity * (isBase ? 1 : f.price);

export const getNetFills = (fills = [], isUserBuySide = true, currencies = []) => {
  const flows = fills.reduce((totals, f) => {
    const base = get(f, 'order.instrument.baseCurrency.id') || '';
    const term = get(f, 'order.instrument.termCurrency.id') || '';
    totals[base] = (totals[base] || 0) + netFill(f, isUserBuySide, true);
    totals[term] = (totals[term] || 0) + netFill(f, isUserBuySide, false);
    return totals;
  }, {});
  return populateFlowCurrencies(flows, currencies);
}

const populateFlowCurrencies = (flows, currencies) => {
  const currenciesKeyedById = getCurrenciesKeyedById(currencies);
  flows = Object.keys(flows).map(currencyId => ({
    currencyId,
    currencySymbol: getAssetDisplayText(currenciesKeyedById[currencyId]),
    currencyType: get(currenciesKeyedById[currencyId], 'type'),
    quantity: flows[currencyId]
  }));
  return flows;
}

const isSettlementIncoming = (senderCompID, toCompID) => senderCompID === toCompID;
const netAlloc = (a, senderCompID) => isSettlementIncoming(senderCompID, a.fixToCompID) ? a.quantity : -a.quantity;

export const getNetSettlements = (settlements = [], senderCompID, currencies = []) => {
  const flows = settlements.reduce((totals, s) => {
    s.allocations.forEach(a => totals[a.currency.id] = (totals[a.currency.id] || 0) + netAlloc(a, senderCompID));
    return totals;
  }, {});
  return populateFlowCurrencies(flows, currencies);
}

const settlementUtils = {
  formatNum,
  getNetFills,
  getNetSettlements,
};

export default settlementUtils;
