import gql from 'graphql-tag';

export default gql`
  mutation updateUser($userId: ID!, $roleTypes: [String!], $status: String, $portfolioIds: [ID]) {
    user: updateUser(userId: $userId, roleTypes: $roleTypes, status: $status, portfolioIds: $portfolioIds) {
      id
      roles {
        id
        status
        type
      }
      status
    }
  }
`;
