import { bool, func, object } from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import SettlementPageMainSection from './SettlementPageMainSection';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');
require('@omniex/onx-common-ui/lib/semantic/css/popup.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const COMPONENT_NAME = 'SettlementPage';

const StyledDiv = styled('div')`
  height: 100%;
  overflow: hidden;

  .${COMPONENT_NAME}-sectionWrapper {
    padding-top: 20px;

    :last-child {
      margin-bottom: 60px;
    }
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class SettlementPage extends PureComponent {
  static propTypes = {
    acceptSettlement: func,
    acceptSettlementMutationError: object,
    acceptSettlementMutationProcessing: bool,
    acceptSettlementMutationResult: object,
    advanceAllocationOnConfirm: func,
    advanceAllocationOnConfirmMutationError: object,
    advanceAllocationOnConfirmMutationProcessing: bool,
    advanceAllocationOnConfirmMutationResult: object,
    csvDataQueryData: object,
    csvDataQueryError: object,
    csvDataQueryLoading: bool,
    currenciesQueryData: object,
    currenciesQueryError: object,
    currenciesQueryLoading: bool,
    fetchCsvData: func,
    initiateSettlement: func,
    initiateSettlementMutationError: object,
    initiateSettlementMutationProcessing: bool,
    initiateSettlementMutationResult: object,
    instrumentsQueryData: object,
    instrumentsQueryError: object,
    instrumentsQueryLoading: bool,
    orgQueryData: object,
    orgQueryError: object,
    orgQueryLoading: bool,
    portfolioQueryData: object,
    portfolioQueryError: object,
    portfolioQueryLoading: bool,
    rejectSettlement: func,
    rejectSettlementMutationError: object,
    rejectSettlementMutationProcessing: bool,
    rejectSettlementMutationResult: object,
    releaseFills: func,
    releaseFillsMutationError: object,
    releaseFillsMutationProcessing: bool,
    releaseFillsMutationResult: object,
    settlementsQueryData: object,
    settlementsQueryError: object,
    settlementsQueryLoading: bool,
    settlementsSubscriptionData: object,
    settlementsSubscriptionError: object,
    settlementsSubscriptionLoading: bool,
    unsettledOtcFillsQueryData: object,
    unsettledOtcFillsQueryError: object,
    unsettledOtcFillsQueryLoading: bool,
    unsettledOtcFillsSubscriptionData: object,
    unsettledOtcFillsSubscriptionError: object,
    unsettledOtcFillsSubscriptionLoading: bool,
    user: object,
  };

  static defaultProps = {
    acceptSettlement: noop,
    advanceAllocationOnConfirm: noop,
    fetchCsvData: noop,
    initiateSettlement: noop,
    rejectSettlement: noop,
    releaseFills: noop,
  };

  render() {
    return (
      <StyledDiv className={COMPONENT_NAME}>
          <main>
            <div id="settlement" className={cn('sectionWrapper')}>
              <SettlementPageMainSection
                acceptSettlement={this.props.acceptSettlement}
                acceptSettlementMutationError={
                  this.props.acceptSettlementMutationError
                }
                acceptSettlementMutationProcessing={
                  this.props.acceptSettlementMutationProcessing
                }
                acceptSettlementMutationResult={
                  this.props.acceptSettlementMutationResult
                }
                advanceAllocationOnConfirm={
                  this.props.advanceAllocationOnConfirm
                }
                advanceAllocationOnConfirmMutationError={
                  this.props.advanceAllocationOnConfirmMutationError
                }
                advanceAllocationOnConfirmMutationProcessing={
                  this.props.advanceAllocationOnConfirmMutationProcessing
                }
                advanceAllocationOnConfirmMutationResult={
                  this.props.advanceAllocationOnConfirmMutationResult
                }
                csvDataQueryData={this.props.csvDataQueryData}
                csvDataQueryError={this.props.csvDataQueryError}
                csvDataQueryLoading={this.props.csvDataQueryLoading}
                currenciesQueryData={this.props.currenciesQueryData}
                currenciesQueryError={this.props.currenciesQueryError}
                currenciesQueryLoading={this.props.currenciesQueryLoading}
                fetchCsvData={this.props.fetchCsvData}
                initiateSettlement={this.props.initiateSettlement}
                initiateSettlementMutationError={
                  this.props.initiateSettlementMutationError
                }
                initiateSettlementMutationProcessing={
                  this.props.initiateSettlementMutationProcessing
                }
                initiateSettlementMutationResult={
                  this.props.initiateSettlementMutationResult
                }
                instrumentsQueryData={this.props.instrumentsQueryData}
                instrumentsQueryError={this.props.instrumentsQueryError}
                instrumentsQueryLoading={this.props.instrumentsQueryLoading}
                orgQueryData={this.props.orgQueryData}
                orgQueryError={this.props.orgQueryError}
                orgQueryLoading={this.props.orgQueryLoading}
                portfolioQueryData={this.props.portfolioQueryData}
                portfolioQueryError={this.props.portfolioQueryError}
                portfolioQueryLoading={this.props.portfolioQueryLoading}
                rejectSettlement={this.props.rejectSettlement}
                rejectSettlementMutationError={
                  this.props.rejectSettlementMutationError
                }
                rejectSettlementMutationProcessing={
                  this.props.rejectSettlementMutationProcessing
                }
                rejectSettlementMutationResult={
                  this.props.rejectSettlementMutationResult
                }
                releaseFills={this.props.releaseFills}
                releaseFillsMutationError={this.props.releaseFillsMutationError}
                releaseFillsMutationProcessing={
                  this.props.releaseFillsMutationProcessing
                }
                releaseFillsMutationResult={
                  this.props.releaseFillsMutationResult
                }
                settlementsQueryData={this.props.settlementsQueryData}
                settlementsQueryError={this.props.settlementsQueryError}
                settlementsQueryLoading={this.props.settlementsQueryLoading}
                settlementsSubscriptionData={
                  this.props.settlementsSubscriptionData
                }
                settlementsSubscriptionError={
                  this.props.settlementsSubscriptionError
                }
                settlementsSubscriptionLoading={
                  this.props.settlementsSubscriptionLoading
                }
                unsettledOtcFillsQueryData={
                  this.props.unsettledOtcFillsQueryData
                }
                unsettledOtcFillsQueryError={
                  this.props.unsettledOtcFillsQueryError
                }
                unsettledOtcFillsQueryLoading={
                  this.props.unsettledOtcFillsQueryLoading
                }
                unsettledOtcFillsSubscriptionData={
                  this.props.unsettledOtcFillsSubscriptionData
                }
                unsettledOtcFillsSubscriptionError={
                  this.props.unsettledOtcFillsSubscriptionError
                }
                unsettledOtcFillsSubscriptionLoading={
                  this.props.unsettledOtcFillsSubscriptionLoading
                }
                user={this.props.user}
              />
            </div>
          </main>
      </StyledDiv>
    );
  }
}
