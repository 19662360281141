import { Accordion, Icon, Loader, Menu, Segment } from 'semantic-ui-react';
import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import { createSelector } from 'reselect';
import React, { Fragment, PureComponent } from 'react';
import styled from 'react-emotion';

import { get, has } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { isEmpty, isError } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { sortBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import AccountDetailTable from '../components/AccountDetailTable';
import AccountInstructionForm from '../components/AccountInstructionForm';
import AccountsTable from '../components/AccountsTable';
import AccountUpdateForm from '../components/AccountUpdateForm';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './CryptoSection.copyText';
import CustomCustodianForm from '../components/CustomCustodianForm';
import CustodianDetail from '../components/CustodianDetail';
import AssetType from '@omniex/poms-core/lib/enums/AssetType';
import { getExistingCurrencies, partitionAccounts } from '../../utils/CustodyUtils';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import populateAccountsWithCurrency from '../../selectors/populateAccountsWithCurrency';
import SaveCryptoAccountForm from '../components/SaveCryptoAccountForm';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/loader.css');
require('@omniex/onx-common-ui/lib/semantic/css/segment.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');
require('@omniex/onx-common-ui/lib/semantic/css/accordion.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const { CRYPTO } = AssetType;

const COMPONENT_NAME = 'CryptoSection';

const ERROR_MESSAGE_KEY_CREATE_CUSTODIAN_FAILED = 'errorMessageCreateCustodianFailed';
const ERROR_MESSAGE_KEY_CREATE_INSTRUCTION_FAILED =
  'errorMessageCreateInstructionFailed';
const ERROR_MESSAGE_KEY_CREATE_MANUAL_ACCOUNT_FAILED =
  'errorMessageCreateManualAccountFailed';
const ERROR_MESSAGE_KEY_DELETE_INSTRUCTION_FAILED =
  'errorMessageDeleteInstructionFailed';
const ERROR_MESSAGE_KEY_UPDATE_ACCOUNT_FAILED =
  'errorMessageUpdateAccountFailed';
const ERROR_MESSAGE_KEY_UPDATE_CUSTODIAN_FAILED =
  'errorMessageUpdateCustodianFailed';
const ERROR_MESSAGE_KEY_UPDATE_INSTRUCTION_FAILED =
  'errorMessageUpdateInstructionFailed';

const SUCCESS_MESSAGE_KEY_ACCOUNT_UPDATED = 'successMessageUpdateAccount';
const SUCCESS_MESSAGE_KEY_MANUAL_ACCOUNT_CREATED =
  'successMessageCreateManualAccount';
const SUCCESS_MESSAGE_KEY_CUSTODIAN_CREATED = 'successMessageCreateCustodian';
const SUCCESS_MESSAGE_KEY_CUSTODIAN_UPDATED = 'successMessageUpdateCustodian';
const SUCCESS_MESSAGE_KEY_INSTRUCTION_CREATED =
  'successMessageCreateInstruction';
const SUCCESS_MESSAGE_KEY_INSTRUCTION_DELETED =
  'successMessageDeleteInstruction';
const SUCCESS_MESSAGE_KEY_INSTRUCTION_UPDATED =
  'successMessageUpdateInstruction';

const StyledSection = styled('section')`
  .${COMPONENT_NAME}-loader {
    color: ${colors.grey};
  }

  .${COMPONENT_NAME}-segment {
    display: flex;
    min-height: 400px;
    padding: 0 !important;
  }

  .${COMPONENT_NAME}-successMessage + .${COMPONENT_NAME}-segment {
    border-top-color: ${colors.successBorderColor};
  }

  .${COMPONENT_NAME}-errorMessage + .${COMPONENT_NAME}-segment {
    border-top-color: ${colors.errorBorderColor};
  }

  .${COMPONENT_NAME}-loaderWrapper {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
  }

  .${COMPONENT_NAME}-subsegment {
    :first-child {
      flex: 1;
      overflow: auto;
    }

    :nth-child(2) {
      border-left: 1px solid ${colors.internalBorderColor};
      max-width: 308px !important;
      padding: 14px;
    }
  }

  .${COMPONENT_NAME}-accountsTableWrapper {
    overflow-x: auto;
  }

  .${COMPONENT_NAME}-accordionTitle {
    background-color: ${colors.transparentBlack};
    border-top: 1px solid ${colors.borderColor} !important;
    padding-left: 10px !important;

    &.withActive {
      background-color: ${colors.borderColor};
    }
  }

  .${COMPONENT_NAME}-accordionContent {
    border-top: 1px solid ${colors.borderColor};
    display: flex;
    padding: 14px 14px 14px 28px !important;
  }

  .${COMPONENT_NAME}-singleAccountTableWrapper {
    padding: 14px;
  }

  .${COMPONENT_NAME}-walletDetailsTableWrapper {
    margin-left: 14px;
    margin-top: 14px;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class CryptoSection extends PureComponent {
  static propTypes = {
    accounts: arrayOf(
      shape({
        id: string.isRequired,
        currency: shape({
          id: string.isRequired
        }).isRequired,
        instructions: arrayOf(
          shape({
            id: string.isRequired,
            account: shape({ id: string.isRequired }),
            description: string,
            walletAddress: string,
            xpubKey: string,
            xpubParentId: string
          })
        ),
        name: string.isRequired
      })
    ),
    createCustomCustodian: func,
    createCustomCustodianError: object,
    createCustomCustodianProcessing: bool,
    createCustomCustodianResult: shape({
      custodian: shape({ id: string.isRequired })
    }),
    createInstruction: func,
    createInstructionMutationError: object,
    createInstructionMutationProcessing: bool,
    createInstructionMutationResult: shape({
      instruction: shape({ id: string.isRequired })
    }),
    createManualAccount: func,
    createManualAccountMutationError: object,
    createManualAccountMutationProcessing: bool,
    createManualAccountMutationResult: shape({
      instruction: shape({ id: string.isRequired })
    }),
    currenciesQueryData: shape({
      currencies: arrayOf(object)
    }),
    currenciesQueryError: object,
    currenciesQueryLoading: bool,
    customCustodians: arrayOf(
      shape({
        id: string.isRequired,
        name: string.isRequired,
        type: string.isRequired,
        __typename: string.isRequired,
      })
    ),
    deleteInstruction: func,
    deleteInstructionMutationError: object,
    deleteInstructionMutationProcessing: bool,
    deleteInstructionMutationResult: shape({
      instruction: shape({ id: string.isRequired })
    }),
    isUserBuySide: bool,
    orgQueryData: shape({
      org: shape({
        id: string.isRequired,
        homeCurrency: shape({ id: string.isRequired })
      })
    }),
    portfolioQueryError: object,
    portfolioQueryLoading: bool,
    updateAccount: func,
    updateAccountMutationError: object,
    updateAccountMutationProcessing: bool,
    updateAccountMutationResult: shape({
      instruction: shape({ id: string.isRequired })
    }),
    updateCustomCustodian: func,
    updateCustomCustodianError: object,
    updateCustomCustodianProcessing: bool,
    updateCustomCustodianResult: shape({
      custodian: shape({ id: string.isRequired })
    }),
    updateInstruction: func,
    updateInstructionMutationError: object,
    updateInstructionMutationProcessing: bool,
    updateInstructionMutationResult: shape({
      instruction: shape({ id: string.isRequired })
    })
  };

  static defaultProps = {
    accounts: [],
    createCustomCustodian: noop,
    createInstruction: noop,
    createManualAccount: noop,
    deleteInstruction: noop,
    updateAccount: noop,
    updateCustomCustodian: noop,
    updateInstruction: noop
  };

  state = {};

  static getDerivedStateFromProps(nextProps, prevState) {
    const stateChanges = {};

    if (isEmpty(prevState)) {
      return getInitialState(nextProps);
    }

    for (const key of [
      'createCustomCustodianMutationProcessing',
      'createInstructionMutationProcessing',
      'createManualAccountMutationProcessing',
      'deleteInstructionMutationProcessing',
      'updateAccountMutationProcessing',
      'updateInstructionMutationProcessing',
      'updateCustomCustodianMutationProcessing',
    ]) {
      if (has(nextProps, key) && get(prevState, key) !== get(nextProps, key)) {
        stateChanges[key] = nextProps[key];
      }
    }

    if (!prevState.waiting) return stateChanges;

    if (
      stateChanges.createCustomCustodianMutationProcessing === false &&
      !isError(nextProps.createCustomCustodianMutationError) &&
      get(nextProps, 'createCustomCustodianMutationResult.custodian')
    ) {
      stateChanges.waiting = false;
      stateChanges.errorMessageKey = undefined;
      stateChanges.custodian = undefined;
      stateChanges.isEditingCustodian = false;
      stateChanges.showCustodianForm = false;
      stateChanges.successMessageKey = SUCCESS_MESSAGE_KEY_CUSTODIAN_CREATED;
    }

    if (
      stateChanges.createCustomCustodianMutationProcessing === false &&
      isError(nextProps.createCustomCustodianMutationError) &&
      !get(nextProps, 'createCustomCustodianMutationResult.custodian')
    ) {
      stateChanges.waiting = false;
      stateChanges.errorMessageKey = ERROR_MESSAGE_KEY_CREATE_CUSTODIAN_FAILED;
      stateChanges.custodian = undefined;
      stateChanges.isEditingCustodian = false;
      stateChanges.successMessageKey = undefined;
    }

    if (
      stateChanges.createManualAccountMutationProcessing === false &&
      !isError(nextProps.createManualAccountMutationError) &&
      get(nextProps, 'createManualAccountMutationResult.account')
    ) {
      stateChanges.waiting = false;
      stateChanges.errorMessageKey = undefined;
      stateChanges.instructionIdEditing = undefined;
      stateChanges.showCreateInstructionForm = false;
      stateChanges.showXPubInstructionForm = false;
      stateChanges.showAccountForm = false;
      stateChanges.successMessageKey = SUCCESS_MESSAGE_KEY_MANUAL_ACCOUNT_CREATED;
    }

    if (
      stateChanges.createManualAccountMutationProcessing === false &&
      isError(nextProps.createManualAccountMutationError) &&
      !get(nextProps, 'createManualAccountMutationResult.account')
    ) {
      stateChanges.waiting = false;
      stateChanges.errorMessageKey = ERROR_MESSAGE_KEY_CREATE_MANUAL_ACCOUNT_FAILED;
      stateChanges.instructionIdEditing = undefined;
      stateChanges.showCreateInstructionForm = false;
      stateChanges.showXPubInstructionForm = false;
      stateChanges.successMessageKey = undefined;
    }

    if (
      stateChanges.createInstructionMutationProcessing === false &&
      !isError(nextProps.createInstructionMutationError) &&
      get(nextProps, 'createInstructionMutationResult.instruction')
    ) {
      stateChanges.waiting = false;
      stateChanges.errorMessageKey = undefined;
      stateChanges.instructionIdEditing = undefined;
      stateChanges.showCreateInstructionForm = false;
      stateChanges.showXPubInstructionForm = false;
      stateChanges.successMessageKey = SUCCESS_MESSAGE_KEY_INSTRUCTION_CREATED;
    }

    if (
      stateChanges.createInstructionMutationProcessing === false &&
      isError(nextProps.createInstructionMutationError) &&
      !get(nextProps, 'createInstructionMutationResult.instruction')
    ) {
      stateChanges.waiting = false;
      stateChanges.errorMessageKey = ERROR_MESSAGE_KEY_CREATE_INSTRUCTION_FAILED;
      stateChanges.instructionIdEditing = undefined;
      stateChanges.showCreateInstructionForm = false;
      stateChanges.showXPubInstructionForm = false;
      stateChanges.successMessageKey = undefined;
    }

    if (
      stateChanges.deleteInstructionMutationProcessing === false &&
      !isError(nextProps.deleteInstructionMutationError) &&
      get(nextProps, 'deleteInstructionMutationResult.instruction')
    ) {
      stateChanges.waiting = false;
      stateChanges.errorMessageKey = undefined;
      stateChanges.instructionIdEditing = undefined;
      stateChanges.showCreateInstructionForm = false;
      stateChanges.showXPubInstructionForm = false;
      stateChanges.successMessageKey = SUCCESS_MESSAGE_KEY_INSTRUCTION_DELETED;
    }

    if (
      stateChanges.deleteInstructionMutationProcessing === false &&
      isError(nextProps.deleteInstructionMutationError) &&
      !get(nextProps, 'deleteInstructionMutationResult.instruction')
    ) {
      stateChanges.waiting = false;
      stateChanges.errorMessageKey = ERROR_MESSAGE_KEY_DELETE_INSTRUCTION_FAILED;
      stateChanges.showCreateInstructionForm = false;
      stateChanges.showXPubInstructionForm = false;
      stateChanges.successMessageKey = undefined;
    }

    if (
      stateChanges.updateAccountMutationProcessing === false &&
      !isError(nextProps.updateAccountMutationError) &&
      get(nextProps, 'updateAccountMutationResult.account')
    ) {
      stateChanges.waiting = false;
      stateChanges.errorMessageKey = undefined;
      stateChanges.instructionIdEditing = undefined;
      stateChanges.showCreateInstructionForm = false;
      stateChanges.showXPubInstructionForm = false;
      stateChanges.accountIdEditing = '';
      stateChanges.successMessageKey = SUCCESS_MESSAGE_KEY_ACCOUNT_UPDATED;
    }

    if (
      stateChanges.updateAccountMutationProcessing === false &&
      isError(nextProps.updateAccountMutationError) &&
      !get(nextProps, 'updateAccountMutationResult.account')
    ) {
      stateChanges.waiting = false;
      stateChanges.errorMessageKey = ERROR_MESSAGE_KEY_UPDATE_ACCOUNT_FAILED;
      stateChanges.instructionIdEditing = undefined;
      stateChanges.successMessageKey = undefined;
    }

    if (
      stateChanges.updateCustomCustodianMutationProcessing === false &&
      !isError(nextProps.updateCustomCustodianMutationError) &&
      get(nextProps, 'updateCustomCustodianMutationResult.custodian')
    ) {
      stateChanges.waiting = false;
      stateChanges.errorMessageKey = undefined;
      stateChanges.custodian = undefined;
      stateChanges.isEditingCustodian = false;
      stateChanges.showCustodianForm = false;
      stateChanges.successMessageKey = SUCCESS_MESSAGE_KEY_CUSTODIAN_UPDATED;
    }

    if (
      stateChanges.updateCustomCustodianMutationProcessing === false &&
      isError(nextProps.updateCustomCustodianMutationError) &&
      !get(nextProps, 'updateCustomCustodianMutationResult.custodian')
    ) {
      stateChanges.waiting = false;
      stateChanges.errorMessageKey = ERROR_MESSAGE_KEY_UPDATE_CUSTODIAN_FAILED;
      stateChanges.custodian = undefined;
      stateChanges.isEditingCustodian = false;
      stateChanges.successMessageKey = undefined;
    }

    if (
      stateChanges.updateInstructionMutationProcessing === false &&
      !isError(nextProps.updateInstructionMutationError) &&
      get(nextProps, 'updateInstructionMutationResult.instruction')
    ) {
      stateChanges.waiting = false;
      stateChanges.errorMessageKey = undefined;
      stateChanges.instructionIdEditing = undefined;
      stateChanges.showCreateInstructionForm = false;
      stateChanges.showXPubInstructionForm = false;
      stateChanges.successMessageKey = SUCCESS_MESSAGE_KEY_INSTRUCTION_UPDATED;
    }

    if (
      stateChanges.updateInstructionMutationProcessing === false &&
      isError(nextProps.updateInstructionMutationError) &&
      !get(nextProps, 'updateInstructionMutationResult.instruction')
    ) {
      stateChanges.waiting = false;
      stateChanges.errorMessageKey = ERROR_MESSAGE_KEY_UPDATE_INSTRUCTION_FAILED;
      stateChanges.successMessageKey = undefined;
    }

    return stateChanges;
  }

  render() {
    const loading = this.props.portfolioQueryLoading

    const accounts = populateCryptoAccountsForList(this.props.accounts, get(this.props, 'currenciesQueryData.currencies'));

    let partitionedAccounts = partitionAccounts(accounts);
    partitionedAccounts.custodians = sortBy(partitionedAccounts.custodians.concat(this.props.customCustodians || []), ['name']);

    const existingCurrencies = getExistingCurrencies(accounts)

    return (
      <StyledSection className={COMPONENT_NAME}>
        <Menu attached="top">
          <Menu.Item header icon="table" content={copyText.sectionTitle} />
          <Menu.Menu position="right">
            <Menu.Item
              active={this.state.showCustodianForm}
              disabled={loading}
              content={copyText.addCustodian}
              icon="plus"
              onClick={this._handleClickCustodianForm}
            />
            <Menu.Item
              active={this.state.showAccountForm}
              disabled={loading}
              content={copyText.showAccountFormTabLabel}
              icon="keyboard"
              onClick={this._handleClickAccountForm}
            />
          </Menu.Menu>
        </Menu>
        {this.state.successMessageKey && (
          <Message
            className={cn('successMessage')}
            attached
            closeOnClick
            content={copyText[this.state.successMessageKey]}
            icon="check circle outline"
            success
          />
        )}
        {this.state.errorMessageKey && (
          <Message
            className={cn('errorMessage')}
            attached
            closeOnClick
            content={copyText[this.state.errorMessageKey]}
            icon="warning sign"
            error
          />
        )}
        <Segment className={cn('segment')} attached="bottom">
          {loading ? (
            <div className={cn('loaderWrapper')}>
              <Loader
                className={cn('loader')}
                active
                content={copyText.loadingMessage}
                size="medium"
              />
            </div>
          ) : (
            <Fragment>
              <div className={cn('subsegment')}>
                <div className={cn('accountsTableWrapper')}>
                  {this._renderAccountTables(partitionedAccounts)}
                </div>
              </div>
              {this.state.showAccountForm && (
                <div className={cn('subsegment')}>
                  <SaveCryptoAccountForm
                    currencies={sortBy(get(this.props, 'currenciesQueryData.currencies'), 'name')}
                    existingCurrencies={existingCurrencies}
                    custodians={partitionedAccounts.custodians}
                    onCancel={this._handleClickCancelAccountForm.bind(this)}
                    onSave={this._handleCreateAccount.bind(this)}
                    selfCustodianName={copyText.nullCustodian}
                  />
                </div>
              )}
              {(this.state.showCustodianForm || this.state.isEditingCustodian) && (
                <div className={cn('subsegment')}>
                  <CustomCustodianForm
                    crypto={true}
                    custodian={this.state.custodian}
                    onCancel={this._handleClickCancelCustodianForm}
                    onChange={this._handleChangeCustodian}
                    onSave={this._handleSaveCustodian}
                  />
                </div>
              )}
            </Fragment>
          )}
        </Segment>
      </StyledSection>
    );
  }

  _renderAccountTables({ accountsByCustodianId, accountsByCustomCustodianId, custodians }) {
    return custodians.map(custodian => {
      const isCustom = custodian.__typename === 'CustomCustodian';
      const accounts = isCustom ? accountsByCustomCustodianId[custodian.id] : accountsByCustodianId[custodian.id];
      const key = `${custodian.id}_${custodian.__typename}`;
      const isActive = this.state.openCustodianKey === key ||
        (accountsByCustodianId.length + accountsByCustomCustodianId.length) === 1;

      return (
        <Accordion fluid key={key}>
          <Accordion.Title
            className={cn(`accordionTitle ${isActive ? 'withActive' : ''}`)}
            active={isActive}
            onClick={this._handleToggleAccordionSection.bind(this, key)}>
            <Icon name={`triangle ${isActive ? 'down' : 'right'}`} />
            <span>
              {custodian.name || copyText.nullCustodian}
            </span>
          </Accordion.Title>
          {isActive ? (
            <Accordion.Content
              className={cn('accordionContent')}
              active={isActive}>
              {isCustom && this._renderCustomCustodianDetail(custodian)}
              <AccountsTable
                accountIdEditing={this.state.accountIdEditing}
                accounts={accounts}
                renderExpandedAccountDetailTable={this._renderExpandedAccountDetailTable.bind(this)}
                renderExpandedAccountUpdateForm={this._renderExpandedAccountUpdateForm.bind(this)}
                onSelectEditAccount={this._handleClickEditAccount.bind(this)}
                onUpdateAccount={this._handleUpdateAccount.bind(this)}
              />
            </Accordion.Content>
          ) : null}
        </Accordion>
      );
    });
  }

  //
  // Expandable Content Helpers
  //

  _renderExpandedAccountDetailTable(account) {
    return (
      <div className={cn('walletDetailsTableWrapper')}>
        <AccountDetailTable
          editingInstructionId={this.state.editingInstructionId}
          instructionIdEditing={this.state.instructionIdEditing}
          renderExpandedAccountInstructionForm={this._renderExpandedAccountInstructionForm.bind(
            this
          )}
          selectCreate={this._handleClickCreateInstruction.bind(this)}
          selectCreateXPub={this._handleClickCreateXPubInstruction.bind(this)}
          selectEdit={this._handleClickEditInstruction.bind(this)}
          showCreateInstructionForm={this.state.showCreateInstructionForm}
          showXPubInstructionForm={this.state.showXPubInstructionForm}
          wallet={account}
          onDeleteInstruction={this._handleDeleteInstruction.bind(this)}
        />
      </div>
    );
  }

  _renderExpandedAccountInstructionForm({ instruction, isXPub, wallet }) {
    return (
      <AccountInstructionForm
        account={wallet}
        instruction={instruction}
        isXPub={isXPub}
        onCancel={this._handleClickCancelButton.bind(this)}
        onSave={this._handleCreateInstruction.bind(this)}
        onUpdateInstruction={this._handleUpdateInstruction.bind(this)}
      />
    );
  }

  _renderExpandedAccountUpdateForm(account) {
    return (
      <div className={cn('walletDetailsTableWrapper')}>
        <AccountUpdateForm
          account={account}
          onCancel={this._handleClickCancelRenameAccount.bind(this)}
          onSave={this._handleUpdateAccount.bind(this)}
        />
      </div>
    );
  }

  _renderCustomCustodianDetail = custodian => (
    <CustodianDetail
      custodian={custodian}
      handleEdit={this._handleClickEditCustodian}
      isFiat={false}
    />
  )

  //
  // Event Handling operations
  //
  _handleClickCustodianForm = () => {
    this.setState(currentState => ({
      showAccountForm: false,
      showCustodianForm: !currentState.showCustodianForm,
      successMessageKey: undefined
    }));
  }

  _handleClickCancelCustodianForm = () => {
    this.setState({
      custodian: undefined,
      isEditingCustodian: false,
      showCustodianForm: false,
      successMessageKey: undefined
    });
  };

  _handleClickCancelButton() {
    this.setState({
      instructionIdEditing: null,
      showCreateInstructionForm: false,
      showXPubInstructionForm: false
    });
  }

  _handleClickCancelRenameAccount() {
    this.setState({
      accountIdEditing: null
    });
  }

  _handleClickCreateInstruction = bool => {
    this.setState({
      instructionIdEditing: null,
      showCreateInstructionForm: bool
    });
  };

  _handleClickCreateXPubInstruction = bool => {
    this.setState({
      instructionIdEditing: null,
      showXPubInstructionForm: bool
    });
  };

  _handleClickEditInstruction = id => {
    this.setState({
      instructionIdEditing: id,
      showCreateInstructionForm: false,
      showXPubInstructionForm: false
    });
  };

  _handleClickEditAccount = id => {
    this.setState({
      accountIdEditing: id
    });
  };

  _handleClickAccountForm = () => {
    this.setState(currentState => ({
      custodian: undefined,
      isEditingCustodian: false,
      showAccountForm: !currentState.showAccountForm,
      showCustodianForm: false,
      successMessageKey: undefined
    }));
  };

  _handleClickCancelAccountForm = () => {
    this.setState(currentState => ({
      showAccountForm: false,
      successMessageKey: undefined
    }));
  };

  _handleToggleAccordionSection = custodianKey => {
    const key = custodianKey === this.state.openCustodianKey ? null : custodianKey
    this.setState({ openCustodianKey: key });
  };


  _handleClickEditCustodian = custodian => {
    this.setState({
      custodian,
      isEditingCustodian: true,
      showAccountForm: false,
    });
  }

  _handleChangeCustodian = (input, event) => {
    const name = event.name;
    const value = has(input, 'nativeEvent') ? event.value : input.value;
    this.setState({ custodian: {...this.state.custodian, [name]: value} });
  };

  //
  // CRUD operations
  //

  _handleCreateAccount = attributes => {
    this.setState({
      errorMessageKey: undefined,
      successMessageKey: undefined,
      waiting: true,
    });
    this.props.createManualAccount(attributes);
  };

  _handleSaveCustodian = attributes => {
    this.setState({
      errorMessageKey: undefined,
      successMessageKey: undefined,
      waiting: true,
    });

    const { id, name, description } = attributes;
    if (this.state.isEditingCustodian) {
      this.props.updateCustomCustodian({ id, name, description })
    } else {
      this.props.createCustomCustodian({ id, name, description });
    }
  };

  _handleCreateInstruction = instruction => {
    this.setState({
      errorMessageKey: undefined,
      successMessageKey: undefined,
      waiting: true,
    });

    const { accountId, description, walletAddress, xpubKey } = instruction;
    this.props.createInstruction({
      accountId,
      description,
      walletAddress,
      xpubKey
    });
  };

  _handleDeleteInstruction = id => {
    this.setState({
      errorMessageKey: undefined,
      successMessageKey: undefined,
      waiting: true,
    });

    this.props.deleteInstruction({ id });
  };

  // This is only good for renaming, and only for NO-CUSTODIAN cases
  _handleUpdateAccount = attributes => {
    this.setState({
      errorMessageKey: undefined,
      successMessageKey: undefined,
      waiting: true,
    });

    this.props.updateAccount(attributes);
  };

  _handleUpdateInstruction = attributes => {
    this.setState({
      errorMessageKey: undefined,
      successMessageKey: undefined,
      waiting: true,
    });

    const { instructionId, description, walletAddress, xpubKey } = attributes;
    this.props.updateInstruction({
      instructionId,
      description,
      walletAddress,
      xpubKey
    });
  };
}

function getInitialState(props) {
  return {
    custodian: undefined,
    errorMessageKey: undefined,
    instructionIdEditing: '',
    isEditingCustodian: false,
    openCustodianKey: '',
    showAccountInstructionForm: '',
    successMessageKey: undefined,
    waiting: false,
  };
}

// TODO: when the map gets uncommented out in populateFiatAccountsForList pull out both functions and combine into one selector
const populateCryptoAccountsForList = createSelector(
  (accounts, currencies) => accounts,
  (accounts, currencies) => currencies,
  (accounts, currencies) => {
    if (
      !Array.isArray(accounts) ||
      accounts.length === 0 ||
      !Array.isArray(currencies) ||
      currencies.length === 0
    )
      return [];

    return sortBy(
      populateAccountsWithCurrency(accounts, currencies).filter(
        account =>
          get(account, 'currency.type') === CRYPTO &&
          get(account, 'custodian.id') !== '1'
      ),
      'custodian.name'
    ).map(account =>
      (account.custodian || account.customCustodian)
        ? account
        : {
            ...account,
            custodian: { id: 'N/A', name: copyText.nullCustodian }
          }
    );
  }
);
