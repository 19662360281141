const updateNotificationsSettingsFormCopy = {
  cancelButtonLabel: 'Cancel',
  notificationConditionsTitle: 'Notify me when',
  notificationConditions_orderCancelled: 'Order is Cancelled',
  notificationConditions_orderFilled: 'Order is Completed',
  notificationConditions_orderPlaced: 'Order is Placed',
  notificationConditions_venueExcludedFromAlgo: 'Venue is temporarily excluded from Algo',
  notificationConditions_venueRemovedFromAlgo: 'Venue is removed from Algo',
  notificationConditions_algoPaused: 'Algo is paused',
  notificationDeliveryMethodsTitle: 'Notify me by',
  notificationDeliveryMethods_email: 'Email',
  notificationDeliveryMethods_desktop: 'Desktop notification',
  saveButtonLabel: 'Update',
  popupText: 'To receive notifications, you’ll need to enable your browser to send notifications in your operating system settings.',
  popupTextDenied: 'Permission for sites to send notifications is turned off in your browser’s notifications settings.',
  popupLink: 'https://support.omniex.io/support/solutions/articles/36000196495-user-settings',
  testNotificationTitle: 'Test Message',
  testNotificationBody: 'This is an Omniex Edge test message. Subscribe to different types of notifications to stay updated on the status of your orders!',
  testNotificationButtonLabel: 'Send Test Notification'
};

export default updateNotificationsSettingsFormCopy;
