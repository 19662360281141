import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@omniex/poms-ui/lib/themes';
import copyText from './copyText';
import clsx from 'clsx';

const COMPONENT = 'DownloadReportButton';

const styles = {
  button: {
    height: 37.625,
    padding: 5,
  },
  downloadReportButton: {
    margin: '5px',
    flex: '1 1 auto',
    backgroundColor: '#266dbe',
    color: 'white',
  },
  cancelReportButton: {
    margin: '5px',
    flex: '1 1 auto',
    backgroundColor: '#EF7D4A',
    color: 'white'
  },
};

const useStyles = makeStyles(styles, { name: COMPONENT });

export interface DownloadReportButtonProps {
  onClick: () => void,
  disabled: boolean
};

export const DownloadReportButton = ({ onClick, disabled }: DownloadReportButtonProps) => {
  const cls = useStyles();

  return (
    <Button
      className={clsx(cls.button, cls.downloadReportButton)}
      variant='contained'
      disabled={disabled}
      size='small'
      onClick={onClick}
    >
      {copyText.downloadButtonLabel}
    </Button>
  );
};

export interface CancelReportButtonProps {
  onClick: () => void
};

export const CancelReportButton = ({ onClick }: CancelReportButtonProps) => {
  const cls = useStyles();

  return (
    <Button
      className={clsx(cls.button, cls.cancelReportButton)}
      variant='contained'
      size='small'
      onClick={onClick}
      endIcon={ <CircularProgress size={20} /> }
    >
      {copyText.cancelButtonLabel}
    </Button>
  );
};
