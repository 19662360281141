import { useQuery, useMutation, useSubscription } from 'react-apollo';
import React from 'react';

import { ERROR_PERMISSION_DENIED } from '@omniex/onx-common-ui/lib/errors';
import { safeExecAsync } from '../utils/FunctionUtils';
import cancelOrderMutation from '../apollo/graphql/sell-side/cancelOrder';
import cancelQuoteRequestMutation from '../apollo/graphql/sell-side/cancelQuoteRequest';
import claimQuoteRequestMutation from '../apollo/graphql/sell-side/claimQuoteRequest';
import ErrorPage from '@omniex/onx-common-ui/lib/pages/ErrorPage';
import fillOrderMutation from '../apollo/graphql/sell-side/fillOrder';
import getActiveOrders from '../apollo/graphql/sell-side/getActiveOrders';
import getActiveQuoteRequests from '../apollo/graphql/sell-side/getActiveQuoteRequests';
import getCurrencies from '../apollo/graphql/getCurrencies';
import getFullInstruments from '../apollo/graphql/getFullInstruments';
// import getInstruments from '../apollo/graphql/getInstruments';
import getRecentlyCompletedOrders from '../apollo/graphql/sell-side/getRecentlyCompletedOrders';
import OrgType from '@omniex/onx-poms-entity-helpers/lib/enums/OrgType';
import QuotePage from '../ui/pages/QuotePage';
import refreshActiveOrders from '../apollo/graphql/sell-side/refreshActiveOrders';
import refreshActiveQuoteRequests from '../apollo/graphql/sell-side/refreshActiveQuoteRequests';
import refreshRecentlyCompletedOrders from '../apollo/graphql/sell-side/refreshRecentlyCompletedOrders';
import sendQuoteMutation from '../apollo/graphql/sell-side/sendQuote';

const QuotePageWithAuth = ({ apiClient, fixApiClient, user }) => {
  const useApiQuery = (q, o) => useQuery(q, { client: apiClient, ...o })
  const useMakerQuery = (q, o) => useQuery(q, { client: fixApiClient, ...o })
  const useMakerMutation = (m, o) => useMutation(m, { client: fixApiClient, ...o })
  const useMakerSubscription = (s, o) => useSubscription(s, { client: fixApiClient, ...o })

  // TODO remove this once the maker's `getRecentlyCompletedOrders` endpoint returns symbol along with type `Currency`
  const qCurrencies = useApiQuery(getCurrencies)
  const qInstruments = useApiQuery(getFullInstruments)

  const [cancelRequest, mCancelRequest] = useMakerMutation(cancelQuoteRequestMutation)
  const [claimRequest, mClaimRequest] = useMakerMutation(claimQuoteRequestMutation)
  const [sendQuote, mSendQuote] = useMakerMutation(sendQuoteMutation)
  const [cancelOrder, mCancelOrder] = useMakerMutation(cancelOrderMutation)
  const [fillOrder, mFillOrder] = useMakerMutation(fillOrderMutation)

  const qRequests = useMakerQuery(getActiveQuoteRequests, {fetchPolicy: 'network-only'})
  const sRequests = useMakerSubscription(refreshActiveQuoteRequests)
  const qActiveOrders = useMakerQuery(getActiveOrders, {fetchPolicy: 'network-only'})
  const sActiveOrders = useMakerSubscription(refreshActiveOrders)
  const qCompletedOrders = useMakerQuery(getRecentlyCompletedOrders, {fetchPolicy: 'network-only'})
  const sCompletedOrders = useMakerSubscription(refreshRecentlyCompletedOrders)

  return (
    <QuotePage
      activeOrdersQueryData={qActiveOrders?.data}
      activeOrdersQueryError={qActiveOrders?.error}
      activeOrdersQueryLoading={qActiveOrders?.loading}
      activeOrdersSubscriptionData={sActiveOrders?.data}
      activeOrdersSubscriptionError={sActiveOrders?.error}
      activeOrdersSubscriptionLoading={sActiveOrders?.loading}
      activeQuoteRequestsQueryData={qRequests?.data}
      activeQuoteRequestsQueryError={qRequests?.error}
      activeQuoteRequestsQueryLoading={qRequests?.loading}
      activeQuoteRequestsSubscriptionData={sRequests?.data}
      activeQuoteRequestsSubscriptionError={sRequests?.error}
      activeQuoteRequestsSubscriptionLoading={sRequests?.loading}
      claimQuoteRequest={id => safeExecAsync(claimRequest, {variables: {id}})}
      claimQuoteRequestMutationError={mClaimRequest?.error}
      claimQuoteRequestMutationProcessing={mClaimRequest?.loading}
      claimQuoteRequestMutationResult={mClaimRequest?.data}
      currenciesQueryData={qCurrencies?.data}
      currenciesQueryError={qCurrencies?.error}
      currenciesQueryLoading={qCurrencies?.loading}
      cancelOrder={id => safeExecAsync(cancelOrder, {variables: {id}})}
      cancelOrderMutationError={mCancelOrder?.error}
      cancelOrderMutationProcessing={mCancelOrder?.loading}
      cancelOrderMutationResult={mCancelOrder?.data}
      cancelQuoteRequest={id => safeExecAsync(cancelRequest, {variables: {id}})}
      cancelQuoteRequestMutationError={mCancelRequest?.error}
      cancelQuoteRequestMutationProcessing={mCancelRequest?.loading}
      cancelQuoteRequestMutationResult={mCancelRequest?.data}
      fillOrder={id => safeExecAsync(fillOrder, {variables: {id}})}
      fillOrderMutationError={mFillOrder?.error}
      fillOrderMutationProcessing={mFillOrder?.loading}
      fillOrderMutationResult={mFillOrder?.data}
      instrumentsQueryData={qInstruments?.data}
      instrumentsQueryError={qInstruments?.error}
      instrumentsQueryLoading={qInstruments?.loading}
      recentlyCompletedOrdersQueryData={qCompletedOrders?.data}
      recentlyCompletedOrdersQueryError={qCompletedOrders?.error}
      recentlyCompletedOrdersQueryLoading={qCompletedOrders?.loading}
      recentlyCompletedOrdersSubscriptionData={sCompletedOrders?.data}
      recentlyCompletedOrdersSubscriptionError={sCompletedOrders?.error}
      recentlyCompletedOrdersSubscriptionLoading={sCompletedOrders?.loading}
      sendQuote={variables => safeExecAsync(sendQuote, {variables})}
      sendQuoteMutationError={mSendQuote?.error}
      sendQuoteMutationProcessing={mSendQuote?.loading}
      sendQuoteMutationResult={mSendQuote?.data}
      user={user}
    />
  )
}

const QuotePageContainer = ({user, ...props}) => user?.org?.type === OrgType.CLIENT_SELL_SIDE && user?.canViewQuote
  ? <QuotePageWithAuth user={user} {...props} /> 
  : <ErrorPage type={ERROR_PERMISSION_DENIED} />

export default QuotePageContainer
