export const OTCCopyText = {
  active: 'Order in progress.',
  filled: 'Order successfully completed.',
  error: 'Unable to place order. Please try again. If this issue persists, contact support.',
  inspector: 'For more information, go to the blotter, or view the ',
  inspectorActive: 'To manage this order, go to the blotter, or view the ',
  addTile: 'Add Tile',
  newTile: 'New Tile',
  deleteTile: 'Delete Tile',
  deleteTemplate: 'Delete Template',
  buy: 'Buy',
  bought: 'Bought',
  sell: 'Sell',
  sold: 'Sold',
  combinedParamLabel: 'Stop/Limit Price',
  stopPrice: 'Stop Price',
  limitPrice: 'Limit Price',
  templateLabel: 'Saved Templates',
  tileLabel: 'Active Tiles',
  dismiss: 'Dismiss',
  save: 'Save Template',
  duplicate: 'Duplicate Tile',
};

export default OTCCopyText;
