import { arrayOf, shape, string } from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { format as formatDate } from '@omniex/onx-common-js/lib/utils/DateTimeUtils';
import { get, has } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './UserInfoComponent.copyText';

const COMPONENT_NAME = 'UserInfoComponent';

const StyledDiv = styled('div')`
  min-width: 150px;

  .${COMPONENT_NAME}-entry {
    div:first-child {
      color: ${colors.grey};
      display: inline-block;
      font-size: 12px;
      min-width: 150px;
      text-align: right;
    }

    div:last-child {
      display: inline-block;
      margin-bottom: 10px;
      margin-left: 10px;
    }

    :last-child {
      div:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class UserInfoComponent extends PureComponent {
  static propTypes = {
    user: shape({
      id: string.isRequired,
      emailAddress: string.isRequired,
      name: string.isRequired,
      org: shape({ name: string.isRequired }).isRequired,
      roles: arrayOf(shape({ type: string.isRequired })).isRequired,
      timeCreated: string.isRequired,
      username: string.isRequired
    })
  };

  static defaultProps = {};

  render() {
    const roleNames = get(this.props, 'user.roles', [])
      .map(role => copyText[`labelRole_${role.type}`])
      .join(', ');

    return (
      <StyledDiv className={COMPONENT_NAME}>
        <div className={cn('entry')}>
          <div>{copyText.labelOrgName}:</div>
          <div>{get(this.props, 'user.org.name', '')}</div>
        </div>
        <div className={cn('entry')}>
          <div>{copyText.labelName}:</div>
          <div>{get(this.props, 'user.name', '')}</div>
        </div>
        <div className={cn('entry')}>
          <div>{copyText.labelUsername}:</div>
          <div>{get(this.props, 'user.username', '')}</div>
        </div>
        <div className={cn('entry')}>
          <div>{copyText.labelEmailAddress}:</div>
          <div>{get(this.props, 'user.emailAddress', '')}</div>
        </div>
        <div className={cn('entry')}>
          <div>{copyText.labelRoles}:</div>
          <div>{roleNames}</div>
        </div>
        <div className={cn('entry')}>
          <div>{copyText.labelTimeCreated}:</div>
          <div>
            {has(this.props, 'user.timeCreated')
              ? formatDate(this.props.user.timeCreated, null, 'YYYY-MM-DD')
              : ''}
          </div>
        </div>
      </StyledDiv>
    );
  }
}
