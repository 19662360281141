import * as React from 'react';
import clsx from 'clsx';
import { WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@omniex/poms-ui/lib/themes';
import { Theme } from '@omniex/poms-ui/lib/types';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import { AutoSizer, InfiniteLoader, MultiGrid, GridCellProps } from 'react-virtualized';
import { HeaderCell } from './Header';
import { BaseMultiGridProps, MultiGridCellProps } from '.';
// @ts-ignore
import colors from '@omniex/onx-common-ui/lib/styles/colors';

const styles = (theme: Theme) => createStyles({
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    borderRadius: 2
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableRowOdd: {
    backgroundColor: theme.palette.grey[50]
  },
  cell: {
    borderRightColor: theme.palette.grey[400],
    borderRightStyle: 'solid',
    borderRightWidth: 1,
    flex: 1,
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
    maxWidth: '100%',
  },
  innerSpan: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  // header classes
  controls: {
    display: 'flex',
    alignItems: 'center',
  },
  filter: {
    color: colors.grey,
    cursor: 'pointer',
    marginRight: '0em',
    opacity: '0.8',

    '&:hover, &:active': {
      opacity: '1',
    }
  },
  adornedStartSign: {
    paddingLeft: 0,
  },
  menuItemLabel: {
    paddingLeft: '5px',
  },
  header: {
    backgroundColor: theme.palette.grey[200],
    borderRightColor: theme.palette.grey[400],
    borderRightStyle: 'solid',
    borderRightWidth: 1,
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 'bold',
    width: '100%',
    justifyContent: 'space-between',
  },
});

type CellContentProps = Omit<MultiGridCellProps, 'cellData'> & {
  innerClass: string
  noDataLabel: string
}

// can't use FC here as ReactNode cannot be assigned to the JSXElement constraint
const renderCellContent = ({
  columnData,
  columnIndex,
  dataKey,
  innerClass,
  isScrolling,
  isVisible,
  noDataLabel,
  parent,
  rowData,
  rowIndex,
}: CellContentProps) => {
  if (!rowData) return (
    <span className={innerClass}>
      {noDataLabel}
    </span>
  );

  const cellData = typeof columnData.cellDataGetter === 'function'
    ? columnData.cellDataGetter({ columnData, dataKey, rowData })
    : rowData[dataKey];

  return typeof columnData.render === 'function' ? columnData.render({
    cellData,
    columnData,
    columnIndex,
    dataKey,
    parent,
    isScrolling: !!isScrolling,
    isVisible,
    rowData,
    rowIndex,
  }) : (
    <Tooltip title={cellData}>
      <span className={innerClass}>{cellData}</span>
    </Tooltip>
  );
};

type Props = WithStyles<typeof styles> & BaseMultiGridProps

class InfiniteMultiGrid extends React.PureComponent<Props> {
  constructor(props: Props, context: any) {
    super(props, context)
    this._cellRenderer = this._cellRenderer.bind(this);
  }

  _cellRenderer({
    columnIndex,
    isScrolling,
    isVisible,
    key,
    parent,
    rowIndex,
    style
  }: GridCellProps) {
    const { classes, columns, data, fixedRowCount, onCellClick, onCellDoubleClick, onSort, filters, setFilter, sortBy, sortDirection } = this.props;
    const column = columns[columnIndex];
    const { dataKey } = column;
    if (fixedRowCount && rowIndex < fixedRowCount) {
      return (
        <HeaderCell
          filter={filters?.[dataKey]}
          columnIndex={columnIndex}
          key={key}
          onSort={onSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
          columns={columns}
          classes={classes}
          setFilter={setFilter?.(dataKey)}
          style={style}
        />
      )
    }

    const adjustedRowIndex = rowIndex - 1;
    const rowData = data[adjustedRowIndex];
    const noDataLabel = data.length
      ? columnIndex === 0 || columnIndex === columns.length - 1
        ? '...'
        : 'Loading...'
      : '--';

    return (
      <TableCell
        component='div'
        variant='body'
        align={columnIndex != null && column.numeric ? 'right' : 'left'}
        className={clsx(classes.cell, classes.flexContainer, {
          [classes.tableRowOdd]: adjustedRowIndex > 0 && adjustedRowIndex % 2,
        })}
        key={key}
        style={style}
        onClick={(event) => onCellClick?.({ event, rowIndex: adjustedRowIndex, columnIndex })}
        onDoubleClick={(event) => onCellDoubleClick?.({ event, rowIndex: adjustedRowIndex, columnIndex })}
      >
        {renderCellContent({
          columnData: column,
          columnIndex,
          dataKey,
          innerClass: classes.innerSpan,
          isScrolling,
          isVisible,
          noDataLabel,
          parent,
          rowData,
          rowIndex: adjustedRowIndex,
        })}
      </TableCell>
    )
  }

  render() {
    const {
      classes,
      columns,
      data,
      filters,
      forwardedRef,
      headerHeight = 48,
      height = 400,
      isNextPageLoading,
      loadNextPage,
      onCellClick,
      onCellDoubleClick,
      onSort,
      remoteRowCount = Infinity,
      rowHeight = 48,
      setFilter,
      sortBy,
      sortDirection,
      ...gridProps
    } = this.props;
    const hasNextPage = remoteRowCount !== data.length;
    const fixedRowCount = this.props.fixedRowCount ? this.props.fixedRowCount : 0;
    const itemCount = hasNextPage ? data.length + 1 : data.length;
    const { fixedWidth, variableWidth } = columns.reduce((total, { width }, i) => {
      if (i < fixedRowCount) total.fixedWidth += width;
      else total.variableWidth += width;
      return total;
    }, { fixedWidth: 0, variableWidth: 0 });
    const totalWidth = fixedWidth + variableWidth;
    const estimatedColumnWidth = Math.floor(Math.E * (variableWidth / (columns.length - (this.props.fixedColumnCount ?? 0))));

    return (
      <TableContainer component='div'>
        <InfiniteLoader
          isRowLoaded={({ index }) => !hasNextPage || index < data.length}
          rowCount={itemCount}
          loadMoreRows={isNextPageLoading ? async () => {} : loadNextPage}
          minimumBatchSize={50}
        >
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer disableHeight>
              {({ width }) => (
                <MultiGrid
                  {...gridProps}
                  cellRenderer={this._cellRenderer}
                  columnCount={columns.length}
                  columnWidth={({ index }) => columns[index].width}
                  estimatedColumnWidth={estimatedColumnWidth}
                  estimatedRowSize={rowHeight}
                  height={height}
                  onSectionRendered={({
                    rowStartIndex,
                    rowStopIndex,
                  }) => {
                    onRowsRendered({
                      startIndex: Math.min(0, rowStartIndex - fixedRowCount),
                      stopIndex: rowStopIndex - fixedRowCount,
                    })
                  }}
                  ref={(instance) => {
                    registerChild(instance);
                    if (typeof forwardedRef === 'function') forwardedRef(instance);
                    else if (forwardedRef) forwardedRef.current = instance;
                  }}
                  rowCount={fixedRowCount + (itemCount || 1)}
                  rowHeight={({ index }) => index === 0 ? headerHeight : rowHeight}
                  width={Math.min(totalWidth + 15, width)}
                />
              )}
            </AutoSizer>
          )}
        </InfiniteLoader>
      </TableContainer>
    )
  }
};

const InfiniteMultiGridFR = React.forwardRef<MultiGrid, Props>((
  props,
  ref,
) => <InfiniteMultiGrid {...props} forwardedRef={ref} />);

export default withStyles(styles)(InfiniteMultiGridFR as any) as unknown as React.ForwardRefExoticComponent<BaseMultiGridProps<any> & React.RefAttributes<MultiGrid>>;
