import { css, cx } from 'react-emotion';
import { object } from 'prop-types';
import React, { PureComponent } from 'react';

import { darken } from '@omniex/onx-common-ui/lib/colors/utils';
import { format } from '@omniex/onx-common-js/lib/utils/NumberUtils';
import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { HighchartsAdapter } from '../../ext/react-highcharts';
import { isPlainObject } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { unique } from '@omniex/onx-common-js/lib/utils/ArrayUtils';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './CorrelationMatrixComponent.copyText';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';

const styles = css`
  .highcharts-text-outline {
    fill: none;
    stroke-width: 0;
  }
`;

const rowHeight = 25;

export default class CorrelationMatrixComponent extends PureComponent {
  static propTypes = { currencyStatisticsQueryData: object };

  render() {
    if (!isPlainObject(this.props.currencyStatisticsQueryData)) {
      return this._renderWarningMessage();
    }
    if (Object.keys(this.props.currencyStatisticsQueryData).length === 0) {
      return this._renderWarningMessage();
    }
    return (
      <HighchartsAdapter
        domProps={{ className: cx('CorrelationMatrixComponent', styles) }}
        config={this._getChartConfig()}
        isPureConfig={true}
      />
    );
  }

  _getChartConfig() {
    const stats = this.props.currencyStatisticsQueryData.currencyStatistics;

    const symbols = unique(stats.map(stat => stat.baseSymbol));

    const symbolsIndexMap = symbols.reduce(
      (accum, symbol, i) => ({ ...accum, [symbol]: i }),
      {}
    );

    let dataMatrix = new Array(symbols.length);
    for (let i = 0; i < symbols.length; i++) {
      dataMatrix[i] = new Array(symbols.length);
    }

    for (const stat of stats) {
      dataMatrix[symbolsIndexMap[stat.baseSymbol]][
        symbolsIndexMap[stat.termSymbol]
      ] = stat;
    }
    const pairs = symbols.reduce(
      (accum, symbol1) => [
        ...accum,
        ...symbols.map(symbol2 => [
          symbolsIndexMap[symbol1],
          symbolsIndexMap[symbol2]
        ])
      ],
      []
    );
    const chartData = pairs.map(([symbolIndex1, symbolIndex2]) => [
      symbolIndex1,
      symbolIndex2,
      get(dataMatrix[symbolIndex1][symbolIndex2], 'correlation', get(
        dataMatrix[symbolIndex2][symbolIndex1], 'correlation', 0
      ))
    ]);
    const component = this;
    return {
      chart: {
        type: 'heatmap',
        height: rowHeight * symbols.length,
        marginTop: 30,
        spacingRight: -10
      },
      colorAxis: {
        labels: {
          format: '{value:.1f}',
          style: {
            fontSize: '80%'
          },
          y: 3,
          x: 5
        },
        max: 1,
        min: -1,
        stops: [
          [0, colors.blue],
          [0.5, darken(colors.white, 5)],
          [1, colors.red]
        ]
      },
      legend: {
        align: 'right',
        layout: 'vertical',
        margin: 0,
        symbolHeight: (rowHeight - 5) * symbols.length,
        verticalAlign: 'middle',
        y: 17
      },
      plotOptions: {
        heatmap: {
          borderColor: colors.white,
          borderWidth: 1,
          dataLabels: {
            color: colors.black,
            enabled: true,
            format: '{point.value:.2f}',
            inside: true,
            style: {
              fontSize: '90%'
            },
            y: -1
          }
        }
      },
      series: [
        {
          data: chartData,
          name: 'Correlation'
        }
      ],
      tooltip: {
        borderColor: colors.black,
        formatter: function() {
          return component._renderTooltipContent.call(this, {
            symbols,
            dataMatrix,
            symbolsIndexMap
          });
        },
        useHTML: true
      },
      xAxis: {
        categories: symbols,
        labels: {
          rotation: -45,
          style: {
            fontSize: '80%'
          },
          y: -5
        },
        lineWidth: 0,
        opposite: true,
        tickWidth: 0,
        type: 'symbol'
      },
      yAxis: {
        categories: symbols,
        gridLineWidth: 0,
        labels: {
          style: {
            fontSize: '80%'
          },
          x: -10,
          y: 3
        },
        reversed: true,
        title: null,
        type: 'symbol'
      }
    };
  }

  _renderTooltipContent(context) {
    const { point } = this;
    const { symbols, dataMatrix, symbolsIndexMap } = context;
    const symbol1 = symbols[point.y];
    const symbol2 = symbols[point.x];
    const correlation = point.value;
    const covariance = (
      dataMatrix[symbolsIndexMap[symbol1]][symbolsIndexMap[symbol2]] ||
      dataMatrix[symbolsIndexMap[symbol2]][symbolsIndexMap[symbol1]]
    ).covariance;
    const content = `
<table>
  <tr>
    <th colspan="3" style="color: ${
      colors.grey
    }; text-align: center; font-weight: bold; padding-bottom: 4px;">
      ${symbol1} / ${symbol2}
    </th>
  </tr>
  <tr>
    <td style="font-weight: bold;">Correlation</td>
    <td style="text-align: right;">${format(correlation, '0.000000')}</td>
  </tr>
  <tr>
    <td style="font-weight: bold;">Covariance</td>
    <td style="text-align: right;">${format(covariance, '0.000000')}</td>
  </tr>
</table>
`.replace(/>[\s\n]+</g, '><');
    return content;
  }

  _renderWarningMessage() {
    return (
      <div>
        <Message content={copyText.warningMessage} warning />
      </div>
    );
  }
}
