import { Menu } from 'semantic-ui-react';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'react-emotion';

import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './MaintenanceCountdownMenuItem.copyText';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');

const COMPONENT = 'MaintenanceCountdownMenuItem';

const StyledMenuItem = styled(Menu.Item)`
  background-color: ${colors.orange} !important;
  color: #404040 !important;
  display: flex;
  flex-direction: row;
  font-weight: bold !important;
  justify-content: space-around;
  width: 240px;

  .${COMPONENT}-text {
    padding-right: 14px !important;
    text-align: right !important;
    width: 150px;
  }

  .${COMPONENT}-number {
    font-size: 2em !important;
    font-weight: 900;
    text-align: center;
    width: 100px;
  }
`;

const cn = c => `${COMPONENT}-${c}`;
const timeUntil = t => (t - Date.now())/1000|0
const pad = n => `${n < 10 ? 0 : ''}${n}`
const format = t => {
  const h = t/3600|0
  const m = (t - 3600*h)/60|0
  const s = t - 3600*h - 60*m
  return `${h ? `${pad(h)}:` : ''}${pad(m)}:${pad(s)}`
}

const MaintenanceCountdownMenuItem = ({ expirationDate, loginUrl }) => {
  const [seconds, setSeconds] = useState(timeUntil(expirationDate))

  const updateTime = useCallback(_ => {
    const time = timeUntil(expirationDate)
    if (time < 1) window.location.href = window.encodeURI(loginUrl)
    setSeconds(time)
  }, [expirationDate, loginUrl])

  useEffect(_ => {
    const timer = setInterval(updateTime, 500)
    return _ => clearInterval(timer)
  })

  return (
    <StyledMenuItem className={COMPONENT} color="orange">
      <div className={cn('text')}>{copyText.message}</div>
      <div className={cn('number')}>{format(seconds)}</div>
    </StyledMenuItem>
  )
}

export default MaintenanceCountdownMenuItem
