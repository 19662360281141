import * as React from 'react';
import { useState } from 'react';
import { Button, Dropdown, Form, DropdownProps } from 'semantic-ui-react';
import styled from 'react-emotion';
import copyText from './TradeSettings.copyText.js';
import { TradeSettings } from '../../types';
import { has } from 'lodash';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/button.css');
require('@omniex/onx-common-ui/lib/semantic/css/form.css');

const COMPONENT_NAME = 'UpdateTradeSettingsForm';

const cn = (elementName: string) => `${COMPONENT_NAME}-${elementName}`;

const StyledForm = styled(Form)`
  min-width: 180px;
  margin: auto;

  .${COMPONENT_NAME}-section {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .${COMPONENT_NAME}-buttons {
    margin-top: 10px;
  }

  .ui.button.basic.link, .ui.button.basic.link:hover, .ui.button.basic.link:focus {
    box-shadow: 0 0 !important;
    color: #006FBF !important;
    padding: 3px !important;
    margin: 0 !important;
  }
`;


const getDefaultState = (settings?: TradeSettings) => {
  const TRADE_SETTINGS_KEYS: Array<keyof TradeSettings> = ['executeOnSingleClickOTC'];
  const DEFAULT_TRADE_SETTINGS: TradeSettings = { executeOnSingleClickOTC: false };

  if (!settings) return { ...DEFAULT_TRADE_SETTINGS };
  return TRADE_SETTINGS_KEYS.reduce<TradeSettings>((acc, cur) => {
    acc[cur] = has(settings, cur) ? settings[cur] : DEFAULT_TRADE_SETTINGS[cur];
    return acc;
  }, {} as TradeSettings);
}

const singleClickOptions = [
  {
    key: 'singleClick',
    text: copyText.singleClickLabel,
    value: true,
  },
  {
    key: 'doubleClick',
    text: copyText.doubleClickLabel,
    value: false,
  },
];

export type UpdateTradeSettingsFormProps = {
  handleCancel: () => void;
  handleSave: (newSettings: TradeSettings) => void;
  processing: boolean;
  settings?: TradeSettings;
}

export function UpdateTradeSettingsForm({
  handleCancel,
  handleSave,
  processing,
  settings,
}: UpdateTradeSettingsFormProps) {
  const [state, setState] = useState(getDefaultState(settings));

  const onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    handleCancel();
  }
  const onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    handleSave(state);
  }

  const onChange = (_: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) =>
    setState({ executeOnSingleClickOTC: data.value as boolean });

  return (
    <StyledForm className={COMPONENT_NAME}>
      <div className={cn('section')}>
        {copyText.otcClickLabel}:
        <Form.Field className={cn('dropdown')}>
          <Dropdown
            name='singleClick'
            value={state.executeOnSingleClickOTC}
            fluid
            selection
            options={singleClickOptions}
            onChange={onChange}
          />
        </Form.Field>
      </div>
      <div className={cn('buttons')}>
        <Form.Field>
          <Button
            color="blue"
            disabled={processing}
            fluid
            icon
            labelPosition="left"
            loading={processing}
            onClick={onSubmit}>
            <Button.Content>
              <span>{copyText.saveButtonLabel}</span>
            </Button.Content>
          </Button>
        </Form.Field>
        <Form.Field>
          <Button
            color="orange"
            disabled={processing}
            fluid
            icon
            labelPosition="left"
            loading={processing}
            onClick={onCancel}>
            <Button.Content>
              <span>{copyText.cancelButtonLabel}</span>
            </Button.Content>
          </Button>
        </Form.Field>
      </div>
    </StyledForm>
  );
}

export default UpdateTradeSettingsForm;
