const adminPageOrgSettingsSectionCopy = {
  errorMessageUnexpected:
    'Sorry, an unexpected error occured. Please try again.',
  errorMessageUpdateOrgFailed: 'Sorry, something went wrong. Please try again.',
  rightMenuItemLabel: 'Update Settings',
  sectionTitle: 'Org Settings',
  successMessageOrgUpdated:
    'Your organization settings have been updated successfully.'
};

export default adminPageOrgSettingsSectionCopy;
