import gql from 'graphql-tag';

export default gql`
  query getPortfolio($id: ID!) {
    portfolio: getPortfolio(id: $id) {
      id
      accounts {
        id
        currency {
          id
        }
        custodian {
          id
          name
          operatingVenue {
            id
          }
          type
        }
        customCustodian {
          id
          name
          type
          bankAddress
          swiftCode
          description
        }
        instructions {
          id
          accountId
          description
          freeForm
          iban
          timeCreated
          walletAddress
          xpubKey
          xpubParentId
        }
        name
        timeCreated
      }
      name
    }
  }
`;
