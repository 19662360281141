import gql from 'graphql-tag';

export default gql`
  mutation updateInstruction(
    $instructionId: ID!
    $description: String
    $freeForm: String
    $iban: String
    $walletAddress: String
    $xpubKey: String
  ) {
    instruction: updateInstruction(
      instructionId: $instructionId
      description: $description
      freeForm: $freeForm
      iban: $iban
      walletAddress: $walletAddress
      xpubKey: $xpubKey
    ) {
      id
    }
  }
`;
