import { Popup } from 'semantic-ui-react';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';
import { isNil } from '@omniex/poms-core/lib/utils/LangUtils';
import { getLastNChars } from '../../utils/DisplayUtils';
import orderBlotterTableCopyText from './OrderBlotterTable.copyText';
import OrderSource from '@omniex/poms-core/lib/enums/OrderSource';
import copyText from './OrderIDPopupComponent.copyText';

const NUM_DIGITS_ORDER_ID = 10;
const MILLIS_DELAY = 200;

const popupStyles = {
  container: {
    fontSize: '0.9em',
    padding: '8px 16px',
    position: 'absolute'
  },
  identifierLabel: {
    fontSize: '0.9em',
    textAlign: 'right'
  },
  identifier: {
    fontFamily: 'monospace',
    fontSize: '0.9em'
  },
  info: {
    fontSize: '0.9em',
    textAlign: 'center'
  }
};

const COMPONENT_NAME = 'OrderIDPopup';

const StyledDiv = styled('div')`
  font-size: 0.85em;
  text-align: center;

  .${COMPONENT_NAME}-monospaceId {
    font-family: monospace !important;
    font-size: 1em !important;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class OrderIDPopupComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { copyStateText: copyText.popupCopyToClipboard };
  }

  _clickedOrderID = event => {
    event.preventDefault();
    navigator.clipboard.writeText(this.props.fixClOrdID).then(
      () => this.setState({ copyStateText: copyText.popupCopySuccess }),
      () => this.setState({ copyStateText: copyText.popupCopyFailure })
    );
  };

  _resetText = event => this.setState({ copyStateText: copyText.popupCopyToClipboard });

  _renderOrderIDColumn = () => {
    return this.props.source === OrderSource.EXTERNAL
      ? `Placed on ${this.props.venue.name}`
      : getLastNChars(this.props.fixClOrdID, NUM_DIGITS_ORDER_ID);
  };

  render() {
    return (
      <Popup
        flowing
        closeOnTriggerBlur
        inverted
        mouseEnterDelay={MILLIS_DELAY}
        mouseLeaveDelay={MILLIS_DELAY}
        onClose={this._resetText}
        position="top center"
        style={popupStyles.container}
        trigger={
          <StyledDiv>
            <span className={cn('monospaceId')} onContextMenu={this._clickedOrderID}>
              {this._renderOrderIDColumn()}
            </span>
          </StyledDiv>
        }>
        <table>
          <tbody>
            {this.props.source === OrderSource.INTERNAL ? (
              <tr>
                <td style={popupStyles.identifierLabel}>
                  {orderBlotterTableCopyText.popupLabelOrderID}:
                </td>

                <td style={popupStyles.identifier}>
                  {isNil(this.props.fixClOrdID)
                    ? orderBlotterTableCopyText.notAvailable
                    : this.props.fixClOrdID}
                </td>
              </tr>
            ) : null}
            {isNil(this.props.fixQuoteID) ||
            this.props.source === OrderSource.EXTERNAL ? null : (
              <tr>
                <td style={popupStyles.identifierLabel}>
                  {orderBlotterTableCopyText.popupLabelQuoteID}:
                </td>
                <td style={popupStyles.identifier}>{this.props.fixQuoteID}</td>
              </tr>
            )}
            {this.props.source === OrderSource.INTERNAL ? (
              <tr>
                <td style={popupStyles.identifierLabel}>
                  {orderBlotterTableCopyText.popupLabelEMSOrderID}:
                </td>
                <td style={popupStyles.identifier}>
                  {isNil(this.props.fixOrderID)
                    ? orderBlotterTableCopyText.notAvailable
                    : this.props.fixOrderID}
                </td>
              </tr>
            ) : null}
            {!isNil(this.props.fixVenueOrderID) && (
              <tr>
                <td style={popupStyles.identifierLabel}>
                  {orderBlotterTableCopyText.popupLabelVenueOrderID}:
                </td>

                  <td style={popupStyles.identifier}>
                    {this.props.fixVenueOrderID}
                  </td>
              </tr>
            )}
            {isNil(this.props.fixClOrdID) ? null : (
              <tr style={popupStyles.info}>
                <td colSpan={2}>{this.state.copyStateText}</td>
              </tr>
            )}
          </tbody>
        </table>
      </Popup>
    );
  }
}
