import { bool, func, shape, string } from 'prop-types';
import { Button, Form, Input } from 'semantic-ui-react';
import React, { Component } from 'react';
import styled from 'react-emotion';
import WAValidator from 'multicoin-address-validator';

import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { isString } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import copyText from './AccountInstructionForm.copyText';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/button.css');
require('@omniex/onx-common-ui/lib/semantic/css/input.css');
require('@omniex/onx-common-ui/lib/semantic/css/form.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');

const COMPONENT_NAME = 'AccountInstructionForm';

const StyledDiv = styled('div')`
  display: flex !important;
  flex-direction: column !important;
  margin-right: 14px;
  width: 100%;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex: 1;
  font-size: 1em !important;
  margin-bottom: 28px !important;
  margin-top: 14px !important;

  .field {
    border-right-color: 'blue';
    margin-bottom: 0 !important;

    :first-child {
      flex: 3;
      margin-right: 14px !important;
    }

    :nth-child(2) {
      flex: 3;
      margin-right: 14px !important;
    }

    :nth-child(3) {
      margin-right: 14px !important;
    }

    :nth-child(4) {
      margin-right: 14px !important;
    }
  }

  .${COMPONENT_NAME}-errorMessage {
    padding-right: 14px;
  }
`;

const MAX_ADDR_CHARS = 128;
const MAX_DESC_CHARS = 50;
const MAX_XPUB_CHARS = 112;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class AccountInstructionForm extends Component {
  static propTypes = {
    account: shape({
      id: string.isRequired,
      currency: shape({
        id: string.isRequired,
        symbol: string.isRequired
      })
    }),
    instruction: shape({
      id: string.isRequired,
      description: string.isRequired,
      freeForm: string,
      walletAddress: string,
      xpubKey: string,
      xpubParentId: string
    }),
    isFiat: bool,
    isXPub: bool,
    onCancel: func,
    onSave: func,
    onUpdateInstruction: func
  };

  static defaultProps = {
    onCancel: noop,
    onSave: noop,
    onUpdateInstruction: noop
  };

  state = {
    description: get(this.props, 'instruction.description', ''),
    freeForm: get(this.props, 'instruction.freeForm', ''),
    iban: get(this.props, 'instruction.iban', ''),
    walletAddress: get(this.props, 'instruction.walletAddress', ''),
    xpubKey: get(this.props, 'instruction.xpubKey', '')
  };

  render() {
    const symbol = get(this.props, 'account.currency.symbol');
    const xpubPlaceHolder = (symbol === 'ETH') ? copyText.inputPlaceholder_xyzpub : copyText.inputPlaceholder_xypub;

    const valInput = this.props.isFiat
      ? { name: 'freeForm', placeholder: copyText.inputPlaceholder_freeForm, value: this.state.freeForm }
      : this.props.isXPub
      ? { name: "xpubKey", placeholder: xpubPlaceHolder, value: this.state.xpubKey }
      : { name: "walletAddress", placeholder: copyText.inputPlaceholder_walletAddress, value: this.state.walletAddress };

    return (
      <StyledDiv className={COMPONENT_NAME}>
        {this.state.invalidWalletAddress && (
          <Message
            className={cn('errorMessage')}
            attached
            closeOnClick
            content={copyText.invalidWalletAddress}
            icon="warning sign"
            error
          />
        )}
        <StyledForm className={cn('instructionsForm')} autoComplete="off">
          <Form.Field className={cn('fieldAddress')}>
            <Input {...valInput} required onChange={this._handleChangeField}/>
          </Form.Field>
          {this.props.isFiat &&
            <Form.Field>
              <Input
                name="iban"
                placeholder={copyText.inputPlaceholder_iban}
                required
                value={this.state.iban}
                onChange={this._handleChangeField}
              />
            </Form.Field>}
          <Form.Field>
            <Input
              name="description"
              placeholder={copyText.inputPlaceholder_description}
              required
              value={this.state.description}
              onChange={this._handleChangeField}
            />
          </Form.Field>
          <Form.Field>
            <Button
              color="orange"
              disabled={!this._canSave() || this.props.processing}
              fluid
              loading={this.props.processing}
              size="tiny"
              type="submit"
              onClick={this._handleClickSubmit.bind(this)}>
              {copyText.saveButtonLabel}
            </Button>
          </Form.Field>
          <Form.Field>
            <Button
              fluid
              loading={this.props.processing}
              size={'tiny'}
              type="reset"
              onClick={this._handleClickCancel.bind(this)}>
              {copyText.cancelButtonLabel}
            </Button>
          </Form.Field>
        </StyledForm>
      </StyledDiv>
    );
  }

  _canSave() {
    const isValidValue = this.props.isFiat
      ? /^\d+$/.test(this.state.freeForm)
      : this.props.isXPub
      ? verifyStr(this.state.xpubKey, MAX_XPUB_CHARS)
      : verifyStr(this.state.walletAddress, MAX_ADDR_CHARS);

    const isValidDesc = verifyStr(this.state.description, MAX_DESC_CHARS);

    const changeNum = ['freeform', 'iban', 'walletAddress', 'xpubKey'].filter(i => this._hasValueChanged(i)).length;

    return isValidValue && isValidDesc && (changeNum === 1 || (changeNum === 0 && this._hasValueChanged('description')));
  }

  _handleChangeField = (event, input) => {
    const changes = { [input.name]: input.value };

    if (changes.description && changes.description.length > MAX_DESC_CHARS) {
      return;
    }

    this.setState(changes);
  };

  _handleClickCancel = event => {
    event.preventDefault();

    this.props.onCancel(Boolean(this.props.instruction));
  };

  _handleClickSubmit = event => {
    event.preventDefault();

    const { description, freeForm, iban, walletAddress, xpubKey } = this.state;

    let isValidCrypto;

    if (!this.props.isFiat) {
      if (this.props.isXPub) {
        isValidCrypto = verifyStr(xpubKey, MAX_XPUB_CHARS);
      } else {
        try {
          isValidCrypto = WAValidator.validate(
            this.state.walletAddress,
            get(this.props, 'account.currency.symbol')
          );
        } catch (error) {
          isValidCrypto = isString(walletAddress) &&
            verifyStr(walletAddress, MAX_ADDR_CHARS) &&
            !/ /.test(walletAddress);
        }
      }
    }

    if (!this.props.isFiat && !isValidCrypto) {
      this.setState({ invalidWalletAddress: true });
      return;
    }

    const instructionArgs = {
      description,
      ...(freeForm ? { freeForm } : {}),
      ...(iban ? { iban } : {}),
      ...(walletAddress ? { walletAddress } : {}),
      ...(xpubKey ? { xpubKey } : {})
    };

    if(!!this.props.instruction) {
      this.props.onUpdateInstruction({
        instructionId: get(this.props, 'instruction.id'),
        ...instructionArgs
      })
    } else {
      this.props.onSave({
        accountId: get(this.props, 'account.id'),
        ...instructionArgs
      });
    }
  };

  _hasValueChanged = str => {
    return get(this.state, str, '') !== get(this.props, `instruction.${str}`, '');
  };
}

const verifyStr = (str, max) => str.length > 0 && str.length <= max;
