import gql from 'graphql-tag';

export default gql`
  query getPaginatedAccountActivities(
    $accountId: ID
    $orderParameter: String
    $orderDirection: String
    $limit: Int
    $offset: Int
  ) {
    accountActivities: getPaginatedAccountActivities(
      accountId: $accountId
      orderParameter: $orderParameter
      orderDirection: $orderDirection
      limit: $limit
      offset: $offset
    ) {
      accountActivities {
        id
        account {
          id
        }
        cancelStrategy
        canDelete
        createdBy {
          id
          name
        }
        insertType
        instrument {
          id
          displayName
        }
        orderPlacedViaOmniex
        quantity
        timeExecuted
        transactionType
        venue {
          id
          name
        }
      }
      count
    }
  }
`;
