import { useMutation } from 'react-apollo';
import React from 'react';

import { safeExecAsync } from '../utils/FunctionUtils';
import SettingsPage from '../ui/pages/SettingsPage';
import getAuthenticatedUser from '../apollo/graphql/getAuthenticatedUser';
import updateAuthenticatedUserPassword from '../apollo/graphql/updateAuthenticatedUserPassword';
import updateNotificationSettings from '../apollo/graphql/updateNotificationSettings';
import updateTradeSettings from '../apollo/graphql/updateTradeSettings';

const SettingsPageContainer = ({ apiClient, user }) => {
  const useApiMutation = (m, o) => useMutation(m, { client: apiClient, ...o })

  const refetchQueries = [{ query: getAuthenticatedUser }]
  const [updatePassword, mUpdatePassword] = useApiMutation(updateAuthenticatedUserPassword)
  const [updateNotifications, mUpdateNotifications] = useApiMutation(updateNotificationSettings)
  const [updateTrade, mUpdateTrade] = useApiMutation(updateTradeSettings)

  return (
    <SettingsPage updateAuthenticatedUserPassword={variables => safeExecAsync(updatePassword, { variables })}
      updateAuthenticatedUserPasswordMutationError={mUpdatePassword?.error}
      updateAuthenticatedUserPasswordMutationProcessing={mUpdatePassword?.loading}
      updateAuthenticatedUserPasswordMutationResult={mUpdatePassword?.data}
      updateNotificationSettings={variables => safeExecAsync(updateNotifications, { variables, refetchQueries })}
      updateNotificationSettingsMutationError={mUpdateNotifications?.error}
      updateNotificationSettingsMutationProcessing={mUpdateNotifications?.loading}
      updateNotificationSettingsMutationResult={mUpdateNotifications?.data}
      updateTradeSettings={input => safeExecAsync(updateTrade, { variables: { input } })}
      updateTradeSettingsMutationError={mUpdateTrade?.error}
      updateTradeSettingsMutationProcessing={mUpdateTrade?.loading}
      updateTradeSettingsMutationResult={mUpdateTrade?.data}
      user={user}
    />
  )
}

export default SettingsPageContainer
