import UserRoleType from '@omniex/onx-poms-entity-helpers/lib/enums/UserRoleType';

// NOTE: Following permissions already implemented
export const VIEW_SETTINGS_PAGE = 'settingsPage:view';
export const UPDATE_NOTIFICATION_SETTINGS = 'userNotificationSettings:update';
export const UPDATE_USER_PASSWORD = 'userPassword:update';

export const VIEW_DASHBOARD_PAGE = 'canViewDashboard';

export const VIEW_PORTFOLIO_PAGE = 'portfolioPage:view';
export const RECON_BALANCES = 'reconBalances:initiate';
export const DOWNLOAD_PORTFOLIO_CSV = 'portfolioPageCSV:download';
export const VIEW_MANUAL_ENTRY_FORM = 'manualEntryForm:view'; // NOTE: If a user can view the manual entry form, assume they can create manual activities and positions
export const DELETE_MANUAL_ACTIVITY = 'manualActivity:delete';
export const UPDATE_MANUAL_POSITION = 'manualPosition:update';

export const VIEW_TRADE_PAGE = 'tradePage:view';
export const VIEW_TRADE_PAGE_EXECUTION_SECTION = 'tradePageExecutionSection:view';
export const DOWNLOAD_TRADE_CSV = 'tradePageCSV:download';
export const CANCEL_ORDER = 'order:cancel';
export const RECON_ORDERS = 'reconOrders:initiate';

export const VIEW_SETTLEMENT_PAGE = 'settlementPage:view';
export const DOWNLOAD_SETTLEMENT_CSV = 'settlementCSV:download';
export const INITIATE_SETTLEMENT = 'settlement:initiate';
export const AFFIRM_SETTLEMENT = 'settlement:affirm';
export const CONFIRM_SETTLEMENT = 'settlement:confirm';

export const VIEW_ADMIN_PAGE = 'adminPage:view';
export const VIEW_USER_MANAGEMENT_PAGE = 'userManagementPage:view';
export const VIEW_CUSTODY_PAGE = 'custodyPage:view';
export const VIEW_CUSTODY_PAGE_CRYPTO_SECTION = 'custodyPageCryptoSection:view';
export const VIEW_CUSTODY_PAGE_FIAT_SECTION = 'custodyPageFiatSection:view';
export const VIEW_REQUESTS_PAGE = 'requestsPage:view';
export const VIEW_QUOTE_PAGE = 'quotePage:view';
export const VIEW_REPORT_PAGE = 'reportPage:view';

export const READ_ONLY = 'readOnly'

// NOTE: Following permissions need to be implemented
// Admin page
export const CREATE_INTEGRATION = 'integration:create';
export const UPDATE_INTEGRATION = 'integration:update';

// User management page
export const UPDATE_USER_ROLE = 'userRole:update';
export const UPDATE_USER_STATUS = 'userStatus:update';

// Crypto page
export const CREATE_WALLET = 'wallet:create';
export const UPDATE_WALLET = 'wallet:update';
export const CREATE_ADDRESS = 'address:create';
export const UPDATE_ADDRESS = 'address:update';
export const DELETE_ADDRESS = 'address:delete';

// Quote page
export const CLAIM_QUOTE = 'quote:claim';

// All users with access to the web app share the following permissions
const COMMON_PERMISSIONS = [
  VIEW_SETTINGS_PAGE,
  UPDATE_NOTIFICATION_SETTINGS,
  UPDATE_USER_PASSWORD,
];

// NOTE: I didn't define permissions for the ANALYST type role because it's not currently used.
// I didn't define permissions for the SYSTEM_ADMIN type either, since it represents the system.

const permissions = {
  [UserRoleType.ADMIN]: [
    ...COMMON_PERMISSIONS,
    VIEW_ADMIN_PAGE,
    CREATE_INTEGRATION,
    UPDATE_INTEGRATION,
    VIEW_USER_MANAGEMENT_PAGE,
    UPDATE_USER_ROLE,
    UPDATE_USER_STATUS,
  ],
  [UserRoleType.CRYPTO_ACCOUNT_CONTROLLER]: [
    ...COMMON_PERMISSIONS,
    VIEW_CUSTODY_PAGE,
    VIEW_CUSTODY_PAGE_CRYPTO_SECTION,
    CREATE_WALLET,
    UPDATE_WALLET,
    CREATE_ADDRESS,
    UPDATE_ADDRESS,
    DELETE_ADDRESS,
  ],
  [UserRoleType.FIAT_ACCOUNT_CONTROLLER]: [
    ...COMMON_PERMISSIONS,
    VIEW_CUSTODY_PAGE,
    VIEW_CUSTODY_PAGE_FIAT_SECTION,
    CREATE_ADDRESS,
    UPDATE_ADDRESS,
    DELETE_ADDRESS,
  ],
  [UserRoleType.PORTFOLIO_AUDITOR]: [
    ...COMMON_PERMISSIONS,
    VIEW_DASHBOARD_PAGE,
    VIEW_PORTFOLIO_PAGE,
    RECON_BALANCES,
    DOWNLOAD_PORTFOLIO_CSV,
    VIEW_TRADE_PAGE,
    DOWNLOAD_TRADE_CSV,
    VIEW_SETTLEMENT_PAGE,
    DOWNLOAD_SETTLEMENT_CSV,
  ],
  [UserRoleType.PORTFOLIO_MANAGER]: [
    ...COMMON_PERMISSIONS,
    VIEW_DASHBOARD_PAGE,
    VIEW_PORTFOLIO_PAGE,
    VIEW_TRADE_PAGE,
    VIEW_TRADE_PAGE_EXECUTION_SECTION,
    VIEW_SETTLEMENT_PAGE,
    VIEW_REPORT_PAGE,
    DOWNLOAD_PORTFOLIO_CSV,
    DOWNLOAD_TRADE_CSV,
    DOWNLOAD_SETTLEMENT_CSV,
    VIEW_MANUAL_ENTRY_FORM,
    DELETE_MANUAL_ACTIVITY,
    UPDATE_MANUAL_POSITION,
    CANCEL_ORDER,
    RECON_BALANCES,
  ],
  [UserRoleType.SETTLEMENT_OFFICER]: [
    ...COMMON_PERMISSIONS,
    VIEW_DASHBOARD_PAGE,
    VIEW_SETTLEMENT_PAGE,
    DOWNLOAD_SETTLEMENT_CSV,
    INITIATE_SETTLEMENT,
    AFFIRM_SETTLEMENT,
    CONFIRM_SETTLEMENT,
  ],
  [UserRoleType.SYSTEM_INTEGRATOR]: [
    ...COMMON_PERMISSIONS,
    VIEW_REQUESTS_PAGE,
  ],
  [UserRoleType.SYSTEM_OPERATOR]: [
    DOWNLOAD_PORTFOLIO_CSV,
    DOWNLOAD_SETTLEMENT_CSV,
    DOWNLOAD_TRADE_CSV,
    READ_ONLY,
    RECON_BALANCES,
    VIEW_ADMIN_PAGE,
    VIEW_CUSTODY_PAGE,
    VIEW_CUSTODY_PAGE_CRYPTO_SECTION,
    VIEW_CUSTODY_PAGE_FIAT_SECTION,
    VIEW_DASHBOARD_PAGE,
    VIEW_MANUAL_ENTRY_FORM,
    VIEW_PORTFOLIO_PAGE,
    VIEW_QUOTE_PAGE,
    VIEW_REPORT_PAGE,
    VIEW_SETTINGS_PAGE,
    VIEW_SETTLEMENT_PAGE,
    VIEW_TRADE_PAGE,
    VIEW_TRADE_PAGE_EXECUTION_SECTION,
    VIEW_USER_MANAGEMENT_PAGE
  ],
  [`BUY_SIDE_${UserRoleType.TRADER}`]: [
    ...COMMON_PERMISSIONS,
    VIEW_DASHBOARD_PAGE,
    VIEW_PORTFOLIO_PAGE,
    VIEW_TRADE_PAGE,
    VIEW_TRADE_PAGE_EXECUTION_SECTION,
    VIEW_REPORT_PAGE,
    DOWNLOAD_PORTFOLIO_CSV,
    DOWNLOAD_TRADE_CSV,
    VIEW_MANUAL_ENTRY_FORM,
    DELETE_MANUAL_ACTIVITY,
    UPDATE_MANUAL_POSITION,
    CANCEL_ORDER,
    RECON_BALANCES,
  ],
  [`SELL_SIDE_${UserRoleType.TRADER}`]: [
    ...COMMON_PERMISSIONS,
    VIEW_QUOTE_PAGE,
    CLAIM_QUOTE,
  ],
};

export default permissions;
