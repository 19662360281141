import { arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { Button, Icon, Popup } from 'semantic-ui-react';
import React, { Fragment, PureComponent } from 'react';
import styled from 'react-emotion';

import { format as formatNumber } from '@omniex/onx-common-js/lib/utils/NumberUtils';
import { format as formatDate } from '@omniex/onx-common-js/lib/utils/DateTimeUtils';
import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { isNil, isString } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import AccountActivityCancelStrategy from '@omniex/onx-poms-entity-helpers/lib/enums/AccountActivityCancelStrategy';
import AccountActivityTransactionType from '@omniex/onx-poms-entity-helpers/lib/enums/AccountActivityTransactionType';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './AccountActivityTable.copyText';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import UpdatePositionForm from './UpdatePositionForm';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/button.css');
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');
require('@omniex/onx-common-ui/lib/semantic/css/table.css');
require('@omniex/onx-common-ui/lib/semantic/css/popup.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const SYSTEM_CUSTODIAN_ID = '1';

const COMPONENT_NAME = 'AccountActivityTable';

const StyledTable = styled(Table)`
  font-size: 0.85em;
  text-align: center;

  .${COMPONENT_NAME}-activityTableWrapper {
    width: 100%;
  }

  .${COMPONENT_NAME}-warningMessage {
    margin-top: 5px !important;
  }

  .${COMPONENT_NAME}-iconTrash {
    color: ${colors.blue} !important;
    margin-left: 2em !important;
  }

  .${COMPONENT_NAME}-iconTrash: hover {
    cursor: pointer;
  }

  .ReactVirtualized__Table__headerRow {
    align-items: center;
    border-bottom: 3px solid ${colors.internalBorderColor};
    display: flex;
    font-weight: 700;
    text-transform: none;
  }

  .ReactVirtualized__Table__row {
    align-items: center;
    border-bottom: 1px solid ${colors.internalBorderColor};
    display: flex;

    :last-child {
      border-bottom: none;
    }
  }

  .ReactVirtualized__Table__headerColumn {
    margin-right: 2px;
  }

  .ReactVirtualized__Table__rowColumn {
    margin-right: 2px;
  }

  .ReactVirtualized__Table__Grid {
    margin-right: 2px;
    outline: none !important;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

const popupStyle = {
  fontSize: '0.9em',
  padding: '8px 16px'
};

export default class AccountActivityTable extends PureComponent {
  static propTypes = {
    account: shape({
      id: string.isRequired,
      balanceAtCustodianAsReportedTotal: number,
      currency: shape({
        id: string.isRequired
      }).isRequired,
      hasCustomAsset: bool,
      temporaryCalculatedBalance: number
    }),
    activities: arrayOf(
      shape({
        id: string.isRequired,
        account: shape({
          name: string.isRequired
        }).isRequired,
        instrument: shape({
          displayName: string.isRequired,
          id: string.isRequired,
        }),
        quantity: number.isRequired,
        timeExecuted: string,
        transactionType: string.isRequired,
        venue: shape({
          id: string.isRequired,
          name: string.isRequired
        })
      })
    ),
    activitiesLoading: bool,
    currentPrice: number,
    homeCurrency: shape({
      id: string.isRequired,
      symbol: string.isRequired
    }),
    isFiat: bool,
    loading: bool,
    updatePositionMutationError: object,
    updatePositionMutationProcessing: bool,
    updatePositionMutationResult: object,
    user: shape({
      org: shape({
        type: string.isRequired
      })
    }),
    onDelete: func,
    onSave: func
  };

  static defaultProps = {
    onDelete: noop,
    onSave: noop
  };

  state = {};

  render() {
    const activitiesData = get(this.props, 'activities', []).map(activity =>
      this._getRowData(activity)
    );

    if (activitiesData.length === 0 && !this.props.activitiesLoading) {
      return this._renderWarningMessage();
    }

    const heightForPositionsForm =
      this.props.account.hasCustomAsset === true ? 90 : 0;

    return (
      <div
        className={cn('activityTableWrapper')}
        style={{
          height:
            activitiesData.length >= 2
              ? activitiesData.length * 40 + heightForPositionsForm
              : 150
        }}>
        {this.props.account.hasCustomAsset === true && this.props.user.canUpdateManualPosition && (
          <UpdatePositionForm
            account={this.props.account}
            currentPrice={this.props.currentPrice}
            homeCurrency={this.props.homeCurrency}
            loading={this.props.loading}
            updatePositionMutationError={this.props.updatePositionMutationError}
            updatePositionMutationProcessing={
              this.props.updatePositionMutationProcessing
            }
            updatePositionMutationResult={
              this.props.updatePositionMutationResult
            }
            onSave={this.props.onSave}
            size="small"
          />
        )}

        <AutoSizer>
          {({ height, width }) => {
            width = width < 1000 ? 1000 : width;
            return (
              <StyledTable
                headerHeight={20}
                height={height}
                rowCount={activitiesData.length}
                rowGetter={({ index }) => activitiesData[index]}
                rowHeight={30}
                width={width}>
                <Column
                  dataKey="source"
                  label={copyText.tableHeaderSource}
                  width={width / (1 / 0.19)}
                />
                <Column
                  dataKey="timeExecuted"
                  label={copyText.tableHeaderDate}
                  width={width / (1 / 0.19)}
                />
                <Column
                  dataKey="accountName"
                  label={copyText.tableHeaderAccountName}
                  width={width / (1 / 0.19)}
                />
                <Column
                  dataKey="transactionType"
                  cellRenderer={({ cellData }) => <div>{cellData}</div>}
                  label={copyText.tableHeaderActualTransaction}
                  width={width / (1 / 0.19)}
                />
                <Column
                  dataKey="quantity"
                  cellRenderer={({ cellData }) => (
                    <div>
                      {cellData.multiplier > 0 ? (
                        <Icon color="green" name="plus" />
                      ) : (
                        <Icon color="red" name="minus" />
                      )}
                      {cellData.amount}
                    </div>
                  )}
                  label={copyText.tableHeaderQuantity}
                  width={width / (1 / 0.19)}
                />
                <Column
                  dataKey="deleteIcon"
                  cellRenderer={({ cellData }) => <div>{cellData}</div>}
                  label={''}
                  width={width / 20}
                />
              </StyledTable>
            );
          }}
        </AutoSizer>
      </div>
    );
  }

  //TODO: MAKE SELECTOR
  _getRowData(activity) {
    return {
      timeExecuted: this._getDate(activity),
      source: `${this._getSource(activity)}`,
      accountName: getAccountName(activity.account),
      transactionType: `${
        activity.transactionType === AccountActivityTransactionType.JOURNAL
          ? copyText.journalLabel
          : activity.transactionType
      } ${this._getActualInstrument(activity)}`,
      venueName: !isNil(activity.venue)
        ? activity.venue.name
        : copyText.notApplicable,
      quantity: this._getQuantity(activity),
      deleteIcon: (
        <div>
          {activity.canDelete === true && this.props.user.canDeleteManualActivity ? (
            <Popup
              content={
                <Fragment>
                  <p style={{ marginTop: '10px' }}>
                    {copyText.popupDeleteWarningMessage}
                  </p>
                  <Button
                    color="orange"
                    fluid
                    size="small"
                    content="Proceed"
                    onClick={this._handleClickDelete.bind(this, activity.id)}
                  />
                </Fragment>
              }
              header={copyText.popupDeleteWarningHeader}
              hideOnScroll
              on="click"
              onClose={this._handlePopupClose.bind(this, activity.id)}
              onOpen={this._handlePopupOpen.bind(this, activity.id)}
              open={this.state.popupRowId === activity.id}
              position="left center"
              size="tiny"
              style={popupStyle}
              trigger={<Icon name="trash" className={cn('iconTrash')} />}
            />
          ) : null}
        </div>
      )
    };
  }

  _getActualInstrument(activity) {
    return !isNil(activity.instrument) ? `(${activity.instrument.displayName})` : '';
  }

  _getDate(activity) {
    const timestamp =
      formatDate(activity.timeExecuted);

    const activityDate = new Date(timestamp);
    const activityMonth = activityDate.getMonth();
    const activityDay = activityDate.getDate();

    const currentDate = new Date(Date.now());
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    const isSameCalendarDay =
      activityMonth === currentMonth && activityDay === currentDay;

    const dateFormat = isSameCalendarDay ? 'LTS' : 'LLL';

    return activity.timeExecuted
      ? formatDate(activity.timeExecuted, null, dateFormat)
      : copyText.missingDateLabel;
  }

  _getQuantity(activity) {
    const multiplier = activity.quantity > 0 ? 1 : -1;

    const amount = formatNumber(
      Math.abs(activity.quantity),
      this.props.isFiat ? '0.00' : '0.00000000'
    );

    return { amount, multiplier };
  }

  _getSource(activity) {
    if (
      activity.createdBy &&
      activity.createdBy.id !== 'null' &&
      activity.createdBy.id !== SYSTEM_CUSTODIAN_ID
    )
      return activity.createdBy.name;

    if (
      activity.cancelStrategy ===
      AccountActivityCancelStrategy.ON_RECONCILIATION
    )
      return 'Adjustment (Temporary)';

    return activity.orderPlacedViaOmniex ? 'System' : 'Imported';
  }

  _renderWarningMessage() {
    return (
      <div className={COMPONENT_NAME}>
        <Message
          className={cn('warningMessage')}
          content={copyText.warningMessage}
          warning
        />
      </div>
    );
  }

  _handlePopupOpen(id) {
    this.setState({
      popupRowId: id
    });
  }

  _handlePopupClose() {
    this.setState({
      popupRowId: undefined
    });
  }

  _handleClickDelete = id => {
    this.props.onDelete(id);
  };
}

const getAccountName = function(account) {
  if (!account || !isString(account.name)) return '';

  return get(account, 'custodian.id') === SYSTEM_CUSTODIAN_ID
    ? account.name.replace('Default', copyText.accountNamePrefixUnsettled)
    : account.name;
};
