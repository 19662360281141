/* eslint-disable no-sequences */
/* stylelint-disable */
import { arrayOf, func, number, object, shape, string } from 'prop-types';
import { createSelector } from 'reselect';
import { Icon, Loader as SemanticLoader, Segment } from 'semantic-ui-react';
import React, { Component, createRef } from 'react';
import styled from 'react-emotion';
import { changed } from '../../utils/PerformanceUtils';
import { get, has } from '@omniex/poms-core/lib/utils/ObjectUtils';
import { hasMarketDataFor } from '../../utils/MarketDataUtils';
import { isEmpty, isError, isNil, isEqual } from '@omniex/poms-core/lib/utils/LangUtils';
import { unique } from '@omniex/poms-core/lib/utils/ArrayUtils';
import { keyBy } from '@omniex/poms-core/lib/utils/CollectionUtils';
import { noop } from '@omniex/poms-core/lib/utils/FunctionUtils';
import { arrayOfShape, queryShape } from '../../utils/PropTypeUtils';
import AlgoExecutionForm from '../components/AlgoExecutionForm';
import AlgorithmStrategyDescriptionComponent from '../components/AlgorithmStrategyDescriptionComponent';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './TradePageExecutionSection.copyText';
import DmaExecutionForm from '../components/DmaExecutionForm';
import filterMarketData from '../../selectors/filterMarketDataEntriesByInstrumentsAndVenues';
import getInstrumentFromNameVenue from '../../selectors/getInstrumentFromNameVenue';
import groupMarketData from '../../selectors/getMarketDataEntriesGroupedByType';
import MarketDataEntryType from '@omniex/poms-core/lib/enums/MarketDataEntryType';
import Menu from '@omniex/onx-common-ui/lib/semantic/react/Menu';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import OrderAlgorithmStrategy from '@omniex/poms-core/lib/enums/OrderAlgorithmStrategy';
import OrderBookTable from '../components/OrderBookTable';
import OrderFeature from '@omniex/poms-core/lib/enums/OrderFeature';
import PairPositionsComponent from '../components/PairPositionsComponent';
import VenueType from '@omniex/poms-core/lib/enums/VenueType';
import OTCContainer from '../components/OTC/OTCContainer';
import { getFilteredVenues, getKeyedInstrumentsFromVenues, getKeyedFilteredVenues, getSortedInstrumentsFromVenues } from '../../selectors/blotterSelectors';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/header.css');
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/loader.css');
require('@omniex/onx-common-ui/lib/semantic/css/segment.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');

const ERR_MARKET_DATA = 'errorMessageNoMarketDataAvailable';
const ERR_ALGO_ORDER = 'errorMessagePlaceOrderForAlgorithmicExecutionFailed';
const ERR_DMA_ORDER = 'errorMessagePlaceOrderForSpecificExchangeFailed';
const ERR_ALGO_ACCOUNTS = 'errorMessagePlaceOrderForAlgorithmicExecutionFailed__outgoingAccounts';

const ERR_NO_ACCOUNTS = "NO ACCOUNTS" // returned by the taker api server

const TAB_NAME_ALGO = copyText.menuItemLabelAlgo;
const TAB_NAME_DMA = copyText.menuItemLabelDma;
const TAB_NAME_OTC = copyText.menuItemLabelOtc;
const DEFAULT_TAB_NAME = TAB_NAME_ALGO;
const EXECUTION_TABS = [TAB_NAME_ALGO, TAB_NAME_DMA, TAB_NAME_OTC];

const INPUT_CURRENCY_ID = 'currencyId';
const INPUT_INSTRUMENT_DISPLAY_NAME = 'instrumentDisplayName';
const INPUT_VENUE_ID = 'venueId';
const INPUT_VENUE_IDS = 'venueIds';
const INPUT_VISIBLE_TAB = 'visibleTab';

const SOR_FEES_KEY = 'includeSorFees';
const REF_RATE_KEY = 'termCurrencyReferenceRate';

const SYSTEM_CUSTODIAN_ID = '1';

const COMPONENT_NAME = 'TradePageExecutionSection';

const StyledSection = styled('section')`
  .${COMPONENT_NAME}-sectionTitle {
    width: 121.5px;
  }

  .${COMPONENT_NAME}-menuItem {
    justify-content: center !important;
    width: 93px !important;

    &.active {
      border-top-color: ${colors.subTabActiveLine};
    }
  }

  .${COMPONENT_NAME}-segment {
    min-height: ${props => props.height}px;
    padding: 0 !important;
  }

  .${COMPONENT_NAME}-segmentLayout {
    display: flex;
  }

  .${COMPONENT_NAME}-executionFormWrapper {
    border-right: 1px solid ${colors.solidBorderColor};
    max-width: 308px;
    min-height: 440px;
    padding: 14px;
  }

  .${COMPONENT_NAME}-executionContentWrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: auto;
  }

  .${COMPONENT_NAME}-pairPositionsWrapper {
    border-bottom: 1px solid ${colors.solidBorderColor};
    height: auto;
    overflow-x: scroll;
    padding: 0;
  }

  .${COMPONENT_NAME}-pairPositionsPlaceholderMessage {
    font-style: italic;
    padding: 30px;
    text-align: center;
  }

  .${COMPONENT_NAME}-executionContent {
    padding-bottom: 14px;
    display: flex;
    flex: auto;
    height: 500px;
  }

  .${COMPONENT_NAME}-executionContent
    > div:nth-child(1)
    > table
    > thead
    > tr
    > th {
    background-color: white;
    height: 41px;
    position: sticky !important;
    top: 0px;
  }

  .${COMPONENT_NAME}-executionContent
    > div:nth-child(2)
    > table
    > thead
    > tr
    > th {
    background-color: white;
    height: 41px;
    position: sticky !important;
    top: 0px;
  }

  .${COMPONENT_NAME}-orderBookTableWrapper {
    flex: 1;
    padding: 0 14px 14px 14px;
    overflow: auto;

    :first-of-type {
      border-right: 1px solid ${colors.solidBorderColor};
    }
  }

  .${COMPONENT_NAME}-descriptionWrapper {
    padding: 14px;
  }

  .${COMPONENT_NAME}-successMessage {
    code {
      font-size: 0.9em;
    }
  }

  .${COMPONENT_NAME}-errorMessage + .${COMPONENT_NAME}-segment {
    border-top-color: ${colors.errorBorderColor};
  }

  .${COMPONENT_NAME}-successMessage + .${COMPONENT_NAME}-segment {
    border-top-color: ${colors.successBorderColor};
  }

  .${COMPONENT_NAME}-warningMessage {
    margin: 14px !important;
    max-height: 3.5em !important;
    width: 100% !important;
  }

  .${COMPONENT_NAME}-loaderWrapper {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
  }

  .${COMPONENT_NAME}-iconWrapper {
    align-items: center;
    display: flex !important;
    flex: 1 !important;
    justify-content: space-around;
    min-height: 371px;
  }

  .${COMPONENT_NAME}-icon {
    color: #e8e8e8 !important;
  }

  .${COMPONENT_NAME}-gridWrapper {
    height: ${props => props.height}px;
    /* max-width: 1650px !important; */
    overflow-x: initial;
    padding: 5px 5px 10px 5px;
    transition: height 0.5s;
    width: 100%;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

const MAX_WAIT_TIME = 5000;

const orderShape = {
  order: shape({
    id: string.isRequired,
    fixClOrdID: string.isRequired,
  })
};

const Loader = ({ inline = false }) => (loader => inline ? <div className={cn('loaderWrapper')}>{loader}</div> : loader)(
  <SemanticLoader active inline={inline} className={cn('loader')} content={copyText.loadingMessage} size="medium" />
);

const Header = _ => <Menu.Item className={cn('sectionTitle')} content={copyText.sectionTitle} header icon="exchange" />;

const Notification = ({ content, error = false, success = false }) => (
  <Message
    className={cn(error ? 'errorMessage' : 'successMessage')}
    attached
    closeOnClick
    content={content}
    icon={error ? 'warning sign' : 'check circle outline'}
    error={error}
    success={success}
  />
);

const Tab = ({ tab, visibleTab, onClick, onMouseOver, disabled = false }) => (
  <Menu.Tab
    className={cn('menuItem')}
    active={visibleTab === tab}
    content={tab}
    onClick={onClick}
    disabled={disabled}
    onMouseOver={onMouseOver}
  />
);

const Tabs = ({ visibleTab, changeTab }) => (
  <>
    <Tab tab={TAB_NAME_ALGO} visibleTab={visibleTab} onClick={changeTab} />
    <Tab tab={TAB_NAME_DMA} visibleTab={visibleTab} onClick={changeTab} />
    <Tab tab={TAB_NAME_OTC} visibleTab={visibleTab} onClick={changeTab} />
  </>
);


export default class TradePageExecutionSection extends Component {
  static propTypes = {
    accountsWithBalancesQuery: queryShape({
      accounts: arrayOfShape({
        id: string.isRequired,
        balanceAtCustodianAsReportedFree: number,
        custodian: shape({
          id: string.isRequired,
          name: string.isRequired,
        }),
        temporaryCalculatedBalance: number,
      }, true)
    }),
    configureMarketDataFeed: func,
    currenciesQuery: queryShape({
      currencies: arrayOfShape({
        id: string.isRequired,
        symbol: string.isRequired,
      })
    }),
    currentPricesQuery: queryShape({
      currentPrices: arrayOfShape({ price: number.isRequired })
    }),
    fetchAccounts: func,
    fetchCurrentPrices: func,
    integrationsQuery: queryShape({
      integrations: arrayOfShape({
        id: string.isRequired,
        settings: object,
        type: string.isRequired,
      })
    }),
    marketDataFeedSubscription: queryShape({
      marketDataEntries: arrayOfShape({
        id: string.isRequired,
        fixMDStreamID: string.isRequired,
        price: number.isRequired,
        type: string.isRequired,
        venueSymbol: string.isRequired,
      })
    }),
    orgQuery: queryShape({
      org: shape({
        relationships: arrayOfShape({
          instumentConfigurations: arrayOfShape({
            instrument: shape({ id: string }),
            minSize: number,
          })
        })
      })
    }),
    placeOrderForAlgorithmicExecution: func,
    placeOrderForAlgorithmicExecutionMutation: queryShape(orderShape),
    placeOrderForSpecificExchange: func,
    placeOrderForSpecificExchangeMutation: queryShape(orderShape),
    portfolioQuery: queryShape(object, false),
    recentInputHistoryQuery: queryShape({
      inputHistory: shape({
        TradePageExecutionSection_algo_instrumentDisplayName: arrayOf(string),
        TradePageExecutionSection_algo_venueIds: arrayOf(string),
        TradePageExecutionSection_dma_instrumentDisplayName: arrayOf(string),
        TradePageExecutionSection_dma_venueId: arrayOf(string),
        TradePageExecutionSection_termCurrencyReferenceRate: arrayOf(number),
        TradePageExecutionSection_visibleTab: arrayOf(string),
      })
    }),
    refreshDataNonce: string,
    requestedFeeds: shape({
      ALGO: arrayOf(string),
      DMA: arrayOf(string),
      OTC: arrayOf(string),
    }).isRequired,
    updateRecentInputHistory: func,
    user: object.isRequired,
  }

  static defaultProps = {
    configureMarketDataFeed: noop,
    fetchCurrentPrices: noop,
    placeOrderForAlgorithmicExecution: noop,
    placeOrderForSpecificExchange: noop,
    updateRecentInputHistory: noop,
  }

  state = {
    algo_instrumentDisplayName: undefined,
    algo_venueIds: [],
    dma_instrumentDisplayName: undefined,
    dma_venueId: undefined,
    sectionHeight: 390,
    visibleTab: DEFAULT_TAB_NAME,
    waitingForMarketDataEntries_algo: false,
    waitingForMarketDataEntries_dma: false,
  }
  _timers = {}

  constructor(props) {
    super(props);
    this._otcButtonContainerRef = createRef();
  }

  shouldComponentUpdate(newProps, newState) {
    return changed(this.props, newProps) || changed(this.state, newState);
  }

  render() {
    const {
      algo_instrumentDisplayName,
      algo_venueIds,
      dma_instrumentDisplayName,
      dma_venueId,
      errorMessageKey,
      sectionHeight,
      visibleTab,
    } = this.state;

    let { accounts, currencies, instruments, keyedInstruments, keyedVenues, venues } = this._getPopulatedQueryData(visibleTab)

    const marketData = get(this.props, 'marketDataFeedSubscription.data.marketDataEntries', []);

    const loading = this._isLoading();

    const venueIds = {
      [TAB_NAME_ALGO]: algo_venueIds,
      [TAB_NAME_DMA]: [dma_venueId],
      [TAB_NAME_OTC]: [],
    }[visibleTab];

    accounts = _filterAccountsForVisibleTab(accounts, venueIds, visibleTab);

    const positionsKeyedByCurrencyId = _getPositions(accounts);

    const instrumentDisplayName = {
      [TAB_NAME_ALGO]: algo_instrumentDisplayName,
      [TAB_NAME_DMA]: dma_instrumentDisplayName,
      [TAB_NAME_OTC]: '',
    }[visibleTab];

    const algoVenuesInclude = (algo_venueIds || []).reduce((map, id) => (map[id] = true, map), {});
    const selectedVenues = ({
      [TAB_NAME_ALGO]: () => venues.filter(v => algoVenuesInclude[v.id]),
      [TAB_NAME_DMA]: () => [keyedVenues[dma_venueId]].filter(_ => _),
      [TAB_NAME_OTC]: () => [],
    }[visibleTab] || (_ => _))();

    const modelVenue = selectedVenues?.[0] ?? {} // Only 1 unique instrument after venue selection
    const instrument = modelVenue.instrumentConfigurations?.find(vic => vic?.instrument?.displayName === instrumentDisplayName)?.instrument ?? {};

    const entries = filterMarketData(marketData, [instrument], selectedVenues);
    const entriesGroupedByType = groupMarketData(entries);

    const getQuantity = c => get(positionsKeyedByCurrencyId, `${get(instrument, `${c}.id`)}.quantity`);

    const basePositionQuantity = getQuantity('baseAsset');
    const termPositionQuantity = getQuantity('termAsset');
    const marginPositionQuantity = getQuantity('marginAsset');

    const errorMessage = (() => {
      const message = this.props.placeOrderForAlgorithmicExecutionMutation?.error?.message ?? '';
      const parsedMsg = message.slice(message.indexOf('*') + 1, message.lastIndexOf('*'));
      return (copyText[errorMessageKey] || '').replace('%currencySymbol%', parsedMsg);
    })();

    return (
      <StyledSection
        className={COMPONENT_NAME}
        height={sectionHeight}>
        <Menu attached="top" style={{ height: 52 }}>
          <Header />
          {(!loading || errorMessageKey) && <Tabs visibleTab={visibleTab} changeTab={this._changeTab} />}
          {visibleTab === TAB_NAME_OTC && <Menu.Item position="right" ref={this._otcButtonContainerRef} />}
        </Menu>
        {errorMessageKey && <Notification error content={errorMessage} />}
        <Segment attached="bottom" className={cn('segment')}>
          {loading && !errorMessageKey ? (
            <Loader />
          ) : visibleTab === TAB_NAME_OTC ? (
            <OTCContainer
              apiClient={this.props.apiClient}
              buttonContainerRef={this._otcButtonContainerRef}
              fixSenderCompID={this.props.orgQuery.data?.org?.fixSenderCompID ?? ''}
              instruments={instruments}
              keyedInstruments={keyedInstruments}
              keyedVenues={keyedVenues}
              portfolioId={this.props.portfolioId}
              refreshDataNonce={this.props.refreshDataNonce}
              setInspectedOrder={this.props.setInspectedOrder}
              takerApiClient={this.props.takerApiClient}
              user={this.props.user}
              venues={venues}
            />
          ) : (
            <div className={cn('segmentLayout')}>
              <div className={cn('executionFormWrapper')}>
                {this._renderExecutionForm({
                  basePositionQuantity,
                  currencies,
                  entriesGroupedByType,
                  instruments,
                  marginPositionQuantity,
                  termPositionQuantity,
                  venues
                })}
              </div>
              <div className={cn('executionContentWrapper')}>
                <div className={cn('pairPositionsWrapper')}>
                  <PairPositionsComponent instrument={instrument} positions={positionsKeyedByCurrencyId} />
                </div>
                <div className={cn('executionContent')}>
                  {this._renderExecutionContent({
                    currencies,
                    entriesGroupedByType,
                    instrument,
                    instruments,
                    venues,
                  })}
                </div>
              </div>
            </div>
          )}
        </Segment>
      </StyledSection>
    );
  }

  _fetchAccounts = (venueOptions) => {
    const previouslyFetchedAccountVenueIds = this.props.accountsWithBalancesQuery?.variables?.venueIds ?? [];
    const previouslyFetchedAccountAssetIds = this.props.accountsWithBalancesQuery?.variables?.assetIds ?? undefined;


    const assets = unique(venueOptions.reduce((assets, venue) => {
      const baseAssetId = venue?.baseAsset?.id;
      const termAssetId = venue?.termAsset?.id;
      const marginAssetId = venue?.marginAsset?.id;

      if (baseAssetId) assets.push(baseAssetId);
      if (termAssetId) assets.push(termAssetId);
      if (marginAssetId) assets.push(marginAssetId);

      return assets;
    }, []));

    const assetIds = assets.length !== 0 ? assets : undefined; // if there are no assets, don't filter by assets
    const venueIds = Array.isArray(venueOptions) ? venueOptions.map(v => v.value) : [];
    const variables = { venueIds, assetIds };

    const isNewVenueIds = !isEqual(previouslyFetchedAccountVenueIds, venueIds);
    const isNewAssetIds = !isEqual(previouslyFetchedAccountAssetIds, assetIds);
    const finishedLoading = !this._isRecentInputHistoryLoading();

    if (finishedLoading && (isNewVenueIds || isNewAssetIds)) this.props.fetchAccounts({ variables });
  }

  _renderExecutionForm = ({ basePositionQuantity, currencies, entriesGroupedByType, instruments, marginPositionQuantity, termPositionQuantity, venues }) => ({
    [TAB_NAME_ALGO]: this._renderExecutionFormForAlgo,
    [TAB_NAME_DMA]: this._renderExecutionFormForDma,
  }[this.state.visibleTab] || (_ => null))({
    basePositionQuantity,
    currencies,
    entriesGroupedByType,
    instruments,
    marginPositionQuantity,
    termPositionQuantity,
    venues,
  });

  _renderExecutionFormForAlgo = ({ basePositionQuantity, entriesGroupedByType, instruments, marginPositionQuantity, termPositionQuantity, venues }) => {
    return (
      <AlgoExecutionForm
        accountsWithBalancesQueryData={get(this.props, 'accountsWithBalancesQuery.data')}
        basePositionQuantity={basePositionQuantity || 0}
        currentPrices={_keyPricesByBaseId(get(this.props, 'currentPricesQuery.data', {}))}
        entriesGroupedByType={entriesGroupedByType}
        fetchAccounts={this._fetchAccounts}
        includeSorFees={this.state.includeSorFees}
        instruments={instruments}
        marginPositionQuantity={marginPositionQuantity || 0}
        org={this.props.orgQuery?.data?.org}
        processing={get(this.props, 'placeOrderForAlgorithmicExecutionMutation.loading')}
        recentInstrumentDisplayNames={_inputHistoryFor('algo_instrumentDisplayName', [], this.props)}
        selectedInstrumentDisplayName={this.state.algo_instrumentDisplayName}
        selectedMarketDataEntry={this.state.orderBookEntry}
        selectedVenueIds={this.state.algo_venueIds}
        showSuccessButton={this.state.algoOrderPlaced}
        termCurrencyReferenceRate={get(this.props, 'currentPricesQuery.data.currentPrices[0].price')}
        termPositionQuantity={termPositionQuantity || 0}
        venues={venues}
        onChange={this._handleChangeExecutionForm}
        onSubmit={this._handleSubmitAlgoExecutionForm}
      />
    );
  }

  _renderExecutionFormForDma = ({ basePositionQuantity, entriesGroupedByType, instruments, marginPositionQuantity, termPositionQuantity, venues }) => {
    return (
      <DmaExecutionForm
        accountsWithBalancesQueryData={get(this.props, 'accountsWithBalancesQuery.data')}
        basePositionQuantity={basePositionQuantity || 0}
        currentPrices={_keyPricesByBaseId(get(this.props, 'currentPricesQuery.data', {}))}
        entriesGroupedByType={entriesGroupedByType}
        fetchAccounts={this._fetchAccounts}
        instruments={instruments}
        marginPositionQuantity={marginPositionQuantity || 0}
        org={this.props.orgQuery?.data?.org}
        processing={get(this.props, 'placeOrderForSpecificExchangeMutation.loading')}
        recentInstrumentDisplayNames={_inputHistoryFor('dma_instrumentDisplayName', [], this.props)}
        selectedInstrumentDisplayName={this.state.dma_instrumentDisplayName}
        selectedMarketDataEntry={this.state.orderBookEntry}
        selectedVenueId={this.state.dma_venueId}
        showSuccessButton={this.state.dmaOrderPlaced}
        termCurrencyReferenceRate={get(this.props, 'currentPricesQuery.data.currentPrices[0].price')}
        termPositionQuantity={termPositionQuantity || 0}
        venues={venues}
        onChange={this._handleChangeExecutionForm}
        onSubmit={this._handleSubmitDmaExecutionForm}
      />
    );
  }

  _renderExecutionContent = ({ currencies, entriesGroupedByType, instrument, instruments, venues }) => ({
    [TAB_NAME_ALGO]: this._algoContent,
    [TAB_NAME_DMA]: this._dmaContent,
  }[this.state.visibleTab] || (_ => null))({ currencies, entriesGroupedByType, instrument, instruments, venues });

  _algoContent = ({ entriesGroupedByType, instrument, venues }) => this.state.errorMessageKey ? (
    <div className={cn('iconWrapper')}>
      <Icon className={cn('icon')} name="warning sign" size="huge" />
    </div>
  ) : !this.state.algo_instrumentDisplayName || !this.state.algo_venueIds || isEmpty(this.state.algo_venueIds) ? (
    <div className={cn('descriptionWrapper')}>
      <AlgorithmStrategyDescriptionComponent />
    </div>
  ) : this.state.algo_instrumentDisplayName && this.state.waitingForMarketDataEntries_algo ? (
    <Loader inline />
  ) : (
    <>
      <div className={cn('orderBookTableWrapper')}>
        <OrderBookTable
          entries={entriesGroupedByType[MarketDataEntryType.BID]}
          executionType={OrderFeature.ALGO}
          algorithmStrategy={this.state.algorithmStrategy}
          instrument={instrument}
          processing={get(this.props, 'placeOrderForAlgorithmicExecutionMutation.loading')}
          onSelect={this._getHandleEntry()}
          venues={venues}
        />
      </div>
      <div className={cn('orderBookTableWrapper')}>
        <OrderBookTable
          entries={entriesGroupedByType[MarketDataEntryType.OFFER]}
          executionType={OrderFeature.ALGO}
          algorithmStrategy={this.state.algorithmStrategy}
          instrument={instrument}
          processing={get(this.props, 'placeOrderForAlgorithmicExecutionMutation.loading')}
          onSelect={this._getHandleEntry()}
          venues={venues}
        />
      </div>
    </>
  );

  _dmaContent = ({ entriesGroupedByType, instrument, venues }) => this.state.errorMessageKey ? (
    <div className={cn('iconWrapper')}>
      <Icon className={cn('icon')} name="warning sign" size="huge" />
    </div>
  ) : !this.state.dma_instrumentDisplayName || !this.state.dma_venueId ? (
    <Message
      className={cn('warningMessage')}
      content={copyText.warningMessageDma}
      warning
    />
  ) : this.state.dma_instrumentDisplayName && this.state.waitingForMarketDataEntries_dma ? (
    <Loader inline />
  ) : (
    <>
      <div className={cn('orderBookTableWrapper')}>
        <OrderBookTable
          entries={entriesGroupedByType[MarketDataEntryType.BID]}
          executionType={OrderFeature.DMA}
          instrument={instrument}
          processing={get(this.props, 'placeOrderForSpecificExchangeMutation.loading')}
          onSelect={this._handleSelectOrderBookEntry}
          venues={venues}
        />
      </div>
      <div className={cn('orderBookTableWrapper')}>
        <OrderBookTable
          entries={entriesGroupedByType[MarketDataEntryType.OFFER]}
          executionType={OrderFeature.DMA}
          instrument={instrument}
          processing={get(this.props, 'placeOrderForSpecificExchangeMutation.loading')}
          onSelect={this._handleSelectOrderBookEntry}
          venues={venues}
        />
      </div>
    </>
  );


  // TODO: This is an antipattern. This component should be refactored to use props directly instead of injecting them into state
  static getDerivedStateFromProps(nextProps, prevState) {
    const stateChanges = {};
    const inputHistory = _getInputHistory(nextProps)

    for(const [key, value] of Object.entries(inputHistory)){
      if(!isEqual(prevState[key], value)){
        if (key === 'visibleTab' && !EXECUTION_TABS.includes(value)) {
          stateChanges[key] = DEFAULT_TAB_NAME;
        } else {
          stateChanges[key] = value
        }
      }
    }

    if(Object.keys(stateChanges).length === 0){
      return null
    } else {
      return stateChanges
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      algo_instrumentDisplayName,
      algo_venueIds,
      dma_instrumentDisplayName,
      dma_venueId,
      errorMessageKey,
      visibleTab,
      waitingForMarketDataEntries_algo,
      waitingForMarketDataEntries_dma,
    } = this.state;

    const { keyedInstruments, keyedVenues, venues } = this._getPopulatedQueryData(visibleTab);

    const queryChanges = (qry, dataKey) => [
      get(prevProps, `${qry}.loading`) && !get(this.props, `${qry}.loading`), // query finished loading
      get(this.props, `${qry}.data.${dataKey}`), // result of query
      get(this.props, `${qry}.error`), // error with query,
      get(prevProps, `${qry}.data.${dataKey}`), // previous result of query
    ];

    //
    // Algo/DMA Market Data
    //

    const dmaTabVisible = visibleTab === TAB_NAME_DMA;
    const algoTabVisible = visibleTab === TAB_NAME_ALGO;

    const dmaVenue = keyedVenues[dma_venueId] || {}
    const dmaInstrument = getInstrumentFromNameVenue(venues, dma_instrumentDisplayName, 'id', dmaVenue.id);
    const dmaNeedsUpdate = dmaTabVisible && dmaInstrument.id && (!(get(this.props, 'requestedFeeds.DMA') || []).includes(dmaInstrument.id));

    const algoVenue = keyedVenues[algo_venueIds?.[0]] || {}  // Only used to get algo instrument, so any of these venues can be used
    const algoInstrument = getInstrumentFromNameVenue(venues, algo_instrumentDisplayName, 'id', algoVenue.id);
    const algoNeedsUpdate = algoTabVisible && algoInstrument.id && (!(get(this.props, 'requestedFeeds.ALGO') || []).includes(algoInstrument.id));

    if (dmaNeedsUpdate) {
      this.setState({ waitingForMarketDataEntries_dma: true }, _ => {
        this.props.updateInstruments('DMA', [dmaInstrument.id]);
        this._setTimeout(TAB_NAME_DMA);
      });
    }

    if (algoNeedsUpdate){
      this.setState({ waitingForMarketDataEntries_algo: true }, _ => {
        this.props.updateInstruments('ALGO', [algoInstrument.id]);
        this._setTimeout(TAB_NAME_ALGO);
      });
    }

    const marketDataQuery = 'marketDataFeedSubscription';
    const [mdFinishedLoading, marketData, mdError] = queryChanges(marketDataQuery, 'marketDataEntries');
    const errorIsForMD = errorMessageKey === ERR_MARKET_DATA;
    const waitingForMD = waitingForMarketDataEntries_algo || waitingForMarketDataEntries_dma || errorIsForMD;

    if (waitingForMD || (mdFinishedLoading && !isError(mdError))) {
      const dmaFinished = (errorIsForMD || waitingForMarketDataEntries_dma) && hasMarketDataFor(dmaInstrument.id, keyedInstruments, marketData);
      const algoFinished = (errorIsForMD || waitingForMarketDataEntries_algo) && hasMarketDataFor(algoInstrument.id, keyedInstruments, marketData);
      const visibleTabHasMD = (dmaFinished && dmaTabVisible) || (algoFinished && algoTabVisible);
      const updatedError = errorIsForMD && visibleTabHasMD ? undefined : errorMessageKey;

      if (dmaFinished) clearTimeout(this._timers[TAB_NAME_DMA]);
      if (algoFinished) clearTimeout(this._timers[TAB_NAME_ALGO]);

      if (dmaFinished || algoFinished) this.setState({
        waitingForMarketDataEntries_dma: waitingForMarketDataEntries_dma && !dmaFinished,
        waitingForMarketDataEntries_algo: waitingForMarketDataEntries_algo && !algoFinished,
        errorMessageKey: updatedError,
      });
    }

    const fetchPrices = i => this.props.fetchCurrentPrices({
      currencyIds: [ get(i, 'baseAsset.id') ],
      termCurrencyId: get(i, 'termAsset.id'),
    });

    if (algoNeedsUpdate) {
      fetchPrices(algoInstrument)
    } else if (dmaNeedsUpdate) {
      fetchPrices(dmaInstrument)
    }

    //
    // Algo/DMA Orders
    //

    const dmaMutation = 'placeOrderForSpecificExchangeMutation';
    const [dmaFinishedProcessing, dmaOrder, dmaError] = queryChanges(dmaMutation, 'order');

    if (dmaFinishedProcessing) {
      if (!isError(dmaError) && dmaOrder) {
        this.setState({ dmaOrderPlaced: true });
        clearTimeout(this._timers.dmaSuccess);
        this._timers.dmaSuccess = setTimeout(_ => this.setState({ dmaOrderPlaced: false }), 1500);
      } else {
        this.setState({ errorMessageKey: ERR_DMA_ORDER });
      }
    }

    const algoMutation = 'placeOrderForAlgorithmicExecutionMutation';
    const [algoFinishedProcessing, algoOrder, algoError] = queryChanges(algoMutation, 'order');

    if (algoFinishedProcessing) {
      if (!isError(algoError) && algoOrder) {
        this.setState({ algoOrderPlaced: true });
        clearTimeout(this._timers.algoSuccess);
        this._timers.algoSuccess = setTimeout(_ => this.setState({ algoOrderPlaced: false }), 1500);
      } else {
        const errorMessageKey = get(algoError, 'message', '').endsWith(ERR_NO_ACCOUNTS) ? ERR_ALGO_ACCOUNTS : ERR_ALGO_ORDER;
        this.setState({ errorMessageKey });
      }
    }
  }

  componentWillUnmount() {
    Object.values(this._timers).map(clearTimeout);
  }

  _getHandleEntry = _ => [
    OrderAlgorithmStrategy.ICE,
    OrderAlgorithmStrategy.SOR,
  ].includes(this.state.algorithmStrategy)
    ? this._handleSelectOrderBookEntry
    : noop;

  _getPopulatedQueryData = (visibleTab = '') => {
    const currencies = get(this.props, 'currenciesQuery.data.currencies') || [];
    const integrations = this.props.integrationsQuery?.data?.integrations || [];
    const accounts = get(this.props, 'accountsWithBalancesQuery.data.accounts') || [];
    const relationships = get(this.props, 'orgQuery.data.org.relationships') || [];

    const venueType = visibleTab === TAB_NAME_OTC
      ? VenueType.OTC
      : VenueType.EXCHANGE;

    const venues = getFilteredVenues({ integrations, relationships }, { venueType });
    const keyedVenues = getKeyedFilteredVenues({ integrations, relationships }, { venueType });
    const keyedInstruments = getKeyedInstrumentsFromVenues({ integrations, relationships }, { venueType });
    const instruments = getSortedInstrumentsFromVenues({ integrations, relationships }, { venueType });

    return { accounts, currencies, instruments, keyedInstruments, keyedVenues, venues };
  }

  _handleChangeExecutionForm = change => {
    const changes = {};

    const tab = this.state.visibleTab || '';
    const tabKey = tab.toLowerCase();
    const keyFor = k => `${tabKey}_${k}`;

    if (change.currencyId) changes[keyFor(INPUT_CURRENCY_ID)] = change.currencyId;
    if (change.instrumentDisplayName) changes[keyFor(INPUT_INSTRUMENT_DISPLAY_NAME)] = change.instrumentDisplayName;
    if (change.venueId) changes[keyFor(INPUT_VENUE_ID)] = change.venueId;
    if (change.venueIds) changes[keyFor(INPUT_VENUE_IDS)] = change.venueIds;

    if (changes.dma_instrumentDisplayName) changes.dma_venueId = '';

    if (change.instrumentDisplayName || change.venueId) {
      changes.errorMessageKey = undefined;
      changes.successMessageKey = undefined;
    }

    if (change.algorithmStrategy) changes.algorithmStrategy = change.algorithmStrategy;

    this.setState(changes, _ => {
      const stateKey = k => `${COMPONENT_NAME}_${k}`;
      const inputs = Object.keys(this.state).reduce((inputs, k) => {
        const key = stateKey(k);
        const value = changes[k];
        return (
          (k.startsWith(`${tabKey}_`) && !isNil(value)) ||
          (k === REF_RATE_KEY && Number.isFinite(value)) ||
          (k === SOR_FEES_KEY && !isNil(value))
        ) ? [ ...inputs, { key, value } ] : inputs;
      }, []);

      this.props.updateRecentInputHistory({ inputs });
    })
  }

  _changeTab = (event, { content }) => this.state.visibleTab !== content && this.setState({
    errorMessageKey: false,
    successMessageKey: false,
    visibleTab: content,
    orderBookEntry: null,
    algorithmStrategy: null,
  }, _ => this.props.updateRecentInputHistory({
    inputs: [{ key: `${COMPONENT_NAME}_${INPUT_VISIBLE_TAB}`, value: content }]
  }));

  _handleSelectOrderBookEntry = entry => this.setState({
    errorMessageKey: undefined,
    orderBookEntry: entry,
  });

  _handleSubmitAlgoExecutionForm = inputs => this.props.placeOrderForAlgorithmicExecution(inputs);

  _handleSubmitDmaExecutionForm = inputs => this.props.placeOrderForSpecificExchange(inputs);

  _handleChangeSectionHeight = sectionHeight => this.setState({ sectionHeight });

  _setTimeout(tab) {
    clearTimeout(this._timers[tab]);
    const mdKey = `waitingForMarketDataEntries_${(tab || '').toLowerCase()}`;
    this._timers[tab] = setTimeout(_ => this.state[mdKey] && this.state.visibleTab === tab && this.setState({
      [mdKey]: false,
      errorMessageKey: ERR_MARKET_DATA,
    }), MAX_WAIT_TIME);
  }

  _isLoading = _ => (
    get(this.props, 'integrationsQuery.loading') ||
    !has(this.props, 'integrationsQuery.data.integrations') ||
    get(this.props, 'orgQuery.loading') ||
    !has(this.props, 'orgQuery.data.org') ||
    this._isRecentInputHistoryLoading()
  );

  _isRecentInputHistoryLoading = _ =>
    get(this.props, 'recentInputHistoryQuery.loading') ||
    !has(this.props, 'recentInputHistoryQuery.data.inputHistory');
}

const _inputHistoryFor = (str, dflt, props) => get(props, `recentInputHistoryQuery.data.inputHistory.${COMPONENT_NAME}_${str}`, dflt) || dflt;

const _getInputHistory = props => ({
  algo_instrumentDisplayName: _inputHistoryFor('algo_instrumentDisplayName[0]', '', props),
  algo_recentInstrumentDisplayNames: _inputHistoryFor('algo_instrumentDisplayName', [], props),
  algo_venueIds: _inputHistoryFor('algo_venueIds', [], props),
  dma_instrumentDisplayName: _inputHistoryFor('dma_instrumentDisplayName[0]', '', props),
  dma_recentInstrumentDisplayNames: _inputHistoryFor('dma_instrumentDisplayName', [], props),
  dma_venueId: _inputHistoryFor('dma_venueId[0]', '', props),
  includeSorFees: _inputHistoryFor('includeSorFees[0]', true, props),
  visibleTab: _inputHistoryFor('visibleTab[0]', DEFAULT_TAB_NAME, props),
});

const covIdStr = 'custodian.operatingVenue.id';
const notSystem = a => get(a, 'custodian.id') !== SYSTEM_CUSTODIAN_ID;
const algoFilter = (ac, vs) => (ac || []).filter(a => notSystem(a) && (vs || []).includes(get(a, covIdStr)));
const dmaFilter = (ac, vs) => (ac || []).filter(a => notSystem(a) && (vs || [])[0] === get(a, covIdStr));

const _filterAccountsForVisibleTab = createSelector(
  (accounts, venueIds, visibleTab) => accounts,
  (accounts, venueIds, visibleTab) => venueIds,
  (accounts, venueIds, visibleTab) => visibleTab,
  (accounts, venueIds, visibleTab) => (({
    [TAB_NAME_ALGO]: algoFilter,
    [TAB_NAME_DMA]: dmaFilter,
  })[visibleTab] || (_ => _))(accounts, venueIds)
);

const _keyPricesByBaseId = createSelector(
  ({ currentPrices }) => currentPrices || [],
  prices => keyBy(prices, p => get(p, 'baseCurrency.id'))
);

const _getPositions = createSelector(
  accounts => accounts,
  accounts => accounts.reduce((positions, acct) => {
    const id = get(acct, 'currency.id');

    const quantity = get(acct, 'position.quantity')
      || acct.balanceAtCustodianAsReportedFree
      || acct.temporaryCalculatedBalance
      || 0;

    positions[id] = positions[id] || { currency: acct.currency, quantity: 0 };
    positions[id].quantity += quantity;

    return positions;
  }, {})
);
