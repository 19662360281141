import React, { PureComponent } from 'react';
import { components } from 'react-select';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { Popup } from 'semantic-ui-react';

import { colors } from '@omniex/onx-common-ui/lib/styles';
import Dropdown, { getDropdownStyles } from '@omniex/onx-common-ui/lib/react-select/Dropdown';
import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { isEmpty } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';

export const styles = {
  option: (styles, { isSelected }) => ({
    ...styles,
    background: isSelected ? colors.selectedColor : 'none',
    color: colors.black,
    cursor: 'pointer',
    fontSize: '0.95em',
    fontWeight: isSelected ? 'bold' : 'none',
    'label': {
      cursor: 'pointer',
      paddingLeft: '6px',
      verticalAlign: 'middle !important',
    },
    'input': {
      cursor: 'pointer',
      verticalAlign: 'middle !important',
    },
    '&:hover': {
      background: colors.transparentBlack
    }
  }),
  valueContainer: base => ({ ...base, width: '100%', minHeight: '2em' }),
  singleValue: base => ({ ...base, width: '100%', lineHeight: '2em' })
}

export const popupStyles = {
  container: {
    fontSize: '0.9em',
    padding: '8px 8px',
    position: 'absolute',
    width: 'fit-content',
  },
  info: {
    fontSize: '0.9em',
    textAlign: 'left'
  },
};

const ClearIndicator = () => {
  return null;
};

const Option = props => {
  const value = get(props, 'selectProps.value', []);
  const options = get(props, 'selectProps.options');
  const allSelected = value.length === getUngroupedOptions(options).length;
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected || allSelected}
          onChange={() => null}
        />
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const flattener = (list, item) => (list.push(...(item.options || [item])), list); //eslint-disable-line no-sequences
export const getUngroupedOptions = (options) => options.reduce(flattener, []).filter(o => o.value !== '*') || []

const MultiValue = props => {
  const value = get(props, 'selectProps.value', []);
  const index = value.indexOf(props.data);

  // Only create MultiValue component for 0th value
  if (index) return null;

  const allSelected = value.length === getUngroupedOptions(props.options).length;

  const allLabel = p => get(get(p, 'options', []).find(o => o.value === '*'), 'label', 'All Items');

  const label = value.length === 1
    ? get(props, 'data.label', 'N/A')
    : allSelected
      ? `${allLabel(props)} (${value.length})`
      : `${value.length} ${allLabel(props).split(' ')[1] || 'Items'}`

  const menuOpen = get(props, 'selectProps.menuIsOpen', false);

  return (
    <components.SingleValue {...props}>
      {value.length > 1 && !menuOpen
        ? <Popup
            flowing
            hideOnScroll
            hoverable
            inverted
            position="bottom center"
            style={popupStyles.container}
            trigger={<div>{label}</div>}
            verticalOffset={10}>
            <div style={popupStyles.info}>
              {value.filter(v => v && v.value !== '*').sort(v => v.label).map((v, i) => <div key={i}>{v.label}</div>)}
            </div>
          </Popup>
        : <div>{label}</div>}
    </components.SingleValue>
  );
}

export default class MultiSelect extends PureComponent {
  static propTypes = {
    allOption: shape({
      label: string.isRequired,
      value: string.isRequired
    }),
    isDisabled: bool,
    onChange: func.isRequired,
    options: arrayOf(
      shape({
        label: string.isRequired,
        value: string,
        option: arrayOf(
          shape({
            lable: string,
            value: string
          })
        )
      })
    ),
    placeholder: string,
  }

  static defaultProps = {
    allOption: {
      label: 'All Items',
      value: '*'
    },
    onChange: noop,
  };

  _onChange = (selected, event) => {
    if (isEmpty(selected) || get(event, 'option.value') !== get(this.props, 'allOption.value')) {
      return this.props.onChange(selected, event);
    }

    const ungroupedOptions = getUngroupedOptions(this.props.options);
    const newSelected = selected.length > ungroupedOptions.length
        ? []
        : ungroupedOptions;

    return this.props.onChange(newSelected, event);
  };

  render() {
    const options = get(this.props, 'options.length') > 1
      ? [this.props.allOption, ...this.props.options]
      : this.props.options;

    return (
      <Dropdown
        styles={{ ...getDropdownStyles(), ...styles }}
        {...this.props}
        closeMenuOnSelect={false}
        components={{ ClearIndicator, MultiValue, Option, ...this.props.components }}
        hideSelectedOptions={false}
        isMulti
        isSearchable={false}
        onChange={this._onChange}
        options={options}
      />
    )
  }
}
