import { bool, func, object } from 'prop-types';
import { Grid, Menu, Segment } from 'semantic-ui-react';
import React, { PureComponent } from 'react';

import styled from 'react-emotion';

import { has } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { isError } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './SettingsPageNotificationSettingsSection.copyText';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import NotificationSettingsComponent from '../components/NotificationSettingsComponent';
import UpdateNotificationSettingsForm from '../components/UpdateNotificationSettingsForm';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/segment.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const COMPONENT_NAME = 'SettingsPageNotificationSettingsSection';

const StyledSection = styled('section')`
  /* min-width: 0; TODO: determine suitable min-width */

  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;

  .${COMPONENT_NAME}-segment {
    flex: 1 !important;
    padding-top: 0em !important;
    padding-bottom: 0em !important;
  }

  .${COMPONENT_NAME}-successMessage + .${COMPONENT_NAME}-segment {
    border-top-color: ${colors.successBorderColor};
  }

  .${COMPONENT_NAME}-errorMessage + .${COMPONENT_NAME}-segment {
    border-top-color: ${colors.errorBorderColor};
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

const ERROR_MESSAGE_KEY_UPDATE_NOTIFICATION_SETTINGS_FAILED =
  'errorMessageUpdateNotificationSettingsFailed';
const SUCCESS_MESSAGE_KEY_UPDATED_NOTIFICATION_SETTINGS_PASSWORD =
  'successMessageUpdatedNotificationSettings';

export default class SettingsPageNotificationSettingsSection extends PureComponent {
  static propTypes = {
    updateNotificationSettings: func,
    updateNotificationSettingsMutationError: object,
    updateNotificationSettingsMutationProcessing: bool,
    updateNotificationSettingsMutationResult: object,
    user: object,
  };

  static defaultProps = { updateNotificationSettings: noop };

  state = {
    showUpdateSettingsForm: false,
  };

  render() {
    const { updateNotificationSettingsMutationProcessing, updateNotificationSettingsMutationError, user } = this.props;
    const hasError = isError(updateNotificationSettingsMutationError);
    const errorMessageKey = !updateNotificationSettingsMutationProcessing && hasError
      ? ERROR_MESSAGE_KEY_UPDATE_NOTIFICATION_SETTINGS_FAILED 
      : undefined;
    const successMessageKey = !updateNotificationSettingsMutationProcessing && !hasError && has(this.props, 'updateNotificationSettingsMutationResult.userSettings') 
      ? SUCCESS_MESSAGE_KEY_UPDATED_NOTIFICATION_SETTINGS_PASSWORD 
      : undefined;

    return (
      <StyledSection className={COMPONENT_NAME}>
        {successMessageKey && (
          <Message
            className={cn('successMessage')}
            attached
            closeOnClick
            content={copyText[successMessageKey]}
            icon="check circle outline"
            success
          />
        )}
        {errorMessageKey && (
          <Message
            className={cn('errorMessage')}
            attached
            closeOnClick
            content={copyText[errorMessageKey]}
            error
            icon="warning sign"
          />
        )}
        <Menu attached="top">
          <Menu.Item content={copyText.sectionTitle} header icon="envelope" />
          <Menu.Menu position="right">
            <Menu.Item
              disabled={false}
              content={'Update Settings'}
              icon="edit"
              onClick={this._handleToggleSettingsForm}
            />
          </Menu.Menu>
        </Menu>
        {!user?.loading && <Segment className={cn('segment')} attached="bottom">
          <Grid className={cn('grid')} celled="internally">
            <Grid.Row columns={this.state.showUpdateSettingsForm ? 2 : 1}>
              <Grid.Column width={this.state.showUpdateSettingsForm ? 8 : 16}>
                <NotificationSettingsComponent
                  userSettings={user?.settings}
                />
              </Grid.Column>
              {this.state.showUpdateSettingsForm && (
                <Grid.Column width={8}>
                  <UpdateNotificationSettingsForm
                    processing={
                      this.props.updateNotificationSettingsMutationError
                    }
                    userSettings={user?.settings}
                    onCancel={this._handleToggleSettingsForm}
                    onSave={this._handleSubmitUpdateNotificationSettingsForm}
                  />
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </Segment>}
      </StyledSection>
    );
  }

  _handleSubmitUpdateNotificationSettingsForm = (inputs) => {
    this.props
      .updateNotificationSettings({ inputs })
      .then(
        this.setState({
          showUpdateSettingsForm: false
        })
      );
  }

  _handleToggleSettingsForm = () =>  {
    this.setState(currentState => ({
      showUpdateSettingsForm: !currentState.showUpdateSettingsForm
    }));
  }
}
