import colors from '@omniex/onx-common-ui/lib/colors'
import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
	palette: {
		primary: {
			main: colors.blue,
		},
		secondary: {
			main: colors.orange,
		},
	},
})

export default theme
