import * as React from 'react';

import Box from '@material-ui/core/Box';
import { Button, Icon } from 'semantic-ui-react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@omniex/poms-ui/lib/themes';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { FormApiKey } from '../../../types';
import copyText from './copyText';

const copyToClipboard = (text: string) => navigator.clipboard.writeText(text).catch(console.error);

const useStyles = makeStyles((theme) => ({
  box: {
    border: 'solid',
    margin: theme.spacing(2),
  },
  boxText: {
    margin: theme.spacing(1),
    textAlign: 'left',
  },
  closeButton: {
    textAlign: 'center',
    width: '30%',
  },
  copyButton: {
    background: '#f2f3f3 !important',
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    width: '115px',
  },
  copyButtonLabel: {
    verticalAlign: 'top',
  },
  gridCell: {
    overflowWrap: 'break-word',
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    textAlign: 'left',
  },
  modal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  overflowText: {
    overflowWrap: 'break-word',
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    textAlign: 'left',
  },
  paper: {
    padding: theme.spacing(2),
    maxWidth: '1000px',
    minWidth: '400px',
    textAlign: 'center',
    width: '50vw',
  },
  popupHeader: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    textAlign: 'left',
  },
  popupSuccess: {
    fontWeight: 'bold',
    margin: theme.spacing(1),
  }
}));

type ApiKeyPopupProps = {
  apiKey: FormApiKey
  closePopup: () => void
  showPopup: boolean
};

const ApiKeyPopup: React.FC<ApiKeyPopupProps> = ({
  apiKey,
  closePopup,
  showPopup
}) => {
  // Styles
  const classes = useStyles();

  return (
    <Modal
      open={showPopup}
      className={classes.modal}
    >
      <Paper square className={classes.paper}>
        <Typography variant="h6">{copyText.popupHeader}</Typography>
        <Divider />
        <Typography variant="subtitle2" className={classes.popupSuccess}>{copyText.popupSuccess}</Typography>
        <Box className={classes.box}>
          <div className={classes.boxText}>
            <Grid container item xs={12}>
              <Grid item xs={2} className={classes.gridCell}>
                <Typography variant="subtitle2">{copyText.labelHeader}</Typography>
              </Grid>
              <Grid item xs={7} className={classes.overflowText}>
                <Typography variant="subtitle2">{apiKey.label}</Typography>
              </Grid>
              <Grid item xs={3} className={classes.gridCell}></Grid>
              <Grid item xs={2} className={classes.gridCell}>
                <Typography variant="subtitle2">{copyText.keyHeader}</Typography>
              </Grid>
              <Grid item xs={7} className={classes.overflowText}>
                <Typography variant="subtitle2">{apiKey.key}</Typography>
              </Grid>
              <Grid item xs={3} className={classes.gridCell}>
                <Button
                  onClick={() => copyToClipboard(apiKey.key)}
                  icon
                  labelPosition="left"
                  className={classes.copyButton}
                >
                  <Icon name='clipboard'/>
                    {copyText.copyButton}
                </Button>
              </Grid>
              <Grid item xs={2} className={classes.gridCell}>
                <Typography variant="subtitle2">{copyText.secretHeader}</Typography>
              </Grid>
              <Grid item xs={7} className={classes.overflowText}>
                <Typography variant="subtitle2">{apiKey.secret}</Typography>
              </Grid>
              <Grid item xs={3} className={classes.gridCell}>
              <Button
                  onClick={() => copyToClipboard(apiKey.secret)}
                  icon
                  labelPosition="left"
                  className={classes.copyButton}
                >
                  <Icon name='clipboard'/>
                    {copyText.copyButton}
                </Button>
              </Grid>
            </Grid>
            <p className={classes.boxText}><i>{copyText.popupNote}</i></p>
          </div>
        </Box>
        <Button
          onClick={closePopup}
          size="small"
          variant="text"
          className={classes.closeButton}
        >
          {copyText.closeButton}
        </Button>
      </Paper>
    </Modal>
  );
};

export default ApiKeyPopup;
