import { createSelector } from 'reselect';

import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { isValid as isValidIdentifier } from '@omniex/onx-common-js/lib/utils/IdentifierUtils';

export function generateCurrentPriceLookupTable(currentPrices, homeCurrencyId) {
  if (
    !Array.isArray(currentPrices) ||
    currentPrices.length === 0 ||
    !isValidIdentifier(homeCurrencyId)
  ) {
    return {};
  }

  const currentPriceLookupTable = currentPrices.reduce(
    (currentPriceLookupTable, priceEntry) => ({
      ...currentPriceLookupTable,
      [get(priceEntry, 'baseCurrency.id')]: priceEntry.price
    }),
    {}
  );

  return {
    ...currentPriceLookupTable,
    [homeCurrencyId]: 1
  };
}

export default createSelector(
  (currentPrices, homeCurrencyId) => currentPrices,
  (currentPrices, homeCurrencyId) => homeCurrencyId,
  generateCurrentPriceLookupTable
);
