import * as React from 'react';

import { BellIcon } from '@omniex/poms-ui/lib/components/Icon';
//@ts-ignore
import { colors } from '@omniex/onx-common-ui/lib/styles';
import { makeStyles } from '@omniex/poms-ui/lib/themes';
import { TextButton } from '@omniex/poms-ui/lib/components/TextButton';

import { BaseUser } from '../../../types';
import { userEnabledDesktopNotifications } from '../../../utils/OrderNotificationUtils';

import clsx from 'clsx';

const COMPONENT = 'NotificationsIcon';

const useStyles = makeStyles(() => ({
  userAlertButton: {
    fontFamily: '"Roboto", sans-serif !important',
    marginRight: '0',
    '& span > span > svg': {
      color: `${colors.white} !important`,
    },
    '& span': {
      color: `${colors.navBarText} !important`,
    },
  },
  userAlertButtonDisabled: {
    '& span > span > svg': {
      color: `${colors.white} !important`,
    },
  },
  userAlertButtonNew: {
    '& span > span > svg': {
      color: `${colors.orange} !important`,
    },
  },
}), { name: COMPONENT });

export interface NotificationsIconProps {
  newNotificationsCount: number
  notificationsCount: number
  onClick: () => void
  showNotifications: boolean
  user: BaseUser
};

const NotificationsIcon = ({
  newNotificationsCount = 0,
  notificationsCount = 0,
  onClick,
  showNotifications = false,
  user,
}: NotificationsIconProps) => {
  const cls = useStyles();

  // Avoids case where clicking the button closes the tray due to
  // "on blur" and then reopens it due to button click
  const disabled = !userEnabledDesktopNotifications(user) || showNotifications;

  return (
    <TextButton
      className={clsx(
        cls.userAlertButton,
        notificationsCount <= 0 && cls.userAlertButtonDisabled,
        newNotificationsCount > 0 && cls.userAlertButtonNew
      )}
      disabled={disabled}
      IconComponent={BellIcon}
      onClick={onClick}
      selected={showNotifications}
    >
      { newNotificationsCount > 0 && newNotificationsCount }
    </TextButton>
  );
};

export default NotificationsIcon;
