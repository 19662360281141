import gql from 'graphql-tag';

export default gql`
  query getCurrentPrices($currencyIds: [ID!], $termCurrencyId: ID) {
    currentPrices: getCurrentPrices(
      currencyIds: $currencyIds
      termCurrencyId: $termCurrencyId
    ) {
      id
      baseCurrency {
        id
        symbol
        type
        source
        expiry
      }
      termCurrency {
        id
        symbol
        type
        source
        expiry
      }
      marginAsset {
        id
        symbol
        type
        source
        expiry
      }
      baseValue
      homeValue
      marginValue
      markPrice
      price
    }
  }
`;
