import gql from 'graphql-tag';

export default gql`
  mutation createInstruction(
    $accountId: ID!
    $description: String!
    $freeForm: String
    $iban: String
    $walletAddress: String
    $xpubKey: String
  ) {
    instruction: createInstruction(
      accountId: $accountId
      description: $description
      freeForm: $freeForm
      iban: $iban
      walletAddress: $walletAddress
      xpubKey: $xpubKey
    ) {
      id
    }
  }
`;
