export const ADMIN_PAGE_PATH = '/admin';
export const ANALYTICS_PAGE_PATH = '/analytics';
export const CREDITS_PAGE_PATH = '/credits';
export const CUSTODY_PAGE_PATH = '/custody';
export const DASHBOARD_PAGE_PATH = '/dashboard';
export const MARKET_PAGE_PATH = '/market';
export const PORTFOLIO_PAGE_PATH = '/portfolio';
export const QUOTE_PAGE_PATH = '/quote';
export const REPORT_PAGE_PATH = '/report';
export const REQUESTS_PAGE_PATH = '/requests';
export const ROOT_PATH = '/';
export const SETTINGS_PAGE_PATH = '/settings';
export const SETTLEMENT_PAGE_PATH = '/settlement';
export const TRADE_PAGE_PATH = '/trade';
export const USER_MGMT_PAGE_PATH = '/usermgmt';

const paths = {
  ADMIN_PAGE_PATH,
  ANALYTICS_PAGE_PATH,
  CREDITS_PAGE_PATH,
  CUSTODY_PAGE_PATH,
  DASHBOARD_PAGE_PATH,
  MARKET_PAGE_PATH,
  PORTFOLIO_PAGE_PATH,
  QUOTE_PAGE_PATH,
  REPORT_PAGE_PATH,
  REQUESTS_PAGE_PATH,
  ROOT_PATH,
  SETTINGS_PAGE_PATH,
  SETTLEMENT_PAGE_PATH,
  TRADE_PAGE_PATH,
  USER_MGMT_PAGE_PATH
};

export default paths;
