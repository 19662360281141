import { bool, func, shape, string } from 'prop-types';
import { Form, Icon, Input, Popup, TextArea } from 'semantic-ui-react';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import {
  allowsFixClientId,
  displayThirdInputCopyText as displayAdditionalInputCopyText,
  needsFixClientId,
  needsFixCompId,
  needsThirdInput as needsPassphrase,
} from '@omniex/poms-core/lib/utils/IntegrationUtils';
import IntegrationType from '@omniex/poms-core/lib/enums/IntegrationType';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import copyText from './SaveCredentialsForm.copyText';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/form.css');
require('@omniex/onx-common-ui/lib/semantic/css/input.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');

const COMPONENT_NAME = 'SaveCredentialsForm';

const StyledDiv = styled('div')`
  .${COMPONENT_NAME}-fixClientIdTooltip {
    color: #006FBF;
    margin-left: 5px;
  }

  .${COMPONENT_NAME}-fixCompIdTooltip {
    color: #006FBF;
    margin-left: 5px;
  }

  .${COMPONENT_NAME}-fixClientIdWrapper {
    display: flex;
    align-items: center;
  }

  .${COMPONENT_NAME}-fixCompIdWrapper {
    display: flex;
    align-items: center;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class SaveCredentialsForm extends PureComponent {
  static propTypes = {
    integration: shape({
      id: string.isRequired,
      type: string.isRequired
    }),
    isForView: bool.isRequired,
    fixCompId: string,
    fixClientId: string,
    keyValue: string,
    password: string,
    secret: string,
    showSecondConfirmation: bool,
    type: string.isRequired,
    handleChangeField: func
  };

  static defaultProps = {
    clearSaveIntegrationForm: noop,
    handleChangeField: noop
  };

  render() {
    const infoMessage = this.props.isForView
      ? copyText.credentialsReadOnlyMessage
      : copyText.credentialsTradeReadMessage;

    return (
      <div
        className={COMPONENT_NAME}
        style={{
          marginBottom: 20
        }}>
        <span>
          {this.props.isForView
            ? copyText.credentialsForViewTitle
            : copyText.credentialsForTradeTitle}
        </span>
        <Message
          content={
            <span
              dangerouslySetInnerHTML={{
                __html: infoMessage
                  .replace('/%', '</strong>')
                  .replace('%', '<strong>')
              }}
            />
          }
          info
        />
        <Form.Field>
          <Input
            name={this.props.isForView ? 'keyForView' : 'key'}
            disabled={this.props.showSecondConfirmation}
            placeholder={
              this.props.isForView
                ? copyText.inputPlaceholder_keyForView
                : copyText.inputPlaceholder_key
            }
            required
            value={this.props.keyValue}
            onChange={this.props.handleChangeField}
          />
        </Form.Field>
        <Form.Field>
          <TextArea
            name={this.props.isForView ? 'secretForView' : 'secret'}
            disabled={this.props.showSecondConfirmation}
            placeholder={
              this.props.isForView
                ? copyText.inputPlaceholder_secretForView
                : copyText.inputPlaceholder_secret
            }
            required
            rows={3}
            value={this.props.secret}
            onChange={this.props.handleChangeField}
          />
        </Form.Field>
        {this._renderPasswordField(this.props.integration)}
        {this._renderFixClientIdField(this.props.integration)}
        {this._renderFixCompIdField(this.props.integration)}
      </div>
    );
  }

  _renderPasswordField = integration => {
    return needsPassphrase(this.props.type) ? (
      <Form.Field>
        <Input
          name={this.props.isForView ? 'passwordForView' : 'password'}
          disabled={this.props.showSecondConfirmation}
          placeholder={
            copyText[
              `inputPlaceholder_${displayAdditionalInputCopyText(
                this.props.type
              )}${this.props.isForView ? 'ForView' : ''}`
            ]
          }
          required
          value={this.props.password}
          onChange={this.props.handleChangeField}
        />
      </Form.Field>
    ) : null;
  };

  _renderFixCompIdTooltip = type => {
    if (type === IntegrationType.EXCHANGE_GEMINI) {
      return <Popup
        hoverable
        content={(<div>
          <p>{copyText.tooltipContent_geminiFixCompId}</p>
          <p>Your account's FIX Comp ID can be obtained from the Gemini support desk. Refer usage of <i>OnBehalfOfCompID</i> in the Gemini API <a href='https://docs.gemini.com/fix-api/#third-party-support' target='_blank' rel="noopener noreferrer">documentation</a>.</p>
          <p>You can always <a href='mailto:support@omniex.io'>contact us</a> with any questions.</p>
        </div>)}
        trigger={<Icon className={cn('fixCompIdTooltip')} name='question circle outline' />}
      />
    }
    return null;
  };

  _renderFixCompIdField = integration => {
    // FIX Comp ID is only required for trade
    return needsFixCompId(this.props.type) && !this.props.isForView ? (
      <StyledDiv>
        <Form.Field className={cn('fixCompIdWrapper')}>
          <Input
            name={'fixCompId'}
            disabled={this.props.showSecondConfirmation}
            placeholder={copyText.inputPlaceholder_fixCompId}
            required
            value={this.props.fixCompId}
            onChange={this.props.handleChangeField}
          />
          {this._renderFixCompIdTooltip(this.props.type)}
        </Form.Field>
      </StyledDiv>
    ) : null;
  };

  _renderFixClientIdTooltip = type => {
    if (type === IntegrationType.EXCHANGE_FTX) {
      return <Popup
        hoverable
        content={(<div>
          <p>{copyText.tooltipContent_ftxFixClientId}</p>
          <p>Refer to usage of Tag 1 / Account in the FTX API <a href='https://docs.ftx.com/#logon-a' target='_blank' rel="noopener noreferrer">documentation</a>. The Subaccount Name field is case sensitive.</p>
          <p>You can always <a href='mailto:support@omniex.io'>contact us</a> with any questions.</p>
        </div>)}
        trigger={<Icon className={cn('fixClientIdTooltip')} name='question circle outline' />}
      />
    }
    return null;
  };

  _getFixClientIdPlaceholder = type => {
    return type === IntegrationType.EXCHANGE_FTX
      ? copyText.inputPlaceholder_ftxFixClientId
      : copyText.inputPlaceholder_fixClientId;
  };

  _renderFixClientIdField = integration => {
    return (needsFixClientId(this.props.type) || allowsFixClientId(this.props.type)) ? (
      <StyledDiv>
        <Form.Field className={cn('fixClientIdWrapper')}>
          <Input
            name={this.props.isForView ? 'fixClientIdForView' : 'fixClientId'}
            disabled={this.props.showSecondConfirmation}
            placeholder={this._getFixClientIdPlaceholder(this.props.type)}
            required={needsFixClientId(this.props.type)}
            value={this.props.fixClientId}
            onChange={this.props.handleChangeField}
          />
          {this._renderFixClientIdTooltip(this.props.type)}
        </Form.Field>
      </StyledDiv>
    ) : null;
  };
}
