import gql from 'graphql-tag';

export default gql`
  subscription refreshActiveQuoteRequests {
    quoteRequests: refreshActiveQuoteRequests {
      id
      fixClientID
      fixQuoteReqID
      instrument {
        id
      }
      quotes {
        id
        bidPrice
        fixQuoteID
        offerPrice
        status
        venueTrader {
          id
        }
        order {
          id
          status
        }
      }
      side
      specifiedCurrency {
        id
      }
      specifiedQuantity
      status
      timeCreated
      timeLastUpdated
      venueTrader {
        id
      }
    }
  }
`;
