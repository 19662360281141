import createSelector from 're-reselect';
import { isEmpty } from '@omniex/poms-core/lib/utils/LangUtils';

export function getVenuesWithInstrument(venues, instrumentDisplayName) {
  if (!Array.isArray(venues) || isEmpty(instrumentDisplayName)) return [];

  return venues.filter(v => v.instrumentConfigurations?.some(
    ic => ic.instrument?.displayName === instrumentDisplayName
  ))
}

export default createSelector(
  (venues, instrumentDisplayName) => venues,
  (venues, instrumentDisplayName) => instrumentDisplayName,
  getVenuesWithInstrument
)((venues, instrumentDisplayName) => instrumentDisplayName || '');
