const affirmAllocationsTableCopyText = {
  acceptButtonLabel: 'Accept',
  acceptMessageContent:
    'If you proceed, these allocations will enter the final stage of the settlement process.',
  cancelButtonLabel: 'Cancel',
  cancelRejectButtonLabel: 'Cancel',
  confirmationMessageHeader: 'Are you sure you want to proceed?',
  incomingLabel: 'Incoming',
  inputPlaceholder_reasonRejected: 'Reason rejected',
  inputPlaceholder_selectFromAddress: 'Select From Address',
  inputPlaceholder_selectToAddress: 'Select To Address',
  inputPlaceholder_selectFromWallet: 'Select From Wallet',
  inputPlaceholder_selectToWallet: 'Select To Wallet',
  notAvailable: 'N/A',
  outgoingLabel: 'Outgoing',
  proceedButtonLabel: 'Accept',
  reasonRejectedMessageHeader: 'Reason Rejected:',
  rejectButtonLabel: 'Reject',
  rejectedLabel: 'Rejected',
  rejectionMessageContent: 'Please enter a reason for settlement rejection.',
  releaseFillsButtonLabel: 'Release Fills',
  releaseMessageContent:
    'If you proceed, all fills associated with this settlement will revert back to the previous stage of the settlement process.',
  submitRejectButtonLabel: 'Submit',
  tableHeaderAddress: 'Address/Account',
  tableHeaderCurrency: 'Currency',
  tableHeaderDirection: 'Direction',
  tableHeaderQuantity: 'Quantity',
  tableHeaderWallet: 'Wallet/Institution'
};

export default affirmAllocationsTableCopyText;
