import gql from 'graphql-tag';

export default gql`
  mutation createAccounts($portfolioId: ID!, $accounts: [AccountInput!]) {
    accounts: createAccounts(portfolioId: $portfolioId, accounts: $accounts) {
      id
      currency {
        id
      }
    }
  }
`;
