import { bool, func, object } from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import UserManagementPageRolesSection from './UserManagementPageRolesSection';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');
require('@omniex/onx-common-ui/lib/semantic/css/popup.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const COMPONENT_NAME = 'UserManagementPage';

const StyledDiv = styled('div')`
  height: 100%;
  overflow: hidden;

  .${COMPONENT_NAME}-sectionWrapper {
    padding-top: 20px;

    :last-child {
      margin-bottom: 60px;
    }
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class UserManagementPage extends PureComponent {
  static propTypes = {
    qPortfolios: object,
    updateUser: func,
    updateUserMutationError: object,
    updateUserMutationProcessing: bool,
    updateUserMutationResult: object,
    usersQueryData: object,
    usersQueryError: object,
    usersQueryLoading: bool,
  };

  static defaultProps = {
    updateUser: noop,
  };

  render() {
    return (
      <StyledDiv className={COMPONENT_NAME}>
          <main>
            <div className={cn('sectionWrapper')}>
              <UserManagementPageRolesSection
                qPortfolios={this.props.qPortfolios}
                updateUser={this.props.updateUser}
                updateUserMutationError={this.props.updateUserMutationError}
                updateUserMutationResult={this.props.updateUserMutationResult}
                updateUserMutationProcessing={
                  this.props.updateUserMutationProcessing
                }
                usersQueryData={this.props.usersQueryData}
                usersQueryError={this.props.usersQueryError}
                usersQueryLoading={this.props.usersQueryLoading}
              />
            </div>
          </main>
      </StyledDiv>
    );
  }
}
