import React, { useState } from 'react'
import { Icon } from 'semantic-ui-react';
import clsx from 'clsx'

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { useColumnHeaderClasses } from './styles'
import { align, defaultSort, sortDirections } from './utils'

const containEvent = callback => e => {
  e.stopPropagation()
  callback(e)
}

const ColumnHeader = ({ column, label, sortCol, setSortCol, sortDir, setSortDir, filterable, filter = [], setFilter, filterOptions = [] }) => {
  const allSelected = filterable && filter.length === filterOptions.length
  const filterActive = filter.length > 0 && !allSelected
  const [filtersAnchor, setFiltersAnchor] = useState(null)
  const openFilters = e => setFiltersAnchor(e.currentTarget)
  const closeFilters = () => setFiltersAnchor(null)
  const addToFilter = o => () => (setFilter([...filter.filter(f => f !== o), o]))
  const removeFromFilter = o => () => (setFilter(filter.filter(f => f !== o)))
  const handleClickItem = o => () => filter.includes(o) ? removeFromFilter(o)() : addToFilter(o)()
  const handleClickAll = () => allSelected ? setFilter([]) : setFilter([...filterOptions])

  const cls = useColumnHeaderClasses()

  const setSort = () => {
    let nextDir
    if (sortCol !== column) {
      setSortCol(column)
      nextDir = column === defaultSort.col ? defaultSort.dir : sortDirections.ASCENDING
    } else if (sortCol === defaultSort.col) {
      nextDir = sortDir === sortDirections.ASCENDING ? sortDirections.DESCENDING : sortDirections.ASCENDING
    } else if (sortDir === sortDirections.ASCENDING) {
      nextDir = sortDirections.DESCENDING
    } else { // sortDir === DESCENDING
      setSortCol(defaultSort.col)
      nextDir = defaultSort.dir
    }
    setSortDir(nextDir)
  }

  return (
    <div className={clsx(cls.header, cls[align(column)])} onClick={setSort}>
      <div className={cls.label}>{label}</div>
      <div className={cls.controls}>
        <div className={cls.sortContainer}>
          {sortCol === column &&
            <Icon className={cls.sort} name={sortDir === sortDirections.ASCENDING ? 'sort up' : 'sort down'} />}
        </div>
        {filterable && filterOptions &&
          <div className={cls.filterContainer}>
            <Icon className={filterActive ? cls.activeFilter : cls.inactiveFilter} name="filter" size='small' onClick={containEvent(openFilters)} />
            <Menu
              anchorEl={filtersAnchor}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              elevation={1}
              onClose={containEvent(closeFilters)}
              open={!!filtersAnchor}
              PaperProps={{ style: { maxHeight: '500px' } }}>
              <MenuItem onClick={containEvent(handleClickAll)}>
                {allSelected ? <CheckBoxIcon /> : filter.length ? <IndeterminateCheckBoxIcon /> : <CheckBoxOutlineIcon />}
                <em className={cls.menuItemLabel}>Select All</em>
              </MenuItem>
              {filterOptions.map(o => (
                <MenuItem key={o} value={o} onClick={containEvent(handleClickItem(o))}>
                  {filter.includes(o) ? <CheckBoxIcon /> : <CheckBoxOutlineIcon />}
                  <span className={cls.menuItemLabel}>{o}</span>
                </MenuItem>
              ))}
            </Menu>
          </div>}
      </div>
    </div>
  )
}

export default ColumnHeader
