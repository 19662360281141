import { useQuery, useMutation } from 'react-apollo';
import React from 'react';

import { ERROR_PERMISSION_DENIED } from '@omniex/onx-common-ui/lib/errors';
import { safeExecAsync } from '../utils/FunctionUtils';
import { unique } from '@omniex/onx-common-js/lib/utils/ArrayUtils';
import createAccountBuyMutation from '../apollo/graphql/createAccountBuy';
import createAccountDepositMutation from '../apollo/graphql/createAccountDeposit';
import createAccountSellMutation from '../apollo/graphql/createAccountSell';
import createAccountWithdrawalMutation from '../apollo/graphql/createAccountWithdrawal';
import createPositionMutation from '../apollo/graphql/buy-side/createPosition';
import deleteAccountActivityMutation from '../apollo/graphql/deleteAccountActivity';
import ErrorPage from '@omniex/onx-common-ui/lib/pages/ErrorPage';
import fetchBalancesMutation from '../apollo/graphql/fetchBalances';
import getAccountsWithBalances from '../apollo/graphql/getAccountsWithBalances';
import getClientAssets from '../apollo/graphql/getClientAssets';
import getCurrentPrices from '../apollo/graphql/getCurrentPrices';
import getIntegrations from '../apollo/graphql/getIntegrations';
import getOrgRelationships from '../apollo/graphql/getOrgRelationships';
import getPaginatedAccountActivities from '../apollo/graphql/buy-side/getPaginatedAccountActivities';
import getPortfolioCurrencies from '../apollo/graphql/getPortfolioCurrencies';
import updatePositionMutation from '../apollo/graphql/buy-side/updatePosition';
import PortfolioPage from '../ui/pages/PortfolioPage';

const isLoading = (q, l) => q?.loading || !q?.data?.[l]

const PortfolioPageWithAuth = ({apiClient, user, portfolio = {} }) => {
  const useApiQuery = (q, o) => useQuery(q, { client: apiClient, ...o });
  const useApiMutation = (m, o) => useMutation(m, { client: apiClient, ...o });

  const orgId = user?.org?.id;
  const portfolioId = portfolio?.id;
  const awaitRefetchQueries = true;
  const balancesQueries = [{query: getAccountsWithBalances, variables: {portfolioId}}];

  const qCurrencies = useApiQuery(getPortfolioCurrencies, { skip: !portfolioId, variables: { portfolioId } });
  const qOrg = useApiQuery(getOrgRelationships, { skip: !orgId, variables: { portfolioId } });
  const qAccounts = useApiQuery(getAccountsWithBalances, { variables: { portfolioId } });

  const termCurrencyId = user?.org?.homeCurrency?.id;
  const accounts = qAccounts?.data?.accounts || [];
  const currencyIds = unique(accounts.map(a => a?.currency?.id).filter(id => id && id !== termCurrencyId));
  const priceVars = {currencyIds, termCurrencyId};

  const qClientAssets = useApiQuery(getClientAssets, { variables: { orgId } });
  const qIntegrations = useApiQuery(getIntegrations, { variables: { portfolioId } });
  const qPrices = useApiQuery(getCurrentPrices, {variables: priceVars, skip: !currencyIds.length});
  const qActivities = useApiQuery(getPaginatedAccountActivities);

  const [createBuy, mCreateBuy] = useApiMutation(createAccountBuyMutation);
  const [createDeposit, mCreateDeposit] = useApiMutation(createAccountDepositMutation);
  const [createSell, mCreateSell] = useApiMutation(createAccountSellMutation);
  const [createWithdrawal, mCreateWithdrawal] = useApiMutation(createAccountWithdrawalMutation);
  const [createPosition, mCreatePosition] = useApiMutation(createPositionMutation);
  const [updatePosition, mUpdatePosition] = useApiMutation(updatePositionMutation);
  const [deleteActivity, mDeleteActivity] = useApiMutation(deleteAccountActivityMutation);
  const [fetchBalances, mFetchBalances] = useApiMutation(fetchBalancesMutation, {variables: {portfolioId}, refetchQueries: balancesQueries});

  const loading = [
    isLoading(qAccounts, 'accounts'),
    isLoading(qCurrencies, 'currencies'),
    isLoading(qOrg, 'org'),
    isLoading(qPrices, 'currentPrices') && currencyIds.length, // for some reason skipping qPrices leaves qPrices.loading === true
    qIntegrations?.loading,
    mFetchBalances?.loading,
  ].some(l => l);

  const createQueries = [
    {query: getPortfolioCurrencies},
    {query: getClientAssets, variables: {orgId}},
    {query: getAccountsWithBalances},
    {query: getCurrentPrices, variables: priceVars},
  ];

  const getActivityVars = accountId => ({accountId, orderParameter: 'timeExecuted', orderDirection: 'desc', limit: 10, offset: 0});
  const updateQueries = id => [...createQueries, {query: getPaginatedAccountActivities, variables: getActivityVars(id)}];
  const updateArgs = variables => ({variables, awaitRefetchQueries, refetchQueries: updateQueries(variables?.accountId)});

  return (
    <PortfolioPage
      accountsWithBalancesQueryData={             qAccounts?.data}
      accountsWithBalancesQueryError={            qAccounts?.error}
      accountsWithBalancesQueryLoading={          qAccounts?.loading}
      clientAssetsQueryData={                     qClientAssets?.data}
      clientAssetsQueryError={                    qClientAssets?.error}
      clientAssetsQueryLoading={                  qClientAssets?.loading}
      createAccountBuy={                          variables => safeExecAsync(createBuy, { variables })}
      createAccountBuyMutationError={             mCreateBuy?.error}
      createAccountBuyMutationProcessing={        mCreateBuy?.loading}
      createAccountBuyMutationResult={            mCreateBuy?.data}
      createAccountDeposit={                      variables => safeExecAsync(createDeposit, { variables })}
      createAccountDepositMutationError={         mCreateDeposit?.error}
      createAccountDepositMutationProcessing={    mCreateDeposit?.loading}
      createAccountDepositMutationResult={        mCreateDeposit?.data}
      createAccountSell={                         variables => safeExecAsync(createSell, { variables })}
      createAccountSellMutationError={            mCreateSell?.error}
      createAccountSellMutationProcessing={       mCreateSell?.loading}
      createAccountSellMutationResult={           mCreateSell?.data}
      createAccountWithdrawal={                   variables => safeExecAsync(createWithdrawal, { variables })}
      createAccountWithdrawalMutationError={      mCreateWithdrawal?.error}
      createAccountWithdrawalMutationProcessing={ mCreateWithdrawal?.loading}
      createAccountWithdrawalMutationResult={     mCreateWithdrawal?.data}
      createPosition={                            variables => safeExecAsync(createPosition, { variables, awaitRefetchQueries, refetchQueries: createQueries })}
      createPositionMutationError={               mCreatePosition?.error}
      createPositionMutationProcessing={          mCreatePosition?.loading}
      createPositionMutationResult={              mCreatePosition?.data}
      currenciesQueryData={                       qCurrencies?.data}
      currenciesQueryError={                      qCurrencies?.error}
      currenciesQueryLoading={                    qCurrencies?.loading}
      currentPricesQueryData={                    qPrices?.data}
      currentPricesQueryError={                   qPrices?.error}
      currentPricesQueryLoading={                 qPrices?.loading}
      deleteAccountActivity={                     variables => safeExecAsync(deleteActivity, { variables })}
      deleteAccountActivityMutationError={        mDeleteActivity?.error}
      deleteAccountActivityMutationProcessing={   mDeleteActivity?.loading}
      deleteAccountActivityMutationResult={       mDeleteActivity?.data}
      fetchAccountsWithBalances={                 _ => safeExecAsync(qAccounts?.refetch)}
      fetchPaginatedAccountActivities={           variables => safeExecAsync(qActivities?.refetch, { ...variables })}
      fetchBalances={                             _ => safeExecAsync(fetchBalances, { awaitRefetchQueries })}
      fetchBalancesMutationData={                 mFetchBalances?.data}
      fetchBalancesMutationError={                mFetchBalances?.error}
      fetchBalancesMutationProcessing={           mFetchBalances?.loading}
      integrationsQueryData={                     qIntegrations?.data}
      integrationsQueryError={                    qIntegrations?.error}
      integrationsQueryLoading={                  qIntegrations?.loading}
      loading={                                   loading}
      orgQueryData={                              qOrg?.data}
      orgQueryError={                             qOrg?.error}
      orgQueryLoading={                           qOrg?.loading}
      paginatedAccountActivitiesQueryData={       qActivities?.data}
      paginatedAccountActivitiesQueryError={      qActivities?.error}
      paginatedAccountActivitiesQueryLoading={    qActivities?.loading}
      updatePosition={                            variables => safeExecAsync(updatePosition, updateArgs(variables))}
      updatePositionMutationError={               mUpdatePosition?.error}
      updatePositionMutationProcessing={          mUpdatePosition?.loading}
      updatePositionMutationResult={              mUpdatePosition?.data}
      user={                                      user}
    />
  )
}

const PortfolioPageContainer = ({user, ...props}) => user?.canViewPortfolio
  ? <PortfolioPageWithAuth user={user} {...props} /> 
  : <ErrorPage type={ERROR_PERMISSION_DENIED} />

export default PortfolioPageContainer;
