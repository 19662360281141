import gql from 'graphql-tag';

export default gql`
  mutation createAccountWithdrawal(
    $accountId: ID!
    $adjustedCostBasis: Float!
    $quantity: Float!
    $timeExecuted: String!
  ) {
    accountActivity: createAccountWithdrawal(
      accountId: $accountId
      adjustedCostBasis: $adjustedCostBasis
      quantity: $quantity
      timeExecuted: $timeExecuted
    ) {
      id
    }
  }
`;
