const algorithmStrategyDescriptionCopyText = {
  bodyIce: 'The Iceberg algo attempts to divide a single large order into smaller execution units of size equalling the "Display Quantity" without revealing the overall size of the actual order. The algorithm will use the "Limit Price" to determine when to cross the spread or place a resting order on an exchange when an executable price is not available. A display price of zero instructs the algo to not leave resting orders on an exchange but execute available price and amount whenever "Limit Price" is breached.',
  bodyPegger: 'This passive algo will place resting orders that peg the best price (bid price for buy-order and offer price for sell-order) on the exchange with the best price. If launched against multiple exchanges, the passive peg occurs only on the exchange with the best price.  Any unfilled quantity remaining at the end time will cross the book and execute aggressively, unless canceled.',
  bodyPovUpper: 'Percent of Volume Participation is a predominantly passive algorithm that targets a fixed rate of participation in market volume across the selected venues. The algorithm does not impose a time limit. The participation rate parameter controls how fast the algo will execute. The duration is a function of ticket quantity and actual market volume. The algorithm places resting limit orders expected to fill at a target percent of market volume on the selected venues. If the algorithm falls behind its planned volume execution trajectory, it will place aggressive limit orders to catch up.',
  bodyPovLower: 'Percent of Volume Participation is for maintaining a fixed rate of participation in market volume to explicitly control market impact. It’s recommended to be below 1% for minimal impact.',
  bodyPtwap: 'The Passive TWAP algo is a predominately passive algo that balances the predictability of TWAP with the passive execution of Passive VWAP. The algorithm places resting limit orders based on a planned execution schedule that targets an evenly distributed linear trajectory.',
  bodyPvwapLower: 'Passive VWAP is designed specifically for hitting a timed VWAP benchmark. Use this algorithm when best execution is favored over tracking error.',
  bodyPvwapUpper: 'Passive VWAP is predominantly a passive algorithm that favors reducing market impact over limiting tracking error. The algorithm places resting limit orders based on a planned execution trajectory that targets a percent of market volume on the selected venues. The plan for the execution trajectory is an Omniex proprietary fill probability designed to maximize spread capture while achieving a predictable fill profile. If the algorithm falls behind its planned execution trajectory, it will place aggressive limit orders to catch up.',
  bodySor: 'The Smart Order Router algo is designed to optimize the execution of a block order across multiple selected venues, as soon as practically possible, against the best available liquidity while managing for the micro-structures of the crypto market. The Smart Order Router algo requires the input of a limit-rate, which will be the ceiling or floor of a buy or sell order, respectively.',
  bodySpread: "The Spread algo is an aggressive execution algorithm that uses Omniex's proprietary heuristics to execute trades when spreads are narrowing. In market structure terms, compressed spreads often represent opportune times to execute orders for lower execution cost. Spreads are considered compressed when the spread is narrower relative to historical averages.",
  bodySvwapLower: 'Static VWAP is designed specifically for hitting a timed VWAP benchmark. Because it is aggressive, use when minimizing tracking error is more important than best execution.',
  bodySvwapUpper: 'Static VWAP is an aggressive algorithm that favors limiting tracking error over slippage. The algo trajectory is precomputed to match the historic volume seasonality over the execution period. It will not modify its trajectory based on market events.',
  bodyTwap: 'The TWAP algo will execute the specified amount (M) over the specified time period (T) over a number (N) of equally divided trades. This is a simple and reliable algo where N is determined by the system based on order quantity and market venues. The algo will cross the spread on execution every T/N seconds with T/N random delays for each execution to reduce trade predictability.',
  headerIce: 'Iceberg',
  headerPegger: 'Pegger',
  headerPov: 'POV (Percent of Volume)',
  headerPtwap: 'Passive TWAP (Passive Time Weighted Average Price)',
  headerPvwap: 'Passive VWAP (Passive Volume Weighted Average Price)',
  headerSor: 'SOR (Smart Order Router)',
  headerSpread: 'Spread',
  headerSvwap: 'VWAP (Volume Weighted Average Price)',
  headerTwap: 'TWAP (Time Weighted Average Price)',
};

export default algorithmStrategyDescriptionCopyText;
