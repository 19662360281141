import React from 'react';
import { Statistic as Stat } from 'semantic-ui-react';
import styled from 'react-emotion';

import { displayInHomeCurrency } from '../../utils/DisplayUtils';
import { format } from '@omniex/onx-common-js/lib/utils/NumberUtils';
import { isEmpty } from '@omniex/onx-common-js/lib/utils/LangUtils';
import copyText from './PortfolioMarketValueComponent.copyText';
import BaseMessage from '@omniex/onx-common-ui/lib/semantic/react/Message';

// NOTE: The order of these imports matters. Do not change.
import '@omniex/onx-common-ui/lib/semantic/css/message.css';
import '@omniex/onx-common-ui/lib/semantic/css/statistic.css';

const ERROR = 'errorMessage';
const WARNING = 'warningMessage';

const COMPONENT_NAME = 'PortfolioMarketValueComponent';

const Statistic = styled(Stat)`
  display: flex !important;
  margin: 0 !important;
`;

const Message = ({type}) => <BaseMessage content={copyText[type]} error={type === ERROR} warning={type === WARNING} />

const PortfolioMarketValueComponent = ({ positions, homeCurrency, priceLookupTable: prices, titlePrefix, size }) => {
  if (!positions || !positions.length) return <Message type={WARNING} />;
  if (!homeCurrency || !homeCurrency.id || isEmpty(prices)) return <Message type={ERROR} />;

  const total = positions.reduce((total, {quantity = 0, account = {}}) => {
    const { currency, position } = account || {};
    const { liquidationValue, marginAssetId } = position || {};
    const price = (position ? prices[marginAssetId] : prices[currency.id]) || 0;
    const qty = (position ? liquidationValue : quantity) || 0;
    return total + qty * price;
  }, 0);

  return (
    <Statistic className={COMPONENT_NAME} color={total < 0 ? 'red' : 'blue'} size={size}>
      <Stat.Label>{`${titlePrefix ? `${titlePrefix} ` : ''}${copyText.title}`}</Stat.Label>
      <Stat.Value>{displayInHomeCurrency(format(total, '0,0.00'), homeCurrency.symbol)}</Stat.Value>
    </Statistic>
  )
}

export default PortfolioMarketValueComponent
