import { object, shape, string } from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import AssetType from '@omniex/poms-core/lib/enums/AssetType';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './PairPositionsComponent.copyText';
import InstrumentType from '@omniex/onx-poms-entity-helpers/lib/enums/InstrumentType';
import { isNil } from '@omniex/onx-common-js/lib/utils/LangUtils';
import Table, {
  CELL_FORMAT_NUMBER
} from '@omniex/onx-common-ui/lib/semantic/react/Table';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/table.css');

const COMPONENT_NAME = 'PairPositionsComponent';

const StyledDiv = styled('div')`
  display: flex;
  flex-wrap: none;

  .${COMPONENT_NAME}-baseAssetPositionWrapper {
    flex: 1;
    padding: 14px;
  }

  .${COMPONENT_NAME}-termAssetPositionWrapper {
    border-left: 1px solid ${colors.solidBorderColor};
    flex: 1;
    padding: 14px;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

const emptyPosition = {
  currency: {
    symbol: '--',
    type: AssetType.CRYPTO
  },
  quantity: 0
};

const getPosition = (instrument, positions, position) => isNil(instrument)
  ? emptyPosition
  : positions[get(instrument, `${position}.id`)] || {
    currency: get(instrument, position),
    quantity: 0
  };

const posFormat = pos => ({
  [AssetType.FIAT]: '0,0.00',
  [AssetType.CRYPTO]: '0,0.00000000',
  [AssetType.FUTURE]: '0,0.0000',
  [AssetType.OPTION]: '0,0'
})[get(pos, 'currency.type')] || '0,0.00000000';

export default class PairPositionsComponent extends PureComponent {
  static propTypes = {
    instrument: shape({
      baseAsset: shape({
        symbol: string.isRequired,
        type: string.isRequired,
        expiry: string
      }),
      termAsset: shape({
        symbol: string.isRequired,
        type: string.isRequired
      }),
      marginAsset: shape({
        symbol: string,
        type: string
      })
    }),
    positions: object.isRequired
  };

  render() {
    const { instrument, positions } = this.props;

    const basePosition = getPosition(instrument, positions, 'baseAsset');
    const termPosition = getPosition(instrument, positions, 'termAsset');
    const marginPosition = get(instrument, 'type') === InstrumentType.FUTURE
      ? getPosition(instrument, positions, 'marginAsset')
      : null;

    return (
      <StyledDiv className={COMPONENT_NAME}>
        <div className={cn('baseAssetPositionWrapper')}>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell border="none" textAlign="left">
                  {copyText.tableHeaderBaseAsset}
                </Table.HeaderCell>
                <Table.HeaderCell border="none" textAlign="right">
                  {copyText.tableHeaderCurrentQuantity}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell border="none" color={colors.blue} fontSize="1.5em">
                  <span>{getAssetDisplayText(get(basePosition, 'currency')) || '--'}</span>
                </Table.Cell>
                <Table.Cell
                  border="none"
                  color={colors.blue}
                  fontSize="1.5em"
                  fontWeight="bold"
                  format={posFormat(basePosition)}
                  formatType={CELL_FORMAT_NUMBER}
                  ifNotNumber={'0.00000000'}>
                  {basePosition.quantity}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
        <div className={cn('termAssetPositionWrapper')}>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell border="none" textAlign="left">
                  {isNil(marginPosition)
                    ? copyText.tableHeaderTermAsset
                    : copyText.tableHeaderMarginAsset
                  }
                </Table.HeaderCell>
                <Table.HeaderCell border="none" textAlign="right">
                  {copyText.tableHeaderCurrentQuantity}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell border="none" color={colors.blue} fontSize="1.5em">
                  <span>{getAssetDisplayText(get(marginPosition || termPosition, 'currency'), '--')}</span>
                </Table.Cell>
                <Table.Cell
                  border="none"
                  color={colors.blue}
                  fontSize="1.5em"
                  fontWeight="bold"
                  format={posFormat(marginPosition || termPosition)}
                  formatType={CELL_FORMAT_NUMBER}
                  ifNotNumber={'0.00000000'}>
                  {get(marginPosition || termPosition, 'quantity')}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      </StyledDiv>
    );
  }
}
