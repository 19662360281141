import { bool, func } from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import copyText from './TokenExpirationModal.copyText';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/button.css');
require('@omniex/onx-common-ui/lib/semantic/css/modal.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const COMPONENT_NAME = 'TokenExpirationModal';

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

const StyledModal = styled(Modal)`
  .${COMPONENT_NAME}-header {
    display: flex !important;
    flex-direction: row !important;
    font-size: 1.8em !important;
    justify-content: space-between !important;
  }
`;

export default class TokenExpirationModal extends PureComponent {
  static propTypes = {
    show: bool.isRequired,
    onLogout: func,
    onRefreshToken: func
  };

  static defaultProps = {
    onLogout: noop,
    onRefreshToken: noop
  };

  state = {};

  _tick = setInterval(() => {
    const timeLeft =
      this.props.expirationTime - Math.floor(new Date().getTime() / 1000);

    if (timeLeft < 1) {
      this.props.onLogout(`?redirect=${window.location.href}&reason=inactive`);
    }

    this.setState({
      secondsUntilExpiration: timeLeft
    });
  }, 500);

  render() {
    const totalSeconds = this.state.secondsUntilExpiration;

    const minutes =
      Math.floor(totalSeconds / 60) > 0 ? Math.floor(totalSeconds / 60) : 0;

    const seconds =
      totalSeconds - minutes * 60 ? totalSeconds - minutes * 60 : 0;

    return (
      <StyledModal
        size="tiny"
        open={this.props.show}
        onClose={this.props.onRefreshToken}>
        <Modal.Header className={cn('header')}>
          <div>{copyText.title}</div>
          <div>
            {`${minutes < 10 ? `0${minutes}` : minutes}:${
              seconds < 10 ? `0${seconds}` : seconds
            }`}
          </div>
        </Modal.Header>
        <Modal.Content>
          <p>{copyText.timeoutText1}</p>
          <p>{copyText.timeoutText2}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="orange"
            onClick={() => {
              this.props.onLogout(`?redirect=${window.location.href}`);
            }}>
            {copyText.buttonTextLogOut}
          </Button>
          <Button
            color="blue"
            content={copyText.buttonTextLogIn}
            onClick={this.props.onRefreshToken}
          />
        </Modal.Actions>
      </StyledModal>
    );
  }

  componentWillUnmount() {
    clearInterval(this._tick);
  }
}
