import { bool, func, number, oneOf, shape, string } from 'prop-types';
import { Button, Form, Input, Label } from 'semantic-ui-react';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import { isEmpty } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { isValid } from '@omniex/onx-common-js/lib/utils/IdentifierUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './SendQuoteForm.copyText';
import AssetType from '@omniex/poms-core/lib/enums/AssetType';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import OrderSide from '@omniex/onx-poms-entity-helpers/lib/enums/OrderSide';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/button.css');
require('@omniex/onx-common-ui/lib/semantic/css/input.css');
require('@omniex/onx-common-ui/lib/semantic/css/label.css');
require('@omniex/onx-common-ui/lib/semantic/css/form.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');

const MAX_NUMERIC_VALUE = 2e9;

const COMPONENT_NAME = 'SendQuoteForm';

const StyledForm = styled(Form)`
  padding: 0 15px;

  input {
    text-align: right !important;
  }

  .ui.label:nth-of-type(odd) {
    color: ${colors.lightBlack} !important;
  }

  .ui.label:nth-of-type(even) {
    color: ${colors.lightBlack} !important;
    text-align: left;
  }

  .${COMPONENT_NAME}-submitButton {
    padding: 0.76em 1.5em;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class SendQuoteForm extends PureComponent {
  static propTypes = {
    initialBidPrice: number,
    initialOfferPrice: number,
    instrument: shape({
      id: string.isRequired,
      termCurrency: shape({
        id: string.isRequired,
        symbol: string.isRequired,
        type: oneOf(Object.values(AssetType)).isRequired
      })
    }),
    processing: bool,
    requestId: string,
    side: oneOf(Object.values(OrderSide)),
    onSubmit: func
  };

  static defaultProps = { onSubmit: noop };

  state = {};

  static getDerivedStateFromProps(nextProps, prevState) {
    if (isEmpty(prevState)) return getInitialState(nextProps);

    return {};
  }

  render() {
    const termCurrencySymbol = getAssetDisplayText(get(this.props, 'instrument.termCurrency'));

    return isValid(this.props.requestId) ? (
      <StyledForm
        className={COMPONENT_NAME}
        size="tiny"
        onSubmit={this._handleSubmit}>
        <Form.Group>
          {this.props.side !== OrderSide.BUY ? (
            <Form.Field>
              <Input
                name="bidPrice"
                labelPosition="right"
                placeholder={copyText.inputPlaceholder_bidPrice}
                size="small"
                value={this.state.bidPrice}
                onChange={this._handleChangeField}>
                <Label>{copyText.bidLabel}</Label>
                <input />
                <Label basic>{termCurrencySymbol}</Label>
              </Input>
            </Form.Field>
          ) : null}
          {this.props.side !== OrderSide.SELL ? (
            <Form.Field>
              <Input
                name="offerPrice"
                labelPosition="right"
                placeholder={copyText.inputPlaceholder_offerPrice}
                size="small"
                value={this.state.offerPrice}
                onChange={this._handleChangeField}>
                <Label>{copyText.offerLabel}</Label>
                <input />
                <Label basic>{termCurrencySymbol}</Label>
              </Input>
            </Form.Field>
          ) : null}
          <Form.Field>
            <Button
              className={cn('submitButton')}
              color="orange"
              disabled={this.props.processing || !this._canSave()}
              size="tiny"
              type="submit">
              {copyText.submitButtonLabel}
            </Button>
          </Form.Field>
        </Form.Group>
      </StyledForm>
    ) : (
      this._renderErrorMessage()
    );
  }

  _renderErrorMessage(copyTextKey) {
    return (
      <div className={COMPONENT_NAME}>
        <Message content={copyText.errorMessage} error />
      </div>
    );
  }

  _canSave() {
    const bidPrice = parseFloat(this.state.bidPrice);
    const offerPrice = parseFloat(this.state.offerPrice);

    if (this.props.side === OrderSide.SELL) {
      return (
        bidPrice > 0 &&
        bidPrice <= MAX_NUMERIC_VALUE &&
        this.props.initialBidPrice !== bidPrice
      );
    }

    if (this.props.side === OrderSide.BUY) {
      return (
        offerPrice > 0 &&
        offerPrice <= MAX_NUMERIC_VALUE &&
        this.props.initialOfferPrice !== offerPrice
      );
    }

    return (
      bidPrice > 0 &&
      bidPrice <= MAX_NUMERIC_VALUE &&
      offerPrice > 0 &&
      offerPrice <= MAX_NUMERIC_VALUE &&
      (this.props.initialBidPrice !== bidPrice ||
        this.props.initialOfferPrice !== offerPrice)
    );
  }

  _handleChangeField = (event, field) => {
    field.value = field.value.replace(/[^\d.]/g, '');

    if (
      ['bidPrice', 'offerPrice'].includes(field.name) &&
      this.props.instrument.termCurrency.type === AssetType.FIAT &&
      !/^\d*\.?\d{0,2}$/.test(field.value)
    ) {
      return;
    }

    if (
      ['bidPrice', 'offerPrice'].includes(field.name) &&
      !/^\d*\.?\d{0,8}$/.test(field.value)
    ) {
      return;
    }

    this.setState({ [field.name]: field.value });
  };

  _handleSubmit = event => {
    event.preventDefault();

    const prices = {};

    if (this.props.side === OrderSide.SELL) {
      prices.bidPrice = parseFloat(this.state.bidPrice);
    } else if (this.props.side === OrderSide.BUY) {
      prices.offerPrice = parseFloat(this.state.offerPrice);
    } else {
      prices.bidPrice = parseFloat(this.state.bidPrice);
      prices.offerPrice = parseFloat(this.state.offerPrice);
    }

    this.props.onSubmit({
      requestId: this.props.requestId,
      ...prices
    });
  };
}

function getInitialState(props) {
  return {
    ...(Number.isFinite(props.initialBidPrice)
      ? { bidPrice: props.initialBidPrice }
      : { bidPrice: '' }),
    ...(Number.isFinite(props.initialOfferPrice)
      ? { offerPrice: props.initialOfferPrice }
      : { offerPrice: '' })
  };
}
