import { get, has } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { displayInHomeCurrency, getYMDHMString } from './DisplayUtils';
import { getTotalAccountBalance } from '../utils/AccountUtils';
import { isNil } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { toEpochMilliseconds as format } from '@omniex/onx-common-js/lib/utils/DateTimeUtils';
import { truncateNumber } from '@omniex/onx-common-js/lib/utils/NumberUtils';
import AccountType from '@omniex/poms-core/lib/enums/AccountType';
import AssetType from '@omniex/poms-core/lib/enums/AssetType';
import moment from 'moment-timezone';

const SYSTEM_CUSTODIAN_ID = '1';

export const shouldDisplayPosition = (account = {}, balance) => (
  !(account?.currency?.type === AssetType.FUTURE && isNil(account.position)) &&
  typeof balance === 'number' &&
  (balance > 0 || ((account.type === AccountType.FUTURE || account.custodian?.id === SYSTEM_CUSTODIAN_ID) && balance !== 0))
)

const number = n => {
  try {
    n = parseFloat(n);
    if (isNaN(n)) return 0;
    return n;
  } catch (e) {
    return 0;
  }
}

const truncate = (n = 0, d = 2) => (truncateNumber(number(n || 0), d|0) || 0).toFixed(d|0)

export const formatExpiry = date => !isNil(date) ? moment(date).format('MMM D, YYYY') : '';

export const formatExpiryTooltip = date => !isNil(date) ? getYMDHMString(format(date) - Date.now(), 'milliseconds') : '';

export function formatPrice(price = 0) {
  price = number(price);
  const wholeNum = price.toString().split('.')[0];
  const frac = price.toString().split('.')[1];
  price = frac && frac.length >= 2 && wholeNum && wholeNum <= 0 ? price : price.toFixed(2);
  return `$${price}`;
}

export function getLiquidationValue(record, currentPriceLookupTable) {
  const position = record?.position;
  const currentPrice = number(currentPriceLookupTable[position?.marginAssetId]);
  const value = number(position?.liquidationValue);
  return truncate(value * currentPrice, 2);
}

export function getMargin(record) {
  const position = (record || {}).position || {};
  const qty = truncate(position.margin || 0, 4);
  const sym = position.marginAssetSymbol || '';
  const lev = number(position.leverage) > 0 ? ` (${truncate(position.leverage, 2)}x)` : '';

  return `${qty} ${sym}${lev}`;
}

export function getPositions(accounts) {
  if (!Array.isArray(accounts) || accounts.length === 0) return [];

  const positions = accounts.reduce((accum, account) => {
    const balance = getTotalAccountBalance(account)

    if (!shouldDisplayPosition(account, balance)) return accum;

    const existingRecord = accum[get(account, 'currency.id')];

    if (existingRecord) {
      accum[get(account, 'currency.id')] = {
        ...existingRecord,
        quantity: existingRecord.quantity + balance
      };
    } else {
      accum[get(account, 'currency.id')] = {
        currency: account.currency,
        quantity: balance,
        position: has(account, 'position') ? account.position : undefined,
        venueName: get(account, 'custodian.name')
      };
    }

    return accum;
  }, {});

  return Object.values(positions);
}

export function getUnrealizedPnL(record) {
  const position = (record || {}).position || {};
  const pnl = truncate(position.unrealizedPnL, 4);
  const sym = position.marginAssetSymbol || '';
  const pct = truncate(position.unrealizedRoePcnt, 2);
  return `${pnl} ${sym} (${pct}%)`;
}

export function getValue(record) {
  const position = (record || {}).position || {};
  const qty = truncate(position.baseValue, 4);
  const sym = position.baseCurrencySymbol || '';//"baseCurrencySymbol" = right ("y") half of x/y instrument master symbol
  return `${qty} ${sym}`;
}

export function getValueTooltip(record, homeCurrency) {
  const position = (record || {}).position || {};
  const notionalValue = truncate(position.notionalValue, 2);
  const symbol = (homeCurrency || {}).symbol || '';
  return `Notional: ${displayInHomeCurrency(notionalValue, symbol)}`;
}

const positionUtils = {
  shouldDisplayPosition,
  formatExpiry,
  formatExpiryTooltip,
  getLiquidationValue,
  getMargin,
  getPositions,
  getUnrealizedPnL,
  getValue,
  getValueTooltip
};

export default positionUtils;
