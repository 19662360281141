const tradePageMarketDepthSectionCopy = {
  errorMessageConfigureMarketDataFailed:
    'Sorry, an unexpected error occurred while trying to configure your market data feed. Please try again.',
  errorMessageNoMarketDataAvailable:
    'Sorry, there is no market data available for the specified instrument at this time. Please select a different instrument or try again later.',
  defaultVenueOptionLabel: 'All Venues',
  inputPlaceholder_instrument: 'Instrument',
  inputPlaceholder_venue: 'Venues',
  loadingMessage: 'Loading...',
  sectionTitle: 'Market Depth',
  warningMessage: 'Please select an instrument to display market data.'
};

export default tradePageMarketDepthSectionCopy;
