import gql from 'graphql-tag';

export default gql`
  mutation createPosition(
    $accountId: ID!
    $assetId: ID!
    $assetName: String
    $assetSymbol: String
    $custodianId: ID!
    $positionQuantity: Float!
    $price: Float!
  ) {
    position: createPosition(
      accountId: $accountId
      assetId: $assetId
      assetName: $assetName
      assetSymbol: $assetSymbol
      custodianId: $custodianId
      positionQuantity: $positionQuantity
      price: $price
    ) {
      assetId
      accountId
      activityId
      price
      quantity
    }
  }
`;
