import {
  arrayOf,
  bool,
  func,
  noop,
  number,
  objectOf,
  shape,
  string
} from 'prop-types';
import { Icon, Popup } from 'semantic-ui-react';
import React, { Fragment, PureComponent } from 'react';
import styled from 'react-emotion';

import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { displayInHomeCurrency, getPriceFormat } from '../../utils/DisplayUtils';
import { isNearZero } from '@omniex/onx-common-js/lib/utils/NumberUtils';
import { sortBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import {
  formatExpiry,
  formatExpiryTooltip,
  formatPrice,
  getLiquidationValue,
  getMargin,
  getUnrealizedPnL,
  getValue,
  getValueTooltip
} from '../../utils/PositionUtils';
import AssetType from '@omniex/poms-core/lib/enums/AssetType';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './PortfolioPositionsTable.copyText';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import Table, {
  CELL_FORMAT_MONEY,
  CELL_FORMAT_NUMBER,
  CELL_STYLE_SUBHEADER
} from '@omniex/onx-common-ui/lib/semantic/react/Table';

const WARNING_MESSAGE_KEY_NO_CRYPTO_POSITIONS =
  'warningMessageNoCryptoPositions';
const WARNING_MESSAGE_KEY_NO_FIAT_POSITIONS = 'warningMessageNoFiatPositions';
const WARNING_MESSAGE_KEY_NO_FUTURE_POSITIONS =
  'warningMessageNoFuturePositions';
const WARNING_MESSAGE_KEY_NO_POSITIONS = 'warningMessageNoPositions';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');
require('@omniex/onx-common-ui/lib/semantic/css/table.css');

const COMPONENT_NAME = 'PortfolioPositionsTable';

const StyledTable = styled(Table)`
  .${COMPONENT_NAME}-negative, .${COMPONENT_NAME}-short{
    color: ${colors.red};
  }

  table.ui.small.single.line.unstackable.very.basic.very.compact.table.PortfolioPositionsTable {
    > tr.${COMPONENT_NAME}-subheader {
      color: red;
    }
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

const MIN_QUANTITY = 0.0000000001;
const SHORT_SUFFIX = 'SHORT';

export default class PortfolioPositionsTable extends PureComponent {
  static propTypes = {
    currentPriceLookupTable: objectOf(number),
    homeCurrency: shape({ id: string.isRequired }),
    onDashboardPage: bool,
    positions: arrayOf(
      shape({
        currency: shape({
          id: string.isRequired,
          name: string.isRequired,
          symbol: string.isRequired,
          type: string.isRequired
        }).isRequired,
        quantity: number.isRequired
      })
    ),
    renderExpandedContent: func
  };

  static defaultProps = { renderExpandedContent: noop };

  state = {};

  render() {

    let [cryptoRecords, fiatRecords, futureRecords] = this._getRecords().reduce(
      (records, record) => {
        const assetType = get(record, 'currency.type');

        switch (assetType) {
          case AssetType.CRYPTO: {
            records[0].push(record);
            break;
          }
          case AssetType.FIAT: {
            records[1].push(record);
            break;
          }
          case AssetType.FUTURE: {
            records[2].push(record);
            break;
          }
          default: {
            break;
          }
        }

        return records;
      },
      [[], [], []]
    );

    cryptoRecords = sortBy(cryptoRecords, 'currency.symbol');
    fiatRecords = sortBy(fiatRecords, 'currency.symbol');
    futureRecords = sortBy(futureRecords, ['currency.name', 'venueName']);

    if (
      cryptoRecords.length === 0 &&
      fiatRecords.length === 0 &&
      futureRecords.length === 0
    ) {
      return this._renderWarningMessage(WARNING_MESSAGE_KEY_NO_POSITIONS);
    }

    return (
      <>
        <Table className={COMPONENT_NAME}>
          <Table.Body>
            <Table.Row className={cn('subheader')}>
              <Table.HeaderCell>{copyText.subheaderCrypto}</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                {copyText.tableHeaderQuantity}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                {copyText.tableHeaderCurrentPrice}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                {copyText.tableHeaderCurrentMarketValue}
              </Table.HeaderCell>
            </Table.Row>

            {this._renderRecords(cryptoRecords, AssetType.CRYPTO)}
            <Table.Row className={cn('subheader')}>
              <Table.HeaderCell>{copyText.subheaderFiat}</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                {copyText.tableHeaderQuantity}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                {copyText.tableHeaderCurrentPrice}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                {copyText.tableHeaderCurrentMarketValue}
              </Table.HeaderCell>
            </Table.Row>
            {this._renderRecords(fiatRecords, AssetType.FIAT)}
          </Table.Body>
        </Table>

        {futureRecords.length > 0
          ? this._renderFutureSection(futureRecords, AssetType.FUTURE)
          : null}
      </>
    );
  }

  _renderFutureSection(records, type) {
    return (
      <StyledTable className={cn('future')}>
        <Table.Body>
          <Table.Row>
            <Table.HeaderCell styleName={CELL_STYLE_SUBHEADER}>
              {copyText.subheaderFuture}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="left">
              {copyText.tableHeaderExpiration}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              {copyText.tableHeaderQuantity}
            </Table.HeaderCell>
            {this.props.onDashboardPage === true ? null : (
              <Table.HeaderCell textAlign="right">
                {copyText.tableHeaderValue}
              </Table.HeaderCell>
            )}
            {this.props.onDashboardPage === true ? null : (
              <Table.HeaderCell textAlign="right">
                {copyText.tableHeaderEntryPrice}
              </Table.HeaderCell>
            )}
            <Table.HeaderCell textAlign="right">
              {copyText.tableHeaderMarkPrice}
            </Table.HeaderCell>
            {this.props.onDashboardPage === true ? null : (
              <Table.HeaderCell textAlign="right">
                {copyText.tableHeaderLiquidationPrice}
              </Table.HeaderCell>
            )}
            <Table.HeaderCell textAlign="right">
              {copyText.tableHeaderMargin}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              {copyText.tableHeaderUnrealizedPnL}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              {copyText.tableHeaderLiquidationValue}
            </Table.HeaderCell>
          </Table.Row>
          {this._renderRecords(records, type)}
        </Table.Body>
      </StyledTable>
    );
  }

  _getRecords() {
    return this.props.positions.map(position => ({
      ...position,
      quantity: isNearZero(position.quantity, MIN_QUANTITY)
        ? 0
        : position.quantity
    }));
  }

  _renderRecords(records, type) {
    if (records.length === 0) {
      let key;

      switch (type) {
        case AssetType.CRYPTO: {
          key = WARNING_MESSAGE_KEY_NO_CRYPTO_POSITIONS;
          break;
        }
        case AssetType.FIAT: {
          key = WARNING_MESSAGE_KEY_NO_FIAT_POSITIONS;
          break;
        }
        case AssetType.FUTURE: {
          key = WARNING_MESSAGE_KEY_NO_FUTURE_POSITIONS;
          break;
        }
        default: {
          key = WARNING_MESSAGE_KEY_NO_POSITIONS;
        }
      }

      return this._renderWarningMessage(key);
    }

    return records.map(record =>
      type === AssetType.FUTURE
        ? this._renderFutureRecord(record)
        : this._renderCryptoOrFiatRecord(record, type === AssetType.FIAT)
    );
  }

  _renderCryptoOrFiatRecord(record, isFiat) {
    let currentPrice = this.props.currentPriceLookupTable[record.currency.id];
    const quantity = record.quantity;

    return (
      <Fragment key={record.currency.id}>
        <Table.Row
          onClick={
            this.props.renderExpandedContent
              ? evt => this._handleClickExpandRow(evt, record.currency.id)
              : undefined
          }>
          <Table.Cell padding="0px 0px 0px 10px">
            {!this.props.renderExpandedContent ? null : (
              <Icon
                name={`triangle ${
                  this.state.expandedCurrencyId === record.currency.id
                    ? 'down'
                    : 'right'
                }`}
              />
            )}
            <span>{record?.currency?.symbol || ''}</span>
          </Table.Cell>
          <Table.Cell
            formatType={CELL_FORMAT_NUMBER}
            format={isFiat ? '0,0.00' : '0,0.00000000'}>
            {record.quantity}
          </Table.Cell>
          <Table.Cell
            format={getPriceFormat(currentPrice)}
            formatType={CELL_FORMAT_MONEY}
            homecurrency={this.props.homeCurrency}>
            {currentPrice}
          </Table.Cell>
          <Table.Cell formatType={CELL_FORMAT_MONEY} homecurrency={this.props.homeCurrency}>
            {quantity * currentPrice}
          </Table.Cell>
        </Table.Row>
        {this.state.expandedCurrencyId === record.currency.id ? (
          <Table.Row>
            <Table.Cell border="none" colSpan={5} padding={30}>
              {this.props.renderExpandedContent(record.currency, currentPrice)}
            </Table.Cell>
          </Table.Row>
        ) : null}
      </Fragment>
    );
  }

  _renderFutureRecord(record) {
    const { position } = record;
    return position && (
      <Fragment key={record.currency.id}>
        <Table.Row
          onClick={
            this.props.renderExpandedContent
              ? evt => this._handleClickExpandRow(evt, record.currency.id)
              : undefined
          }>
          <Table.Cell padding="0px 0px 0px 10px">
            {!this.props.renderExpandedContent ? null : (
              <Icon
                name={`triangle ${
                  this.state.expandedCurrencyId === record.currency.id
                    ? 'down'
                    : 'right'
                }`}
              />
            )}
            <span>{(record?.currency?.name || '').replace(SHORT_SUFFIX,'')}</span>
            {(record?.currency?.name || '').includes(SHORT_SUFFIX) && <span className={cn('short')}>{SHORT_SUFFIX}</span>}
            <span>{record.venueName ? ` (${record.venueName})` : ''}</span>
          </Table.Cell>
          <Table.Cell textAlign="left">
            <Popup
              content={formatExpiryTooltip(record.currency.expiry)}
              inverted
              position="top center"
              trigger={<span className={cn('expiration')}>{formatExpiry(record.currency.expiry)}</span>}
              wide
            />
          </Table.Cell>
          <Table.Cell
            textAlign="right"
            className={position.quantity < 0 ? cn('negative') : null}>
            {position.quantity}
          </Table.Cell>
          {this.props.onDashboardPage === true ? null : (
            <Table.Cell textAlign="right">
              <Popup
                content={getValueTooltip(record, this.props.homeCurrency)}
                inverted
                position="top left"
                trigger={<span className={cn('value')}>{getValue(record)}</span>}
              />
            </Table.Cell>
          )}
          {this.props.onDashboardPage === true ? null : (
            <Table.Cell textAlign="right">
              {formatPrice(get(record, 'position.entryPrice'))}
            </Table.Cell>
          )}
          <Table.Cell textAlign="right">
            {formatPrice(get(record, 'position.markPrice'))}
          </Table.Cell>
          {this.props.onDashboardPage === true ? null : (
            <Table.Cell textAlign="right">
              {formatPrice(get(record, 'position.liquidationPrice'))}
            </Table.Cell>
          )}
          <Table.Cell textAlign="right">{getMargin(record)}</Table.Cell>
          <Table.Cell
            textAlign="right"
            className={position.unrealizedPnL < 0 ? cn('negative') : null}>
            {getUnrealizedPnL(record)}
          </Table.Cell>
          <Table.Cell textAlign="right">
            {displayInHomeCurrency(
              getLiquidationValue(record, this.props.currentPriceLookupTable),
              this.props.homeCurrency?.symbol
            )}
          </Table.Cell>
        </Table.Row>
        {this.state.expandedCurrencyId === record.currency.id ? (
          <Table.Row>
            <Table.Cell border="none" colSpan={10}>
              {this.props.renderExpandedContent(
                record.currency,
                position.markPrice
              )}
            </Table.Cell>
          </Table.Row>
        ) : null}
      </Fragment>
    );
  }

  _renderWarningMessage(copyTextKey) {
    return copyTextKey === WARNING_MESSAGE_KEY_NO_POSITIONS ? (
      <div className={COMPONENT_NAME}>
        <Message
          className={cn('warningMessage')}
          content={copyText[copyTextKey]}
          warning
        />
      </div>
    ) : (
      <Table.Row>
        <Table.Cell colSpan={11}>
          <Message
            className={cn('warningMessage')}
            content={copyText[copyTextKey]}
            warning
          />
        </Table.Cell>
      </Table.Row>
    );
  }

  _handleClickExpandRow(event, currencyId) {
    this.setState(currentState => ({
      expandedCurrencyId:
        currentState.expandedCurrencyId === currencyId ? undefined : currencyId
    }));
  }
}
