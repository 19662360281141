/* eslint no-sequences: 0 */
import React, { useState } from 'react'

import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Box from '@material-ui/core/Box'
import GetAppIcon from '@material-ui/icons/GetApp'
import Switch from '@material-ui/core/Switch'
import { withStyles } from '@omniex/poms-ui/lib/themes'

import { defaultSort, generateTable, getCollapsibleRows, visibleColumns, csv } from './utils'

import Table, { Table2, Table3 } from './Table'
import GroupControls from './GroupControls'

import { useReportClasses } from './styles'

const StyledSwitch = withStyles({
  switchBase: {
    '&$checked': {
      transform: 'translateX(55%)',
    },
    '&$checked + $track': {
      opacity: 1,
    },
  },
  thumb: {
    color: 'white',
  },
  checked: {},
  track: {},
})(Switch)

// temporary, for debugging purposes
const RawDataViewer = ({ summary, tickets }) => {
  const [showData, setShowData] = useState(false)
  return (
    <div>
      <Button variant="outlined" onClick={() => setShowData(!showData)}>
        {`${showData ? 'Hide' : 'Show'} Data`}
      </Button>
      {showData && <pre>{JSON.stringify({ summary, tickets }, undefined, 2)}</pre>}
    </div>
  )
}


const ReportTable = ({ summary = {}, tickets = [], instruments = {}, startDate, endDate }) => {
  const pathOptions = [{ category: 'algo', value: 'algo' }, { category: 'instrument', value: 'instrument' },
  { category: 'side', value: 'side' }, { category: 'order_status', value: 'order_status' }, { category: 'interval', value: 'day' },
  { category: 'interval', value: 'month' }, { category: 'interval', value: 'year' }, { category: 'interval', value: 'quarter' }]
  const [rawPath, setRawPath] = useState([])
  const [showData, setShowData] = useState(true)
  const [sortCol, setSortCol] = useState(defaultSort.col)
  const [sortDir, setSortDir] = useState(defaultSort.dir)
  const [filters, setFilters] = useState({})
  const [collapsedRows, setCollapsedRows] = useState([])

  const resetCollapsedRows = _ => setCollapsedRows([])
  const resetPath = _ => setPath([])
  const resetFilter = c => setFilters({ ...filters, [c]: [] })
  const resetFilters = _ => setFilters({})
  const resetSorting = _ => setSortCol(defaultSort.col) || setSortDir(defaultSort.dir)
  const resetAll = _ => {
    resetCollapsedRows()
    resetPath()
    resetFilters()
    resetSorting()
  }

  const cls = useReportClasses()

  if (!Array.isArray(tickets) || !tickets.length) return null

  const path = rawPath.map(p => p.value)

  const { rows, visibleRows, cols, filterOptions } = generateTable({ tickets, instruments, path, filters, sortCol, sortDir, showData, collapsedRows })

  const setFilter = c => f => setFilters({ ...filters, [c]: f })

  const collapseAllRows = () => setCollapsedRows(getCollapsibleRows(rows, path, showData))
  const collapsibleRows = getCollapsibleRows(rows, path, showData)
  const allRowsCollapsed = collapsibleRows.length === collapsedRows.length

  const headerColProps = { sortCol, setSortCol, sortDir, setSortDir, filters, setFilter, filterOptions }

  const noPath = !path.length
  const noFilters = Object.values(filters).reduce((fs, f) => fs && !f.length, true)
  const noSorting = !sortDir || !sortCol || (sortDir === defaultSort.dir && sortCol === defaultSort.col)
  const noChanges = noPath && noSorting && noFilters
  const numChanges = noChanges ? 0 : [noPath, noSorting, noFilters].reduce((n, c) => n + (c ? 0 : 1), 0)
  const resetButtons = [
    [resetPath, 'Groups', noPath],
    [resetFilters, 'Filters', noFilters],
    [resetSorting, 'Sorting', noSorting],
    [resetAll, 'All', numChanges <= 1],
  ]

  const filterFilters = okCols => Object.keys(filters).forEach(k => filters[k].length && !okCols.includes(k) && resetFilter(k))

  const setPath = newPath => {
    const vc = visibleColumns(newPath.map(p => p.value), showData)
    filterFilters(vc)
    if (sortDir && sortCol && !vc.includes(sortCol)) resetSorting()
    setRawPath(newPath)
  }

  const toggleData = _ => {
    if (showData) filterFilters(visibleColumns(path, !showData))
    setShowData(!showData)
    const newCollapsibleRows = getCollapsibleRows(rows, path, !showData)
    setCollapsedRows(collapsedRows.filter(r => newCollapsibleRows.includes(r)))
  }

  const download = _ => csv(rows, path, showData, startDate, endDate)

  return (
    <div className={cls.root}>
      <div className={cls.controls}>
        <div className={cls.left}>
          <GroupControls path={rawPath} setPath={setPath} options={pathOptions} setCollapsedRows={setCollapsedRows} />
        </div>
        <div className={cls.right}>
          {collapsibleRows.length > 0 && (
            <div className={cls.reset}>
              <Box mr={1} color="text.secondary" fontSize="caption.fontSize">ROWS</Box>
              <ButtonGroup size="small">
                {collapsedRows.length > 0 && <Button classes={{ label: cls.buttonLabel }} color="primary" onClick={resetCollapsedRows}>EXPAND ALL</Button>}
                {!allRowsCollapsed && <Button classes={{ label: cls.buttonLabel }} color="primary" onClick={collapseAllRows}>COLLAPSE ALL</Button>}
              </ButtonGroup>
            </div>
          )}
          {!noPath && (
            <div className={cls.toggleData}>
              <Box mr={1} color="text.secondary" fontSize="caption.fontSize">DISPLAY ORDERS</Box>
              <StyledSwitch size="small" color="primary" checked={showData} onChange={toggleData} />
            </div>
          )}
          {!noChanges && (
            <div className={cls.reset}>
              <Box mr={1} color={noChanges ? 'text.disabled' : 'text.secondary'} fontSize="caption.fontSize">RESET</Box>
              <ButtonGroup size="small">
                {resetButtons.map(([oc, l, d]) => !d && <Button key={l} classes={{ label: cls.buttonLabel }} color="primary" onClick={oc}>{l}</Button>)}
              </ButtonGroup>
            </div>
          )}
          {rows && rows.length && (
            <div className={cls.download}>
              <Button classes={{ label: cls.buttonLabel }} size="small" variant="outlined" color="primary" startIcon={<GetAppIcon color="primary" />} onClick={download}>CSV</Button>
            </div>
          )}
        </div>
      </div>
      {false && <Table cols={cols} rows={rows} path={path} headerColProps={headerColProps} showData={showData} />}
      {false && <Table2 cols={cols} rows={rows} path={path} headerColProps={headerColProps} showData={showData} />}
      {true && <Table3 cols={cols} rows={visibleRows} path={path} headerColProps={headerColProps} showData={showData} collapsedRows={collapsedRows} setCollapsedRows={setCollapsedRows} />}
      {false && <RawDataViewer summary={summary} tickets={tickets} />}
    </div>
  )
}

export default ReportTable
