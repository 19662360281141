import { Button, Icon, Popup } from 'semantic-ui-react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import styled from 'react-emotion';

import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { opaque } from '@omniex/onx-common-ui/lib/colors/utils';
import { sortBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './AccountDetailTable.copyText';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import Table from '@omniex/onx-common-ui/lib/semantic/react/Table';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/button.css');
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');
require('@omniex/onx-common-ui/lib/semantic/css/table.css');
require('@omniex/onx-common-ui/lib/semantic/css/popup.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const COMPONENT_NAME = 'AccountDetailTable';

// prettier-ignore
const StyledTable = styled(Table)`
  margin-left: -14px 14px !important;
  max-width: 1000px;
  padding-bottom: 14px;
  padding-left: 14px;

  .${COMPONENT_NAME}-warningMessage {
    margin-top: 5px !important;
  }

  .${COMPONENT_NAME}-icon {
    color: ${colors.blue} !important;
    font-size: 1em !important;

    :hover {
      color: ${opaque(colors.blue, 0.8)} !important;
      cursor: pointer;
    }
  }

  .${COMPONENT_NAME}-buttonWrapper {
    display: flex;
    justify-content: flex-end;
    margin-right: 14px;
    margin-top: 14px;
  }

  .${COMPONENT_NAME}-accountInstructionFormWrapper {
    display: flex;
    justify-content: flex-end;
  }

  textarea {
    border-color: ${colors.internalBorderColor};
    width: 100%;
  }

  tr {
    td, th {
      &:nth-child(4) {
        width: 100px !important;
      }

      &:nth-child(5) {
        width: 100px !important;
      }
    }
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

const popupStyle = {
  fontSize: '0.9em',
  padding: '8px 16px'
};

export default class AccountDetailTable extends PureComponent {
  static propTypes = {
    editingInstructionId: string,
    instructionIdEditing: string,
    isFiat: bool,
    renderExpandedAccountInstructionForm: func,
    selectCreate: func,
    selectCreateXPub: func,
    selectEdit: func,
    showCreateInstructionForm: bool,
    showXPubInstructionForm: bool,
    wallet: shape({
      currency: shape({
        id: string,
        symbol: string
      }),
      id: string.isRequired,
      name: string.isRequired,
      instructions: arrayOf(
        shape({
          id: string.isRequired,
          freeForm: string,
          walletAddress: string,
          xpubKey: string,
          xpubParentId: string
        })
      )
    }),
    onDeleteInstruction: func,
    onSaveInstruction: func,
    onUpdateInstruction: func
  };

  static defaultProps = {
    renderExpandedAccountInstructionForm: noop,
    selectCreate: noop,
    selectCreateXPub: noop,
    selectEdit: noop,
    onDeleteInstruction: noop,
    onSaveInstruction: noop,
    onUpdateInstruction: noop
  };

  state = {
    editingInstructionId: null,
    showAccountInstructionForm: false
  };

  render() {
    const instructions = sortBy(
      get(this.props, 'wallet.instructions', []),
      'timeCreated'
    );

    const symbol = get(this.props, 'wallet.currency.symbol');
    const showXPubButton = ['BTC', 'ETH'].includes(symbol) && !this.props.isFiat;

    return (
      <StyledTable className={COMPONENT_NAME}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell border="none">
              {this.props.isFiat
                ? copyText.tableHeaderAccountNumber
                : copyText.tableHeaderWalletAddress}
            </Table.HeaderCell>
            {this.props.isFiat &&
              <Table.HeaderCell border="none">
                {copyText.tableHeaderIBAN}
              </Table.HeaderCell>
            }
            <Table.HeaderCell border="none">
              {copyText.tableHeaderDescription}
            </Table.HeaderCell>
            <Table.HeaderCell border="none" textAlign="center">
              {copyText.tableHeaderUpdate}
            </Table.HeaderCell>
            <Table.HeaderCell border="none" textAlign="center">
              {copyText.tableHeaderDelete}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {get(this.props, 'wallet.instructions.length', 0) === 0
            ? this._renderWarningMessage()
            : instructions.map(instruction => this._renderRow(instruction))}
          <Table.Row>
            <Table.Cell border="none" colSpan={this.props.isFiat ? 5 : 4} padding="0">
              {(this.props.showCreateInstructionForm || this.props.showXPubInstructionForm) ? (
                <div className={cn('accountInstructionFormWrapper')}>
                  {this.props.renderExpandedAccountInstructionForm({
                    wallet: this.props.wallet,
                    isXPub: this.props.showXPubInstructionForm
                  })}
                </div>
              ) : (
                <div className={cn('buttonWrapper')}>
                  <Button color="blue" size='tiny' onClick={this._handleClickAddNewInstructionButton}>
                    {this.props.isFiat ? copyText.addAccountButtonLabel : copyText.addAddressButtonLabel}
                  </Button>
                  {showXPubButton && (
                    <Button color="blue" size='tiny' onClick={this._handleClickAddXPubButton}>
                      {copyText.addXPubButtonLabel}
                    </Button>
                  )}
                </div>
              )}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </StyledTable>
    );
  }

  _renderRow(instruction) {
    if (instruction.xpubParentId)
      return null;

    return (
      <Fragment key={instruction.id}>
        <Table.Row>
          <Table.Cell border="none">
            {this.props.isFiat
              ? instruction.freeForm
              : instruction.xpubKey || instruction.walletAddress}
          </Table.Cell>
          {this.props.isFiat &&
            <Table.Cell border="none">
            {instruction.iban || copyText.notAvailable}
          </Table.Cell>}
          <Table.Cell border="none">
            {instruction.description || copyText.notAvailable}
          </Table.Cell>
          <Table.Cell border="none" textAlign="center">
            <Icon
              className={cn('icon')}
              name="edit"
              onClick={this._handleClickEditIcon.bind(this, instruction.id)}
            />
          </Table.Cell>
          <Table.Cell border="none" textAlign="center">
            <Popup
              content={
                <Fragment>
                  <p style={{ marginTop: '10px' }}>
                    {copyText.popupDeleteWarningMessage}
                  </p>
                  <Button
                    color="orange"
                    content={copyText.proceedButtonLabel}
                    fluid
                    size="small"
                    onClick={this._handleClickDelete.bind(this, instruction.id)}
                  />
                </Fragment>
              }
              header={copyText.popupDeleteWarningHeader}
              hideOnScroll
              on="click"
              open={this.state.popupRowId === instruction.id}
              position="left center"
              size="tiny"
              style={popupStyle}
              trigger={<Icon name="trash" className={cn('icon')} />}
              onClose={this._handlePopupClose.bind(this, instruction.id)}
              onOpen={this._handlePopupOpen.bind(this, instruction.id)}
            />
          </Table.Cell>
        </Table.Row>
        {this.props.instructionIdEditing === instruction.id ? (
          <Table.Row>
            <Table.Cell colSpan={4} padding="0" border="none">
              <div className={cn('accountInstructionFormWrapper')}>
                {this.props.renderExpandedAccountInstructionForm({
                  wallet: this.props.wallet,
                  instruction,
                  isXPub: !!instruction.xpubKey
                })}
              </div>
            </Table.Cell>
          </Table.Row>
        ) : null}
      </Fragment>
    );
  }

  _renderWarningMessage() {
    return (
      <tr>
        <td className={COMPONENT_NAME} colSpan={4}>
          <Message
            className={cn('warningMessage')}
            content={copyText.warningMessage.replace(
              '%account%',
              this.props.isFiat ? copyText.account : copyText.wallet
            )}
            warning
          />
        </td>
      </tr>
    );
  }

  _handleClickEditIcon = id => {
    this.props.selectEdit(this.props.editingInstructionId === id ? null : id);
  };

  _handleClickAddNewInstructionButton = () => {
    this.props.selectCreate(
      this.props.showCreateInstructionForm ? false : true
    );
  };

  _handleClickAddXPubButton = () => {
    this.props.selectCreateXPub(!this.props.showXPubInstructionForm);
  };

  _handleClickDelete = id => {
    this.props.onDeleteInstruction(id);
  };

  _handlePopupOpen(id) {
    this.setState({ popupRowId: id });
  }

  _handlePopupClose() {
    this.setState({ popupRowId: undefined });
  }
}
