const tradePageExecutionSectionCopy = {
  errorMessageCloseActiveQuoteSessionFailed:
    'Sorry, we encountered an issue while attempting to close this RFQ session. Please try again.',
  errorMessageConfigureMarketDataFailed:
    'Sorry, an unexpected error occurred while trying to configure your market data feed. Please try again.',
  errorMessageCreateEspTileFailed:
    'Sorry, we encountered an issue while attempting to create your esp tile. Please try again.',
  errorMessageNoMarketDataAvailable:
    'Sorry, there is no market data available for the specified instrument at this time. Please select a different instrument or try again later.',
  errorMessagePlaceOrderForAlgorithmicExecutionFailed__outgoingAccounts:
    'Sorry, your organization does not have any %currencySymbol% accounts for the selected venue(s). Please try again.',
  errorMessagePlaceOrderForAlgorithmicExecutionFailed:
    'Sorry, we encountered an issue while attempting to place an order for algorithmic execution. Please try again.',
  errorMessagePlaceOrderForQuoteFailed:
    'Sorry, we encountered an issue while attempting to place an order for this quote. Please try again.',
  errorMessagePlaceOrderForSpecificExchangeFailed:
    'Sorry, we encountered an issue while attempting to place an order for this quote. Please try again.',
  errorMessageStartQuoteSessionFailed:
    'Sorry, we encountered an issue while attempting to initiate your RFQ session. Please try again.',
  loadingMessage: 'Loading...',
  menuItemLabelAlgo: 'ALGO',
  menuItemLabelDma: 'DMA',
  menuItemLabelEsp: 'ESP',
  menuItemLabelOtc: 'OTC',
  menuItemLabelRfq: 'RFQ',
  sectionTitle: 'Execution',
  successMessageOrderForQuotePlaced:
    'The RFQ order %fixClOrdID% was placed. See blotter below for full details.',
  warningMessageDma:
    'Please select an instrument and venue to see order book data.',
  warningMessageRfq:
    'Please complete the form to the left to begin an RFQ session.'
};

export default tradePageExecutionSectionCopy;
