import { useQuery, useMutation } from 'react-apollo';
import React from 'react';

import { ERROR_PERMISSION_DENIED } from '@omniex/onx-common-ui/lib/errors';
import { filterMap } from '@omniex/poms-core/lib/utils/FunctionUtils';
import { safeExecAsync } from '../utils/FunctionUtils';
import { unique } from '@omniex/onx-common-js/lib/utils/ArrayUtils';
import DashboardPage from '../ui/pages/DashboardPage';
import ErrorPage from '@omniex/onx-common-ui/lib/pages/ErrorPage';
import getAccountsWithBalances from '../apollo/graphql/getAccountsWithBalances';
import getCurrencyStatistics from '../apollo/graphql/getCurrencyStatistics';
import getCurrentPrices from '../apollo/graphql/getCurrentPrices';
import getOrg from '../apollo/graphql/getOrg';
import getPortfolioCurrencies from '../apollo/graphql/getPortfolioCurrencies';
import getRecentInputHistory from '../apollo/graphql/getRecentInputHistoryByPortfolioId';
import getTopList from '../apollo/graphql/getTopList';
import updateRecentInputHistory from '../apollo/graphql/updateRecentInputHistoryByPortfolioId';

const DashboardPageWithAuth = ({apiClient, user, portfolio = {} }) => {
  const orgId = user?.org?.id
  const useApiQuery = (q, o = {}) => useQuery(q, { client: apiClient, ...o })

  const portfolioId = portfolio?.id
  const qCurrencies = useApiQuery(getPortfolioCurrencies, { skip: !portfolioId, variables: { portfolioId } })
  const qOrg = useApiQuery(getOrg, { skip: !orgId })
  const qAccounts = useApiQuery(getAccountsWithBalances, { skip: !portfolioId, variables: { portfolioId } })

  const notHomeCurrency = a => a?.currency?.id !== qOrg?.data?.org?.homeCurrency?.id
  const toCurrencyId = a => a?.currency?.id
  const currencyIds = unique(filterMap(qAccounts?.data?.accounts || [], notHomeCurrency, toCurrencyId))

  const qPrices = useApiQuery(getCurrentPrices, {
    skip: !qOrg?.data?.org,
    variables: { currencyIds, termCurrencyId: qOrg?.data?.org?.homeCurrency?.id },
  })

  const qTopList = useApiQuery(getTopList)
  const qCurrencyStats = useApiQuery(getCurrencyStatistics, { fetchPolicy: 'network-only' })
  const qInputHistory = useApiQuery(getRecentInputHistory, { variables: { portfolioId }})

  const [updateInputHistory, ] = useMutation(updateRecentInputHistory, { client: apiClient })

  return (
    <DashboardPage
      accountsWithBalancesQueryData={qAccounts?.data}
      accountsWithBalancesQueryError={qAccounts?.error}
      accountsWithBalancesQueryLoading={qAccounts?.loading}
      currenciesQueryData={qCurrencies?.data}
      currenciesQueryError={qCurrencies?.error}
      currenciesQueryLoading={qCurrencies?.loading}
      currentPricesQueryData={qPrices?.data}
      currentPricesQueryError={qPrices?.error}
      currentPricesQueryLoading={qPrices?.loading}
      currencyStatisticsQueryData={qCurrencyStats?.data}
      currencyStatisticsQueryLoading={qCurrencyStats?.loading}
      currencyStatisticsQueryError={qCurrencyStats?.error}
      fetchCurrencyStatistics={args => safeExecAsync(qCurrencyStats?.refetch, args)}
      orgQueryData={qOrg?.data}
      orgQueryError={qOrg?.error}
      orgQueryLoading={qOrg?.loading}
      recentInputHistoryQueryData={qInputHistory.data}
      topListQueryData={qTopList?.data}
      topListQueryError={qTopList?.error}
      topListQueryLoading={qTopList?.loading}
      updateRecentInputHistory={({ inputs: userInput }) => safeExecAsync(updateInputHistory, { variables: { inputs: { portfolioId, userInput }}})}
    />
  )
}

const DashboardPageContainer = ({user, ...props}) => user?.canViewDashboard
  ? <DashboardPageWithAuth user={user} {...props} /> 
  : <ErrorPage type={ERROR_PERMISSION_DENIED} />

export default DashboardPageContainer
