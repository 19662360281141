import { AxiosResponse } from 'axios';
import contentDisposition from 'content-disposition';
import { format as formatDate } from '@omniex/poms-core/lib/utils/DateTimeUtils';

const getCsvFilename = (reportName: string): string => 
  `${reportName} ${formatDate(
    Date.now(),
    null,
    'YYYYMMDDHHmmss'
  )}.csv`.replace(/\s/g, '-');

const triggerFileDownload = ({ data, headers }: AxiosResponse, reportName: string) => {
  const disposition = contentDisposition.parse(headers['content-disposition'] || '')

  if (disposition.type !== 'attachment') {
     throw new Error("No attachment from server")
  }

  const filename = disposition.parameters?.filename || getCsvFilename(reportName)
  const type = headers['content-type']
  const blob = new Blob([data], { type })
  const blobURL = window.URL.createObjectURL(blob)
  const tempLink = document.createElement('a')

  tempLink.style.display = 'none'
  tempLink.href = blobURL
  tempLink.setAttribute('download', filename)

  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank')
  }

  document.body.appendChild(tempLink)
  tempLink.click()

  document.body.removeChild(tempLink)
  window.URL.revokeObjectURL(blobURL)
};

export default triggerFileDownload;
