import gql from 'graphql-tag';

export default gql`
  query getUsers($orgId: ID!) {
    users: getUsers(orgId: $orgId) {
      id
      name
      timeCreated
      org {
        id
        name
        type
      }
      roles {
        id
        status
        type
      }
      portfolios {
        id
        name
        active
      }
      status
      username
    }
  }
`;
