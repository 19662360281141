import { arrayOf, func, shape, string } from 'prop-types';
import { Checkbox, Icon, Loader, Message, Popup } from 'semantic-ui-react';
import React, { Fragment, PureComponent } from 'react';
import styled from 'react-emotion';

import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { hasRoleType } from '@omniex/onx-poms-entity-helpers/lib/utils/UserUtils';
import { isEmpty } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { orderBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import copyText from './UserRolesTable.copyText';
import Table from '@omniex/onx-common-ui/lib/semantic/react/Table';
import UserRoleStatus from '@omniex/onx-poms-entity-helpers/lib/enums/UserRoleStatus';
import UserRoleType from '@omniex/onx-poms-entity-helpers/lib/enums/UserRoleType';
import UserStatus from '@omniex/onx-poms-entity-helpers/lib/enums/UserStatus';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/loader.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');
require('@omniex/onx-common-ui/lib/semantic/css/table.css');
require('@omniex/onx-common-ui/lib/semantic/css/checkbox.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const MILLIS_DELAY = 200;

const COMPONENT_NAME = 'UserRolesTable';

const StyledTable = styled(Table)`
  .${COMPONENT_NAME}-headerCell {
    &.hidden {
      width: 30% !important;
    }

    &.show {
      width: 6% !important;
    }
  }

  .${COMPONENT_NAME}-toggle {
    margin-right: 20px;
  }

  .${COMPONENT_NAME}-loaderWrapper {
    padding: 4px 3px 3px 20px;
  }

  .${COMPONENT_NAME}-loader {
    align-items: center;
    display: flex;
  }

  .${COMPONENT_NAME}-portfoliosTableContainer {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

const isAdmin = user => hasRoleType(user, UserRoleType.ADMIN);

export default class UserRolesTable extends PureComponent {
  static propTypes = {
    users: arrayOf(
      shape({
        id: string.isRequired,
        name: string.isRequired,
        roles: arrayOf(
          shape({
            id: string.isRequired,
            status: string.isRequired,
            type: string.isRequired
          })
        ),
        status: string.isRequired,
        username: string.isRequired
      })
    ),
    onUpdate: func
  };

  static defaultProps = { onUpdate: noop };

  state = {};

  static getDerivedStateFromProps(nextProps, prevState) {
    if (isEmpty(prevState)) return getInitialState(nextProps);

    const stateChanges = {};

    if (prevState.result !== nextProps.result) {
      stateChanges.result = nextProps.result;
    }

    if (prevState.userIds.includes(get(stateChanges, 'result.id'))) {
      stateChanges.userIds = prevState.userIds.filter(
        id => id !== stateChanges.result.id
      );
    }

    return stateChanges;
  }

  render() {
    const users = orderBy(this.props.users, 'name');

    return (
      <StyledTable className={COMPONENT_NAME}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{copyText.tableHeaderName}</Table.HeaderCell>
            <Table.HeaderCell>{copyText.tableHeaderUsername}</Table.HeaderCell>
            <Table.HeaderCell>{copyText.tableHeaderPortfolios}</Table.HeaderCell>
            <Table.HeaderCell>{copyText.tableHeaderStatus}</Table.HeaderCell>
            <Table.HeaderCell className={cn(`headerCell ${this.props.show ? 'show' : 'hidden'}`)} />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {users.map(user => (
            <Fragment key={user.id}>
              <Table.Row onClick={this._handleClickExpandRow.bind(this, user.id)}>
                <Table.Cell>
                  <Icon name={`triangle ${this.state.expandedUserId === user.id ? 'down' : 'right'}`} />
                  {user.name}
                </Table.Cell>
                <Table.Cell>{user.username}</Table.Cell>
                {isAdmin(user) ? (
                  <Table.Cell>&mdash;</Table.Cell>
                ) : user.portfolios?.length ? (
                  <Popup
                    flowing
                    closeOnTriggerBlur
                    inverted
                    mouseEnterDelay={MILLIS_DELAY}
                    mouseLeaveDelay={MILLIS_DELAY}
                    position="top left"
                    trigger={<Table.Cell>{user.portfolios.length}</Table.Cell>}
                  >
                    {orderBy(user.portfolios, 'id').map(p => <div key={p.id}>{`${p.name}${p.active ? '' : ' (inactive)'}`}</div>)}
                  </Popup>
                ) : (
                  <Table.Cell>0</Table.Cell>
                )}
                <Table.Cell
                  className={cn('statusCell')}
                  content={
                    isAdmin(user) ? (
                      copyText.cellLabelAdmin
                    ) : !this.state.userIds.includes(user.id) ? (
                      <Checkbox
                        className={cn('toggle')}
                        checked={user.status === UserStatus.ACTIVE}
                        toggle
                        onClick={this._handleClickStatus.bind(this, user.id)}
                      />
                    ) : (
                      <div className={cn('loaderWrapper')}>
                        <Loader
                          className={cn('loader')}
                          active={this.state.userIds.includes(user.id)}
                          inline
                          size="tiny"
                        />
                      </div>
                    )
                  }
                />
                <Table.Cell />
              </Table.Row>
              {this.state.expandedUserId === user.id ? (
                <Table.Row>
                  <Table.Cell border="none" colSpan={2} padding={20} verticalAlign="top">
                    {this._renderRoles(user.roles)}
                  </Table.Cell>
                  <Table.Cell border="none" colSpan={1} verticalAlign="top" className={cn('portfoliosTableContainer')}>
                    <Table>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>{copyText.tableHeaderPortfolios}</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>{isAdmin(user) ? (
                        <Table.Row>
                          <Table.Cell>&mdash;</Table.Cell>
                        </Table.Row>
                      ) : orderBy(user?.portfolios || [], 'id').map(p => (
                        <Table.Row key={p.id}>
                          <Table.Cell>{`${p.name}${p.active ? '' : ' (inactive)'}`}</Table.Cell>
                        </Table.Row>
                      ))}</Table.Body>
                    </Table>
                  </Table.Cell>
                </Table.Row>
              ) : null}
            </Fragment>
          ))}
        </Table.Body>
      </StyledTable>
    );
  }

  _renderRoles(roles) {
    const activeRoles = orderBy(
      roles.filter(role => role.status === UserRoleStatus.ACTIVE),
      'type',
      'asc'
    );

    if (activeRoles.length === 0) return this._renderWarningMessage();

    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{copyText.tableHeaderRole}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {activeRoles.map(role => (
            <Table.Row key={role.id}>
              <Table.Cell>{copyText[`roleTypeLookup_${role.type}`]}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }

  _renderWarningMessage() {
    return (
      <div>
        <Message
          className={COMPONENT_NAME}
          content={copyText.warningMessage}
          warning
        />
      </div>
    );
  }

  _handleClickExpandRow = userId => {
    this.setState(currentState => ({
      expandedUserId:
        currentState.expandedUserId === userId ? undefined : userId
    }));
  };

  _handleClickStatus = (userId, event) => {
    event.preventDefault();
    event.stopPropagation();

    const selectedUser = this.props.users.find(user => user.id === userId);

    this.setState(currentState => ({
      userIds: [...currentState.userIds, userId]
    }));

    this.props.onUpdate({
      userId,
      status:
        selectedUser.status === UserStatus.ACTIVE
          ? UserStatus.INACTIVE
          : UserStatus.ACTIVE
    });
  };
}

function getInitialState(props) {
  return { userIds: [] };
}
