import gql from 'graphql-tag';

export default gql`
  query getCsvData(
    $orderParameter: String!
    $orderDirection: String!
    $dataType: String
    $endDate: String
    $startDate: String
  ) {
    data: getCsvData(
      orderParameter: $orderParameter
      orderDirection: $orderDirection
      dataType: $dataType
      endDate: $endDate
      startDate: $startDate
    ) {
      fills {
        id
        feeCost
        feeCurrency {
          id
        }
        fixExecID
        quantity
        price
        timeCreated
        specifiedCurrency {
          id
        }
        venue {
          id
          name
        }
        order {
          id
          algorithmDuration
          algorithmStrategy
          feature
          fixQuoteID
          specifiedQuantity
          instrument {
            id
            type
          }
          side
          type
          fixOrderID
          fixClOrdID
          fixVenueOrderID
        }
      }
      orders {
        id
        algorithmDuration
        algorithmStrategy
        calculatedQuantityFilled
        calculatedAverageFillPrice
        calculatedTotalFees
        customOrderId
        displayQuantity
        expiryType
        feature
        feeCost
        feeCurrency {
          id
        }
        fixCancellationClOrdID
        fixClientID
        fixClOrdID
        fixOrderID
        fixQuoteID
        fixVenueOrderID
        hypePrice
        instrument {
          id
          type
        }
        limitPrice
        participationRate
        reasonCancellationRejected
        reasonRejected
        side
        source
        specifiedCurrency {
          id
        }
        specifiedQuantity
        status
        stopPrice
        timeCreated
        timeLastUpdated
        trader {
          id
          username
        }
        type
        venue {
          id
          name
        }
        venueNames
        venueNamesSelected
      }
      settlements {
        id
        allocations {
          id
          currency {
            id
          }
          fixToCompID
          fromAccount {
            id
            name
          }
          fromInstruction {
            id
            freeForm
            iban
            walletAddress
          }
          quantity
          toAccount {
            id
            name
          }
          toInstruction {
            id
            freeForm
            iban
            walletAddress
          }
        }
        fixAllocID
        initiatorOrg {
          id
          name
        }
        respondentOrg {
          id
          name
        }
        status
        timeCreated
      }
      overflow
      maxRows
    }
  }
`;
