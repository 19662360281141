const unclaimedQuoteRequestsTableCopy = {
  cancelButtonLabel: 'Cancel',
  claimButtonLabel: 'Claim',
  label2Way: '2-WAY',
  tableHeaderClient: 'Client ID',
  tableHeaderInstrument: 'Instrument',
  tableHeaderQuantity: 'Quantity',
  tableHeaderSide: 'Side',
  tableHeaderTimestamp: 'Timestamp',
  warningMessage: 'There are no active quote requests to claim at this time.'
};

export default unclaimedQuoteRequestsTableCopy;
