import { number, shape, string } from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';

import { format } from '@omniex/onx-common-js/lib/utils/NumberUtils';
import { get } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import { getPriceFormat } from '../../utils/DisplayUtils';
import { isEmpty } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './TopOfBookComponent.copyText';
import AssetType from '@omniex/poms-core/lib/enums/AssetType';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/message.css');

const COMPONENT_NAME = 'TopOfBookComponent';

const StyledDiv = styled('div')`
  display: flex;
  flex-direction: row;
  font-size: 0.8em;
  margin: 0;

  .${COMPONENT_NAME}-bidWrapper {
    flex: 1;
    justify-content: flex-end;
    padding: 14px;
    text-align: center;
  }

  .${COMPONENT_NAME}-bidHeader {
    text-align: center;

    span {
      text-transform: uppercase;
    }
  }

  .${COMPONENT_NAME}-bidPrice {
    color: ${colors.blue};
    font-size: 1.3em;
    font-weight: bold;
    text-align: center;
  }

  .${COMPONENT_NAME}-bidQuantity {
    text-align: center;
  }

  .${COMPONENT_NAME}-spreadWrapper {
    border-left: 1px solid ${colors.internalBorderColor};
    border-right: 1px solid ${colors.internalBorderColor};
    flex: 1;
    margin: 0;
    max-width: 160px;
    padding: 14px;
    text-align: center;
  }

  .${COMPONENT_NAME}-spread {
    font-size: 1.3em;
    font-weight: bold;
  }

  .${COMPONENT_NAME}-offerWrapper {
    flex: 1;
    padding: 14px;
    text-align: center;
  }

  .${COMPONENT_NAME}-offerHeader {
    text-align: center;

    span {
      text-transform: uppercase;
    }
  }

  .${COMPONENT_NAME}-offerPrice {
    color: ${colors.orange};
    font-size: 1.3em;
    font-weight: bold;
    text-align: center;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class TopOfBookComponent extends PureComponent {
  static propTypes = {
    bid: shape({
      id: string.isRequired,
      fixMDStreamID: string.isRequired,
      price: number.isRequired,
      type: string.isRequired,
      quantity: number.isRequired,
      venueSymbol: string.isRequired,
    }),
    offer: shape({
      id: string.isRequired,
      fixMDStreamID: string.isRequired,
      price: number.isRequired,
      type: string.isRequired,
      quantity: number.isRequired,
      venueSymbol: string.isRequired,
    }),
    instrument: shape({
      id: string.isRequired,
      displayName: string.isRequired,
      baseAsset: shape({
        id: string.isRequired,
        type: string.isRequired
      }),
      termAsset: shape({
        id: string.isRequired,
        type: string.isRequired
      })
    }),
  };

  static defaultProps = {};

  render() {
    const bidPrice = get(this.props.bid, 'price', 0);
    const offerPrice = get(this.props.offer, 'price', 0);

    const bidVenue = get(this.props.venueMap, `${get(this.props, 'bid.venueSymbol')}.name`, 'N/A');
    const offerVenue = get(this.props.venueMap, `${get(this.props, 'offer.venueSymbol')}.name`, 'N/A');

    const instrumentName = get(this.props, 'instrument.displayName', '');
    const baseCurrencySymbol = getAssetDisplayText(get(this.props, 'instrument.baseAsset'));
    const termCurrencySymbol = getAssetDisplayText(get(this.props, 'instrument.termAsset'));

    const isFiatBase =
      get(this.props, 'instrument.baseAsset.type', '') === AssetType.FIAT;
    const isFiatTerm =
      get(this.props, 'instrument.termAsset.type', '') === AssetType.FIAT;

    if (
      isEmpty(this.props.bid) ||
      isEmpty(this.props.offer) ||
      !Number.isFinite(bidPrice) ||
      !Number.isFinite(offerPrice)
    ) {
      return (
        <div className={cn(COMPONENT_NAME)}>
          <Message content={copyText.warningMessage} warning />
        </div>
      );
    }

    return (
      <StyledDiv className={COMPONENT_NAME}>
        <div className={cn('bidWrapper')}>
          <div className={cn('bidHeader')}>
            {copyText.headingBestBid}
            <span>{bidVenue}</span>
          </div>
          <div className={cn('bidPrice')}>
            {`${format(
              bidPrice,
              getPriceFormat(bidPrice)
            )} ${termCurrencySymbol}`}
          </div>
          <div className={cn('bidQuantity')}>
            {`${format(
              this.props.bid.quantity,
              isFiatBase ? '0.00' : '0.00000000'
            )} ${baseCurrencySymbol}`}
          </div>
        </div>
        <div className={cn('spreadWrapper')}>
          <div className={cn('spreadHeader')}>
            {`${instrumentName} ${copyText.headingSpread}`}
          </div>
          <div className={cn('spread')}>
            {format(
              offerPrice - bidPrice,
              getPriceFormat(offerPrice - bidPrice)
            )}
          </div>
          <div className={cn('midPrice')}>
            {`${format(
              bidPrice + (offerPrice - bidPrice) / 2,
              isFiatTerm ? '0.00' : '0.00000000'
            )} ${termCurrencySymbol}`}
          </div>
        </div>
        <div className={cn('offerWrapper')}>
          <div className={cn('offerHeader')}>
            {copyText.headingBestOffer}
            <span>{offerVenue}</span>
          </div>
          <div className={cn('offerPrice')}>
            {`${format(
              offerPrice,
              getPriceFormat(offerPrice)
            )} ${termCurrencySymbol}`}
          </div>
          <div className={cn('offerQuantity')}>
            {`${format(
              this.props.offer.quantity,
              isFiatBase ? '0.00' : '0.00000000'
            )} ${baseCurrencySymbol}`}
          </div>
        </div>
      </StyledDiv>
    );
  }
}
