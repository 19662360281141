import * as React from 'react';
import orderCopyText from '../OrderBlotterTable.copyText';
//@ts-ignore
import { colors } from '@omniex/onx-common-ui/lib/styles';
import { formatQuantity, getAvgFillPrice, getFees, getQuantityFilled, getQuantityOrdered } from '../../../utils/OrderUtils';
import { displayNotification } from '../../../utils/OrderNotificationUtils';
import { getDateFormat, getDateFormatSimple, formatPrice } from '../../../utils/DisplayUtils';
import OrderIDPopup from '../OrderIDPopupComponent';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Portal from '@material-ui/core/Portal';
import Tooltip from '@material-ui/core/Tooltip'
import WarningIcon from '@material-ui/icons/Warning';
import fillCopyText from '../FillBlotterTable.copyText';
import { Fill, Order } from '../../../types';
import { isOrderActive, formatDuration, getVenueName, TABLE_HEADER_HEIGHT, ROW_HEIGHT, removeTrailingZeroes } from './shared';
import User from '../../../utils/AuthUtils';
import moment from 'moment';
import FindInPage from '@material-ui/icons/FindInPage';
import Button from '@material-ui/core/Button';
import OrderStatusPopup from './OrderStatusPopup';
import { MultiGridColumnData } from '../multigrid';
import { format as formatNumber } from '@omniex/poms-core/lib/utils/NumberUtils';
import { getExecutionType } from '@omniex/poms-core/lib/utils/OrderUtils';
import ORDER_STATUS from '@omniex/poms-core/lib/enums/OrderStatus';
import ORDER_TYPE from '@omniex/poms-core/lib/enums/OrderType';

const TimeString: React.FC<{cellData: string}> = ({
  cellData,
}) => cellData ? (
  <Tooltip title={getDateFormat(cellData)}>
    <span>
      {getDateFormatSimple(cellData, true)}
    </span>
  </Tooltip>
) : (
  <span>{orderCopyText.noData}</span>
);

const Venue: React.FC<{cellData: {name: string, names: Array<string> | null}}> = ({
  cellData: { name, names },
}) => {
  if (name !== "MULTIPLE" || !names) return <span>{name}</span>;
  const title = (
    <List disablePadding dense>
      {names.map(n => (
        <ListItem key={n}>
          <ListItemText primary={n} />
        </ListItem>
      ))}
    </List>
  )
  return (
    <Tooltip title={title}>
      <span>
        {name}
      </span>
    </Tooltip>
  )
}

const Portfolio: React.FC<{cellData: {name: string}}> = ({ cellData: { name } }) => (
  <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%'}}>
    {name}
  </div>
)

const QuantityWithInstrument: React.FC<{ cellData: string }> = ({
  cellData,
}) => {
  const MAX_VISIBLE = 19;
  const indexOfFirstSpace = cellData.indexOf(' ');
  let numberStr = removeTrailingZeroes(cellData.slice(0, indexOfFirstSpace));
  const instrumentStr = cellData.slice(indexOfFirstSpace);
  const charactersLeft = MAX_VISIBLE - instrumentStr.length;
  if (numberStr.length <= charactersLeft) return <span>{numberStr}{instrumentStr}</span>;
  numberStr = (cellData[charactersLeft - 4] === '.' ? cellData.slice(0, charactersLeft - 4) : cellData.slice(0, charactersLeft - 3)) + '...';

  return (
    <Tooltip title={cellData}>
      <span>
        {`${numberStr}${instrumentStr}`}
      </span>
    </Tooltip>
  );
}

type CancelButtonProps = {
  cancelContainer: HTMLDivElement
  onCancel: (order: Order) => void
  order: Order,
  rowIndex: number,
  parent: any,
}

const CancelButton: React.FC<CancelButtonProps> = ({
  cancelContainer,
  onCancel,
  order,
  rowIndex,
  parent,
}) => {
  // this is truly beautiful /s
  const scrollTop = parent.state?.scrollTop ?? 0;
  // const rowsScrolled = Math.floor(scrollTop / ROW_HEIGHT);
  // if (rowsScrolled > rowIndex || rowsScrolled + PAGE_SIZE <= rowIndex) return null;
  const rect = cancelContainer.getBoundingClientRect();
  const left = rect.right - 100;
  const top = (TABLE_HEADER_HEIGHT + rect.top + rowIndex * ROW_HEIGHT) - (scrollTop - 2);
  return (
    <Portal container={cancelContainer}>
      <Button
        disabled={[
          ORDER_STATUS.CANCELLATION_REQUESTED,
          ORDER_STATUS.PENDING_CANCEL
        ].includes(order.status)}
        onClick={() => onCancel(order)}
        style={{ backgroundColor: 'orange', position: 'fixed', height: ROW_HEIGHT, left, top, zIndex: 1 }}
        size='small'
      >
        {orderCopyText.cancelOrderButtonLabel}
      </Button>
    </Portal>
  );
}


export function getOrderColumns(
  cancelContainer: React.RefObject<HTMLDivElement>,
  checkIsOrderCancellable: (order: Order) => boolean,
  onCancel: (order: Order) => void,
  onInspect: (order: Order) => void,
  hasOrgEnabledCustomId: boolean,
  hasOrdersFromMultiPortfolios: boolean,
  user: User,
) {
  const columns: Array<MultiGridColumnData<Order>> = [
    {
      dataKey: 'id',
      label: '',
      width: 80,
      render: ({ rowData, isVisible, parent, rowIndex }) => {
        const notifications = (rowData?.notifications || []).filter(n => displayNotification(user, n, 'blotter'));
        return (
          <>
            {(notifications.length > 0) ? (
              <IconButton onClick={() => onInspect?.(rowData)} size='small'>
                <WarningIcon style={{color: colors.red}} />
              </IconButton>
            ) : (
              <div style={{padding: '14px'}}/>
            )}
            <IconButton onClick={() => onInspect?.(rowData)} size='small'>
              <FindInPage />
            </IconButton>
            {isVisible && checkIsOrderCancellable(rowData) && cancelContainer.current && parent && (
              <CancelButton
                cancelContainer={cancelContainer.current}
                onCancel={onCancel}
                order={rowData}
                rowIndex={rowIndex}
                parent={parent}
              />
            )}
          </>
        );
      }
    },
    {
      dataKey: 'timeCreated',
      clickable: true,
      label: orderCopyText.tableHeaderTimestamp,
      width: 140,
      render: TimeString
    },
    {
      dataKey: 'timeLastUpdated',
      clickable: true,
      label: orderCopyText.tableHeaderTimeLastUpdated,
      width: 140,
      cellDataGetter: ({ rowData }) => rowData.timeLastUpdated ?? rowData.timeCreated,
      render: TimeString,
    },
    {
      dataKey: 'fixOrderID',
      label: orderCopyText.tableHeaderOrderID,
      width: 120,
      render: ({ rowData }) => (
        <OrderIDPopup
          fixClOrdID={rowData.fixClOrdID}
          fixOrderID={rowData.fixOrderID}
          fixQuoteID={rowData.fixQuoteID}
          fixVenueOrderID={rowData.feature !== 'ALGO' ? rowData.fixVenueOrderID : undefined}
          source={rowData.source}
          venue={rowData.venue}
        />
      ),
    },
    {
      dataKey: 'traderId',
      clickable: true,
      label: orderCopyText.tableHeaderTraderName,
      width: 160,
      cellDataGetter: ({ rowData }) => rowData.trader?.username || orderCopyText.noData,
    },
    {
      dataKey: 'status',
      clickable: true,
      label: orderCopyText.tableHeaderStatus,
      width: 160,
      render: ({ rowData }) => <OrderStatusPopup order={rowData} />,
    },
    {
      dataKey: 'executionType',
      clickable: true,
      label: orderCopyText.tableHeaderExecutionType,
      width: 165,
      cellDataGetter: ({ rowData }) => getExecutionType(rowData)
    },
    {
      dataKey: 'side',
      clickable: true,
      label: orderCopyText.tableHeaderSide,
      width: 95,
    },
    {
      dataKey: 'instrumentId',
      label: orderCopyText.tableHeaderInstrument,
      width: 160,
      cellDataGetter: ({ rowData }) => rowData.instrument.displayName
    },
    {
      dataKey: 'specifiedQuantity',
      label: orderCopyText.tableHeaderQuantityOrdered,
      width: 180,
      cellDataGetter: ({ rowData }) => getQuantityOrdered(rowData),
      render: QuantityWithInstrument,
      numeric: true,
    },
    {
      dataKey: 'quantityFilled',
      label: orderCopyText.tableHeaderQuantityFilled,
      width: 180,
      cellDataGetter: ({ rowData }) => getQuantityFilled(rowData),
      render: QuantityWithInstrument,
      numeric: true,
    },
    {
      dataKey: 'venue',
      label: orderCopyText.tableHeaderVenue,
      width: 160,
      cellDataGetter: ({ rowData }) => ({ name: getVenueName(rowData), names: rowData.venueNames?.length ? rowData.venueNames : rowData.venueNamesSelected }),
      render: Venue
    },
    {
      dataKey: 'averageFillPrice',
      label: orderCopyText.tableHeaderAverageFillPrice,
      width: 180,
      cellDataGetter: ({ rowData }) => getAvgFillPrice(rowData),
      render: QuantityWithInstrument,
      numeric: true,
    },
    {
      dataKey: 'type',
      clickable: true,
      label: orderCopyText.tableHeaderOrderType,
      width: 140,
      cellDataGetter: ({ rowData }) => {
        if (rowData.type === ORDER_TYPE.PREVIOUSLY_QUOTED) {
         return orderCopyText.statusQuoted
        } else if (rowData.feature === 'ALGO' && rowData.type === ORDER_TYPE.STOP_LOSS) {
          return orderCopyText.stopMarket
        } else {
          return rowData.type.replaceAll('_', ' ')
        }
      }
    },
    {
      dataKey: 'expiryType',
      clickable: true,
      label: orderCopyText.tableHeaderTimeInForce,
      width: 80
    },
    {
      dataKey: 'limitPrice',
      clickable: true,
      label: orderCopyText.tableHeaderLimitPrice,
      width: 180,
      cellDataGetter: ({ rowData }) => rowData.limitPrice
        ? `${formatPrice(rowData.limitPrice)} ${rowData.instrument.termAsset.symbol}`
        : orderCopyText.noData,
      render: QuantityWithInstrument,
      numeric: true,
    },
    {
      dataKey: 'stopPrice',
      clickable: true,
      label: orderCopyText.tableHeaderStopPrice,
      width: 180,
      cellDataGetter: ({ rowData }) => rowData.stopPrice
        ? `${formatPrice(rowData.stopPrice)} ${rowData.instrument.termAsset.symbol}`
        : orderCopyText.noData,
      render: QuantityWithInstrument,
      numeric: true,
    },
    {
      dataKey: 'algorithmDuration',
      clickable: true,
      label: orderCopyText.algoRequestedDuration,
      width: 180,
      cellDataGetter: ({ rowData }) => rowData.algorithmDuration ? formatDuration(moment.duration(rowData.algorithmDuration, 'seconds')) : orderCopyText.noData,
    },
    {
      dataKey: 'algorithmActualDuration',
      label: orderCopyText.algoActualDuration,
      width: 180,
      cellDataGetter: ({ rowData }) => {
        const isActive = isOrderActive(rowData);
        if (!isActive && !rowData.timeLastUpdated) return orderCopyText.noData;
        const createdAt = moment(rowData.timeCreated);
        const completedAt = isActive ? moment() : moment(rowData.timeLastUpdated);
        return formatDuration(moment.duration(completedAt.diff(createdAt))) || orderCopyText.noData;
      }
    },
    {
      dataKey: 'feeCost',
      label: orderCopyText.tableHeaderTotalFees,
      width: 180,
      cellDataGetter: ({ rowData }) => getFees(rowData),
      render: QuantityWithInstrument,
      numeric: true,
    },
    {
      dataKey: 'displayQuantity',
      label: orderCopyText.tableHeaderDisplayQuantity,
      width: 180,
      cellDataGetter: ({ rowData }) => rowData.displayQuantity ? formatQuantity(rowData.specifiedCurrency, rowData.displayQuantity) : orderCopyText.noData,
      render: QuantityWithInstrument,
      numeric: true,
    },
    {
      dataKey: 'participationRate',
      clickable: true,
      label: orderCopyText.tableHeaderParticipationRate,
      width: 180,
      cellDataGetter: ({ rowData }) => rowData.participationRate != null
        ? formatNumber(
            `${rowData.participationRate * 100}`,
            '0,0.00'
          ) + ' %'
        : orderCopyText.noData,
      numeric: true,
    },
    {
      // this is so cancel button does not obscure last column
      dataKey: 'paddingColumn',
      label: '',
      width: 120,
      cellDataGetter: () => '',
    }
  ];

  if(hasOrdersFromMultiPortfolios) columns.splice(3, 0, {
    dataKey: 'portfolio',
    label: orderCopyText.portfolio,
    width: 160,
    render: Portfolio,
  })

  if (hasOrgEnabledCustomId) columns.splice(3, 0, {
    dataKey: 'customOrderId',
    label: orderCopyText.tableHeaderCustomOrderId,
    width: 140,
  });

  return columns;
}

type PickedFillKeys = Pick<Fill, 'timeCreated' | 'fixExecID' | 'venue' | 'quantity' | 'price' | 'feeCost'>

export function getFillColumns(includeAllColumns: boolean) {
  const shared: Record<keyof PickedFillKeys, MultiGridColumnData<Fill>> = {
    timeCreated: {
      dataKey: 'timeCreated',
      clickable: true,
      label: fillCopyText.tableHeaderTimeExecuted,
      width: 140,
      render: TimeString
    },
    fixExecID: {
      dataKey: 'fixExecID',
      clickable: true,
      label: fillCopyText.tableHeaderExecID,
      width: 300
    },
    venue: {
      dataKey: 'venueId',
      label: fillCopyText.tableHeaderVenue,
      width: 160,
      cellDataGetter: ({ rowData }) => rowData.venue.name
    },
    quantity: {
      dataKey: 'quantity',
      clickable: true,
      label: fillCopyText.tableHeaderQuantityFilled,
      width: 180,
      cellDataGetter: ({ rowData }) => formatQuantity(rowData.specifiedCurrency, rowData.quantity),
      render: QuantityWithInstrument,
      numeric: true,
    },
    price: {
      dataKey: 'price',
      clickable: true,
      label: fillCopyText.tableHeaderFillPrice,
      width: 180,
      cellDataGetter: ({ rowData }) => `${formatPrice(rowData.price)} ${rowData.order.instrument.termAsset.symbol}`,
      render: QuantityWithInstrument,
      numeric: true,
    },
    feeCost: {
      dataKey: 'feeCost',
      clickable: true,
      label: fillCopyText.tableHeaderFeeCost,
      width: 180,
      cellDataGetter: ({ rowData }) => rowData.feeCost
        ? `${formatPrice(rowData.feeCost)} ${rowData.feeCurrency?.symbol || ''}`
        : fillCopyText.noData,
      render: QuantityWithInstrument,
      numeric: true,
    }
  }

  if (!includeAllColumns) return [
    shared.timeCreated, shared.fixExecID, shared.venue, shared.quantity, shared.price, shared.feeCost
  ];

  const columns: Array<MultiGridColumnData<Fill>> = [
    shared.timeCreated,
    {
      dataKey: 'fixClOrdID',
      clickable: true,
      label: fillCopyText.tableHeaderOrderID,
      render: ({ rowData }) => (
        <OrderIDPopup
          fixClOrdID={rowData.order.fixClOrdID}
          fixOrderID={rowData.order.fixOrderID}
          fixQuoteID={rowData.order.fixQuoteID}
          source='INTERNAL'
          venue={rowData.venue}
        />
      ),
      width: 120,
    },
    shared.fixExecID,
    {
      dataKey: 'executionType',
      clickable: true,
      label: fillCopyText.tableHeaderExecutionType,
      width: 180,
      cellDataGetter: ({ rowData }) => getExecutionType(rowData.order)
    },
    shared.venue,
    {
      dataKey: 'instrumentId',
      label: fillCopyText.tableHeaderInstrument,
      width: 160,
      cellDataGetter: ({ rowData }) => rowData.order.instrument.displayName
    },
    {
      dataKey: 'side',
      clickable: true,
      label: fillCopyText.tableHeaderSide,
      width: 100,
      cellDataGetter: ({ rowData }) => rowData.order.side
    },
    shared.quantity,
    shared.price,
    shared.feeCost,
  ]

  return columns;
}
