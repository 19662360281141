export const defaultDiffOptions = {
  includeFuncs: false, // if false, changes in function references are ignored
  maxDepth: 30, // max depth of recursive calls, used to improve performance on deeply nested comparisons
  full: false, // if false, only the first change is returned, and no further comparisons are made
  checkCircular: true, // if true, circular references will be detected and result in a positive return value even if no changes have been found
}

export const diff = (oldThing = {}, newThing = {}, options = defaultDiffOptions, depth = 0, oldRefs = [], newRefs = []) => {
  const { includeFuncs, maxDepth, full, checkCircular } = { ...defaultDiffOptions, ...options }
  return [...new Set([...Object.keys(oldThing), ...Object.keys(newThing)])].reduce((result, key) => {
    if ((result.changed && !full) || result.circular) return result

    const oldVal = oldThing[key]
    const newVal = newThing[key]

    if (oldVal !== newVal) {
      const areFuncs = typeof oldVal === 'function' && typeof newVal === 'function'
      const areObjs = typeof oldVal === 'object' && typeof newVal === 'object' && oldVal && newVal // typeof null === 'object' because lol javascript

      if (areFuncs && !includeFuncs) return result

      if (areObjs && !(depth > maxDepth)) {
        if (checkCircular) {
          const oldRef = oldRefs.find(r => r.value === oldVal)
          const newRef = newRefs.find(r => r.value === newVal)

          const oldCycle = [...oldRefs, { oldThing, key, value: oldVal }]
          const newCycle = [...newRefs, { newThing, key, value: newVal }]

          if (oldRef || newRef) return {
            changed: true,
            changes: {...result.changes, [key]: { was: oldVal, is: newVal }},
            circular: true,
            cycle: { old: oldCycle, new: newCycle },
          }

          if (!diff(oldVal, newVal, options, depth + 1, oldCycle, newCycle).changed) return result
        } else if (!diff(oldVal, newVal, options, depth + 1).changed) return result
      }

      result.changed = true
      result.changes = {...result.changes, [key]: { was: oldVal, is: newVal }}
    }

    return result
  }, { changed: false, changes: {}, circular: false, cycle: {} })
}

export const changed = (oldThing = {}, newThing = {}) => diff(oldThing, newThing).changed

const performanceUtils = {
  changed,
  diff,
};

export default performanceUtils;
