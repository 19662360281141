import { IdentifiableWithName, Maybe, Order, PopulatedInstrument, NonNullify, Venue } from ".";
import { Instrument } from "./instruments";
import OrderExpiryType from '@omniex/poms-core/lib/enums/OrderExpiryType';
import QuoteRequestStatus from '@omniex/poms-core/lib/enums/QuoteRequestStatus';
import QuoteRequestSide from '@omniex/poms-core/lib/enums/QuoteRequestSide';

export type OTCSortDirection = -1 | 0 | 1;

export enum OTCOrderType {
  AS_QUOTED = 'Quoted',
  LIMIT_FOK = 'Limit-FOK',
  MARKET = 'Market',
  STOP_MARKET = 'Stop Market',
  STOP_LIMIT = 'Stop Limit',
  LIMIT_GTC = 'Limit-GTC',
}

export type OTCOrder = Pick<Order, 'id' | 'fixClOrdID' | 'instrument' | 'side' | 'specifiedQuantity' | 'status' | 'type'>;

export interface QuoteMetric {
  name: string;
  time: string;
}

export interface Quote {
  fixQuoteID: string;
  bidPrice: number;
  offerPrice: number;
  metrics: QuoteMetric[];
}

export interface QuoteRequest {
  key: string;
  instrument: Pick<Instrument, 'id' | 'displayName' | 'type'>; // Instrument
  quantity: number;
  venue: IdentifiableWithName & { shortName?: string }; // Venue
  status: QuoteRequestStatus;
  reasonRejected: Maybe<string>;
  quote: Maybe<Quote>;
}

export interface OTCOrderTypeOption {
  name: OTCOrderType;
  orderType: string;        // not unique
  expiryType: OrderExpiryType;
  hasLimitPrice: boolean;
  hasStopPrice: boolean;
  resting: boolean;
}

export interface OTCFormOptions {
  instruments: Array<PopulatedInstrument>;
  venues: Array<Venue>;
}

export interface OTCBaseData {
  id?: string;
  instrumentId?: string;
  orderType?: OTCOrderType;
  quantity?: string;
  venueIds?: Array<string>;
}

export interface OTCTemplateData extends NonNullify<OTCBaseData, 'id'> {}
export interface HydratedOTCTemplateData extends OTCTemplateData {
  instrument?: PopulatedInstrument;
  venues?: Venue[];
}

export interface OTCTileData extends OTCTemplateData {
  limitPrice?: string;
  side?: QuoteRequestSide;
  stopPrice?: string;
}

export interface HydratedOTCTileData extends OTCTileData, HydratedOTCTemplateData {
  keys: string[];
}

export type CompletedOTCForm = NonNullify<OTCTileData, keyof OTCBaseData | 'side'>;

export type OTCOrderBookLevel = [
  string,  // venueName
  number | QuoteRequestStatus,  // bidPrice
  number | QuoteRequestStatus,  // offerPrice
  string,  // venueName
];

export type OTCOrderBook = Array<OTCOrderBookLevel>;

export type QuoteFeedInput = {
  instrumentId: string;
  quantity: number;
  venueId: string;
}
