import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { Button, Form, Icon, Input, Label } from 'semantic-ui-react';
import { createSelector } from 'reselect';
import React, { PureComponent } from 'react';
import styled from 'react-emotion';
import { convertIncrementToDecimalPlaces } from '@omniex/onx-common-js/lib/utils/NumberUtils';
import { get, has } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import { getOrderTypesByVenueId } from '../../utils/OrderUtils';
import { isEmpty, isNil } from '@omniex/onx-common-js/lib/utils/LangUtils';
import { isFloat, pluralize } from '@omniex/onx-common-js/lib/utils/StringUtils';
import { isValid as isValidIdentifier } from '@omniex/onx-common-js/lib/utils/IdentifierUtils';
import { keyBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { Decimal } from 'decimal.js';
import calculateMarketPrice from '../../selectors/calculateMarketPrice';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './DmaExecutionForm.copyText';
import ExpiryType from '@omniex/onx-poms-entity-helpers/lib/enums/OrderExpiryType';
import FormSelect from '@omniex/onx-common-ui/lib/semantic/react/FormSelect';
import getGroupedInstrumentOptions from '../../selectors/getGroupedInstrumentOptions';
import { getDisplayFormattedNum } from '../../utils/DisplayUtils';
import getInstrumentFromNameVenue from '../../selectors/getInstrumentFromNameVenue';
import getVenueInstrumentConfiguration from '../../selectors/getVenueInstrumentConfiguration';
import getVenueOptions from '../../selectors/getVenueOptions';
import getVenuesWithInstrument from '../../selectors/getVenuesWithInstrument';
import getProjectedPrice from '../../selectors/getProjectedPrice';
import InstrumentType from '@omniex/onx-poms-entity-helpers/lib/enums/InstrumentType';
import MarketDataEntryType from '@omniex/onx-poms-entity-helpers/lib/enums/MarketDataEntryType';
import Message from '@omniex/onx-common-ui/lib/semantic/react/Message';
import OrderSide from '@omniex/onx-poms-entity-helpers/lib/enums/OrderSide';
import OrderType from '@omniex/onx-poms-entity-helpers/lib/enums/OrderType';
import ProceedMessage from './ProceedMessage';
import ProjectedPriceMessage from './ProjectedPriceMessage';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/button.css');
require('@omniex/onx-common-ui/lib/semantic/css/form.css');
require('@omniex/onx-common-ui/lib/semantic/css/input.css');
require('@omniex/onx-common-ui/lib/semantic/css/label.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');
require('@omniex/onx-common-ui/lib/semantic/css/transition.css');

const QUANTITY_TYPE_BASE = 'BASE';
// const QUANTITY_TYPE_TERM = 'TERM';

const DEFAULT_QUANTITY_TYPE = QUANTITY_TYPE_BASE;

const DEFAULT_DECIMALS = 8;

const ENTER_KEY_CODE = 13;

const MAX_NUMERIC_VALUE = 2e12;

const percentageOptions = ['25%', '50%', '75%', '100%'];

const COMPONENT_NAME = 'DmaExecutionForm';

const StyledForm = styled(Form)`
min-width: 280px;

.${COMPONENT_NAME}-selection {
  min-width: unset !important;

  &.withFixedWidth {
    width: 100px !important;
  }
}

.${COMPONENT_NAME}-submitButtonWrapper {
  text-align: right;
}

.${COMPONENT_NAME}-submitButton {
  margin: 0;
}

.${COMPONENT_NAME}-proceedButton {
  margin: 0 0 14px 0;
}

.${COMPONENT_NAME}-quantityButtonWrapper {
  display: flex;
  justify-content: space-between;
  width: 81%;
}

.${COMPONENT_NAME}-quantityButton {
  background-color: white !important;
  border: 1px solid ${colors.borderColor} !important;
  color: black !important;
  font-size: 0.75em !important;
  height: 25px !important;
  padding: 1px !important;
  width: 50px !important;

  &.selected {
    background-color: ${colors.blue} !important;
    color: ${colors.white} !important;
  }
}

.${COMPONENT_NAME}-confirmMessage {
  white-space: normal;
}

.${COMPONENT_NAME}-warning {
  white-space: normal;
}

.${COMPONENT_NAME}-buttonGroup {
  .button {
    background-color: ${colors.white};
    border: 1px solid ${colors.borderColor} !important;
  }

  .button:hover:not(.${COMPONENT_NAME}-selectedButton) {
    background-color: ${colors.blue} !important;
    color: ${colors.white};
    opacity: 0.45;
  }
}

.${COMPONENT_NAME}-selectedButton {
  background-color: ${colors.blue} !important;
  color: ${colors.white} !important;
}

.${COMPONENT_NAME}-textInput input {
  text-align: right !important;
}

.${COMPONENT_NAME}-inputLabel {
  min-width: 4em;
  text-align: center;
}

.${COMPONENT_NAME}-contractContent {
  margin-top: 8px;
  padding-left: 8px;
}

.${COMPONENT_NAME}-contractContentRow {
  display: flex;
  flex-flow: row nowrap;
}

.${COMPONENT_NAME}-contractContentLabel {
  flex-grow: 1;
}

.${COMPONENT_NAME}-contractContentValue {}

.${COMPONENT_NAME}-contractFooter {
  margin-top: 8px;
}
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class DmaExecutionForm extends PureComponent {
static propTypes = {
  basePositionQuantity: number,
  instruments: arrayOf(
    shape({
      id: string.isRequired,
      baseAsset: shape({
        id: string.isRequired,
        symbol: string.isRequired
      }),
      termAsset: shape({
        id: string.isRequired,
        symbol: string.isRequired
      })
    })
  ),
  marginPositionQuantity: number,
  org: shape({ enableCustomOrderId: bool }),
  processing: bool,
  selectedMarketDataEntry: shape({
    price: number.isRequired,
    type: string.isRequired,
    cumulativeQuantity: number
  }),
  selectedVenueId: string,
  termPositionQuantity: number,
  venues: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
      symbol: string.isRequired,
      instrumentConfigurations: arrayOf(
        shape({
          instrument: shape({
            id: string.isRequired,
            displayName: string.isRequired,
            baseAsset: shape({
              id: string.isRequired,
              symbol: string.isRequired
            }).isRequired,
            termAsset: shape({
              id: string.isRequired,
              symbol: string.isRequired
            }).isRequired
          })
        })
      )
    })
  ),
  onChange: func,
  onSubmit: func
};

static defaultProps = {
  onChange: noop,
  onSubmit: noop
};

state = {};

_getOrderTypeOptions = createSelector(
  venueId => venueId,
  getOrderTypeOptions
);

componentDidUpdate() {
  const { venues } = this.props;
  const { instrumentDisplayName } = this.state;
  const venueOptions = getVenueOptions(venues, instrumentDisplayName);

  this.props.fetchAccounts(venueOptions);
}

static getDerivedStateFromProps(nextProps, prevState) {
  if (isEmpty(prevState)) return getInitialState(nextProps);

  const stateChanges = {};

  if (
    prevState.selectedMarketDataEntry !== nextProps.selectedMarketDataEntry &&
    !prevState.showProceedButton
  ) {
    stateChanges.selectedMarketDataEntry = nextProps.selectedMarketDataEntry;
    stateChanges.limitPrice = get(
      nextProps,
      'selectedMarketDataEntry.price',
      ''
    ).toString();

    stateChanges.side =
      get(
        nextProps,
        'selectedMarketDataEntry.type',
        MarketDataEntryType.OFFER
      ) === MarketDataEntryType.BID
        ? OrderSide.SELL
        : OrderSide.BUY;

    stateChanges.quantity = get(
      nextProps,
      'selectedMarketDataEntry.cumulativeQuantity',
      ''
    ).toString();
  }

  if (
    prevState.showSuccessButton === false &&
    nextProps.showSuccessButton === true
  ) {
    return getInitialState(nextProps, {
      orderType: prevState.orderType,
      side: prevState.side
    });
  }

  if (prevState.instrumentDisplayName && !prevState.hasInputChanged) {
    const instrument = getInstrumentFromNameVenue(nextProps.venues, prevState.instrumentDisplayName, 'id', prevState.venueId);

    stateChanges.instrumentOption = isEmpty(instrument)
      ? undefined
      : {
        value: prevState.instrumentDisplayName,
        label: prevState.instrumentDisplayName
      };
  }

  if (prevState.venueId && !prevState.hasInputChanged) {
    const venueOption = getVenueOptions(nextProps.venues, prevState.instrumentDisplayName).find(v => v.value === prevState.venueId);

    stateChanges.venueOption = isEmpty(venueOption)
      ? undefined
      : venueOption;
  }

  return stateChanges;
}

render() {
  const nameToInst = mapNameToInst(this.props.instruments);
  const instrument = nameToInst[this.state.instrumentDisplayName]
    ? getInstrumentFromNameVenue(this.props.venues, this.state.instrumentDisplayName, 'id', this.state.venueId)
    : null;

  const recentInstrumentDisplayNames = get(this.props, 'recentInstrumentDisplayNames', []);
  const recentInstruments = recentInstrumentDisplayNames.reduce((options, n) => {
    if (n && nameToInst[n]) options.push({displayName: n});
    return options;
  }, []);

  const groupedInstrumentOptions = getGroupedInstrumentOptions(
    this.props.instruments,
    recentInstruments
  );

  const venueOptions = getVenueOptions(this.props.venues, this.state.instrumentDisplayName);

  return (
    <StyledForm
      className={COMPONENT_NAME}
      autoComplete="off"
      warning
      onSubmit={this._handleSubmit.bind(this, instrument)}>
      <FormSelect
        className={cn('selection')}
        name="instrumentDisplayName"
        isDisabled={this.state.showProceedButton}
        options={groupedInstrumentOptions}
        placeholder={copyText.inputPlaceholder_instrumentDisplayName}
        value={this.state.instrumentOption}
        onChange={this._handleChangeField}
      />
      <FormSelect
        className={cn('selection')}
        name="venueId"
        isDisabled={!this.state.instrumentDisplayName || this.state.showProceedButton}
        isSearchable={false}
        options={venueOptions}
        placeholder={copyText.inputPlaceholder_venueId}
        value={this.state.venueOption}
        onChange={this._handleChangeField}
      />
      <Form.Field>
        <Button.Group className={cn('buttonGroup')} fluid size="tiny">
          <Button
            className={
              this.state.side === OrderSide.BUY ? cn('selectedButton') : null
            }
            name="side"
            disabled={this.state.showProceedButton}
            type="button"
            value={OrderSide.BUY}
            onClick={this._handleChangeField}>
            {copyText.orderSideBuy}
          </Button>
          <Button
            className={
              this.state.side === OrderSide.SELL ? cn('selectedButton') : null
            }
            name="side"
            disabled={this.state.showProceedButton}
            type="button"
            value={OrderSide.SELL}
            onClick={this._handleChangeField}>
            {copyText.orderSideSell}
          </Button>
        </Button.Group>
      </Form.Field>
      <FormSelect
        className={cn('selection')}
        name="orderType"
        isDisabled={!this.state.venueId || this.state.showProceedButton}
        options={this._getOrderTypeOptions(this.state.venueId)}
        placeholder={copyText.inputPlaceholder_orderType}
        value={this.state.orderTypeOption}
        onChange={this._handleChangeField}
      />
      {this._renderStopPriceInput(instrument)}
      {this.state.showTruncStopPriceMessage
        && this._renderTruncMessage('stopPrice')}
      {this.state.showStopPriceWarning
        && this._renderWarning('stopPrice')}
      {this._renderLimitInput(instrument)}
      {this.state.showTruncLimitPriceMessage
        && this._renderTruncMessage('limitPrice')}
      {this.state.showLimitPriceWarning
        && this._renderWarning('limitPrice')}
      <Form.Field>
        <Input
          className={cn('textInput')}
          name="quantity"
          disabled={!this.state.orderType || this.state.showProceedButton}
          label={
            <Label
              className={cn('inputLabel')}
              basic
              content={get(instrument, 'type') === InstrumentType.FUTURE
                ? copyText.quantityUnitFutures
                : get(instrument, 'baseAsset.symbol', '')
              }
            />
          }
          labelPosition="right"
          placeholder={copyText.inputPlaceholder_quantity}
          value={this.state.quantity}
          onChange={this._handleChangeField}
          onBlur={this._handlePrecisionTruncation('quantity')}
          onKeyDownCapture={this._handleKeyPress('quantity')}
        />
      </Form.Field>
      <Form.Field
        className={cn('quantityButtonWrapper')}
        disabled={this.state.showProceedButton}>
        {percentageOptions.map(percentageOption =>
          this._renderPercentageOptionButton(percentageOption)
        )}
      </Form.Field>
      {this.props.org?.enableCustomOrderId && (
        <Form.Field>
          <Input
            className={cn('textInput')}
            name="customOrderId"
            disabled={!this.state.orderType || this.state.showProceedButton}
            placeholder={copyText.inputPlaceholder_customOrderId}
            value={this.state.customOrderId}
            onChange={this._handleChangeField}
          />
        </Form.Field>
      )}
      {this.state.showQuantityWarning
        && this._renderWarning('quantity')}
      {this.state.showTermSizeWarning
        && this._renderWarning('termSize')}
      {this._renderProjectedPrice()}
      {this.state.showProceedButton ? (
        this._renderProceedForm(instrument)
      ) : (
        <Form.Field className={cn('submitButtonWrapper')}>
          <Button
            className={cn('submitButton')}
            color={this.props.showSuccessButton ? 'green' : 'blue'}
            disabled={
              (!this._canSave() || this.props.processing) &&
              !this.props.showSuccessButton
            }
            fluid
            onClick={this._handleClickSubmitButton}>
            {this.props.showSuccessButton ? (
              <span>
                <Icon name="check" /> {copyText.orderSuccessButtonLabel}
              </span>
            ) : (
                copyText.submitButtonLabel
              )}
          </Button>
        </Form.Field>
      )}
    </StyledForm>
  );
}

_renderProceedForm(instrument) {
  const {
    basePositionQuantity,
    currentPrices,
    entriesGroupedByType,
    marginPositionQuantity,
    termPositionQuantity,
  } = this.props;

  const {
    limitPrice,
    orderType,
    processing,
    quantity,
    side,
    stopPrice,
  } = this.state;

  return (
    <>
      <ProceedMessage
        algoOrder={false}
        basePositionQuantity={basePositionQuantity}
        currentPrices={currentPrices}
        entriesGroupedByType={entriesGroupedByType}
        instrument={instrument}
        limitPrice={limitPrice}
        marginPositionQuantity={marginPositionQuantity}
        orderType={orderType}
        projectedPrice={this._getProjectedPrice().projectedPrice}
        quantity={quantity}
        side={side}
        stopPrice={stopPrice}
        termPositionQuantity={termPositionQuantity}
        visible={this._canSave()} />
      <Form.Field>
        <Button
          className={cn('proceedButton')}
          color="orange"
          disabled={!this._canSave()}
          fluid
          loading={processing}
          type="submit">
          {copyText.proceedButtonLabel}
        </Button>
      </Form.Field>
      <Form.Field>
        <Button
          disabled={this.state.processing}
          fluid
          onClick={this._handleClickCancelButton}
          type="reset">
          {copyText.cancelButtonLabel}
        </Button>
      </Form.Field>
    </>
  );
}

_renderProjectedPrice() {
  const orderType = get(this.state, 'orderType', '').split(':')[0];
  if (orderType !== OrderType.LIMIT && orderType !== OrderType.MARKET) return null;

  return (
    <ProjectedPriceMessage
      {...this._getProjectedPrice().projectedPriceArgs}
      includeSorFees={false} />
  );
}

_renderPercentageOptionButton(percentageOption) {
  return (
    <Button
      key={percentageOption}
      className={cn(
        `quantityButton ${
        this.state.percentageOption === percentageOption ? 'selected' : ''
        }`
      )}
      name="percentageOption"
      disabled={this.state.disabled || !this.state.orderType}
      size="mini"
      type="button"
      value={percentageOption}
      onClick={this._handleChangeField}>
      {percentageOption}
    </Button>
  );
}

_renderLimitInput(instrument) {
  if (
    get(this.state, 'orderType', '').startsWith(OrderType.LIMIT) ||
    get(this.state, 'orderType', '').startsWith(OrderType.STOP_LIMIT)
  ) {
    return (
      <>
        <Form.Field>
          <Input
            className={cn('textInput')}
            name="limitPrice"
            disabled={this.state.showProceedButton}
            label={
              <Label
                className={cn('inputLabel')}
                basic
                content={instrument ? getAssetDisplayText(instrument.termAsset) : ''}
              />
            }
            labelPosition={'right'}
            placeholder={copyText.inputPlaceholder_limitPrice}
            value={this.state.limitPrice}
            onChange={this._handleChangeField}
            onBlur={this._handlePrecisionTruncation('limitPrice')}
            onKeyDownCapture={this._handleKeyPress('limitPrice')}
          />
        </Form.Field>
      </>
    );
  }
}

_renderStopPriceInput(instrument) {
  if (
    get(this.state, 'orderType', '').startsWith(OrderType.STOP_LOSS) ||
    get(this.state, 'orderType', '').startsWith(OrderType.STOP_LIMIT)
  ) {
    return (
      <>
        <Form.Field>
          <Input
            className={cn('textInput')}
            name="stopPrice"
            disabled={this.state.showProceedButton}
            label={
              <Label
                className={cn('inputLabel')}
                basic
                content={instrument ? getAssetDisplayText(instrument.termAsset) : ''}
              />
            }
            labelPosition={'right'}
            placeholder={copyText.inputPlaceholder_stopPrice}
            value={this.state.stopPrice}
            onChange={this._handleChangeField}
            onBlur={this._handlePrecisionTruncation('stopPrice')}
            onKeyDownCapture={this._handleKeyPress('stopPrice')}
          />
        </Form.Field>
      </>
    );
  }
}

_renderTruncMessage(tag) {
  let header = '';
  let content = '';
  let preTruncPrice;

  if (tag === 'limitPrice') {
    header = 'Limit Price Truncated';
    content = copyText.warningMessage_limitPriceTrunc;
    preTruncPrice = this.state.preTruncLimitPrice;
  }

  else if (tag === 'stopPrice') {
    header = 'Stop Price Truncated';
    content = copyText.warningMessage_stopPriceTrunc;
    preTruncPrice = this.state.preTruncStopPrice;
  }

  return (
    <Message
      warning
      className={cn('warning')}
      style={{opacity: this.state.showProceedButton ? "0.4" : "1.0"}}
      header={header}
      content={
        content
        .replace('%limitPrice%',
          `${getDisplayFormattedNum(parseFloat(this.state.limitPrice))}`)
        .replace('%stopPrice%',
          `${getDisplayFormattedNum(parseFloat(this.state.stopPrice))}`)
        .replace('%preTruncPrice%',
          `${getDisplayFormattedNum(preTruncPrice)}`)
    }
    />
  )
}

_renderWarning(tag) {

  const instrument = getInstrumentFromNameVenue(this.props.venues, this.state.instrumentDisplayName, 'id', this.state.venueId);

  const {
    maxSize,
    maxPrice
  } = get(
    getVenueInstrumentConfiguration(this.props.venues),
    `${this.state.venueId}.${instrument.id}`
  ) || {};

  let header, content;
  let warning = false;

  if (tag === 'limitPrice') {
    if (!isNil(maxPrice) &&
      (this.state.limitPrice > maxPrice)) {
      header = 'Limit Price Too High';
      content = copyText.warningMessage_limitPriceAbove;
    } else {
      header = 'Limit Price Too Low';
      content = copyText.warningMessage_limitPriceBelow;
    }
  }

  else if (tag === 'stopPrice') {
    if (!isNil(maxPrice) &&
      (this.state.stopPrice > maxPrice)) {
      header = 'Stop Price Too High';
      content = copyText.warningMessage_stopPriceAbove;
    } else {
      header = 'Stop Price Too Low';
      content = copyText.warningMessage_stopPriceBelow;
    }
  }

  else if (tag === 'quantity') {
    if (!isNil(maxSize) &&
      (this.state.quantity > maxSize)) {
      header = 'Quantity Too High';
      content = copyText.warningMessage_quantityAbove;
    } else if (this.state.quantity > this.props.basePositionQuantity) {
      header = 'Quantity Exceeds Holdings';
      content = copyText.warningMessage_quantityExceedsBasePositionQuantity;
    } else {
      header = 'Quantity Too Low';
      content = copyText.warningMessage_quantityBelow;
    }
  }

  else if (tag === 'termSize') {
    header = 'Term Size Too Low';
    content = copyText.warningMessage_termSizeBelow;
    warning = true;
  }

  return (
    <Message
      className={cn('warning')}
      style={{opacity: this.state.showProceedButton ? "0.4" : "1.0"}}
      header={header}
      content={this._getMessageWithParamReplacements(content)}
      warning={warning}
      negative={!warning}
    />
  )
}

_canSave() {
  const shouldDisplayLimitPrice =
    this.state.orderType.startsWith(OrderType.LIMIT) ||
    this.state.orderType.startsWith(OrderType.STOP_LIMIT);

  const shouldDisplayStopPrice =
    this.state.orderType.startsWith(OrderType.STOP_LOSS) ||
    this.state.orderType.startsWith(OrderType.STOP_LIMIT);

  let isInvalidStopPrice = false;

  if (this.state.orderType.startsWith(OrderType.STOP_LIMIT)) {
    if (this.state.side === OrderSide.BUY) {
      isInvalidStopPrice =
        parseFloat(this.state.limitPrice) < parseFloat(this.state.stopPrice);
    }
    else if (this.state.side === OrderSide.SELL) {
      isInvalidStopPrice =
        parseFloat(this.state.limitPrice) > parseFloat(this.state.stopPrice);
    }
  }

  return (
    !isEmpty(this.state.instrumentDisplayName) &&
    isValidIdentifier(this.state.venueId) &&
    !isEmpty(this.state.orderType) &&
    !isEmpty(this.state.side) &&
    !(this.state.quantity <= 0 || this.state.quantity > MAX_NUMERIC_VALUE) &&
    !(shouldDisplayLimitPrice && (this.state.limitPrice <= 0 || this.state.limitPrice > MAX_NUMERIC_VALUE)) &&
    !(shouldDisplayStopPrice && (this.state.stopPrice <= 0 || this.state.stopPrice > MAX_NUMERIC_VALUE)) &&
    !isInvalidStopPrice &&
    !this.state.showLimitPriceWarning &&
    !this.state.showStopPriceWarning &&
    !this.state.showQuantityWarning
  );
}

_getMessageWithParamReplacements = message => {
  const instrument = getInstrumentFromNameVenue(this.props.venues, this.state.instrumentDisplayName, 'id', this.state.venueId);
  let {
    minSize,
    maxSize,
    minTermSize,
    minSizeIncrement,
    minPrice,
    maxPrice,
    minTick
  } = get(
    getVenueInstrumentConfiguration(this.props.venues),
    `${this.state.venueId}.${instrument.id}`
  ) || {};

  // Truncate min/max accordingly (some have excess precision)
  maxSize = this._getPrecisionTruncatedFloat(maxSize, minSizeIncrement);
  maxPrice = this._getPrecisionTruncatedFloat(maxPrice, minTick);

  let preciseMinSize = this._getPrecisionTruncatedFloat(minSize, minSizeIncrement);
  if(preciseMinSize !== minSize)
    minSize = preciseMinSize + minSizeIncrement;

  let preciseMinPrice = this._getPrecisionTruncatedFloat(minPrice, minTick);
  if(preciseMinPrice !== minPrice)
    minPrice = preciseMinPrice + minTick;

  return message
    .replace(/%instrument%/g, `${getAssetDisplayText(get(this.state, 'instrumentDisplayName', ''))}`)
    .replace(/%baseCurrency%/g, `${getAssetDisplayText(get(this.state, 'instrument.baseAsset', ''))}`)
    .replace(/%termCurrency%/g, `${get(this.state, 'instrument.termAsset', '')}`)
    .replace(/%venue%/g, `${this.state.venueOption.label}`)
    .replace(/%minSize%/g, `${getDisplayFormattedNum(minSize)}`)
    .replace(/%maxSize%/g, `${getDisplayFormattedNum(maxSize)}`)
    .replace(/%minTermSize%/g, `${getDisplayFormattedNum(minTermSize)}`)
    .replace(/%minSizeIncrement%/g, `${getDisplayFormattedNum(minSizeIncrement)}`)
    .replace(/%minPrice%/g, `${getDisplayFormattedNum(minPrice)}`)
    .replace(/%maxPrice%/g, `${getDisplayFormattedNum(maxPrice)}`)
    .replace(/%minTick%/g, `${getDisplayFormattedNum(minTick)}`)
    .replace(/%quantity%/g, `${getDisplayFormattedNum(parseFloat(this.state.quantity))}`)
    .replace(/%unit%/g, `${pluralize(copyText.contractsLabel, parseFloat(this.state.quantity))}`)
    .replace(/%limitPrice%/g, `${getDisplayFormattedNum(parseFloat(this.state.limitPrice))}`)
    .replace(/%stopPrice%/g, `${getDisplayFormattedNum(parseFloat(this.state.stopPrice))}`)
    .replace(/%basePositionQuantity%/g, `${getDisplayFormattedNum(this.props.basePositionQuantity)}`);
}

_getPrecisionTruncatedString(value, precision, tag) {
  if (isEmpty(value)) return value;

  value = parseFloat(value);

  return this._getPrecisionTruncatedFloat(
    value,
    precision,
    tag
  ).toString();
}

_getPrecisionTruncatedFloat(value, precision, tag) {
  if (!(precision > 0)) {
    precision = Decimal.pow(10, -DEFAULT_DECIMALS);
  }

  // Decimal precision to avoid float errors
  value = new Decimal(value || 0);
  precision = new Decimal(precision);
  const dividedValue = value.dividedBy(precision);

  if(tag === 'limitPrice'){
    this.setState({
      showTruncLimitPriceMessage: !dividedValue.isInt()
    });
  } else if(tag === 'stopPrice'){
    this.setState({
      showTruncStopPriceMessage: !dividedValue.isInt()
    });
  }

  value = Decimal.trunc(dividedValue).mul(precision);

  return value.toNumber();
}

_getProjectedPrice = () => {

  const accounts = get(this.props, 'accountsWithBalancesQueryData.accounts', []);

  const entries = this.props.entriesGroupedByType[
    (this.state.side === OrderSide.BUY)
      ? MarketDataEntryType.OFFER
      : MarketDataEntryType.BID
  ];

  const instrument = getInstrumentFromNameVenue(this.props.venues, this.state.instrumentDisplayName, 'id', this.state.venueId);

  const [orderType, expiryType] = get(this.state, 'orderType', '').split(':');

  const venueOptions = keyBy(getVenuesWithInstrument(this.props.venues, this.state.instrumentDisplayName), 'id');

  const projectedPriceArgs = {
    accounts,
    entries,
    gtc: expiryType === ExpiryType.GTC,
    instrument,
    limitPrice: this.state.limitPrice,
    orderType,
    qtyToFill: this.state.quantity,
    side: this.state.side,
    venueIds: [this.state.venueId],
    venueOptions,
  };

  return {
    projectedPrice: getProjectedPrice(projectedPriceArgs) || {},
    projectedPriceArgs
  };
}

_getTermSizeFlag = () => {
  const instrument = getInstrumentFromNameVenue(this.props.venues, this.state.instrumentDisplayName, 'id', this.state.venueId);
  const {
      minTermSize
    } = get(
      getVenueInstrumentConfiguration(this.props.venues),
      `${this.state.venueId}.${instrument.id}`
    ) || {};

  const [orderType] = (this.state.orderType || '').split(':');

  const priceUsed = [OrderType.MARKET, OrderType.LIMIT, OrderType.STOP_LIMIT].includes(orderType)
    ? this.state.limitPrice
    : this.state.stopPrice;

  let pxq;

  if ([OrderType.MARKET, OrderType.LIMIT].includes(orderType)) {
    const { projectedPrice } = this._getProjectedPrice();
    pxq = projectedPrice.avgPrice * projectedPrice.qtyFilled;
  } else {
    pxq = parseFloat(priceUsed) * parseFloat(this.state.quantity);
  }

  return isFloat(this.state.quantity) && ((orderType === OrderType.MARKET) || isFloat(priceUsed)) && (pxq < (minTermSize || 0));
}

_handleChangeField = (field, event) => {
  let { value } = field;
  const { name } = event;

  if (has(event, 'value')) {
    value = event.value;
  }

  let change = { [name]: value };
  let changes = change;

  if (['limitPrice', 'stopPrice', 'quantity'].includes(name)) {
    const instrument = getInstrumentFromNameVenue(this.props.venues, this.state.instrumentDisplayName, 'id', this.state.venueId);
    const {
      minSizeIncrement,
      minTick
    } = get(
      getVenueInstrumentConfiguration(this.props.venues),
      `${this.state.venueId}.${instrument.id}`
    ) || {};

    let dec = DEFAULT_DECIMALS;

    if ((name === 'quantity') && !isNil(minSizeIncrement)) {
      dec = convertIncrementToDecimalPlaces(minSizeIncrement);
    } else if ((['limitPrice', 'stopPrice'].includes(name)) && !isNil(minTick)) {
      dec = convertIncrementToDecimalPlaces(minTick);
    }

    const floatRegex = new RegExp('^\\d*\\.?\\d{0,' + dec + '}$', 'g');

    const invalidFloat = (
      has(change, 'limitPrice') ||
      has(change, 'stopPrice') ||
      (has(change, 'quantity') && field.value !== '')
    ) && !floatRegex.test(value);

    if (invalidFloat) return;
  }

  if (has(change, 'instrumentDisplayName')) {
    changes = {
      ...changes,
      instrumentOption: field,
      limitPrice: '',
      orderType: '',
      orderTypeOption: '',
      quantity: '',
      quantityType: DEFAULT_QUANTITY_TYPE,
      showProceedButton: false,
      showLimitPriceWarning: false,
      showStopPriceWarning: false,
      showQuantityWarning: false,
      showTermSizeWarning: false,
      showTruncLimitPriceMessage: false,
      showTruncStopPriceMessage: false,
      stopPrice: '',
      venueId: '',
      venueOption: ''
    };
  }

  if (has(change, 'venueId')) {
    changes = {
      ...changes,
      limitPrice: '',
      orderType: '',
      orderTypeOption: '',
      quantity: '',
      quantityType: DEFAULT_QUANTITY_TYPE,
      showProceedButton: false,
      showLimitPriceWarning: false,
      showStopPriceWarning: false,
      showQuantityWarning: false,
      showTermSizeWarning: false,
      showTruncLimitPriceMessage: false,
      showTruncStopPriceMessage: false,
      stopPrice: '',
      venueOption: field
    };
  }

  if (has(change, 'orderType')) {
    changes = {
      ...changes,
      limitPrice: '',
      orderTypeOption: field,
      quantity: '',
      quantityType: DEFAULT_QUANTITY_TYPE,
      showProceedButton: false,
      showLimitPriceWarning: false,
      showStopPriceWarning: false,
      showQuantityWarning: false,
      showTermSizeWarning: false,
      showTruncLimitPriceMessage: false,
      showTruncStopPriceMessage: false,
      stopPrice: '',
    };
  }

  if (has(change, 'quantity')) {
    changes = {
      ...changes,
      percentageOption: '',
    };
  }

  if (has(change, 'limitPrice')) {
    changes = {
      ...changes,
      showTruncLimitPriceMessage: false
    };
  }

  if (has(change, 'stopPrice')) {
    changes = {
      ...changes,
      showTruncStopPriceMessage: false
    };
  }

  if (has(change, 'side')) {
    changes = {
      ...changes,
      percentageOption: ''
    };
  }

  if (has(change, 'percentageOption')) {
    let quantity;
    const percentageOption = parseInt(change.percentageOption) * 0.01;
    const baseQty = this.props.basePositionQuantity;
    const termQty = this.props.termPositionQuantity;
    const price = calculateMarketPrice(this.props.entriesGroupedByType);

    const instrument = getInstrumentFromNameVenue(this.props.venues, this.state.instrumentDisplayName, 'id', this.state.venueId);

    const { minSizeIncrement } = get(
      getVenueInstrumentConfiguration(this.props.venues),
      `${this.state.venueId}.${instrument.id}`
    ) || {};

    if (instrument.type === InstrumentType.FUTURE) {
      quantity = Math.abs(Math.round(percentageOption * baseQty)) || 0; // baseQty can be negative for contracts
      quantity = this._getPrecisionTruncatedString(
        quantity.toString(),
        minSizeIncrement
      );

      changes = { ...changes, quantity };
    } else {
      if (this.state.side === OrderSide.SELL) {
        quantity = percentageOption * baseQty;
      } else if (this.state.side === OrderSide.BUY) {
        quantity = (percentageOption * termQty) / (price === 0 ? 1 : price);
      }

      quantity = Math.max(quantity || 0, 0); // make sure quantity is a non-negative number
      quantity = this._getPrecisionTruncatedString(
        quantity.toString(),
        minSizeIncrement
      );

      if (isFinite(parseFloat(quantity))) {
        changes = {
          ...changes,
          quantity
        };
      }
    }
  }

  changes.hasInputChanged = true;

  this.setState(changes, () => {
    this.props.onChange(changes);
    if (['limitPrice', 'stopPrice', 'quantity', 'percentageOption', 'side'].includes(name)) this._handleWarningFlags();
  });
};

_handleClickCancelButton = event => {
  event.preventDefault();

  this.setState({
    showProceedButton: false
  });
};

_handleClickSubmitButton = event => {
  event.preventDefault();

  this.setState({ showProceedButton: true });
};

_handleKeyPress = tag => event => {
  if (event.keyCode === ENTER_KEY_CODE) {
    this._handlePrecisionTruncation(tag)();
  }
};

_handlePrecisionTruncation = tag => _ => {
  const instrument = getInstrumentFromNameVenue(this.props.venues, this.state.instrumentDisplayName, 'id', this.state.venueId);

  const { minSizeIncrement, minTick } = get(
    getVenueInstrumentConfiguration(this.props.venues),
    `${this.state.venueId}.${instrument.id}`
  ) || {};

  if (tag === 'quantity') {
    const quantity = this._getPrecisionTruncatedString(this.state.quantity, minSizeIncrement);
    this.setState({ quantity }, () => this._handleWarningFlags());

  } else if (tag === 'limitPrice') {
    const preTruncLimitPrice = parseFloat(this.state.limitPrice);
    const limitPrice = this._getPrecisionTruncatedString(this.state.limitPrice, minTick, 'limitPrice');
    this.setState({ limitPrice, preTruncLimitPrice }, () => this._handleWarningFlags());

  } else if (tag === 'stopPrice') {
    const preTruncStopPrice = parseFloat(this.state.stopPrice);
    const stopPrice = this._getPrecisionTruncatedString(this.state.stopPrice, minTick, 'stopPrice');
    this.setState({ stopPrice, preTruncStopPrice }, () => this._handleWarningFlags());
  }
};

_handleSubmit = (instrument, event) => {
  event.preventDefault();

  const specifiedQuantity = isFloat(this.state.quantity)
    ? parseFloat(this.state.quantity)
    : 0;

  const [orderType, expiryType] = get(this.state, 'orderType', '').split(':');

  const stopPrice =
    [OrderType.STOP_LIMIT, OrderType.STOP_LOSS].includes(orderType) && isFloat(this.state.stopPrice)
      ? parseFloat(this.state.stopPrice)
      : null;

  const limitPrice =
    [OrderType.LIMIT, OrderType.STOP_LIMIT].includes(orderType) && isFloat(this.state.limitPrice)
      ? parseFloat(this.state.limitPrice)
      : null;

  const specifiedCurrencyId =
    this.state.quantityType === QUANTITY_TYPE_BASE
      ? get(instrument, 'baseAsset.id', 0)
      : get(instrument, 'termAsset.id', 0);

  this.props.onSubmit({
    customOrderId: this.state.customOrderId,
    expiryType,
    ...(limitPrice ? { limitPrice } : {}),
    instrumentId: instrument.id,
    side: this.state.side,
    specifiedCurrencyId,
    specifiedQuantity,
    ...(stopPrice ? { stopPrice } : {}),
    type: orderType,
    venueId: this.state.venueId
  });
};

_handleWarningFlags = () => {
  const instrument = getInstrumentFromNameVenue(this.props.venues, this.state.instrumentDisplayName, 'id', this.state.venueId);

  const {
    minSize,
    maxSize,
    minPrice,
    maxPrice
  } = get(
    getVenueInstrumentConfiguration(this.props.venues),
    `${this.state.venueId}.${instrument.id}`
  ) || {};

  const { side, quantity } = this.state;
  const { basePositionQuantity } = this.props;
  const sellingMoreThanHeld = get(instrument, 'mdInstrument.id') && side === 'SELL' && quantity > basePositionQuantity;

  const showLimitPriceWarning = !isInRange(this.state.limitPrice, minPrice, maxPrice);
  const showQuantityWarning = !isInRange(this.state.quantity, minSize, maxSize) || sellingMoreThanHeld;
  const showStopPriceWarning = !isInRange(this.state.stopPrice, minPrice, maxPrice);
  const showTermSizeWarning = this._getTermSizeFlag();

  this.setState({
    showLimitPriceWarning,
    showQuantityWarning,
    showStopPriceWarning,
    showTermSizeWarning
  });
}
}

function getInitialState(props, overrides = {}) {
  return {
    customOrderId: '',
    instrumentDisplayName: props.selectedInstrumentDisplayName,
    limitPrice: '',
    orderType: '',
    quantity: '',
    quantityType: DEFAULT_QUANTITY_TYPE,
    selectedMarketDataEntry: props.selectedMarketDataEntry,
    showProceedButton: false,
    showSuccessButton: false,
    showLimitPriceWarning: false,
    showStopPriceWarning: false,
    showQuantityWarning: false,
    showTermSizeWarning: false,
    showTruncLimitPriceMessage: false,
    showTruncStopPriceMessage: false,
    side: OrderSide.BUY,
    stopPrice: '',
    venueId: props.selectedVenueId,
    preTruncLimitPrice: null,
    preTruncStopPrice: null,
    ...overrides
  };
}

function getOrderTypeOptions(venueId) {
  const orderTypes = getOrderTypesByVenueId(venueId);
  return orderTypes.map(orderType => ({
    label: `${orderType.join(' - ').replace('_', ' ')}`,
    value: `${orderType.join(':')}`
  }));
}

const isInRange = (value, min, max) => !isFloat(value) || ((isNil(min) || value >= min) && (isNil(max) || value <= max));

const mapNameToInst = createSelector(i => i, i => keyBy(i, 'displayName'))
