import { arrayOf, bool, object, shape, string } from 'prop-types';
import { CSVLink } from 'react-csv';
import { Icon, Label, Segment, Loader } from 'semantic-ui-react';
import React, { Fragment, PureComponent } from 'react';
import styled from 'react-emotion';

import { format as formatDate } from '@omniex/onx-common-js/lib/utils/DateTimeUtils';
import { format as formatNumber } from '@omniex/onx-common-js/lib/utils/NumberUtils';
import { get, has } from '@omniex/onx-common-js/lib/utils/ObjectUtils';
import { getAssetDisplayText } from '@omniex/poms-core/lib/utils/AssetDisplayUtils';
import { noop } from '@omniex/onx-common-js/lib/utils/FunctionUtils';
import { orderBy } from '@omniex/onx-common-js/lib/utils/CollectionUtils';
import { colors } from '@omniex/onx-common-ui/lib/styles';
import copyText from './QuotePageBlotterSection.copyText';
import AssetType from '@omniex/poms-core/lib/enums/AssetType';
import Dropdown from '@omniex/onx-common-ui/lib/semantic/react/Dropdown';
import Menu from '@omniex/onx-common-ui/lib/semantic/react/Menu';
// import populateInstrumentsWithCurrencies from '../../selectors/populateInstrumentsWithCurrencies';
import populateOrders from '../../selectors/populateOrders';
import QuotedOrderBlotterTable from '../components/QuotedOrderBlotterTable';

// NOTE: The order of these imports matters. Do not change.
require('@omniex/onx-common-ui/lib/semantic/css/icon.css');
require('@omniex/onx-common-ui/lib/semantic/css/label.css');
require('@omniex/onx-common-ui/lib/semantic/css/loader.css');
require('@omniex/onx-common-ui/lib/semantic/css/segment.css');
require('@omniex/onx-common-ui/lib/semantic/css/menu.css');
require('@omniex/onx-common-ui/lib/semantic/css/message.css');

const COMPONENT_NAME = 'QuotePageBlotterSection';

const DEFAULT_ROWS_PER_PAGE = 25;

const pageRowOptions = [
  { text: 10, value: 10 },
  { text: 25, value: 25 },
  { text: 50, value: 50 },
  { text: 100, value: 100 }
];

const StyledSection = styled('section')`
  .${COMPONENT_NAME}-csvLink {
    color: ${colors.black};
  }

  .${COMPONENT_NAME}-loader {
    color: ${colors.grey};
  }

  .${COMPONENT_NAME}-menuItem {
    justify-content: center !important;
    width: 200px !important;
  }

  .${COMPONENT_NAME}-paginationSection {
    display: flex;
    flex-direction: row;
    padding-bottom: 14px;
    padding-top: 14px;
  }

  .${COMPONENT_NAME}-paginationWrapper {
    flex: 1;
    justify-content: flex-end;
  }

  .${COMPONENT_NAME}-pagination {
    box-shadow: none;
    height: 34px;
    margin-left: 14px !important;
  }

  .${COMPONENT_NAME}-rowNumberSelectionWrapper {
    flex: 1;
    justify-content: flex-start;
  }

  .${COMPONENT_NAME}-rowNumberSelection {
    height: 34px !important;
    margin-right: 5px;
    min-height: 0 !important;
  }

  .${COMPONENT_NAME}-segment {
    min-height: 400px;
  }

  .${COMPONENT_NAME}-tableWrapper {
    overflow-x: auto;
  }
`;

const cn = elementName => `${COMPONENT_NAME}-${elementName}`;

export default class QuotePageBlotterSection extends PureComponent {
  static propTypes = {
    // TODO can remove currenciesQuery once `specifiedCurrency` in `Order` from maker has the full object
    currenciesQueryData: shape({
      currencies: arrayOf(shape({ id: string.isRequired }))
    }),
    currenciesQueryError: object,
    currenciesQueryLoading: bool,
    instrumentsQueryData: shape({
      instruments: arrayOf(shape({
        id: string.isRequired,
        displayName: string.isRequired
      }))
    }),
    instrumentsQueryError: object,
    instrumentsQueryLoading: bool,
    recentlyCompletedOrdersQueryData: shape({
      orders: arrayOf(
        shape({
          instrument: shape({ id: string.isRequired }).isRequired,
          specifiedCurrency: shape({ id: string.isRequired }).isRequired
        })
      )
    }),
    recentlyCompletedOrdersQueryError: object,
    recentlyCompletedOrdersQueryLoading: bool,
    recentlyCompletedOrdersSubscriptionData: shape({
      orders: arrayOf(
        shape({
          instrument: shape({ id: string.isRequired }).isRequired,
          specifiedCurrency: shape({ id: string.isRequired }).isRequired
        })
      )
    }),
    recentlyCompletedOrdersSubscriptionError: object,
    recentlyCompletedOrdersSubscriptionLoading: bool
  };

  state = {
    currentPage: 1,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE
  };

  render() {
    const loading =
      this.props.recentlyCompletedOrdersQueryLoading ||
      !has(this.props, 'recentlyCompletedOrdersQueryData.orders') ||
      this.props.currenciesQueryLoading ||
      !has(this.props, 'currenciesQueryData.currencies') ||
      this.props.instrumentsQueryLoading ||
      !has(this.props, 'instrumentsQueryData.instruments');

    const currencies = get(this.props, 'currenciesQueryData.currencies');

    // const instruments = populateInstrumentsWithCurrencies(
    //   get(this.props, 'instrumentsQueryData.instruments', []),
    //   currencies
    // );

    const instruments = get(this.props, 'instrumentsQueryData.instruments', []);

    let completedOrders =
      get(this.props, 'recentlyCompletedOrdersSubscriptionData.orders') ||
      get(this.props, 'recentlyCompletedOrdersQueryData.orders');

    completedOrders = populateOrders(
      completedOrders,
      instruments,
      currencies, // TODO remove
      []
    );

    completedOrders = orderBy(
      completedOrders,
      order => order.timeCreated,
      'desc'
    );

    const numberOfPages = Math.ceil(
      completedOrders.length / this.state.rowsPerPage
    );

    const currentData = completedOrders.slice(
      (this.state.currentPage - 1) * this.state.rowsPerPage,
      this.state.currentPage * this.state.rowsPerPage
    );

    let startPage;

    if (numberOfPages <= 3 || this.state.currentPage === 1) {
      startPage = 1;
    } else if (this.state.currentPage === numberOfPages) {
      startPage = this.state.currentPage - 2;
    } else {
      startPage = this.state.currentPage - 1;
    }

    const pageRange = Array.from(
      new Array(Math.min(3, numberOfPages)),
      (v, i) => i + startPage
    );

    return (
      <StyledSection>
        <Menu attached="top">
          <Menu.Item content={copyText.sectionTitle} header icon="table" />
          <Menu.Tab active className={cn('menuItem')} onClick={noop}>
            {copyText.tabLabelCompletedOrders}
            <Label>{completedOrders.length}</Label>
          </Menu.Tab>
          <Menu.Menu position="right">
            <Menu.Item
              content={
                <CSVLink
                  className={cn('csvLink')}
                  data={getCsvData(completedOrders)}
                  filename={getCsvFilename()}
                  headers={getCsvHeaders()}>
                  <span>{copyText.csvLabel_download} CSV</span>{' '}
                  <Icon name="download" />
                </CSVLink>
              }
            />
          </Menu.Menu>
        </Menu>
        <Segment attached="bottom" className={cn('segment')}>
          {loading ? (
            <Loader
              className={cn('loader')}
              active
              content={copyText.loadingMessage}
              size="medium"
            />
          ) : (
            <Fragment>
              <div className={cn('tableWrapper')}>
                <QuotedOrderBlotterTable orders={currentData} />
              </div>
              <div className={cn('paginationSection')}>
                <div className={cn('rowNumberSelectionWrapper')}>
                  <Dropdown
                    className={cn('rowNumberSelection')}
                    name="rowsPerPageSelection"
                    compact
                    options={pageRowOptions}
                    selection
                    value={this.state.rowsPerPage || ''}
                    upward
                    onChange={this._handleChangeRowsPerPage}
                  />
                  <Fragment>{copyText.perPageLabel}</Fragment>
                </div>
                <div className={cn('paginationWrapper')}>
                  <Menu
                    className={cn('pagination')}
                    borderless
                    floated="right"
                    pagination
                    size="mini">
                    <Menu.Item
                      icon="angle double left"
                      value={1}
                      onClick={this._handleClickPageNumber}
                    />
                    <Menu.Item
                      icon="angle left"
                      value="LEFT"
                      onClick={this._handleClickPageDirection.bind(
                        this,
                        numberOfPages
                      )}
                    />
                    {pageRange.map(pageIndex => (
                      <Menu.Item
                        active={pageIndex === this.state.currentPage}
                        as="a"
                        content={`${pageIndex}`}
                        key={pageIndex}
                        value={pageIndex}
                        onClick={this._handleClickPageNumber}
                      />
                    ))}
                    <Menu.Item
                      icon="angle right"
                      value="RIGHT"
                      onClick={this._handleClickPageDirection.bind(
                        this,
                        numberOfPages
                      )}
                    />
                    <Menu.Item
                      icon="angle double right"
                      value={numberOfPages}
                      onClick={this._handleClickPageNumber}
                    />
                  </Menu>
                </div>
              </div>
            </Fragment>
          )}
        </Segment>
      </StyledSection>
    );
  }

  _handleChangeRowsPerPage = (event, { value }) => {
    const completedOrders =
      get(this.props, 'recentlyCompletedOrdersSubscriptionData.orders') ||
      get(this.props, 'recentlyCompletedOrdersQueryData.orders');

    if (this.state.currentPage * value > completedOrders.length) {
      this.setState({ currentPage: Math.ceil(completedOrders.length / value) });
    }

    this.setState({ rowsPerPage: value });
  };

  _handleClickPageNumber = (event, { value }) => {
    this.setState({ currentPage: value });
  };

  _handleClickPageDirection = (numberOfPages, event, { value }) => {
    let change = 0;
    if (value === 'LEFT' && this.state.currentPage > 1) {
      change = -1;
    }

    if (value === 'RIGHT' && this.state.currentPage < numberOfPages) {
      change = 1;
    }

    if (change !== 0) {
      this.setState(currentState => ({
        currentPage: currentState.currentPage + change
      }));
    }
  };
}

function getCsvData(orders) {
  return orderBy(
    orders.map(order => {
      return {
        timeLastUpdated: order.timeLastUpdated
          ? order.timeLastUpdated
          : order.timeCreated,
        fixClientID: order.fixClientID,
        fixQuoteID: order.fixQuoteID,
        fixVenueOrderID: order.fixVenueOrderID,
        fixOrderID: order.fixOrderID,
        fixClOrdID: order.fixClOrdID,
        status: order.status,
        instrument: order.instrument.displayName,
        side: order.side,
        specifiedQuantity: `${formatNumber(
          order.specifiedQuantity,
          get(order, 'specifiedCurrency.type') === AssetType.FIAT
            ? '0.00'
            : '0.00000000'
        )}`,
        specifiedCurrency: getAssetDisplayText(get(order, 'specifiedCurrency')),
        limitPrice: `${formatNumber(
          order.limitPrice,
          get(order, 'instrument.termCurrency.type') === AssetType.FIAT
            ? '0.00'
            : '0.00000000'
        )}`,
        limitCurrency: getAssetDisplayText(get(order, 'instrument.termCurrency')),
        filledQuantity: order.filledQuantity
          ? `${formatNumber(
              order.filledQuantity,
              get(order, 'specifiedCurrency.type') === AssetType.FIAT
                ? '0.00'
                : '0.00000000'
            )}`
          : copyText.notAvailable,
        filledCurrency: getAssetDisplayText(get(order, 'specifiedCurrency'))
      };
    }),
    'timeLastUpdated',
    'desc'
  );
}

function getCsvFilename() {
  return `${copyText.tabLabelCompletedOrders.toLowerCase()} ${formatDate(
    Date.now(),
    null,
    'YYYYMMDDHHmmss'
  )}.csv`.replace(/\s/g, '-');
}

function getCsvHeaders() {
  return [
    'timeLastUpdated',
    'fixClientID',
    'fixClOrdID',
    'fixQuoteID',
    'fixOrderID',
    'fixVenueOrderID',
    'status',
    'instrument',
    'side',
    'specifiedQuantity',
    'specifiedCurrency',
    'limitPrice',
    'limitCurrency',
    'filledQuantity',
    'filledCurrency'
  ].map(key => ({
    key,
    label: copyText[`csvHeader_${key}`]
  }));
}
