import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { populateBlotterOrders } from '../../../selectors/blotterSelectors';
import { useParams, useHistory } from 'react-router-dom';
import TradeBlotterToolbar from './TradeBlotterToolbar';
import OrderBlotterTable from './OrderBlotterTable';
import FillBlotterTable from './FillBlotterTable';
import SelectedOrderContainer from './SelectedOrderContainer';
import { QueryResult } from '@apollo/react-common'
import {
  PaginatedFillQData,
  Filter,
  FilterMap,
  Identifiable,
  IPaginationVariables,
  Notification,
  PaginatedOrderQData,
  Org,
  Order,
  SortParams,
  DistinctValueQData,
  TradePagePathParams,
  CancelOrderMData,
} from '../../../types';
import User from '../../../utils/AuthUtils';
import { useQuery, useMutation } from '@apollo/react-hooks';
import getPaginatedOrders from '../../../apollo/graphql/buy-side/getPaginatedOrders';
import getPaginatedFills from '../../../apollo/graphql/buy-side/getPaginatedFills';
import {
  BlotterTab,
  checkFiltersActive,
  DEFAULT_ORDER_DIRECTION,
  DEFAULT_PAGINATION_VARS,
  DEFAULT_SORT_PARAMS,
  getRefetchPaginationVars,
  LIMIT,
  TABLE_HEIGHT,
  BLOTTER_HEIGHT,
  condenseFilterOptions,
  parseInitialFilters,
  ORDER_FILTER_OPTIONS,
  getFiltersFromDistinctValues,
  FILL_FILTER_OPTIONS,
  checkCanUserCancelOrder,
  orderUpdateQuery,
  fillUpdateQuery,
  DEFAULT_FILL_SORT_PARAMS,
  DEFAULT_FILL_PAGINATION_VARS,
} from './shared';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IndexRange, MultiGrid } from 'react-virtualized';
import requestOrderCancellation from '../../../apollo/graphql/buy-side/requestOrderCancellation';
import { makeStyles } from '@omniex/poms-ui/lib/themes';
import { changed } from '../../../utils/PerformanceUtils';
import Paper from '@material-ui/core/Paper';
import getDistinctOrderValues from '../../../apollo/graphql/buy-side/getDistinctOrderValues';
import { ApolloClient, NetworkStatus } from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { MultiGridHandleSortParams } from '../multigrid';
import { useDebouncedTrigger } from '../../hooks';

const useStyles = makeStyles({
  root: {
    height: BLOTTER_HEIGHT,
  },
  tabContentContainer: {
    alignItems: 'center',
    display: 'flex',
    height: TABLE_HEIGHT,
    justifyContent: 'center',
  },
}, { name: 'TradePageBlotterSection' });

type TradeBlotterSectionProps = {
  apiClient: ApolloClient<NormalizedCacheObject> | undefined
  takerApiClient: ApolloClient<NormalizedCacheObject> | undefined
  newNotifications: Array<Notification>
  notificationFixClOrdID?: string
  orgQuery: Pick<QueryResult<{ org: Org }, { orgId: string }>, 'data' | 'loading' | 'error'>
  portfolioId: string | undefined
  refreshDataOnFill: string | undefined
  setNotificationFixClOrdID: (notificationFixClOrdID?: string) => void
  user: User
}

const TradeBlotterSection: React.FC<TradeBlotterSectionProps> = ({
  apiClient,
  takerApiClient,
  newNotifications,
  notificationFixClOrdID,
  orgQuery,
  portfolioId,
  refreshDataOnFill,
  setNotificationFixClOrdID,
  user,
}) => {
  const classes = useStyles();
  const sectionRef = useRef<HTMLDivElement>(null);
  const tabContentWrapperRef = useRef<HTMLDivElement>(null);
  const gridRef = useRef<MultiGrid>(null);
  const { fixClOrdID: pathParamFixClOrdID } = useParams<TradePagePathParams>();
  const history = useHistory();
  const [visibleTab, setVisibleTab] = useState(BlotterTab.Orders);
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);
  const [sortParams, setSortParams] = useState<SortParams>(DEFAULT_SORT_PARAMS);
  const [filters, setFilters] = useState<FilterMap>(parseInitialFilters(ORDER_FILTER_OPTIONS));

  // QUERIES
  const { data: oData, loading: oLoading, refetch: oRefetch, fetchMore: oFetchMore, networkStatus: oNetworkStatus, variables: oVars } = useQuery<PaginatedOrderQData, IPaginationVariables>(
    getPaginatedOrders, { client: apiClient, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true, variables: { portfolioId, ...DEFAULT_PAGINATION_VARS } }
  );
  const { data: fData, loading: fLoading, refetch: fRefetch, fetchMore: fFetchMore, networkStatus: fNetworkStatus, variables: fVars } = useQuery<PaginatedFillQData, IPaginationVariables>(
    getPaginatedFills, { client: apiClient, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true, variables: { portfolioId, ...DEFAULT_FILL_PAGINATION_VARS } }
  );
  const { data: distinctData } = useQuery<DistinctValueQData, {}>(getDistinctOrderValues, { client: apiClient, fetchPolicy: 'network-only' });
  const [requestCancelOrder] = useMutation<CancelOrderMData, Identifiable>(requestOrderCancellation, { client: takerApiClient });

  // DERIVED DATA
  const isLoading = orgQuery.loading;
  const isDataLoading = oLoading || fLoading;
  const isRefetching = oNetworkStatus === NetworkStatus.refetch || fNetworkStatus === NetworkStatus.refetch;
  const isFetchingMore = oNetworkStatus === NetworkStatus.fetchMore || fNetworkStatus === NetworkStatus.fetchMore;
  const isFetching = isRefetching || isFetchingMore || isDataLoading;
  const orderCount = oData?.orderData.count ?? 0;
  const fillCount = fData?.fillData.count ?? 0;
  const orders = oData?.orderData.orders ?? [];
  const fills = fData?.fillData.fills ?? [];
  const queryFilters = useMemo(() => condenseFilterOptions(filters), [filters]);
  const paginationVars = useMemo(() =>
    getRefetchPaginationVars(
      visibleTab === BlotterTab.Orders ? orders.length : fills.length,
      sortParams,
      queryFilters,
      portfolioId,
  ), [fills.length, orders.length, portfolioId, queryFilters, sortParams, visibleTab]);
  const populatedOrders = useMemo(() => populateBlotterOrders({ newNotifications, orders }), [newNotifications, orders]);
  const selectedOrder = useMemo(() => (selectedOrderId && populatedOrders.find(order => order.id === selectedOrderId)) || null, [selectedOrderId, populatedOrders]);
  const distinctOrderPortfolios = (distinctData && distinctData.distinctValues?.portfolios) || [];
  const hasOrdersFromMultiPortfolios = distinctOrderPortfolios.length > 1;

  // PRIVATE FUNS
  const getFreshData = useCallback(async () => {
    try {
      await (visibleTab === BlotterTab.Orders ? oRefetch : fRefetch)?.(paginationVars);
    } catch (e) {
      console.error(e);
    }
  }, [visibleTab, oRefetch, fRefetch, paginationVars]);

  const handleSubscriptionUpdate = useCallback(async () => {
    const otherTabRefetch = async () => await (visibleTab === BlotterTab.Orders ? fRefetch?.(DEFAULT_FILL_PAGINATION_VARS) : oRefetch?.(DEFAULT_PAGINATION_VARS));
    try {
      await Promise.all([getFreshData(), otherTabRefetch()]);
    } catch (e) {
      console.error(e);
    }
  }, [fRefetch, getFreshData, oRefetch, visibleTab]);

  const getFreshFilters = useCallback((tab: BlotterTab) => {
    const freshFilters = parseInitialFilters(tab === BlotterTab.Orders ? ORDER_FILTER_OPTIONS : FILL_FILTER_OPTIONS);
    if (distinctData) {
      const { executionType, instrumentId, traderId, venueId } = getFiltersFromDistinctValues(distinctData.distinctValues);
      freshFilters.executionType = executionType;
      freshFilters.instrumentId = instrumentId;
      freshFilters.traderId = traderId;
      freshFilters.venueId = venueId;
    }
    return freshFilters;
  }, [distinctData]);

  // PUBLIC FUNS
  const requestCancel = useCallback(async (id: string) => {
    if (!user.canReadOnly && id) {
      try {
        await requestCancelOrder({ variables: { id } });
      } catch (e) {
        console.error(e);
      }
    }
  }, [requestCancelOrder, user.canReadOnly]);

  const fetchNextPage = useCallback(async ({ startIndex }: IndexRange) => {
    const variables: IPaginationVariables = {
      params: {
        limit: startIndex < 100 ? LIMIT : 2 * LIMIT,
        offset: startIndex,
        orderDirection: sortParams.orderDirection,
        orderParameter: sortParams.orderParameter,
      },
      filters: queryFilters,
      portfolioId,
    };

    try {
      if (visibleTab === BlotterTab.Orders) {
        await oFetchMore({ variables, updateQuery: orderUpdateQuery })
      } else {
        await fFetchMore({ variables, updateQuery: fillUpdateQuery })
      }
    } catch (e) {
      console.error(e);
    }
  }, [fFetchMore, oFetchMore, portfolioId, queryFilters, sortParams.orderDirection, sortParams.orderParameter, visibleTab]);

  const setFilter = useCallback((c: string) => (f: Filter) => {
    setFilters(prev => ({ ...prev, [c]: f }))
  }, []);

  const resetFilters = useCallback((tab: BlotterTab) => {
    setFilters(getFreshFilters(tab));
  }, [getFreshFilters]);

  const handleSort = useCallback(({ dataKey }: MultiGridHandleSortParams) => {
    setSortParams(prev => prev.orderParameter === dataKey
      ? {
        orderDirection: prev.orderDirection === 'DESC' ? 'ASC' : 'DESC',
        orderParameter: prev.orderParameter
      }
      : {
        orderDirection: DEFAULT_ORDER_DIRECTION,
        orderParameter: dataKey
      }
    );
  }, []);

  const handleChangeTab = useCallback((_event: React.ChangeEvent<{}>, tab: BlotterTab) => {
    if (tab !== visibleTab) {
      resetFilters(tab);
      setSortParams(tab === BlotterTab.Fills ? DEFAULT_FILL_SORT_PARAMS : DEFAULT_SORT_PARAMS);
    }
    setVisibleTab(tab);
  }, [resetFilters, visibleTab]);

  const handleFocusOrder = useCallback((o: Order) => setSelectedOrderId(o.id), []);
  const checkIsOrderCancellable = useCallback((order: Order) => checkCanUserCancelOrder(order, user), [user]);

  const resetPortal = useCallback(() => {
    // forces grid update
    requestAnimationFrame(() => {
      gridRef?.current?.forceUpdateGrids();
    });
  }, []);

  // SIDE EFFECTS
  useEffect(() => {
    // For updating the #$@&!ng cancel button
    if (visibleTab === BlotterTab.Orders) document.addEventListener('scroll', resetPortal);
    return () => {
      document.removeEventListener('scroll', resetPortal);
    }
  }, [resetPortal, visibleTab]);

  useEffect(() => {
    // For refetching on sort/filter change
    if (changed(visibleTab === BlotterTab.Orders ? oVars : fVars, paginationVars) && !isFetching) getFreshData();
  }, [fVars, getFreshData, isFetching, oVars, paginationVars, visibleTab]);

  useEffect(() => {
    // For ensuring the distinct data makes its way into the filters.
    // In future more work can be done to update the distinct data based on currently active filters.
    if (distinctData) {
      const { executionType, instrumentId, traderId, venueId } = getFiltersFromDistinctValues(distinctData.distinctValues)
      setFilters(prevFilters => ({
        ...prevFilters,
        executionType,
        instrumentId,
        traderId,
        venueId,
      }));
    }
  }, [distinctData]);

  useDebouncedTrigger(refreshDataOnFill, handleSubscriptionUpdate, 1000);

  useEffect(() => gridRef.current?.recomputeGridSize(), [hasOrdersFromMultiPortfolios]);


  const closeModal = () => {
    if (selectedOrderId) setSelectedOrderId(null);
    if (pathParamFixClOrdID) history.replace({ pathname: '/trade' });
    if (notificationFixClOrdID) setNotificationFixClOrdID(undefined);
  };

  return (
    <div ref={sectionRef} className={classes.root}>
      <Paper>
        <TradeBlotterToolbar
          canDownloadTradeCSV={user.canDownloadTradeCSV && selectedOrderId === null}
          fillCount={fillCount}
          filters={queryFilters}
          handleChangeTab={handleChangeTab}
          isLoading={isFetching}
          orderCount={orderCount}
          onOpenCsvForm={resetPortal}
          portfolioId={portfolioId}
          resetFilters={resetFilters}
          showResetFilters={!selectedOrderId && checkFiltersActive(filters)}
          sortParams={sortParams}
          visibleTab={visibleTab}
        />
        <div ref={tabContentWrapperRef} className={classes.tabContentContainer}>
          {isLoading ? (
            <CircularProgress disableShrink />
          ) : (
            <>
              {visibleTab === BlotterTab.Orders ? (
                <OrderBlotterTable
                  cancelContainer={tabContentWrapperRef}
                  checkIsOrderCancellable={checkIsOrderCancellable}
                  fetchNextPage={fetchNextPage}
                  filters={filters}
                  gridRef={gridRef}
                  handleSort={handleSort}
                  handleFocusOrder={handleFocusOrder}
                  hasOrdersFromMultiPortfolios={hasOrdersFromMultiPortfolios}
                  hasOrgEnabledCustomId={orgQuery.data?.org.enableCustomOrderId ?? false}
                  height={TABLE_HEIGHT}
                  orders={populatedOrders}
                  ordersCount={orderCount}
                  ordersQueryLoading={isFetchingMore}
                  requestCancel={requestCancel}
                  setFilter={setFilter}
                  sortParams={sortParams}
                  user={user}
                />
              ) : (
                <FillBlotterTable
                  fetchNextPage={fetchNextPage}
                  fills={fills}
                  fillsCount={fillCount}
                  fillsQueryLoading={isFetchingMore}
                  filters={filters}
                  handleSort={handleSort}
                  height={TABLE_HEIGHT}
                  key='allFills'
                  setFilter={setFilter}
                  sortParams={sortParams}
                />
              )}
              <SelectedOrderContainer
                apiClient={apiClient}
                checkIsOrderCancellable={checkIsOrderCancellable}
                fixClOrdID={pathParamFixClOrdID || notificationFixClOrdID}
                newNotifications={newNotifications}
                onClose={closeModal}
                order={selectedOrder}
                requestCancel={requestCancel}
                updateTrigger={refreshDataOnFill}
                user={user}
              />
            </>
          )}
        </div>
      </Paper>
    </div>
  )
}

export default TradeBlotterSection;
