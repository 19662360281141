import gql from 'graphql-tag';

export default gql`
  query getCurrencies {
    currencies: getCurrencies {
      id
      name
      symbol
      type
      expiry
    }
  }
`;
